import React, { useEffect, useState } from 'react';
import './CAS1016.css';

const CAS1016 = () => {
    const [green, setGreen] = useState(false);
    const [patientStatus, setPatientStatus] = useState(0);
    const [emergency, setEmergency] = useState(null);
  
    const [result, setResult] = useState(0);

    const postData = async () => {
        setResult(patientStatus );
        setGreen(true);
        if(patientStatus == 1 || patientStatus == 2){
            setEmergency(null);
        }
    }
    useEffect(() => {
        postData();


    }, [patientStatus, result ,emergency]);






    return (
        <div className="rev">
              <h5 className="text-info pl-2">Canadian Cardiovascular Society (CCS) Angina Grade</h5>
              <p className="pl-2">Classifies severity of angina.</p>
            <div>
            <div className="fields row">
                <div className="col-md-4">
                    <div className="pt-2"><strong>Description</strong></div>

                </div>
                <div className="col-md-8 btn-group-vertical options-btn mb-3  "  >
                    <button style={{ height:80 }} onClick={() => setPatientStatus("I")} className={patientStatus === "I" ? "btn  text-left active" : "btn  text-left"}><span className="btn-name small  " >Angina with strenuous/rapid/prolonged exertion<br/> at work or recreation only; no angina with ordinary physical activity,<br/> e.g. walking, climbing stairs</span></button>
                    <button style={{ height:130 }}  onClick={() => setPatientStatus("II")} className={patientStatus === "II" ? "btn  text-left active" : "btn  text-left"}><span className="btn-name small ">Ordinary activity slightly limited: angina with <br/>walking/climbing stairs rapidly, walking uphill, walking or stair <br/>climbing after meals, in cold/wind, under emotional stress,<br/> during few hours after awakening, walking &gt;2 blocks on level<br/> ground, or climbing {">"}1 flight of stairs at normal <br/>pace and normal conditions</span></button>
                    <button style={{ height:80 }}  onClick={() => setPatientStatus("III")} className={patientStatus === "III" ? "btn  text-left active" : "btn  text-left"}><span className="btn-name small ">Marked limitation of ordinary physical activity:<br/> angina with walking 1-2 blocks on level ground or climbing 1 flight<br/> of stairs at normal pace and normal conditions</span></button>
                    <button style={{ height:80 }}  onClick={() => setPatientStatus("IV")} className={patientStatus === "IV" ? "btn  text-left active" : "btn  text-left"}><span className="btn-name small ">Inability to carry on any physical activity without<br/> discomfort; anginal syndrome may be present at rest</span></button>
                </div>
                </div>
            
            </div>
  


            <div className="stick mt-5" >
                {
                    result !=0 ? <>
                        <div className="apfel-score-result-container col-12 " >
                            <div className="point-result-container " >
                                <h1> Grade {result}</h1>
                                <div> CCS Angina Grade</div>
                                   
                               
                            </div>
                            
                                <div className="point-result-container " >
                                <div>
                                  Higher grade indicates higher severity
                                </div>
                                   
                               
                            </div>
                        

                        </div>
                  
                  
           


                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <br/>
           <div className="p-2" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/947585/">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Campeau L. Letter: Grading of angina pectoris. Circulation. 1976;54(3):522-3.</p>
                </div>
            </a>
        </div>
        </div>
    )
}


export default CAS1016

