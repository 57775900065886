import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';

const FluidBloodVolume = () => {
    const [green, setGreen] = useState(false);
    const [patient, setPatient] = useState(0);
    const [hematocrit, setHematocrit] = useState([]);
    const [weight, setWeight] = useState([]);
    const [sex, setSex] = useState("");
    const [height, setHeight] = useState([]);
    const [tunit, setTunit] = useState('cm');
    const [placehldr, setPlacehldr] = useState('Norm: 152 - 213');
    const [tunit1, setTunit1] = useState('kg');
    const [placehldr1, setPlacehldr1] = useState('');
    const [tunit11, setTunit11] = useState('g');
    const [placehldr11, setPlacehldr11] = useState('Norm: 600 - 4,000');

    const [totalbloodvol, setTotalbloodvol] = useState(0);
    const [rbcvol, setRbcvol] = useState(0);
    const [plazmavol, setPlazmavol] = useState(0);
    const [lml, setLml] = useState("");
    const [newheight, setNewheight] = useState(0);
    const [newweight, setNewweight] = useState(0);





    const postData = async () => {
        if (tunit === 'cm') {
            if(height < 229){
                
                setNewheight(height);
            }
            else{
                setNewheight(10000);
            }
        }
        if (tunit === 'in') {
            if(height < 91){

                setNewheight((height * 2.54).toFixed(2));
            }
            else{
                setNewheight(10000);
            }

        }

        if ((tunit1 === 'kg') && ((patient === 70) || (patient === 75) || (patient === 4))) {
            if (patient === 75) {
                setPlacehldr1("Norm: 3 - 8.3");
            }
            if (patient === 70) {
                setPlacehldr1("Norm: 4 - 25");
            }
            if (patient === 4) {
                setPlacehldr1("Norm: 45 - 113");
            }


            if((tunit1 === 'kg') && (patient === 75) && (weight < 46)){
                setNewweight(weight);
            }
        
            else if((tunit1 === 'kg') && (patient === 70)&& (weight < 26)){
                setNewweight(weight);
            }
     
            else if((tunit1 === 'kg') && (patient === 4)&& (weight > 24)&& (weight < 273) ){
                setNewweight(weight);
            }
            else{
                setNewweight(20000);
            }
           
           
        }
        if ((tunit1 === 'lbs') && ((patient === 70) || (patient === 75) || (patient === 4))) {
            if (patient === 75) {
                setPlacehldr1("Norm: 6 - 20");
            }
            if (patient === 70) {
                setPlacehldr1("Norm: 10 - 55");
            }
            if (patient === 4) {
                setPlacehldr1("Norm: 100 - 250");
            }

            if((tunit1 === 'lbs') && (patient === 75) && (weight < 101)){
                setNewweight((weight / 2.2046226218488).toFixed(2));
            }
        
            else if((tunit1 === 'lbs') && (patient === 70)&& (weight < 57)){
                setNewweight((weight / 2.2046226218488).toFixed(2));
            }
     
            else if((tunit1 === 'lbs') && (patient === 4)&& (weight > 54)&& (weight < 601) ){
                setNewweight((weight / 2.2046226218488).toFixed(2));
            }
            else{
                setNewweight(20000);
            }
            
            

        }

        if ((tunit11 === 'g') && ((patient === 100) || (patient === 85))) {
            if(weight < 9071){
                
                setNewweight((weight / 1000).toFixed(2));
            }
            else{
                setNewweight(20000);
            }
        }
        if ((tunit11 === 'oz') && ((patient === 100) || (patient === 85))) {
            if(weight <321){

                setNewweight((((weight / 0.035274) / 1000).toFixed(2)));
            }
            else{
                setNewweight(20000);
            }

        }




        if ((hematocrit < 71 && newheight != 10000)  /*(weight >= 25) && (weight < 273)*/) {
            if ((patient == 100) || (patient == 85) || (patient == 75) || (patient == 70)) {
                setTotalbloodvol(Math.round((newweight * patient)));
                setRbcvol(Math.round(((newweight * patient) * hematocrit) / 100));
                setPlazmavol(Math.round((newweight * patient) * (1 - hematocrit / 100)));

                setLml("mL");

                // setTotalbloodvol(Math.round(0.3561 * ((height*0.01)*3) + 0.03308 * (weight) + 0.1833));
            }
            else if (sex === "female") {
                setTotalbloodvol(Math.round((0.3561 * ((newheight * 0.01) * (newheight * 0.01) * (newheight * 0.01)) + 0.03308 * (newweight) + 0.1833) * 10) / 10);
                setRbcvol(Math.round(((0.3561 * ((newheight * 0.01) * (newheight * 0.01) * (newheight * 0.01)) + 0.03308 * (newweight) + 0.1833) * 1000) * hematocrit / 100));
                setPlazmavol(Math.round(((0.3561 * ((newheight * 0.01) * (newheight * 0.01) * (newheight * 0.01)) + 0.03308 * (newweight) + 0.1833) * 1000) * (1 - hematocrit / 100)));
                setLml("L");

            }
            else if (sex === "male") {
                // setTotalbloodvol(Math.round((0.3669 * ((height*0.01)*(height*0.01)*(height*0.01)) + 0.03219 * (weight) + 0.6041)*10)/10);
                setTotalbloodvol(Math.round((0.3669 * ((newheight * 0.01) * (newheight * 0.01) * (newheight * 0.01)) + 0.03219 * (newweight) + 0.6041) * 10) / 10);
                setRbcvol(Math.round(((0.3669 * ((newheight * 0.01) * (newheight * 0.01) * (newheight * 0.01)) + 0.03219 * (newweight) + 0.6041) * 1000) * hematocrit / 100));
                setPlazmavol(Math.round(((0.3669 * ((newheight * 0.01) * (newheight * 0.01) * (newheight * 0.01)) + 0.03219 * (newweight) + 0.6041) * 1000) * (1 - hematocrit / 100)));
                setLml("L");

            }

        }
        else {
            setTotalbloodvol(0);
            setRbcvol(0);
            setPlazmavol(0);
        }

        setGreen(true);

    }
    const validation1 = () => {
        if (tunit === 'cm') {

            if ((height < 13) && (height != 0)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if ((height < 229) && (height > 205)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (height > 228) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(height)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
        if (tunit === 'in') {
            if (height !== 0) {
                if ((height < 5) && (height > 0)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if ((height < 91) && (height > 80)) {
                    return <div className="validate">*Very High; double check.</div>

                }
                if (height > 89.7638) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(height)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
    }
    const validation2 = () => {

        if ((hematocrit <= 19) && (hematocrit != 0)) {
            return <div className="validate">*Very Low;double check.</div>

        }
        if ((hematocrit <= 70) && (hematocrit >= 56)) {
            return <div className="validate">*Very High;double check.</div>

        }
        if (hematocrit >= 71) {
            return <div className="validate">*Too high; please change to proceed.</div>

        }
        else if (isNaN(hematocrit)) {
            return <div className="validate">*Please Enter valid-no.</div>

        }
    }
    const validation3 = () => {

        if ((tunit1 === 'kg')&&(patient === 4)) {
            if ((weight < 25) && (weight != 0)) {
                return <div className="validate">*Too low; please change to proceed.</div>

            }
            if ((weight < 273) && (weight > 136)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (weight > 272) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(weight)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }

        }
        if ((tunit1 === 'lbs')&&(patient === 4)) {
            if ((weight < 55) && (weight != 0)) {
                return <div className="validate">*Too low; please change to proceed.</div>

            }
            if ((weight < 601) && (weight > 300)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (weight > 600) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(weight)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
        if ((tunit1 === 'kg')&&(patient === 70)) {
            if ((weight < 4) && (weight != 0)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if (weight > 25) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }

        }
        if ((tunit1 === 'lbs')&&(patient === 70)) {

            if ((weight < 8) && (weight != 0)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if (weight > 56) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }

            
        }
        if ((tunit1 === 'kg')&&(patient === 75)) {
            if ((weight < 2) && (weight != 0)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if ((weight < 46) && (weight > 22)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (weight > 45) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }

        }
        if ((tunit1 === 'lbs')&&(patient === 75)) {

            if ((weight < 3) && (weight != 0)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if ((weight < 101) && (weight > 50)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (weight > 100) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }

            
        }
        if ((tunit11 === 'g')&&((patient === 85)||(patient === 100))) {
            if ((weight < 596) && (weight != 0)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if (weight > 9070) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }

        }
        if ((tunit11 === 'oz')&&((patient === 85)||(patient === 100))) {

            if ((weight < 21) && (weight != 0)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if (weight >320 ) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }

            
        }


    }

    useEffect(() => {
        postData();

    }, [weight, hematocrit, height, patient, newheight, sex, newweight, placehldr11, placehldr1])

    const changeunit = () => {
        if (tunit === 'cm') {
            setTunit('in');
            setPlacehldr("Norm: 60 - 84");
            if(height. length !== 0){

                setHeight((height / 2.54).toFixed(2));
            }
        }
        if (tunit === 'in') {
            setTunit('cm');
            setPlacehldr("Norm: 152 - 213");
            if(height.length !== 0){
                setHeight((height * 2.54).toFixed(2));

            }
        }
    }
    const takeUnit = (e) => {
        if (tunit === 'cm') {
            let htcm = e.target.value.trim();
                setHeight(htcm);
        }
        if (tunit === 'in') {
            let htin = e.target.value.trim();
            setHeight(htin);
        }

    }
    ////////////////////////////////////////////////////////////////////////
    const changeunit1 = () => {

        if (tunit1 === 'kg') {
            setTunit1('lbs');
            if (patient === 75) {
                setPlacehldr1("Norm: 6 - 20");
            }
            if (patient === 70) {
                setPlacehldr1("Norm: 10 - 55");
            }
            if (patient === 4) {
                setPlacehldr1("Norm: 100 - 250");
            }
           
            if(weight.length !== 0){

                setWeight((weight * 2.2046226218488).toFixed(2));
            }
        }
        if (tunit1 === 'lbs') {
            setTunit1('kg');
            if (patient === 75) {
                setPlacehldr1("Norm: 3 - 8.3");
            }
            if (patient === 70) {
                setPlacehldr1("Norm: 4 - 25");
            }
            if (patient === 4) {
                setPlacehldr1("Norm: 45 - 113");
            }
           
            if(weight.length !== 0)
            {
                
                setWeight((weight / 2.2046226218488).toFixed(2));
            }
        }

    }
    const takeUnit1 = (e) => {
        if (tunit1 === 'kg') {
            setWeight(e.target.value);
        }
        if (tunit1 === 'lbs') {
            let ht = e.target.value;
            setWeight(ht);

        }
    }
    ///////////////////////////////////////////////////////////////////

    const changeunit11 = () => {
        if (tunit11 === 'g') {
            setTunit11('oz');
            setPlacehldr11("Norm: 21 - 160");
            if(weight.length !== 0){

                setWeight((weight * 0.035274).toFixed(2));
            }
        }

        if (tunit11 === 'oz') {
            setTunit11('g');
            setPlacehldr11("Norm: 600 - 4,000");
            if(weight.length !== 0){

                setWeight((weight / 0.035274).toFixed(2));
            }
        }

    }
    const takeUnit11 = (e) => {
        if (tunit11 === 'oz') {
            setWeight(e.target.value);
            if ((patient === 100) || (patient === 85)) {
            }
        }
        if (tunit11 === 'g') {
            let ht = e.target.value;
            setWeight(ht);
            if ((patient === 100) || (patient === 85)) {
            }

        }
    }


    const result = () => {
        if (patient == 4) {
            return <div>
                <div className="fields row">
                    <div className="col-md-5 col-sm-12">
                        <div className="pt-2"><strong>Sex</strong></div>
                    </div>
                    <div className="col-md-7 col-sm-12 btn-group" >
                        <button style={{ height: 40 }} className={sex === "male" ? "btn active text-white" : "btn "} onClick={() => setSex("male")}>Male <span className="calc-val text-right"></span></button>
                        <button style={{ height: 40 }} className={sex === "female" ? "btn active text-white" : "btn "} onClick={() => setSex("female")}>Female<span className="calc-val text-right"></span></button>
                    </div>

                </div>
                <div className="fields row">
                    <div className="col-md-4 col-sm-12">
                        <div className="pt-2"><strong>Height</strong></div>
                    </div>
                    <div className="col-md-8 col-sm-12 frm-field">
                        <input
                            onChange={takeUnit}
                            placeholder={placehldr}


                            className="ant-text-input" style={{ width: '72%' }} value={height} /><span className="units" onClick={changeunit}>{tunit}&#8651;</span>
                        <div>{validation1()}</div>
                        {/* <div>{changeht}</div>
                        <div>height{height}</div>
                        <div>newheight{newheight}</div> */}

                    </div>
                </div>
            </div>

        }

    }
    const weightcondition = () => {
        if ((patient == 100) || (patient == 85)) {
            return <div className="fields row">
                <div className="col-md-4 col-sm-12">
                    <div className="pt-2"><strong>Weight</strong></div>
                </div>
                <div className="col-md-8 col-sm-12 frm-field">
                    <input

                        onChange={takeUnit11}
                        placeholder={placehldr11}


                        className="ant-text-input" style={{ width: '72%' }} value={weight} /><span className="units" onClick={changeunit11}>{tunit11}&#8651;</span>
                    <div>{validation3()}</div>
                    {/* <div>{weight}</div>
                    <div>new weight{newweight}</div> */}

                </div>

            </div>
        }
        else if ((patient == 4) || (patient == 70) || (patient == 75)) {
            return <div className="fields row">
                <div className="col-md-4 col-sm-12">
                    <div className="pt-2"><strong>Weight</strong></div>
                </div>
                <div className="col-md-8 col-sm-12 frm-field">
                    <input

                        onChange={takeUnit1}
                        placeholder={placehldr1}


                        className="ant-text-input" style={{ width: '72%'}} value={weight} /><span className="units" onClick={changeunit1}>{tunit1}&#8651;</span>
                    <div>{validation3()}</div>
                    {/* <div>{weight}</div>
                    <div>new weight{newweight}</div> */}

                </div>

            </div>
        }
    }






    return (<div className="rev">
        <h5 className="text-info pl-2">Blood Volume Calculation</h5>
        <p className="pl-2">Calculates total blood volume, red blood cell volume, and plasma volume.</p>

        <div className="fields row">

            <div className="col-md-4 col-sm-12">
                <div className="pt-2"><strong>Patient</strong></div>
            </div>
            <div className="col-md-8 col-sm-12 btn-group-vertical options-btn">
                <button style={{ height:  40   }} onClick={() => setPatient(100)} className={patient === 100 ? "btn  text-left active " : "btn  text-left"}><span className="btn-name">Preterm neonate</span><span className="calc-val"></span></button>
                <button style={{ height:  40   }} onClick={() => setPatient(85)} className={patient === 85 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Term neonate</span><span className="calc-val"></span></button>
                <button style={{ height:  40   }} onClick={() => setPatient(75)} className={patient === 75 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Infant aged 1-4 months</span><span className="calc-val"></span></button>
                <button style={{ height:  40   }} onClick={() => setPatient(70)} className={patient === 70 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Child	&lt;25 kg (55 lbs)</span><span className="calc-val"></span></button>
                <button style={{ height:  40   }} onClick={() => setPatient(4)} className={patient === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Child ≥25 kg (55 lbs) or adult</span><span className="calc-val"></span></button>
            </div>
        </div>
        <div>
            {result()}
        </div>

        <div className="fields row">
            <div className="col-md-4 col-sm-12">
                <div className="pt-2"><strong>Hematocrit</strong><div>Optional, to calculate RBC and plasma volume</div></div>
            </div>
            <div className="col-md-8 col-sm-12 frm-field">
                <input
                    type={hematocrit}
                    onChange={e => setHematocrit(+e.target.value)}
                    autoComplete="off"
                    placeholder="Norm: 36 - 51"
                    className="ant-text-input fluidInput" style={{height: '50%'}}/><span  className="units unitBtn" style={{height: '50%'}}>%</span>
                <div>{validation2()}</div>
            </div>
        </div>
        <div>{weightcondition()}</div>
        {/* <div className="pb-2">
            <button onClick={postData} className="save-button-container" style={{ margin: '0 auto' }}>Submit</button>
        </div> */}
        <br />
        <div className="stick">
            {
                (totalbloodvol && rbcvol && plazmavol && newweight != 20000) ?
                    <div className="apfel-score-result-container" >

                        <div className="point-result-container" >
                            <div>
                                {/* <span><h1 className="breath">{totalbloodvol}</h1>&nbsp;{lml} </span> */}
                                <h1>{totalbloodvol} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>{lml}</span></h1>
                                <div>Total blood volume</div>
                            </div>
                        </div>
                        <div className="point-result-container" >
                            <div>
                              
                                <h1>{rbcvol} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>mL</span></h1>
                                <div>RBC volume</div>
                            </div>
                        </div>
                        <div className="point-result-container" >
                            <div>
                                <h1>{plazmavol} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>mL</span></h1>
                                <div>Plazma Volume</div>
                            </div>
                        </div>

                    </div> :
                    <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>

            }
        </div>
        <br/>
        <div className="p-2 mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a  className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/21936146/">
            <div className="reference-container-card" >
                <div className="reference-img" >
                    <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                </div>
                <p className="reference-paragraph" >Nadler SB, Hidalgo JH, Bloch T. Prediction of blood volume in normal human adults. Surgery. 1962;51(2):224-32.</p>
            </div>
           </a>
        </div>
    </div>
    )
}
export default FluidBloodVolume;