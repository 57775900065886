import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import logo from '../images/logo-small.png';

const Alert = (props) => {
    const customStyles = {
        content: {
          top: '25%',
          left: '37%',
          right: 'auto',
          bottom: 'auto',
          // marginRight           : '-50%',
        //   backgroundColor: "#000",
          backgroundSize: '30%',
         // backgroundImage: "linear-gradient(180deg,teal,#fff 90%)",
          width: 370,
          height: 210,
          boxShadow: '0px 0px 0px 1px silver',
          zIndex: 99,
          position:'fixed',
          padding:0

        }
      };
    return (
        <div>
            <Modal
                isOpen={props.isOpen}
                onRequestClose={props.onClose}
                 style={customStyles}
                 ariaHideApp={false}
                contentLabel="Anesthesia one"
            >   <div>
                <div style={{background: '#44948B', color:'#FFF',
                 borderTopLeftRadius:3,
                 padding:10,
                 borderTopRightRadius:3}}>
                     <img src={logo} style={{width:30, height:30}}/><span>ANESTHESIA ONE</span>
                 </div>
                 <div style={{textAlign:'center', minHeight:60, paddingTop:'10%'}}>{props.message}</div>
                 <hr/>
                 <div style={{ width:'100%', justifyContent:'center', display:'flex'}}>
                 <button className="save-button-container"  style={{color:'#FFF', backgroundColor:'rgb(68, 148, 139)'}} onClick={() => props.closeAlert()}>{props.label}</button>
                 </div>
                </div>
            </Modal>
            </div>
    )
}
export default Alert;
