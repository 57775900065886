import React, { useEffect, useState } from 'react';
import './CAS1053.css';

const CAS1053 = () => {
    const [green, setGreen] = useState(false);
    const [scale, setScale] = useState(0);
    const [result, setResult] = useState(0);
    const postData = async () => {
        if (scale) {
            setResult(scale);
        }
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [scale]);
    const result1 = () => {
        if (scale) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>Higher scores indicate greater sleepiness</div>
                </div>
            </div>
        }
        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }

    return (
        <div className="rev">
             <h4 className="text-info pl-2">Stanford Sleepiness Scale</h4>
            <p className="pl-2">Quantifies subjective degrees of sleepiness.</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Sleepiness descriptions</strong><br /></div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  60   }} onClick={() => setScale(1)} className={scale === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Feeling active and vital, alert,<br/> wide awake</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  60   }} onClick={() => setScale(2)} className={scale === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Functioning at a high level but not <br/>at a peak, able to concentrate</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  60   }} onClick={() => setScale(3)} className={scale === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Relaxed, awake, not at full<br/> alertness, responsive</span><span className="calc-val ">+3</span></button>
                    <button style={{ height:  60   }} onClick={() => setScale(4)} className={scale === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">A little foggy, not at a peak, <br/>let down</span><span className="calc-val ">+4</span></button>
                    <button style={{ height:  60   }} onClick={() => setScale(5)} className={scale === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Foggy, beginning to lose interest <br/>in remaining awake, slowed down</span><span className="calc-val ">+5</span></button>
                    <button style={{ height:  60   }} onClick={() => setScale(6)} className={scale === 6 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Sleepy, prefer to be laying down,<br/> fighting sleep, woozy</span><span className="calc-val ">+6</span></button>
                    <button style={{ height:  60   }} onClick={() => setScale(7)} className={scale === 7 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Almost in reverie, sleep onset soon,<br/> lost struggle to remain awake</span><span className="calc-val ">+7</span></button>
                </div>
            </div>
            <div className='stick pt-3'>
                {
                    result1()
                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://onlinelibrary.wiley.com/doi/abs/10.1111/j.1469-8986.1973.tb00801.x">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >Hoddes E, Zarcone V, Smythe H, Phillips R, Dement WC. Quantification of sleepiness: a new approach. Psychophysiology. 1973;10(4):431-6.</p>
                    </div>
                </a>
            </div>
        </div>

    )
}

export default CAS1053
