import React, { useEffect, useState } from 'react';
import './CAS1045.css';

const CAS1045 = () => {

    const [cry, setCry] = useState([]);
    const [cry1, setCry1] = useState(0);
    const [fexpression, setFexpression] = useState([]);
    const [fexpression1, setFexpression1] = useState(0);
    const [verbalres, setVerbalres] = useState([]);
    const [verbalres1, setVerbalres1] = useState(0);
    const [torso, setTorso] = useState([]);
    const [torso1, setTorso1] = useState(0);
    const [Wound, setwound] = useState([]);
    const [Wound1, setwound1] = useState(0);
    const [legs, setLegs] = useState([]);
    const [legs1, setLegs1] = useState(0);
    const [result, setResult] = useState(0);


    const postData = async () => {
        if (cry == 1) {
            setCry1(1);
        }
        else if (cry == 2 || cry == 3) {
            setCry1(2);
        }
        else if (cry == 4) {
            setCry1(3);
        }

        if (fexpression == 1) {
            setFexpression1(0);
        }
        else if (fexpression == 2) {
            setFexpression1(1);
        }
        else if (fexpression == 3) {
            setFexpression1(2);
        }

        if (verbalres == 1) {
            setVerbalres1(0);
        }
        else if (verbalres == 2 || verbalres == 3) {
            setVerbalres1(1);
        }
        else if (verbalres == 4 || verbalres == 5) {
            setVerbalres1(2);
        }

        if (torso == 1) {
            setTorso1(1);
        }
        else if (torso != 0) {
            setTorso1(2);
        }

        if (Wound == 1) {
            setwound1(1);
        }
        else if (Wound != 0) {
            setwound1(2);
        }


        if (legs == 1) {
            setLegs1(1);
        }
        else if (legs != 0) {
            setLegs1(2);
        }




        setResult(cry1 + fexpression1 + verbalres1 + torso1 + Wound1 + legs1);

    }
    useEffect(() => {
        postData();


    }, [cry, fexpression, verbalres, torso, Wound, legs, result, cry1, fexpression1, verbalres1, torso1, Wound1, legs1]);

    return (
        <div className="rev">
            <h4 className="text-info pl-2">Children's Hospital of Eastern Ontario Pain Scale (CHEOPS) for Post-Op Pediatric Pain</h4>
            <p className="pl-2">
                Quantifies pain post-operatively in pediatric patients 1-5 years old.</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Cry</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  40 }} onClick={() => setCry(1)} className={cry === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">
                        No crying</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  40 }} onClick={() => setCry(2)} className={cry === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moaning</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40 }} onClick={() => setCry(3)} className={cry === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Crying</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40 }} onClick={() => setCry(4)} className={cry === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Screaming</span><span className="calc-val ">+3</span></button>


                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Facial expression</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2 ">
                    <button style={{ height:  40 }} onClick={() => setFexpression(1)} className={fexpression === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">
                        Smiling</span><span className="calc-val ">0</span></button>
                    <button style={{ height:  40 }} onClick={() => setFexpression(2)} className={fexpression === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Composed, neutral</span><span className="calc-val  ">+1</span></button>
                    <button style={{ height:  40 }} onClick={() => setFexpression(3)} className={fexpression === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Grimace</span><span className="calc-val ">+2</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Verbal responses</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button style={{ height:  40 }} onClick={() => setVerbalres(1)} className={verbalres === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">
                        Positive statementst</span><span className="calc-val ">0</span></button>
                    <button style={{ height:  40 }} onClick={() => setVerbalres(2)} className={verbalres === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Not talking</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  40 }} onClick={() => setVerbalres(3)} className={verbalres === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Complains but not about pain</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  40 }} onClick={() => setVerbalres(4)} className={verbalres === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Complains about pain</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40 }} onClick={() => setVerbalres(5)} className={verbalres === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Complains about pain and other subjects</span><span className="calc-val ">+2</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Torso</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button style={{ height:  40 }} onClick={() => setTorso(1)} className={torso === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">
                        Neutral/resting</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  40 }} onClick={() => setTorso(2)} className={torso === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Shifting</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40 }} onClick={() => setTorso(3)} className={torso === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Tense</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40 }} onClick={() => setTorso(4)} className={torso === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Shivering</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40 }} onClick={() => setTorso(5)} className={torso === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Upright</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40 }} onClick={() => setTorso(6)} className={torso === 6 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Restrained</span><span className="calc-val ">+2</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Wound evaluation</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button style={{ height:  40 }} onClick={() => setwound(1)} className={Wound === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Child not touching their woundt</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  40 }} onClick={() => setwound(2)} className={Wound === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Reaching for their wound</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40 }} onClick={() => setwound(3)} className={Wound === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Touching their wound</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40 }} onClick={() => setwound(4)} className={Wound === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Grabbing vigorously at wound</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40 }} onClick={() => setwound(5)} className={Wound === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Arms are restrained</span><span className="calc-val ">+2</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Legs</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button style={{ height:  40 }} onClick={() => setLegs(1)} className={legs === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Neutral position</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  40 }} onClick={() => setLegs(2)} className={legs === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Squirming/kicking</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40 }} onClick={() => setLegs(3)} className={legs === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Drawn up, tensed legs</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40 }} onClick={() => setLegs(4)} className={legs === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Standing/kneeling</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40 }} onClick={() => setLegs(5)} className={legs === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Legs are restrained</span><span className="calc-val ">+2</span></button>
                </div>
            </div>



            <div className="stick">
                {
                    (result != 0 && cry != 0 && torso != 0 && legs != 0 && Wound != 0) ? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container col-12" >
                                <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                                <div>{result <= 4 ? "No pain (minimum score is 4)." : " "}</div>
                                <div>{result > 4 ? "Pain present." : " "}</div>
                                <div>{result > 4 ? "Consider analgesia if appropriate." : " "}</div>

                            </div>

                        </div>

                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>

                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >McGrath PJ, et al. CHEOPS: a behavioral scale for rating postoperative pain in children. In: H.L. Fields, R. Dubner and F. Cervero (Eds.), Advances in Pain Research and Therapy, Raven Press, New York, 1985b. pp. 395-402.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}


export default CAS1045

