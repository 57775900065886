import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';
import Result from './Result';
const FenaFluidCalculator = () => {
    const [green, setGreen] = useState(true);
    const [number1, setNumber1] = useState([]);
    const [number2, setNumber2] = useState([]);
    const [number3, setNumber3] = useState([]);
    const [number4, setNumber4] = useState([]);
    const [total1, settotal1] = useState(0)
    const [tunit, setTunit] = useState('mmol/L');
    const [placehldr, setPlacehldr] = useState('Norm: 136 - 145')
    const [tunit1, setTunit1] = useState('mmol/L');
    const [placehldr1, setPlacehldr1] = useState('Norm:100-260')
    const [tunit2, setTunit2] = useState('μmol/L');
    const [placehldr2, setPlacehldr2] = useState('Norm: 62 - 115')
    const [tunit4, setTunit4] = useState('μmol/L');
    const [placehldr4, setPlacehldr4] = useState('Norm: 1768 - 32780')
    const [newNumber, setnewNumber] = useState(0);
    const [newNumber1, setnewNumber1] = useState(0);
    const [newNumber2, setnewNumber2] = useState(0);
    const [newNumber3, setnewNumber3] = useState(0);
    const postData = async () => {
        if (tunit === 'mmol/L') {
            if ((number1 < 200)&& (number1 > 99)) {
                setnewNumber(number1)
            }
            else{
                setnewNumber(0)  
            }

        }
        if (tunit === 'mEq/L') {
            if ((number1 < 200) &&(number1 >= 100)){
                setnewNumber((number1 * 1).toFixed(2))
               
            }
            else{
                setnewNumber(0)  
            }

        }
        if (tunit1 === 'mmol/L') {
            if(number3 < 500){
            setnewNumber1(number3)
            }
            else{
                setnewNumber1(0)
            }

        }
        if (tunit1 === 'mEq/L') {
            if(number3 < 500){
            setnewNumber1((number3 * 1).toFixed(2))
            }else{
                setnewNumber1(0)
            }

        }
        if (tunit2 === 'μmol/L') {
            if(number2 < 3535){

            setnewNumber2((number2))
            }
            else{
            setnewNumber2(0)
            }
        }
        if (tunit2 === 'mg/dL') {
            if(number2 < 20){
            setnewNumber2((number2 * 88.4).toFixed(2))
            }
            else{
                setnewNumber2(0)
            }
        }
        if (tunit4 === 'μmol/L') {
            if((number4 < 61880)&&(number4>89)){
            setnewNumber3(number4)
            }
            else{
                setnewNumber3(0)
            }

        }
        if (tunit4 === 'mg/dL') {
            if( number4<700){
            setnewNumber3((number4 * 88.4).toFixed(2))
            }
            else{
                setnewNumber3(0)
            }

        }
        if ((number1 < 200) && (number2 < 300) && (number3 < 500) && (number4 < 61881)) {
            settotal1(100 * (newNumber2 * newNumber1) / (newNumber * newNumber3));
            setGreen(true);
        } else {
            settotal1(0);
        }
    }
    const round = () => {
        return (total1.toFixed(1));
    }
    const valnumber1 = () => {

        if (tunit === 'mmol/L') {
            if (number1 != 0) {
                if ((number1 < 100) && (number1 > 0)) {
                    return <div className="validate">*Too low; please change to proceed.</div>

                }
                if ((number1 < 200) && (number1 > 170)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number1 > 199) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number1)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
        if (tunit === 'mEq/L') {
            if (number1 !== 0) {
                if ((number1 < 100) && (number1 > 0)) {
                    return <div className="validate">*Too low; please change to proceed.</div>

                }
                if ((number1 < 201) && (number1 > 170)) {
                    return <div className="validate">*Very High; double check.</div>

                }
                if (number1 > 200) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number1)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }

    }

    const valnumber2 = () => {

        if (tunit2 === 'μmol/L') {
            if (number2 != 0) {
                if ((number2 <= 0)) {
                    return <div className="validate">*Too small please change to proceed</div>

                }
                if ((number2 < 3535) && (number2 > 884)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number2 > 3535) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number2)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
        if (tunit2 === 'mg/dL') {
            if (number2 !== 0) {

                if ((number2 < 41) && (number2 > 10)) {
                    return <div className="validate">*Very High; double check.</div>

                }
                if (number2 > 40) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number2)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }

    }

    const valnumber3 = () => {

        if (tunit1 === 'mmol/L') {
            if (number3 != 0) {
               
                if ((number3 < 501) && (number3 > 260)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number3 > 500) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number3)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
        if (tunit1 === 'mEq/L') {
            if (number3 !== 0) {
               
                if ((number3 < 500) && (number3 > 260)) {
                    return <div className="validate">*Very High; double check.</div>

                }
                if (number3 > 500) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number3)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }

    }
    const valnumber4 = () => {

        if (tunit4 === 'μmol/L') {
            if (number4 != 0) {
                if ((number4 < 89) && (number4 > 0)) {
                    return <div className="validate">Verylow ; double check.</div>

                }
                if ((number4 < 61881) && (number4 > 39780)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number4 > 61880) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number4)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
        if (tunit4 === 'mg/dL') {
            if (number4 !== 0) {
                if ((number4 < 700) && (number4 > 450)) {
                    return <div className="validate">*Very High; double check.</div>

                }
                if (number4 > 699) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number4)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }

    }

    useEffect(() => {

        postData()

    }, [number1, number2, number3, number4, newNumber, newNumber1, newNumber2, newNumber3])




    const changeunit = () => {
        if (number1 === 0) {
            if (tunit === 'mmol/L') {
                setTunit('mEq/L');

            }
            if (tunit === 'mEq/L') {
                setTunit('mmol/L');

            }
        } else if (number1 !== 0) {
            if (tunit === 'mmol/L') {
                setPlacehldr("Norm: 136-145");
                setTunit('mEq/L');
                if (number1.length !== 0) {
                    setNumber1(Math.round(number1 * 1).toFixed(2))
                }
            }
            if (tunit === 'mEq/L') {
                setPlacehldr("Norm: 136-145");
                setTunit('mmol/L');
                if (number1.length !== 0) {
                    setNumber1(Math.round(number1 * 1))
                }

            }
        }
    }
    const takeUnit = (e) => {
        if (tunit === 'mmol/L') {
            setNumber1(e.target.value);
            setPlacehldr("Norm:136-145");

        }
        if (tunit === 'mEq/L') {
            let ht = e.target.value;
            setNumber1(ht);
            setPlacehldr("Norm:136-145");
        }
    }

    const changeunit1 = () => {
        if (number3 === 0) {
            if (tunit1 === 'mmol/L') {
                setTunit1('mEq/L');

            }
            if (tunit1 === 'mEq/L') {
                setTunit1('mmol/L');

            }
        } else if (number3 !== 0) {
            if (tunit1 === 'mmol/L') {
                setPlacehldr1("Norm: 100-260");
                setTunit1('mEq/L');
                if (number3.length !== 0) {
                    setNumber3(Math.round(number3 * 1).toFixed(2))
                }
            }
            if (tunit1 === 'mEq/L') {
                setPlacehldr1("Norm: 100-260");
                setTunit1('mmol/L');
                if (number3.length !== 0) {
                    setNumber3(Math.round(number3 * 1))
                }
            }
        }
    }
    const takeUnit1 = (e) => {
        if (tunit1 === 'mmol/L') {
            setNumber3(e.target.value);
            setPlacehldr1("Norm:100-260");

        }
        if (tunit1 === 'mEq/L') {
            let ht = e.target.value;
            setNumber3(ht);
            setPlacehldr1("Norm:100-260");
        }
    }

    const changeunit2 = () => {
        if (number2 === 0) {
            if (tunit2 === 'μmol/L') {
                setTunit2('mg/dL');

            }
            if (tunit2 === 'mg/dL') {
                setTunit2('μmol/L');

            }
        } else if (number2 !== 0) {
            if (tunit2 === 'μmol/L') {
                setTunit2('mg/dL');

                setPlacehldr2("Norm: 0.7-1.3");
                if (number2.length !== 0) {
                    setNumber2((number2 / 88.4).toFixed(2))
                }
            }
            if (tunit2 === 'mg/dL') {
                setPlacehldr2("Norm: 62-155");
                setTunit2('μmol/L');
                if (number2.length !== 0) {
                    setNumber2((number2 * 88.4).toFixed(2))
                }
            }
        }
    }
    const takeUnit2 = (e) => {
        if (tunit2 === 'μmol/L') {
            setNumber2(e.target.value);
            setPlacehldr2("Norm: 62-155");

        }
        if (tunit2 === 'mg/dL') {
            let ht = e.target.value;
            setNumber2(ht);
            setPlacehldr2("Norm: 0.7-1.3");
        }
    }


    const changeunit4 = () => {
        if (number4 === 0) {
            if (tunit4 === 'μmol/L') {
                setTunit4('mg/dL');

            }
            if (tunit4 === 'mg/dL') {
                setTunit4('μmol/L');

            }
        } else if (number4 !== 0) {
            if (tunit4 === 'μmol/L') {
                setPlacehldr4("Norm: 20-370");
                setTunit4('mg/dL');

                if (number4.length !== 0) {
                    setNumber4((number4 / 88.41).toFixed(2))
                }
            }
            if (tunit4 === 'mg/dL') {
                setPlacehldr4("Norm: 1768-32780");
                setTunit4('μmol/L');

                if (number4.length !== 0) {
                    setNumber4((number4 * 88.41).toFixed(2))
                }
            }
        }
    }
    const takeUnit4 = (e) => {
        if (tunit4 === 'μmol/L') {
            setNumber4(e.target.value);
            setPlacehldr4("Norm: 1768-32780");

        }
        if (tunit4 === 'mg/dL') {
            let ht = e.target.value;
            setNumber4(ht);
            setPlacehldr4("Norm: 20-370");

        }
    }


    const result = () => {
        if (total1 && isFinite(total1) ) {
            if (total1 > 4) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <h2>{round()} %</h2>
                        <div>FENa</div>
                    </div>
                    <div className="point-result-container" >
                        <h2>Post-Renal/Obstructive</h2>
                        <div>e.g.BPH, balader store.bilateral ureter obstration</div>
                    </div>
                </div>
            } else if (total1 < 1) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <h2>{round()} %</h2>
                        <div>FENa</div>
                    </div>
                    <div className="point-result-container" >
                        <h2>Pre Renal</h2>
                        <div>e.g. Hypovolemia, heart failure, renal artery stenosis, sepsis (anything causing decreased effective renal perfusion). Remember, contrast-induced nephropathy will often look pre-renal.</div>

                    </div>
                </div>
            } else if (total1 >= 1) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <h2 className="breath">{round()} %</h2>
                        <div>FENa</div>
                    </div>
                    <div className="point-result-container" >
                        <h1>Intrinsic</h1>
                        <div>e.g. ATN, AIN, glomerulonephritides</div>

                    </div>
                </div>
            }

        }
        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }

    return (<div className="rev">
        <h5 className="text-info pl-2">Fractional Excretion of Sodium (FENa)</h5>
        <p className="pl-2">Determines if renal failure is due to pre-renal, intrinsic, or post-renal pathology.</p>

        <div className="fields row">
            <div className="col-md-5 col-sm-12">
                <div className="pt-2"><strong>Serum sodium</strong></div>
            </div>
            <div className="col-md-7 col-sm-12 frm-field">
                <input

                    onChange={takeUnit}
                    placeholder={placehldr}




                    className="ant-text-input" style={{ width: '65%' }} value={number1} /><span className="units" onClick={changeunit}>{tunit}&#8651;</span>
                <div>{valnumber1()}</div>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-5 col-sm-12">
                <div className="pt-2"><strong>Serum creatinine</strong></div>
            </div>
            <div className="col-md-7 col-sm-12 frm-field">
                <input
                    onChange={takeUnit2}
                    placeholder={placehldr2}

                    className="ant-text-input" style={{ width: '65%' }} value={number2} /><span className="units" onClick={changeunit2}>{tunit2}&#8651;</span>
                <div>{valnumber2()}</div>
            </div>


        </div>
        <div className="fields row">
            <div className="col-md-5 col-sm-12">
                <div className="pt-2"><strong>Urine sodium</strong></div>
            </div>
            <div className="col-md-7 col-sm-12 frm-field">
                <input
                    onChange={takeUnit1}
                    placeholder={placehldr1}


                    className="ant-text-input" style={{ width: "65%" }} value={number3} /><span className="units" onClick={changeunit1}>{tunit1}&#8651;</span>
                <div>{valnumber3()}</div>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-5 col-sm-12">
                <div className="pt-2"><strong>Urine creatinine`</strong></div>
            </div>
            <div className="col-md-7 col-sm-12 frm-field">
                <input
                    onChange={takeUnit4}
                    placeholder={placehldr4}


                    className="ant-text-input" style={{ width: '65%' }} value={number4} /><span className="units" onClick={changeunit4}>{tunit4}&#8651;</span>
                <div>{valnumber4()}</div>

            </div>
        </div>
        <br />

        <div>
            {
                result()
            }
        </div>
        <br />
        <div className="p-2 mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/947239/">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Espinel CH. The FENa test. Use in the differential diagnosis of acute renal failure. JAMA. 1976;236(6):579-81.</p>
                </div>
            </a>
        </div>
    </div>
    )
}
export default FenaFluidCalculator;
