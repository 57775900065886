const constantAntibioticsBySurgeryType = {
    CARDIAC:{
        "id": 52,
        "user_fav_table": "uf_drugs",
        "foreign_key": "drugs_id",
        "name": "CARDIAC",
        "parent_id": "40",
        "parent_name": "Antibiotics - by surgery type",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "drugs",
        "cp_code": "D_ST_1326",
        "children": [
            {
                "id": 64,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "CABG +/- VALVE REPLACEMENT OR OTHER CARDIAC PROCEDURE",
                "parent_id": "52",
                "parent_name": "CARDIAC",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1124"
            },
            {
                "id": 66,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "LVAD/RVAD/BiVAD, HEART TRANSPLANT, TOTAL ARTIFICAL HEART",
                "parent_id": "52",
                "parent_name": "CARDIAC",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1126"
            },
            {
                "id": 65,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "PACEMAKER AND DEVICE IMPLANT",
                "parent_id": "52",
                "parent_name": "CARDIAC",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1125"
            }
        ]
    },
    GENERAL:{
        "id": 57,
        "user_fav_table": "uf_drugs",
        "foreign_key": "drugs_id",
        "name": "GENERAL",
        "parent_id": "40",
        "parent_name": "Antibiotics - by surgery type",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "drugs",
        "cp_code": "D_ST_1327",
        "children": [
            {
                "id": 75,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "APPENDECTOMY - UNCOMPLICATED",
                "parent_id": "57",
                "parent_name": "GENERAL",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1128"
            },
            {
                "id": 74,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "BILIARY TRACT - OPEN",
                "parent_id": "57",
                "parent_name": "GENERAL",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1127"
            },
            {
                "id": 79,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "COLORECTAL",
                "parent_id": "57",
                "parent_name": "GENERAL",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1132"
            },
            {
                "id": 80,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "GASTRODUODENAL - PEG TUBE, BARIATRIC SURGERY, ANY GASTRODUODENAL SURGERY, OR WITH OR WITHOUT ENTRY INTO THE LUMEN",
                "parent_id": "57",
                "parent_name": "GENERAL",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1133"
            },
            {
                "id": 76,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "SMALL INTESTINE - HERNIA REPAIR",
                "parent_id": "57",
                "parent_name": "GENERAL",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1129"
            },
            {
                "id": 77,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "SMALL INTESTINE - NON-OBSTRUCTED",
                "parent_id": "57",
                "parent_name": "GENERAL",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1130"
            },
            {
                "id": 78,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "SMALL INTESTINE - OBSTRUCTED",
                "parent_id": "57",
                "parent_name": "GENERAL",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1131"
            },
            {
                "id": 116,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "WITH IMPLANTED FOREIGN MATERIAL",
                "parent_id": "57",
                "parent_name": "GENERAL",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1134"
            }
        ]
    },
    GYNECOLOGIC:{
        "id": 59,
        "user_fav_table": "uf_drugs",
        "foreign_key": "drugs_id",
        "name": "GYNECOLOGIC",
        "parent_id": "40",
        "parent_name": "Antibiotics - by surgery type",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "drugs",
        "cp_code": "D_ST_1328",
        "children": [
            {
                "id": 86,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "C-SECTION - ELECTIVE",
                "parent_id": "59",
                "parent_name": "GYNECOLOGIC",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1137"
            },
            {
                "id": 87,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "C-SECTION - NON-ELECTIVE",
                "parent_id": "59",
                "parent_name": "GYNECOLOGIC",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1138"
            },
            {
                "id": 84,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "HYSTERECTOMY WITH ANY APPROACH, ONCOLOGIC WITHOUT BOWEL INVOLVEMENT",
                "parent_id": "59",
                "parent_name": "GYNECOLOGIC",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1135"
            },
            {
                "id": 85,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "SUCTION D AND C",
                "parent_id": "59",
                "parent_name": "GYNECOLOGIC",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1136"
            }
        ]
    },
    HEADANDNECK:{
        "id": 61,
        "user_fav_table": "uf_drugs",
        "foreign_key": "drugs_id",
        "name": "HEAD AND NECK",
        "parent_id": "40",
        "parent_name": "Antibiotics - by surgery type",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "drugs",
        "cp_code": "D_ST_1329",
        "children": [
            {
                "id": 91,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "CLEAN - CONTAMINATED / MUCOSA VIOLATED / CLEAN CONTAMINATED CANCER SURGERY",
                "parent_id": "61",
                "parent_name": "HEAD AND NECK",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1141"
            },
            {
                "id": 89,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "CLEAN PROCEDURES",
                "parent_id": "61",
                "parent_name": "HEAD AND NECK",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1139"
            },
            {
                "id": 90,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "CLEAN WITH IMPLANT PLACEMENT",
                "parent_id": "61",
                "parent_name": "HEAD AND NECK",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1140"
            },
            {
                "id": 92,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "SKULL BASE WITH DURAL VIOLATION",
                "parent_id": "61",
                "parent_name": "HEAD AND NECK",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1142"
            }
        ]
    },
    LAPAROSCOPIC:{
        "id": 58,
        "user_fav_table": "uf_drugs",
        "foreign_key": "drugs_id",
        "name": "LAPAROSCOPIC",
        "parent_id": "40",
        "parent_name": "Antibiotics - by surgery type",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "drugs",
        "cp_code": "D_ST_1330",
        "children": [
            {
                "id": 83,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "HIGH RISK",
                "parent_id": "58",
                "parent_name": "LAPAROSCOPIC",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1144"
            },
            {
                "id": 82,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "LOW RISK",
                "parent_id": "58",
                "parent_name": "LAPAROSCOPIC",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1143"
            }
        ]
    },
    NEUROSURGERY:{
        "id": 54,
        "user_fav_table": "uf_drugs",
        "foreign_key": "drugs_id",
        "name": "NEUROSURGERY",
        "parent_id": "40",
        "parent_name": "Antibiotics - by surgery type",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "drugs",
        "cp_code": "D_ST_1331",
        "children": [
            {
                "id": 70,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "COMPLEX CRANIOTOMY, IMPLANTED INTRACRANIAL HARDWARE (SHUNTS, LEADS, ETC…)",
                "parent_id": "54",
                "parent_name": "NEUROSURGERY",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1146"
            },
            {
                "id": 69,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "SIMPLE CRANIOTOMY",
                "parent_id": "54",
                "parent_name": "NEUROSURGERY",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1145"
            }
        ]
    },
    ORTHOPEDICS:{
        "id": 53,
        "user_fav_table": "uf_drugs",
        "foreign_key": "drugs_id",
        "name": "ORTHOPEDICS",
        "parent_id": "40",
        "parent_name": "Antibiotics - by surgery type",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "drugs",
        "cp_code": "D_ST_1332",
        "children": [
            {
                "id": 67,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "CLEAN PROCEDURES OF HAND, KNEE AND FOOT",
                "parent_id": "53",
                "parent_name": "ORTHOPEDICS",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1147"
            },
            {
                "id": 68,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "INTERNAL FIXATION OF FRACTURE, TOTAL JOINT REPLACEMENT OR IMPLANTED FOREIGN BODY",
                "parent_id": "53",
                "parent_name": "ORTHOPEDICS",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1148"
            }
        ]
    },
    THORACIC:{
        "id": 55,
        "user_fav_table": "uf_drugs",
        "foreign_key": "drugs_id",
        "name": "THORACIC",
        "parent_id": "40",
        "parent_name": "Antibiotics - by surgery type",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "drugs",
        "cp_code": "D_ST_1333",
        "children": [
            {
                "id": 71,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "NON-CARDIAC, THORACOTOMY/VIDEOTHORACOSCOPY",
                "parent_id": "55",
                "parent_name": "THORACIC",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1149"
            }
        ]
    },
    TRANSPLANT:{
        "id": 63,
        "user_fav_table": "uf_drugs",
        "foreign_key": "drugs_id",
        "name": "TRANSPLANT",
        "parent_id": "40",
        "parent_name": "Antibiotics - by surgery type",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "drugs",
        "cp_code": "D_ST_1336",
        "children": [
            {
                "id": 98,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "LIVER TRANSPLANT",
                "parent_id": "63",
                "parent_name": "TRANSPLANT",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1150"
            },
            {
                "id": 99,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "PANCREAS OR KIDNEY TRANSPLANT",
                "parent_id": "63",
                "parent_name": "TRANSPLANT",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1151"
            }
        ]
    },
    UROGYNECOLOGY:{
        "id": 60,
        "user_fav_table": "uf_drugs",
        "foreign_key": "drugs_id",
        "name": "UROGYNECOLOGY",
        "parent_id": "40",
        "parent_name": "Antibiotics - by surgery type",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "drugs",
        "cp_code": "D_ST_1337",
        "children": [
            {
                "id": 88,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "ALL",
                "parent_id": "60",
                "parent_name": "UROGYNECOLOGY",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1152"
            }
        ]
    },
    UROLOGIC:{
        "id": 62,
        "user_fav_table": "uf_drugs",
        "foreign_key": "drugs_id",
        "name": "UROLOGIC",
        "parent_id": "40",
        "parent_name": "Antibiotics - by surgery type",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "drugs",
        "cp_code": "D_ST_1335",
        "children": [
            {
                "id": 97,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "CLEAN CONTAMINATED WITH ENTRY IN URINARY TRACT, RADICAL CYSTECTOMY, ILEAL CONDUIT, CYSTOPROSTATECTOMY",
                "parent_id": "62",
                "parent_name": "UROLOGIC",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1157"
            },
            {
                "id": 95,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "CLEAN WITHOUT ENTERING INTO URINARY TRACT, NEPHRECTOMY, RADICAL PROSTATECTOMY, PROSTATE BRACHYTERAPY",
                "parent_id": "62",
                "parent_name": "UROLOGIC",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1155"
            },
            {
                "id": 93,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "CYSTOSCOPY WITH MANIPULATION, BIOPSY, STENT, DILATION, LITHOTROPSY OR ANY HIGH RISK PROCEDURE",
                "parent_id": "62",
                "parent_name": "UROLOGIC",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1153"
            },
            {
                "id": 96,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "PROSTHETIC MAERIAL PLACEMENT",
                "parent_id": "62",
                "parent_name": "UROLOGIC",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1156"
            },
            {
                "id": 94,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "TRANS-RECTAL PROSTATE BIOPSY",
                "parent_id": "62",
                "parent_name": "UROLOGIC",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1154"
            }
        ]
    },
    VASCULAR:{
        "id": 56,
        "user_fav_table": "uf_drugs",
        "foreign_key": "drugs_id",
        "name": "VASCULAR",
        "parent_id": "40",
        "parent_name": "Antibiotics - by surgery type",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "drugs",
        "cp_code": "D_ST_1334",
        "children": [
            {
                "id": 73,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "AMPUTATION, ARTERIAL SURGERY, GRAFT SURGERY",
                "parent_id": "56",
                "parent_name": "VASCULAR",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1159"
            },
            {
                "id": 72,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "PROCEDURE WITHOUT PROSTHESIS, THROMBOLYSIS, ANGIOGRAM, IVC/CVC",
                "parent_id": "56",
                "parent_name": "VASCULAR",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "drugs",
                "cp_code": "D1158"
            }
        ]
    }
}

export default constantAntibioticsBySurgeryType