import React from 'react'
import AAV1001 from './AAV1001/AAV1001';
import AAV1002 from './AAV1002/AAV1002';
import AAV1003 from './AAV1003/AAV1003';
import AAV1004 from "./AAV1004/AAV1004";
import AAV1005 from "./AAV1005/AAV1005";
import AAV1006 from "./AAV1006/AAV1006";
import AAV1007 from "./AAV1007/AAV1007";
import AAV1008 from "./AAV1008/AAV1008";
import AAV1009 from "./AAV1009/AAV1009";
import AAV1010 from "./AAV1010/AAV1010";

const AirwayAndVentilationIndex = ({ id }) => {
    const data = {
        AAV1001: <AAV1001 />,
        AAV1002: <AAV1002 />,
        AAV1003: <AAV1003 />,
        AAV1004: <AAV1004 />,
        AAV1005: <AAV1005 />,
        AAV1006: <AAV1006 />,
        AAV1007: <AAV1007 />,
        AAV1008: <AAV1008 />,
        AAV1009: <AAV1009 />,
        AAV1010: <AAV1010 />
    }

    return <React.Fragment>
        {data[id]}
    </React.Fragment>

}

export {
    AirwayAndVentilationIndex
}