import '../css/header.css'
import React, { useEffect, useState, useContext } from 'react';
import header from '../images/logo-1.png';
import { Link, NavLink } from 'react-router-dom';
import { API_ROOT } from '../constants'
import ProfileIcon from '../images/profile.svg'
import { useHistory } from "react-router-dom";
import AppContext from '../contextStore/DataProvider';

const Header = ({ login }) => {
    const history = useHistory();
    const context = useContext(AppContext)
    const [profileImage, setprofileImage] = useState("")
    const [loading, setloading] = useState(false)
    const window_width = window.innerWidth;
    const getProfile = () => {
        var token = sessionStorage.getItem("token")
        if (token === null) return
        setloading(true)
        fetch(API_ROOT + `get-profile`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }

        })
            .then(response => response.json())
            .then(res => {
                if (res.status) {
                    context.addUserDetails(res.data)
                    setprofileImage(res.data.profile_photo_url)
                    setloading(false)
                } else {
                    setloading(false)
                }
            })
    }

    if (login || sessionStorage.getItem("token") !== null) {
        if (profileImage === "" && loading === false) {
            getProfile()
        }
    }

    const toggleHamburger = () => {
        if (history.location.pathname !== '/drawer') {
            history.push('/drawer');
        } else {
            history.push('/actionLibrary');

        }
    }

    const gotoFeedback = () => {
        history.push({
            pathname: '/feedback',
            state: {
                sourceName: 'Action Library',
                returnPath: 'allaction'
            }

        });
    }

    const logout = () => {
        let token = sessionStorage.getItem("token")
        sessionStorage.removeItem('token');
        sessionStorage.removeItem("id");
        history.push('/login');
        context.changeLogin(false)
        context.changeActiveId(null)
        context.addUserDetails(null)
        setprofileImage("")
        fetch(API_ROOT + `user-logout?device_type=Android&device_token=123`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }

        })
            .then(response => response.json())
            .then(res => { })
    }




    return (
        <div className="header stickheader" >
            {/* <div className="container w-75"> */}
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-8 header-logo">
                        <Link to="/home"  >
                            <img alt="logo" src={header} />
                        </Link>

                    </div>
                    <div className="col-md-8 col-12">
                        <div className="tab-container" >
                            <NavLink to="/home" activeClassName="active-tab">ACTION LIBRARY</NavLink>
                            <NavLink to="/preoperativeevaluation" activeClassName="active-tab">PREOP TESTING</NavLink>
                            <NavLink to="/favourite" activeClassName="active-tab">FAVORITES</NavLink>
                            <NavLink to="/case-logs" activeClassName="active-tab">CASE LOGS</NavLink>


                            {/* <NavLink to="/startacase/casesummary" activeClassName="active-tab">
                            START A CASE
                        </NavLink> */}
                        </div>
                    </div>
                    <div className="col-md-1 col-4">

                        <div className="pt-2 profile-btn">
                            <img id="navbarDropdown" className="dropdown-toggle" data-toggle="dropdown" role="button" style={{ height: 40, width: 40, borderRadius: '50%', objectFit: 'cover' }} src={loading || profileImage === null ? ProfileIcon : profileImage} alt="" />
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <div style={{ cursor: 'pointer' }} onClick={() => history.push('/myprofile')} className="dropdown-item">My Profile</div>
                                <div style={{ cursor: 'pointer' }} onClick={() => history.push('/changepassword')} className="dropdown-item" href="#">Change Password</div>
                                <div style={{ cursor: 'pointer' }} onClick={gotoFeedback} className="dropdown-item" href="#">Write Feedback</div>
                                <div style={{ cursor: 'pointer' }} onClick={logout} className="dropdown-item" href="#">Logout</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <span className="feedback-btn"><div onClick={() => { gotoFeedback(); }}>Feedback</div></span> */}
        </div>
    )
}
export default Header
