import './App.css';
import './css/style.css';
import './css/responsive.css';
import React, { useContext } from 'react'
import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";

import { store } from "./redux/store"
import { Provider } from "react-redux"
import Login from './Screen/login';
import IndexAfterLogin from './Screen/indexAfterLogin';
import Signup from './Screen/Signup';
import AllAction from './Screen/AllAction';
import ActionLibrary from './views/pages/home/Home';
import ForgotPassword from './Screen/ForgotPassword';
import MyProfile from './Screen/MyProfile';
import Change_pass from './Screen/Change_Pass';
import Notification from '../src/Screen/Notification'
import MySubcription from './Screen/MySubcription';
import EditProfile from './Screen/EditProfile';
import Subcription from './Screen/Subcription';
import Settings from './Screen/Settings';
import Feedback from './Screen/Feedback';
import SaveCases from './Screen/SaveCases';
import Home from './Screen/Home.js'
import About from './Screen/About';
import Contact from './Screen/Contact';
import Tnc from './Screen/Tnc';
import PrivacyPolicy from './Screen/PrivacyPolicy.js'
import ResultOfAction from './Screen/ResultOfActionLibrary';
import AllActionForActionSummary from './Screen/AllActionForActionSummary';
import ActionSummary from './Screen/ActionSummary';
import Favorites from './Screen/Favorites';
import Recent from './Screen/Recent';
import BurgerMenuScreen from './Screen/BurgerMenuScreen';
import CaseSummaryData from './Screen/CaseSummaryData';
import ActionSummaryData from './Screen/ActionSummaryData';
import Result from './Screen/Result';
import PreoperativeEvaluation from './Screen/PreoperativeEvaluation';
import RiskEvalution from './Screen/RiskEvalution';
import Rcri from './Screen/Rcri';
import PhysicalExam from './Screen/PhysicalExam';
import Premedication from './Screen/Premedication';
import Note from './Screen/Note';
import Airway from './Screen/Airway';
import VascularAcess from './Screen/VascularAccess';
import AnesthesiaType from './Screen/AnesthesiaType';
import CaseSummaryRedux from './Screen/CaseSummaryRedux';
import Maintainces from './Screen/Maintainces'
import Induction from './Screen/Induction';
import Emergence from './Screen/Emergence';
import EmergenceAirway from './Screen/EmergenceAirway';
import PostopAirway from './Screen/PostopAirway';
import Crises from './Screen/Crises';
import AfterLamax from './Screen/AfterLamax';
import SelectConcentrations from './Screen/SelectConcentrations';
import PatientProfile from './Screen/PatientProfile';
import SelectComoribities from './Screen/SelectComoribities';
import MaxDoses from './Screen/MaxDoses';
import AfterMaxDoses from './Screen/AfterMaxDoses';
import CrisesSubtype from './Screen/CrisesSubtype';
import CaseTipSubInfo from './Screen/CaseTipSubInfo';
import DrugInfo from './Screen/DrugInfo';
import Bradycardia from './Screen/Bradycardia';
import SubRegionalAnethesia from './Screen/SubRegionalAnethesia';
import NewAfterLamax from './Screen/NewAfterLamax';
import NewAfterLamax2 from './Screen/NewAfterLamax2';
import Positioning from './Screen/Positioning';
import Monitoring from './Screen/Monitoring';
import SpecialMonitoring from './Screen/SpecialMonitoring';
import Checklists from './Screen/Checklists';
import CaseTips from './Screen/CaseTips';
import AfterPreop from './Screen/AfterPreop';
import Header from './CustomComponent/Header';
import AfterAirway from './Screen/AfterAirway';
import SecondAdmin from './Screen/SecondAdmin';
import Last from './Screen/Last';
import RsbiAirwayCalculator from './Screen/RsbiAirwayCalculator';
import FluidCalculator from './Screen/FluidsCalculator';
import SickleCellRBCExchangeVolume from './Screen/SickleCellRBCExchangeVolume';
import EGRIAirwayCalculator from './Screen/EGRIAirwayCalculator';
import MallClassAirwayCalculator from './Screen/MallClassAirwayCalculator';
import RoxIndexAirwayCalculator from './Screen/RoxIndexAirwayCalculator';
import EttTidalAirwayCalculator from './Screen/EttTidalAirwayCalculator';
import EttPedAirwayCalculator from './Screen/EttPedAirwayCalculator';
import FenaFluidCalculator from './Screen/FenaFluidCalculator';
import FeureaFluidCalculator from './Screen/FeureaFluidCalculator';
import StopBangAirwayCalculator from './Screen/STOPBANGAirwayCalculator';
import IfdaFluidCalculator from './Screen/IfdaFluidCalculator';
import FluidMaximunAbl from './Screen/FluidMaximunAbl';
import RbcFluidCalculator from './Screen/RbcFluidCalculator';
import FluidAbcScore from './Screen/FluidAbcScore';
import SearchResults from './Screen/SearchResults';
import AirwayEquipmentCalculators from './Screen/AirwayEquipmentCalculators';
import FluidsTransfusionsCalculators from './Screen/FluidsTransfusionsCalculators';
import ChangePassword from './Screen/ChangePassword';
import NeurologicalCalculators from './Screen/NeurologicalCalculators';
import CardiovascularCalculators from './Screen/CardiovascularCalculators';
import RenalCalculators from './Screen/RenalCalculators';
import GastrointestinalCalculators from './Screen/GastrointestinalCalculators';
import ReproductiveCalculators from './Screen/ReproductiveCalculators';
import HematologicCalculators from './Screen/HematologicCalculators';
import EndocrineCalculators from './Screen/EndocrineCalculators';
import PulmonaryCalculators from './Screen/PulmonaryCalculators';
import NutritionCalculators from './Screen/NutritionCalculators';
import MusculoskeletalCalculators from './Screen/Musculoskeletal';
import InfectiousDiseaseCalculators from './Screen/InfectiousDiseaseCalculators';
import RheumatologicCalculators from './Screen/RheumatologicCalculators';
import CaseLogs from './views/pages/caselogs/CaseLogs';
import Detailed from './views/pages/caselogs/Detailed';
import AppContext from './contextStore/DataProvider';
import Print from './views/pages/print/Print';
import ViewCaseLogs from './views/pages/viewCaseLogs/ViewCaseLogs';
import EditCaseLogs from './views/pages/editCaseLogs/EditCaseLogs';
import LandingPage from './views/pages/landingPage/LandingPage';
import DrugSearchResults from './components/drugs/drugSearchResults';
import DrugLink from './Screen/DrugLink';
import Export from './views/pages/caselogs/export';

function App() {

  const context = useContext(AppContext)

  return (
    <Provider store={store}>
      <BrowserRouter>
        {/* <PersistGate persistor={persistor}> */}
        {context.login ? <Header login={true} /> : <Header login={false} />}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/home" component={LandingPage} />
          <Route exact path="/forgotPassword" component={ForgotPassword} />
          <Route exact path="/termsandconditions" component={Tnc} />
          <Route exact path="/privacypolicy" component={PrivacyPolicy} />
          <Route exact path="/afterLogin" component={IndexAfterLogin} />
          <Route exact path="/favourite" component={Favorites} />
          <Route exact path="/actionLibrary" component={ActionLibrary} />
          <Route exact path="/startacase/casesummary" component={CaseSummaryRedux} />
          <Route exact path="/myprofile" component={MyProfile} />
          <Route exact path="/changepassword" component={Change_pass} />
          <Route exact path="/notification" component={Notification} />
          <Route exact path="/mysubcription" component={MySubcription} />
          <Route exact path="/editprofile" component={EditProfile} />
          <Route exact path="/subcription" component={Subcription} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/feedback" component={Feedback} />
          <Route exact path="/savecases" component={SaveCases} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/resultOfAction" component={ResultOfAction} />
          <Route exact path="/actionLibraryforactionsummary" component={AllActionForActionSummary} />
          <Route exact path="/startacase/actionsummary" component={ActionSummary} />
          <Route exact path="/recent" component={Recent} />
          <Route exact path="/start-a-case" component={CaseSummaryRedux} />
          <Route exact path="/case-logs" component={CaseLogs} />
          <Route exact path="/case-logs/view/:id" component={ViewCaseLogs} />
          <Route exact path="/case-logs/preview/:id" component={Print} />
          <Route exact path="/case-logs/edit/:id" component={EditCaseLogs} />
          <Route exact path="/export/:id" component={Export} />
          <Route exact path="/drawer" component={BurgerMenuScreen} />
          <Route exact path="/casesummarydata" component={CaseSummaryData} />
          <Route exact path="/actionummarydata" component={ActionSummaryData} />
          <Route exact path="/result" component={Result} />
          <Route exact path="/preoperativeevaluation" component={PreoperativeEvaluation} />
          <Route exact path="/riskevalution" component={RiskEvalution} />
          <Route exact path="/rcri" component={Rcri} />
          <Route exact path="/physicalexam" component={PhysicalExam} />
          <Route exact path="/premedication" component={Premedication} />
          <Route exact path="/note" component={Note} />
          <Route exact path="/airway" component={Airway} />
          <Route exact path="/vascularaccess" component={VascularAcess} />
          <Route exact path="/anesthesiatype" component={AnesthesiaType} />
          <Route exact path="/induction" component={Induction} />
          <Route exact path="/maintaince" component={Maintainces} />
          <Route exact path="/emergence" component={Emergence} />
          <Route exact path="/emergenceairway" component={EmergenceAirway} />
          <Route exact path="/postopairway" component={PostopAirway} />
          <Route exact path="/crises" component={Crises}></Route>
          <Route exact path="/afterlamax" component={AfterLamax}></Route>
          <Route exact path="/selecconcentrations" component={SelectConcentrations}></Route>
          <Route exact path="/patientprofile" component={PatientProfile}></Route>
          <Route exact path="/selectcomoribities" component={SelectComoribities}></Route>
          <Route exact path="/maxdoses" component={MaxDoses}></Route>
          <Route exact path="/aftermaxdoses" component={AfterMaxDoses}></Route>
          <Route exact path="/crisessubtype" component={CrisesSubtype} />
          <Route exact path="/casetipsubinfo" component={CaseTipSubInfo} />
          <Route exact path="/drugInfo" component={DrugInfo} />
          <Route exact path="/bradycardia" component={Bradycardia} />
          <Route exact path="/subRegionalAnesthesia" component={SubRegionalAnethesia} />
          <Route exact path="/newAfterLamax" component={NewAfterLamax} />
          <Route exact path="/newAfterLamax2" component={NewAfterLamax2} />
          <Route exact path="/secondAdmin" component={SecondAdmin} />
          <Route exact path="/positioning" component={Positioning} />
          <Route exact path="/monitoring" component={Monitoring} />
          <Route exact path="/specialmonitoring" component={SpecialMonitoring} />
          <Route exact path="/checklists" component={Checklists} />
          <Route exact path="/caseTips" component={CaseTips} />
          <Route exact path="/afterPreop" component={AfterPreop} />
          <Route exact path="/afterAirway" component={AfterAirway} />
          <Route exact path="/last:id" component={Last} />
          <Route exact path="/druglink:id" component={DrugLink} />
          <Route exact path="/rsbiairwaycalculator" component={RsbiAirwayCalculator} />
          <Route exact path="/sicklecellvolume" component={SickleCellRBCExchangeVolume} />
          <Route exact path="/fluidcalculator" component={FluidCalculator} />
          <Route exact path="/egriairwaycalculator" component={EGRIAirwayCalculator} />
          <Route exact path="/mallclassairwaycalculator" component={MallClassAirwayCalculator} />
          <Route exact path="/roxindexairwaycalculator" component={RoxIndexAirwayCalculator} />
          <Route exact path="/ettTidalairwaycalculator" component={EttTidalAirwayCalculator} />
          <Route exact path="/ettpedairwaycalculator" component={EttPedAirwayCalculator} />
          <Route exact path="/fenafluidcalculator" component={FenaFluidCalculator} />
          <Route exact path="/stopbangairwaycalculator" component={StopBangAirwayCalculator} />
          <Route exact path="/ifdafluidcalculator" component={IfdaFluidCalculator} />
          <Route exact path="/fluidmaximunabl" component={FluidMaximunAbl} />
          <Route exact path="/feureafluidcalculator" component={FeureaFluidCalculator} />
          <Route exact path="/rbcfluidcalculator" component={RbcFluidCalculator} />
          <Route exact path="/fluidAbcScore" component={FluidAbcScore} />
          <Route exact path="/searchresults" component={SearchResults} />
          <Route exact path="/airwayequipmentcalculators" component={AirwayEquipmentCalculators} />
          <Route exact path="/fluidstransfusionscalculators" component={FluidsTransfusionsCalculators} />
          <Route exact path="/reqchangepassword" component={ChangePassword} />
          <Route exact path="/neurologicalcalculators" component={NeurologicalCalculators} />
          <Route exact path="/cardiovascularcalculators" component={CardiovascularCalculators} />
          <Route exact path="/renalcalculators" component={RenalCalculators} />
          <Route exact path="/gastrointestinalcalculators" component={GastrointestinalCalculators} />
          <Route exact path="/reproductivecalculators" component={ReproductiveCalculators} />
          <Route exact path="/hematologiccalculators" component={HematologicCalculators} />
          <Route exact path="/endocrinecalculators" component={EndocrineCalculators} />
          <Route exact path="/pulmonaryCalculators" component={PulmonaryCalculators} />
          <Route exact path="/nutritionCalculators" component={NutritionCalculators} />
          <Route exact path="/musculoskeletalCalculators" component={MusculoskeletalCalculators} />
          <Route exact path="/infectiousdiseasecalculators" component={InfectiousDiseaseCalculators} />
          <Route exact path="/rheumatologiccalculators" component={RheumatologicCalculators} />
          <Route path="/case-logs/detailed" component={Detailed} />
          <Route exact path="/rsbiairwaycalculator" component={RsbiAirwayCalculator} />
          <Route exact path="/sicklecellvolume" component={SickleCellRBCExchangeVolume} />
          <Route exact path="/fluidcalculator" component={FluidCalculator} />
          <Route exact path="/egriairwaycalculator" component={EGRIAirwayCalculator} />
          <Route exact path="/mallclassairwaycalculator" component={MallClassAirwayCalculator} />
          <Route exact path="/roxindexairwaycalculator" component={RoxIndexAirwayCalculator} />
          <Route exact path="/ettTidalairwaycalculator" component={EttTidalAirwayCalculator} />
          <Route exact path="/ettpedairwaycalculator" component={EttPedAirwayCalculator} />
          <Route exact path="/fenafluidcalculator" component={FenaFluidCalculator} />
          <Route exact path="/stopbangairwaycalculator" component={StopBangAirwayCalculator} />
          <Route exact path="/ifdafluidcalculator" component={IfdaFluidCalculator} />
          <Route exact path="/fluidmaximunabl" component={FluidMaximunAbl} />
          <Route exact path="/feureafluidcalculator" component={FeureaFluidCalculator} />
          <Route exact path="/rbcfluidcalculator" component={RbcFluidCalculator} />
          <Route exact path="/fluidAbcScore" component={FluidAbcScore} />
          <Route exact path="/searchresults" component={SearchResults} />
          <Route exact path="/airwayequipmentcalculators" component={AirwayEquipmentCalculators} />
          <Route exact path="/fluidstransfusionscalculators" component={FluidsTransfusionsCalculators} />
          <Route exact path="/reqchangepassword" component={ChangePassword} />
          <Route exact path="/neurologicalcalculators" component={NeurologicalCalculators} />
          <Route exact path="/cardiovascularcalculators" component={CardiovascularCalculators} />
          <Route exact path="/renalcalculators" component={RenalCalculators} />
          <Route exact path="/gastrointestinalcalculators" component={GastrointestinalCalculators} />
          <Route exact path="/reproductivecalculators" component={ReproductiveCalculators} />
          <Route exact path="/hematologiccalculators" component={HematologicCalculators} />
          <Route exact path="/endocrinecalculators" component={EndocrineCalculators} />
          <Route exact path="/pulmonaryCalculators" component={PulmonaryCalculators} />
          <Route exact path="/nutritionCalculators" component={NutritionCalculators} />
          <Route exact path="/musculoskeletalCalculators" component={MusculoskeletalCalculators} />
          <Route exact path="/infectiousdiseasecalculators" component={InfectiousDiseaseCalculators} />
          <Route exact path="/rheumatologiccalculators" component={RheumatologicCalculators} />
          <Route exact path="/drugsearchresults" component={DrugSearchResults}/>
        </Switch>

        {/* </PersistGate> */}
      </BrowserRouter>
    </Provider>
  );
}

export default App;
