import React, { useEffect, useState } from 'react';
import './RE1217.css';

const RE1217 = () => {
    const [green, setGreen] = useState(false);
    const [age, setAge] = useState(0);
    const [eye, setEye] = useState(0);
    const [verbal, setVerbal] = useState(0);
    const [motor, setMotor] = useState(0);
    const [result, setResult] = useState(0);

    const postData = async () => {

        if (eye === "NT" || verbal === "NT" || motor === "NT") {
            setResult(500);
        }
        else {
            setResult(eye + verbal + motor);
        }

    }
    useEffect(() => {
        postData();


    }, [eye, verbal, motor, result]);


    const resultVal = () => {
        if (result == 500) {
            return <div>Glasgow Coma Score cannot be calculated (one or more components not testable)</div>
        }
        else if (result != 0) {
            return <>
                <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                <div>Pediatric Glasgow Coma Score</div>
            </>
        }
    }


    const afterage1 = () => {
        if (age == 1) {
            return <div>
                <div className="fields row">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Eye Opening</strong></div>


                    </div>
                    <div className="col-md-7 btn-group-vertical options-btn mb-2">
                        <button style={{ height:  40   }} onClick={() => setEye(4)} className={eye === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Open spontaneously </span><span className="calc-val ">+4</span></button>
                        <button style={{ height:  40   }} onClick={() => setEye(3)} className={eye === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Open to verbal stimuli </span><span className="calc-val ">+3</span></button>
                        <button style={{ height:  40   }} onClick={() => setEye(2)} className={eye === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Open to pain only</span><span className="calc-val ">+2</span></button>
                        <button style={{ height:  40   }} onClick={() => setEye(1)} className={eye === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">No response</span><span className="calc-val ">+1</span></button>


                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Verbal Response</strong></div>


                    </div>
                    <div className="col-md-7 btn-group-vertical options-btn mb-2 ">
                        <button style={{ height:  40   }} onClick={() => setVerbal(5)} className={verbal === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Coos, babbles</span><span className="calc-val ">+5</span></button>
                        <button style={{ height:  40   }} onClick={() => setVerbal(4)} className={verbal === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Irritable cries </span><span className="calc-val ">+4</span></button>
                        <button style={{ height:  40   }} onClick={() => setVerbal(3)} className={verbal === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Cries in response to pain</span><span className="calc-val ">+3</span></button>
                        <button style={{ height:  40   }} onClick={() => setVerbal(2)} className={verbal === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moans in response to pain </span><span className="calc-val ">+2</span></button>
                        <button style={{ height:  40   }} onClick={() => setVerbal(1)} className={verbal === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">No response</span><span className="calc-val ">+1</span></button>

                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Motor Response</strong></div>


                    </div>
                    <div className="col-md-7 btn-group-vertical options-btn mb-4">
                        <button style={{ height:  40   }} onClick={() => setMotor(6)} className={motor === 6 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moves spontaneously/purposefully</span><span className="calc-val ">+6</span></button>
                        <button style={{ height:  40   }} onClick={() => setMotor(5)} className={motor === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Withdraws to touch</span><span className="calc-val ">+5</span></button>
                        <button style={{ height:  40   }} onClick={() => setMotor(4)} className={motor === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Withdrawal to pain </span><span className="calc-val ">+4</span></button>
                        <button style={{ height:  40   }} onClick={() => setMotor(3)} className={motor === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Flexor posturing to pain</span><span className="calc-val ">+3</span></button>
                        <button style={{ height:  40   }} onClick={() => setMotor(2)} className={motor === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Extensor posturing to pain </span><span className="calc-val ">+2</span></button>
                        <button style={{ height:  40   }} onClick={() => setMotor(1)} className={motor === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">No response </span><span className="calc-val ">+1</span></button>

                    </div>
                </div>

            </div>
        }
    }
    const afterage2 = () => {
        if (age == 2) {
            return <div>
                <div className="fields row">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Eye Opening</strong></div>


                    </div>
                    <div className="col-md-7 btn-group-vertical options-btn mb-2">
                        <button style={{ height:  40   }} onClick={() => setEye(4)} className={eye === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Open spontaneously (+4)</span></button>
                        <button style={{ height:  40   }} onClick={() => setEye(3)} className={eye === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Open to verbal stimuli (+3) (+3)</span></button>
                        <button style={{ height:  40   }} onClick={() => setEye(2)} className={eye === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Open to pain only (+2)</span></button>
                        <button style={{ height:  40   }} onClick={() => setEye(1)} className={eye === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">No response (+1)</span></button>
                        <button style={{ height:  40   }} onClick={() => setEye("NT")} className={eye === "NT" ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Not testable due to trauma, edema, etc (NT)</span></button>

                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Verbal Response</strong></div>


                    </div>
                    <div className="col-md-7 btn-group-vertical options-btn mb-2 ">
                        <button style={{ height:  40   }} onClick={() => setVerbal(5)} className={verbal === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Oriented/appropriate (+5)</span></button>
                        <button style={{ height:  40   }} onClick={() => setVerbal(4)} className={verbal === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Confused (+4)</span></button>
                        <button style={{ height:  40   }} onClick={() => setVerbal(3)} className={verbal === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Inappropriate words (+3)</span></button>
                        <button style={{ height:  40   }} onClick={() => setVerbal(2)} className={verbal === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Incomprehensible words/sounds (+2)</span></button>
                        <button style={{ height:  40   }} onClick={() => setVerbal(1)} className={verbal === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">No response (+1)</span></button>
                        <button style={{ height:  40   }} onClick={() => setVerbal("NT")} className={verbal === "NT" ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Not testable because intubated, etc (NT)</span></button>
                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Motor Response</strong></div>


                    </div>
                    <div className="col-md-7 btn-group-vertical options-btn mb-4">
                        <button style={{ height:  40   }} onClick={() => setMotor(6)} className={motor === 6 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Obeys commands (+6)</span></button>
                        <button style={{ height:  40   }} onClick={() => setMotor(5)} className={motor === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Localizes to pain (+5)</span></button>
                        <button style={{ height:  40   }} onClick={() => setMotor(4)} className={motor === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Withdrawal to pain (+4)</span></button>
                        <button style={{ height:  40   }} onClick={() => setMotor(3)} className={motor === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Flexor posturing to pain (+3)</span></button>
                        <button style={{ height:  40   }} onClick={() => setMotor(2)} className={motor === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Extensor posturing to pain (+2)</span></button>
                        <button style={{ height:  40   }} onClick={() => setMotor(1)} className={motor === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">No response (+1)</span></button>
                        <button style={{ height:  40   }} onClick={() => setMotor("NT")} className={motor === "NT" ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Not testable because sedation/paralysis, etc (NT)</span></button>
                    </div>
                </div>
            </div>
        }
    }


    return (
        <div className="rev">
            <h5 className="text-info pl-2">Pediatric Glasgow Coma Scale (pGCS)</h5>
            <p className="pl-2">Assesses impaired consciousness and coma in pediatric patients.</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Age</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  40   }} onClick={() => setAge(1)} className={age === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name "> ≤2 years</span></button>
                    <button style={{ height:  40   }} onClick={() => setAge(2)} className={age === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name "> &gt;2 years</span></button>

                </div>

            </div>
            <div>{afterage1()}</div>
            <div> {afterage2()}</div>


            <div className="stick">
                {
                    result != 0  && eye!=0 && motor!=0 && verbal!=0? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container" >
                                {resultVal()}

                            </div>
                            <div className="point-result-container" >
                                <h1>E&#40;{eye}&#41; 	V&#40;{verbal}&#41; 	M&#40;{motor}&#41; </h1>
                                <div>Pediatric Glasgow Coma Scale</div>

                            </div>
                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/3951884/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >James HE. Neurologic Evaluation and Support in the Child with an Acute Brain Insult. Pediatric Annals Pediatr Ann, 1986; 15(1), 16-22.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}


export default RE1217;

