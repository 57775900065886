import React, { useEffect, useState } from 'react';
import './RE1266.css';

const RE1266 = () => {
    const [green, setGreen] = useState(false);

    const [thrombocytopenia, setThrombocytopenia] = useState(0);
    const [timing, setTiming] = useState(0);
    const [thrombosis, setThrombisis] = useState(0);
    const [otherCauses, setOtherCauses] = useState(0);
    const [result, setResult] = useState(0);
    const postData = async () => {

        setResult(thrombocytopenia + timing + thrombosis + otherCauses);
        setGreen(true);
    }

    useEffect(() => {
        postData();


    }, [thrombocytopenia, timing, thrombosis, otherCauses, result]);


    const resultVal = () => {
        if (result == 500) {
            return <div>Glasgow Coma Score cannot be calculated (one or more components not testable)</div>
        }
        else if (result != 0) {
            return <>
                <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                <div>Glasgow Coma Score</div>
            </>
        }
    }

    return (
        <div className="rev">
              <h5 className="text-info pl-2">4Ts Score for Heparin-Induced Thrombocytopenia</h5>
            <p className="pl-2">Differentiates patients with HIT from those with other causes of thrombocytopenia.</p>
  
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Thrombocytopenia</strong></div>


                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">

                    <button style={{ height:  60   }} onClick={() => setThrombocytopenia(2)} className={thrombocytopenia === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Platelet count fall &gt;
                        &gt;50% AND <br/>platelet nadir ≥20</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  60   }} onClick={() => setThrombocytopenia(1)} className={thrombocytopenia === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Platelet count fall 30–50% OR <br/>platelet nadir 10–19</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  60   }} onClick={() => setThrombocytopenia(0)} className={thrombocytopenia === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Platelet count fall &lt;30% OR<br/> platelet nadir &lt;10</span><span className="calc-val ">0</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Timing of platelet count fall</strong></div>


                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">

                <button style={{ height:  80   }} onClick={() => setTiming(2)} className={timing === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Clear onset between days 5–10 OR<br/> platelet fall ≤1 day (prior heparin <br/>exposure within 30 days)</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  100   }} onClick={() => setTiming(1)} className={timing === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Consistent with days 5–10 fall, but not clear;<br/> onset after day 10 OR fall ≤1 day<br/> (prior heparin exposure 30–100 days <br/>ago)</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  60   }} onClick={() => setTiming(0)} className={timing === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Platelet count fall &lt;4 days<br/> without recent exposure</span><span className="calc-val ">0</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Thrombosis or other sequelae</strong></div>


                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">

                    <button  style={{ height:  60   }} onClick={() => setThrombisis(2)} className={thrombosis === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">
                        New thrombosis OR skin necrosis; acute<br/> systemic reaction post-IV heparin bolus</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  80   }} onClick={() => setThrombisis(1)} className={thrombosis === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Progressive OR recurrent thrombosis; <br/>non-necrotizing skin lesions; suspected<br/> thrombosis (not proven)</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  40   }} onClick={() => setThrombisis(0)} className={thrombosis === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">None</span><span className="calc-val ">0</span></button>

                </div>
            </div>
            <div className="fields row mb-5">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Other causes for thrombocytopenia</strong></div>


                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">

                    <button style={{ height:  40   }} onClick={() => setOtherCauses(2)} className={otherCauses === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">None apparent</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40   }} onClick={() => setOtherCauses(1)} className={otherCauses === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Possible</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  40   }} onClick={() => setOtherCauses(0)} className={otherCauses === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Definite</span><span className="calc-val ">0</span></button>

                </div>
            </div>



            <div className="stick">
                {
                    green ? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container" >
                                <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                                <div>{result < 4 ? "Low Probability of HIT (<5%) " : " "}</div>
                                <div>{result == 4 || result == 5   ? "Intermediate Probability of HIT (~14%)" : " "}</div>
                                <div>{result > 5 ? "High Probability of HIT (~64%)" : " "}</div>
                            </div>

                        </div>



                    </>

                        :
                     <></>

                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/16634744/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Lo GK, Juhl D, Warkentin TE, Sigouin CS, Eichler P, Greinacher A. Evaluation of pretest clinical score (4 Ts) for the diagnosis of heparin-induced thrombocytopenia in two clinical settings. J Thromb Haemost 2006; 4: 759–65.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}


export default RE1266;

