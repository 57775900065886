export const SET_MASK_2 = "SET_MASK_2"
export const SET_LMA_2 = "SET_LMA_2"
export const SET_INTUBATION_2 = "SET_INTUBATION_2"
export const SET_SUB_INTUBATION_2 = "SET_SUB_INTUBATION_2"
export const SET_LUNG_INTUBATION_2 = "SET_LUNG_INTUBATION_2"
export const SET_LUNG_SUB_INTUBATION_2 = "SET_LUNG_SUB_INTUBATION_2"
export const SET_LUNG_SUB_SUB_INTUBATION_2 = "SET_LUNG_SUB_SUB_INTUBATION_2"
export const SET_LARYNGOSCOPE_MAIN_2 = "SET_LARYNGOSCOPE_MAIN_2"
export const SET_LARYNGOSCOPE_SUB_TYPE_2 = "SET_LARYNGOSCOPE_SUB_TYPE_2"
export const SET_LARYNGOSCOPE_SUB_SUB_TYPE_2 = "SET_LARYNGOSCOPE_SUB_SUB_TYPE_2"
export const SET_AIRWAY_ADJUNCT_2 = "SET_AIRWAY_ADJUNCT_2"
export const SET_AIRWAY_ADJUNCT_TYPE_2 = "SET_AIRWAY_ADJUNCT_TYPE_2"
export const SET_AIRWAY_OTHER_2 = "SET_AIRWAY_OTHER_2"
export const SET_AIRWAY_CALCULATORS_2 = "SET_AIRWAY_CALCULATORS_2"
export const SET_AIRWAY_NOTES_2 = "SET_AIRWAY_NOTES_2"