import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';

const DialysisRiskClinic = () => {
    const [green, setGreen] = useState(false);



    const [gender, setGender] = useState(0);
    const [heartFailure, setheartFailure] = useState(0);
    const [ejection, setEjection] = useState(0);
    const [preoperative, setPreoperative] = useState(0);
    const [copd, setCopd] = useState(0);
    const [diabetes, setDiabetes] = useState(0);
    const [pastCardiacSurgery, setPastCardiacSurgery] = useState(0);
    const [ckd, setCkd] = useState(0);
    const [emergency, setEmergency] = useState(0);
    const [typeOfSurgery, setTypeOfSurgery] = useState(0);
    const [typeOfSurgery1, setTypeOfSurgery1] = useState(0);
    const [result, setResult] = useState(0);

    const postData = async () => {
        if (typeOfSurgery == 4) {
            setTypeOfSurgery1(2);
        }
        if (typeOfSurgery == 0) {
            setTypeOfSurgery1(0);
        }
        if (typeOfSurgery == 1) {
            setTypeOfSurgery1(1);
        }
        if (typeOfSurgery == 2) {
            setTypeOfSurgery1(2);
        }
        setResult(gender + heartFailure + ejection + preoperative + copd + diabetes + pastCardiacSurgery + ckd + emergency + typeOfSurgery1);
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [gender, heartFailure, ejection, preoperative, copd, diabetes, pastCardiacSurgery, ckd, emergency, typeOfSurgery, typeOfSurgery1, result]);

    return (
        <div className="rev">
            <h5 className="text-info pl-2">Dialysis Risk After Cardiac Surgery (Cleveland Clinic Score by Thakar)</h5>
            <p className="pl-2">Estimate risk of dialysis after cardiac surgery.</p>

            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Gender</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left" >
                    <button style={{ height: 40 }} className={gender === 0 ? "btn active" : "btn"} onClick={() => setGender(0)}>Male </button>
                    <button style={{ height: 40 }} className={gender === 1 ? "btn active" : "btn"} onClick={() => setGender(1)}>Female</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Heart Failure?</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={heartFailure === 0 ? "btn active" : "btn"} onClick={() => setheartFailure(0)}>No</button>
                    <button style={{ height: 40 }} className={heartFailure === 1 ? "btn active" : "btn"} onClick={() => setheartFailure(1)}>Yes</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Ejection Fraction &lt;35%?</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={ejection === 0 ? "btn active" : "btn"} onClick={() => setEjection(0)}>No</button>
                    <button style={{ height: 40 }} className={ejection === 1 ? "btn active" : "btn"} onClick={() => setEjection(1)}>Yes</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong> Preoperative Use of IABP?</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={preoperative === 0 ? "btn active" : "btn"} onClick={() => setPreoperative(0)}>No</button>
                    <button style={{ height: 40 }} className={preoperative === 2 ? "btn active" : "btn"} onClick={() => setPreoperative(2)}>Yes</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong> COPD Requiring Medical Therapy?</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={copd === 0 ? "btn active" : "btn"} onClick={() => setCopd(0)}>No</button>
                    <button style={{ height: 40 }} className={copd === 1 ? "btn active" : "btn"} onClick={() => setCopd(1)}>Yes</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Diabetes Requiring Insulin?</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={diabetes === 0 ? "btn active" : "btn"} onClick={() => setDiabetes(0)}>No</button>
                    <button style={{ height: 40 }} className={diabetes === 1 ? "btn active" : "btn"} onClick={() => setDiabetes(1)}>Yes</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Past Cardiac Surgery?</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={pastCardiacSurgery === 0 ? "btn active" : "btn"} onClick={() => setPastCardiacSurgery(0)}>No</button>
                    <button style={{ height: 40 }} className={pastCardiacSurgery === 1 ? "btn active" : "btn"} onClick={() => setPastCardiacSurgery(1)}>Yes</button>

                </div>
            </div>
            <div className="fields row ">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Preoperative CKD: Creatinine?</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn  ">
                    <button onClick={() => setCkd(0)} className={ckd === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name "> &lt;106 µmol/L <span className="small"> (&lt;1.2 mg/dL)</span></span></button>
                    <button onClick={() => setCkd(2)} className={ckd === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name "> 106 - &lt;186 µmol/L <span className="small"> (1.2 - &lt;2.1 mg/dL)</span></span></button>
                    <button onClick={() => setCkd(5)} className={ckd === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name "> ≥186 µmol/L <span className="small"> (≥2.1 mg/dL)</span></span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Emergency Surgery?</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={emergency === 0 ? "btn active" : "btn"} onClick={() => setEmergency(0)}>No</button>
                    <button style={{ height: 40 }} className={emergency === 2 ? "btn active" : "btn"} onClick={() => setEmergency(2)}>Yes</button>

                </div>
            </div>
            <div className="fields row mb-4">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Type of Surgery?</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn  ">
                    <button onClick={() => setTypeOfSurgery(0)} className={typeOfSurgery === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name "> CABG Only</span></button>
                    <button onClick={() => setTypeOfSurgery(1)} className={typeOfSurgery === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Valve Surgery Only</span></button>
                    <button onClick={() => setTypeOfSurgery(2)} className={typeOfSurgery === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">CABG + Valve</span></button>
                    <button onClick={() => setTypeOfSurgery(4)} className={typeOfSurgery === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Other Cardiac Surgeries</span></button>

                </div>

            </div>
            <br />
            <div className="stick">
                {
                    green ? <>
                        <div className="apfel-score-result-container col-12 " >
                            <div className="point-result-container col-12"  >



                                <h5>{result < 3 && result >= 0 ? "Risk of Dialysis Post Cardiac Surgery : 0.4%" : " "}</h5>

                                <h5>{result > 2 && result < 6 ? "Risk of Dialysis Post Cardiac Surgery: 1.8%" : " "}</h5>
                                <h5>{result > 5 && result < 9 ? "Risk of Dialysis Post Cardiac Surgery: 7.8-9.5%" : " "}</h5>
                                <h5>{result > 8 && result < 30 ? "Risk of Dialysis Post Cardiac Surgery: 21.5%" : " "}</h5>





                            </div>






                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>

            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://read.qxmd.com/read/15563569/a-clinical-score-to-predict-acute-renal-failure-after-cardiac-surgery">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Thakar, CV et al.A clinical score to predict acute renal failure after cardiac surgery.Journal of the American Society of Nephrology: JASN 2005, 16 (1): 162-8</p>
                    </div>
                </a>
            </div>

            <div>
                <a className="referenceLink" target="_blank" href="https://read.qxmd.com/read/23222415/comparison-and-clinical-suitability-of-eight-prediction-models-for-cardiac-surgery-related-acute-kidney-injury">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Kiers HD et al.Comparison and clinical suitability of eight prediction models for cardiac surgery-related acute kidney injury.Nephrology, Dialysis, Transplantation 2013, 28 (2): 345-51</p>
                    </div>
                </a>
            </div>

        </div>
    )
}


export default DialysisRiskClinic;

