import React from 'react'
import Modal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux';
import '../css/savecasename.css'
import { setFileName } from '../redux/caseSummaryActions';
const SaveCaseNameModal = (props) => {
  const agee = useSelector((state) => state.casesummary.agee);
  const ageeType = useSelector((state) => state.casesummary?.ageeType);
  const genderType = useSelector((state) => state.casesummary?.genderType);
  const surgeryName = useSelector((state) => state.casesummary?.surgeryName);
  const subSurgeryName = useSelector((state) => state.casesummary?.subSurgeryName);
  const categorySurgeryName = useSelector((state) => state.casesummary?.categorySurgeryName);

  const customStyles = {
    content: {
      top: '25%',
      left: '37%',
      right: 'auto',
      bottom: 'auto',
      // marginRight           : '-50%',
      backgroundColor: "#000",
      backgroundSize: '30%',
      // backgroundImage: "linear-gradient(180deg,#3F2579,#fff 20%)",
      width: 340,
      height: 210,
      border: '1px solid orange',
      zIndex: 99
    }
  };

  const dispatch = useDispatch()
  var surgery_Name = subSurgeryName ? subSurgeryName.name.replaceAll(" ", "-") : categorySurgeryName ? categorySurgeryName.name.replaceAll(" ", "-") : surgeryName.name
  Modal.setAppElement('body');
  var date = new Date().toISOString().slice(0, 10).replaceAll("/", "-")
  var date2 = String(date) + "_" + String(agee === undefined ? "" : agee) + "_" + (ageeType.label === undefined ? "" : ageeType.label) + "_" + (genderType.value === undefined ? "" : genderType.value) + "_" + (surgery_Name === undefined ? "" : surgery_Name)
  dispatch(setFileName(date2))
  return (
    <div>
      <Modal
        isOpen={props.saveVisible}
        onRequestClose={props.onClose}
        style={customStyles}
        contentLabel="Case Name Entry Modal"
      >

        <div className="savecasemodal-container" >
          <span onClick={() => props.closeNameModal()} className="close-popup"><i className="fa fa-close close-btn"></i></span>
          <div className="form-group">
            <label className="caseLabel">Enter Case Name</label>
            <input defaultValue={date2} onChange={(e) => props.onCaseNameChange(e.target.value)} placeholder="APPENDECTOMY_12-19-2020_18YO_MALE" type="text" className="save-case-name-input ant-text-input" />
          </div>
          <hr />
          <div className="action-button-container mt-2">
            <div onClick={() => { props.postRequest(); }} className="save-button-container" style={{margin: '0 auto'}} >
              Save
            </div>
{/* 
            <div onClick={() => props.closeNameModal()} className="cancel-button" >
              Cancel
            </div> */}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SaveCaseNameModal
