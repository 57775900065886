import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';
import { Link } from 'react-router-dom';
import ARISTOCAT from './ARISCAT';
import DRIP from './DRIP';
import GuptaPneumoniaRisk from './GuptaPneumoniaRisk';
import GuptaFailureRisk from './GuptaFailureRisk';
import AaO2Gradient from './AaO2Gradient';
import EGRIAirwayCalculator from './EGRIAirwayCalculator';
import EttTidalAirwayCalculator from './EttTidalAirwayCalculator';
import EttPedAirwayCalculator from './EttPedAirwayCalculator';
import HorowitzIndexAirwayCalculator from './HorowitzIndexAirwayCalculator';
import MallClassAirwayCalculator from './MallClassAirwayCalculator';
import RoxIndexAirwayCalculator from './RoxIndexAirwayCalculator';
import RsbiAirwayCalculator from './RsbiAirwayCalculator';
import StopBangAirwayCalculator from './STOPBANGAirwayCalculator';
import UpperLipBiteTestAirwayCalculator from './UpperLipBiteTestAirwayCalculator';
import BerlinForARDS from './BerlinForARDS';
const PulmonaryCalculators = () => {
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };
    useEffect(() => {
        toggleTab(1);
    }, [])
    return (
        <div>
            <div className="backNavigation text-center">
                <Link className="arrow" to="/actionLibrary"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Action Library</span></span></Link> PULMONARY CALCULATORS
            </div>
            <div className="main-container calculator-container mt-3">
                <div className="row">
                    <div className="col-md-4 airway-calc-tabs">
                    <button className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(1)} >A-a O2 Gradient</button>
                        <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(2)} >ARISTOCAT</button>
                        <button className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(3)} >Berlin Criteria for ARDS</button>
                        <button className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(4)} >DRIP</button>
                        <button className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(5)} >EGRI</button> 
                        <button className={toggleState === 6 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(6)} >ETT Depth (Adult) and Tidal Volume</button>   
                        <button className={toggleState === 7 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(7)} >ETT Dept (Pediatric)</button> 
                        <button className={toggleState === 8 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(8)} >Gupta Postoperative Pneumomia Risk</button>  
                        <button className={toggleState === 9 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(9)} >Gupta Postoperative Respiratory Failure Risk</button>
                        <button className={toggleState === 10 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(10)} >Horowitz Index</button>
                        <button className={toggleState === 11 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(11)} >Modified Mallampati Classification</button>  
                        <button className={toggleState === 12 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(12)} >ROX Index</button>  
                        <button className={toggleState === 13 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(13)} >RSBI</button>  
                        <button className={toggleState === 14 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(14)} >STOP-BANG</button>
                        <button className={toggleState === 15 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(15)} >Upper Lip Bite Test</button>                                      
                    </div>
                    <div className="col-md-8 col-sm-12">
                        <div className="content-tabs">
                            <div
                                className={toggleState === 1 ? "calculator-content  active-content" : "calculator-content"}>
                             <AaO2Gradient/>
                            </div>
                            <div className={toggleState === 2 ? "calculator-content  active-content" : "calculator-content"}>
                            <ARISTOCAT/>
                            </div>

                            <div
                                className={toggleState === 3 ? "calculator-content  active-content" : "calculator-content"} >
                              <BerlinForARDS/>
                            </div>

                            <div
                                className={toggleState === 4 ? "calculator-content  active-content" : "calculator-content"}>
                               <DRIP/>
                            </div>
                            <div
                                className={toggleState === 5 ? "calculator-content  active-content" : "calculator-content"}>
                             <EGRIAirwayCalculator/>
                            </div>
                            <div className={toggleState === 6 ? "calculator-content  active-content" : "calculator-content"}>
                            <EttTidalAirwayCalculator/>
                            </div>

                            <div
                                className={toggleState === 7 ? "calculator-content  active-content" : "calculator-content"} >
                              <EttPedAirwayCalculator/>
                            </div>
                            
                            <div
                                className={toggleState === 8 ? "calculator-content  active-content" : "calculator-content"}>
                             <GuptaFailureRisk/>
                            </div>
                            <div className={toggleState === 9 ? "calculator-content  active-content" : "calculator-content"}>
                            <GuptaPneumoniaRisk/>
                            </div>

                            <div
                                className={toggleState === 10 ? "calculator-content  active-content" : "calculator-content"} >
                              <HorowitzIndexAirwayCalculator/>
                            </div>

                            <div
                                className={toggleState === 11 ? "calculator-content  active-content" : "calculator-content"}>
                               <MallClassAirwayCalculator/>
                            </div>
                            <div
                                className={toggleState === 12 ? "calculator-content  active-content" : "calculator-content"}>
                             <RoxIndexAirwayCalculator/>
                            </div>
                            <div className={toggleState === 13 ? "calculator-content  active-content" : "calculator-content"}>
                            <RsbiAirwayCalculator/>
                            </div>

                            <div
                                className={toggleState === 14 ? "calculator-content  active-content" : "calculator-content"} >
                              <StopBangAirwayCalculator/>
                            </div>

                            <div
                                className={toggleState === 15 ? "calculator-content  active-content" : "calculator-content"}>
                               <UpperLipBiteTestAirwayCalculator/>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
 
}
export default PulmonaryCalculators;