export const PREOP_CLICK = "PREOP_CLICK"
export const POSTOP_CLICK = "POSTOP_CLICK"
export const INTRAOP_CLICK = "INTRAOP_CLICK"
export const SET_ACTION_LIBRARY_DATA = "SET_ACTION_LIBRARY_DATA"
export const SET_SEARCH_DRUG_DATA = "SET_SEARCH_DRUG_DATA"
export const SET_ISSUB = "SET_ISSUB"
export const SET_SUB_NAME = "SET_SUB_NAME"
export const SET_SUB_ID = "SET_SUB_ID"
export const SET_SUB_CATEGORY = "SET_SUB_CATEGORY"
export const SET_SUB_SUB_CATEGORY = "SET_SUB_SUB_CATEGORY"
export const SET_SUB_SUB = "SET_SUB_SUB"
export const SET_SUB_SUB_SUB_CATEGORY = "SET_SUB_SUB_SUB_CATEGORY"
export const SET_SUB_SUB_ID = "SET_SUB_SUB_ID"
export const SET_SUB_SUB_SUB = "SET_SUB_SUB_SUB"
export const SET_SUB4_CATEGORY = "SET_SUB4_CATEGORY"
export const SET_SUB4 = "SET_SUB4"
export const SET_SUB4_ID = "SET_SUB4_ID"
export const SET_DRUG_LIST = "SET_DRUG_LIST"
export const SET_LEVEL_1  = "SET_LEVEL_1"
export const SET_LEVEL_2  = "SET_LEVEL_2"
export const SET_SUB_CRISES = "SET_SUB_CRISES"
