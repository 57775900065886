import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';


const DasiCalculator = () => {
    const [take, setTake] = useState(2.75);
    const [walk, setWalk] = useState(1.75);
    const [block, setBlock] = useState(2.75);
    const [climb, setClimb] = useState(5.5);
    const [run, setRun] = useState(8);
    const [light, setLight] = useState(2.7);
    const [moderate, setModerate] = useState(3.5);
    const [heavy, setHeavy] = useState(8);
    const [yardwork, setYardwork] = useState(4.5);
    const [sexual, setSexual] = useState(5.25);
    const [participate, setParticipate] = useState(6);
    const [sports, setSports] = useState(7.5);


    const[result,setResult]= useState(0);
    const[vo,setVo] = useState(0);

    const postData = async () => {
      
      setResult((take+walk+block+climb+run+light+moderate+heavy+ yardwork+sexual+participate+sports).toFixed(2))
      setVo(((0.43*(take+walk+block+climb+run+light+moderate+heavy+ yardwork+sexual+participate+sports )+9.6)/3.5).toFixed(2))

    }
    useEffect(() => {
        postData();


    }, [take,walk,block,climb,run,light,moderate,heavy,yardwork,sexual,participate,sports]);

    return (
        <div className="rev">
            <h5 className="text-info pl-2">Duke Activity Status Index (DASI)</h5>
            <p className="pl-2">Estimates functional capacity..</p>
            <div class="alert alert-dark" role="alert">
                Is the patient able to:
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Take care of self</strong></div>
                    <div>e.g. eating, dressing, bathing, using the toilet</div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={take === 0 ? "btn active" : "btn"} onClick={() => setTake(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={take === 2.75 ? "btn active" : "btn"} onClick={() => setTake(2.75)}>Yes<span className="ml-4 text-muted">+2.75</span></button>
                </div>
               
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Walk indoors</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={walk === 0 ? "btn active" : "btn"} onClick={() => setWalk(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={walk === 1.75 ? "btn active" : "btn"} onClick={() => setWalk(1.75)}>Yes<span className="ml-4 text-muted">+1.75</span></button>
                </div>
              
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Walk 1–2 blocks on level ground</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={block === 0 ? "btn active" : "btn"} onClick={() => setBlock(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={block === 2.75 ? "btn active" : "btn"} onClick={() => setBlock(2.75)}>Yes<span className="ml-4 text-muted">+2.75</span></button>
                </div>
              
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Climb a flight of stairs or walk up a hill
                    </strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={climb === 0 ? "btn active" : "btn"} onClick={() => setClimb(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={climb === 5.5 ? "btn active" : "btn"} onClick={() => setClimb(5.5)}>Yes<span className="ml-4 text-muted">+5.5</span></button>
                </div>
             
            </div>

            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Run a short distance</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={run === 0 ? "btn active" : "btn"} onClick={() => setRun(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={run === 8 ? "btn active" : "btn"} onClick={() => setRun(8)}>Yes<span className="ml-4 text-muted">+8</span></button>
                </div>
              
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Do light work around the house</strong></div>
                    <div>e.g. dusting, washing dishes</div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={light === 0 ? "btn active" : "btn"} onClick={() => setLight(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={light === 2.7 ? "btn active" : "btn"} onClick={() => setLight(2.7)}>Yes<span className="ml-4 text-muted">+2.7</span></button>
                </div>
           
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Do moderate work around the house</strong></div>
                    <div>e.g. vacuuming, sweeping floors, carrying in groceries</div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={moderate === 0 ? "btn active" : "btn"} onClick={() => setModerate(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={moderate === 3.5 ? "btn active" : "btn"} onClick={() => setModerate(3.5)}>Yes<span className="ml-4 text-muted">+3.5</span></button>
                </div>
             
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Do heavy work around the house</strong></div>
                    <div>e.g. scrubbing floors, lifting or moving heavy furniture
                    </div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={heavy === 0 ? "btn active" : "btn"} onClick={() => setHeavy(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={heavy === 8 ? "btn active" : "btn"} onClick={() => setHeavy(8)}>Yes<span className="ml-4 text-muted">+8</span></button>
                </div>
             
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Do yardwork</strong></div>
                    <div>e.g. raking leaves, weeding, pushing a power mower</div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={yardwork === 0 ? "btn active" : "btn"} onClick={() => setYardwork(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={yardwork === 4.5 ? "btn active" : "btn"} onClick={() => setYardwork(4.5)}>Yes<span className="ml-4 text-muted">+4.5</span></button>
                </div>
           
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Have sexual relations</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={sexual === 0 ? "btn active" : "btn"} onClick={() => setSexual(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={sexual === 5.25 ? "btn active" : "btn"} onClick={() => setSexual(5.25)}>Yes<span className="ml-4 text-muted">+5.25</span></button>
                </div>
           
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Participate in moderate recreational activities</strong></div>
                    <div>e.g. golf, bowling, dancing, doubles tennis, throwing a baseball or football</div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={participate === 0 ? "btn active" : "btn"} onClick={() => setParticipate(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={participate === 6 ? "btn active" : "btn"} onClick={() => setParticipate(6)}>Yes<span className="ml-4 text-muted">+6</span></button>
                </div>
               
            </div>

            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Participate in strenuous sports
                    </strong></div>
                    <div>e.g. swimming, singles tennis, football, basketball, skiing</div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={sports === 0 ? "btn active" : "btn"} onClick={() => setSports(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={sports === 7.5 ? "btn active" : "btn"} onClick={() => setSports(7.5)}>Yes<span className="ml-4 text-muted">+7.5</span></button>
                </div>
               
            </div>
            <div className="stick pt-2">
            {
                (result && vo) ?
                    <div className="apfel-score-result-container" >
                        <div className="point-result-container" >
                            <div>
                            <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                            </div>
                            <div>The higher the score (maximum 58.2), the higher the functional status.</div>
                        </div>
                        <div className="point-result-container" >
                            <div>
                            <h1>{vo} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>METs</span></h1>
                            </div>
                        </div>

                    </div>
                    :
                    <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>
            }
        </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/2782256/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Hlatky MA, Boineau RE, Higginbotham MB, Lee KL, Mark DB, Califf RM, et al. A brief self-administered questionnaire to determine functional capacity (the Duke Activity Status Index) Am J Cardiol. 1989;64(10):651–654..</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default DasiCalculator
