import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {API_ROOT} from '../constants'

const Change_pass = (props) => {
    const [oldPassword,setoldPassword]=useState(null)
    const [password,setpassword]=useState(null)
    const [confirmPassword,setconfirmPassword]=useState(null)
    const [burgerMenu,setburgerMenu]=useState(false)
    const burgerMenuClick=()=>{
        props.history.push('/drawer')
       
    }

    const requestChangePassword=()=>{
        if(oldPassword===""){
            alert("Please enter old password")
        }else if(password===""){
            alert("Please enter new password")
        }else if(confirmPassword===""){
            alert("Please enter confirm password")
        }
        else if(password===oldPassword || confirmPassword === oldPassword){
            alert("New/Confirm Password should not be same as Old password")
        }else
         if(password===confirmPassword){
             
            var token= sessionStorage.getItem("token")
                fetch(API_ROOT+`change-password?old_password=${oldPassword}&new_password=${password}` ,{
                method: 'POST',
                headers: {
                    'Accept':'application/json',
                    'Authorization': 'Bearer ' + token
                }
             
            })
                .then(response => response.json())
                .then(res =>{
                    if(res.status==="true"&&res.message==="Password is updated successfully."){
                        alert("Password is updated successfully.");
                        sessionStorage.removeItem('token')
                            window.location = '/login';

                    }
                    if(res.status==="false"&&res.message==="Old password is incorrect."){
                        alert("Old password is incorrect.");
                        return false;

                    } 
                })
               
            }else {
                alert("Password and Confirm password are not same..")
            }
    }

    
    return (
        <>
           
            { /* header starts here... */}
            {/* <header>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="navbar_header">
                                <img src={logo} className="logo-img img-fluid"></img>
                            </h1>
                        </div>
                    </div>
                </div>
            </header> */}
            { /* header ends here... */}


            {/* change password part starts here */}
            <div className="backNavigation text-center">
                CHANGE PASSWORD
            </div>
            <div className="main-container profile-container pt-5">
                            <div className="user-profile-show">
                                <form>
                                    <div className="form-group pb-2">
                                        <label for="exampleInputEmail1">Old Password</label>
                                        <input onChange={(e)=>setoldPassword(e.target.value)} type="password" className="form-control" id="exampleInputEmail1" placeholder="Enter Old Password" />
                                    </div>
                                    <div className="form-group pb-2">
                                        <label for="exampleInputPassword1">New Password</label>
                                        <input onChange={(e)=>setpassword(e.target.value)} type="password" className="form-control" id="exampleInputPassword1" placeholder="New Password" />
                                    </div>
                                    <div className="form-group pb-2">
                                        <label for="exampleInputPassword1">Confirm New Password</label>
                                        <input onChange={(e)=>setconfirmPassword(e.target.value)} type="password" className="form-control" id="exampleInputPassword1" placeholder="Confirm New Password" />
                                    </div>
                                    <div onClick={()=> requestChangePassword()} className="save-button-container mt-3 mb-3" style={{margin:'0 auto'}}>UPDATE</div>
                                </form>
                            </div>
            </div>

            {/* change password part ends here */}
        </>
    )
}

export default Change_pass;