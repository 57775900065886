import React, { useEffect, useState } from 'react';
import './RE1265.css';

const RE1265 = () => {
    const [npo, setNpo] = useState(0)
    const postData = async () => {

    }
    useEffect(() => {
        postData();


    }, [npo]);

    const result1 = () => {
        if (npo) {
            if (npo == 0) {
                return (
                    <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>

                )
            }
            if (npo == 1) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container col-12" >
                        <h4><strong>Clear Fluid </strong>&nbsp;=&nbsp; 2 Hrs</h4>
                        <h4><strong>Breast Milk </strong>&nbsp;=&nbsp; 4 Hrs</h4>
                        <h4><strong>Infant Formula</strong>&nbsp;=&nbsp; 5 Hrs</h4>
                        
                    </div>
                </div>
            }
            if (npo == 2) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container col-12" >
                        <h4><strong>Clear Fluid</strong>&nbsp;=&nbsp; 2 Hrs</h4>
                        <h4><strong>Breast Milk</strong>&nbsp;=&nbsp; 4 Hrs</h4>
                        <h4><strong>Infant Formula</strong>&nbsp;=&nbsp; 6 Hrs</h4>
                        <h4><strong>Non-human Milk </strong>&nbsp;=&nbsp; 6 Hrs</h4>
                        <h4><strong>Solid </strong>&nbsp;=&nbsp; 6 Hrs</h4>
                        
                    </div>
                </div>
            }
            if (npo == 3) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container col-12" >
                        <h4><strong>Clear Fluid</strong>&nbsp;=&nbsp; 2 Hrs</h4>
                        <h4><strong>Non-human Milk</strong>&nbsp;=&nbsp; 6 Hrs</h4>
                        <h4><strong>Light Meal</strong>&nbsp;=&nbsp; 6 Hrs</h4>
                        <h4><strong>Heavy Meal</strong>&nbsp;=&nbsp; 8 Hrs</h4>
                        
                    </div>
                </div>
            }
        }
        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }




    return (
        <div className="rev">
            <h5 className="text-info pl-2">NPO</h5>

            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Age</strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={npo}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNpo(patient);
                        }}
                    >
                        <option value='0'>Select</option>
                        <option value='1'>New Born-6 Months</option>
                        <option value='2' >6 Months -36 months</option>
                        <option value='3' >Older then 36 Months</option>
                    </select>
                </div>
            </div><br />
            <div className="stick ">
                {
                    result1()
                }
            </div><br/>
            <h5>Please Note :</h5>
                        <ol style={{margin:0, padding:0}}>
                            <li><strong>1)</strong> Clear Fluid: water, black coffee, mclear tea, carbonate beverage, fruit jusics without pulp.<br/>
                                Light meal : coffee with milk, orange juice, other fruit juices with  with puip, cereal, toast, crackers, etc.<br/>
                                Heavy meal : fatty or fried food, cheeses, meat etc.<br/>
                            </li><br />
                            <li><strong>2)</strong> Certain medical conditions slow  down gastric emptying and/or put patients at higher
                                risk of pulmonary aspiration, such as diabetes, renal disease, gastrointestinal motility
                                disorder, GERD, dysphagia, hiatal hernia, potential difficult airway manangement, etc. The anesthesia provider should use his/her clinical judgement to decide the appropiate NPO time accordingly.
                            </li><br />
                            <li><strong>3)</strong> For emergency sugeries, the anesthesia provider should discuss with surgeon the
                                urgency of the surgery, weigh risks and benefits, and decide with the surgeon the  appropriate NPO time accordingly
                            </li>
                        </ol>
        </div>
    )
}

export default RE1265
