import React, { useEffect, useState } from 'react';
import './CAS1059.css';


const CAS1059 = () => {
    const [age, setAge] = useState(0);
    const [spo, setSpo] = useState(0);
    const [respiratory, setRespiratory] = useState(0);
    const [anemia, setAnemia] = useState(0);
    const [incision, setIncision] = useState(0);
    const [duration, setDuration] = useState(0);
    const [emergency, setEmergency] = useState(0);


    const [result, setResult] = useState(0);
    const postData = async () => {
        setResult(age + spo + respiratory + anemia + incision + emergency + duration);
    }
    useEffect(() => {
        postData();


    }, [age, spo, respiratory, anemia, incision, emergency, duration]);

    const result1 = () => {

        if (result < 26) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{0} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>ARISCAT Score</div>
                </div>
                <div className="point-result-container" >
                    <h1>Low <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
                    <div>1.6% risk of in-hospital post-op pulmonary complications (composite including respiratory failure, respiratory infection, pleural effusion, atelectasis, pneumothorax, bronchospasm, aspiration pneumonitis)</div>
                </div>
            </div>
        }
        if ((result >= 26) && (result <= 44)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>ARISCAT Score</div>
                </div>
                <div className="point-result-container" >
                    <h1>Intermediate <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
                    <div>13.3% risk of in-hospital post-op pulmonary complications (composite including respiratory failure, respiratory infection, pleural effusion, atelectasis, pneumothorax, bronchospasm, aspiration pneumonitis)</div>
                </div>
            </div>
        }
        if ((result >= 45)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>ARISCAT Score</div>
                </div>
                <div className="point-result-container" >
                    <h1>HIGH <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
                    <div>42.1% risk of in-hospital post-op pulmonary complications (composite including respiratory failure, respiratory infection, pleural effusion, atelectasis, pneumothorax, bronchospasm, aspiration pneumonitis)</div>
                </div>
            </div>
        }
        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }
    return (
        <div className="rev">
            <h5 className="text-info pl-2">ARISCAT Score for Postoperative Pulmonary Complications</h5>
            <p className="pl-2">Predicts risk of pulmonary complications after surgery, including respiratory failure.</p>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Age, years</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{height: 40}} key="1" className={age === 0 ? "btn active" : "btn"} onClick={() => setAge(0)}>&lt;50%<span className="ml-4 text-muted">0</span></button>
                    <button style={{height: 40}}key="2" className={age === 3 ? "btn active" : "btn"} onClick={() => setAge(3)}>50-80%<span className="ml-4 text-muted">+3</span></button>
                    <button style={{height: 40}} key="2" className={age === 16 ? "btn active" : "btn"} onClick={() => setAge(16)}>&gt;80% <span className="ml-4 text-muted">+16</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Preoperative SpO₂</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{height: 40}} key="1" className={spo === 0 ? "btn active" : "btn"} onClick={() => setSpo(0)}>≥96%<span className="ml-4 text-muted">0</span></button>
                    <button style={{height: 40}} key="2" className={spo === 8 ? "btn active" : "btn"} onClick={() => setSpo(8)}>91-95%<span className="ml-4 text-muted">+8</span></button>
                    <button style={{height: 40}} key="2" className={spo === 24 ? "btn active" : "btn"} onClick={() => setSpo(24)}>≤90% <span className="ml-4 text-muted">+24</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Respiratory infection in the last month</strong></div>
                    <div>Either upper or lower (i.e., URI, bronchitis, pneumonia), with fever and antibiotic treatment</div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{height: 40}} key="1" className={respiratory === 0 ? "btn active" : "btn"} onClick={() => setRespiratory(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{height: 40}} key="2" className={respiratory === 17 ? "btn active" : "btn"} onClick={() => setRespiratory(17)}>Yes<span className="ml-4 text-muted">+17</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Preoperative anemia (Hgb ≤10 g/dL)</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{height: 40}} key="1" className={anemia === 0 ? "btn active" : "btn"} onClick={() => setAnemia(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{height: 40}} key="2" className={anemia === 11 ? "btn active" : "btn"} onClick={() => setAnemia(11)}>Yes<span className="ml-4 text-muted">+11</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Surgical incision</strong></div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn mb-2">
                    <button style={{height: 40}} onClick={() => setIncision(0)} className={incision === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Peripheral</span><span className="calc-val ">0</span></button>
                    <button style={{height: 40}} onClick={() => setIncision(15)} className={incision === 15 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Upper abdominal</span><span className="calc-val ">+15</span></button>
                    <button style={{height: 40}} onClick={() => setIncision(24)} className={incision === 24 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Intrathoracic</span><span className="calc-val ">+24</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Duration of surgery</strong></div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn mb-2">
                    <button style={{height: 40}} onClick={() => setDuration(0)} className={duration === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">&lt;2 hrs</span><span className="calc-val ">0</span></button>
                    <button style={{height: 40}} onClick={() => setDuration(16)} className={duration === 16 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">2-3 hrs</span><span className="calc-val ">+16</span></button>
                    <button style={{height: 40}} onClick={() => setDuration(23)} className={duration === 23 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">&gt;3 hrs</span><span className="calc-val ">+23</span></button>
                </div>
            </div>
            <div className="fields row mb-5">
                <div className="col-md-6">
                    <div ><strong>Emergency procedure</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{height: 40}} key="1" className={emergency === 0 ? "btn active" : "btn"} onClick={() => setEmergency(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{height: 40}} key="2" className={emergency === 8 ? "btn active" : "btn"} onClick={() => setEmergency(8)}>Yes<span className="ml-4 text-muted">+8</span></button>
                </div>
            </div>
            <div className="stick pt-3">{

                result1()
            }</div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/21045639/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Canet J, Gallart L, Gomar C, et al. Prediction of postoperative pulmonary complications in a population-based surgical cohort. Anesthesiology. 2010;113(6):1338-50..</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default CAS1059
