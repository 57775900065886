import React, { useEffect, useState } from 'react';
import './RE1192.css';

const RE1192 = () => {
    const [green, setGreen] = useState(false);
    const [fexpression, setFexpression] = useState(0);
    const [verbalization, setVerbalization] = useState(0);
    const [bodyposition, setBodyposition] = useState(0);
    const [result, setResult] = useState(0);

    const postData = async () => {
        setResult(fexpression + verbalization + bodyposition);
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [fexpression, verbalization, bodyposition, result]);

    return (
        <div className="rev">
            <h5 className="text-info pl-2">Behavioral Observational Pain Scale (BOPS) for Post-Op Pediatric Pain</h5>
            <p className="pl-2">Quantifies post-operative pain for children aged 1-7 years.</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Facial expression</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  40 }} onClick={() => setFexpression(0)} className={fexpression === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Neutral/positive facial expression, composed, calm</span><span className="calc-val ">0</span></button>
                    <button style={{ height:  40 }} onClick={() => setFexpression(1)} className={fexpression === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Negative facial expression, concerned</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  60 }} onClick={() => setFexpression(2)} className={fexpression === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Negative facial expression, grimace,<br/> distorted face</span><span className="calc-val ">+2</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Verbalization</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2 ">
                    <button style={{ height:  40 }} onClick={() => setVerbalization(0)} className={verbalization === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Normal conversation, laugh, crow</span><span className="calc-val ">0</span></button>
                    <button style={{ height:  60 }} onClick={() => setVerbalization(1)} className={verbalization === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Completely quiet, sobbing and/or complaining <br/>but not because of pain</span><span className="calc-val  ">+1</span></button>
                    <button style={{ height:  40 }} onClick={() => setVerbalization(2)} className={verbalization === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Crying, screaming and/or complaining about pain</span><span className="calc-val ">+2</span></button>

                </div>
            </div>
            <div className="fields row mb-5">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Body position</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button style={{ height:  60 }} onClick={() => setBodyposition(0)} className={bodyposition === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Inactive, laying, relaxed with all extremities<br/> or sitting, walking</span><span className="calc-val ">0</span></button>
                    <button style={{ height:  60 }} onClick={() => setBodyposition(1)} className={bodyposition === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Inactive, laying, relaxed with all extremities<br/> or sitting, walking</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  60 }} onClick={() => setBodyposition(2)} className={bodyposition === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Restless movements, shifting fashion and/or<br/> touching wound or wound area</span><span className="calc-val ">+2</span></button>

                </div>
            </div>


            <div className="stick">
                {
                    green ? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container col-12" >
                                <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>


                                <div>{result < 3 ? "Minimal or no pain." : " "}</div>
                                <div>{result < 3 ? "Consider reassessing after 30 minutes." : " "}</div>
                                <div>{result > 3 ? "Significant pain present." : " "}</div>

                                <div>{result > 2 ? "Consider analgesia and reassess afterward." : " "}</div>
                            </div>






                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/8895225/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" ><span class="resource__text">Anand KJ, Craig KD. New perspectives on definition of pain. Pain 1996; 67:3-6.</span></p>
                    </div>
                </a>
            </div>
        </div>
    )
}


export default RE1192
