import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';

const DialysisRiskMehta = () => {
    const [green, setGreen] = useState(false);
    const [number2, setNumber2] = useState([]);
    const [tunit2, setTunit2] = useState('μmol/L');
    const [placehldr2, setPlacehldr2] = useState('Norm: 62 - 115')




    const [race, setRace] = useState(0);
    const [cronicLung, setCronicLung] = useState(0);
    const [reoperation, setReoperation] = useState(0);
    const [Nyha, setNyha] = useState(0);

    const [cardiacShock, setCardiacShock] = useState(0);


    const [typeOfSurgery, setTypeOfSurgery] = useState(0);
   

    const [result, setResult] = useState(0);
    const [finalresult, setFinalesult] = useState(0);


  
    const [newcreatinine, setNewcreatinine] = useState(0);
    const [newcreatinine2, setNewcreatinine2] = useState(0);
   
    const [age, setAge] = useState(0);
    const [newAge, setNewAge] = useState(0);

    const [diabetes, setDiabetes] = useState(0);
    const [mi, setMi] = useState(0);


    const postData = async () => {
        if (tunit2 === 'μmol/L') {
            setNewcreatinine((number2/ 88.4 ).toFixed(2))
          
        }
        if (tunit2 === 'mg/dL') {
            setNewcreatinine((number2 ))
       
        }
 
        if (age < 55) {
            setNewAge(0);
        } else if (age > 54 && age < 60) {
            setNewAge(1);
        } else if (age > 59 && age < 65) {
            setNewAge(2);
        } else if (age > 64 && age < 70) {
            setNewAge(3);
        }
        else if (age > 69 && age < 75) {
            setNewAge(4);
        }
        else if (age > 74 && age < 80) {
            setNewAge(5);
        } else if (age > 79 && age < 85) {
            setNewAge(6);
        } else if (age > 84 && age < 90) {
            setNewAge(7);
        } else if (age > 89 && age < 95) {
            setNewAge(8);
        } else if (age > 94 && age < 100) {
            setNewAge(9);
        } else if (age > 99) {
            setNewAge(10);
        }


        if(newcreatinine < 4.1){

            setNewcreatinine2((newcreatinine * 10).toFixed(2));
        }
        else{
            setNewcreatinine2(parseInt(40).toFixed(0));
        }

        if (result < 8) {
            setFinalesult(0.1);
        } else if (result == 8 || result == 9 || result == 10 || result == 11 ) {
            setFinalesult(0.2);
        } else if (result == 12 || result == 13 ) {
            setFinalesult(0.3);
        } else if (result == 14 || result == 15 ) {
            setFinalesult(0.4);
        }
        else if (result == 16) {
            setFinalesult(0.5);
        }
        else if (result == 17 ) {
            setFinalesult(0.6);
        } else if (result == 18 ) {
            setFinalesult(0.7);
        } else if (result == 19 ) {
            setFinalesult(0.9);
        } else if (result == 20) {
            setFinalesult(1.1);
        }
        else if (result == 21 ) {
            setFinalesult(1.3);
        } else if (result == 22 ) {
            setFinalesult(1.5);
        } else if (result == 23 ) {
            setFinalesult(1.8);
        }
        else if (result == 24) {
            setFinalesult(2.1);
        }
        else if (result == 25 ) {
            setFinalesult(2.5);
        } else if (result == 26 ) {
            setFinalesult(3);
        } else if (result == 27 ) {
            setFinalesult(3.4);
        } else if (result == 28) {
            setFinalesult(4);
        }
        else if (result == 29 ) {
            setFinalesult(4.7);
        } else if (result == 30 ) {
            setFinalesult(5.4);
        } else if (result == 31 ) {
            setFinalesult(6.2);
        } else if (result == 32) {
            setFinalesult(7.1);
        }
        else if (result == 33 ) {
            setFinalesult(8.1);
        } else if (result == 34 ) {
            setFinalesult(9.2);
        } else if (result == 35 ) {
            setFinalesult(10);
        }
        else if (result == 36) {
            setFinalesult(11);
        }
        else if (result == 37 ) {
            setFinalesult(13);
        } else if (result == 38 ) {
            setFinalesult(14);
        } else if (result == 39 ) {
            setFinalesult(15);
        } else if (result == 40) {
            setFinalesult(16);
        }
        else if (result == 41 ) {
            setFinalesult(18);
        } else if (result == 42 ) {
            setFinalesult(19);
        } else if (result == 43 ) {
            setFinalesult(19);
        }
        else if (result == 44) {
            setFinalesult(20);
        }
        else if (result == 45 ) {
            setFinalesult(20);
        } else if (result == 46 ) {
            setFinalesult(21);
        } else if (result == 47 ) {
            setFinalesult(22);
        } else if (result == 48) {
            setFinalesult(24);
        }
        else if (result == 49 ) {
            setFinalesult(25);
        } else if (result == 50 ) {
            setFinalesult(28);
        } else if (result == 51 ) {
            setFinalesult(31);
        } else if (result == 52) {
            setFinalesult(31);
        }
        else if (result == 53 ) {
            setFinalesult(33);
        } else if (result == 54 ) {
            setFinalesult(35);
        } else if (result == 55 ) {
            setFinalesult(40);
        }
        else if (result == 56) {
            setFinalesult(42);
        }
        else if (result == 57 ) {
            setFinalesult(44);
        } else if (result == 58 ) {
            setFinalesult(48);
        } else if (result == 59 ) {
            setFinalesult(55);
        } else if (result == 60) {
            setFinalesult(56);
        }
        else if (result == 61) {
            setFinalesult(59);
        } else if (result == 62 ) {
            setFinalesult(59);
        } else if (result == 63 ) {
            setFinalesult(59);
        }
        else if (result == 64) {
            setFinalesult(59);
        }
        else if (result == 65 ) {
            setFinalesult(70);
        } else if (result == 66 ) {
            setFinalesult(70);
        } else if (result == 67 ) {
            setFinalesult(80);
        } 
        else if (result > 67) {
            setFinalesult("≥ 80");
        }

         





        setResult((parseFloat(newcreatinine2)+parseInt(newAge)+parseInt(typeOfSurgery)+parseInt(mi) + parseInt(race) + parseInt(cronicLung) + parseInt(reoperation) + parseInt(Nyha) + parseInt(diabetes) + parseInt(cardiacShock)).toFixed(0));
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [mi, number2,race, cronicLung, reoperation, Nyha, diabetes, cardiacShock, typeOfSurgery, newcreatinine,newcreatinine2,newAge, result,age ,finalresult,tunit2]);


    const changeunit2 = () => {
        if (number2 === 0) {
            if (tunit2 === 'μmol/L') {
                setTunit2('mg/dL');

            }
            if (tunit2 === 'mg/dL') {
                setTunit2('μmol/L');

            }
        } else if (number2 !== 0) {
            if (tunit2 === 'μmol/L') {
                setTunit2('mg/dL');

                setPlacehldr2("Norm: 0.7-1.3");
                if (number2.length !== 0) {
                    setNumber2((number2 / 88.4).toFixed(2))
                }
            }
            if (tunit2 === 'mg/dL') {
                setPlacehldr2("Norm: 62-155");
                setTunit2('μmol/L');
                if (number2.length !== 0) {
                    setNumber2((number2 * 88.4).toFixed(2))
                }
            }
        }
    }
    const takeUnit2 = (e) => {
        if (tunit2 === 'μmol/L') {
            setNumber2(e.target.value);
            setPlacehldr2("Norm: 62-155");

        }
        if (tunit2 === 'mg/dL') {
            let ht = e.target.value;
            setNumber2(ht);
            setPlacehldr2("Norm: 0.7-1.3");
        }
    }


    return (
        <div className="rev">
            <h5 className="text-info pl-2">Dialysis Risk After Cardiac Surgery (Mehta)</h5>
            <p className="pl-2">Estimate the risk of dialysis after cardiac surgery (Mehta model)</p>

            <div className="fields row">
            <div className="col-md-5 col-sm-12">
                <div className="pt-2"><strong>Creatinine</strong></div>
            </div>
         
          
            <div className="col-md-6 col-sm-12  ml-4 frm-field ">
                <input type="number"
                    onChange={takeUnit2}
                    placeholder={placehldr2}

                    className="ant-text-input " style={{ width: '85%' }} value={number2} /><span className="units" onClick={changeunit2}>{tunit2}&#8651;</span>
              
            </div>


        </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Age</strong></div>

                </div>
                <div className="col-md-6 frm-field ml-4 ">
                    <input type="number" style={{ width: "92%" }} className="ant-text-input " placeholder="Norm: 40 - 79" onChange={(e) => setAge(e.target.value)} /><span className="units ">Years</span>

                </div>
      
            </div>

            <div className="fields row mb-4">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Type of Surgery?</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn  ">
                    <button style={{ height:  40   }}onClick={() => setTypeOfSurgery(0)} className={typeOfSurgery === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name "> CABG Only</span></button>
                    <button style={{ height:  40   }}onClick={() => setTypeOfSurgery(2)} className={typeOfSurgery === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">AV Only</span></button>
                    <button style={{ height:  40   }}onClick={() => setTypeOfSurgery(5)} className={typeOfSurgery === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">AV & CABG</span></button>
                    <button style={{ height:  40   }}onClick={() => setTypeOfSurgery(4)} className={typeOfSurgery === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">MV Only</span></button>
                    <button style={{ height:  40   }}onClick={() => setTypeOfSurgery(7)} className={typeOfSurgery === 7 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">MV & CABG</span></button>

                </div>
            

            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Diabetes?</strong></div>
                </div>
                <div className="col-md-6 btn-group-vertical text-left">
                    <button style={{ height:  40   }}className={diabetes === 0 ? "btn active" : "btn"} onClick={() => setDiabetes(0)}>None</button>
                    <button style={{ height:  40   }} className={diabetes === 2 ? "btn active" : "btn"} onClick={() => setDiabetes(2)}>Controlled Orally</button>
                    <button style={{ height:  40   }}className={diabetes === 5 ? "btn active" : "btn"} onClick={() => setDiabetes(5)}>Insulin Dependent</button>

                </div>
            </div>




            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Recent MI</strong></div>
                </div>
                <div className="col-md-6 btn-group-vertical text-left" >
                    <button style={{ height:  40   }} className={mi === 0 ? "btn active" : "btn"} onClick={() => setMi(0)}>No Recent MI</button>
                    <button style={{ height:  40   }} className={mi === 3 ? "btn active" : "btn"} onClick={() => setMi(3)}>Within Last 3 Weeks</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong> Race?</strong></div>
                </div>
                <div className="col-md-6 btn-group-vertical  text-left">
                    <button style={{ height:  40   }} className={race === 0 ? "btn active" : "btn"} onClick={() => setRace(0)}>Not African-American</button>
                    <button style={{ height:  40   }} className={race === 2 ? "btn active" : "btn"} onClick={() => setRace(2)}>African-American</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Chronic Lung Disease?</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} className={cronicLung === 0 ? "btn active" : "btn"} onClick={() => setCronicLung(0)}>No</button>
                    <button style={{ height:  40   }} className={cronicLung === 3 ? "btn active" : "btn"} onClick={() => setCronicLung(3)}>Yes</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>  Reoperation?</strong></div>
                </div>
                <div className="col-md-6 btn-group-vertical text-left">
                    <button style={{ height:  40   }} className={reoperation === 0 ? "btn active" : "btn"} onClick={() => setReoperation(0)}>No Prior CV Surgery</button>
                    <button style={{ height:  40   }} className={reoperation === 3 ? "btn active" : "btn"} onClick={() => setReoperation(3)}>Prior CAB or Other CV Surgery</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>  NYHA Class?</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} className={Nyha === 0 ? "btn active" : "btn"} onClick={() => setNyha(0)}>1,2 or 3</button>
                    <button style={{ height:  40   }} className={Nyha === 3 ? "btn active" : "btn"} onClick={() => setNyha(3)}>4</button>

                </div>
            </div>

            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Cardiogenic Shock?</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} className={cardiacShock === 0 ? "btn active" : "btn"} onClick={() => setCardiacShock(0)}>No</button>
                    <button style={{ height:  40   }} className={cardiacShock === 7 ? "btn active" : "btn"} onClick={() => setCardiacShock(7)}>Yes</button>

                </div>
                
            </div>

            <br />
            <div className="stick">
                {
                    result != 0 ? <>
                        <div className="apfel-score-result-container col-12 " >
                            
                            <div className="point-result-container col-12"  >
                                <h5>Estimated Risk of Dialysis after CV Surgery  <strong/> 
                                <span style={{fontSize: '1.5rem', color: 'white' }}>{finalresult}%</span></h5>
                             

                            </div>
                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>

            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://read.qxmd.com/read/17088458/bedside-tool-for-predicting-the-risk-of-postoperative-dialysis-in-patients-undergoing-cardiac-surgery">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Mehta et al.Bedside tool for predicting the risk of postoperative dialysis in patients undergoing cardiac surgery.Circulation 2006 November 21, 114 (21): 2208-16; quiz 2208</p>
                    </div>
                </a>
            </div>



        </div>
    )
}


export default DialysisRiskMehta;

