import React, { useEffect, useState } from 'react';
import './RE1182.css';

const RE1182 = () => {
    const [green, setGreen] = useState(false);
    const [fexpression, setFexpression] = useState(0);


    const [result, setResult] = useState(0);


    const [immunocompromise, setImmunocompromise] = useState(0);
    const [age, setAge] = useState(0);

    const [temperature, setTemperature] = useState([]);
    const [tunit4, setTunit4] = useState('C');
    const [placehldr4, setPlacehldr4] = useState('Norm: 35-55 ');
    const [mAPressure, setMAPressure] = useState(0);

    const [ph, setPh] = useState(0);
    const [pulse, setPulse] = useState(0);
    const [respiratory, setRespiratory] = useState(0);

    const [sodium, setSodium] = useState([]);
    const [tunit1, setTunit1] = useState('mmol/L');
    const [placehldr1, setPlacehldr1] = useState('Norm: 136-145')

    const [potassium, setPotassium] = useState([]);
    const [tunit2, setTunit2] = useState('mmol/L');
    const [placehldr2, setPlacehldr2] = useState('Norm: 3.5-5')

    const [tunit3, setTunit3] = useState('μmol/L');
    const [placehldr3, setPlacehldr3] = useState('Norm: 62 - 115')
    const [creatinine, setCreatinine] = useState([]);

    const [tunit5, setTunit5] = useState('× 10⁹ cells/L');
const [placehldr5, setPlacehldr5] = useState('Norm: 62 - 115')
const [wbc, setWbc] = useState([]);

    const [fio, setFio] = useState(0);
    const [pao2, setPao2] = useState(0);
    const [aGradient, setAGradient] = useState(0);
    const [typeOfSurgery, setTypeOfSurgery] = useState(0);
    const [renal, setRenal] = useState(0);
    const [hematocrite, setHematocrite] = useState(0);
    const [glassglow, setGlasgow] = useState(0);



    const postData = async () => {
        setResult(fexpression + fio + pao2);
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [fexpression, fio, pao2, result]);

    const changeunit4 = () => {
        if (temperature === 0) {
            if (tunit4 === 'C') {
                setTunit4('F');
                setPlacehldr4("Norm: 3.5- 5.5");

            }
            if (tunit4 === 'F') {
                setTunit4('C');

            }
        } else if (temperature !== 0) {
            if (tunit4 === 'C') {

                setTunit4('F');
                setPlacehldr4("Norm: 3.5- 5.5");
                if (temperature.length !== 0) {

                    setTemperature((temperature / 10).toFixed(2));
                }

            }
            if (tunit4 === 'F') {
                setPlacehldr4("Norm: 35-55");
                setTunit4('C');
                if (temperature.length !== 0) {

                    setTemperature((temperature * 10).toFixed(2));
                }

            }
        }
    }
    const takeunit4 = (e) => {
        if (tunit4 === 'C') {
            setTemperature(e.target.value);



        }
        if (tunit4 === 'F') {
            let ht = e.target.value;
            setTemperature(ht);

        }
    }

    const changeunit1 = () => {
        if (sodium === 0) {
            if (tunit1 === 'mmol/L') {
                setTunit1('mEq/L');

            }
            if (tunit1 === 'mEq/L') {
                setTunit1('mmol/L');

            }
        } else if (sodium !== 0) {
            if (tunit1 === 'mmol/L') {
                setPlacehldr1("Norm: 136-145");
                setTunit1('mEq/L');
                if (sodium.length !== 0) {
                    setSodium(Math.round(sodium * 1).toFixed(2))
                }
            }
            if (tunit1 === 'mEq/L') {
                setPlacehldr1("Norm: 136-145");
                setTunit1('mmol/L');
                if (sodium.length !== 0) {
                    setSodium(Math.round(sodium * 1))
                }
            }
        }
    }
    const takeUnit1 = (e) => {
        if (tunit1 === 'mmol/L') {
            setSodium(e.target.value);
            setPlacehldr1("Norm: 136-145");

        }
        if (tunit1 === 'mEq/L') {
            let ht = e.target.value;
            setSodium(ht);
            setPlacehldr1("Norm: 136-145");
        }
    }

    const changeUnit2 = () => {
        if (potassium === 0) {
            if (tunit2 === 'mmol/L') {
                setTunit2('mEq/L');

            }
            if (tunit2 === 'mEq/L') {
                setTunit2('mmol/L');

            }
        } else if (potassium !== 0) {
            if (tunit2 === 'mmol/L') {
                setPlacehldr2("Norm: 3.5-5");
                setTunit2('mEq/L');
                if (potassium.length !== 0) {
                    setPotassium(Math.round(potassium * 1).toFixed(2))
                }
            }
            if (tunit2 === 'mEq/L') {
                setPlacehldr2("Norm: 3.5-5");
                setTunit2('mmol/L');
                if (potassium.length !== 0) {
                    setPotassium(Math.round(potassium * 1))
                }
            }
        }
    }
    const takeUnit2 = (e) => {
        if (tunit2 === 'mmol/L') {
            setPotassium(e.target.value);
            setPlacehldr2("Norm:3.5-5");

        }
        if (tunit2 === 'mEq/L') {
            let ht = e.target.value;
            setPotassium(ht);
            setPlacehldr2("Norm:3.5-5");
        }
    }
    const changeUnit3 = () => {
        if (creatinine === 0) {
            if (tunit3 === 'μmol/L') {
                setTunit3('mg/dL');

            }
            if (tunit3 === 'mg/dL') {
                setTunit3('μmol/L');

            }
        } else if (creatinine !== 0) {
            if (tunit3 === 'μmol/L') {
                setTunit3('mg/dL');

                setPlacehldr3("Norm: 0.7-1.3");
                if (creatinine.length !== 0) {
                    setCreatinine((creatinine / 88.4).toFixed(2))
                }
            }
            if (tunit3 === 'mg/dL') {
                setPlacehldr3("Norm: 62-155");
                setTunit3('μmol/L');
                if (creatinine.length !== 0) {
                    setCreatinine((creatinine * 88.4).toFixed(2))
                }
            }
        }
    }
    const takeUnit3 = (e) => {
        if (tunit3 === 'μmol/L') {
            setCreatinine(e.target.value);
            setPlacehldr3("Norm: 62-155");

        }
        if (tunit3 === 'mg/dL') {
            let ht = e.target.value;
            setCreatinine(ht);
            setPlacehldr3("Norm: 0.7-1.3");
        }
    }

    const changeUnit5 = () => {
        if (wbc === 0) {
            if (tunit5 === '× 10⁹ cells/L') {
                setTunit5('× 10³ cells/µL');

            }
            if (tunit5 === '× 10³ cells/µL') {
                setTunit5('× 10⁹ cells/L');

            }
        } else if (wbc !== 0) {
            if (tunit5 === '× 10⁹ cells/L') {
                setTunit5('× 10³ cells/µL');

                setPlacehldr5("Norm: 0.7-1.3");
                if (wbc.length !== 0) {
                    setWbc((wbc / 88.4).toFixed(2))
                }
            }
            if (tunit5 === '× 10³ cells/µL') {
                setPlacehldr5("Norm: 62-155");
                setTunit5('× 10⁹ cells/L');
                if (wbc.length !== 0) {
                    setWbc((wbc * 88.4).toFixed(2))
                }
            }
        }
    }
    const takeUnit5 = (e) => {
        if (tunit5 === '× 10⁹ cells/L') {
            setWbc(e.target.value);
            setPlacehldr5("Norm: 62-155");

        }
        if (tunit5 === '× 10³ cells/µL') {
            let ht = e.target.value;
            setWbc(ht);
            setPlacehldr5("Norm: 0.7-1.3");
        }
    }


    const afterfio2 = () => {
        if (fio === 1) {
            return <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>PaO₂, mmHg</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn mb-4">
                    <button onClick={() => setPao2(1)} className={pao2 === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">&gt;70</span></button>
                    <button onClick={() => setPao2(2)} className={pao2 === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">61-70</span></button>
                    <button onClick={() => setPao2(3)} className={pao2 === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">55-60</span></button>
                    <button onClick={() => setPao2(4)} className={pao2 === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">&lt;55</span></button>
                </div>
            </div>

        }
        if (fio === 2) {
            return <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>A-a gradient</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn mb-4">
                    <button onClick={() => setAGradient(1)} className={aGradient === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">&lt;200</span></button>
                    <button onClick={() => setAGradient(2)} className={aGradient === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">200-349</span></button>
                    <button onClick={() => setAGradient(3)} className={aGradient === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">350-499</span></button>
                    <button onClick={() => setAGradient(4)} className={aGradient === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">&gt;499</span></button>

                </div>
            </div>

        }
    }


    const afterHistory = () => {
        if (immunocompromise === 2) {
            return <>
                <div className="fields row">
                    <div className="col-md-6">
                        <div className="pt-2"><strong>Type of surgery</strong></div>

                    </div>
                    <div className="col-md-6 btn-group-vertical options-btn mb-4">
                        <button onClick={() => setTypeOfSurgery(1)} className={typeOfSurgery === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Tolerating movement</span><span className="calc-val ">+1</span></button>
                        <button onClick={() => setTypeOfSurgery(2)} className={typeOfSurgery === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Coughing but tolerating ventilation for<br/> most of the time</span><span className="calc-val ">+2</span></button>
                        <button onClick={() => setTypeOfSurgery(3)} className={typeOfSurgery === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Fighting ventilator</span><span className="calc-val ">+3</span></button>
                        <button onClick={() => setTypeOfSurgery(4)} className={typeOfSurgery === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Unable to control ventilation</span><span className="calc-val ">+4</span></button>
                    </div>
                </div>

            </>

        }
    }

    return (
        <div className="rev">
            <h5 className="text-info pl-2">APACHE II Score</h5>
            <p className="pl-2">Estimates ICU mortality.</p>


            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>History of severe organ failure or immunocompromise</strong></div>
                    <span>Heart Failure Class IV, cirrhosis, chronic lung disease, or dialysis-dependent</span>
                </div>
                <div className="col-md-6 btn-group text-left ">
                    <button style={{ height: 40 }} className={immunocompromise === 1 ? "btn active" : "btn"} onClick={() => setImmunocompromise(1)}>No</button>
                    <button style={{ height: 40 }} className={immunocompromise === 2 ? "btn active" : "btn"} onClick={() => setImmunocompromise(2)}>Yes</button>

                </div>
            </div>
            <div>{afterHistory()}</div>

            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Age</strong></div>

                </div>
                <div className="col-md-6 frm-field ml-4 ">
                    <input type="number" style={{height: 40 }} className="ant-text-input " onChange={(e) => setAge(e.target.value)} /><span className="units ">Years</span>

                </div>
            </div>

            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Temperature</strong></div>

                </div>
                <div className="col-md-6 frm-field ml-4 ">
                    <input type="number"
                        onChange={takeunit4}
                        placeholder={placehldr4}

                        className="ant-text-input fluidInput" value={temperature} style={{ height: '100%', width: '85%' }} /><span className="units unitBtn" style={{ height:  40 }} onClick={changeunit4} >{tunit4}&#8651;</span>


                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Mean arterial pressure</strong></div>

                </div>
                <div className="col-md-6 frm-field ml-4 ">
                    <input type="number" style={{height: 40 }} className="ant-text-input " placeholder="Norm: 70 - 100" onChange={(e) => setMAPressure(e.target.value)} /><span className="units ">mmHg</span>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>pH</strong></div>

                </div>
                <div className="col-md-6 frm-field ml-4 ">
                    <input type="number" style={{ height: 40}} className="ant-text-input " placeholder="Norm: 7.38 - 7.44" onChange={(e) => setPh(e.target.value)} />

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Heart rate/pulse</strong></div>

                </div>
                <div className="col-md-6 frm-field ml-4 ">
                    <input type="number" style={{ height: 40 }} className="ant-text-input " placeholder="Norm: 60 - 100" onChange={(e) => setPulse(e.target.value)} /><span className="units ">beats/min</span>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Respiratory rate</strong></div>

                </div>
                <div className="col-md-6 frm-field ml-4 ">
                    <input type="number" style={{ height: 40 }} className="ant-text-input " placeholder="Norm: 12- 20" onChange={(e) => setRespiratory(e.target.value)} /><span className="units ">beats/min</span>

                </div>
            </div>

            <div className="fields row">
                <div className="col-md-5 col-sm-12">
                    <div className="pt-2"><strong>sodium</strong></div>
                </div>
                <div className="col-md-7 col-sm-12 frm-field">
                    <input type="number"
                        onChange={takeUnit1}
                        placeholder={placehldr1}

                        className="ant-text-input" style={{ width: '65%' }} value={sodium} /><span className="units" onClick={changeunit1}>{tunit1}&#8651;</span>

                </div>


            </div>

            <div className="fields row">
                <div className="col-md-5 col-sm-12">
                    <div className="pt-2"><strong>Potassium</strong></div>
                </div>
                <div className="col-md-7 col-sm-12 frm-field">
                    <input type="number"
                        onChange={takeUnit2}
                        placeholder={placehldr2}


                        className="ant-text-input" style={{ width: "65%", height: 40 }} value={potassium} /><span className="units" onClick={changeUnit2}>{tunit2}&#8651;</span>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5 col-sm-12">
                    <div className="pt-2"><strong>Creatinine</strong></div>
                </div>
                <div className="col-md-7 col-sm-12 frm-field">
                    <input type="number"
                        onChange={takeUnit3}
                        placeholder={placehldr3}

                        className="ant-text-input" style={{ width: '65%', height: 40 }} value={creatinine} /><span className="units" onClick={changeUnit3}>{tunit3}&#8651;</span>

                </div>


            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Acute renal failure</strong></div>
                    <span>Note: "acute renal failure" was not defined in the original study. Use clinical judgment to determine whether patient has acute kidney injury.</span>
                </div>
                <div className="col-md-6 btn-group text-left ">
                    <button style={{ height: 40 }} className={renal === 1 ? "btn active" : "btn"} onClick={() => setRenal(1)}>No</button>
                    <button style={{ height: 40 }} className={renal === 2 ? "btn active" : "btn"} onClick={() => setRenal(2)}>Yes</button>

                </div>
            </div>

            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Hematocrit</strong></div>

                </div>
                <div className="col-md-6 frm-field ml-4 ">
                    <input type="number" style={{ width: "92%", height: 40 }} className="ant-text-input " placeholder=" Norm: 36 -51" onChange={(e) => setHematocrite(e.target.value)} /><span className="units ">%</span>

                </div>
            </div>
            <div className="fields row">
    <div className="col-md-5 col-sm-12">
        <div className="pt-2"><strong>White blood cell count</strong></div>
    </div>
    <div className="col-md-6 col-sm-12 frm-field ml-4">
        <input
            onChange={takeUnit5}
            placeholder={placehldr5}
            type="number"
            className="ant-text-input" style={{ width: '65%' }} value={wbc} /><span className="units" onClick={changeUnit5}>{tunit5}&#8651;</span>

    </div>


</div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Glasgow Coma Scale</strong></div>

                </div>
                <div className="col-md-6 frm-field ml-4 ">
                    <input type="number" style={{ width: "92%" }} className="ant-text-input " placeholder=" Norm: 3 - 15" onChange={(e) => setGlasgow(e.target.value)} /><span className="units ">points</span>

                </div>
            </div>

            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>FiO₂</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn mb-2 ">
                    <button onClick={() => setFio(1)} className={fio === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">&lt;50% (or non-intubated)</span></button>
                    <button onClick={() => setFio(2)} className={fio === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">≥50%</span></button>

                </div>

            </div>
            <div>{afterfio2()}</div>



            <div className="stick">
                {
                    result != 0 ? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container" >
                            <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>



                            </div>

                        </div>

                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <div className="p-2 mb-5" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/3928249/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Knaus WA, Draper EA, Wagner DP, Zimmerman JE. APACHE II: a severity of disease classification system. Crit Care Med. 1985 Oct;13(10):818-29.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}


export default RE1182;

