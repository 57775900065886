
import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';

const ASAPS = () => {
    const [green, setGreen] = useState(false);
    const [description, setDescription] = useState(0);
    const [emergency, setEmergency] = useState(null);
  
    const [result, setResult] = useState(0);

    const postData = async () => {
        setResult(description );
        setGreen(true);
      
    }
    useEffect(() => {
        postData();


    }, [description, result ,emergency]);





    return (
        <div className="rev">
              <h5 className="text-info pl-2">ASA Physical Status/ASA Classification</h5>
              <p className="pl-2">Classifies health of patients prior to surgery.</p>
            <div>
            <div className="fields row">
                <div className="col-md-4">
                    <div className="pt-2"><strong>Description</strong></div>

                </div>
                <div className="col-md-8 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  40   }} onClick={() => setDescription(1)} className={description === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name  ">Normal healthy patient</span></button>
                    <button style={{ height:  40   }} onClick={() => setDescription(2)} className={description === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Patient with mild systemic disease</span></button>
                    <button style={{ height:  40   }} onClick={() => setDescription(3)} className={description === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Patient with severe systemic disease</span></button>
                    <button style={{ height:  40   }} onClick={() => setDescription(4)} className={description === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Patient with severe systemic disease <br/>that is a constant threat to life</span></button>
                    <button style={{ height:  40   }} onClick={() => setDescription(5)} className={description === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name  ">Moribund patient not expected to survive<br/> without the operation</span></button>
                    <button style={{ height:  40   }} onClick={() => setDescription(6)} className={description === 6 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name  ">Declared brain-dead patient whose organs are<br/> being removed for donor purposes</span></button>

                </div>
                </div>
          
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Smoking within last year</strong></div>

                </div>
                <div className="col-md-7 col-sm-8 btn-group" >
                        <button style={{ height: 50 }} className={emergency === null? "btn active text-white" : "btn "} onClick={() => setEmergency(null)}>No </button>
                        <button style={{ height: 50 }} className={emergency === "E"? "btn active text-white" : "btn "} onClick={() => setEmergency("E")}>Yes </button>
                    </div>
            </div>
  


            <div className="stick mt-5">
                {
                    result !=0 ? <>
                        <div className="apfel-score-result-container col-12 " >
                            <div className="point-result-container col-12" >
                                <h1> ASA {result}{emergency} </h1>
                                <div>ASA Physical Status</div>

                            </div>


                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <br/>
           <div className="p-2" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://pubs.asahq.org/anesthesiology/article/2/3/281/19041/GRADING-OF-PATIENTS-FOR-SURGICAL-PROCEDURES">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Saklad M. Grading of Patients for Surgical Procedures. Anesthesiology. 1941;2(3):281-284.</p>
                </div>
            </a>
        </div>
        </div>
    )
}


export default ASAPS;

