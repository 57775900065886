import React, { useEffect, useState,useContext } from 'react'
import { API_ROOT } from '../../../constants'
import Select from 'react-select'
import "../../../css/newLamaxHeader.css"
import { useDispatch, useSelector } from 'react-redux'
import { setNewAdminValue, setNewConc, setNewDrug1, setMedType, setmixtureValue1, setmixtureValueA } from '../../../redux/NewLamaxActions'
import { setNewAdminValue2, setNewConc2, setNewDrug2, setNewDrugMixture1, setNewDrugMixture2, setMedType2, setDocValue1, setDocValue2, setNewLamaxWeight, setNewLamaxHeight, setNewGenderType, setNewHeightType, setNewWeightType } from '../../../redux/NewLamaxActions'
import { Link } from 'react-router-dom'
import AppContext from '../../../contextStore/DataProvider'
import { Modal } from 'react-bootstrap'

const LAMAX1003 = (props) => {
    const context = useContext(AppContext)
    var result1 = 0;
    var result2 = 0;
    var remainingDrug;

    const [drugData, setDrugData] = useState([])
    const [anesthesiaData, setAnesthesiaData] = useState([])
    const [anesthesiaData2, setAnesthesiaData2] = useState([])
    const [checkedNum, setCheckedNum] = useState(0)
    const [arr, setArr] = useState([])
    const [arr2, setArr2] = useState([])
    const [drugANotPlainValue, setdrugANotPlainValue] = useState(null)
    const [drugANotEpiValue, setdrugANotEpiValue] = useState(null)
    const [drugBNotPlainValue, setdrugBNotPlainValue] = useState(null)
    const [drugBNotEpiValue, setdrugBNotEpiValue] = useState(null)

    const [burgerMenu, setburgerMenu] = useState(false)

    const newAdminValue2 = useSelector(state => state.newLamax.newAdminValue2)
    const newConc2 = useSelector(state => state.newLamax.newConc2)
    const weight2 = useSelector(state => state.newLamax.weight2)
    const newComorbities = useSelector(state => state.newLamax.newComorbities)
    const drug1 = useSelector(state => state.newLamax.mixtureDrug1)
    const drug2 = useSelector(state => state.newLamax.mixtureDrug2)
    
    const newConc = useSelector(state => state.newLamax.newConc)
    const newAdminValue = useSelector(state => state.newLamax.newAdminValue)
    const medType = useSelector(state => state.newLamax.medType)
    
    const medType2 = useSelector(state => state.newLamax.medType2)
    const docValue1 = useSelector(state => state.newLamax.docValue1)
    const docValue2 = useSelector(state => state.newLamax.docValue2)
    const mixValueA = useSelector(state => state.newLamax.mixValueA)
    const mixValueB = useSelector(state => state.newLamax.mixValueB)
    const [drugBState, setDrugBstate] = useState(false)
    const [drugAPercentageMixture, setdrugAPercentageMixture] = useState(null)
    const [drugBPercentageMixture, setdrugBPercentageMixture] = useState(null)
    const [option, setOption] = useState([])
    const [show, setShow] = useState(false)
    const mixtureOptions = [
        { value: 'Individual', label: 'Individual' },
        { value: 'Mixture', label: "Mixture" },
    ];
    var totalReduction = 0;

    newComorbities.map((data) => {
        totalReduction = totalReduction + parseInt(data.value) / 100
    })
    const [checked, setChecked] = useState(false);


    const [refresh, setrefresh] = useState({})

    const [doc, setDoc] = useState(false)
    const [doc1, setDoc1] = useState(false)

    const dispatch = useDispatch()


    useEffect(() => {
        getLAMAXData()
        dispatch(setNewConc(""))
        dispatch(setNewConc2(""))
        dispatch(setNewAdminValue(""))
        dispatch(setNewAdminValue2(""))


    }, [])

    useEffect(() => {

    }, [refresh])

    const getLAMAXData = () => {
        var token = sessionStorage.getItem('token')
        fetch(API_ROOT + `la-max-drugs?token=${token}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(res => {
                if (res.status === "true" && res.message === "LA Max Drugs") {
                    setDrugData(res.data.drugs)
                } else {
                    alert(res.message)
                }
            })

    }

    var obj = {
        name: "",
        pValue: "",
        eValue: ""
    }

    const medTypes = [
        { value: 'Plain', label: 'Plain' },
        { value: 'Epinephrine', label: "W/Epi" },
    ];


    var resultValue1 = 0;
    var resultValue2 = 0;

    const countCheck = () => {
        setCheckedNum(checkedNum + 1)
        setChecked(!checked)
    }

    const onClickDrugA = (value, pValue, eValue) => {
        if (value !== "Drug A(Not Listed)") {


            obj = {
                name: value,
                pValue: pValue,
                eValue: eValue
            }
            anesthesiaData.push(obj)
            dispatch(setNewDrugMixture1(obj))
            arr.push(value)
            setDoc(false)
        } else {
            if (value === "Drug A(Not Listed)") {
                obj = {
                    name: value,
                    pValue: pValue,
                    eValue: eValue
                }
                anesthesiaData.push(obj)
                dispatch(setNewDrugMixture1(obj))
                arr.push(value)

                setDoc(!doc)
            }
        }
        setrefresh({})
    }


    const onClickDrugB = (value, pValue, eValue) => {
        if (value !== "Drug A(Not Listed)") {
            obj = {
                name: value,
                pValue: pValue,
                eValue: eValue
            }
            anesthesiaData2.push(obj)
            dispatch(setNewDrugMixture2(obj))
            arr2.push(value)
            setDoc1(false)
        } else {
            if (value === "Drug A(Not Listed)") {
                obj = {
                    name: value,
                    pValue: pValue,
                    eValue: eValue
                }
                anesthesiaData2.push(obj)
                dispatch(setNewDrugMixture2(obj))
                arr2.push(value)

                setDoc1(!doc1)

            }
        }
        setrefresh({})
    }

    const onChangeAdminValue = (value) => {
        dispatch(setNewAdminValue(value));
    }

    const onChangeAdminValue2 = (value) => {
        dispatch(setNewAdminValue2(value));
    }

    const reset = () => {
        dispatch(setNewDrugMixture1([]))
    }
    const reset2 = () => {
        dispatch(setNewDrugMixture2([]))
    }
    const reset3 = () => {
        dispatch(setmixtureValueA([]))
    }

    const [mix, setMix] = useState(null)
    const [showmix, setShowMix] = useState(false)
    const mixtureChange = (name) => {
        setMix(name.value)
        if (name.value === "Mixture") {
            dispatch(setNewAdminValue(""))
            dispatch(setNewAdminValue2(""))
            dispatch(setNewConc(""))
            dispatch(setNewConc2(""))

            setDrugBstate(true)
        } else {
            dispatch(setNewAdminValue(""))
            dispatch(setNewAdminValue2(""))
            dispatch(setNewConc(""))
            dispatch(setNewConc2(""))

            setDrugBstate(false)

        }
        setShowMix(!showmix)
    }

    const mixtureRatioOptions = [
        { value: "1:1", label: "1:1 Mixture Of Drug A1 : Drug A2" },
        { value: "1:2", label: "1:2 Mixture Of Drug A1 : Drug A2" },
        { value: "1:3", label: "1:3 Mixture Of Drug A1 : Drug A2" },
        { value: "1:4", label: "1:4 Mixture Of Drug A1 : Drug A2" },

    ]


    const [drugAPercent, setDrugAPercent] = useState(0)
    const [drugBPercent, setDrugBPercent] = useState(0)
    const setMixValue = (val) => {
        dispatch(setmixtureValueA(val))
        setdrugAPercentageMixture(parseInt(val.value[0]))
        setdrugBPercentageMixture(parseInt(val.value[2]))
        setDrugAPercent((parseInt(val.value[0]) / (parseInt(val.value[0]) + parseInt(val.value[2]))) * 100)
        setDrugBPercent((parseInt(val.value[2]) / (parseInt(val.value[0]) + parseInt(val.value[2]))) * 100)

    }

    var mixtureA = 0
    var mixtureB = 0
    const handleClose = () => { setShow(false) };

    return (
        <div>
            {/* <LamaxSubHeader name="Return to Step-2" text="LOCAL ANESTHETIC MAX" /> */}
            <div className="rev stick-lamax">
            <h5 className="text-info pl-2" style={{position:'relative'}}>
                <Link className="arrow" onClick={() => {
                    context.changeActiveId(216)
                    dispatch(setNewAdminValue(""))
                    dispatch(setNewAdminValue2(""))
                    dispatch(setNewConc(""))
                    dispatch(setNewConc2(""))
                    dispatch(setNewDrugMixture1(""))
                    dispatch(setNewDrugMixture2(""))


                }}><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to LA MAX Step-2</span></span></Link>LOCAL ANESTHETIC MAX<span className="moreInfo" onClick={() => {setShow(true)}}>more information</span></h5>
            </div>

            <div className="rev">

                <div className="p-2" style={{ margin: '0 auto', background: 'rgb(242, 242, 242)' }}>
                    <div className="text-center p-3 mt-3">
                        <div><strong>2nd Drug Admin</strong></div>
                    </div>
                    <div className="frm-field">
                        <div style={{ height: 1, width: '28%', backgroundColor: 'lightgray', marginTop: 15 }}></div>
                        <div style={{ height: 30, backgroundColor: 'gray', width: '43%', borderRadius: '4%', textAlign: 'center', color: '#FFF', paddingTop: 5, fontWeight: 600 }}>DRUG PREPARATION</div>
                        <div style={{ height: 1, width: '28%', backgroundColor: 'lightgray', marginTop: 15 }}></div>
                    </div><br />

                    <div className="fields row">
                        <div className="col-md-5 pt-2">
                            <strong>SELECT PREFERENCE</strong>
                        </div>

                        <div className="col-md-7 frm-field pl-5">
                            {/* <select className="custom-select" onChange={(value) => mixtureChange(value)} placeholder="select mixture type">
                                {mixtureOptions.map((data, key) => {
                                    return (
                                        <option key={key} value={data.value} defaultValue={mixtureOptions.filter(opt => opt.label === "Individual")}>{data.label}</option>
                                    )
                                })
                                }
                            </select> */}
                            <Select className="newLamaxDd" onChange={(value) => mixtureChange(value)} defaultValue={mixtureOptions.filter(opt => opt.label === "Individual")} placeholder="select mixture type" options={mixtureOptions} />


                        </div>
                    </div>

                    {
                        showmix ? <>
                            <div className="fields row">
                                <div className="col-md-5 pt-2"><strong>SELECT MIXTURE RATIO</strong></div>
                                <div className="col-md-7 pl-5 btn-group-vertical">
                                    {
                                        mixValueA.length === 0 ?
                                            <>
                                                {
                                                    mixtureRatioOptions.map((drug, i) => {
                                                        return (
                                                            <div className="btn med-name" style={{ height: 40 }}>
                                                                <h6 style={{ color: "#555" }} onClick={() => setMixValue(drug)}>{drug.label}</h6>
                                                            </div>
                                                        )
                                                    }
                                                    )
                                                }
                                            </>
                                            :
                                            <>
                                                <div className="med-name-2">
                                                    <h6 style={{ color: "#FFF" }} onClick={reset3} >{mixValueA.label}</h6>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                        </>
                            :
                            <>
                            </>
                    }

                    <div className="lamaxContent"><br />
                        <div className="frm-field">
                            <div style={{ height: 1, width: '28%', backgroundColor: 'lightgray', marginTop: 15 }}></div>
                            <div style={{ height: 30, backgroundColor: 'gray', width: '43%', borderRadius: '4%', textAlign: 'center', color: '#FFF', paddingTop: 3, fontWeight: 600 }}>SELECT DRUGS</div>
                            <div style={{ height: 1, width: '28%', backgroundColor: 'lightgray', marginTop: 15 }}></div>
                        </div>

                        <div className="mt-2 mb-2">
                            <div className="fields row">
                                <div className="col-md-5 pt-2"><strong>{mix !== "Mixture" ? "DRUG A" : "DRUG A1"}</strong></div>
                                <div className="col-md-7 pl-5 btn-group-vertical">

                                    {
                                        drug1.length === 0 ?
                                            <>
                                                {
                                                    drugData.map((drug, i) => {
                                                        return (
                                                            <div className="text-center btn" style={{ height: 40, width: '100%' }}>
                                                                <h6 style={{ color: "#555", marginTop:7 }} onClick={() => onClickDrugA(drug.name, drug.plain_max, drug.epinephrine_max)}>{drug.name}</h6>
                                                            </div>
                                                        )
                                                    }
                                                    )
                                                }
                                            </>
                                            :
                                            <>
                                                <div className="text-center btn active" style={{ height:40, width: '100%' }}>
                                                    <h6 style={{ color: "#FFF", marginTop:7 }} onClick={reset} >{drug1.name}</h6>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                            {
                                doc ?
                                    <div className="p-2" style={{ borderRadius: '12px', border: '1px solid rgba(206, 205, 205, 0.932)', margin: '10px auto' }}>
                                        <span>Max Dose Plain, mg/kg</span><input  className="ant-text-input" style={{ marginLeft: 25, marginBottom: 10, }} onChange={(e) =>{ setdrugANotPlainValue(e.target.value);drug1.pValue=e.target.value}} type="number"/>
                                        <br></br>
                                        <span>Max Dose W/Epi, mg/kg</span><input className="ant-text-input" style={{ marginLeft: 10 }} onChange={(e) =>{ setdrugANotEpiValue(e.target.value);drug1.eValue=e.target.value}} type="number"/>
                                        <br></br>
                                    </div>
                                    :
                                    <>
                                    </>
                            }
                            <div className="fields row mt-2">
                                <div className="col-md-5 pt-2"><strong>TYPE</strong></div>
                                <div className="col-md-7 btn-group pl-5">
                                    {
                                        medTypes.map((data, i) => {
                                            if (data.label === "Plain" && medType === "") {
                                                return (
                                                    <button style={{ height: 40 }} className="btn active weight-type-button-selected-of-patient-profile" onClick={() => dispatch(setMedType(data))}>{data.label}</button>
                                                )
                                            }
                                            else {
                                                if (data.value === medType.value) {
                                                    return (
                                                        <button style={{ height: 40 }} className="btn active weight-type-button-selected-of-patient-profile" onClick={() => dispatch(setMedType(data))}>{data.label}</button>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <button style={{ height: 40 }} className="btn weight-type-button-of-patient-profile" onClick={() => dispatch(setMedType(data))}>{data.label}</button>
                                                    )
                                                }
                                            }

                                        })
                                    }
                                </div>
                            </div>
                            <div className="p-2" style={{ borderRadius: '12px', border: '1px solid rgba(206, 205, 205, 0.932)', margin: '10px auto' }}>
                                <span>% Concentration</span><input type="number" value={newConc} className="ant-text-input" style={{ marginLeft: 25, marginBottom: 10, }} onChange={(e) => dispatch(setNewConc(e.target.value ))}  />
                                <br></br>
                                <span>Admin volume, ml </span><input type="number" className="ant-text-input" style={{ marginLeft: 10 }} value={newAdminValue} onChange={(e) => dispatch(setNewAdminValue(e.target.value))} onBlur={(e) => {
                                    if (mix !== "Mixture") {
                                        if (e.target.value !== "") {
                                            if (doc) {
                                                if (parseFloat(e.target.value) <= parseFloat((context.remaining / 100) * `${medType.label === "Plain" || medType === "" ? ((((drugANotPlainValue * weight2) / (newConc/100)).toFixed(0) - (((drugANotPlainValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000) : ((((drugANotEpiValue * weight2) / (newConc/100)).toFixed(0) - (((drugANotEpiValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)}`)) {
                                                    onChangeAdminValue(e.target.value);

                                                } else {
                                                    onChangeAdminValue(e.target.value);
                                                    if (isNaN(`${parseFloat((context.remaining / 100) * `${medType.label === "Plain" || medType === "" ? ((((drugANotPlainValue * weight2) / (newConc/100)).toFixed(0) - (((drugANotPlainValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000) : ((((drugANotEpiValue * weight2) / (newConc/100)).toFixed(0) - (((drugANotEpiValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)}`)}`)) {
                                                        alert("Please enter correct volume of remaining drug");
                                                        dispatch(setNewAdminValue(""));
                                                    } else {
                                                        alert(`Please enter less than or equal to ${parseFloat((context.remaining / 100) * `${medType.label === "Plain" || medType === "" ? ((((drugANotPlainValue * weight2) / (newConc/100)).toFixed(0) - (((drugANotPlainValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000) : ((((drugANotEpiValue * weight2) / (newConc/100)).toFixed(0) - (((drugANotEpiValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)}`)}ml`)
                                                        dispatch(setNewAdminValue(""));
                                                    }
                                                }

                                            } else {
                                                if (parseFloat(e.target.value) <= parseFloat((context.remaining / 100) * `${medType.label === "Plain" || medType === "" ? ((((drug1.pValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.pValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000) : ((((drug1.eValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.eValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)}`)) {
                                                    onChangeAdminValue(e.target.value);

                                                } else {
                                                    onChangeAdminValue(e.target.value);
                                                    if (isNaN(`${parseFloat((context.remaining / 100) * `${medType.label === "Plain" || medType === "" ? ((((drug1.pValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.pValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000) : ((((drug1.eValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.eValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)}`)}`)) {
                                                        alert("Please enter correct volume of remaining drug");
                                                        dispatch(setNewAdminValue(""));
                                                    } else {
                                                        alert(`Please enter less than or equal to ${parseFloat((context.remaining / 100) * `${medType.label === "Plain" || medType === "" ? ((((drug1.pValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.pValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000) : ((((drug1.eValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.eValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)}`)}ml`)
                                                        dispatch(setNewAdminValue(""));
                                                    }

                                                }
                                            }
                                        }

                                    } else {
                                        onChangeAdminValue(e.target.value)
                                    }

                                }} />

                            </div>
                            <br /><br />

                            <br></br>
                            {
                                drugBState ?
                                    <>
                                        <div>
                                            <div className="fields">
                                                <div className="mt-2 col-md-5"><strong>{mix !== "Mixture" ? "DRUG B" : "DRUG A2"}</strong></div>

                                                <div className="col-md-7 btn-group-vertical pl-5">

                                                    {
                                                        drug2.length === 0 ?
                                                            <>
                                                                {
                                                                    drugData.map((drug, i) => {
                                                                        return (
                                                                            <div className="text-center btn" style={{ height: 40, width: '100%' }}>
                                                                                <h6 style={{ color: "#555",marginTop:7 }} onClick={() => onClickDrugB(drug.name, drug.plain_max, drug.epinephrine_max)}>{drug.name}</h6>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    )
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <div className="text-center btn active" style={{ height: 40, width: '100%' }}>
                                                                    <h6 style={{ color: "#FFF", marginTop:7 }} onClick={reset2} >{drug2.name}</h6>
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                doc1 ?
                                                    <div className="p-2" style={{ borderRadius: '12px', border: '1px solid rgba(206, 205, 205, 0.932)', margin: '10px auto' }}>

                                                        <span>Max Dose Plain, mg/kg</span><input className="ant-text-input" style={{ marginLeft: 25, marginBottom: 10, }} onChange={(e) =>{ setdrugBNotPlainValue(e.target.value);drug2.pValue=e.target.value}} />
                                                        <br></br>
                                                        <span>Max Dose W/Epi, mg/kg</span> <input className="ant-text-input" style={{ marginLeft: 10 }} onChange={(e) =>{ setdrugBNotEpiValue(e.target.value);drug2.eValue=e.target.value}} />
                                                        <br></br>
                                                    </div>
                                                    :
                                                    <>
                                                    </>
                                            }

                                            <div className="fields row">
                                                <div className="col-md-5 pt-2"><strong className="pt-2">TYPE</strong></div>
                                                <div className="col-md-7 pl-5 btn-group">
                                                    {
                                                        medTypes.map((data, i) => {
                                                            if (data.label === "Plain" && medType2 === "") {
                                                                return (
                                                                    <button style={{ height: 40 }} className="btn active weight-type-button-selected-of-patient-profile" onClick={() => dispatch(setMedType2(data))}>{data.label}</button>
                                                                )
                                                            }
                                                            else {
                                                                if (data.value === medType2.value) {
                                                                    return (
                                                                        <button style={{ height: 40 }} className="btn active weight-type-button-selected-of-patient-profile" onClick={() => dispatch(setMedType2(data))}>{data.label}</button>
                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                        <button style={{ height: 40 }} className="btn weight-type-button-of-patient-profile" onClick={() => dispatch(setMedType2(data))}>{data.label}</button>
                                                                    )
                                                                }
                                                            }

                                                        })
                                                    }
                                                </div>
                                            </div>

                                            <div className="p-2" style={{ borderRadius: '12px', border: '1px solid rgba(206, 205, 205, 0.932)', margin: '10px auto' }}>
                                                <span>% Concentration <input type="number" value={newConc2 } className="ant-text-input" style={{ marginLeft: 25, marginBottom: 10, }} onChange={(e) =>  dispatch(setNewConc2(e.target.value)) }></input></span>
                                                <br></br>
                                                {
                                                    mix !== "Mixture" ?
                                                        <span>Admin volume, ml <input type="number" className="ant-text-input" style={{ marginLeft: 10 }} onBlur={(e) => {
                                                            {
                                                                if (e.target.value !== "") {
                                                                    if (doc1) {
                                                                        if (parseFloat(e.target.value) <= parseFloat((remainingDrug / 100) * `${medType2.label === "Plain" || medType2 === "" ? ((((drugBNotPlainValue * weight2) / (newConc2/100)).toFixed(0) - (((drugBNotPlainValue * weight2) / (newConc2/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000) : ((((drugBNotEpiValue * weight2) / (newConc2/100)).toFixed(0) - (((drugBNotEpiValue * weight2) / (newConc2/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)}`)) {
                                                                            onChangeAdminValue2(e.target.value);

                                                                        } else {
                                                                            onChangeAdminValue2(e.target.value);
                                                                            alert(`Enter less than or equal to ${parseFloat((remainingDrug / 100) * `${medType2.label === "Plain" || medType2 === "" ? ((((drugBNotPlainValue * weight2) / (newConc2/100)).toFixed(0) - (((drugBNotPlainValue * weight2) / (newConc2/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000) : ((((drugBNotEpiValue * weight2) / (newConc2/100)).toFixed(0) - (((drugBNotEpiValue * weight2) / (newConc2/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)}`)} ml`)
                                                                        }
                                                                    } else {
                                                                        if (parseFloat(e.target.value) <= parseFloat((remainingDrug / 100) * `${medType2.label === "Plain" || medType2 === "" ? ((((drug2.pValue * weight2) / (newConc2/100)).toFixed(0) - (((drug2.pValue * weight2) / (newConc2/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000) : ((((drug2.eValue * weight2) / (newConc2/100)).toFixed(0) - (((drug2.eValue * weight2) / (newConc2/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)}`)) {
                                                                            onChangeAdminValue2(e.target.value);

                                                                        } else {
                                                                            onChangeAdminValue2(e.target.value);
                                                                            alert(`Enter less than or equal to ${parseFloat((remainingDrug / 100) * `${medType2.label === "Plain" || medType2 === "" ? ((((drug2.pValue * weight2) / (newConc2/100)).toFixed(0) - (((drug2.pValue * weight2) / (newConc2/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000) : ((((drug2.eValue * weight2) / (newConc2/100)).toFixed(0) - (((drug2.eValue * weight2) / (newConc2/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)}`)} ml`)
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                        }}  ></input></span>
                                                        : <></>
                                                }
                                            </div>

                                            <br></br>
                                            <div>

                                            </div>

                                        </div>
                                        {/* </div> */}
                                    </>
                                    :
                                    <>
                                    </>
                            }

                            {
                                mix !== "Mixture" ? <button style={{ height: 40 , width:'40%'}} className="btn add-drug-btn" onClick={() => { setDrugBstate(!drugBState); if (drugBState) { dispatch(setNewDrugMixture2("")); dispatch(setNewAdminValue2("")); dispatch(setNewConc2("")) } }} >{drugBState === false ? "ADD DRUG" : "REMOVE DRUG"} {drugBState === false ? <i className="fa fa-plus-circle">&nbsp;</i> : <i className="fa fa-minus-circle">&nbsp;</i>}</button>
                                    : <></>
                            }

                        </div>
                        <div className="green-bg text-white p-2 stick" >
                            {
                                doc ?
                                    <div className="green-bg text-white p-2 stick">
                                        <h6 style={{ textDecoration: 'underline' }} >GIVEN</h6>
                                        {
                                            newConc !== "" && drug1.length !== 0 && newAdminValue !== null ?
                                                <>
                                                    {
                                                        mix !== "Mixture" ?
                                                            <>
                                                                <h6>{newConc !== "" && drug1.length !== 0 ? parseFloat(context.givenLaMax) + parseFloat(drug1.length !== 0 && newAdminValue !== ""? parseFloat(`${medType.label === "Plain" || medType === "" ? (((newAdminValue / ((((drugANotPlainValue * weight2) / (newConc/100)).toFixed(0) - (((drugANotPlainValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) * 100)).toFixed(2) : (((newAdminValue / ((((drugANotEpiValue * weight2) / (newConc/100)).toFixed(0) - (((drugANotEpiValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) * 100)).toFixed(2)}`):parseFloat(0)) + parseFloat(`${drug2.length !== 0 && newAdminValue2 !== "" ? parseFloat(parseFloat(`${medType2.label === "Plain" || medType2 === "" ? (((newAdminValue2 / ((((drugBNotPlainValue * weight2) / (newConc2/100)).toFixed(0) - (((drugBNotPlainValue * weight2) / (newConc2/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) * 100)).toFixed(2) : (((newAdminValue2 / ((((drugBNotEpiValue * weight2) / (newConc2/100)).toFixed(0) - (((drugBNotEpiValue * weight2) / (newConc2/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) * 100)).toFixed(2)}`)) : parseFloat(0)}`) : "____"}% of total LA MAX – 1st & 2nd Admin</h6>

                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        mix === "Mixture" ? <h6>{newConc !== "" && newConc2 !== "" && newAdminValue !== "" && drug1!=="" ? (parseFloat(context.givenLaMax) + parseFloat( parseFloat(drug1.length !== 0 && newAdminValue !== ""?(parseFloat(((newConc/100) * 100) * 10 * newAdminValue) / parseFloat(`${medType.label === "Plain" || medType === "" ? parseFloat(drugANotPlainValue * weight2) : parseFloat(drugANotEpiValue * weight2)}`)):parseFloat(0)) + parseFloat(parseFloat(((newConc2/100) * 100) * 10 * drugBPercentageMixture * parseFloat(newAdminValue)) / parseFloat(`${medType2.label === "Plain" || medType2 === "" ? parseFloat(drugBNotPlainValue * weight2) : parseFloat(drugBNotEpiValue * weight2)}`))) * 100).toFixed(2) : "___"}% of total LA MAX – 1st & 2nd Admin</h6> :
                                                            <></>
                                                    }
                                                </> : <>
                                                    <h6 className="p-3">{parseFloat(context.givenLaMax)}% of total lamax given</h6>

                                                </>
                                        }


                                        <h6>2nd Drug Admin Summary</h6>
                                        {
                                            mix !== "Mixture" ?
                                                <>
                                                    {medType.label === "Plain" || medType === "" ?
                                                        <>

                                                            <h6>{newAdminValue !== "" && newConc !== "" && drug1.length !== 0 && weight2 !== "" ? `${parseFloat(newAdminValue) <= parseFloat((context.remaining / 100) * ((((drugANotPlainValue * weight2) / (((newConc/100) * 100) / 100)).toFixed(0) - (((drugANotPlainValue * weight2) / (((newConc/100) * 100) / 100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) ? `${newAdminValue}ml  of Drug A` : `Please input less than or equal to ${parseFloat((context.remaining / 100) * ((((drugANotPlainValue * weight2) / (((newConc/100) * 100) / 100)).toFixed(0) - (((drugANotPlainValue * weight2) / (newAdminValue / 100)).toFixed(0) * totalReduction)).toFixed(2) / 1000))} ml`}` : "____ml of Drug A"}</h6>
                                                            <h6 style={{ color: '#254540' }} >({newConc !== "" && drug1.length !== 0 && weight2 !== "" ? remainingDrug = (parseFloat(context.remaining) - parseFloat((((newAdminValue / ((((drugANotPlainValue * weight2) / (newConc/100)).toFixed(0) - (((drugANotPlainValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) * 100)).toFixed(2))).toFixed(2) : "____"}% of Drug B available)</h6>

                                                        </>
                                                        :
                                                        <>
                                                            <h6>{newAdminValue !== "" && newConc !== "" && drug1.length !== 0 && weight2 !== "" ? `${parseFloat(newAdminValue) <= parseFloat((context.remaining / 100) * ((((drugANotEpiValue * weight2) / (newConc/100)).toFixed(0) - (((drugANotEpiValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) ? `${newAdminValue}ml  of Drug A` : `Please input less than or equal to ${parseFloat((context.remaining / 100) * ((((drugANotEpiValue * weight2) / (newConc/100)).toFixed(0) - (((drugANotEpiValue * weight2) / (newAdminValue / 100)).toFixed(0) * totalReduction)).toFixed(2) / 1000))} ml`}` : "____ ml of Drug A "}</h6>
                                                            <h6 style={{ color: '#254540' }} >({newConc !== "" && drug1.length !== 0 && weight2 !== "" ? remainingDrug = (parseFloat(context.remaining) - parseFloat((((newAdminValue / ((((drugANotEpiValue * weight2) / (newConc/100)).toFixed(0) - (((drugANotEpiValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) * 100)).toFixed(2)).toFixed(2)).toFixed(2) : ""}% of Drug B available)</h6>

                                                        </>
                                                    }
                                                </>
                                                : <></>
                                        }
                                        {mix === "Mixture" ? <h6>{drug1.length !== 0 && newAdminValue !== "" ? mixtureA = (newAdminValue * drugAPercentageMixture).toFixed(2) : '____'} ml of Drug A1</h6>
                                            : <></>}

                                    </div>
                                    :
                                    <div className="green-bg text-white p-2 stick">
                                        <h6 style={{ textDecoration: 'underline' }} >GIVEN</h6>
                                        {
                                            newConc !== "" && drug1.length !== 0 && newAdminValue !== "" ?
                                                <>
                                                    {
                                                        mix !== "Mixture" ?
                                                            <> 
                                                                <h6>{newConc !== "" && drug1.length !== 0 ? parseFloat(context.givenLaMax) + parseFloat(drug1.length !== 0 && newAdminValue !== ""? parseFloat(`${medType.label === "Plain" || medType === "" ? (((newAdminValue / ((((drug1.pValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.pValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) * 100)).toFixed(2) : (((newAdminValue / ((((drug1.eValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.eValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) * 100)).toFixed(2)}`):parseFloat(0)) + parseFloat(`${drug2.length !== 0 && newAdminValue2 !== "" ? parseFloat(parseFloat(`${medType2.label === "Plain" || medType2 === "" ? (((newAdminValue2 / ((((drug2.pValue * weight2) / (newConc2/100)).toFixed(0) - (((drug2.pValue * weight2) / (newConc2/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) * 100)).toFixed(2) : (((newAdminValue2 / ((((drug2.eValue * weight2) / (newConc2/100)).toFixed(0) - (((drug2.eValue * weight2) / (newConc2/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) * 100)).toFixed(2)}`)) : parseFloat(0)}`) : "____"}% of total LA MAX – 1st & 2nd Admin</h6>
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        mix === "Mixture" ? <h6>{newConc !== "" && newConc2 !== "" && newAdminValue !== "" && drug1!=="" ? (parseFloat(context.givenLaMax) + parseFloat(parseFloat(drug1.length !== 0 && newAdminValue !== ""? (parseFloat(((newConc/100) * 100) * 10 * newAdminValue) / parseFloat(`${medType.label === "Plain" || medType === "" ? parseFloat(drug1.pValue * weight2) : parseFloat(drug1.eValue * weight2)}`)):parseFloat(0)) + parseFloat(parseFloat(((newConc2/100) * 100) * 10 * drugBPercentageMixture * parseFloat(newAdminValue)) / parseFloat(`${medType2.label === "Plain" || medType2 === "" ? parseFloat(drug2.pValue * weight2) : parseFloat(drug2.eValue * weight2)}`))) * 100).toFixed(2) : "___"}% of total LA MAX – 1st & 2nd Admin </h6> :
                                                            <></>
                                                    }

                                                </> : <>
                                                    <h6 className="p-3">{parseFloat( context.givenLaMax)}% of total lamax given</h6>

                                                </>
                                        }

                                        <h6>2nd Drug Admin Summary</h6>

                                        {
                                            mix !== "Mixture" ?
                                                <>

                                                    {medType.label === "Plain" || medType === "" ?
                                                        <>
                                                            
                                                            <h6>{newConc !== "" && drug1.length !== 0 ? `${parseFloat(newAdminValue) <= parseFloat((context.remaining / 100) * ((((drug1.pValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.pValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) ? `${newAdminValue}ml of Drug A` : ` ${isNaN(parseFloat((context.remaining / 100) * ((((drug1.pValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.pValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000))) ? "Please select a drug": "Please input less than or equal to " + parseFloat((context.remaining / 100) * ((((drug1.pValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.pValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) + ' ml' }`}` : "____ml of Drug A"}</h6>

                                                            <h6 style={{ color: '#254540' }} >({newConc !== "" && drug1.length !== 0 && weight2 !== "" ? remainingDrug = (parseFloat(context.remaining) - parseFloat((((newAdminValue / ((((drug1.pValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.pValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) * 100)).toFixed(2))).toFixed(2) : ""}% of Drug B available)</h6>

                                                        </>
                                                        :
                                                        <>

                                                            {/* <h6>{newConc !== "" && drug1 !== null ? `${parseFloat(newAdminValue) <= parseFloat((context.remaining / 100) * ((((drug1.eValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.eValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) ? `${newAdminValue}ml of Drug A` : `Please input less than or equal to ${parseFloat((context.remaining / 100) * ((((drug1.eValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.eValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000))} ml`}` : "____ml of Drug A"}</h6> */}
                                                            <h6>{newConc !== "" && drug1.length !== 0 ? `${parseFloat(newAdminValue) <= parseFloat((context.remaining / 100) * ((((drug1.pValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.pValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) ? `${newAdminValue}ml of Drug A` : ` ${isNaN(parseFloat((context.remaining / 100) * ((((drug1.pValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.pValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000))) ? "Please select a drug": "Please input less than or equal to " + parseFloat((context.remaining / 100) * ((((drug1.pValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.pValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) + ' ml' }`}` : "____ml of Drug A"}</h6>

                                                            <h6 style={{ color: '#254540' }} >({newConc !== "" && drug1.length !== 0 && weight2 !== "" ? remainingDrug = (parseFloat(context.remaining) - parseFloat((((newAdminValue / ((((drug1.eValue * weight2) / (newConc/100)).toFixed(0) - (((drug1.eValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) * 100)).toFixed(2)).toFixed(2)).toFixed(2) : ""}% of Drug B available)</h6>
                                                        </>
                                                    }
                                                </>
                                                : <></>
                                        }

                                        {mix === "Mixture" ? <h6>{drug1.length !== 0 && newAdminValue !== "" ? mixtureA = (newAdminValue * drugAPercentageMixture).toFixed(2) : '____'} ml of Drug A1</h6>
                                            : <></>}

                                        {/* <h6>{(newAdminValue / resultValue1) * 100} ml of drug A remaining.</h6> */}


                                    </div>
                            }
                            {
                                doc1 ?
                                    <div className="green-bg text-white p-2 stick">
                                        {
                                            mix !== "Mixture" ?
                                                <>
                                                    {
                                                        medType2.label === "Plain" || medType2 === "" ?
                                                            <>
                                                                {newConc2 !== "" && drug2.length !== 0 && newAdminValue2 !== "" ?
                                                                    <h6>{newConc2 !== "" && drug2.length !== 0 && newAdminValue2 !== "" ? `${parseFloat(newAdminValue2) <= parseFloat((remainingDrug / 100) * ((((drugBNotPlainValue * weight2) / (newConc2/100)).toFixed(0) - (((drugBNotPlainValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) ? `${newAdminValue2}ml of Drug B` : `Please input less than or equal to ${parseFloat((remainingDrug / 100) * ((((drugBNotPlainValue * weight2) / (newConc2/100)).toFixed(0) - (((drugBNotPlainValue * weight2) / (newConc2/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000))} ssml`}` : "____ml of Drug B "}</h6>
                                                                    : <></>
                                                                }</>
                                                            :
                                                            <>
                                                                {
                                                                    newConc2 !== "" && drug2.length !== 0 && newAdminValue2 !== "" ?
                                                                        <h6> {newConc2 !== "" && drug2.length !== 0 && newAdminValue2 !== "" ? `${parseFloat(newAdminValue2) <= parseFloat((remainingDrug / 100) * ((((drugBNotEpiValue * weight2) / (newConc2/100)).toFixed(0) - (((drugBNotEpiValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) ? `${newAdminValue2}ml of Drug B` : `Please input less than or equal to ${parseFloat((remainingDrug / 100) * ((((drugBNotEpiValue * weight2) / (newConc2/100)).toFixed(0) - (((drugBNotEpiValue * weight2) / (newConc2/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000))} smml`}` : "____ml of Drug B "}</h6>
                                                                        : <></>
                                                                }
                                                            </>

                                                    }
                                                </>
                                                : <></>
                                        }



                                        {mix === "Mixture" ? <h6>{drug2.length !== 0 && newConc2 !== "" ? mixtureB = (newAdminValue * drugBPercentageMixture).toFixed(2) : "____"} ml of Drug A2</h6> : <></>}
                                        {/*
                                                            mix==="Mixture"?
                                                            <>
                                                            {drug2!==""&&newConc2!==""&&drug1!==""&&newConc!==""?
                                                            <>
                                                            <h6>100 % total lamax given</h6>
                                                            <h6>Max available dose of Mixture A1:A2 is {  parseFloat(drug1!==""&&newConc!==""?(medType.label === "Plain" || medType === ""?result1=(((((drugANotPlainValue*weight2)/((newConc*100)/100)).toFixed(0)-(((drugANotPlainValue*weight2)/((newConc*100)/100)).toFixed(0)*totalReduction)).toFixed(2)/1000)*(drugAPercent/100)*(context.remaining/100)).toFixed(2):(((((drugANotPlainValue*weight2)/((newConc*100)/100)).toFixed(0)-(((drugANotPlainValue*weight2)/((newConc*100)/100)).toFixed(0)*totalReduction)).toFixed(2)/1000)*(drugAPercent/100)*(context.remaining/100)).toFixed(2)):0)+parseFloat(drug2!==""&&newConc2!==""?(medType2.label === "Plain" || medType2 === ""?result2=(((((drugBNotPlainValue*weight2)/((newConc2*100)/100)).toFixed(0)-(((drugBNotPlainValue*weight2)/((newConc2*100)/100)).toFixed(0)*totalReduction)).toFixed(2)/1000)*(drugBPercent/100)*(context.remaining/100)).toFixed(2):(((((drugBNotEpiValue*weight2)/((newConc2*100)/100)).toFixed(0)-(((drugBNotEpiValue*weight2)/((newConc2*100)/100)).toFixed(0)*totalReduction)).toFixed(2)/1000)*(drugBPercent/100)*(context.remaining/100)).toFixed(2)):"0")} ml</h6>
                                                            </>
                                                            :<></>
                                                            }
                                                            </>
                                                            :<></>
                                                        */}
                                        {mix === "Mixture" ? <><h6 style={{ textDecoration: 'underline' }} >AVAILABLE</h6> <h6>
                                            Max available dose of Mixture A1:A2 is {newConc !== "" && newConc2 !== "" && newAdminValue !== "" ? ((parseFloat(parseFloat(`${medType.label === "Plain" || medType === "" ? drugANotPlainValue * weight2 * parseFloat((((100-parseFloat( context.givenLaMax))/100)*(drugAPercent/100)).toFixed(2)) : drugANotEpiValue * weight2 * ((((100-parseFloat( context.givenLaMax))/100)*(drugAPercent/100)).toFixed(2))}`) / parseFloat(((newConc/100) * 100) * 10)) + parseFloat(parseFloat(`${medType2 === "Plain" || medType2 === "" ? drugBNotPlainValue * weight2 * ((((100-parseFloat( context.givenLaMax))/100)*(drugBPercent/100)).toFixed(2)) : drugBNotEpiValue * weight2 * ((((100-parseFloat( context.givenLaMax))/100)*(drugBPercent/100)).toFixed(2))}`) / parseFloat(((newConc2/100) * 100) * 10))) - parseFloat(parseFloat(mixtureA) + parseFloat(mixtureB))).toFixed(2) : "____"} mL</h6> </> : <></>}


                                        {/*  <h6>{(newAdminValue2 / resultValue2) * 100} ml of drug B remaining.</h6>*/}
                                    </div>
                                    :
                                    <div className="green-bg text-white p-2 stick">
                                        {/*   <h6>{medType2.label === "Plain" || medType2 === "" ? resultValue2 = (((drug2.pValue * weight2) / newConc2).toFixed(0) - ((drug2.pValue * weight2) / newConc2).toFixed(0) * totalReduction).toFixed(0) / 1000 : resultValue2 = (((drug2.eValue * weight2) / newConc2).toFixed(0) - ((drug2.eValue * weight2) / newConc2).toFixed(0) * totalReduction).toFixed(0) / 1000}  % of total LA MAX given.</h6>*/}
                                        {
                                            mix !== "Mixture" ?
                                                <>
                                                    {
                                                        medType2.label === "Plain" || medType2 === "" ?
                                                            <>
                                                                {newConc2 !== "" && drug2.length !== 0 && newAdminValue2 !== "" ?
                                                                    <h6>{newConc2 !== "" && drug2.length !== 0 ? `${parseFloat(newAdminValue2) <= parseFloat((remainingDrug / 100) * ((((drug2.pValue * weight2) / (newConc2/100)).toFixed(0) - (((drug2.pValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) ? `${newAdminValue2}ml of Drug B ` : `Please input less than or equal to ${parseFloat((remainingDrug / 100) * ((((drug2.pValue * weight2) / (newConc2/100)).toFixed(0) - (((drug2.pValue * weight2) / (newConc2/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000))} ml`}` : "____ml of Drug B "}</h6>
                                                                    : <></>

                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {newConc2 !== "" && drug2.length !== 0 && newAdminValue2 !== "" ?
                                                                    <h6>{newConc2 !== "" && drug2.length !== 0 ? `${parseFloat(newAdminValue2) <= parseFloat((remainingDrug / 100) * ((((drug2.eValue * weight2) / (newConc2/100)).toFixed(0) - (((drug2.eValue * weight2) / (newConc/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000)) ? `${newAdminValue2}ml of Drug B ` : `Please input less than or equal to ${parseFloat((remainingDrug / 100) * ((((drug2.eValue * weight2) / (newConc2/100)).toFixed(0) - (((drug2.eValue * weight2) / (newConc2/100)).toFixed(0) * totalReduction)).toFixed(2) / 1000))} ml`}` : "____ml of Drug B "}</h6>
                                                                    : <></>
                                                                }
                                                            </>

                                                    }
                                                </>
                                                : <></>
                                        }

                                        <br></br>
                                        {mix === "Mixture" ? <h6>{drug2.length !== 0 && newConc2 !== "" ? mixtureB = (newAdminValue * drugBPercentageMixture).toFixed(2) : "____"} ml of Drug A2</h6> : <></>}
                                        {
                                            mix === "Mixture" ?
                                                <>
                                                    {/*
                                                                drug1!==""&&newConc!==""?
                                                                <>
                                                                <h6>100 % total lamax given</h6>
                                                                <h6>Max available dose of Mixture A1:A2 is {  parseFloat(drug1!==""&&newConc!==""?(medType.label === "Plain" || medType === ""?result1=(((((drug1.pValue*weight2)/((newConc*100)/100)).toFixed(0)-(((drug1.pValue*weight2)/((newConc*100)/100)).toFixed(0)*totalReduction)).toFixed(2)/1000)*(drugAPercent/100)*(context.remaining/100)).toFixed(2):(((((drug1.eValue*weight2)/((newConc*100)/100)).toFixed(0)-(((drug1.eValue*weight2)/((newConc*100)/100)).toFixed(0)*totalReduction)).toFixed(2)/1000)*(drugAPercent/100)*(context.remaining/100)).toFixed(2)):0)+parseFloat(drug2!==""&&newConc2!==""?(medType2.label === "Plain" || medType2 === ""?result2=(((((drug2.pValue*weight2)/((newConc2*100)/100)).toFixed(0)-(((drug2.pValue*weight2)/((newConc2*100)/100)).toFixed(0)*totalReduction)).toFixed(2)/1000)*(drugBPercent/100)*(context.remaining/100)).toFixed(2):(((((drug2.eValue*weight2)/((newConc2*100)/100)).toFixed(0)-(((drug2.eValue*weight2)/((newConc2*100)/100)).toFixed(0)*totalReduction)).toFixed(2)/1000)*(drugBPercent/100)*(context.remaining/100)).toFixed(2)):"0")} ml</h6>
                                                            
                                                                </>
                                                                :
                                                                <></>
                                                            */}
                                                    {mix === "Mixture" ?
                                                        <><h6 style={{ textDecoration: 'underline' }} >AVAILABLE</h6> <h6>​Max available dose of Mixture A1:A2 is {newConc !== "" && newConc2 !== "" && newAdminValue !== "" ? ((parseFloat(parseFloat(`${medType.label === "Plain" || medType === "" ? drug1.pValue * weight2 * parseFloat( (((100-parseFloat( context.givenLaMax))/100)*(drugAPercent/100)).toFixed(2)) : drug1.eValue * weight2 * ((((100-parseFloat( context.givenLaMax))/100)*(drugAPercent/100)).toFixed(2))}`) / parseFloat(((newConc/100) * 100) * 10)) + parseFloat(parseFloat(`${medType2 === "Plain" || medType2 === "" ? drug2.pValue * weight2 * ((((100-parseFloat( context.givenLaMax))/100)*(drugBPercent/100)).toFixed(2)) : drug2.eValue * weight2 * ((((100-parseFloat( context.givenLaMax))/100)*(drugBPercent/100)).toFixed(2))}`) / parseFloat(((newConc2/100) * 100) * 10))) - parseFloat(parseFloat(mixtureA) + parseFloat(mixtureB))).toFixed(2) : "____"} mL</h6></> : <></>}

                                                </>
                                                : <></>
                                        }


                                    </div>
                            }
                        </div>



                    </div>
                    <br></br>
                    <div className="frm-field responsive-containr" style={{ justifyContent: 'space-between' }}>
                        <div className="save-button-container" style={{ float: "left" }} onClick={(e) => {
                            e.preventDefault(); context.changeActiveId(216)
                            dispatch(setNewAdminValue(""))
                            dispatch(setNewAdminValue2(""))
                            dispatch(setNewConc(""))
                            dispatch(setNewConc2(""))
                            dispatch(setNewDrugMixture1(""))
                            dispatch(setNewDrugMixture2(""))

                        }}><i style={{ marginRight: 15 }} className="fas fa-play rotate"></i>Back</div>
                        <div className="save-button-container" style={{ float: "right" }} onClick={() => {
                            context.changeActiveId(null)
                            dispatch(setNewLamaxWeight(""))
                            dispatch(setNewLamaxHeight(""))
                            dispatch(setNewGenderType(""))
                            dispatch(setNewHeightType(""))
                            dispatch(setNewWeightType(""))
                            dispatch(setNewAdminValue(""))
                            dispatch(setNewAdminValue2(""))
                            dispatch(setNewConc(""))
                            dispatch(setNewConc2(""))
                            dispatch(setNewDrugMixture1(""))
                            dispatch(setNewDrugMixture2(""))
                            props.closeLAMAXAfterFinish()
                        }}>Finish<i style={{ marginLeft: 15 }} className="fas fa-play ">&nbsp;&nbsp;&nbsp;</i></div>
                    </div>
                </div >
                <br />
            </div>
            <div onClick={() => {
                  props.navigateToLast("CE_1321")
            }} className="crises-button" >
                LAST
            </div>
            {show ?
            <div className="static-modal" style={{backgroundColor: 'rgba(0,0,0,0.1)', width:'100%',border:'1px solid red', height:'100vh',left:0,top:0, position:'fixed', zIndex:999, alignItems:'center'}}>
                <Modal.Dialog  style={{ position: 'absolute'}} size="lg" className="modBox"

                >
                    <Modal.Header>
                        <Modal.Title>LA MAX Reference </Modal.Title>
                        <i className="fas fa-times" style={{ cursor: 'hand' }} onClick={handleClose}></i>
                    </Modal.Header>
                    <Modal.Body className="modbody labody">
                        <p style={{textDecoration:"underline"}}>LA MAX Calculator Details</p>
                        <p>The LA MAX calculator uses the following values to estimate the  weight based maximum dose for each medication: [shortcut to <span><a href="/druglink:localanesthesia">Drugs/Local Anesthetics</a></span>]</p>
                        <p>The calculator was designed to make sure each mixture of local anesthetics does not exceed 100% of the combined maximum dose.</p>
                        <p>**Caution – Mixing local anesthetics is not evidence-based practice. The potential for complications such as neurotoxicity is not well studied with mixing local anesthetics.</p>
                        <p>For more information, here is a review of adjuncts and mixing local anesthetics:  <a href="https://associationofanaesthetists-publications.onlinelibrary.wiley.com/doi/10.1111/anae.15641" target="_bamnk">Pharmacological and clinical implications of local anaesthetic mixtures: a narrative review (Anaesthesia 2022)</a></p>
                        </Modal.Body>
                </Modal.Dialog>
                
                </div> : <></>
            }
            
        </div >
    )
}

export default LAMAX1003