import React, { useEffect, useState } from 'react'
import '../css/RiskEvalution.css'
import { API_ROOT } from '../constants'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Spinner from '../CustomComponent/Spinner';
import AaO2Gradient from './AaO2Gradient';
import Abbey from './Abbey';
import FluidAbcScore from './FluidAbcScore';
import AnionGap from './AnionGap';
import Abg from './Abg'
import Acc from './Acc'
import ApgarScore from './ApgarScore'
import ARISCAT from './ARISCAT';
import ASAPS from './ASAPS'
import CCI from './CCI';
import IBW from './IBW';
import AldreteScore from './AldreteScore';
import ModifieldAldreteScore from './ModifieldAldreteScore';
import QuickSofa from './QuickSofa';
import BerlinForARDS from './BerlinForARDS';
import SirsSepsis from './SirsSepsis';
import DasiCalculator from './DasiCalculator';
import SOFA from './SOFA';
import ORT from './ORT';
import Mme from './Mme';
import BloodProductFluid from './BloodProductFluid';
import APACHEIIScore from './APACHEIIScore';
import Bops from './Bops';
import Bps from './Bps';
import Cheops from './Cheops';
import CiwaAr from './CiwaAr';
import GcsAdult from './GcsAdult';
import GcsPediatric from './GcsPediatric';
import SIRSpediatric from './SIRSpediatric';
import Nps from './Nps';
import Nvps from './Nvps';
import Sms from './Sms';
import StanfordSleepinessScale from './StanfordSleepinessScale';
import SteroidConversion from './SteroidConversion';
import BicarbonateDeficit from './BicarbonateDeficit';
import BishopScore from './BishopScore';
import Bpp from './Bpp';
import Care from './Care';
import CardicOutput from './CardicOutput';
import CardiacRiskIndex from './CardiacRiskIndex';
import CssCardio from './CssCardio';
import CHA2DS2VASc from './CHA2DS2VASc';
import DetskRiskIndex from './DetskRiskIndex';
import DialysisRiskClinic from './DialysisRiskClinic';
import DialysisRiskMehta from './DialysisRiskMehta';
import DRIP from './DRIP';
import EGRIAirwayCalculator from './EGRIAirwayCalculator';
import EttTidalAirwayCalculator from './EttTidalAirwayCalculator'
import EttPedAirwayCalculator from './EttPedAirwayCalculator'
import FenaFluidCalculator from './FenaFluidCalculator'
import FeureaFluidCalculator from './FeureaFluidCalculator'
import GuptaFailureRisk from './GuptaFailureRisk'
import GuptaPneumoniaRisk from './GuptaPneumoniaRisk'
import HasBledScore from './HasBledScore'
import HorowitzIndexAirwayCalculator from './HorowitzIndexAirwayCalculator'
import IfdaFluidCalculator from './IfdaFluidCalculator'
import RbcFluidCalculator from './RbcFluidCalculator'
import Must from './Must'
import MeternalFetalHemorrhage from './MeternalFetalHemorrhage'
import FluidMaximumAbl from './FluidMaximunAbl'
import MeanArterialCardiovascular from './MeanArterialCardiovascular'
import MallClassAirwayCalculator from './MallClassAirwayCalculator'
import NRI from './NRI';
import NRS2002 from './NRS2002';
import NUTRIC from './NUTRIC';
import NyhaCardiovascularCalculator from './NyhaCardiovascularCalculator'
import PlasmaFluidCalculator from './PlasmaFluidCalculator'
import PortFluidCalculator from './PortFluidCalculator'
import PulsePressureCardiovascular from './PulsePressureCardiovascular'
import RcriCardiovascularCalculator from './RcriCardiovascularCalculator'
import RIFLEFluidCalculator from './RIFLEFluidCalculator'
import RoxIndexAirwayCalculator from './RoxIndexAirwayCalculator'
import RsbiAirwayCalculator from './RsbiAirwayCalculator'
import FluidCalculator from './FluidsCalculator'
import SickleCellRBCExchangeVolume from './SickleCellRBCExchangeVolume'
import StopBangAirwayCalculator from './STOPBANGAirwayCalculator'
import TisdaleCardiovascularCalculator from './TisdaleCardiovascularCalculator'
import Vbac from './Vbac'
import PediatricCardiovascularcalculator from './PediatricCardiovascularcalculator'
import WintersFormula from './WintersFormula'
import UpperLipBiteTestAirwayCalculator from './UpperLipBiteTestAirwayCalculator'
import PreoperativeEvaluation from './PreoperativeEvaluation'
import FluidBloodVolume from './FluidBloodVolume'
import EuroScoreCardivascular from './EuroScoreCardivascular'
import Npo from './Npo';
import FourTScore from './FourTScore'
import CAGE from './CAGE';
import POSSUM from './POSSUM';
import Sas from './Sas';
import Frailty from './Frailty';
import BmiANDBsa from './BmiANDBsa';
import NSQIP from './NSQIP';
import ApfelScore from './ApfelScore';
import Pregnancy from './Pregnancy';
const RiskEvalution = (props) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
    };
    
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
        getData()
        

    }, [])

    useEffect(() => {
       

    }, [data])
    const getData = async () => {
        var token = sessionStorage.getItem("token")
        var url = API_ROOT + `all-risk-scores-names?token=${token}`
        const res = await axios.get(url)
        setData(res.data.data)
        setLoading(true)
    }

    const forwardData = (id, name) => {
        props.history.push({
            pathname: "/rcri",
            state: id,
            name: name
        })
    }


    return (
        <div>
            <div className="backNavigation text-center">
                <Link className="arrow" to={"/" + props.location.state.returnPath}><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to {props.location.state.sourceName}</span></span></Link> RISK EVALUATION
                <h4 className="hidn">CRISES</h4>
            </div>
            <div className="main-container risk-eval-content mt-3">
                <div className="row">
                    <div className="col-md-4">
                    <button className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(1)} >A-a O2 Gradient</button>
                    <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(2)} >Abbey</button>
                    <button className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(3)} >ABC Score for Massive Transfusion</button>
                    <button className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(4)} >ABG</button>
                    <button className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(5)} >ACC/AHA CV Risk Calculator (2013)</button>
                    <button className={toggleState === 6 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(6)} >Aldrete Score</button>
                    <button className={toggleState === 7 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(7)} >Aldrete (Modified) Score</button>
                    <button className={toggleState === 8 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(8)} >Anion Gap</button>
					 <button className={toggleState === 9 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(9)} >APACHE II</button>
					<button className={toggleState === 10  ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(10)} >Apfel</button>
					<button className={toggleState === 11 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(11)} >APGAR Score</button>
					<button className={toggleState === 12 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(12)} >ARISTOCAT</button>
					<button className={toggleState === 13 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(13)} >ASA-PS</button>
					<button className={toggleState === 14 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(14)} >Berlin Criteria for ARDS</button>
					<button className={toggleState === 15 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(15)} >Bicarbonate Deficit</button>
					<button className={toggleState === 16 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(16)} >Bishop Score</button>
					<button className={toggleState === 17 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(17)} >Blood Volume Calculation</button>
					<button className={toggleState === 18 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(18)} >BMI and BSA</button>
					<button className={toggleState === 19 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(19)} >BOPS</button>
					<button className={toggleState === 20 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(20)} >BPS</button>
					<button className={toggleState === 21 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(21)} >CAGE</button>
					<button className={toggleState === 22 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(22)} >CARE</button>
					<button className={toggleState === 23 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(23)} >Cardiac Output (Fick's Formula)</button>
					<button className={toggleState === 24 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(24)} >Cardiac Risk Index (Goldman Criteria)</button>
					<button className={toggleState === 25 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(25)} >CCI</button>
					<button className={toggleState === 26 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(26)} >CCS</button>
					<button className={toggleState === 27 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(27)} >CHA2DS2 VASc</button>
					<button className={toggleState === 28 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(28)} >CHEOPS</button>
					<button className={toggleState === 29 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(29)} >CIWA-Ar</button>
					<button className={toggleState === 30 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(30)} >DASI</button>
					<button className={toggleState === 31 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(31)} >Detsk Modified Cardiac Risk Index</button>
					<button className={toggleState === 32 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(32)} >Dialysis Risk After Cardiac Surgery (Cleveland Clinic Score by Thakar)</button>
					<button className={toggleState === 33 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(33)} >Dialysis Risk After Cardiac Surgery (Mehta)</button>
					<button className={toggleState === 34 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(34)} >DRIP</button>
					<button className={toggleState === 35 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(35)} >EGRI</button>
					<button className={toggleState === 36 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(36)} >ETT Depth (Adult) and Tidal Volume</button>
					<button className={toggleState === 37 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(37)} >ETT Dept (Pediatric)</button>
					<button className={toggleState === 38 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(38)} >EuroSCORE II</button>
					<button className={toggleState === 39 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(39)} >FBP</button>
                    <button className={toggleState === 40 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(40)} >FENa</button>
					<button className={toggleState === 41 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(41)} >FEUrea</button>
					<button className={toggleState === 42 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(42)} >Frailty</button>
					<button className={toggleState === 43 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(43)} >GCS - Adult</button>
					<button className={toggleState === 44 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(44)} >GCS - Pediatric</button>
					<button className={toggleState === 45 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(45)} >Gupta Postoperative Pneumomia Risk</button>
					<button className={toggleState === 46 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(46)} >Gupta Postoperative Respiratory Failure Risk</button>
					<button className={toggleState === 47 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(47)} >HAS-BLED Score</button>
					<button className={toggleState === 48 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(48)} >Horowitz Index</button>
					<button className={toggleState === 49 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(49)} >IBW</button>
					<button className={toggleState === 50 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(50)} >Intraoperative Fluid Dosing in Adult Patients</button>
					<button className={toggleState === 51 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(51)} >Intrauterine RBC Transfusion Dosage</button>
					<button className={toggleState === 52 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(52)} >MUST</button>
					<button className={toggleState === 53 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(53)} >Maternal-Fetal Hemorrhage Rh(D) Immune Globulin Dosage</button>
					<button className={toggleState === 54 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(54)} >Maximum allowable blood loss without transfusion</button>
					<button className={toggleState === 55 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(55)} >Mean Arterial Pressure </button>
					<button className={toggleState === 56 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(56)} >MME</button>
					<button className={toggleState === 57 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(57)} >Modified Mallampati Classification</button>
					<button className={toggleState === 58 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(58)} >NPS</button>
					<button className={toggleState === 59 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(59)} >NRI</button>
					<button className={toggleState === 60 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(60)} >NRS-2002</button>
					<button className={toggleState === 61 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(61)} >NUTRIC</button>
					<button className={toggleState === 62 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(62)} >NSQIP</button>
					<button className={toggleState === 63 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(63)} >NVPS</button>
					<button className={toggleState === 64 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(64)} >NYHA</button>
					<button className={toggleState === 65 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(65)} >ORT</button>
					<button className={toggleState === 66 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(66)} >Plasma Volume</button>
					<button className={toggleState === 67 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(67)} >PORT score</button>
					<button className={toggleState === 68 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(68)} >POSSUM</button>
					<button className={toggleState === 69 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(69)} >Pulse Pressure </button>
					<button className={toggleState === 70 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(70)} >Pregnancy Gestation by LMP and Ultrasound Biometry</button>
					<button className={toggleState === 71 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(71)} >qSOFA</button>
					<button className={toggleState === 72 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(72)} >RCRI</button>
					<button className={toggleState === 73 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(73)} >RIFLE Criteria for AKI</button>
					<button className={toggleState === 74 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(74)} >ROX Index</button>
					<button className={toggleState === 75 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(75)} >RSBI</button>
					<button className={toggleState === 76 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(76)} >Serum Osmolality / Osmolarity</button>
					<button className={toggleState === 77 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(77)} >Sickle Cell RBC Exchange Volume</button>
					<button className={toggleState === 78 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(78)} >SIRS, Sepsis and Septic Shock</button>
					<button className={toggleState === 79 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(79)} >SIRS - Pediatric</button>
					<button className={toggleState === 80 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(80)} >SMS</button>
					<button className={toggleState === 81 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(81)} >SOFA</button>
					<button className={toggleState === 82 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(82)} >Stanford Sleepiness Scale</button>
					<button className={toggleState === 83 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(83)} >Steroid Conversion Calculator</button>
					<button className={toggleState === 84 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(84)} >STOP-BANG</button>
					<button className={toggleState === 85 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(85)} >Surgical Apgar Score</button>
					<button className={toggleState === 86 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(86)} >Tisdale</button>
					<button className={toggleState === 87 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(87)} >VBAC</button>
					<button className={toggleState === 88 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(88)} >Vital Signs - Pediatric</button>
					<button className={toggleState === 89 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(89)} >Winter's Formula</button>
					<button className={toggleState === 90 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(90)} >Blood products and expected results</button>
					<button className={toggleState === 91 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(91)} >Preoperative Testing</button>
					<button className={toggleState === 92 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(92)} >Upper Lip Bite Test</button>
					<button className={toggleState === 93 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(93)} >NPO Status</button>
					<button className={toggleState === 94 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(94)} >4T's Score for Heparin-Induced Thrombocytopenia</button>
                        {/* <div className="btn-btn">
                            <a className="risk-btn save-button-container"><i className="fa fa-plus-circle">&nbsp;&nbsp;</i>Add</a>
                        </div> */}
                    </div>
                <div className="col-md-8">
                <div className="content-tabs">
                    <div className={toggleState === 1 ? "risk-content  active-content" : "risk-content"}>
                       <AaO2Gradient/>
                    </div>

                    <div
                        className={toggleState === 2 ? "risk-content  active-content" : "risk-content"} >
                        <Abbey/>
                    </div>

                    <div
                        className={toggleState === 3 ? "risk-content  active-content" : "risk-content"}>
                      <FluidAbcScore/>
                    </div>
                    <div
                        className={toggleState === 4 ? "risk-content  active-content" : "risk-content"}>
                       <Abg/>
                       </div>
                       <div
                        className={toggleState === 5 ? "risk-content  active-content" : "risk-content"}>
                       <Acc/>
                    </div>
                    <div
                        className={toggleState === 6 ? "risk-content  active-content" : "risk-content"}>
                       <AldreteScore/>
                    </div>
                    
                    <div
                        className={toggleState === 7 ? "risk-content  active-content" : "risk-content"}>
                       <ModifieldAldreteScore/>
                    </div>
                    <div
                        className={toggleState === 8 ? "risk-content  active-content" : "risk-content"}>
                       <AnionGap/>
                    </div>
                    <div
                        className={toggleState === 9 ? "risk-content  active-content" : "risk-content"}>
                      <APACHEIIScore/>
                    </div>
                    <div
                        className={toggleState === 10 ? "risk-content  active-content" : "risk-content"}>
                       <ApfelScore/>
                    </div>
                    <div
                        className={toggleState === 11 ? "risk-content  active-content" : "risk-content"}>
                       <ApgarScore/>
                    </div>
                    <div
                        className={toggleState === 12 ? "risk-content  active-content" : "risk-content"}>
                       <ARISCAT/>
                    </div>
                    <div
                        className={toggleState === 13 ? "risk-content  active-content" : "risk-content"}>
                       <ASAPS/>
                    </div>
                    <div
                        className={toggleState === 14 ? "risk-content  active-content" : "risk-content"}>
                       <BerlinForARDS/>
                    </div>
					<div
                        className={toggleState === 15 ? "risk-content  active-content" : "risk-content"}>
                      <BicarbonateDeficit/>
                    </div>
					<div
                        className={toggleState === 16 ? "risk-content  active-content" : "risk-content"}>
                       <BishopScore/>
                    </div>
					<div
                        className={toggleState === 17 ? "risk-content  active-content" : "risk-content"}>
                      <FluidBloodVolume/>
                    </div>
					<div
                        className={toggleState === 18 ? "risk-content  active-content" : "risk-content"}>
                       <BmiANDBsa/>
                    </div>
					<div
                        className={toggleState === 19 ? "risk-content  active-content" : "risk-content"}>
                       <Bops/>
                    </div>
                    
					<div
                        className={toggleState === 20 ? "risk-content  active-content" : "risk-content"}>
                       <Bps/> 
                    </div>
					<div
                        className={toggleState === 21 ? "risk-content  active-content" : "risk-content"}>
                      <CAGE/> 
                    </div>
					<div
                        className={toggleState === 22 ? "risk-content  active-content" : "risk-content"}>
                      <Care/>
                    </div>
					<div
                        className={toggleState === 23 ? "risk-content  active-content" : "risk-content"}>
                       <CardicOutput/>
                    </div>
					<div
                        className={toggleState === 24 ? "risk-content  active-content" : "risk-content"}>
                       <CardiacRiskIndex/>
                    </div>
					<div
                        className={toggleState === 25 ? "risk-content  active-content" : "risk-content"}>
                      <CCI/> 
                    </div>
					<div
                        className={toggleState === 26 ? "risk-content  active-content" : "risk-content"}>
                     <CssCardio/> 
                    </div>
					<div
                        className={toggleState === 27 ? "risk-content  active-content" : "risk-content"}>
                     <CHA2DS2VASc/>
                    </div>
					<div
                        className={toggleState === 28 ? "risk-content  active-content" : "risk-content"}>
                        <Cheops/> 
                    </div>
					<div
                        className={toggleState === 29 ? "risk-content  active-content" : "risk-content"}>
                      <CiwaAr/> 
                    </div>
					<div
                        className={toggleState === 30 ? "risk-content  active-content" : "risk-content"}>
                      <DasiCalculator/>
                    </div>
					<div
                        className={toggleState === 31 ? "risk-content  active-content" : "risk-content"}>
                       <DetskRiskIndex/> 
                    </div>
					<div
                        className={toggleState === 32 ? "risk-content  active-content" : "risk-content"}>
                      <DialysisRiskClinic/> 
                    </div>
					<div
                        className={toggleState === 33 ? "risk-content  active-content" : "risk-content"}>
                      <DialysisRiskMehta/> 
                    </div>
					<div
                        className={toggleState === 34 ? "risk-content  active-content" : "risk-content"}>
                      <DRIP/> 
                    </div>
					<div
                        className={toggleState === 35 ? "risk-content  active-content" : "risk-content"}>
                      <EGRIAirwayCalculator/> 
                    </div>
					<div
                        className={toggleState === 36 ? "risk-content  active-content" : "risk-content"}>
                     <EttTidalAirwayCalculator/>
                    </div>
					<div
                        className={toggleState === 37 ? "risk-content  active-content" : "risk-content"}>
                       <EttPedAirwayCalculator/>
                    </div><div
                        className={toggleState === 38 ? "risk-content  active-content" : "risk-content"}>
                     <EuroScoreCardivascular/> 
                    </div>
                    <div
                        className={toggleState === 39 ? "risk-content  active-content" : "risk-content"}>
                       <Bpp/>
                    </div>
                    <div
                        className={toggleState === 40 ? "risk-content  active-content" : "risk-content"}>
                     <FenaFluidCalculator/> 
                    </div><div
                        className={toggleState === 41 ? "risk-content  active-content" : "risk-content"}>
                      <FeureaFluidCalculator/> 
                    </div><div
                        className={toggleState === 42 ? "risk-content  active-content" : "risk-content"}>
                     <Frailty/>  
                    </div>
                    <div
                        className={toggleState === 43 ? "risk-content  active-content" : "risk-content"}>
                      <GcsAdult/> 
                    </div>
                    <div
                        className={toggleState === 44 ? "risk-content  active-content" : "risk-content"}>
                      <GcsPediatric/>
                    </div>
                    <div
                        className={toggleState === 45 ? "risk-content  active-content" : "risk-content"}>
                     <GuptaPneumoniaRisk/> 
                    </div>
                    <div
                        className={toggleState === 46 ? "risk-content  active-content" : "risk-content"}>
                    <GuptaFailureRisk/>  
                    </div>
                    <div
                        className={toggleState === 47 ? "risk-content  active-content" : "risk-content"}>
                        <HasBledScore/>
                    </div><div
                        className={toggleState === 48 ? "risk-content  active-content" : "risk-content"}>
                    <HorowitzIndexAirwayCalculator/>
                    </div>
                    <div
                        className={toggleState === 49 ? "risk-content  active-content" : "risk-content"}>
                     <IBW/> 
                    </div>
                    <div
                        className={toggleState === 50 ? "risk-content  active-content" : "risk-content"}>
                      <IfdaFluidCalculator/> 
                    </div><div
                        className={toggleState === 51 ? "risk-content  active-content" : "risk-content"}>
                        <RbcFluidCalculator/>
                    </div>
                    <div
                        className={toggleState === 52 ? "risk-content  active-content" : "risk-content"}>
                    <Must/>  
                    </div><div
                        className={toggleState === 53 ? "risk-content  active-content" : "risk-content"}>
                      <MeternalFetalHemorrhage/>
                    </div>
					<div
                        className={toggleState === 54 ? "risk-content  active-content" : "risk-content"}>
                       <FluidMaximumAbl/> 
                    </div>
					<div
                        className={toggleState === 55 ? "risk-content  active-content" : "risk-content"}>
                      <MeanArterialCardiovascular/>
                    </div>
					<div
                        className={toggleState === 56 ? "risk-content  active-content" : "risk-content"}>
                       <Mme/>
                    </div>
					<div
                        className={toggleState === 57 ? "risk-content  active-content" : "risk-content"}>
                     <MallClassAirwayCalculator/> 
                    </div>
					<div
                        className={toggleState === 58 ? "risk-content  active-content" : "risk-content"}>
                        <Nps/>
                    </div>
					<div
                        className={toggleState === 59 ? "risk-content  active-content" : "risk-content"}>
                      <NRI/> 
                    </div>
					<div
                        className={toggleState === 60 ? "risk-content  active-content" : "risk-content"}>
                     <NRS2002/>
                    </div>
					<div
                        className={toggleState === 61 ? "risk-content  active-content" : "risk-content"}>
                      <NUTRIC/> 
                    </div>
					<div
                        className={toggleState === 62 ? "risk-content  active-content" : "risk-content"}>
                        <NSQIP/>
                    </div>
					<div
                        className={toggleState === 63 ? "risk-content  active-content" : "risk-content"}>
                     <Nvps/>  
                    </div>
					<div
                        className={toggleState === 64 ? "risk-content  active-content" : "risk-content"}>
                     <NyhaCardiovascularCalculator/> 
                    </div>
					<div
                        className={toggleState === 65 ? "risk-content  active-content" : "risk-content"}>
                       <ORT/>
                    </div>
					<div
                        className={toggleState === 66 ? "risk-content  active-content" : "risk-content"}>
                      <PlasmaFluidCalculator/> 
                    </div>
					<div
                        className={toggleState === 67 ? "risk-content  active-content" : "risk-content"}>
                        <PortFluidCalculator/>
                    </div>
					<div
                        className={toggleState === 68 ? "risk-content  active-content" : "risk-content"}>
                      <POSSUM/> 
                    </div>
					<div
                        className={toggleState === 69 ? "risk-content  active-content" : "risk-content"}>
                      <PulsePressureCardiovascular/> 
                    </div>
					<div
                        className={toggleState === 70 ? "risk-content  active-content" : "risk-content"}>
                      <Pregnancy/>
                    </div>
					
					<div
                        className={toggleState === 71 ? "risk-content  active-content" : "risk-content"}>
                     <QuickSofa/> 
                    </div>
					<div
                        className={toggleState === 72 ? "risk-content  active-content" : "risk-content"}>
                      <RcriCardiovascularCalculator/> 
                    </div>
					<div
                        className={toggleState === 73 ? "risk-content  active-content" : "risk-content"}>
                      <RIFLEFluidCalculator/>
                    </div>
					<div
                        className={toggleState === 74 ? "risk-content  active-content" : "risk-content"}>
                         <RoxIndexAirwayCalculator/>
                    </div>
					<div
                        className={toggleState === 75 ? "risk-content  active-content" : "risk-content"}>
                     <RsbiAirwayCalculator/>
                    </div>
					<div
                        className={toggleState === 76 ? "risk-content  active-content" : "risk-content"}>
                      <FluidCalculator/> 
                    </div>
					<div
                        className={toggleState === 77 ? "risk-content  active-content" : "risk-content"}>
                        <SickleCellRBCExchangeVolume/>
                    </div>
					<div
                        className={toggleState === 78 ? "risk-content  active-content" : "risk-content"}>
                     <SirsSepsis/>
                    </div>
					<div
                        className={toggleState === 79 ? "risk-content  active-content" : "risk-content"}>
                         <SIRSpediatric/>
                    </div>
					<div
                        className={toggleState === 80 ? "risk-content  active-content" : "risk-content"}>
                        <Sms/>
                    </div>
					<div
                        className={toggleState === 81 ? "risk-content  active-content" : "risk-content"}>
                     <SOFA/>
                    </div>
					<div
                        className={toggleState === 82 ? "risk-content  active-content" : "risk-content"}>
                      <StanfordSleepinessScale/>
                    </div>
					<div
                        className={toggleState === 83 ? "risk-content  active-content" : "risk-content"}>
                       <SteroidConversion/>
                    </div>
					<div
                        className={toggleState === 84 ? "risk-content  active-content" : "risk-content"}>
                       <StopBangAirwayCalculator/>
                    </div>
					<div
                        className={toggleState === 85 ? "risk-content  active-content" : "risk-content"}>
                         <Sas/>
                    </div>
					<div
                        className={toggleState === 86 ? "risk-content  active-content" : "risk-content"}>
                       <TisdaleCardiovascularCalculator/> 
                    </div>
					<div
                        className={toggleState === 87 ? "risk-content  active-content" : "risk-content"}>
                       <Vbac/> 
                    </div>
					<div
                        className={toggleState === 88 ? "risk-content  active-content" : "risk-content"}>
                       <PediatricCardiovascularcalculator/>
                    </div>
					<div
                        className={toggleState === 89 ? "risk-content  active-content" : "risk-content"}>
                      <WintersFormula/> 
                    </div>
                    <div
                        className={toggleState === 90 ? "risk-content  active-content" : "risk-content"}>
                       <BloodProductFluid/>
                    </div>
                    <div
                        className={toggleState === 91 ? "risk-content  active-content" : "risk-content"}>
                      {/* <PreoperativeEvaluation/>  */}
                    </div>
                    <div
                        className={toggleState === 92 ? "risk-content  active-content" : "risk-content"}>
                       <UpperLipBiteTestAirwayCalculator/>
                    </div>
                    <div
                        className={toggleState === 93 ? "risk-content  active-content" : "risk-content"}>
                       <Npo/>
                    </div>
                    <div
                        className={toggleState === 94 ? "risk-content  active-content" : "risk-content"}>
                       <FourTScore/>
                    </div>
			
					
					
                </div>
                </div>
            </div>
            </div>
   
        </div>
    )
}

export default RiskEvalution
