import React, { useEffect, useState } from 'react';
import './FAT1169.css'

const FAT1169 = () => {
    const [green, setGreen] = useState(false);
    const [renaldiseases, setRenaldiseases] = useState("");
    const [kidneyinjury, setKidneyinjury] = useState("");
    const [serum, setSerum] = useState("");
    const [grf, setGrf] = useState("");
    const [urine, setUrine] = useState("");
    const [result1, setResult1] = useState("");
    const [result2, setResult2] = useState("");
    const [scrollpos, setScrollPos] = useState({ bottom: 0 });


    const postData = async () => {
        if (renaldiseases === "Yes") {
            setResult1("ESRD");
            setResult2("RIFLE-E");

        }
        else if ((renaldiseases === "No") && ((kidneyinjury === "Yes"))) {
            setResult1("Loss");
            setResult2("RIFLE-L");

        }
        else if ((serum == 1) && (grf == 1) && (urine == 1)) {
            setResult1("Risk");
            setResult2("RIFLE-R");
        }
        else if ((serum == 2) && (grf == 2) && (urine == 2)) {
            setResult1("Injury");
            setResult2("RIFLE-I");
        }
        else if ((serum == 1) && (grf == 3) && (urine == 3)) {
            setResult1("Failure");
            setResult2("RIFLE-F/RIFLE-FO if oliguria");
        }
        else if ((serum == 3) || (grf == 3) || (urine == 3)) {
            setResult1("Failure");
            setResult2("RIFLE-F/RIFLE-FO if oliguria");
        }
        else if (((serum == 1) && (grf == 1) && (urine == 2)) || ((serum == 1) && (grf == 2) && (urine == 1)) || ((serum == 2) && (grf == 1) && (urine == 1))
            || ((serum == 2) && (grf == 2) && (urine == 1)) || ((serum == 2) && (grf == 1) && (urine == 2)) || ((serum == 1) && (grf == 2) && (urine == 2))) {
            setResult1("Injury");
            setResult2("RIFLE-I");
        }



        setGreen(true);

    }

    useEffect(() => {
        postData();

    }, [renaldiseases, kidneyinjury, serum, grf, urine])
    const creatnew1 = () => {
        if (renaldiseases === "No") {
            return <div> <div className="fields row">
                <div className="col-md-4">
                    <div className="pt-2"><strong>Persistent acute kidney injury</strong><br />On RRT for &gt;4 weeks</div>
                </div>
                <div className="col-md-4 btn-group " >
                    <button style={{ height: 40 }} className={kidneyinjury === "No" ? "btn active text-white" : "btn "} onClick={() => setKidneyinjury("No")}>No <span className="calc-val text-right"></span></button>
                    <button style={{ height: 40 }} className={kidneyinjury === "Yes" ? "btn active text-white" : "btn "} onClick={() => setKidneyinjury("Yes")}>Yes<span className="calc-val text-right"></span></button>
                </div>
            </div>
            </div>
        }
    }
    const creatnew2 = () => {
        if ((kidneyinjury === "No") && (renaldiseases === "No")) {
            return <div>
                <div className="fields row">
                    <div className="col-md-4">
                        <div className="pt-2"><strong>Serum creatinine</strong></div>
                    </div>
                    <div className="col-md-8 btn-group-vertical options-btn">
                        <button style={{ height: 40 }} onClick={() => setSerum(1)} className={serum === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">1.5-2x above baseline</span><span className="calc-val">0</span></button>
                        <button style={{ height: 40 }} onClick={() => setSerum(2)} className={serum === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">&gt;2-3x above baseline</span><span className="calc-val">+1</span></button>
                        <button style={{ height: 80 }} onClick={() => setSerum(3)} className={serum === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">&gt;3x above baseline, ≥4 mg/dL (350 µmol/L),<br/> or acute rise ≥0.5 mg/dL (44 µmol/L)</span><span className="calc-val">+2</span></button>
                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-4">
                        <div className="pt-2"><strong>GFR</strong></div>
                    </div>
                    <div className="col-md-8 btn-group-vertical options-btn">
                        <button onClick={() => setGrf(1)} className={grf === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">&gt;25% decrease below baseline</span><span className="calc-val">0</span></button>
                        <button onClick={() => setGrf(2)} className={grf === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">&gt;50% decrease below baseline</span><span className="calc-val">+1</span></button>
                        <button onClick={() => setGrf(3)} className={grf === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">&gt;75% decrease below baseline</span><span className="calc-val">+2</span></button>
                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-4">
                        <div className="pt-2"><strong>Urine output</strong></div>
                    </div>
                    <div className="col-md-8 btn-group-vertical options-btn">
                        <button onClick={() => setUrine(1)} className={urine === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">&lt;0.5 mL/kg/hr x 6 hr</span><span className="calc-val">0</span></button>
                        <button onClick={() => setUrine(2)} className={urine === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">&lt;0.5 mL/kg/hr x 12 hr</span><span className="calc-val">+1</span></button>
                        <button onClick={() => setUrine(3)} className={urine === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">&lt;0.3 mL/kg/hr x 24 hr (oliguria), or anuria x 12 hr</span><span className="calc-val">+2</span></button>
                    </div>
                </div>

            </div>

        }

    }





    return (<div className="rev">
        <h5 className="text-info pl-2">RIFLE Criteria for Acute Kidney Injury (AKI)</h5>
        <p className="pl-2">Classifies severity of acute kidney injury, similar to AKIN Classification.</p>
        <div className="fields row">
            <div className="col-md-4">
                <div className="pt-1"><strong>End stage renal disease</strong><br />On dialysis for &gt;3 months</div>

            </div>
            <div className="col-md-8 btn-group " >
                <button style={{ height: 40, }} className={renaldiseases === "No" ? "btn active text-white" : "btn "} onClick={() => setRenaldiseases("No")}>No <span className="calc-val text-right"></span></button>
                <button style={{ height: 40, }} className={renaldiseases === "Yes" ? "btn active text-white" : "btn "} onClick={() => setRenaldiseases("Yes")}>Yes<span className="calc-val text-right"></span></button>

            </div>
        </div>

        <div>{creatnew1()}</div>
        <div>{creatnew2()}</div>
        <br />

        <div className="stick-result">
            {
                (result1 && result2) ?
                    <div className="apfel-score-result-container" style={scrollpos}>

                        <div className="point-result-container" >
                            <div>
                                <span><h1 className="breath">{result1}</h1></span> <br />
                                <h5>{result2}</h5>

                            </div>


                        </div>

                    </div>
                    :
                    <div>
                        <div className="text-white p-2 green-bg" style={scrollpos}><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div></div>
            }
        </div>
        <div className="p-2 mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://ccforum.biomedcentral.com/articles/10.1186/cc2872">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Bellomo R, Ronco C, Kellum JA, Mehta RL, Palevsky P. Acute renal failure - definition, outcome measures, animal models, fluid therapy and information technology needs: the Second International Consensus Conference of the Acute Dialysis Quality Initiative (ADQI) Group. Crit Care. 2004;8(4):R204-12.</p>
                </div>
            </a><br />

        </div>
    </div>
    )
}

export default FAT1169