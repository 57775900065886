import React, { useEffect, useState } from "react";
import "./CAS1040.css";

function CAS1040() {
    const [temp, setTemp] = useState(0);
    const [tachycardia, setTachycardia] = useState(0);
    const [tachypnea, setTachypnea] = useState(0);
    const [abnormal, setAbnormal] = useState(0);
    const [suspected, setSuspected] = useState(0);
    const [ards, setArds] = useState(0);
    const [adequate, setAdequate] = useState(0);

    const [result, setResult] = useState(0);

    const postData = async () => {
        setResult(
            temp + tachycardia + tachypnea + abnormal + suspected + ards + adequate
        );
    };
    useEffect(() => {
        postData();
    }, [temp, tachycardia, tachypnea, abnormal, suspected, ards, adequate]);

    const result1 = () => {
        if (result >= 0 && result == 1) {
            return (
                <div className="apfel-score-result-container col-12">
                    <div className="point-result-container col-12">
                        <div>This patient does not meet SIRS criteria.</div>
                        <div>For other causes of shock, see the Next Steps section.</div>
                    </div>
                </div>
            );
        }
        if (result >= 2 && result <= 4) {
            return (
                <div className="apfel-score-result-container col-12">
                    <div className="point-result-container col-12">
                        <div>This patient meets SIRS criteria.</div>
                    </div>
                </div>
            );
        }
        if (result == 5 && suspected == 1) {
            return (
                <div className="apfel-score-result-container col-12">
                    <div className="point-result-container col-12 ">
                        <div>This patient meets sepsis criteria..</div>
                        <br />
                        <div>
                            Screen for Severe Sepsis and Septic Shock physical exam findings.
                            If present, initiate ACCM/PALS Septic Shock Algorithm (see Next
                            Steps section).
                        </div>
                        <br />
                        <div>
                            Consider IV fluid resuscitation, cultures, and broad-spectrum
                            antibiotics.
                        </div>
                    </div>
                </div>
            );
        }
        if (result == 5 && ards == 1) {
            return (
                <div className="apfel-score-result-container col-12">
                    <div className="point-result-container col-12 ">
                        <div>This patient meets severe sepsis criteria.</div>
                    </div>
                </div>
            );
        }
        if (result == 5 && adequate == 1) {
            return (
                <div className="apfel-score-result-container col-12">
                    <div className="point-result-container col-12 ">
                        <div>This patient meets septic shock criteria.</div>
                    </div>
                </div>
            );
        }
        if ((result >= 5 && adequate == 1) || ards == 1 || suspected == 1) {
            return (
                <div className="apfel-score-result-container col-12">
                    <div className="point-result-container col-12 ">
                        <div>This patient meets sepsis criteria..</div>
                        <br />
                        <div>
                            Screen for Severe Sepsis and Septic Shock physical exam findings.
                            If present, initiate ACCM/PALS Septic Shock Algorithm (see Next
                            Steps section).
                        </div>
                        <br />
                        <div>
                            Consider IV fluid resuscitation, cultures, and broad-spectrum
                            antibiotics.
                        </div>
                    </div>
                </div>
            );
        }
        if (result >= 4 && ards == 1 && adequate == 1) {
            return (
                <div className="apfel-score-result-container col-12">
                    <div className="point-result-container col-12">
                        <div>This patient meets septic shock criteria.</div>
                    </div>
                </div>
            );
        }
        if (result == 6 && ards == 1 && adequate == 1) {
            return (
                <div className="apfel-score-result-container col-12">
                    <div className="point-result-container col-12 ">
                        <div>This patient meets septic shock criteria..</div>
                    </div>
                </div>
            );
        }
        if (result >= 5 && suspected == 1 && adequate == 1) {
            return (
                <div className="apfel-score-result-container col-12">
                    <div className="point-result-container col-12 ">
                        <div>This patient meets sepsis criteria..</div>
                        <br />
                        <div>
                            Screen for Severe Sepsis and Septic Shock physical exam findings.
                            If present, initiate ACCM/PALS Septic Shock Algorithm (see Next
                            Steps section).
                        </div>
                        <br />
                        <div>
                            Consider IV fluid resuscitation, cultures, and broad-spectrum
                            antibiotics.
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="text-white p-2 green-bg">
                    <h1>Result:</h1>
                    <br />
                    <h6>Please fill out required fields.</h6>
                </div>
            );
        }
    };

    return (
        <div className="rev">
            <h5 className="text-info pl-2">
            SIRS- Pediatric
            </h5>
            <p className="pl-2">Defines the severity of sepsis and septic shock..</p>
            <div class="alert alert-dark" role="alert">
                SIRS Criteria (≥ 2 meets SIRS definition, 1 of which must be abnormal
                temperature or leukocyte count) List of Age-Dependent Vital Sign Ranges
                Available in About Section
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div>
                        <strong>Temp &gt; 38.5°C (101.3°F) or &lt; 36°C (96.8°F)</strong>
                    </div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button
                        style={{ height: 40 }}
                        key="1"
                        className={temp === 0 ? "btn active" : "btn"}
                        onClick={() => setTemp(0)}
                    >
                        No<span className="ml-4 text-muted"></span>
                    </button>
                    <button
                        style={{ height: 40 }}
                        key="2"
                        className={temp === 1 ? "btn active" : "btn"}
                        onClick={() => setTemp(1)}
                    >
                        Yes<span className="ml-4 text-muted"></span>
                    </button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div>
                        <strong>Tachycardia or bradycardia (if &lt;1 year)</strong>
                    </div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button
                        style={{ height: 40 }}
                        key="1"
                        className={tachycardia === 0 ? "btn active" : "btn"}
                        onClick={() => setTachycardia(0)}
                    >
                        No<span className="ml-4 text-muted"></span>
                    </button>
                    <button
                        style={{ height: 40 }}
                        key="2"
                        className={tachycardia === 1 ? "btn active" : "btn"}
                        onClick={() => setTachycardia(1)}
                    >
                        Yes<span className="ml-4 text-muted"></span>
                    </button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div>
                        <strong>
                            Tachypnea or mechanical ventilation (related to an acute process)
                        </strong>
                    </div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button
                        style={{ height: 40 }}
                        key="1"
                        className={tachypnea === 0 ? "btn active" : "btn"}
                        onClick={() => setTachypnea(0)}
                    >
                        No<span className="ml-4 text-muted"></span>
                    </button>
                    <button
                        style={{ height: 40 }}
                        key="2"
                        className={tachypnea === 1 ? "btn active" : "btn"}
                        onClick={() => setTachypnea(1)}
                    >
                        Yes<span className="ml-4 text-muted"></span>
                    </button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div>
                        <strong>Abnormal leukocyte count or {">"} 10% bands</strong>
                    </div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button
                        style={{ height: 40 }}
                        key="1"
                        className={abnormal === 0 ? "btn active" : "btn"}
                        onClick={() => setAbnormal(0)}
                    >
                        No<span className="ml-4 text-muted"></span>
                    </button>
                    <button
                        style={{ height: 40 }}
                        key="2"
                        className={abnormal === 1 ? "btn active" : "btn"}
                        onClick={() => setAbnormal(1)}
                    >
                        Yes<span className="ml-4 text-muted"></span>
                    </button>
                </div>
            </div>
            <div class="alert alert-dark" role="alert">
                Sepsis Criteria (SIRS + Source of Infection)
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div>
                        <strong>Suspected or present source of infection</strong>
                    </div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button
                        style={{ height: 40 }}
                        key="1"
                        className={suspected === 0 ? "btn active" : "btn"}
                        onClick={() => setSuspected(0)}
                    >
                        No<span className="ml-4 text-muted"></span>
                    </button>
                    <button
                        style={{ height: 40 }}
                        key="2"
                        className={suspected === 1 ? "btn active" : "btn"}
                        onClick={() => setSuspected(1)}
                    >
                        Yes<span className="ml-4 text-muted"></span>
                    </button>
                </div>
            </div>
            <div class="alert alert-dark" role="alert">
                Severe Sepsis Criteria (Sepsis + ≥ 1 following Dysfunction Criteria)
                Organ Dysfunction Criteria »
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div>
                        <strong>
                            Cardiovascular dysfunction OR ARDS OR ≥ 2 other organ dysfunction
                        </strong>
                    </div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button
                        style={{ height: 40 }}
                        key="1"
                        className={ards === 0 ? "btn active" : "btn"}
                        onClick={() => setArds(0)}
                    >
                        No<span className="ml-4 text-muted"></span>
                    </button>
                    <button
                        style={{ height: 40 }}
                        key="2"
                        className={ards === 1 ? "btn active" : "btn"}
                        onClick={() => setArds(1)}
                    >
                        Yes<span className="ml-4 text-muted"></span>
                    </button>
                </div>
            </div>
            <div class="alert alert-dark" role="alert">
                Septic Shock Criteria (Sepsis + Cardiovascular Dysfunction) Organ
                Dysfunction Criteria
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div>
                        <strong>
                            Cardiovascular dysfunction despite adequate fluid resuscitation
                        </strong>
                    </div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button
                        style={{ height: 40 }}
                        key="1"
                        className={adequate === 0 ? "btn active" : "btn"}
                        onClick={() => setAdequate(0)}
                    >
                        No<span className="ml-4 text-muted"></span>
                    </button>
                    <button
                        style={{ height: 40 }}
                        key="2"
                        className={adequate === 1 ? "btn active" : "btn"}
                        onClick={() => setAdequate(1)}
                    >
                        Yes<span className="ml-4 text-muted"></span>
                    </button>
                </div>
            </div>
            <div className="stick pt-3">{result1()}</div>
            <div className="p-2">
                <h6>ORIGINAL/PRIMARY REFERENCE</h6>
                <a
                    className="referenceLink"
                    target="_blank"
                    href="https://pubmed.ncbi.nlm.nih.gov/21045639/"
                >
                    <div className="reference-container-card">
                        <div className="reference-img">
                            <img
                                src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png"
                                alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph">
                            Canet J, Gallart L, Gomar C, et al. Prediction of postoperative
                            pulmonary complications in a population-based surgical cohort.
                            Anesthesiology. 2010;113(6):1338-50..
                        </p>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default CAS1040;
