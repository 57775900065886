import {SET_EXTRA_FAVOURITES,SET_ACTION_SUMMARY_CATEGORY,SET_ACTION_SUMMARY_TYPE, SET_DRUG_DATA, SET_LEVEL_2, SET_LEVEL_1} from './FavouriteTypes'
const selectedCategory=[]
export const setExtraFavourites=(allFavourite)=>{
    return {
        type: SET_EXTRA_FAVOURITES,
        payload: allFavourite
    }
}

export const setActionSummaryType=(ActionSummaryType)=>{
    return {
        type: SET_ACTION_SUMMARY_TYPE,
        payload: ActionSummaryType
    }
}

export const setActionSummaryCategory=(ActionSummaryCategory)=>{
    selectedCategory.push(ActionSummaryCategory)
    return {
        type: SET_ACTION_SUMMARY_CATEGORY,
        payload: selectedCategory
    }
}
export const setDrugData=(drugData)=>{
    return {
        type: SET_DRUG_DATA,
        payload: drugData
    }
}
export const setLevel1=(level1)=>{
    return {
        type: SET_LEVEL_1,
        payload: level1
    }
}
export const setLevel2=(level2)=>{
    return {
        type: SET_LEVEL_2,
        payload: level2
    }
}
