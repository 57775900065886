import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { API_ROOT } from '../constants'

const CaseSummaryData = (props) => {
    const [age, setage] = useState(null);
    const [ageType, setAgeType] = useState("Year");
    const [gender, setgender] = useState(null);
    const [patientType, setpatientType] = useState(null);
    const [height, setheight] = useState(null);
    const [heightType, setHeightType] = useState(null);
    const [weight, setweight] = useState(null);
    const [weightType, setWeightType] = useState(null);
    const [fileName, setfileName] = useState(null);
    const [npo_status_time, setnpo_status_time] = useState(null);
    const [foodTimeType,setfoodTimeType]=useState(null)
    const [npo_status_materials, setnpo_status_materials] = useState(null);
    const [surgeryData, setsurgeryData] = useState([]);
    const [surgeryTypesListData, setsurgeryTypesListData] = useState([]);
    const [allergies, setallergies] = useState([]);
    const [anesthesia_histories, setanesthesia_histories] = useState([]);
    const [midical_histories, setmidical_histories] = useState([]);
    const [midications, setmidications] = useState([]);
    const [asa_ps, setasa_ps] = useState([]);
    const [case_notes, setCase_notes] = useState([]);
    const [case_notes_img, setCase_notes_img] = useState([]);
    const [surgeryHistory, setSurgeryHistory] = useState([])
    const [anesthesiaTypes, setAnesthesiaTypes] = useState([])


    useEffect(() => {
        if (props.location.state !== undefined) {
            sessionStorage.setItem('caseID', props.location.state.caseSummaryId)
            getCaseSummaryDataAsPerId(props.location.state.caseSummaryId)
        } else {
            getCaseSummaryDataAsPerId(sessionStorage.getItem('caseID'))
        }

    }, [])

    const getCaseSummaryDataAsPerId = (caseId) => {
        var token = sessionStorage.getItem("token")
        fetch(API_ROOT + `case-summary-by-id/${caseId}?token=${token}`)
            .then(response => response.json())
            .then(res => {
                if (res.status === "true") {
                    let ageType = '';
                    if (res.data?.age > 1 && res.data?.age_type === 'Y') {
                        ageType = 'Years';
                    }
                    if (res.data?.age <= 1 && res.data?.age_type === 'Y') {
                        ageType = 'Year';
                    }
                    if (res.data?.age > 1 && res.data?.age_type === 'M') {
                        ageType = 'Months';
                    }
                    if (res.data?.age <= 1 && res.data?.age_type === 'M') {
                        ageType = 'Month';
                    }
                    if (res.data?.age > 1 && res.data?.age_type === 'W') {
                        ageType = 'Weeks';
                    }
                    if (res.data?.age <= 1 && res.data?.age_type === 'W') {
                        ageType = 'Week';
                    }
                    if (res.data?.age > 1 && res.data?.age_type === 'D') {
                        ageType = 'Days';
                    }
                    if (res.data?.age <= 1 && res.data?.age_type === 'D') {
                        ageType = 'Day';
                    }
                    if (res?.data?.gender !== null) {
                        res.data.gender = res?.data?.gender[0].toUpperCase() + (res?.data?.gender).slice(1)
                    } else {
                        res.data.gender = null
                    }
                    setage(res.data.age);
                    setAgeType(res.data.age_type);
                    setgender(res?.data?.gender);
                    setpatientType(res.data.patient_type_name);
                    setheight(res.data.height);
                    setHeightType(res.data.height_type);
                    setweight(res.data.weight);
                    setWeightType(res.data.weight_type);
                    setfileName(res.data.name);
                    setnpo_status_time(res.data.npo_status_time);
                    setfoodTimeType(res.data.npo_status_time_type)
                    setnpo_status_materials(res.data.npo_status_materials);
                    setsurgeryData(res.surgeryTypes);
                    setAnesthesiaTypes(res.anesthesiaTypes)
                    setSurgeryHistory(res.surgeryHistories)
                    setsurgeryTypesListData(res.surgeryTypesListData);
                    setallergies(res.allergies);
                    setanesthesia_histories(res.anesthesia_histories);
                    setmidical_histories(res.midical_histories);
                    setmidications(res.midications);
                    setasa_ps(res.asa_ps);
                    setCase_notes(res.data.case_note_message);
                    setCase_notes_img(res.data.case_note_image);

                }
            })
    }


    return (
        <div>
            <div className="backNavigation text-center">
                <Link className="arrow" to="/recent"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Cases</span></span></Link>CASE SUMMARY
            </div>
            <div className="main-container mt-3">
                <div className="custom-heade-case-summary alert alert-info">
                    <div><strong>Case Name :{fileName === null ? '-' : fileName}</strong></div>
                    {/* <div className="sub-container btn-group">
                        <button onClick={() => nameModalVisible()} className="btn">Save</button>
                        <button onClick={() => closeModal()} className="btn">Close</button>
                    </div> */}
                </div>
                <div className="all-action-container mt-3" >
                    {/* <div className="btn-group">
                    <button className="btn sub-nav active"><Link to='/startacase/casesummary'>
                            CASE SUMMARY
                        </Link>
                        </button>
                        <button className="btn sub-nav">
                        <Link  to='/startacase/actionsummary'>
                            ACTION SUMMARY
                        </Link>
                        </button>
                        <button className="btn sub-nav" disabled> CASENAME: {fileName === null ? '-' : fileName}</button>
                        </div> */}
                    <div className="answer-wrapper">
                        <div className="result-container mt-3" >
                            <div className="answer-container" >
                                <div>
                                    1. AGE
                                </div>
                                <div>
                                    {age} {age != null?<>{age <=1 ?<>{ageType}</>:<>{ageType}s</>}</>:<></>}
                                </div>
                            </div>
                            <div className="answer-container" >
                                <div>
                                    2. GENDER
                                </div>
                                <div>
                                    {gender}
                                </div>
                            </div>
                            <div className="answer-container" >
                                <div>
                                    3. PATIENT TYPE
                                </div>
                                <div>
                                    {patientType}
                                </div>
                            </div>
                            <div className="answer-container" >
                                <div>
                                    4. HEIGHT
                                </div>
                                <div>
                                    {height}{heightType!==null?"":null} {heightType}
                                </div>
                            </div>
                            <div className="answer-container" >
                                <div>
                                    5. WEIGHT
                                </div>
                                <div>
                                    {weight}{weightType!==null?"":null} {weightType}
                                </div>
                            </div>
                            <div className="answer-container" >
                                <div>
                                    6. ASA-PS
                                </div>
                                <div>
                                    {
                                        asa_ps.map((data) => {
                                            return (
                                                <div>{data.name}, {data.emergency}</div>
                                            )
                                        })
                                    }
                                    {/*asaps.map((data) => {
                                        return (
                                            <div>
                                                {data.label},
                                            </div>
                                        )
                                    })*/}
                                </div>
                            </div>
                            <div className="answer-container" >
                                <div>
                                    7. SURGERY TYPE
                                </div>
                                <div  >
                                    {
                                        surgeryData.map((data) => {
                                            return (
                                                <div>{data.name}</div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                            <div className="answer-container" >
                                <div>
                                    8. ANESTHESIA TYPE
                                </div>
                                <div>
                                    {anesthesiaTypes.map((data) => {
                                        return (
                                            <p>{data.name}</p>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="answer-container" >
                                <div>
                                    9. NPO STATUS
                                </div>
                                <div>
                                    {npo_status_time}{foodTimeType!==null?"":null} {foodTimeType}{npo_status_materials!==null?"":null}  {npo_status_materials}
                                </div>
                            </div>

                            <div className="answer-container" >
                                <div>
                                    10. MEDICATIONS
                                </div>
                                <div>

                                    {
                                        midications.map((data) => {
                                            return (
                                                <div>{data.name}</div>
                                            )
                                        })
                                    }
                                    {/*
                                    medication.map((data) => {
                                        return (
                                            <div>{data.label}</div>
                                        )
                                    })
                                */}
                                </div>
                            </div>

                            <div className="answer-container" >
                                <div>
                                    11.  ALLERGIES
                                </div>
                                <div>

                                    {
                                        allergies.map((data) => {
                                            return (
                                                <div>{data.name}</div>
                                            )
                                        })
                                    }
                                    {/*
                                    allegries.map((data) => {
                                        return (
                                            <div>{data.label}</div>
                                        )
                                    })
                                */}
                                </div>
                            </div>


                            <div className="answer-container" >
                                <div>
                                    12. MEDICAL HISTORY
                                </div>
                                <div>

                                    {
                                        midical_histories.map((data) => {
                                            return (
                                                <div>{data.name}</div>
                                            )
                                        })
                                    }
                                    {/*
                                        medicalHistoryArray.map((data) => {
                                            return (
                                                <div>
                                                    {data},
                                                </div>
                                            )
                                        })
                                    */}
                                </div>
                            </div>


                            <div className="answer-container" >
                                <div>
                                    13. SURGICAL HISTORY
                                </div>
                                <div>
                                    {surgeryHistory.map((data) => {
                                        return (
                                            <p>{data.name}</p>
                                        )
                                    })}

                                </div>
                            </div>

                            <div className="answer-container" >
                                <div>
                                    14. ANESTHESIA HISTORY
                                </div>
                                <div>

                                    {
                                        anesthesia_histories.map((data) => {
                                            return (
                                                <div>{data.name}</div>
                                            )
                                        })
                                    }
                                    {/*
                                    anesethesiaSubHistory.map((data) => {
                                        return (
                                            <div>
                                                {data.label}
                                            </div>
                                        )
                                    })
                                */}
                                </div>
                            </div>
                           
                            {/* <div className="answer-container" >
                                <div>
                                    16. CASE Notes
                                </div>
                                <div>{case_notes_img ? <img src={case_notes_img} className="img-thumbnail" />:<></>} {case_notes !== "null" ? <>{case_notes}</>:<></> }</div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CaseSummaryData