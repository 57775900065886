import React, { useState, useEffect } from 'react'
import '../css/index.css'
import { API_ROOT } from '../constants'
import Spinner from '../CustomComponent/Spinner';
import Alert from '../CustomComponent/Alert';
const Recent = (props) => {
    const [caseSummaryList, setcaseSummaryList] = useState([])
    const [loading, setLoading] = useState(false);
    const [alertVisible, setAlertVisible] =  useState(false);
    const [message, setMessage] = useState('');
    useEffect(() => {
        if (sessionStorage.getItem("token") === null) {
            setLoading(false);
            setAlertVisible(true);
            setMessage('User session expired');
        }
        getCaseSummaryList()
    }, [])

    const closeAlert = () => {
        setAlertVisible(false);
        props.history.push({
            pathname: '/login'
        })
    }
    const getCaseSummaryList = () => {
        var token = sessionStorage.getItem("token")
        fetch(API_ROOT + `user-case-summaries?token=${token}`)
            .then(response => response.json())
            .then(res => {
                if (res.status === "true" && res.message === "User Case Summarries") {
                    setcaseSummaryList(res.data);
                    setLoading(true);
                }
            })

    }

    const deleteCaseSummaryAsPerId = (casesummaryId) => {
        var token = sessionStorage.getItem("token")
        fetch(API_ROOT + `case-summary/${casesummaryId}?token=${token}`, {
            method: 'DELETE',

        })
            .then(response => response.json())
            .then(res => {
                window.location.reload()
            })
    }
    const formatedDate = (dated) => {
        var strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var inputDate = new Date(dated);
        var d = inputDate.getDate();
        var m = strArray[inputDate.getMonth()];
        var y = inputDate.getFullYear();
        return '' + (d <= 9 ? '0' + d : d) + '-' + m + '-' + y;
    }
    const shareIt = () => {
       alert('The ability to share cases with other anesthesia one members and print cases is coming soon');
    }

    return (
        <div>
            <div className="main-container" >

                <div className="col-md-12 pl-0 pr-2">

                    <div className="conArea d-block">
                        {/* <div className="LHeadings text-center">
                        <a href="#" onclick="goBack()"><i className="fa fa-chevron-left"></i></a> CASES
                    </div> */}

                        <div className="savedCases">
                           
                            {caseSummaryList.length > 0 ? <>
                                <ul className="savdcard mt-1">
                                    {loading ?

                                        (caseSummaryList).map((data) => {
                                            return (
                                                <li  >
                                                    <div onClick={() => props.history.push({
                                                        pathname: '/casesummarydata',
                                                        state: { caseSummaryId: data.id }
                                                    })} className="cszeDetail">
                                                        <div className="caption"><strong>Case ID:{data.id}</strong></div>
                                                        <div className="caption"><strong>{data.name}</strong></div>
                                                        <div className="date mt-1">{formatedDate(data.created_at)}</div>
                                                    </div>

                                                    <div className="soclsBtn btn-group">
                                                        <a href="#" type="button" className="btn action-btn pt-3" onClick={(e) => {e.preventDefault();shareIt(data.id)}}><i className="fa fa-share"></i></a>
                                                        <a href="#" type="button" className="btn action-btn pt-3"> <i onClick={() => deleteCaseSummaryAsPerId(data.id)} className="fa fa-trash"></i></a>
                                                        <a href="#" type="button" className="btn action-btn pt-3"><i onClick={() => props.history.push({
                                                            pathname: '/casesummarydata',
                                                            state: { caseSummaryId: data.id }
                                                        })} className="fa fa-edit"></i></a>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </li>

                                            )
                                        })
                                        : <Spinner />
                                    }
                                </ul>
                                </>:<><div style={{textAlign:'center', padding:10, backgroundColor:'#F1F1F1'}}>No Cases added</div></>
                            }
                        </div>


                    </div>
                </div>
            </div>
            <Alert isOpen={alertVisible} message={message} label="Go to Login" closeAlert={() => { closeAlert() }} />

        </div>

    )
}

export default Recent
