import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { API_ROOT } from '../../constants';

const DrugSearchResults = (props) => {
let history = useHistory();
const [drugs, setDrugs] = useState([]);


const getDrugDetails = () => {
    var token = sessionStorage.getItem("token")
    fetch(API_ROOT + `search-drug-brand-name?token=${token}`, {
        method: 'POST',

        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({

            drug_brand_name: props.location.state.search_text
        })
    })

        .then(response => response.json())
        .then(res => {
            if (res.status === "true") {
                setDrugs(res.data);
                Object.keys(drugs)
              
            }
        })

}

useEffect(() => {

    getDrugDetails();

}, []);

return(
<div className="main-container">
<div className="p-4"><i className="fa fa-chevron-circle-left" style={{fontSize:'1.6em', cursor:'pointer'}} onClick={()=>{history.goBack()}}>&nbsp;&nbsp;</i>SEARCH RESULTS: {props.location.state.search_text}</div>
<table className="table table-striped table-hover mt-2 drug-result">
                            <thead className="bg-header text-center text-white stickheader">
                                <tr>
                                    <th>Drug name</th>
                                    <th>Admin Route</th>
                                    <th>Indication</th>
                                    <th>Target Organ</th>
                                    <th>Organ system</th>
                                    <th>Patient type</th>
                                    <th>Final Dose Unit</th>
                                </tr>
                            </thead>
                            <tbody>
                            {/* onClick={() => gotoDrugs(drugData.drug_brand_name, drugData.id)} */}
                                {
                                    drugs.map((drugData, key) => {
                                        return (
                                            <tr className="text-center" key={key}>
                                                <td><span  className="sectionLink">{drugData.drug_brand_name}</span></td>
                                                <td>{drugData.administration_route}</td>
                                                <td>{drugData.indication}</td>
                                                <td>{drugData.name}</td>
                                                <td>{drugData.organ_systems_name}</td>
                                                <td>{drugData.patient_type_name}</td>
                                                <td>{drugData.bolus_dose_final_unit}</td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                        <div className="drug-result_mobile mb-5 p-3">
                            {
                                drugs?.map((drugData, i) => {
                                    return (
                                        <div className="card w-100 mt-2 p-2" key={i}>
                                            <div className="mb-2 bg-header"><strong>{drugData.drug_brand_name}</strong></div>
                                            <div className="d-flex mb-2">
                                                <div className="mr-1">Admin Route: </div>
                                                <div> {drugData.administration_route}</div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="mr-1">Indication: </div>
                                                <div> {drugData.indication}</div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="mr-1">Target Organ: </div>
                                                <div>{drugData.name}</div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="mr-1">Organ system: </div>
                                                <div>{drugData.organ_systems_name}</div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="mr-1">Patient type: </div>
                                                <div>{drugData.patient_type_name}</div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="mr-1">Final Dose Unit: </div>
                                                <div> {drugData.bolus_dose_final_unit}</div>
                                            </div>
                                            
                                        </div>
                                    )
                                })
                            }
                        </div>
                        </div> 

                
                )

               
}
export default DrugSearchResults