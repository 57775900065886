import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';
const EuroScoreCardivascular = () => {
    const [green, setGreen] = useState(false);
    const [age2, setAge2] = useState(0)
    const [sex, setSex] = useState(0);
    const [insulin, setInsulin] = useState(0);
    const [chronic, setChronic] = useState(0);
    const [nurological, setNurological] = useState(0);
    const [renal, setRenal] = useState(0);
    const [nyha, setNyha] = useState(0);
    const [css, setCss] = useState(0);
    const [extracardic, setExtracardiac] = useState(0);
    const [critical, setCritical] = useState(0);
    const [lv, setLv] = useState(0);
    const [cardiac, setCardiac] = useState(0);
    const [active, setActive] = useState(0);
    const [mi, setMi] = useState(0);
    const [pulmonary, setPulmonary] = useState(0);
    const [thoracic, setThoracic] = useState(0);
    const [urgency, setUrgency] = useState(0);
    const [weight, setWeight] = useState(0)
    const [result, setResult] = useState(0);
    const [result2, setResult2] = useState(0);
    const [result3, setResult3] = useState(0);
    const postData = async () => {
        if(age2!==0&&age2!==""){
        if ((age2 <= 60)) {
            setAge2(0.0285181)

        }
        else {
            setAge2(0.0285181)
            for (let i = 1; i >= 60; i++) {
                setAge2(0.0285181 * i)
            }
        }
        setResult3((Math.pow(2.718281828,(-5.324537 + (age2 + sex + insulin + chronic + nurological + renal + nyha + css + extracardic + lv + cardiac + active + mi + pulmonary + thoracic + urgency + weight + critical))) / (1 + Math.pow(2.718281828,(-5.324537 + (age2 + sex + insulin + chronic + nurological + renal + nyha + css + extracardic + lv + cardiac + active + mi + pulmonary + thoracic + urgency + weight + critical)))) * 100).toFixed(2))
        setGreen(true)
        }
    else{
        setGreen(false)
        }
    }
    useEffect(() => {

        postData();


    }, [age2, sex, insulin, chronic, nurological, renal, nyha, css, extracardic, lv, cardiac, active, mi, pulmonary, thoracic, urgency, weight, critical]);

    return (
        <div className="rev">
            <h5 className="text-info pl-2">European System for Cardiac Operative Risk Evaluation (EuroSCORE) II</h5>
            <p className="pl-2">Predicts risk of in-hospital mortality after major cardiac surgery.</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Age</strong></div>

                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        onChange={(e) => setAge2(e.target.value)}
                        className="ant-text-input" style={{ width: '77%'}} /><span className="units">year</span>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Sex</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={sex === 0 ? "btn active" : "btn"} onClick={() => setSex(0)}>Male <span className="ml-4 text-muted"></span></button>
                    <button style={{ height: 40}} key="2" className={sex === 0.2196434 ? "btn active" : "btn"} onClick={() => setSex(0.2196434)}>Female <span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Insulin-dependent diabetes mellitus</strong></div>

                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={insulin === 0 ? "btn active" : "btn"} onClick={() => setInsulin(0)}>No  <span className="ml-4 text-muted"></span></button>
                    <button style={{ height: 40 }} key="2" className={insulin === 0.3542749 ? "btn active" : "btn"} onClick={() => setInsulin(0.3542749)}>Yes <span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Chronic pulmonary dysfunction</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={chronic === 0 ? "btn active" : "btn"} onClick={() => setChronic(0)}>No  <span className="ml-4 text-muted"></span></button>
                    <button style={{ height: 40 }} key="2" className={chronic === 0.1886564 ? "btn active" : "btn"} onClick={() => setChronic(0.1886564)}>Yes <span className="ml-4 text-muted"></span></button>
                </div>
            </div>

            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Neurological or musculoskeletal dysfunction severely affecting mobility</strong></div>

                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={nurological === 0 ? "btn active" : "btn"} onClick={() => setNurological(0)}>No  <span className="ml-4 text-muted"></span></button>
                    <button style={{ height: 40 }} key="2" className={nurological === 0.2407181 ? "btn active" : "btn"} onClick={() => setNurological(0.2407181)}>Yes <span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Renal dysfunction</strong></div>
                    <div>Creatinine clearance by Cockcroft-Gault formula</div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn">
                    <button onClick={() => setRenal(0)} className={renal === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">
                        &gt;85 mL/min</span><span className="text-right pv text-muted">0</span></button>
                    <button onClick={() => setRenal(0.303553)} className={renal === 0.303553 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">51-85 mL/min</span><span className="text-right pv text-muted"></span></button>
                    <button onClick={() => setRenal(0.8592256)} className={renal === 0.8592256 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">≤50 mL/min
                    </span><span className="text-right pv text-muted"></span></button>
                    <button onClick={() => setRenal(0.6421508)} className={renal === 0.6421508 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">On dialysis (regardless of serum creatinine)
                    </span><span className="text-right pv text-muted"></span></button>
                </div>

            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Critical preop state</strong></div>
                    <div> &ge; of the following in the same hospital admission as the operation: ventricular tachycardia or fibrillation or aborted sudden death; cardiac massage; ventilation before arrival to OR; inotropes; IABP or VAD before arrival to OR; acute renal failure, defined as anuria or oliguria &lt;10 mL/hr</div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={critical === 0 ? "btn active" : "btn"} onClick={() => setCritical(0)}>No  <span className="ml-4 text-muted"></span></button>
                    <button style={{ height: 40 }} key="2" className={critical === 1.086517 ? "btn active" : "btn"} onClick={() => setCritical(1.086517)}>Yes <span className="ml-4 text-muted"></span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>NYHA class</strong></div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn">
                    <button style={{ height:  50   }} onClick={() => setNyha(0)} className={nyha === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Class I: no symptoms on moderate exertion</span><span className="text-right pv text-muted"></span></button>
                    <button style={{ height:  50   }} onClick={() => setNyha(0.1070545)} className={nyha === 0.1070545 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Class II:symptoms on <br/>moderate exertion</span><span className="text-right pv text-muted"></span></button>
                    <button style={{ height:  50   }} onClick={() => setNyha(0.2958358)} className={nyha === 0.2958358 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Class III:symptoms on <br/>light exrtion</span><span className="text-right pv text-muted"></span></button>
                    <button style={{ height:  50   }} onClick={() => setNyha(0.5597929)} className={renal === 0.5597929 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Class IV:symptoms at rest</span><span className="text-right pv text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>CCS class 4</strong></div>
                    <div>Inability to perform any activity without angina or angina at rest</div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={css === 0 ? "btn active" : "btn"} onClick={() => setCss(0)}>No  <span className="ml-4 text-muted"></span></button>
                    <button style={{ height: 40 }} key="2" className={css === 0.2226147 ? "btn active" : "btn"} onClick={() => setCss(0.2226147)}>Yes <span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Extracardiac arteriopathy</strong></div>
                    <div>≥1 of the following: claudication; carotid occlusion or &gt;50% stenosis (NASCET criteria); amputation for arterial disease; previous or planned intervention on abdominal aorta, limb arteries, or carotids</div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={extracardic === 0 ? "btn active" : "btn"} onClick={() => setExtracardiac(0)}>No  <span className="ml-4 text-muted"></span></button>
                    <button style={{ height: 40 }} key="2" className={extracardic == 0.5360268 ? "btn active" : "btn"} onClick={() => setExtracardiac(0.5360268)}>Yes <span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Previous cardiac surgery</strong></div>
                    <div>≥1 previous major cardiac operation involving opening the pericardium</div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={cardiac === 0 ? "btn active" : "btn"} onClick={() => setCardiac(0)}>No  <span className="ml-4 text-muted"></span></button>
                    <button style={{ height: 40 }} key="2" className={cardiac === 1.118599 ? "btn active" : "btn"} onClick={() => setCardiac(1.118599)}>Yes <span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Active endocarditis</strong></div>
                    <div>i.e., on antibiotics for endocarditis at time of surgery</div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={active === 0 ? "btn active" : "btn"} onClick={() => setActive(0)}>No  <span className="ml-4 text-muted"></span></button>
                    <button style={{ height: 40 }} key="2" className={active === 0.6194522 ? "btn active" : "btn"} onClick={() => setActive(0.6194522)}>Yes <span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>LV function or LVEF</strong></div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn">
                    <button style={{ height:  40   }} onClick={() => setLv(0)} className={lv === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Good (LIVE &ge;51%)</span><span className="text-right pv text-muted"></span></button>
                    <button style={{ height:  40   }} onClick={() => setLv(0.3150652)} className={lv === 0.3150652 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Moderate(LIVEF 31-50%)</span><span className="text-right pv text-muted"></span></button>
                    <button style={{ height:  40   }} onClick={() => setLv(0.8084096)} className={lv === 0.8084096 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Poor (LIVE 21-30%)</span><span className="text-right pv text-muted"></span></button>
                    <button style={{ height:  40   }} onClick={() => setLv(0.9346919)} className={lv === 0.9346919 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Very Poor (LIVE &le;20%)</span><span className="text-right pv text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Recent MI</strong></div>
                    <div>≤90 days before operation</div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={mi === 0 ? "btn active" : "btn"} onClick={() => setMi(0)}>No  <span className="ml-4 text-muted"></span></button>
                    <button style={{ height: 40 }} key="2" className={mi === 0.1528943 ? "btn active" : "btn"} onClick={() => setMi(0.1528943)}>Yes <span className="ml-4 text-muted"></span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Pulmonary artery systolic pressure, mm Hg</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: "100%" }} key="1" className={pulmonary === 0 ? "btn active" : "btn"} onClick={() => setPulmonary(0)}>{"<31"}  <span className="ml-4 text-muted"></span></button>
                    <button style={{ height: "100%" }} key="2" className={pulmonary === 0.1788899 ? "btn active" : "btn"} onClick={() => setPulmonary(0.1788899)}>31-54 <span className="ml-4 text-muted"></span></button>
                    <button style={{ height: "100%" }} key="3" className={pulmonary === 0.3491475 ? "btn active" : "btn"} onClick={() => setPulmonary(0.3491475)}>{">=55"} <span className="ml-4 text-muted"></span></button>

                </div>

            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Urgency</strong></div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn">
                    <button style={{ height:100 }} onClick={() => setUrgency(0)} className={urgency === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Elective: routine admission for operation</span><span className="text-right pv text-muted"></span></button>
                    <button style={{ height:100 }} onClick={() => setUrgency(0.3174673)} className={urgency === 0.3174673 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Urgent: not electively admitted <br/>for operation but require surgery on current <br/>admission for medical reasons and cannot be<br/> discharged without definitive procedure</span><span className="text-right pv text-muted"></span></button>
                    <button style={{ height:100 }} onClick={() => setUrgency(0.7039121)} className={urgency === 0.7039121 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Emergency: operation before the beginning of <br/>the next working day after the decision to operate</span><span className="text-right pv text-muted"></span></button>
                    <button style={{ height:100 }} onClick={() => setUrgency(1.362947)} className={urgency === 1.362947 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Salvage: patients requiring CPR (external) en <br/>route to the OR or before induction of anesthesia<br/> (excludes CPR after induction of anesthesia)</span><span className="text-right pv text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>“Weight” of procedure</strong></div>
                    <div>i.e., extent of intervention</div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn">
                    <button style={{ height: 40 }} onClick={() => setWeight(0)} className={weight === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">
                        i.e., extent of intervention
                        Isolated CABG</span><span className="text-right pv text-muted"></span></button>
                    <button style={{ height: 100 }} onClick={() => setWeight(0.0062118)} className={weight === 0.0062118 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Isolated non-CABG major procedure<br/> (e.g. single valve procedure, replacement<br/> of ascending aorta, correction <br/>of septal defect, etc)</span><span className="text-right pv text-muted"></span></button>
                    <button style={{ height: 120 }} onClick={() => setWeight(0.5521478)} className={weight === 0.5521478 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">2 major procedures (e.g. CABG and AVR),<br/> or CABG and mitral valve repair (MVR),<br/> or AVR and replacement of ascending aorta,<br/> or CABG and maze procedure, or AVR <br/>and MVR, etc</span><span className="text-right pv text-muted"></span></button>
                    <button style={{ height: 135 }} onClick={() => setWeight(0.97245333)} className={weight === 0.97245333 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">≥3 major procedures (e.g. AVR, MVR, and <br/>CABG, or MVR, CABG, and <br/>tricuspid annuloplasty, etc), or aortic root<br/> replacement when it includes AVR or repair, <br/>coronary reimplantation, and <br/>root and ascending replacement</span><span className="text-right pv text-muted"></span></button>
                </div>

            </div>
            <div className="fields row mb-5">
                <div className="col-md-6">
                    <div ><strong>Thoracic aorta surgery</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={thoracic === 0 ? "btn active" : "btn"} onClick={() => setThoracic(0)}>No <span className="ml-4 text-muted"></span></button>
                    <button style={{ height: 40 }} key="2" className={thoracic === 0.6527205 ? "btn active" : "btn"} onClick={() => setThoracic(0.6527205)}>Yes <span className="ml-4 text-muted"></span></button>
                </div>
            </div>

            {
                <div className="stick pt-3">
                    {
                        green ?
                            <div className="apfel-score-result-container"  >
                                <div className="point-result-container" >
                                    <h1>{result3} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>%</span></h1>
                                    <div>Predicted mortality by EuroSCORE II</div>
                                </div>
                            </div>
                            :
                            <div className="apfel-score-result-container"  >
                                <div className="point-result-container" >
                                    <h1>Result:</h1>
                                    <div>Please fill out required fields.</div>
                                </div>
                            </div>
                    }
                </div>
            }
            <div className="p-2 mb-4" >
                <div>
                    OTHER REFERENCES
                </div>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/7296795/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Goldman L,Hashimoto B, Cook EF, Loscalzo A.Comparative reproducibility and validity of systems for assessing cardiovascular fun ctional class: advantages of a new specific activity scale.Circulation.1981;64(6):1227-34</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default EuroScoreCardivascular
