import React, { useState } from 'react'
import BurgerMenuModal from '../CustomComponent/BurgerMenuModal'
import Header from '../CustomComponent/Header'
import { Link } from 'react-router-dom'

const CaseTipSubInfo = (props) => {
    const [burgerMenu, setburgerMenu] = useState(false)
    return (
        <div  >
            <BurgerMenuModal modalIsOpen={burgerMenu} />
            {/* <Header onMenuClick={() => burgerMenuClick()} /> */}
            
            <div className="backNavigation text-center">
                <Link className="arrow" to="/actionLibrary"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Action Library</span></span></Link>{props.location.state.case_name}
            </div>
            <div className="all-action-container" >
                {/* <div className="tab-container" >
                    <Link to="/casesummary" className="tab-container-tabs" >
                        START A CASE
                    </Link>

                    <Link to="/favourite" className="tab-container-tabs " >
                        FAVOURITE
                    </Link>
                    <Link className="tab-container-tabs " to="/recent" >CASES</Link>
                    <Link to="/actionLibrary" className="tab-container-tabs active-tab" >ACTION LIBRARY</Link>

                </div> */}
               { props.location.state.dataInfo ? 
                <div className="mt-3">
                    {
                        props.location.state.dataInfo.map((data, key) => {
                            return (
                                <>
                                    <h5 key={key}>{data.name}</h5>
                                    {
                                        data.children ?
                                            <>
                                                {
                                                    data.children.map((data1, id) => {
                                                        return (
                                                            <>
                                                                <div className="alert alert-info" style={{fontWeight:'bold'}}>{id + 1}. {data1.name}</div>
                                                                <ul> {
                                                                    data1.children ? <>
                                                                        {
                                                                          
                                                                            data1.children.map((data2, key) => {
                                                                                return (
                                                                                    <>
                                                                                        <li style={{listStyleType: 'upper-roman'}} key={key}>{data2.name}</li>

                                                                                    </>
                                                                                )
                                                                            })
                                                                           
                                                                        }
                                                                      
                                                                    </> : <></>
                                                                }
                                                                  </ul>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </>
                                            : <></>
                                    }
                                </>

                            )
                        })
                    }
                </div>:<>Data Not Available</>
}


                <div className="col-md-2"></div>
            </div>
        </div>
    )
}

export default CaseTipSubInfo
