import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';
const RoxIndexAirwayCalculator = () => {
    const [green, setGreen] = useState(true);
    const [spo, setSpo] = useState(0);
    const [fio, setFio] = useState(0);
    const [respiratoryRate, setRespiratoryRate] = useState(0);
   
    const [exchange_volume, setExchange_volume] = useState(0);

    const postData = async () => {
        if((spo > 80)&&(spo < 101)&&(fio > 10)&&(fio < 101)&&(respiratoryRate< 90)){
            setExchange_volume(((spo/fio)*100/respiratoryRate).toFixed(2));
        }
        else{
            setExchange_volume(0);
        }
        setGreen(true);
        
    }


    const valnumber2 = () => {
        if (spo) {
            if ((spo < 80) && (spo != 0) && (spo > 0)) {
                return <div className="validate">*Too Low;please change to proceed.</div>

            }
            else if ((spo < 90) && (spo > 79)) {
                return <div className="validate">*Very low;double check.</div>

            }
            else if (spo > 101) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(spo)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }

    }
    const valnumber1 = () => {
        if (fio) {
            if ((fio < 10) && (fio != 0) && (fio > 0)) {
                return <div className="validate">*Too Low;please change to proceed.</div>

            }
            else if ((fio < 19) && (fio > 10)) {
                return <div className="validate">*Very low;double check.</div>

            }
            else if (fio > 101) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(fio)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }

    }
    const valnumber3 = () => {
        if (respiratoryRate) {
            if ((respiratoryRate < 6) && (respiratoryRate != 0) ) {
                return  <div className="validate">*Very Low;double check.</div>

            }
            else if ((respiratoryRate < 91) && (respiratoryRate > 70)) {
                return  <div className="validate">*Very high;double check.</div>

            }
            else if (respiratoryRate> 90) {
                return  <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(respiratoryRate)) {
                return  <div className="validate">*Please Enter valid-no.</div>

            }
        }

    }
    useEffect(() => {
        postData()

    }, [spo, fio, respiratoryRate, exchange_volume])
    return (<div className="rev">
        <h5 className="text-info pl-2">ROX Index for Intubation after HFNC</h5>
<p className="pl-2">Predicts high-flow nasal cannula (HFNC) failure/need for intubation.</p>
        <div className="fields row">
            <div className="col-md-6">
                <div className="pt-2"><strong>SpO₂</strong></div>
            </div>
            <div className="col-md-6 frm-field">
                <input  onChange={(e) => setSpo(e.target.value)} placeholder="Norm: 95 - 100" className="ant-text-input" style={{ width: '76%'}} /><span className="units">%</span>
                <div>{valnumber2()}</div>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-6">
                <div className="pt-2"><strong>FiO₂</strong></div>
            </div>
            <div className="col-md-6 frm-field">
                <input  onChange={(e) => setFio(e.target.value)} className="ant-text-input" style={{ width: '76%' }} /><span className="units">%</span>
                <div>{valnumber1()}</div>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-6">
                <div className="pt-2"><strong>Respiratory rate</strong></div>
            </div>
            <div className="col-md-6 frm-field">
                <input  onChange={(e) => setRespiratoryRate(e.target.value)} className="ant-text-input fluidInput" style={{height:"100%"}} placeholder="Norm: 12 - 20" /><span className="units unitBtnfu" style={{height:"100%"}}>breaths/min</span>
                <div>{valnumber3()}</div>
            </div>
        </div><br/>
        {isNaN(exchange_volume) || exchange_volume === 'Infinity' || exchange_volume === 0 ?
            <div className="text-white p-2 green-bg"><h1>Result:</h1><br/>
            <h6>Please fill out required fields.</h6></div>:
        <div>
            {
                green ?
                
                    <div className= "apfel-score-result-container active" >
                        <div className="point-result-container" >
                            <h1>{exchange_volume} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                            ROX Index
                        </div>
                        <div className="percentage-result-container" >
                            {exchange_volume >= 4.88 ? "Low risk of progressing to intubation" : ""}
                            {exchange_volume < 3.85 ? "Risk of HFNC failure is high, and intubating the patient should be discussed" : ""}
                            {exchange_volume > 3.85 && exchange_volume < 4.88 ? "The scoring could be repeated one or two hours later for further evaluation." : ""}
                        </div>
                    </div>
                    :
                    <></>
            }
             </div>
}

            <div className="p-2 mb-5" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://www.sciencedirect.com/science/article/abs/pii/S0883944116300946?via%3Dihub">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Roca O, Messika J, Caralt B, et al. Predicting success of high-flow nasal cannula in pneumonia patients with hypoxemic respiratory failure: The utility of the ROX index. J Crit Care. 2016;35:200-5.</p>
                </div>
                </a>
            </div>
       
    </div>
    )
}
export default RoxIndexAirwayCalculator;