import React, { useEffect, useState } from 'react';
import './RE1233.css';


const RE1233 = () => {
    const [green, setGreen] = useState(0);
    const [bmi, setBmi] = useState(9);
    const [weight, setWeight] = useState(9);
    const [reducer, setReducer] = useState(9);
    const [icu, setIcu] = useState(9);
    const [nutritional, setNutritional] = useState(null);
    const [severity, setSeverity] = useState(null);
    const [age, setAge] = useState(null);

    const [result, setResult] = useState(0);
    const [result2, setResult2] = useState(0);

    const postData = async () => {
        if ((bmi == 2) || (weight == 2) || (reducer == 2) || (icu == 2)) {
            setResult(nutritional + severity + age);
        }
        else {
            setResult(0);
            setNutritional(0)
            setSeverity(0);
            setAge(0);
        }

        setGreen(0);
    }
    useEffect(() => {
        postData();


    }, [nutritional, severity, age, result]);
    const Newfiels = () => {
        if ((bmi == 2) || (weight == 2) || (reducer == 2) || (icu == 2)) {
            return <div>
                <div className="alert alert-dark">Final Screening
                </div>
                <div className="fields row">
                    <div className="col-md-6">
                        <div className="pt-2"><strong>Nutritional impairment
                        </strong></div>
                    </div>
                    <div className="col-md-6 btn-group-vertical options-btn mb-2">
                        <button onClick={() => setNutritional(0)} className={nutritional === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">
                            None</span><span className="calc-val ">0</span></button>
                        <button style={{ height:  40 }} onClick={() => setNutritional(1)} className={nutritional === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Mild: weight loss &gt;5% in 3 months or food intake &lt;50–75% of normal in the past week+1</span><span className="calc-val ">+2</span></button>
                        <button style={{ height:  40 }} onClick={() => setNutritional(2)} className={nutritional === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moderate: weight loss &gt;5% in 2 months or BMI 18.5–20.5 plus impaired general condition or food intake 25–60% of normal in past week</span><span className="calc-val ">+2</span></button>
                        <button style={{ height:  40 }} onClick={() => setNutritional(3)} className={nutritional === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Severe: weight loss &gt;5% in 1 month (&gt;15% in 3 months) or BMI 	&lt;18.5 plus impaired general condition or food intake 0–25% of normal in past week</span><span className="calc-val ">+2</span></button>
                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-6">
                        <div className="pt-2"><strong>Severity of disease
                        </strong></div>

                    </div>
                    <div className="col-md-6 btn-group-vertical options-btn mb-2">
                        <button onClick={() => setSeverity(0)} className={severity === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">

                            Normal nutritional requirement</span><span className="calc-val ">0</span></button>
                        <button style={{ height:  40 }} onClick={() => setSeverity(1)} className={severity === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Hip fracture, chronic illness (may have acute complications, e.g. cirrhosis or COPD), chronic dialysis, diabetes, cancer</span><span className="calc-val ">+1</span></button>
                        <button style={{ height:  40 }} onClick={() => setSeverity(2)} className={severity === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Major abdominal surgery, stroke, severe pneumonia, hematologic malignancy</span><span className="calc-val ">+2</span></button>
                        <button style={{ height:  40 }} onClick={() => setSeverity(3)} className={severity === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Head injury, bone marrow transplant, ICU patient with APACHE 	&gt;10</span><span className="calc-val ">+3</span></button>
                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-6">
                        <div className="pt-2"><strong>Age
                        </strong></div>
                    </div>
                    <div className="col-md-6 btn-group-vertical options-btn mb-2">
                        <button style={{ height:  40 }} onClick={() => setAge(0)} className={age === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">
                            &lt;70 years</span><span className="calc-val ">0</span></button>
                        <button style={{ height:  40 }} onClick={() => setAge(1)} className={age === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">≥70 years</span><span className="calc-val ">+1</span></button>
                    </div>
                </div>
            </div>
        }

    }
    const result1 = () => {
        if ((bmi == 2) || (weight == 2) || (reducer == 2) || (icu == 2)) {
        if ((result >= 0) && (result <= 3)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h2> {result} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>points</span></h2>
                    <div>
                        NSR-2002</div>
                </div>
                <div className="point-result-container" >
                    <h2> Low risk </h2>
                    <div>Re-screen weekly</div>
                </div>
            </div>
        }
        // if ((result == 0)) {
        //     return <div className="apfel-score-result-container" >
        //         <div className="point-result-container" >
        //             <h2> Low risk </h2>
        //             <div>Re-screen weekly</div>
        //         </div>
        //     </div>
        // }
        if ((result == 4)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h2> {result} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>points</span></h2>
                    <div>
                        NSR-2002</div>
                </div>
                <div className="point-result-container" >
                    <h2> At risk </h2>
                    <div>Initiate nutritional care plan (food, oral supplements, tube feeding, and/or parenteral nutrition as appropriate)</div>
                </div>
            </div>
        }
        if ((result >= 5) && (result <= 7)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h2> {result} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>points</span></h2>
                    <div>
                        NSR-2002</div>
                </div>
                <div className="point-result-container" >
                    <h2>
                        High risk </h2>
                    <div>Initiate early intervention nutritional care plan</div>
                </div>
            </div>
        }
    }
    if ((bmi == 9) && (weight == 9) && (reducer == 9) && (icu == 9)) {
        return <div className="apfel-score-result-container" >
                 <div className="point-result-container" >
                     <h2> Low risk </h2>
                     <div>Re-screen weekly</div>
                 </div>
             </div>
    }

        else {
            return <div className="apfel-score-result-container" >
            <div className="point-result-container" >
                <h2> Low risk </h2>
                <div>Re-screen weekly</div>
            </div>
        </div>
        }

    }



    return (
        <div className="rev">
            <h5 className="text-info pl-2">Nutrition Risk Screening 2002 (NRS-2002)</h5>
            <p className="pl-2">Predicts risk of malnutrition in hospitalized patients, recommended by ACG guidelines.</p>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>BMI &lt; 20.5 kg/m²</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40 }} key="1" className={bmi === "No" ? "btn active" : "btn"} onClick={() => setBmi("No")}>No  <span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40 }} key="2" className={bmi === 2 ? "btn active" : "btn"} onClick={() => setBmi(2)}>Yes <span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Weight loss within 3 months</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40 }} key="1" className={weight === "No" ? "btn active" : "btn"} onClick={() => setWeight("No")}>No  <span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40 }} key="2" className={weight === 2 ? "btn active" : "btn"} onClick={() => setWeight(2)}>Yes <span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Reduced dietary intake in the last week</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40 }} key="1" className={reducer === "No" ? "btn active" : "btn"} onClick={() => setReducer("No")}>No  <span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40 }} key="2" className={reducer === 2 ? "btn active" : "btn"} onClick={() => setReducer(2)}>Yes <span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="fields row mb-5">
                <div className="col-md-6">
                    <div ><strong>ICU patient</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40 }} key="1" className={icu === "No" ? "btn active" : "btn"} onClick={() => setIcu("No")}>No  <span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40 }} key="2" className={icu === 2 ? "btn active" : "btn"} onClick={() => setIcu(2)}>Yes <span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div>
                {
                    Newfiels()
                }
            </div>
            <div className="stick pt-2">
                {
                    result1()

                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/12765673/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >Kondrup J, Rasmussen HH, Hamberg O, Stanga Z. Nutritional risk screening (NRS 2002): a new method based on an analysis of controlled clinical trials. Clin Nutr. 2003;22(3):321-36.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default RE1233
