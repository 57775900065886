import { createContext, useState } from 'react'

const AppContext = createContext({
    activeId: null,
    activeListData: null,
    favourite: true,
    login: false,
    user: null,
    comorbitiesValue: null,
    sourceName: null,
    returnPath: null,
    remaining: null,
    givenLaMax: null,
    actionLibraryData:[],

    changeActiveId: () => { },
    changeFavorite: () => { },
    changeLogin: () => { },
    addUserDetails: () => { },
    changeActiveListData: () => { },
    changecomorbities: () => { },
    changesourceName: () => { },
    changereturnPath: () => { },
    setActionLibraryData:() =>{},
})

export const AppContextProvider = (props) => {
    const [activeId, setactiveId] = useState(null)
    const [activeListData, setactiveListData] = useState(null)
    const [favourite, setfavourite] = useState(null)
    const [login, setlogin] = useState(false)
    const [user, setuser] = useState(null)
    const [comorbitiesValue, setcomorbitiesValue] = useState(null)
    const [sourceName, setsourceName] = useState(null)
    const [returnPath, setreturnPath] = useState(null)
    const [remaining, setremaining] = useState(null)
    const [givenLaMax, setgivenLaMax] = useState(null)
    const [actionLibraryData, setActionLibraryData] = useState([]);

    const addActionLibraryData=(data)=>{
        setActionLibraryData(data)
    }

    const changeActiveId = (id) => {
        if (id === null) {
            const prevoiusActiveId = localStorage.getItem('activeId')
            setactiveId(prevoiusActiveId)
        }else{
        localStorage.setItem('activeId', id)
        setactiveId(id)
        }
    }

    const changeFavorite = (value) => {
        setfavourite(value)
    }

    const changeLogin = (value) => {
        setlogin(value)
    }

    const addUserDetails = (value) => {
        setuser(value)
    }

    const changeActiveListData = (value) => {
        setactiveListData(value)
    }

    const changecomorbities = (comorbities, sourceName, returnPath, remaining, givenLaMax) => {
        setcomorbitiesValue(comorbities)
        setsourceName(sourceName)
        setreturnPath(returnPath)
        setremaining(remaining)
        setgivenLaMax(givenLaMax)
    }



    const context = {
        activeId: activeId,
        favourite: favourite,
        login: login,
        user: user,
        activeListData: activeListData,
        comorbitiesValue: comorbitiesValue,
        sourceName: sourceName,
        returnPath: returnPath,
        remaining: remaining,
        givenLaMax: givenLaMax,
        actionLibraryData: actionLibraryData,
        changeActiveId: changeActiveId,
        changeFavorite: changeFavorite,
        changeLogin: changeLogin,
        addUserDetails: addUserDetails,
        changeActiveListData: changeActiveListData,
        changecomorbities: changecomorbities,
        setActionLibraryData: addActionLibraryData

    }

    return <AppContext.Provider value={context}>
        {props.children}
    </AppContext.Provider>
}

export default AppContext