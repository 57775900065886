import React, { useState } from 'react'
import { API_ROOT } from '../constants'
import { Link } from 'react-router-dom'
import { setFileName, setFileName2, setFileName3, setNoteMessage } from '../redux/caseSummaryActions'
import { useDispatch, useSelector } from 'react-redux'

const Note = (props) => {
    const dispatch = useDispatch()
    const noteMsg = useSelector(state => state.casesummary.noteMsg)
    const [message, setmessage] = useState(null)
    const [file, setFile] = useState(null);
    const [image, setImage] = useState({ preview: "", raw: "", name: "" });
    const [imgValid, setImgValid] = useState("")
    // const [formdata, setFormData] = useState('');

    const handleChangeUploadImage = e => {
        if (e.target.files.length) {
            if (e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "jpg") {
                setImage({
                    preview: URL.createObjectURL(e.target.files[0]),
                    raw: e.target.files[0],
                    name: e.target.files[0].name
                });
                // dispatch(setFileName3(e.target.files))
                sessionStorage.setItem("file", e.target.files[0])
                setFile(e.target.files[0])
                var url = URL.createObjectURL(e.target.files[0])
                dispatch(setFileName2({
                    preview: URL.createObjectURL(e.target.files[0]),
                    raw: e.target.files[0],
                    name: e.target.files[0].name
                }))
                setImgValid("")
            }
            else {
                setImgValid("Only files with '.png', 'jpg', 'image/jpeg' are allowed. ")
            }
        }
    };
    let formdata = new FormData();
    const saveExtraNotes = (e) => {
        e.preventDefault()
        if (props.location.state === "Action Library") {
            props.history.push({
                pathname: "/startacase/casesummary",
                state: file
            })
        }
        else {
            const token = sessionStorage.getItem('token')
            formdata.append("image", file)
            formdata.append("message", noteMsg)
            formdata.append("op_type", "testing")
            formdata.append("case_id", "27")
            fetch(API_ROOT + `save-extranotes?token=${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                body: formdata

            })
                .then(response => response.json())
                .then(res => {
                    if (res.status === "true") {
                        alert(res.message)
                    }
                })
        }



    }

    return (
        <div>
           
            <div className="backNavigation text-center">
                <Link className="arrow" to="/actionLibrary"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Action Library</span></span></Link>CASE NOTES
            </div>

            <div className="col-md-12 pl-0 pr-2">

                <div className="conArea d-block">
                    

                    <div className="row justify-content-center ">
                        <div className="col-md-9 savedCases">
                            <div className="whtbxs">
                                <form>
                                    <div className="form-group">
                                        <label><h6>Notes</h6></label>
                                        <textarea onChange={(e) => dispatch(setNoteMessage(e.target.value))} className="form-control" placeholder="Type here"
                                        ></textarea>

                                    </div>

                                    <div className="form-group uploadsB">
                                        <label><h6>Upload a Image</h6></label>
                                        <span className="uploads">
                                            {image.preview ? (
                                                <div><img src={image.preview} alt="upload Image" /><span>{image.name}</span></div>
                                            ) : (
                                                <>
                                                    <i className="fa fa-file-image-o upload-icon" style={{color:'lightgray'}}></i>
                                                </>
                                            )}
                                            <input type="file" id="myFile" name="filename" onChange={handleChangeUploadImage} />

                                        </span>
                                        <br></br>
                                        {imgValid !== "" ? <p style={{ color: "red" }}>{imgValid}</p> : ""}
                                        <br></br>
                                    </div>
                                    <div className="form-group mt-4 text-center mb-4">
                                        <button onClick={(e) => saveExtraNotes(e)} type="submit" className="save-button-container" style={{ margin: '0 auto' }}>Submit</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default Note