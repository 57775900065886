import React, { useEffect, useState, useContext } from 'react'
import '../print/Print.css'
import axios from 'axios'
import { API_ROOT } from '../../../constants'
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import AppContext from '../../../contextStore/DataProvider';
import { EmailIcon, EmailShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
const SharePopup = ({ close }) => {
    const context = useContext(AppContext)
    return <div className='share-container'>
        <div className='share-card'>
            <button className='close' onClick={close}>x</button>
            <div className='email-button'>
                <EmailShareButton url={`${context.user.first_name} shared case logs with you https://www.anesthesiaone.com/login`}>
                    <EmailIcon size={30} />
                </EmailShareButton>
            </div>
            <div className='whatsapp-button'>
                <WhatsappShareButton url={`${context.user.first_name} shared case logs with you`}>
                    <WhatsappIcon size={30} />
                </WhatsappShareButton>
            </div>
        </div>
    </div>
}

const Export = (props) => {

  const [caseInfo, setcaseInfo] = useState(null)
  const [otherInfo, setotherInfo] = useState(null)
  const [shareClicked, setshareClicked] = useState(false);
  const close = () => {
    setshareClicked(false)
}
  useEffect(() => {
    getCaseSummaryDataAsPerId(props.match.params.id);
    
  }, [])

  const getCaseSummaryDataAsPerId = async (caseId) => {
    try {
      const token = sessionStorage.getItem("token")
      const res = await axios.get(API_ROOT + `case-summary-by-id/${caseId}?token=${token}`)
      setcaseInfo(res.data.data)
      setotherInfo(res.data);
      await createPDF();
      setshareClicked(true);

    } catch (error) {
      console.log(error)
    }
  }
  const createPDF = async () => { 
    const pdf = new jsPDF("portrait", "pt", "a4"); 
    const data = await html2canvas(document.querySelector("#pdf"));
    const img = data.toDataURL("image/png");  
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("case.pdf");
  };
  const formatDate = (date) => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }


  return (
  <>

    {shareClicked ? <SharePopup close={close}/> : null}
    <div style={{ display: "flex", justifyContent: 'center' }} id="pdf">
      <div style={{ width: 1140 }}>
        <div className='print-container' id="print">
          <div className='row1'>
            {(caseInfo?.full_name!=null) &&
              <div>Anesthesia One user : {caseInfo?.full_name}</div>
            }
            </div>
            <div className='row1 pt-1'>
            {(caseInfo?.age!=null ||caseInfo?.age_type!=null) &&
              <div>Age : <span>{caseInfo?.age} {caseInfo?.age ? caseInfo?.age_type: ""}</span></div>
            }
            {(caseInfo?.id!=null) &&
        <div>Case No : <span>{caseInfo?.id}</span></div>
           
            }
            {(caseInfo?.gender!=null)&&
              <div>Gender : <span>{caseInfo?.gender}</span></div>
            
            }
            {(caseInfo?.patient_type_name!=null)&&
 <div>Patient Type : <span>{caseInfo?.patient_type_name}</span></div>
           
            }
            {(caseInfo?.weight!=null||caseInfo?.weight_type!=null)&&
  <div>Weight : <span>{caseInfo?.weight} {caseInfo?.weight ? caseInfo?.weight_type: ""}</span></div>
         
            }
            {(caseInfo?.height!=null||caseInfo?.height_type!=null)&&
 <div>Height : <span>{caseInfo?.height} {caseInfo?.height ? caseInfo?.height_type: ""}</span></div>
            
            }
            {(caseInfo?.status!=null)&&
   <div>Status : <span>{caseInfo?.status}</span></div>
         
            }
            {formatDate(caseInfo?.created_at)!=null &&
   <div>Register on : <span>{formatDate(caseInfo?.created_at)}</span></div>
         
            }
          </div>

          <div className='row2'>
            {otherInfo?.asa_ps?.length?
              <div className='title'>ASA-PS :
              <div className='subtitle'>
                {
                  otherInfo?.asa_ps?.map(data => {
                    return <span key={data.id}>{data.name}</span>
                  })
                }
              </div>
            </div>:<></>
            }
            
            {otherInfo?.surgeryTypes?.length?
              <div className='title'>Surgery Type :
              <div className='subtitle'>
                {
                  otherInfo?.surgeryTypes?.map(data => {
                    return <span key={data.id}>{data.name}</span>
                  })
                }
              </div>
            </div>:<></>

            }
            
            {otherInfo?.anesthesiaTypes?.length?
              <div className='title'>Anesthesia Type :
              <div className='subtitle'>
                {
                  otherInfo?.anesthesiaTypes?.map(data => {
                    return <span key={data.id}>{data.name}</span>
                  })
                }
              </div>
            </div>:
            <></>
            }
            {caseInfo?.npo_status_materials!=null&&
              <div className='title'>NPO Status :
              <div className='subtitle'>
                {
                  <span>{caseInfo?.npo_status_materials}</span>
                }
              </div>
            </div>
            }
            
            {otherInfo?.medications?.length?
              <div className='title'>Medications :
              <div className='subtitle'>
                {
                  otherInfo?.medications?.map(data => {
                    return <span key={data.id}>{data.name}</span>
                  })
                }
              </div>
            </div>:<></>
            }
            
            {otherInfo?.allergies?.length?
              <div className='title'>Allergies :
              <div className='subtitle'>
                {
                  otherInfo?.allergies?.map(data => {
                    return <span key={data.id}>{data.name}</span>
                  })
                }
              </div>
            </div>:<></>
            }
            
            {otherInfo?.midical_histories?.length?
              <div className='title'>Medical History :
              <div className='subtitle'>
                {
                  otherInfo?.midical_histories?.map(data => {
                    return <span key={data.id}>{data.name}</span>
                  })
                }
              </div>
            </div>:<></>
            }
            
            {otherInfo?.surgeryHistories?.length?
              <div className='title'>Surgical History :
              <div className='subtitle'>
                {
                  otherInfo?.surgeryHistories?.map(data => {
                    return <span key={data.id}>{data.name}</span>
                  })
                }
              </div>
            </div>:<></>
            }
            
            {otherInfo?.anesthesia_histories?.length?
              <div className='title'>Anesthesia History :
              <div className='subtitle'>
                {
                  otherInfo?.anesthesia_histories?.map(data => {
                    return <span key={data.id}>{data.name}</span>
                  })
                }
              </div>
            </div>:<></>
            }
            
            {caseInfo?.case_note_message!=null&&
              <div className='title'>Case Note :
              <div className='subtitle'>
                <span>{caseInfo?.case_note_message}</span>
              </div>
            </div>
            }
            
            {caseInfo?.case_note_image&&
              <div className='title'>Case Image :
              <div className='subtitle'>
                <img src={caseInfo?.case_note_image} alt="" />
              </div>
            </div>
            }
            
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Export