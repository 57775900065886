import React, { useState, useEffect, useContext } from 'react'
import google from '../images/gplus.png'
import { Link } from 'react-router-dom'
import logo from '../images/logo.png'
import Facebook from '../images/facebook.png'
import { API_ROOT, GOOGLE_CLIENT_ID } from '../constants'
import '../css/login.css'
import http from '../http-common';

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import AppContext from '../contextStore/DataProvider'
import Spinner from '../CustomComponent/Spinner'

function Login(props) {

    const context = useContext(AppContext)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    sessionStorage.removeItem('token');

    const onLoginSuccess = (credentialResponse) => {
        const decodedSocialUserInfo = jwt_decode(credentialResponse.credential);

        const { sub, email, email_verified, name, picture } = decodedSocialUserInfo;
        const userInfo = { "social_id": sub, "email": email, "email_verified": email_verified, "name": name, "picture": picture, "social_type": "Google", "device_token": "123456789", "device_type": "Android" };

        http.post(`login-with-socialites`, userInfo)
            .then(res => {
                if (res.data.status === "true" && (res.data.message === "Login successfully." || res.data.message === "User register successfully.")) {
                    sessionStorage.setItem('token', res.data.token);
                    sessionStorage.setItem('id', res.data.data.id);
                    props.history.push('/home')


                } else if (res.data.status === "false" && res.data.message === "User not found.") {
                    alert("User not found.")
                } else if (res.data.status === "false" && res.data.message === "Password Incorrect.") {
                    alert("Incorrect Password.")
                }
            })
            .catch(error => {
                console.log(error);
            })

    };

    const onLoginFailure = (failureErrorResponse) => {
        console.log('Login Failed:', failureErrorResponse);
    };


    const loginUser = () => {
        setLoading(true)

        if (email === "") {
            alert("Please enter email id")
            setLoading(false)

        } else if (password === "") {
            alert("Please enter password")
            setLoading(false)

        } else {

            fetch(API_ROOT + `login?email=${email}&password=${password}&device_token=123&device_type=Android`, {
                method: 'POST',

                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }

            })
                .then(response => response.json())
                .then(res => {
                    if (res.status === "true" && res.message === "Login Successfully") {
                        sessionStorage.setItem('token', res.token);
                        sessionStorage.setItem('id', res.data.id);
                        let name = res.data.first_name + " " + res.data.last_name
                        props.history.push('/home')
                        context.changeLogin(true)
                        setLoading(false)


                    } else if (res.status === "false" && res.message === "User not found.") {
                        alert("User not found.");
                        setLoading(false)

                    } else if (res.status === "false" && res.message === "Password Incorrect.") {
                        alert("Incorrect Password.")
                        setLoading(false)

                    }
                })
        }
    }
    const enterKeyPressed = (event) => {
        if (event.keyCode === 13) {
            loginUser()
            return true;
        } else {
            return false;
        }
    }

    const registerFacebookUser = async (id, email, name, picture) => {
        const userInfo = { "social_id": id, "email": email, "email_verified": true, "name": name, "picture": picture, "social_type": "Facebook", "device_token": "123456789", "device_type": "Android" };
        http.post(`login-with-socialites`, userInfo)
            .then(res => {
                if (res.data.status === "true" && (res.data.message === "Login successfully." || res.data.message === "User register successfully.")) {
                    sessionStorage.setItem('token', res.data.token);
                    sessionStorage.setItem('id', res.data.data.id);
                    props.history.push('/home')


                } else if (res.data.status === "false" && res.data.message === "User not found.") {
                    alert("User not found.")
                } else if (res.data.status === "false" && res.data.message === "Password Incorrect.") {
                    alert("Incorrect Password.")
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    const facebookLogin = () => {
        try {
            window.FB.login((response) => {
                window.FB.api(`/${response.authResponse.userID}`, { fields: ["email", "name", "picture"] }, (response) => {
                    if (response && !response.error) {
                        let { id, email, name, picture } = response
                        let { url } = picture.data
                        registerFacebookUser(id, email, name, url)
                    }
                })
            }, { scope: ['email', 'public_profile'] })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div><style>{"\.header {\ display:none; \ } \ "}</style>
            <header>
                <div className="container">
                    <div className="row pt-3">

                        <div className="col-md-12 header-right">
                            <h1 className="logo">
                                <Link to="/" >
                                    <img src={logo} />
                                </Link>
                            </h1>
                            {/* <div className="usersDetail"><i className="fii icon-user" aria-hidden="true"></i>
                            </div> */}
                        </div>
                    </div>
                </div>
            </header>
            <div className="subsBG">
                <div className="container">
                    <div className="row justify-content-center ">
                        <div className="col-md-5 whtbx login">
                            {/* <h3>Welcome Back!</h3> */}
                            <h6 className="text-center">Sign into Anesthesia One</h6>
                            <div className="form-group mt-5">
                                {/* <label for="exampleInputEmail1">Email</label> */}
                                <i className="fa fa-envelope login-icon"></i>
                                <input onChange={(e) => setEmail(e.target.value)} id="email" type="email" className="form-control" aria-describedby="emailHelp" placeholder="Enter Email Id" onKeyDown={(e) => enterKeyPressed(e)} />
                            </div>
                            <div className="form-group">
                                {/* <label for="exampleInputPassword1">Password</label> */}
                                <i className="fa fa-lock login-icon"></i>
                                <input onChange={(e) => setPassword(e.target.value)} id="password" type="password" className="form-control" placeholder="Enter Password" onKeyDown={(e) => enterKeyPressed(e)} />
                            </div>
                            <div>  <Link to="/forgotPassword" className="forgot">Forgot Password?</Link></div>
                            <button onClick={() => loginUser()} className="btn " >Login</button>
                            {/* <p className="ORs pt-3">OR</p> */}
                            <div className="socials text-center ml-2">
                                <div className="row">
                                    {/* <div className="col-md-6">
                                        <a href="#" className="fb btn w-100">
                                            <i className="fab fa-facebook-square" ></i>
                                            <span className="social-text">Login with Facebook</span>
                                        </a>
                                    </div> */}
                                    <div className="col-xs-12 col-md-5">
                                        <div className="socials text-center gl" >
                                            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                                                <GoogleLogin
                                                    onSuccess={onLoginSuccess}
                                                    onError={onLoginFailure}
                                                />
                                            </GoogleOAuthProvider>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-md-7">
                                        <div className="socials text-center ml-3 fl">
                                            <button style={{ border: '1px solid #dadce0', backgroundColor: '#fff', height: 40, marginTop:-2, width: 'auto', borderRadius: '4px' }} onClick={() => facebookLogin()}>
                                                <img style={{ height: 18, width: 18 }} src={Facebook} alt="" />
                                                <span style={{ fontSize: 14, marginLeft: 15, fontWeight: 500, color: '#3c4043' }}>Sign in with Facebook</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {loading === true ? <div style={{ top: '15%', position: 'absolute' }}><Spinner /></div> : <></>}
                    </div>

                    <p className="stexts text-center">Don't have an account? <Link to="/signup" >Create an Account</Link></p>
                </div>

            </div>
            <footer>
                <p>Calculations must be re-checked and should not be used alone to guide patient care, nor should they substitute for clinical judgment. See our full disclaimer.</p>
                <span>Anesthesia One © 2023 · All Rights Reserved · <Link to="/termsandconditions">Terms of Use </Link>· <Link to="/privacypolicy">Privacy Policy</Link></span>
            </footer>
            <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">

                        <div className="modal-body">
                            <h6>are you sure you want to clear data for this field. </h6>
                            <div className="btnBox">
                                <button className="savebtns" data-dismiss="modal">yes</button>
                                <button data-dismiss="modal">NO</button>
                            </div>
                            <span className="infos">click here to prevent this
                                notification from appearing again</span>
                        </div>

                    </div>
                </div>
            </div>


            <div className="modal fade" id="genderModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">

                        <div className="modal-body">
                            <h6>Are you sure you want to clear data for this field. </h6>
                            <div className="btnBox">
                                <button className="gsavebtns" data-dismiss="modal">yes</button>
                                <button data-dismiss="modal">NO</button>
                            </div>
                            <span className="infos">Click here to prevent this
                                notification from appearing again</span>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade" id="WeightModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">

                        <div className="modal-body">
                            <h6>Are you sure you want to clear data for this field. </h6>
                            <div className="btnBox">
                                <button className="wsavebtns" data-dismiss="modal">yes</button>
                                <button data-dismiss="modal">NO</button>
                            </div>
                            <span className="infos">Click here to prevent this
                                notification from appearing again</span>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade" id="surgeryModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">

                        <div className="modal-body">
                            <h6>Are you sure you want to clear data for this field. </h6>
                            <div className="btnBox">
                                <button className="sursavebtns" data-dismiss="modal">yes</button>
                                <button data-dismiss="modal">NO</button>
                            </div>
                            <span className="infos">Click here to prevent this
                                notification from appearing again</span>
                        </div>

                    </div>
                </div>
            </div>


            <div className="modal fade" id="generalModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">general/sURGERY TYPE</h5>
                            <div className="actnss">
                                <i className="fii icon-floppy-disk sursavebtns" data-dismiss="modal"></i>
                                <i data-dismiss="modal" className="fii icon-close"></i>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="row mb-2  ml-0 mr-0">
                                <div className="col-4"><span>APPENDECTOMY - UNCOMPLICATED</span></div>
                                <div className="col-4"><span>MALL INTESTINE – HERNIA REPAIR</span></div>
                                <div className="col-4"><span>SMALL INTESTINE – NON OBSTRUCTED	</span></div>

                            </div>

                            <div className="row mb-2 ml-0 mr-0">
                                <div className="col-4"><span>SMALL INTESTINE - OBSTRUCTED </span></div>
                                <div className="col-4"><span>COLORECTAL</span></div>
                                <div className="col-4"><span>GASTRODUO
                                    DENAL</span></div>

                            </div>

                            <div className="row mb-2 ml-0 mr-0">
                                <div className="col-4"><span>W/IMPLANTED
                                    FOREIGN
                                    MATERIAL </span></div>
                                <div className="col-4"><span>NOT LISTED</span></div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>





            <div className="modal fade" id="AllModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">

                        <div className="modal-body">
                            <h6>PLEASE SELECT HOW YOU WOULD LIKE TO SAVE THIS CASE:</h6>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-4 br-right">
                                        <a href="#" data-dismiss="modal">SAVE W/O STARTING CASE</a>
                                        <a href="#" data-dismiss="modal">SAVE TO YOUR PROFILE</a>
                                    </div>
                                    <div className="col-4">
                                        <a href="#" data-dismiss="modal">SAVE AS CASE 1</a>
                                        <a href="#" data-dismiss="modal">SAVE AS CASE 3</a>
                                    </div>
                                    <div className="col-4">
                                        <a href="#" data-dismiss="modal">SAVE AS CASE 2</a>
                                        <a href="#" data-dismiss="modal">SAVE AS CASE 4</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="modal fade" id="summaryModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">

                        <div className="modal-body">
                            <h6>PLEASE SELECT HOW YOU WOULD LIKE TO SAVE THIS CASE:</h6>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-4 br-right">
                                        <a href="#" data-dismiss="modal" data-toggle="modal" data-target="#summaryModal-2">SAVE W/O STARTING CASE</a>
                                        <a href="#" data-dismiss="modal">SAVE TO YOUR PROFILE</a>
                                    </div>
                                    <div className="col-4">
                                        <a href="#" data-dismiss="modal">SAVE AS CASE 1</a>
                                        <a href="#" data-dismiss="modal">SAVE AS CASE 3</a>
                                    </div>
                                    <div className="col-4">
                                        <a href="#" data-dismiss="modal">SAVE AS CASE 2</a>
                                        <a href="#" data-dismiss="modal">SAVE AS CASE 4</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="modal fade" id="summaryModal-2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">




                        <div className="modal-body">
                            <h6>CASE 1</h6>
                            <span>SAVE AS:</span>
                            <span className="blocks">18YO_F_COLORECTAL_12-19-2020</span>
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal fade" id="StartCaseModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">

                        <div className="modal-body">
                            <h6>PLEASE SELECT HOW YOU WOULD LIKE TO SAVE THIS CASE:</h6>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-4 br-right">
                                        <a href="#" data-dismiss="modal" className="saveLater">Save all started cases</a>
                                        <a href="#" data-dismiss="modal" className="saveLater">SAVE Later</a>
                                        <a href="#" data-dismiss="modal" className="saveLater">SAVE No Cases</a>
                                    </div>
                                    <div className="col-4">
                                        <span>&nbsp;</span>
                                        <a href="#" data-dismiss="modal" className="saveLater">SAVE AS CASE 1</a>
                                        <a href="#" data-dismiss="modal" className="saveLater">CASE 3 Not Started</a>
                                    </div>
                                    <div className="col-4">
                                        <span>&nbsp;</span>
                                        <a href="#" data-dismiss="modal" className="saveLater">CASE 2 Not Started</a>
                                        <a href="#" data-dismiss="modal" className="saveLater">CASE 4 Not Started</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    )
}

export default Login
