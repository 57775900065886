import React, { useEffect, useState } from 'react';
import './RE1198.css';

const RE1198 = () => {
    const [green, setGreen] = useState(false);
    const [age, setAge] = useState(0);
    const [upperlimb, setUpperlimb] = useState(0);
    const [mvantilation, setMvantilation] = useState(0);
    const [result, setResult] = useState(0);
    const [result1, setResult1] = useState(0);
    const [result2, setResult2] = useState(0);

    const [myocardial, setMyocardial] = useState(0);
    const [chf, setChf] = useState(0);
    const [peripheralVascular, setPeripheralVascular] = useState(0);
    const [tia, setTia] = useState(0);
    const [dementia, setDementia] = useState(0);
    const [copd, setCopd] = useState(0);
    const [connectiveTissue, setConnectiveTissue] = useState(0);
    const [peptic, setPeptic] = useState(0);
    const [liverDiseases, setLiverDiseases] = useState(0);
    const [diabetes, setDiabetes] = useState(0);
    const [hemiplegia, setHemiplegia] = useState(0);
    const [ckd, setCkd] = useState(0);
    const [solidTumor, setSolidTumor] = useState(0);
    const [leukemia, setLeukemia] = useState(0);
    const [lymphoma, setLymphoma] = useState(0);
    const [aids, setAids] = useState(0);
   

    const postData = async () => {
        setResult(age+ myocardial+ chf+peripheralVascular+tia+dementia+copd+connectiveTissue+peptic+liverDiseases+diabetes+hemiplegia+ckd+solidTumor+leukemia+lymphoma+aids);
        setResult2((Math.pow(2.718, (result * 0.9))))
        setResult1(((Math.pow(0.983 ,result2 ))*100).toFixed(0));
        setGreen(true);
    }
    useEffect(() => {
        postData();
    }, [age, myocardial, chf,peripheralVascular,tia,dementia,copd,connectiveTissue,peptic,liverDiseases,diabetes,hemiplegia,ckd,solidTumor,leukemia,lymphoma,aids,result,result2,result1]);

    return (
        <div className="rev">
             <h5 className="text-info pl-2">Charlson Comorbidity Index (CCI)</h5>
            <p className="pl-2">Predicts 10-year survival in patients with multiple comorbidities. </p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Age</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{height:40 }} onClick={() => setAge(0)} className={age === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">&lt;50 years</span><span className="calc-val ">0</span></button>
                    <button style={{height:40 }} onClick={() => setAge(1)} className={age === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">50–59 years</span><span className="calc-val ">+1</span></button>
                    <button style={{height:40 }} onClick={() => setAge(2)} className={age === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">60–69 years</span><span className="calc-val ">+2</span></button>
                    <button style={{height:40 }} onClick={() => setAge(3)} className={age === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">70–79 years</span><span className="calc-val ">+3</span></button>
                    <button style={{height:40 }} onClick={() => setAge(4)} className={age === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">≥80 years</span><span className="calc-val ">+4</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Myocardial infarction</strong></div>
                    <p>History of definite or probable MI (EKG changes and/or enzyme changes)</p>
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button className={myocardial === 0 ? "btn active" : "btn"} style={{height:40 }} onClick={() => setMyocardial(0)}>No 0</button>
                    <button  className={myocardial === 1 ? "btn active" : "btn"} style={{height:40 }} onClick={() => setMyocardial(1)}>Yes +1 </button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>CHF</strong></div>
                    <p>Exertional or paroxysmal nocturnal dyspnea and has responded to digitalis, diuretics, or afterload reducing agents</p>
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button className={chf === 0 ? "btn active" : "btn"} style={{height:40 }} onClick={() => setChf(0)}>No 0</button>
                    <button  className={chf === 1? "btn active" : "btn"} style={{height:40 }} onClick={() => setChf(1)}>Yes +1 </button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Peripheral vascular disease</strong></div>
                    <p>Intermittent claudication or past bypass for chronic arterial insufficiency, history of gangrene or acute arterial insufficiency, or untreated thoracic or abdominal aneurysm (≥6 cm)</p>
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button className={peripheralVascular === 0 ? "btn active" : "btn"} style={{height:40 }} onClick={() => setPeripheralVascular(0)}>No 0</button>
                    <button  className={peripheralVascular === 1 ? "btn active" : "btn"} style={{height:40 }} onClick={() => setPeripheralVascular(1)}>Yes +1 </button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>CVA or TIA</strong></div>
                    <p>History of a cerebrovascular accident with minor or no residua and transient ischemic attacks</p>
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button className={tia === 0 ? "btn active" : "btn"} style={{height:40 }} onClick={() => setTia(0)}>No 0</button>
                    <button  className={tia === 1 ? "btn active" : "btn"} style={{height:40 }} onClick={() => setTia(1)}>Yes +1 </button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Dementia</strong></div>
                    <p>Chronic cognitive deficit</p>
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button className={dementia === 0 ? "btn active" : "btn"} style={{height:40 }} onClick={() => setDementia(0)}>No 0</button>
                    <button  className={dementia === 1 ? "btn active" : "btn"} style={{height:40 }} onClick={() => setDementia(1)}>Yes +1 </button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>COPD</strong></div>
                    
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button style={{height:40 }} className={copd === 0 ? "btn active" : "btn"} onClick={() => setCopd(0)}>No 0</button>
                    <button  style={{height:40 }} className={copd === 1 ? "btn active" : "btn"} onClick={() => setCopd(1)}>Yes +1 </button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Connective tissue disease</strong></div>
                    
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button style={{height:40 }} className={connectiveTissue === 0 ? "btn active" : "btn"} onClick={() => setConnectiveTissue(0)}>No 0</button>
                    <button  style={{height:40 }} className={connectiveTissue === 1 ? "btn active" : "btn"} onClick={() => setConnectiveTissue(1)}>Yes +1 </button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Peptic ulcer disease</strong></div>
                    <p>Any history of treatment for ulcer disease or history of ulcer bleeding</p>
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button style={{height:40 }} className={peptic === 0 ? "btn active" : "btn"} onClick={() => setPeptic(0)}>No 0</button>
                    <button  style={{height:40 }} className={peptic === 1 ? "btn active" : "btn"} onClick={() => setPeptic(1)}>Yes +1 </button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Liver disease</strong></div>
                    <p>Severe = cirrhosis and portal hypertension with variceal bleeding history, moderate = cirrhosis and portal hypertension but no variceal bleeding history, mild = chronic hepatitis (or cirrhosis without portal hypertension)</p>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn text-left">
                    <button style={{height:40 }} className={liverDiseases === 0 ? "btn active" : "btn"} onClick={() => setLiverDiseases(0)}>None<span className="calc-val ">0</span></button>
                    <button  style={{height:40 }} className={liverDiseases === 1 ? "btn active" : "btn"} onClick={() => setLiverDiseases(1)}>Mild<span className="calc-val ">+1</span> </button>
                    <button  style={{height:40 }} className={liverDiseases === 3 ? "btn active" : "btn"} onClick={() => setLiverDiseases(3)}>Moderate to severe<span className="calc-val ">+3</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Diabetes mellitus</strong></div>
                   
                </div>
                <div className="col-md-7 btn-group-vertical options-btn text-left">
                    <button style={{height:40 }} className={diabetes === 0 ? "btn active" : "btn"} onClick={() => setDiabetes(0)}>None or diet-controlled<span className="calc-val ">0</span></button>
                    <button  style={{height:40 }} className={diabetes === 1 ? "btn active" : "btn"} onClick={() => setDiabetes(1)}>Uncomplicated<span className="calc-val ">+1</span> </button>
                    <button  style={{height:40 }} className={diabetes === 2 ? "btn active" : "btn"} onClick={() => setDiabetes(3)}>End-organ damage<span className="calc-val ">+2</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Hemiplegia</strong></div>
                  
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button style={{height:40 }} className={hemiplegia === 0 ? "btn active" : "btn"} onClick={() => setHemiplegia(0)}>No 0</button>
                    <button  style={{height:40 }} className={hemiplegia === 2 ? "btn active" : "btn"} onClick={() => setHemiplegia(2)}>Yes +2 </button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Moderate to severe CKD</strong></div>
                    <p>Severe = on dialysis, status post kidney transplant, uremia, moderate = creatinine &gt;3 mg/dL (0.27 mmol/L)</p>
                  
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button style={{height:40 }} className={ckd === 0 ? "btn active" : "btn"} onClick={() => setCkd(0)}>No 0</button>
                    <button style={{height:40 }} className={ckd === 2 ? "btn active" : "btn"} onClick={() => setCkd(2)}>Yes +2 </button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Solid tumor</strong></div>
                   
                </div>
                <div className="col-md-7 btn-group-vertical options-btn  text-left">
                    <button style={{height:40 }} className={solidTumor === 0 ? "btn active" : "btn"} onClick={() => setSolidTumor(0)}>None<span className="calc-val ">0</span></button>
                    <button style={{height:40 }}  className={solidTumor === 2 ? "btn active" : "btn"} onClick={() => setSolidTumor(2)}>Localized<span className="calc-val ">+2</span> </button>
                    <button  style={{height:40 }} className={solidTumor === 6 ? "btn active" : "btn"} onClick={() => setSolidTumor(6)}>Metastatic<span className="calc-val ">+6</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Leukemia</strong></div>
           
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button style={{height:40 }}className={leukemia === 0 ? "btn active" : "btn"} onClick={() => setLeukemia(0)}>No 0</button>
                    <button  style={{height:40 }}className={leukemia === 2 ? "btn active" : "btn"} onClick={() => setLeukemia(2)}>Yes +2 </button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Lymphoma</strong></div>
           
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button style={{height:40 }} className={lymphoma === 0 ? "btn active" : "btn"} onClick={() => setLymphoma(0)}>No 0</button>
                    <button style={{height:40 }} className={lymphoma === 2 ? "btn active" : "btn"} onClick={() => setLymphoma(2)}>Yes +2 </button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>AIDS</strong></div>
           
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button style={{height:40 }} className={aids === 0 ? "btn active" : "btn"} onClick={() => setAids(0)}>No 0</button>
                    <button  style={{height:40 }} className={aids === 6 ? "btn active" : "btn"} onClick={() => setAids(6)}>Yes +6 </button>

                </div>
            </div>
 
          


            <div className="stick pt-4">
                {
                    green ? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container" >
                                <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                                <div>Charlson Comorbidity Index</div>

                              
                            


                           
                            </div>
                            <div className="point-result-container" >
                                <h1>{result1} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>%</span></h1>
                                <div>Estimated 10-year survival</div>

                              
                            


                           
                            </div>






                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/3558716/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Charlson ME, Pompei P, Ales KL, MacKenzie CR: A New Method of Classifying Prognostic Comorbidity in Longitudinal Studies: Development and Validation. Journal of Chronic Diseases 40:373-383, 1987</p>
                    </div>
                </a>
            </div>
        </div>
    )
}


export default RE1198;

