import React, { useEffect, useState } from 'react';
import './RE1188.css';

const RE1188 = () => {
    const [green, setGreen] = useState(false);

    const [result, setResult] = useState(0);


    const [weight, setWeight] = useState([]);
    const [tunit, setTunit] = useState("kg");
    
    const [number3, setNumber3] = useState([]);
    const [newnumber1, setNewnumber1] = useState(0);
    const [tunit1, setTunit1] = useState('mmol/L');
    const [placehldr1, setPlacehldr1] = useState('Norm:23-28')

    const postData = async () => {
    

        if (tunit === 'kg' ) {
            if(weight < 273){
                setNewnumber1(Math.round((weight)*100)/100); 
            }
            else{
                setNewnumber1(0);   
            }
               
                       
    }
  
    if (tunit === 'lbs' ) {  
        if(weight < 601){
            setNewnumber1(Math.round((weight / 2.2046226218488)*100)/100);
        }                      
        else{
            setNewnumber1(0);
        }

    }

    if(number3 < 101){
        setResult(( 0.4 * newnumber1 * (24 - number3)).toFixed(1));
       
    }
    else{
        setResult(0);
    }
    setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [weight,tunit,tunit1,number3,newnumber1, result]);


    const changeunit = () => {
        if (weight === 0) {
            if (tunit === 'kg') {
                setTunit('lbs');

            }
            if (tunit === 'lbs') {
                setTunit('kg');

            }
        } else if (weight !== 0) {
            if (tunit === 'kg') {

                setTunit('lbs');
                if (weight.length !== 0) {
                    setWeight((weight * 2.2046226218488).toFixed(2));
                }
            }
            if (tunit === 'lbs') {

                setTunit('kg');
                if (weight.length !== 0) {
                    setWeight((weight / 2.2046226218488).toFixed(2));
                }

            }
        }
    }

    const changeunit1 = () => {
        if (number3 === 0) {
            if (tunit1 === 'mmol/L') {
                setTunit1('mEq/L');

            }
            if (tunit1 === 'mEq/L') {
                setTunit1('mmol/L');

            }
        } else if (number3 !== 0) {
            if (tunit1 === 'mmol/L') {
                setPlacehldr1("Norm: 23-28");
                setTunit1('mEq/L');
                if (number3.length !== 0) {
                    setNumber3(Math.round(number3 * 1).toFixed(2))
                }
            }
            if (tunit1 === 'mEq/L') {
                setPlacehldr1("Norm: 23-28");
                setTunit1('mmol/L');
                if (number3.length !== 0) {
                    setNumber3(Math.round(number3 * 1))
                }
            }
        }
    }
    const takeUnit1 = (e) => {
        if (tunit1 === 'mmol/L') {
            setNumber3(e.target.value);
            setPlacehldr1("Norm: 23-28");

        }
        if (tunit1 === 'mEq/L') {
            let ht = e.target.value;
            setNumber3(ht);
            setPlacehldr1("Norm:23-28");
        }
    }
   

    const takeUnit = (e) => {
        if (tunit === 'kg') {
            let htcm = (e.target.value);

            setWeight(htcm);


        }
        if (tunit === 'lbs') {
            let htin = (e.target.value);

            setWeight(htin);

        }

    }


    const validation1 = () =>{

            if (tunit === 'kg') {
                if ((weight < 273) && (weight > 226)) {
                    return <div className="validate">*Very High;double check.</div>
    
                }
             
                if (weight > 272) {
                    return <div className="validate">*Too high; please change to proceed.</div>
    
                }
                else if (isNaN(weight)) {
                    return <div className="validate">*Please Enter valid-no.</div>
    
                }
            }
            if(tunit === 'lbs'){
                if ((weight < 601) && (weight > 500)) {
                    return <div className="validate">*Very High;double check.</div>
    
                }
             if(weight > 600) {
                    return <div className="validate">*Too high; please change to proceed.</div>
    
                }
                else if (isNaN(weight)) {
                    return <div className="validate">*Please Enter valid-no.</div>
    
                }
            }

        
    }
    const validation2 = () =>{
        if ((number3 < 101) && (number3 > 60)) {
            return <div className="validate">*Very High;double check.</div>

        }
    
        if (number3 > 100) {
            return <div className="validate" >Too high; please change to proceed.</div>

        }
        if (isNaN(number3)) {
            return <div className="validate">*Please Enter valid-no.</div>

        }

    }

    return (
        <div className="rev">
           <h5 className="text-info pl-2">Bicarbonate Deficit</h5>
        <p className="pl-2">Calculates the total body bicarbonate deficit.</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Weight</strong></div>
                </div>
                <div className="col-md-7 frm-field">
                    <input
type="number"
                        onChange={takeUnit}

                        className="ant-text-input" style={{style:'100% ',width: "90%" }} value={weight} /><span className="units" onClick={changeunit}>{tunit}&#8651;</span>

                 <div>{validation1()}</div>
                </div>
            </div>
            <div className="fields row">
        <div className="col-md-5 col-sm-12">
            <div className="pt-2"><strong>Bicarbonate</strong></div>
        </div>
        <div className="col-md-7 col-sm-12 frm-field">
            <input type="number"
                onChange={takeUnit1}
                placeholder={placehldr1}


                className="ant-text-input" style={{style:'100% ',width: "90%" }} value={number3} /><span className="units" onClick={changeunit1}>{tunit1}&#8651;</span>
           <div>{validation2()}</div>
        </div>
    </div>

            <div className="stick mt-4">
                {
                    result != 0 && weight !=0 && number3 !=0? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container" >
                                <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>{tunit1}</span></h1>
                                <div>Bicarbonate Deficit</div>

                            </div>

                        </div>

                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <br/>
         
        <div className="p-2 mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Kurtz I. Acid-Base Case Studies. 2nd Ed. Trafford Publishing (2004); 68:150.</p>
                </div>
            </a>
        </div>
        </div>
    )
}


export default RE1188;

