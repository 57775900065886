import React, { useState, useEffect } from 'react'
import '../css/ResulforActionLibrary.css'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { API_ROOT } from '../constants'

const optionsforage = [
    { value: 'Y', label: 'Year' },
    { value: 'M', label: 'Month' },
    { value: 'W', label: 'Week' },
    { value: 'D', label: 'Day' },
]

const optionsForWeight = [
    { value: 'KG', label: 'Kilograms' },
    { value: 'G', label: 'Gram' },

]


const ResultOfAction = (props) => {
    const [header, setHeader] = useState(null)
    const [age, setAge] = useState(18)
    const [weight, setWeight] = useState(70)
    const [height, setHeight] = useState(null)
    const [patientType, setPatientType] = useState(null)
    const [id, setId] = useState(null)
    const [drugId, setDrugId] = useState(null)
    const [parentName, setparentName] = useState(null)
    const [subParentName, setSubParentName] = useState(null)
    const [patientTypeArray, setpatientTypeArray] = useState([])
    const [ageType, setageType] = useState('Y')
    const [weightType, setweightType] = useState('KG')
    const [result, setResult] = useState([])
    const [resData, setResData] = useState([])
    const [resData2, setResData2] = useState([])
    const [drugSubType, setdrugSubType] = useState([])
    const [drugstomap, setDrugstoMap] = useState([])
    const [toggleState, setToggleState] = useState(0);
    const [title, setTitle] = useState(props.location.state.subName);
    const [drId, setDrId] = useState(props.location.state.subId);
    const toggleTab = (index) => {
        setToggleState(index);
    };


    useEffect(() => {
        var urlParams = new URLSearchParams(window.location.search)
        setHeader(urlParams.get('name'))
        setId(urlParams.get('id'))
        setDrugId(urlParams.get('drugId'))
        setToggleState(props.location.state.index);
        // setparentName(urlParams.get('subName'))
        setparentName(props.location.state.parentName)
        setSubParentName(props.location.state.subParent)
        getPatientTypeData()
        getDrugTypeData(drId, title, props.location.state.subParent);
        setDrugstoMap(props.location.state.drugsonFly)
    }, [])

    const getPatientTypeData = () => {
        var token = sessionStorage.getItem("token")

        fetch(API_ROOT + `case-summary-form-data?device_type=Android&device_token=123`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }

        })
            .then(response => response.json())
            .then(res => {
                if (res.status === "true") {
                    res.data.patientTypes.forEach(element => {
                        element.value = element.id;
                        element.label = element.type_name;
                    });

                    setpatientTypeArray(res.data.patientTypes)
                }
            })
    }

    const getDrugTypeData = (drugId, drugname, subParent) => {
        const token = sessionStorage.getItem('token')
        if (subParent === "ANTIBIOTIC CLASS") {
            fetch(API_ROOT + `drug-doses?token=${token}`, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    "dose_id": drugId,
                    "drug_name": drugname,
                    "age": "6570",
                    "weight": "70",

                })

            })

                .then(response => response.json())
                .then(res => {
                    if (res.status === "true") {
                        setResData(res.data)
                    }
                })
        }

        else {
            fetch(API_ROOT + `drug-combined-attributes?token=${token}`, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    "drug_id": props.location.state.subId,
                    "drug_name": props.location.state.subName,
                    "age": "6570",
                    "weight": "70",

                })
            })
                .then(response => response.json())
                .then(res => {
                    if (res.status === "true") {
                        let responseData = res.data.sort((a, b) => (a.drug_brand_name > b.drug_brand_name) ? 1 : ((b.drug_brand_name > a.drug_brand_name) ? -1 : 0))
                        setdrugSubType(responseData)
                    }
                })

        }
    }



    
    const handleChangePatientType = selectedOption => {
        setPatientType(selectedOption.value);
    };



    const getResultfromApi = (drgid, drgname, subParent, drugInfo) => {

        setDrId(drgid ? drgid : props.location.state.subId);
        setTitle(drgname ? drgname : props.location.state.subName);
        setResData([]);
        setdrugSubType([]);
        let agee = 18;
        let weightVal = null;
        if (ageType === 'Y') {
            agee = parseInt(age) * 365;
        }
        if (ageType === 'M') {
            agee = parseInt(age) * 30;
        }
        if (ageType === 'W') {
            agee = parseInt(age) * 7;
        }
        if (ageType === 'D') {
            agee = parseInt(age);
        }

        var token = sessionStorage.getItem("token")
        if (parentName === "Crises") {
            fetch(API_ROOT + `action-library-search?token=${token}`, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    "crises_id": id,
                    "patient_age_days": 18,
                    "patient_type_id": patientType,
                    "patient_weight": weight


                })

            })
                .then(response => response.json())
                .then(res => {
                    if (res.status === "true") {
                    }
                })

        } else if (parentName === "Case Tips") {
            fetch(API_ROOT + `action-library-search?token=${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "caseTips_id": id,
                    "patient_age_days": 18,
                    "patient_type_id": patientType,
                    "patient_weight": weight
                })

            })
                .then(response => response.json())
                .then(res => {
                    if (res.status === "true") {
                    }
                })


        } else if (parentName === "Airway & Equipments") {
            fetch(API_ROOT + `action-library-search?token=${token}`, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    "airwayAndEquipments_id": id,
                    "patient_age_days": 18,
                    "patient_type_id": patientType,
                    "patient_weight": weight


                })

            })
                .then(response => response.json())
                .then(res => {
                    if (res.status === "true") {
                    }
                })


        }
        else if (parentName === "Drugs") {
            if (subParent === "ANTIBIOTIC CLASS") {
                fetch(API_ROOT + `drug-doses?token=${token}`, {
                    method: 'POST',

                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        dose_id: drgid,
                        age: agee,
                        weight: weight === null ? "70" : weight,

                    })

                })

                    .then(response => response.json())
                    .then(res => {
                        if (res.status === "true") {
                            setResData(res.data)
                        }
                    })
            }
            if (subParent === "ANTIBIOTICS BY SURGERY TYPE") {
                props.history.push({
                    pathname: "/drugInfo",
                    state: {
                        name: drgname,
                        drugInformation: drugInfo,
                        drugId: drgid,
                        sourceName: 'Results of Action',
                        returnPath: 'resultOfAction',
                    }

                })
            }

            // else {
            
            //     fetch(API_ROOT + `drug-combined-attributes?token=${token}`, {
            //         method: 'POST',
            //         headers: {
            //             'Content-Type': 'application/json'
            //         },
            //         body: JSON.stringify({
            //             drug_id: props.location.state.subId,
            //             drug_name: props.location.state.subName,
            //             age: age,
            //             weight: weight,
            //             patient_type: patientType,
            //         })

            //     })
            //         .then(response => response.json())
            //         .then(res => {
            
            //             if (res.status === "true") {
            
            //                 setResData2(res.data)
            //             }
            //         })
            // }
            // fetch(API_ROOT + `drug_attributes?token=${token}`, {
            //     method: 'POST',

            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify({
            //         drug_id: props.location.state.subId,
            //         drug_name: props.location.state.subName,
            //         parent_drug_name: props.location.state.parentName,
            //         age: age,
            //         age_type: ageType,
            //         weight: weight,
            //         weight_type: weightType,
            //         patient_type: patientType,
            //     })

            // })
            //     .then(response => response.json())
            //     .then(res => {
            
            //         if (res.status === "true") {
        
            //         }
            //     })
            
            
            fetch(API_ROOT + `drug-combined-attributes?token=${token}`, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    "drug_id": drgid,
                    "drug_name": drgname,
                    "age": agee,
                    "weight": weight,
                })
            })
                .then(response => response.json())
                .then(res => {
                    if (res.status === "true") {
                        let responseData = res.data.sort((a, b) => (a.drug_brand_name > b.drug_brand_name) ? 1 : ((b.drug_brand_name > a.drug_brand_name) ? -1 : 0))

                        setdrugSubType(responseData)
                    }
                })


        } else if (parentName === "Preoperative Evaluations") {
            fetch(API_ROOT + `action-library-search?token=${token}`, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    "preoperativeEvaluations_id": id,
                    "patient_age_days": 18,
                    "patient_type_id": patientType,
                    "patient_weight": weight


                })

            })
                .then(response => response.json())
                .then(res => {
                    if (res.status === "true") {
                    }
                })

        } else if (parentName === "Regional Anesthesia") {
            fetch(API_ROOT + `drug-combined-attributes?token=${token}`, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    "regionalAndNAnesthesia_id": id,
                    "patient_age_days": 18,
                    "patient_type": patientType,
                    "patient_weight": weight
                })

            })
                .then(response => response.json())
                .then(res => {
                    if (res.status === "true") {
                    }
                })

        }
    }

    const handleChangeAgeTpe = selectedOption => {
        if (selectedOption.value === "Y") {
            setAge(age * 365)
        } else if (selectedOption.value === "M") {
            setAge(age * 30)
        } else if (selectedOption.value === "W") {
            setAge(age * 7)
        } else if (selectedOption.value === "D") {
            setAge(age)
        }
        setageType(selectedOption.value);

    };

    const handleChangeWeightType = selectedOption => {
        setweightType(selectedOption.value);
    };


    return (
        <div className="result-action w-100">
            {/* <div className="backNavigation text-center">
                <Link className="arrow"  to={"/" + props.location.state.returnPath}><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to {props.location.state.sourceName}</span></span></Link>{title}
            </div> */}
             <h5 className="text-info p-3">{props.data?.name}</h5>
            <div className=" mt-3 rev">
                <div className="row">
                    <div className="col-md-3 drug-result" style={{ height: '80vh', overflowY: 'scroll' }}>

                        {drugstomap.map((data, key) => {

                            return (
                                <div>
                                    {data.name !== 'ANTIBIOTICS' ?
                                        <button key={key} onClick={() => { toggleTab(key); getResultfromApi(data.id, data.name ? data.name : data.drug_name, data.subParentName, data.drugInfo) }} className={toggleState === key ? "tabs active-tabs" : "tabs"}>{data.name}</button>
                                        : <></>
                                    }
                                </div>
                            )
                        })}

                    </div>
                    <div className="dosing-container col-md-9 col-sm-12 content-tabs" >

                        <div className="result-input-container" >
                            <div className="w-25 text-right">Age</div>
                            <div className="result-input-container-2 w-25" >

                                <div>
                                    <input value={age} onChange={(e) => setAge(e.target.value)} placeholder="Age" className="ant-text-input  w-100" onBlur={(e) => {
                                        if(age.trim().length > 0) {return true} else { return setAge(18)}
                                    }}/>
                                </div>
                            </div>
                            <div className="w-50">
                                <Select defaultValue={{ label: "Year", value: "Y" }} onChange={(e) => { setageType(e.value) }} placeholder="Year" className="patientype-dropdown  w-50" id="age" options={optionsforage} />

                            </div>
                        </div>
                        <div className="result-input-container" >
                            <div className="w-25 text-right">Weight</div>
                            <div className="result-input-container-2 w-25">

                                <div>
                                    <input value={weight === null ? "70" : weight} onChange={(e) => setWeight(e.target.value)} placeholder="Weight" className="ant-text-input w-100" />
                                </div>
                            </div>
                            <div className="w-50">
                                <Select defaultValue={{ label: "Kilograms", value: "KG" }} onChange={(value) => handleChangeWeightType(value)} placeholder="Weight Type" className="patientype-dropdown  w-50" id="weight" options={optionsForWeight} />

                            </div>
                        </div>
                        <div style={{ margin: '0 auto' }}>
                            <button onClick={() => getResultfromApi(drId, title, subParentName)} className="submit-button-container submit-button">Submit</button>
                        </div>
                        <div className="drug-result p-2 w-100">
                        {resData.length > 0 ?
                            <div>
                                <table className="table table-striped table-hover mt-2" style={{ fontSize: '.9rem' }}>
                                    <thead className="bg-header text-left text-white stickheader-table">
                                        <tr>
                                            <th>Antibiotic Name</th>
                                            <th>First Dose</th>
                                            <th>First Dose (Calculated)</th>
                                            <th>First dose max</th>
                                            <th>Second Dose</th>
                                            <th>Third Dose</th>
                                            <th>Subsequent Dose</th>
                                            <th>Admin Guidelines</th>

                                        </tr>
                                    </thead>

                                    <tbody>

                                        {

                                            resData.map((data, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{data.antibiotic_name}</td>
                                                        <td>{data.first_dose === null || data.first_dose === 0 ? '' : data.first_dose} {data.first_dose === null || data.first_dose === 0 ? '' : data.first_dose_unit}</td>
                                                        <td>{data.first_dose_calculated === null || data.first_dose_calculated === 0 ? '' : <> ({data.first_dose_calculated} {data.first_dose_final_unit})</>}</td>
                                                        <td>{data.first_dose_maximum > 0 && data.first_dose_maximum} {data.first_dose_maximum > 0 && data.first_dose_final_unit}</td>
                                                        <td>{data.second_dose}</td>
                                                        <td>{data.third_dose}</td>
                                                        <td>{data.subsequent_dose}</td>
                                                        <td>{data.admin_guidelines}</td>

                                                    </tr>

                                                )
                                            })
                                        }

                                    </tbody>
                                </table>

                            </div> : <></>
                        }
                        </div>
                        <div className="drug-result_mobile mb-5 p-3">
                            {

                                resData.map((data, i) => {
                                    return (
                                        <div className="card w-100 mt-2 p-2" key={i}>
                                            <div className="mb-2"><strong>{data.antibiotic_name}</strong></div>
                                            <div className="d-flex mb-2">
                                                <div className="mr-1">First Dose: </div>
                                                <div>{data.first_dose === null || data.first_dose === 0 ? '' : data.first_dose} {data.first_dose === null || data.first_dose === 0 ? '' : data.first_dose_unit}</div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="mr-1">First Dose (Calculated): </div>
                                                <div> {data.first_dose_calculated === null || data.first_dose_calculated === 0 ? '' : <> ({data.first_dose_calculated} {data.first_dose_final_unit})</>}</div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="mr-1">First dose max: </div>
                                                <div> {data.first_dose_maximum > 0 && data.first_dose_maximum} {data.first_dose_maximum > 0 && data.first_dose_final_unit}</div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="mr-1">Second Dose: </div>
                                                <div> {data.second_dose}</div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="mr-1">Third Dose: </div>
                                                <div> {data.third_dose}</div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="mr-1">Subsequent Dose: </div>
                                                <div> {data.subsequent_dose}</div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="mr-1">Admin Guidelines: </div>
                                                <div> {data.admin_guidelines}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="drug-result p-2 w-100">
                        {
                            drugSubType.length > 0 ?
                                <div>
                                    <table className="table table-striped table-hover mt-2" style={{ fontSize: '.9rem' }}>
                                        <thead className="bg-header text-left text-white stickheader-table">
                                            <tr>
                                                <th>Drug name</th>
                                                <th>Admin Route</th>
                                                <th>Indication</th>
                                                <th>Dose</th>
                                                <th>Calculated dose</th>
                                                <th>Infusion dose</th>
                                                <th>Calculated Infusion dose</th>
                                                <th>Admin notes</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                drugSubType.map((drugData, i) => {
                                                    return (
                                                        // <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',width:'80%',alignSelf:'center'}} >
                                                        <tr key={i}>
                                                            <td>{drugData.drug_brand_name}</td>
                                                            <td>{drugData.administration_route}</td>
                                                            <td>{drugData.indication}</td>
                                                            <td>{drugData.bolus_dose_from} {drugData.bolus_dose_from ? <>-</> : <></>} {drugData.bolus_dose_to} {drugData.bolus_dose_unit}</td>
                                                            <td>{drugData.bolus_dose_min !== 0 ? drugData.bolus_dose_min : ""} {drugData.bolus_dose_min ? <>-</> : <></>} {drugData.bolus_dose_max} {drugData.bolus_dose_final_unit}</td>
                                                            <td>{drugData.infusion_dose_from !== 0 ? drugData.infusion_dose_from : ""} {drugData.infusion_dose_from ? <>-</> : <></>} {drugData.infusion_dose_to} {drugData.infusion_dose_unit}</td>
                                                            <td>{drugData.infusion_dose_min !== 0 ? drugData.infusion_dose_min : ""} {drugData.infusion_dose_min ? <>-</> : <></>}{drugData.infusion_dose_max} {drugData.infusion_dose_final_unit}</td>
                                                            <td>{drugData.administration_notes}</td>
                                                        </tr>
                                                        // </div>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div> : <></>
                        }
                        </div>
                         <div className="drug-result_mobile mb-5 p-3">
                         {
                                                drugSubType.map((drugData, i) => {
                                                    return (
                                                        <div className="card w-100 mt-2 p-2" key={i}>
                                                        <div className="mb-2"><strong>{drugData.drug_brand_name}</strong></div>
                                                        <div className="d-flex mb-2">
                                                            <div className="mr-1">Admin Route: </div>
                                                            <div> {drugData.administration_route}</div>
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="mr-1">Indication: </div>
                                                            <div> {drugData.indication}</div>
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="mr-1">Dose: </div>
                                                            <div> {drugData.bolus_dose_from} {drugData.bolus_dose_from ? <>-</> : <></>} {drugData.bolus_dose_to} {drugData.bolus_dose_unit}</div>
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="mr-1">Calculated dose: </div>
                                                            <div> {drugData.bolus_dose_min !== 0 ? drugData.bolus_dose_min : ""} {drugData.bolus_dose_min ? <>-</> : <></>} {drugData.bolus_dose_max} {drugData.bolus_dose_final_unit}</div>
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="mr-1">Infusion dose: </div>
                                                            <div> {drugData.infusion_dose_from !== 0 ? drugData.infusion_dose_from : ""} {drugData.infusion_dose_from ? <>-</> : <></>} {drugData.infusion_dose_to} {drugData.infusion_dose_unit}</div>
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="mr-1">Calculated Infusion dose: </div>
                                                            <div> {drugData.infusion_dose_min !== 0 ? drugData.infusion_dose_min : ""} {drugData.infusion_dose_min ? <>-</> : <></>}{drugData.infusion_dose_max} {drugData.infusion_dose_final_unit}</div>
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="mr-1">Admin notes: </div>
                                                            <div> {drugData.administration_notes}</div>
                                                        </div>
                                                    </div>
                                                    )
                                                })
                                            }
                         </div>
                    </div>
                </div>



            </div>
        </div >
    )
}

export default ResultOfAction