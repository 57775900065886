import React, { useEffect, useState } from 'react';
import './FAT1165.css'

const FAT1165 = () => {
    const [green, setGreen] = useState(false);
    const [number1, setNumber1] = useState([]);
    const [number2, setNumber2] = useState([]);
    const [number3, setNumber3] = useState([]);
    const [number4, setNumber4] = useState([]);
    const [tunit, setTunit] = useState('g');
    const [placehldr, setPlacehldr] = useState('Norm: 600 - 4000')
    const [newNumber, setNewNumber] = useState(0)
    const [total1, settotal1] = useState(0);


    const postData = async () => {
        if (tunit === 'g') {
            if (number1 < 9072) {

                setNewNumber(number1);
            }
            else {
                setNewNumber(0);
            }
        }
        if (tunit === 'oz') {
            if (number1 < 321) {

                setNewNumber((number1 / 0.035274).toFixed(2))
            }
            else {
                setNewNumber(0);
            }
        }

        if (number1 && number2 > 9 && number2 < 101 && number3 > 19 && number3 < 71 && number4 < 101) {
            if (tunit === 'g') {
                settotal1((((newNumber * 0.14) * (number3 / 100 - number2 / 100)) / (number4 / 100)).toFixed(1));
            }
            if (tunit === 'oz') {
                settotal1((((newNumber * 0.14) * (number3 / 100 - number2 / 100)) / (number4 / 100)).toFixed(1));
            }
        }
        else {
            settotal1(0);

        }
        // settotal1((((number1 * 0.14) * (number3 / 100 - number2 / 100)) / (number4 / 100)).toFixed(1))

        setGreen(true);
    }
    const validation1 = () => {

        if ((tunit === 'g')) {
            if ((number1 < 595) && (number1 != 0)) {
                return <div className="validate">*Very Low;double check.</div>

            }

            if (number1 >= 9072) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(number1)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
        if ((tunit === 'oz')) {
            if ((number1 < 21) && (number1 != 0)) {
                return <div className="validate">*Very Low;double check.</div>

            }

            if (number1 > 320) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(number1)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }


    }
    const validation2 = () => {


        if ((number2 < 10) && (number2 != 0)) {
            return <div className="validate">*Very Low;double check.</div>

        }
        if ((number2 < 101) && (number2 > 42)) {
            return <div className="validate">*Very High;double check.</div>

        }
        if (number2 > 100) {
            return <div className="validate">*Too high; please change to proceed.</div>

        }
        else if (isNaN(number2)) {
            return <div className="validate">*Please Enter valid-no.</div>

        }
    }
    const validation3 = () => {


        if ((number3 < 20) && (number3 != 0)) {
            return <div className="validate">*Very Low;double check.</div>

        }
        if ((number3 < 71) && (number3 > 55)) {
            return <div className="validate">*Very High;double check.</div>

        }
        if (number3 > 70) {
            return <div className="validate">*Too high; please change to proceed.</div>

        }
        else if (isNaN(number3)) {
            return <div className="validate">*Please Enter valid-no.</div>

        }
    }
    const validation4 = () => {


        if ((number4 < 55) && (number4 != 0)) {
            return <div className="validate">*Very Low;double check.</div>

        }
        if ((number4 < 101) && (number4 > 85)) {
            return <div className="validate">*Very High;double check.</div>

        }
        if (number4 > 100) {
            return <div className="validate">*Too high; please change to proceed.</div>

        }
        else if (isNaN(number4)) {
            return <div className="validate">*Please Enter valid-no.</div>

        }
    }

    useEffect(() => {

        postData();

    }, [number1, number2, number3, number4, newNumber])

    const changeunit = () => {
        if (number1 === 0) {
            if (tunit === 'g') {
                setTunit('oz');

            }
            if (tunit === 'oz') {
                setTunit('g');

            }
        } else if (number1 !== 0) {
            if (tunit === 'g') {
                setPlacehldr("Norm: 21 - 160");
                setTunit('oz');
                if (number1.length !== 0) {
                    setNumber1((number1 * 0.035274).toFixed(2));

                }

            }
            if (tunit === 'oz') {
                setPlacehldr("Norm: 600 - 4,000");
                setTunit('g');
                if (number1.length !== 0) {
                    setNumber1((number1 / 0.035274).toFixed(2));
                }


            }
        }
    }
    const takeUnit = (e) => {
        if (tunit === 'g') {
            setNumber1(e.target.value);

        }
        if (tunit === 'oz') {
            let ht = e.target.value;
            setNumber1(ht);
        }
    }


    return (<div className="rev">
        <h5 className="text-info pl-2">Intrauterine RBC Transfusion Dosage</h5>
        <p className="pl-2">Estimates volume of donor RBCs needed for intrauterine transfusion (IUT).</p>

        <div className="fields row">
            <div className="col-md-6">
                <div className="pt-2"><strong>Fetal Weight</strong>
                </div>
            </div>
            <div className="col-md-6 frm-field">
                <input
type="number"
                    // type={number1}
                    // onChange={e => setNumber1(+e.target.value)}
                    onChange={takeUnit}
                    placeholder={placehldr}


                    className="ant-text-input" value={number1} style={{ width: "74%" }} /><span className="units" onClick={changeunit}>{tunit}&#8651;</span>
                <div>{validation1()}</div>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-6">
                <div className="pt-2"><strong>Initial fetal hematocrit</strong></div>
                <div>Estimate via ultrasound
                </div>
                <div>
                </div>
            </div>
            <div className="col-md-6 frm-field">
                <input
                    type={number2}
                    onChange={e => setNumber2(e.target.value)} placeholder="Norm: 20-30" className="ant-text-input text-rbc" /><span className="units cunits">%</span>
                <div>{validation2()}</div>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-6">
                <div className="pt-2"><strong>Goal fetal hematocrit</strong></div>

                <div>
                </div>
            </div>
            <div className="col-md-6 frm-field">
                <input
                    type={number3}
                    onChange={e => setNumber3(e.target.value)}

                    placeholder="Norm: 36-51" className="ant-text-input" style={{ width: '100%' }} /><span className="units dunits" >%</span>
                <div>{validation3()}</div>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-6">
                <div className="pt-2"><strong>Hematocrit of transfused RBCs</strong></div>
                <div>varies by storage solution (range 55-85%);contact blood bank or maternal-fetal medicine expert for specifics
                </div>

                <div>
                </div>
            </div>
            <div className="col-md-6 frm-field">
                <input
                    type={number4}
                    onChange={e => setNumber4(e.target.value)}
                    placeholder="Norm: 55-85" className="ant-text-input eInput" /><span className="units eunits">%</span>
                <div>{validation4()}</div>
            </div>
        </div>

        <br />
        <div className="stick-result">
            {
                (!(isNaN(total1)) && isFinite(total1) && (total1 != 0)) ?

                    <div className="apfel-score-result-container" >
                        <div className="point-result-container" >
                            <h1>{total1} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>ml</span></h1>
                            <div>RBC volume for transfusion</div>
                        </div>

                    </div> : <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>


            }
        </div>
        <div className="p-2 mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/3257068/">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Mandelbrot L, Daffos F, Forestier F, Macaleese J, Descombey D. Assessment of fetal blood volume for computer-assisted management of in utero transfusion. Fetal Ther. 1988;3(1-2):60-6.</p>
                </div>
            </a>
        </div>
    </div>
    )
}

export default FAT1165