import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';
import { Link } from 'react-router-dom';
import ApgarScore from './ApgarScore';
import BishopScore from './BishopScore';
import Bpp from './Bpp';
import IntraauterineRbc from './IntraauterineRbc';
import MeternalFetalHemorrhage from './MeternalFetalHemorrhage';
import Vbac from './Vbac';
import Pregnancy from './Pregnancy';

const ReproductiveCalculators = (props) => {
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };
    useEffect(() => {
        toggleTab(1);
    }, [])

    return (
        <div>
            <div className="backNavigation text-center">
                <Link className="arrow" to="/actionLibrary"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Action Library</span></span></Link> REPRODUCTIVE CALCULATORS
            </div>
            <div className="main-container calculator-container mt-3">
                <div className="row">
                    <div className="col-md-4 airway-calc-tabs">
                    <button className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(1)} >APGAR Score</button>
                        <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(2)} >Bishop Score</button>
                        <button className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(3)} >BPP</button>
                        <button className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(4)} >Intrauterine RBC Transfusion Dosage</button>
                        <button className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(5)} >Maternal-Fetal Hemorrhage Rh(D) Immune Globulin Dosage</button>
                        <button className={toggleState === 6 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(6)} >Pregnancy Gestation by LMP and Ultrasound Biometry</button>
                        <button className={toggleState === 7 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(7)} >VBAC</button>
                       
                    </div>
                    <div className="col-md-8 col-sm-12">
                        <div className="content-tabs">
                            <div
                                className={toggleState === 1 ? "calculator-content  active-content" : "calculator-content"}>
                             <ApgarScore/>
                            </div>
                            <div className={toggleState === 2 ? "calculator-content  active-content" : "calculator-content"}>
                            <BishopScore/>
                            </div>

                            <div
                                className={toggleState === 3 ? "calculator-content  active-content" : "calculator-content"} >
                               <Bpp/>
                            </div>

                            <div
                                className={toggleState === 4 ? "calculator-content  active-content" : "calculator-content"}>
                               <IntraauterineRbc/>
                            </div>
                            <div
                                className={toggleState === 5 ? "calculator-content  active-content" : "calculator-content"}>
                                <MeternalFetalHemorrhage/>
                            </div>
                            <div className={toggleState === 6 ? "calculator-content  active-content" : "calculator-content"}>
                            <Pregnancy/>
                            </div>

                            <div
                                className={toggleState === 7 ? "calculator-content  active-content" : "calculator-content"} >
                               <Vbac/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default ReproductiveCalculators;