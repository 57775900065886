import React, { useEffect, useState } from 'react';
import './CAS1017.css';

const CAS1017 = () => {
    const [green, setGreen] = useState(false);
   
    const [result, setResult] = useState(0);


    const [question1, setQuestion1] = useState(0);
    const [question2, setQuestion2] = useState(0);
    const [question3, setQuestion3] = useState(0);
    const [question4, setQuestion4] = useState(0);
    const [question5, setQuestion5] = useState(0);
    const [question6, setQuestion6] = useState(0);
    const [question7, setQuestion7] = useState(0);
 
    const postData = async () => {
        console.log(question1,question2,question3,question4,question5,question6,question7)
        setResult(question1+question2+question3+question4+question5+question6+question7);
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [question1,question2,question3,question4,question5,question6,question7,result]);

    return (
        <div className="rev">
           <h5 className="text-info pl-2">CHA2DS2 VASc Score For Stroke Risk In Atrial Fibrillation</h5>
            <p className="pl-2">Determines risk of stroke and thromboembolic events in patients with atrial fibrillation. </p>
  
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Patient age</strong></div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn" >
                    <button style={{ height: 40 }} className={question1 === 0 ? "btn active" : "btn"} onClick={() => setQuestion1(0)}>&lt;65 years (0 points)</button>
                    <button style={{ height: 40 }} className={question1 === 1 ? "btn active" : "btn"} onClick={() => setQuestion1(1)}>65 - 74 years (1 point)</button>
                    <button style={{ height: 40 }} className={question1 === 2 ? "btn active" : "btn"} onClick={() => setQuestion1(2)}>≥75 years (2 points)</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Patient gender</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={question2 === 0 ? "btn active" : "btn"} onClick={() => setQuestion2(0)}>Male (0)</button>
                    <button style={{ height: 40 }} className={question2 === 1 ? "btn active" : "btn"} onClick={() => setQuestion2(1)}>Female (+1)</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Congestive heart failure history</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={question3 === 0 ? "btn active" : "btn"} onClick={() => setQuestion3(0)}>No (0)</button>
                    <button style={{ height: 40 }} className={question3 === 1 ? "btn active" : "btn"} onClick={() => setQuestion3(1)}>Yes (+1)</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Hypertension history</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={question4 === 0 ? "btn active" : "btn"} onClick={() => setQuestion4(0)}>No (0)</button>
                    <button style={{ height: 40 }} className={question4 === 1 ? "btn active" : "btn"} onClick={() => setQuestion4(1)}>Yes (+1)</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Diabetes mellitus history</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={question5 === 0 ? "btn active" : "btn"} onClick={() => setQuestion5(0)}>No (0)</button>
                    <button style={{ height: 40 }} className={question5 === 1 ? "btn active" : "btn"} onClick={() => setQuestion5(1)}>Yes (+1)</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Stroke or TIA symptoms previously</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={question6 === 0 ? "btn active" : "btn"} onClick={() => setQuestion6(0)}>No (0)</button>
                    <button style={{ height: 40 }} className={question6 === 2 ? "btn active" : "btn"} onClick={() => setQuestion6(2)}>Yes (+2)</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Vascular disease history</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={question7 === 0 ? "btn active" : "btn"} onClick={() => setQuestion7(0)}>No (0)</button>
                    <button style={{ height: 40 }} className={question7 === 1 ? "btn active" : "btn"} onClick={() => setQuestion7(1)}>Yes (+1)</button>

                </div>
            </div>
            


            <div className="stick mt-5">
                {
                    green ? <>
                        <div className="apfel-score-result-container col-12" >
                            <div className="point-result-container col-12" >
                                <h5>CHA2DS2 VASc Score for Atrial Fibrillation Stroke Risk  = {result}</h5>
                                    <div>{result == 0  ? "Interpretation: This score corresponds to a low risk of thromboembolic event, with an annual stroke risk of 0.20% and a risk of stroke, TIA or systemic embolism of 0.30%." : " "}</div>
                                    <div>{result == 1 ? "This score corresponds to a low - moderate risk of thromboembolic event, with an annual stroke risk of 0.60% and a risk of stroke, TIA or systemic embolism of 0.90%." : " "}</div>
                                    <div>{result == 2 ? "Interpretation: This score corresponds to a moderate - high risk of thromboembolic event, with an annual stroke risk of 2.20% and a risk of stroke, TIA or systemic embolism of 2.90%." : " "}</div>
                                    <div>{result == 3  ? "Interpretation: This score corresponds to a high risk of thromboembolic event, with an annual stroke risk of 3.20% and a risk of stroke, TIA or systemic embolism of 4.60%." : " "}</div>
                                    <div>{result == 4 ? "Interpretation: This score corresponds to a high risk of thromboembolic event, with an annual stroke risk of 4.80% and a risk of stroke, TIA or systemic embolism of 6.70%." : " "}</div>
                                    <div>{result == 5 ? "Interpretation: This score corresponds to a high risk of thromboembolic event, with an annual stroke risk of 7.20% and a risk of stroke, TIA or systemic embolism of 10.00%." : " "}</div>
                                    <div>{result == 6 ? "Interpretation: This score corresponds to a high risk of thromboembolic event, with an annual stroke risk of 9.70% and a risk of stroke, TIA or systemic embolism of 13.60%." : " "}</div>
                                    <div>{result == 7 ? "Interpretation: This score corresponds to a high risk of thromboembolic event, with an annual stroke risk of 11.20% and a risk of stroke, TIA or systemic embolism of 15.70%." : " "}</div>
                                    <div>{result == 8 ? "Interpretation: This score corresponds to a high risk of thromboembolic event, with an annual stroke risk of 10.80% and a risk of stroke, TIA or systemic embolism of 15.20%." : " "}</div>
                                    <div>{result == 9 ? "Interpretation: This score corresponds to a high risk of thromboembolic event, with an annual stroke risk of 12.23% and a risk of stroke, TIA or systemic embolism of 17.40%." : " "}</div>
                                   
                                  


                           
                            </div>






                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>
                }
            </div> 
             <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/19762550/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Lip GY, Nieuwlaat R, Pisters R, Lane DA, Crijns HJ. Refining clinical risk stratification for predicting stroke and thromboembolism in atrial fibrillation using a novel risk factor-based approach: the euro heart survey on atrial fibrillation. Chest. 2010; 137(2):263-72.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}


export default CAS1017

