import React, { useEffect, useState } from 'react';
import './RE1197.css';

const RE1197 = () => {
    const [green, setGreen] = useState(false);

    const [result, setResult] = useState(0);



    const [history1, setHistory1] = useState(false);
    const [history2, setHistory2] = useState(false);
    const [cardiacExam1, setCardiacExam1] = useState(false);
    const [cardiacExam2, setCardiacExam2] = useState(false);
    const [electrocardiogram1, setElectrocardiogram1] = useState(false);
    const [electrocardiogram2, setElectrocardiogram2] = useState(false);
    const [gmc1, setGmc1] = useState(false);
    const [operation1, setOperation1] = useState(false);
    const [operation2, setOperation2] = useState(false);


    const postData = async () => {
        setResult(history1 + history2 + cardiacExam1 + cardiacExam2 + electrocardiogram1 + electrocardiogram2 + gmc1 + operation1 + operation2);
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [history1, history2, cardiacExam1, cardiacExam2, electrocardiogram1, electrocardiogram2, gmc1, operation1, operation2, result]);
    if (history1 === true) {
        setHistory1(5);
    }
    if (history2 === true) {
        setHistory2(10);
    } if (cardiacExam1 === true) {
        setCardiacExam1(11);
    }
    if (cardiacExam2 === true) {
        setCardiacExam2(3);
    } if (electrocardiogram1 === true) {
        setElectrocardiogram1(7);
    }
    if (electrocardiogram2 === true) {
        setElectrocardiogram2(7);
    } if (gmc1 === true) {
        setGmc1(3);
    }
    if (operation1 === true) {
        setOperation1(4);
    } if (operation2 === true) {
        setOperation2(3);
    }



    return (
        <div className="rev">
            <h5 className="text-info pl-2">Cardiac Risk Index (Goldman Criteria)</h5>
            <p className="pl-2"> Assesses risk of perioperative cardiac complications in heart surgery patients.</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2" ><strong>History</strong></div>

                </div>
                <div className="col-md-7  p-3">
                    <input style={{ height: 20, width: 20 }} type="checkbox" checked={history1} onChange={(e) => { setHistory1(e.target.checked) }} />
                    <span className="pb-2" >  Age &gt; 70 years (+5)</span><br />
                    <input style={{ height: 20, width: 20 }} type="checkbox" checked={history2} onChange={(e) => { setHistory2(e.target.checked) }} />
                    <span className="pb-2">  Myocardial infarction within 6 months (+10)</span>
                </div>

            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2" ><strong>Cardiac Exam</strong></div>
                </div>
                <div className="col-md-7  p-3">
                    <input style={{ height: 20, width: 20 }} type="checkbox" checked={cardiacExam1} onChange={(e) => { setCardiacExam1(e.target.checked) }} />
                    <span className="pb-2">  Signs of CHF: ventricular gallop or JVD (+11)</span><br />
                    <input style={{ height: 20, width: 20 }} type="checkbox" checked={cardiacExam2} onChange={(e) => { setCardiacExam2(e.target.checked) }} />
                    <span className="pb-2" >  Significant aortic stenosis (+3)</span>
                </div>

            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2" ><strong>Electrocardiogram</strong></div>
                </div>
                <div className="col-md-7  p-3">
                    <input style={{ height: 20, width: 20 }} type="checkbox" checked={electrocardiogram1} onChange={(e) => { setElectrocardiogram1(e.target.checked) }} />
                    <span className="pb-2"> Arrhythmia other than sinus or premature atrial contractions (+7)</span><br />
                    <input style={{ height: 20, width: 20 }} type="checkbox" checked={electrocardiogram2} onChange={(e) => { setElectrocardiogram2(e.target.checked) }} />
                    <span className="pb-2">  5 or more PVC's per minute (+7)</span>
                </div>

            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2" ><strong>General Medical Conditions</strong></div>
                </div>
                <div className="col-md-7  p-3">
                    <input style={{ height: 20, width: 20 }} type="checkbox" checked={gmc1} onChange={(e) => { setGmc1(e.target.checked) }} />
                    <span className="pb-2">  PO2 &lt;60; PCO2 &gt;50; K &lt;3; HCO3 &lt;20; BUN &gt;50; Creatinine &gt;3; elevated SGOT; chronic liver disease; bedridden (+3)</span><br />

                </div>

            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2" ><strong>Operation</strong></div>
                </div>
                <div className="col-md-7  p-3">
                    <input style={{ height: 20, width: 20 }} type="checkbox" checked={operation1} onChange={(e) => { setOperation1(e.target.checked) }} />
                    <span className="pb-2">  Emergency (+4)</span><br />
                    <input style={{ height: 20, width: 20 }} type="checkbox" checked={operation2} onChange={(e) => { setOperation2(e.target.checked) }} />
                    <span className="pb-2" >  Intraperitoneal, intrathoracic or aortic (+3)</span><br />
                </div>

            </div>

            <div className="stick mt-5" >
                {
                    result != 0 ? <>
                        <div className="apfel-score-result-container col-12" >
                            <div className="point-result-container col-12" >
                                <div> <h5>Cardiac Risk Index Score = {result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h5></div>

                                <>{result < 6 ? <div><span className="font-weight-bold" >Interpretation: </span>Class I 1% Complications</div> : " "}</>
                                <div>{result > 5 && result < 13 ? <div> <span className="font-weight-bold" >Interpretation: </span>Class II 7% Complications</div> : " "}</div>
                                <>{result > 12 && result < 26 ? <div><span className="font-weight-bold" >Interpretation: </span>Class III 14% Complications</div> : " "}</>
                                <div>{result > 25 && result < 57 ? <div> <span className="font-weight-bold" >Interpretation: </span>Class IV 78% Complications</div> : " "}</div>


                            </div>






                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <br />
            <div className="p-2 mb-5" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/904659/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Goldman L, Caldera DL, Nussbaum SR, et. al. Multifactorial index of cardiac risk in noncardiac surgical procedures. N. Engl J Med. 1977;297:845.</p>
                    </div>
                </a>

            </div>
        </div>
    )
}


export default RE1197;

