import React, { useEffect, useState } from 'react';
import './CAS1049.css';

const CAS1049 = () => {
    const [drugOption1, setDrugOption1] = useState(0);
    const [drugOption2, setDrugOption2] = useState(0);
    const [drugOption3, setDrugOption3] = useState(0);
    const [drugOption4, setDrugOption4] = useState(0);
    const [drugOption5, setDrugOption5] = useState(0);
    const [drugOption6, setDrugOption6] = useState(0);
    const [dosage1, setDosage1] = useState(0);
    const [dosage2, setDosage2] = useState(0);
    const [dosage3, setDosage3] = useState(0);
    const [dosagePerDay1, setDosagePerDay1] = useState(0);
    const [dosagePerDay2, setDosagePerDay2] = useState(0);
    const [dosagePerDay3, setDosagePerDay3] = useState(0);


    const [drug1, setDrug1] = useState("No");
    const [drug2, setDrug2] = useState("No");
    const [result1, setResult1] = useState(0);
    const [result2, setResult2] = useState(0);
    const [result3, setResult3] = useState(0);
    const [result4, setResult4] = useState(0);
    const [result5, setResult5] = useState(0);
    const [result6, setResult6] = useState(0);
    const [result7, setResult7] = useState(0);
    const [result8, setResult8] = useState(0);
    const [result9, setResult9] = useState(0);

    const [totalresult, setTotalResult] = useState(0);
    const function1 = async () => {

        if (drug2 == "No") {
            setDrugOption5(0)
            setDrugOption6(0)
            setDosage3(0)
            setDosagePerDay3(0)
        }
        if (drug1 == "No") {
            setDrugOption4(0)
            setDrugOption3(0)
            setDosagePerDay2(0);
            setDosage2(0);
            setDrugOption5(0)
            setDrugOption6(0)
            setDosage3(0)
            setDosagePerDay3(0)
        }
        if ((drugOption1 == 0.15) || (drugOption1 == 0.13) || (drugOption1 == 1) || (drugOption1 == 4) || (drugOption1 == 12) || (drugOption1 == 1.5) || (drugOption1 == 3) || (drugOption1 == 0.4) || (drugOption1 == 0.1) || (drugOption1 == 0)) {
            setResult4(0)
        }
        if (drugOption1 == 2.4) {
            setDosage1(0)
            setDosagePerDay1(0)
        }
        if ((drugOption3 == 0.15) || (drugOption3 == 0.13) || (drugOption3 == 1) || (drugOption3 == 4) || (drugOption3 == 12) || (drugOption3 == 1.5) || (drugOption3 == 3) || (drugOption3 == 0.4) || (drugOption3 == 0.1) || (drugOption3 == 0)) {
            setResult5(0)
        }
        if (drugOption3 == 2.4) {
            setDosage2(0)
            setDosagePerDay2(0)
        }
        if ((drugOption5 == 0.15) || (drugOption5 == 0.13) || (drugOption5 == 1) || (drugOption5 == 4) || (drugOption5 == 12) || (drugOption5 == 1.5) || (drugOption5 == 3) || (drugOption5 == 0.4) || (drugOption5 == 0.1) || (drugOption5 == 0)) {
            setResult6(0)
        }
        if (drugOption5 == 2.4) {
            setDosage3(0)
            setDosagePerDay3(0)

        }



        setResult7(dosage1 * dosagePerDay1);
        setResult9(dosage3 * dosagePerDay3);
        setResult8(dosage2 * dosagePerDay2);

    }
    const postData = async () => {

        if ((drugOption1 == 2.4)) {
            setResult4(drugOption1 * drugOption2)
        }
        if ((drugOption3 == 2.4)) {
            setResult5(drugOption3 * drugOption4)
        }
        if (drugOption5 == 2.4) {
            setResult6(drugOption5 * drugOption6)
        }
        else {

            setResult1(drugOption1 * dosage1 * dosagePerDay1)
            setResult2(drugOption3 * dosage2 * dosagePerDay2)
            setResult3(drugOption5 * dosage3 * dosagePerDay3)
        }
        { function1() }

        setTotalResult((result1 + result2 + result3 + result4 + result5 + result6).toFixed(1))
    }

    useEffect(() => {
        postData();
    }, [drugOption1, drugOption2, drugOption3, drugOption4, drugOption5, drugOption6, dosage1, dosage2, dosage3, dosagePerDay1, dosagePerDay2, dosagePerDay3, result1, result2, result3, drug1, drug2, result4, result5, result6, totalresult]);

    const optionname1 = () =>{
        if(drugOption1 == 0.15 ){
          return <div>Codeine</div>
        }
        if(drugOption1 == 0.13 ){
            return <div>FentaNyl buccal or sublingual tablets</div>
          }
          if(drugOption1 == 2.4 ){
            return <div>FentaNyL patch(Duragesic)</div>
          }
          if(drugOption1 == 1 ){
            return <div>HYDROcodone (Vicodine,Norco,Lortab)</div>
          }
          if(drugOption1 == 4 ){
            return <div>HYDROmorphone(Dilaudid)</div>
          }
          if(drugOption1 == 12 ){
            return <div>Methadone</div>
          }
          if(drugOption1 == 1.5 ){
            return <div>OxyCODONE(OxyCONTIN,Roxicodone)</div>
          }
          if(drugOption1 == 3 ){
            return <div>OxyMORphone</div>
          }
          if(drugOption1 == 0.4 ){
            return <div>Tapentadol</div>
          }
          if(drugOption1 == 0.1 ){
            return <div>TraMADol(Ultram)</div>
          }
    }
    const afterafterdose2 = () => {
        if (drugOption3 == 2.4) {
            return <div>
                <div className="fields row w-100">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Dosage,mcg/hr</strong><br />Assumes 1 patch every 3 day
                        </div>
                    </div>
                    <div className="col-md-7">
                        <select className="custom-select" aria-label="Age"
                            value={drugOption4}
                            onChange={(e) => {
                                const patient1 = e.target.value;

                                setDrugOption4(patient1);
                            }}
                        >
                            <option value='0'>Choose One</option>
                            <option value='12.5'>12.5</option>
                            <option value='25' >25</option>
                            <option value='50' >50</option>
                            <option value='75' >75</option>
                            <option value='87.5' >87.5</option>
                            <option value='100' >100</option>
                        </select>
                    </div>

                </div>
                <div className="fields row">
                    <div className="col-md-6">
                        <div className="pt-1"><strong>Add another drug</strong></div>

                    </div>
                    <div className="col-md-6 btn-group pl-4" >
                        <button style={{ height: 40, }} className={drug2 === "No" ? "btn active text-white" : "btn "} onClick={() => setDrug2("No")}>No <span className="calc-val text-right"></span></button>
                        <button style={{ height: 40, }} className={drug2 === "Yes" ? "btn active text-white" : "btn "} onClick={() => setDrug2("Yes")}>Yes<span className="calc-val text-right"></span></button>

                    </div>


                </div>

            </div>

        }
        else {
            return <div>

                <div className="fields row">
                    <div className="col-md-5 col-sm-12">
                        <div className="pt-2"><strong>Dosage</strong></div>
                    </div>
                    <div className="col-md-7 col-sm-12 frm-field">
                        <input type="number"
                            className="ant-text-input" onChange={(e) => setDosage2(e.target.value)} style={{ width: '65%' }} /><span className="units" >mg/dose</span>

                    </div>

                </div>
                <div className="fields row">
                    <div className="col-md-5 col-sm-12">
                        <div className="pt-2"><strong>Doses per day</strong></div>
                    </div>
                    <div className="col-md-7 col-sm-12 frm-field">
                        <input type="number"
                            className="ant-text-input" onChange={(e) => setDosagePerDay2(e.target.value)} style={{ width: '65%' }} /><span className="units" >dose/day</span>
                    </div>

                </div>
                <div className="fields row">
                    <div className="col-md-6">
                        <div className="pt-1"><strong>Add another drug</strong></div>

                    </div>
                    <div className="col-md-6 btn-group pl-4" >
                        <button style={{ height: 40, }} className={drug2 === "No" ? "btn active text-white" : "btn "} onClick={() => setDrug2("No")}>No <span className="calc-val text-right"></span></button>
                        <button style={{ height: 40, }} className={drug2 === "Yes" ? "btn active text-white" : "btn "} onClick={() => setDrug2("Yes")}>Yes<span className="calc-val text-right"></span></button>

                    </div>
                    {/* <div>{result9}</div> */}
                </div>

            </div>

        }
    }
    const creatnew1 = () => {
        if (drug1 === "Yes") {
            return <div>
                <div className="fields row w-100">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Drug</strong>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <select className="custom-select" aria-label="Age"
                            value={drugOption3}
                            onChange={(e) => {
                                const patient = e.target.value;

                                setDrugOption3(patient);
                            }}
                        >
                            <option value='0'>Choose One</option>
                            <option value='0.15'>Codeine</option>
                            <option value='0.13' >FentaNyl buccal or sublingual tablets</option>
                            <option value='2.4' >FentaNyL patch(Duragesic)</option>
                            <option value='1' >HYDROcodone (Vicodine,Norco,Lortab)</option>
                            <option value='4' >HYDROmorphone(Dilaudid)</option>
                            <option value='12' >Methadone</option>
                            <option value='1' >Morphine</option>
                            <option value='1.5' >OxyCODONE(OxyCONTIN,Roxicodone)</option>
                            <option value='3' >OxyMORphone</option>
                            <option value='0.4' >Tapentadol</option>
                            <option value='0.1' >TraMADol(Ultram)</option>
                        </select>
                    </div>
                </div>
                
                <div>{afterafterdose2()}</div>
               
            </div>

        }
    }
    const afterdoses = () => {
        if (drugOption1 == 2.4) {
            return <div>
                <div className="fields row w-100">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Drug</strong>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <select className="custom-select" aria-label="Age"
                            value={drugOption1}
                            onChange={(e) => {
                                const patient = e.target.value;

                                setDrugOption1(patient);
                            }}
                        >
                            <option value='0'>Choose One</option>
                            <option value='0.15'>Codeine</option>
                            <option value='0.13' >FentaNyl buccal or sublingual tablets</option>
                            <option value='2.4' >FentaNyL patch(Duragesic)</option>
                            <option value='1'  >HYDROcodone (Vicodine,Norco,Lortab)</option>
                            <option value='4' >HYDROmorphone(Dilaudid)</option>
                            <option value='12' >Methadone</option>
                            <option value='1' >Morphine</option>
                            <option value='1.5' >OxyCODONE(OxyCONTIN,Roxicodone)</option>
                            <option value='3' >OxyMORphone</option>
                            <option value='0.4' >Tapentadol</option>
                            <option value='0.1' >TraMADol(Ultram)</option>
                        </select>
                    </div>
                   
                </div>
                <div className="fields row w-100">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Dosage,mcg/hr</strong><br />Assumes 1 patch every 3 day
                        </div>
                    </div>
                    <div className="col-md-7">
                        <select className="custom-select" aria-label="Age"
                            value={drugOption2}
                            onChange={(e) => {
                                const patient1 = e.target.value;
                                setDrugOption2(patient1);
                            }}
                        >
                            <option value='0'>Choose One</option>
                            <option value='12.5'>12.5</option>
                            <option value='25' >25</option>
                            <option value='50' >50</option>
                            <option value='75' >75</option>
                            <option value='87.5' >87.5</option>
                            <option value='100' >100</option>
                        </select>
                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-6">
                        <div className="pt-1"><strong>Add another drug</strong></div>

                    </div>
                    <div className="col-md-6 btn-group pl-4" >
                        <button style={{ height: 40, }} className={drug1 === "No" ? "btn active text-white" : "btn "} onClick={() => setDrug1("No")}>No <span className="calc-val text-right"></span></button>
                        <button style={{ height: 40, }} className={drug1 === "Yes" ? "btn active text-white" : "btn "} onClick={() => setDrug1("Yes")}>Yes<span className="calc-val text-right"></span></button>

                    </div>
                </div>

            </div>
        }
        else {
            return <div>
                <div className="fields row w-100">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Drug</strong>
                        
                        </div>
                    </div>
                    <div className="col-md-7">
                        <select className="custom-select" aria-label="Age"
                            value={drugOption1}
                            onChange={(e) => {
                                const patient = e.target.value;

                                setDrugOption1(patient);
                            }}
                        >
                            <option value='0'>Choose One</option>
                            <option value='0.15'>Codeine</option>
                            <option value='0.13' >FentaNyl buccal or sublingual tablets</option>
                            <option value='2.4' >FentaNyL patch(Duragesic)</option>
                            <option value='1' >HYDROcodone (Vicodine,Norco,Lortab)</option>
                            <option value='4' >HYDROmorphone(Dilaudid)</option>
                            <option value='12' >Methadone</option>
                            <option value='1' >Morphine</option>
                            <option value='1.5' >OxyCODONE(OxyCONTIN,Roxicodone)</option>
                            <option value='3' >OxyMORphone</option>
                            <option value='0.4' >Tapentadol</option>
                            <option value='0.1' >TraMADol(Ultram)</option>
                        </select>
                    </div>
                   
                </div>

                <div className="fields row">
                    <div className="col-md-5 col-sm-12">
                        <div className="pt-2"><strong>Dosage</strong></div>
                    </div>
                    <div className="col-md-7 col-sm-12 frm-field">
                        <input type="number"
                            className="ant-text-input" onChange={(e) => setDosage1(e.target.value)} style={{ width: '65%' }} /><span className="units" >mg/dose</span>

                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-5 col-sm-12">
                        <div className="pt-2"><strong>Doses per day</strong></div>
                    </div>
                    <div className="col-md-7 col-sm-12 frm-field">
                        <input type="number"
                            className="ant-text-input" onChange={(e) => setDosagePerDay1(e.target.value)} style={{ width: '65%' }} /><span className="units" >dose/day</span>
                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-6">
                        <div className="pt-1"><strong>Add another drug</strong></div>

                    </div>
                    <div className="col-md-6 btn-group pl-4" >
                        <button style={{ height: 40, }} className={drug1 === "No" ? "btn active text-white" : "btn "} onClick={() => setDrug1("No")}>No <span className="calc-val text-right"></span></button>
                        <button style={{ height: 40, }} className={drug1 === "Yes" ? "btn active text-white" : "btn "} onClick={() => setDrug1("Yes")}>Yes<span className="calc-val text-right"></span></button>

                    </div>
                </div>

            </div>
        }
    }
    const afterafterdose3 = () => {
        if (drugOption5 == 2.4) {
            return <div>
                <div className="fields row w-100">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Dosage,mcg/hr</strong><br />Assumes 1 patch every 3 day
                        </div>
                    </div>
                    <div className="col-md-7">
                        <select className="custom-select" aria-label="Age"
                            value={drugOption6}
                            onChange={(e) => {
                                const patient1 = e.target.value;

                                setDrugOption6(patient1);
                            }}
                        >
                            <option value='0'>Choose One</option>
                            <option value='12.5'>12.5</option>
                            <option value='25' >25</option>
                            <option value='50' >50</option>
                            <option value='75' >75</option>
                            <option value='87.5' >87.5</option>
                            <option value='100' >100</option>
                        </select>
                    </div>
                </div>
            </div>
        }
        else {
            return <div>
                <div className="fields row">
                    <div className="col-md-5 col-sm-12">
                        <div className="pt-2"><strong>Dosage</strong></div>
                    </div>
                    <div className="col-md-7 col-sm-12 frm-field">
                        <input type="number"
                            className="ant-text-input" onChange={(e) => setDosage3(e.target.value)} style={{ width: '65%' }} /><span className="units" >mg/dose</span>

                    </div>

                </div>
                <div className="fields row">
                    <div className="col-md-5 col-sm-12">
                        <div className="pt-2"><strong>Doses per day</strong></div>
                    </div>
                    <div className="col-md-7 col-sm-12 frm-field">
                        <input type="number"
                            className="ant-text-input" onChange={(e) => setDosagePerDay3(e.target.value)} style={{ width: '65%' }} /><span className="units" >dose/day</span>
                    </div>
                </div>
            </div>

        }
    }
    const creatnew2 = () => {
        if ((drug2 === "Yes") && (drug1 === "Yes")) {
            return <div>
                <div className="fields row w-100">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Drug</strong>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <select className="custom-select" aria-label="Age"
                            value={drugOption5}
                            onChange={(e) => {
                                const patient = e.target.value;

                                setDrugOption5(patient);
                            }}
                        >
                            <option value='0'>Choose One</option>
                            <option value='0.15'>Codeine</option>
                            <option value='0.13' >FentaNyl buccal or sublingual tablets</option>
                            <option value='2.4' >FentaNyL patch(Duragesic)</option>
                            <option value='1' >HYDROcodone (Vicodine,Norco,Lortab)</option>
                            <option value='4' >HYDROmorphone(Dilaudid)</option>
                            <option value='12' >Methadone</option>
                            <option value='1' >Morphine</option>
                            <option value='1.5' >OxyCODONE(OxyCONTIN,Roxicodone)</option>
                            <option value='3' >OxyMORphone</option>
                            <option value='0.4' >Tapentadol</option>
                            <option value='0.1' >TraMADol(Ultram)</option>
                        </select>
                    </div>
                </div>
                <div> {afterafterdose3()}</div>
            </div>
        }
    }

    const output = () => {

        if ((totalresult > 0) && (totalresult < 20)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h2>{totalresult}</h2>
                    <div>MME/day</div>
                    <br />
                    <div>
                        Acceptable therapeutic range for acute pain and opioid-naïve patients</div>
                </div>
                <div className="point-result-container" >
                    <div> Drug :
                        <div>{result7}</div>
                        <div>{optionname1()}</div>
                        <div>{result8}</div>
                        <div>{result9}</div></div>

                </div>
            </div>
        }
        else if ((totalresult >= 20) && (totalresult < 50) || drugOption1) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h2>{totalresult}</h2>
                    <div>MME/day</div>
                    <br />
                    <div>
                        2x higher risk of overdoseThere is no completely safe opioid dose; use caution when prescribing opioids at any dose and always prescribe the lowest effective dose </div>
                </div>
                <div className="point-result-container" >
                    <div> Drug :

                        <div>{result7}</div>
                        <div>{optionname1()}</div>
                        <div>{result8}</div>
                        <div>{result9}</div></div>
                </div>
            </div>
        }
        else if ((totalresult >= 50) && (totalresult < 100)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h2>{totalresult}</h2>
                    <div>MME/day</div>
                    <br />
                    <div>
                        3.7x higher risk of overdose Strongly consider non-opioid analgesics and decreasing daily opioid dose </div>
                </div>
                <div className="point-result-container" >
                    <div> Drug :
                        <div>{result7}</div>
                        <div>{optionname1()}</div>
                        <div>{result8}</div>
                        <div>{result9}</div></div>
                </div>
            </div>
        }
        else if (totalresult >= 100) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h2>{totalresult}</h2>
                    <div>MME/day</div>
                    <br />
                    <div>
                        8.9x higher risk of overdoseConsult pain specialist to reassess pain regimen and decrease dosage and/or wean off opioids</div>
                </div>
                <div className="point-result-container" >

                    <div> Drug :
                        <div>{result7}</div>
                        <div>{optionname1()}</div>
                        <div>{result8}</div>
                        <div>{result9}</div></div>
                </div>
            </div>
        }
        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }
    return (
        <div className="rev">
              <h5 className="text-info pl-2">Morphine Milligram Equivalents (MME) Calculator</h5>
            <p className="pl-2">Calculates total daily morphine milligram equivalents..</p>
            <div>{afterdoses()}</div>
            <div>{creatnew1()}</div>
            <div>{creatnew2()}</div>
            
            <br/>
            <div className="stick">
                {
                    output()
                }

            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/18574361/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >Von Korff M, Saunders K, et al. De facto long-term opioid therapy for noncancer pain. Clin J Pain. 2008;24(6):521-7.</p>
                    </div>
                </a>
            </div>

        </div>

    )
}

export default CAS1049
