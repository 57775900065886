import React, { useEffect, useState } from 'react';
import './FAT1167.css'

const FAT1167 = () => {
    const [green, setGreen] = useState(false);
    const [number1, setNumber1] = useState(0);
    const [number2, setNumber2] = useState(0);
    const [number3, setNumber3] = useState(0);
    const [number4, setNumber4] = useState(0);
    const [number5, setNumber5] = useState(0);
    const [store, setStore] = useState(false);

    const [sex, setSex] = useState("");
    const [height1, setHeight1] = useState(0);

    const [total1, settotal1] = useState(0)
    const [total2, settotal2] = useState(0)
    const [total3, settotal3] = useState(0)
    const [newnumber5, setNewnumber5] = useState(0);
    const [newnumber6, setNewnumber6] = useState(0);

    const postData = async () => {
        if (number5 == 321) {

            setNewnumber5(number3);
        }
        if (number5 == 100) {

            setNewnumber5((number3 / 1000).toFixed(2));
        }
        if (number5 == 85) {

            setNewnumber5((number3 / 2.208).toFixed(2));
        }
        if (height1 == 432) {
            setNewnumber6((number4).toFixed(2));
        }
        if (height1 == 108) {
            setNewnumber6(number4 * 2.54);
        }
        if (number1 == 100) {
            settotal1(Math.round(((100 * (1 - number2 / 100)) * newnumber5)))
            settotal2(Math.round(((100 * number2 / 100) * newnumber5)))
            settotal3(((((100 * (1 - number2 / 100)) * newnumber5) + ((100 * number2 / 100) * newnumber5))).toFixed(0))
        }
        else if (number1 == 85) {
            settotal1(Math.round((85 * (1 - number2 / 100)) * newnumber5))
            settotal2(Math.round((85 * number2 / 100) * newnumber5))
            settotal3((((85 * (1 - number2 / 100)) * newnumber5) + ((85 * number2 / 100) * newnumber5)).toFixed(0))
        }
        else if (number1 == 75) {
            settotal1(Math.round((75 * (1 - number2 / 100)) * newnumber5))
            settotal2(Math.round((75 * number2 / 100) * newnumber5))
            settotal3(((((75 * (1 - number2 / 100)) * newnumber5) + ((75 * number2 / 100) * newnumber5))).toFixed(0))
        }
        else if (number1 == 70) {
            settotal1(Math.round((70 * (1 - number2 / 100)) * newnumber5))
            settotal2(Math.round((70 * number2 / 100) * newnumber5))
            settotal3(((((70 * (1 - number2 / 100)) * newnumber5) + ((70 * number2 / 100) * newnumber5))).toFixed(0))
        } else if (sex === "Male") {
            // setSex(Math.round((0.3669 * ((height*0.01)*(height*0.01)*(height*0.01)) + 0.03219 * (weight) + 0.6041)*10)/10);
            settotal3(((0.3669 * ((newnumber6 * 0.01) * (newnumber6 * 0.01) * (newnumber6 * 0.01)) + 0.03219 * (newnumber5) + 0.6041)).toFixed(2));
            settotal2(Math.round(((0.3669 * ((newnumber6 * 0.01) * (newnumber6 * 0.01) * (newnumber6 * 0.01)) + 0.03219 * (newnumber5) + 0.6041) * 1000) * number2 / 100));
            settotal1(Math.round((((0.3669 * ((newnumber6 * 0.01) * (newnumber6 * 0.01) * (newnumber6 * 0.01)) + 0.03219 * (newnumber5) + 0.6041) * 1000) * (1 - number2 / 100))));
        }
        else if (sex === "Female") {
            settotal3(((0.3561 * ((newnumber6 * 0.01) * (newnumber6 * 0.01) * (newnumber6 * 0.01)) + 0.03308 * (newnumber5) + 0.1833)).toFixed(2));
            settotal2(Math.round(((0.3561 * ((newnumber6 * 0.01) * (newnumber6 * 0.01) * (newnumber6 * 0.01)) + 0.03308 * (newnumber5) + 0.1833) * 1000) * number2 / 100));
            settotal1(Math.round(((0.3561 * ((newnumber6 * 0.01) * (newnumber6 * 0.01) * (newnumber6 * 0.01)) + 0.03308 * (newnumber5) + 0.1833) * 1000) * (1 - number2 / 100)));
        }

        setGreen(true);
    }
    const liter = () => {
        if ((number1 == 100) || (number1 == 85) || (number1 == 75) || (number1 == 70)) {
            return <>mL</>
        }
        else if ((sex === "Female") || (sex === "Male")) {
            return <>L</>
        }
    }
    useEffect(() => {
        postData()
    }, [number1, number2, number3, number4, store, sex, number5, newnumber5, height1, newnumber6])



    return (<div className="rev">
        <h5 className="text-info pl-2">Plasma Volume Calculator</h5>
        <p className="pl-2">Determines total blood, RBC and plasma values based on patient criteria, hematocrit, height and weight.</p>
        <div className="fields row">
            <div className="col-md-4 ">
                <div className="pt-2"><strong>Patient Type</strong>
                </div>
            </div>
            <div className="col-md-8 pl-5">
                <select className="custom-select" aria-label="Default select example"
                    value={number1}
                    onChange={(e) => {
                        const patient = e.target.value;
                        let store = false;
                        // alert(e.target.value)
                        if (e.target.value === '1') {
                            store = true;
                            setStore(store)
                        } else {
                            store = false;
                            setStore(store)
                        }
                        setNumber1(patient);
                    }}>

                    <option selected>select</option>
                    <option value="100">Preterm neonate</option>
                    <option value="85" >Term neonate</option>
                    <option value="75">infant aged 1-4 months</option>
                    <option value="70">child 	&lt;25 kg (55lbl)</option>
                    <option value="1">child  ≥25 kg (55lbl) or adult</option>



                </select>
            </div>

        </div>
        <div className="fields row">
            <div className="col-md-4">
                <div className="pt-2"><strong>Hematocrit</strong></div>
            </div>
            <div className="col-md-8 frm-field">
                <input

                    type={number2}
                    onChange={e => setNumber2(+e.target.value)}


                    className="ant-text-input" style={{ width: "87%" }} /><span className="units">%</span>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-4">
                <div className="pt-2"><strong>Weight</strong></div>
            </div>
            <div className="col-md-8 frm-field">
                <input

                    type={number3}
                    onChange={e => setNumber3(+e.target.value)}


                    className="ant-text-input" style={{ width: "80%" }} />

                <select className="custom-select" aria-label="Default select example" style={{ width: "22%", height:40 }} value={number5}
                    onChange={(e) => {
                        const patient2 = e.target.value;
                        setNumber5(patient2);
                    }}>

                    <option selected>select</option>
                    <option value="321">Kg</option>
                    <option value="100">gm</option>
                    <option value="85" >lbs</option>

                </select>
            </div>
        </div>
        <div>
            {
                store ?
                    <div className="fields row w-100">
                        <div className="col-md-4">
                            <div className="pt-2"><strong>Gender</strong>
                            </div>
                        </div>
                        <div className="col-md-8 frm-field">
                            <select className="custom-select ml-4" aria-label="Default select example"
                                onChange={(e) => {
                                    const setsex = e.target.value;
                                    setSex(setsex);

                                }} >
                                <option selected>Select</option>
                                <option value="Male">Male</option>
                                <option value="Female" >Female</option>

                            </select>
                        </div>

                    </div>
                    :
                    <> </>
            }
        </div>
        <div>
            {
                store ?
                    <div className="fields row">
                        <div className="col-md-4">
                            <div className="pt-2"><strong>Height</strong></div>
                        </div>
                        <div className="col-md-8 frm-field">
                            <input

                                type={number4}
                                onChange={e => setNumber4(+e.target.value)}


                                className="ant-text-input" style={{ width: "78%" }} />

                            <select className="custom-select fluidInput" aria-label="Default select example" onChange={(e) => {
                                const height = e.target.value;
                                setHeight1(height);

                            }} >
                                <option selected>select</option>
                                <option value="432">cm</option>
                                <option value="108">in</option>
                            </select>
                        </div>
                    </div>
                    :
                    <> </>
            }
        </div>
        <br />
        <div className="stick-result">
            {(total1 && total2 && total3) ?

                <div className="apfel-score-result-container" >

                    <div className="point-result-container" >
                        <h1>{total1} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>
                            mL</span></h1>
                        <div>Plazma Volume</div>
                    </div>
                    <div className="point-result-container" >
                        <h1>{total2} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>
                            mL</span></h1>
                        <div>RBC volume</div>
                    </div>
                    <div className="point-result-container" >
                        <h1>{total3} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>
                            {liter()}</span></h1>
                        <div>Total blood volume</div>
                    </div>

                </div>
                :
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>
            }
        </div>
        <div className="p-2 mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/21936146/">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Nadler SB, Hidalgo JH, Bloch T. Prediction of blood volume in normal human adults. Surgery. 1962;51(2):224-32.</p>
                </div>
            </a><br />
            <a className="referenceLink" target="_blank" href="https://www.ncbi.nlm.nih.gov/books/NBK526077/">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Sharma R, Sharma S. Physiology, Blood Volume. Treasure Island (FL): StatPearls Publishing; 2020 Jan.</p>
                </div>
            </a>
        </div>
    </div>
    )
}

export default FAT1167