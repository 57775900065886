import React, { useState } from 'react'
import Header from '../CustomComponent/Header'
import { Link } from 'react-router-dom';
import "../css/subregionalAnesthesia.css"
import '../css/ApfelScore.css';
const SubRegionalAnethesia = (props) => {
    const [data, setData] = useState([])
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };
    let video = 'https://www.youtube.com/embed?v=iE9XbLDkLy8&list=PLA9GdUb_D_T0z8-ZW-q8Lg8jkg3mBOz4s';
    useState(() => {
        setData(props.location.state.data);
        toggleTab(1);
    }, [])
    return (
        <div>
            {/* <Header onMenuClick={() => burgerMenuClick()} /> */}
            {/* <div className="backNavigation text-center">
                <Link className="arrow" to={"/" + props.location.state.returnPath}><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to {props.location.state.sourceName}</span></span></Link>{props.location.state.name}
            </div> */}

            {
                data ? data.map((res, i) => {
                    return (
                        <div>
                            {/* <div className="sub-anesthesia-div">
                            <p>Anatomical Correlation: <a href={res.anatomical_correlation} target="_blank">{res.anatomical_correlation}</a></p>
                            <p>Anatomy: <a href={res.anatomy} target="_blank"> {res.anatomy}</a></p>
                            <p>clinical_pearls: <a href={res.clinical_pearls} target="_blank">{res.clinical_pearls}</a></p>
                            <p>image gallery: <a href={res.image_gallery} target="_blank">{res.image_gallery}</a></p>
                            <p>Anesthetic Injection: <a href={res.local_anesthetic_injection} target="_blank">{res.local_anesthetic_injection}</a></p>
                            <p>Insertion Approach: <a href={res.needle_insertion_approach} target="_blank">{res.needle_insertion_approach}</a></p>
                            <p>Nerve Localization: <a href={res.nerve_localization} target="_blank">{res.nerve_localization}</a></p>
                            <p>Scannning Technoque: <a href={res.scanning_technique} target="_blank">{res.scanning_technique}</a></p>
                            <p>Selected References: <a href={res.selected_references} target="_blank">{res.selected_references}</a></p>
                            <p>Video Gallery: <a href={res.video_gallery} target="_blank"> {res.video_gallery}</a></p>
                        </div> */}
                            <div className="main-container calculator-container mt-3">
                                <div className="row">
                                    <div className="col-md-3 airway-calc-tabs">
                                        <button className={toggleState === 1 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(1)} >Anatomical Correlation</button>
                                        <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(2)} >Anatomy</button>
                                        <button className={toggleState === 3 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(3)} >Clinical_pearls</button>
                                        <button className={toggleState === 4 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(4)} >Image gallery</button>
                                        <button className={toggleState === 5 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(5)} >Anesthetic Injection</button>
                                        <button className={toggleState === 6 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(6)} >Insertion Approach</button>
                                        <button className={toggleState === 7 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(7)} >Nerve Localization</button>
                                        <button className={toggleState === 8 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(8)} >Scannning Technoque</button>
                                        <button className={toggleState === 9 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(9)} >Selected References</button>
                                        <button className={toggleState === 10 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(10)} >Video Gallery</button>

                                    </div>
                                    <div className="col-md-9 col-sm-12">
                                        <div className="content-tabs">
                                            <div
                                                className={toggleState === 1 ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={res.anatomical_correlation} type="application/pdf"/>
                                            </div>
                                            <div
                                                className={toggleState === 2 ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={res.anatomy} />
                                            </div>
                                            <div
                                                className={toggleState === 3 ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={res.clinical_pearls} />
                                            </div>
                                            <div
                                                className={toggleState === 4 ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={res.image_gallery} />
                                            </div>
                                            <div
                                                className={toggleState === 5 ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={res.local_anesthetic_injection} />
                                            </div>
                                            <div
                                                className={toggleState === 6 ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={res.needle_insertion_approach} />
                                            </div>
                                            <div
                                                className={toggleState === 7 ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={res.nerve_localization} />
                                            </div>
                                            <div
                                                className={toggleState === 8 ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={res.scanning_technique} />
                                            </div>
                                            <div
                                                className={toggleState === 9 ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={res.selected_references} />
                                            </div>
                                            <div
                                                className={toggleState === 10 ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={video} title="Anesthesia One" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowtransparency="true" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                    : <></>
            }
        </div>
    )
}

export default SubRegionalAnethesia
