import React, { useEffect, useState } from 'react';
import './RE1187.css';

const RE1187 = () => {
    const [green, setGreen] = useState(false);
    const [riskFactor, setRiskFactor] = useState(0);
    const [severity, setSeverity] = useState(0);

    const [result, setResult] = useState(0);
    const [criteria1, setCriteria1] = useState(false);
    const [criteria2, setCriteria2] = useState(false);
    const [criteria3, setCriteria3] = useState(false);


    const postData = async () => {
        setResult(riskFactor + severity);
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [riskFactor, severity, criteria1, criteria2, criteria3, result]);
    const resultARDS = () => {
        if ((criteria1 === true && criteria2 === true && criteria3 === true) && (riskFactor != 3 && severity != 4)) {
            return <> <h1>Positive</h1>
                <div>{severity == 1 ? "Mild ARDS" : " "}</div>
                <div>{severity == 2 ? "Moderate ARDS" : " "}</div>
                <div>{severity == 3 ? "Severe ARDS" : " "}</div>
            </>
        }
        else {
            return<> <h1>Not positive </h1>
            <div>Not positive for ARDS by Berlin Criteria</div>
             </>
        }
    }
    return (
        <div className="rev">
                   <h5 className="text-info pl-2">Berlin Criteria for Acute Respiratory Distress Syndrome</h5>
            <p className="pl-2">Provides diagnostic criteria for acute respiratory distress syndrome (ARDS).</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2" ><strong>Required criteria</strong></div>

                </div>
                <div className="col-md-7  p-3">
                    <input  style={{ height: 20, width: 20 }} type="checkbox" checked={criteria1} onChange={(e) => { setCriteria1(e.target.checked) }} />
                    <span className="pb-2" >  Timing within 1 week of clinical insult or new/worsening respiratory symptoms</span><br />
                    <input style={{ height: 20, width: 20 }} type="checkbox" checked={criteria2} onChange={(e) => { setCriteria2(e.target.checked) }} />
                    <span className="pb-2">  Chest XR shows bilateral opacities not fully explained by effusions, lobar/lung collapse, or nodules</span> <br />
                    <input style={{ height: 20, width: 20 }} type="checkbox" checked={criteria3} onChange={(e) => { setCriteria3(e.target.checked) }} />
                    <span className="pb-2" >  Respiratory failure not fully explained by cardiac failure/fluid overload(+5)</span><br />


                </div>


            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Risk factor</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button  style={{height:  60}} onClick={() => setRiskFactor(1)} className={riskFactor === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Risk factor for ARDS present <br/>(e.g. pneumonia, trauma, sepsis, pancreatitis)</span></button>
                    <button style={{height:  60}} onClick={() => setRiskFactor(2)} className={riskFactor === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Objective assessment (Echo) excludes<br/> hydrostatic edema if no risk factor present</span></button>
                    <button  style={{height:  60}} onClick={() => setRiskFactor(3)} className={riskFactor === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">None of the above</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Severity Based on oxygenation </strong></div>
                    <p>Oxygenation</p>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2 ">
                    <button style={{height:  60}} onClick={() => setSeverity(1)} className={severity === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Mild: PaO₂/FiO₂ &gt;200 to<br/> ≤300 mmHg with PEEP OR CPAP ≥5 cm H₂Ot</span></button>
                    <button style={{height:  60}} onClick={() => setSeverity(2)} className={severity === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moderate: PaO₂/FiO₂ &gt;100 <br/>to ≤200 mmHg with PEEP ≥5 cm H₂Ot</span></button>
                    <button style={{height:  60}} onClick={() => setSeverity(3)} className={severity === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Severe: PaO₂/FiO₂ ≤100 mmHg <br/>with PEEP ≥5 cm H₂O</span></button>
                    <button style={{height:  60}} onClick={() => setSeverity(4)} className={severity === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">None of the above</span></button>
                </div>
            </div>



            <div className="stick mt-4">
                {
                    result != 0 ? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container" >
                                <div>Diagnostic Result</div>
                                <div>{resultARDS()}</div>
                               





                            </div>






                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://jamanetwork.com/journals/jama/article-abstract/1160659">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Ranieri VM, Rubenfeld GD, Thompson BT, et al. Acute respiratory distress syndrome: the Berlin Definition. JAMA. 2012;307(23):2526-33.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}


export default RE1187;


