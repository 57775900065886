import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';

const ApgarScore = () => {
    const [green, setGreen] = useState(false);

    const [activity, setActivity] = useState(null);
    const [pulse, setPulse] = useState(null);
    const [grimace, setGrimace] = useState(null);
    const [appearance, setAppearance] = useState(null);
    const [respirations, setRespirations] = useState(null);




    const [result, setResult] = useState(200);

    const postData = async () => {
        setResult(activity+pulse+grimace+appearance+respirations);
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [activity,pulse,grimace,appearance,respirations, result]);

    return (
        <div className="rev">
            <h5 className="text-info pl-2">APGAR Score</h5>
            <p className="pl-2">Assesses neonates 1 and 5 minutes after birth.</p>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Activity/muscle tone</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn mb-2 ">
                    <button style={{ height:  40   }} onClick={() => setActivity(2)} className={activity === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Active</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40   }} onClick={() => setActivity(1)} className={activity === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Some extremity flexion</span><span className="calc-val  ">+1</span></button>
                    <button style={{ height:  40   }} onClick={() => setActivity(0)} className={activity === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Limp</span><span className="calc-val ">0</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Pulse</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn mb-2 ">
                    <button style={{ height:  40   }} onClick={() => setPulse(2)} className={pulse === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">≥100 BPM</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40   }} onClick={() => setPulse(1)} className={pulse === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">&lt;100 BPM</span><span className="calc-val  ">+1</span></button>
                    <button style={{ height:  40   }} onClick={() => setPulse(0)} className={pulse === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Absent</span><span className="calc-val ">0</span></button>

                </div>
            </div>
            
       
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Grimace</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn mb-2 ">
                    <button style={{ height:  40   }} onClick={() => setGrimace(2)} className={grimace === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Sneeze/cough</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40   }} onClick={() => setGrimace(1)} className={grimace === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Grimace</span><span className="calc-val  ">+1</span></button>
                    <button style={{ height:  40   }} onClick={() => setGrimace(0)} className={grimace === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">None</span><span className="calc-val ">0</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Appearance/color</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn mb-2 ">
                    <button style={{ height:  40   }} onClick={() => setAppearance(2)} className={appearance === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">All pink</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40   }} onClick={() => setAppearance(1)} className={appearance === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Blue extremities, pink body</span><span className="calc-val  ">+1</span></button>
                    <button style={{ height:  40   }} onClick={() => setAppearance(0)} className={appearance === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Blue/pale</span><span className="calc-val ">0</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Respirations</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn mb-2 ">
                    <button style={{ height:  40   }} onClick={() => setRespirations(2)} className={respirations === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Good/cryingk</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40   }} onClick={() => setRespirations(1)} className={respirations === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Irregular/slow</span><span className="calc-val  ">+1</span></button>
                    <button style={{ height:  40   }} onClick={() => setRespirations(0)} className={respirations === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Absent</span><span className="calc-val ">0</span></button>

                </div>
            </div>
            

            <div className="stick pt-3">
                {
                    activity != null && pulse != null && grimace != null && appearance != null && respirations != null ? <>
                        <div className="apfel-score-result-container col-12 " >
                            <div className="point-result-container col-12" >
                                <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>


                                <div>{result < 7 ? "Scores < 7 suggest potential need for medical intervention, like suction, drying, warming, and stimulating the neonate. Supplemental oxygen may be indicated as well." : " "}</div>
                                <div>{result >= 7 ? "Scores ≥ 7 are typically “normal” for neonates" : " "}</div>
                            
                            
                            </div>






                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/13083014/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" > Apgar V. A proposal for a new method of evaluation of the newborn infant. Curr. Res. Anesth. Analg. 1953;32(4): 260–267. doi:10.1213/00000539-195301000-00041. PMID 13083014.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}


export default ApgarScore;

