import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';


const RegionalAnethesia = (props) => {
    const [data, setData] = useState([])
    const [toggleState, setToggleState] = useState("RANA1266");
    const toggleTab = (index) => {
        setToggleState(index);
    };
    let video = 'https://www.youtube.com/embed?v=iE9XbLDkLy8&list=PLA9GdUb_D_T0z8-ZW-q8Lg8jkg3mBOz4s';

    useEffect(() => {
        setData(props.data?.children);
        if(props.subCp_code !== null) {
            setToggleState(props.subCp_code)
        } 
    }, [props.subCp_code])
    

    return (
        <div>
            {/* <Header onMenuClick={() => burgerMenuClick()} /> */}
            {/* <div className="backNavigation text-center">
                <Link className="arrow" ><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i></span></Link>{props.data?.name}
            </div> */}
            <div className="rev"><h5 className="text-info">{props.data?.name}</h5>
</div>
                        <div>
                        {data?.length > 0 ?<>
                            <div  className="calculator-container mt-3">
                            
                                <div  className="row">
                                    <div className="bar" style={{display:'flex', overflowX:'scroll'}}>
                                        <button className={toggleState === "RANA1266" ? "normal-p btn active-nav" : "normal-p btn"} onClick={() => toggleTab("RANA1266")} >Anatomical Correlation</button>
                                        <button className={toggleState === "RANA1267" ? "normal-p btn active-nav" : "normal-p btn"} onClick={() => toggleTab("RANA1267")} >Anatomy</button>
                                        <button className={toggleState === "RANA1268" ? "normal-p btn active-nav" : "normal-p btn"} onClick={() => toggleTab("RANA1268")} >Clinical_pearls</button>
                                        <button className={toggleState === "RANA1269" ? "normal-p btn active-nav" : "normal-p btn"} onClick={() => toggleTab("RANA1269")} >Image gallery</button>
                                        <button className={toggleState === "RANA1270" ? "normal-p btn active-nav" : "normal-p btn"} onClick={() => toggleTab("RANA1270")} >Anesthetic Injection</button>
                                        <button className={toggleState === "RANA1271" ? "normal-p btn active-nav" : "normal-p btn"} onClick={() => toggleTab("RANA1271")} >Insertion Approach</button>
                                        <button className={toggleState === "RANA1272" ? "normal-p btn active-nav" : "normal-p btn"} onClick={() => toggleTab("RANA1272")} >Nerve Localization</button>
                                        <button className={toggleState === "RANA1273" ? "normal-p btn active-nav" : "normal-p btn"} onClick={() => toggleTab("RANA1273")} >Scannning Technoque</button>
                                        <button className={toggleState === "RANA1274" ? "normal-p btn active-nav" : "normal-p btn"} onClick={() => toggleTab("RANA1274")} >Selected References</button>
                                        <button className={toggleState === "RANA1275" ? "normal-p btn active-nav" : "normal-p btn"} onClick={() => toggleTab("RANA1275")} >Video Gallery</button>

                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="content-tabs">
                                            <div
                                                className={toggleState === "RANA1266" ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={data[1].pdf_name} type="application/pdf"/>

                                            </div>
                                            <div
                                                className={toggleState === "RANA1267" ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={data[1]?.pdf_name} />
                                            </div>
                                            <div
                                                className={toggleState === "RANA1268" ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={data[2]?.pdf_name} />
                                            </div>
                                            <div
                                                className={toggleState === "RANA1269" ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={data[3]?.pdf_name} />
                                            </div>
                                            <div
                                                className={toggleState === "RANA1270" ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={data[4]?.pdf_name} />
                                            </div>
                                            <div
                                                className={toggleState === "RANA1271" ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={data[5]?.pdf_name} />
                                            </div>
                                            <div
                                                className={toggleState === "RANA1272" ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={data[6]?.pdf_name} />
                                            </div>
                                            <div
                                                className={toggleState === "RANA1273" ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={data[7]?.pdf_name} />
                                            </div>
                                            <div
                                                className={toggleState === "RANA1274" ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={data[8]?.pdf_name} />
                                            </div>
                                            <div
                                                className={toggleState === "RANA1275" ? "calculator-content  active-content" : "calculator-content"}>
                                                <iframe style={{ width: '100%', minHeight:'80vh' }} src={video} title="Anesthesia One" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowtransparency="true" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             </>:<></>
                            }
           
                        </div>
                   
        </div>
    )
}

export {
    RegionalAnethesia
}
