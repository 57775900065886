import React, { useEffect, useState } from 'react';
import "../print/Print.css"
const Detailed = (props) => {
  const [prints, setPrints] = useState([]);
  useEffect(() => {
    const getCaseSummaryList = () => {
      setPrints(props?.history?.location?.state?.otherInfo)
    }
    console.log("nop", props?.history?.location?.state?.otherInfo)
    getCaseSummaryList();
  }, [])

  const formatDate = (date) => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  return (<>
    <div style={{ display: "flex", justifyContent: 'center' }}>
      <div style={{ width: 1140 }}>
        <button className='print-button' onClick={() => window.print()}>Print All Case Summaries</button>
        <div className='print-container' id="print">
          <div className='row1'>
            {(prints[0]?.data?.full_name != null) &&
              <div>Anesthesia One user : {prints[0]?.data?.full_name}</div>
            }
          </div>
          {prints?.map(caseInfo => {
            return (
              <div className='print-container' style={{ border: '1px solid #ccc' }}>
                <div className='row1' >
                  {(caseInfo?.data?.id != null) &&
                    <div>Case No : <span>{caseInfo?.data?.id}</span></div>

                  }
                  {(caseInfo?.data?.name != null) &&
                    <div>Case Name : <span>{caseInfo?.data?.name}</span></div>

                  }
                  {(caseInfo?.data?.age != null || caseInfo?.data?.age_type != null) &&
                    <div>Age : <span>{caseInfo?.data?.age} {caseInfo?.data?.age? caseInfo?.data?.age_type:''}</span></div>
                  }

                  {(caseInfo?.data?.gender != null) &&
                    <div>Gender : <span>{caseInfo?.data?.gender}</span></div>

                  }
                  {(caseInfo?.data?.patient_type_name != null) &&
                    <div>Patient Type : <span>{caseInfo?.data?.patient_type_name}</span></div>

                  }
                  {(caseInfo?.data?.weight != null || caseInfo?.data?.weight_type != null) &&
                    <div>Weight : <span>{caseInfo?.data?.weight} {caseInfo?.data?.weight ? caseInfo?.data?.weight_type:""}</span></div>

                  }
                  {(caseInfo?.data?.height != null || caseInfo?.data?.height_type != null) &&
                    <div>Height : <span>{caseInfo?.data?.height} {caseInfo?.data?.height? caseInfo?.data?.height_type:""}</span></div>

                  }
                  {(caseInfo?.data?.status != null) &&
                    <div>Status : <span>{caseInfo?.data?.status}</span></div>

                  }

                  {caseInfo?.data?.npo_status_materials != null &&
                    <div className='title'>NPO Status :<span>{caseInfo?.data?.npo_status_materials}</span>
                    </div>
                  }
                  {(caseInfo?.data?.npo_status_time != null || caseInfo?.data?.npo_status_time_type != null) &&
                    <div>Last food taken  : <span>{caseInfo?.data?.npo_status_time} {caseInfo?.data?.npo_status_time ? caseInfo?.data?.npo_status_time_type:""}</span></div>

                  }
                  {/* {caseInfo?.case_note_message != null &&
                  <div className='title'>Case Note :
                    <div className='subtitle'>
                      <span>{caseInfo?.case_note_message}</span>
                    </div>
                  </div>
                }

                {caseInfo?.case_note_image &&
                  <div className='title'>Case Image :
                    <div className='subtitle'>
                      <img src={caseInfo?.case_note_image} alt="" />
                    </div>
                  </div>
                }
                 */}
                  {formatDate(caseInfo?.data?.created_at) != null &&
                    <div>Register on : <span>{caseInfo?.created_at == null?"":formatDate(caseInfo?.created_at)}</span></div>

                  }
                </div>

                <div className='row2'>
                  {caseInfo?.asa_ps?.length ?
                    <div className='title'>ASA-PS :
                      <div className='subtitle'>
                        {
                          caseInfo?.asa_ps?.map(data => {
                            return <span key={data.id}>{data.name}</span>
                          })
                        }
                      </div>
                    </div> : <></>
                  }

                  {caseInfo?.surgeryTypes?.length ?
                    <div className='title'>Surgery Type :
                      <div className='subtitle'>
                        {
                          caseInfo?.surgeryTypes?.map(data => {
                            return <span key={data.id}>{data.name}</span>
                          })
                        }
                      </div>
                    </div> : <></>

                  }

                  {caseInfo?.anesthesiaTypes?.length ?
                    <div className='title'>Anesthesia Type :
                      <div className='subtitle'>
                        {
                          caseInfo?.anesthesiaTypes?.map(data => {
                            return <span key={data.id}>{data.name}</span>
                          })
                        }
                      </div>
                    </div> :
                    <></>
                  }


                  {caseInfo?.medications?.length ?
                    <div className='title'>Medications :
                      <div className='subtitle'>
                        {
                          caseInfo?.medications?.map(data => {
                            return <span key={data.id}>{data.name}</span>
                          })
                        }
                      </div>
                    </div> : <></>
                  }

                  {caseInfo?.allergies?.length ?
                    <div className='title'>Allergies :
                      <div className='subtitle'>
                        {
                          caseInfo?.allergies?.map(data => {
                            return <span key={data.id}>{data.name}</span>
                          })
                        }
                      </div>
                    </div> : <></>
                  }

                  {caseInfo?.midical_histories?.length ?
                    <div className='title'>Medical History :
                      <div className='subtitle'>
                        {
                          caseInfo?.midical_histories?.map(data => {
                            return <span key={data.id}>{data.name}</span>
                          })
                        }
                      </div>
                    </div> : <></>
                  }

                  {caseInfo?.surgeryHistories?.length ?
                    <div className='title'>Surgical History :
                      <div className='subtitle'>
                        {
                          caseInfo?.surgeryHistories?.map(data => {
                            return <span key={data.id}>{data.name}</span>
                          })
                        }
                      </div>
                    </div> : <></>
                  }

                  {caseInfo?.anesthesia_histories?.length ?
                    <div className='title'>Anesthesia History :
                      <div className='subtitle'>
                        {
                          caseInfo?.anesthesia_histories?.map(data => {
                            return <span key={data.id}>{data.name}</span>
                          })
                        }
                      </div>
                    </div> : <></>
                  }


                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>

  </>)
}
export default Detailed;