import React, { useEffect, useState } from 'react';
import './RE1258.css';

const RE1258 = () => {
    const [ebl, setEbl] = useState(0);
    const [pressure, setPressure] = useState(0);
    const [rate, setRate] = useState(0);
    const [rate1, setRate1] = useState(0);


    const [result, setResult] = useState(0)
    const postData = async () => {

        if (rate === 0) {
            setRate1(0)
        }
        if (rate === 1) {
            setRate1(1)
        }
        if (rate === 2) {
            setRate1(2)
        }
        if (rate === 3) {
            setRate1(3)
        }
        if (rate === 4) {
            setRate1(4)
        }
        if (rate === 5) {
            setRate1(0)
        }
        setResult(ebl + pressure + rate1);
    }
    useEffect(() => {
        postData();
    }, [ebl, pressure, rate, rate1]);

    const output = () => {


        if ((result >= 0) && (result <= 2)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>Very high risk patient of poor outcome. 14% mortality rate, overall 75% risk of major complication including death.</div>
                    <br />
                    <div>In one follow-up study, patients with scores ≤4 were 97% specific for post-operative ICU admission if not initially admitted there.</div>
                </div>
            </div>
        }
        if ((result >= 3) && (result <= 4)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>Very high risk patient of poor outcome. 14% mortality rate, overall 56% risk of major complication including death.</div>
                    <br />
                    <div>In one follow-up study, patients with scores ≤4 were 97% specific for post-operative ICU admission if not initially admitted there.
                        .</div>
                </div>
            </div>
        }
        else if ((result >= 5) && (result <= 6)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>High risk patient of poor outcome. 4% mortality rate, overall 16% risk of major complication including death.</div>
                </div>
            </div>
        }
        else if ((result >= 7) && (result <= 8)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>Moderate risk patient of poor outcome. 1% mortality rate, overall 6% risk of major complication including death.</div>
                </div>
            </div>
        }
        else if ((result >= 9)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>Low risk patient of poor outcome. 0% mortality rate, overall &lt; 4% risk of major complication including death.</div>
                </div>
            </div>
        }

        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }
    return (
        <div className="rev">
            <h5 className="text-info pl-2">Surgical Apgar Score (SAS) for Postoperative Risk</h5>
            <p className="pl-2">Predicts postoperative risk of major complication, including death..</p>

            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Estimated blood loss (EBL)</strong></div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn">
                    <button style={{ height:40 }} onClick={() => setEbl(0)} className={ebl === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">&lt;1000 mL</span><span className="text-right pv text-muted">0</span></button>
                    <button style={{ height:40 }} onClick={() => setEbl(1)} className={ebl === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">601-1000 mL</span><span className="text-right pv text-muted">+1</span></button>
                    <button style={{ height:40 }} onClick={() => setEbl(2)} className={ebl === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">101-600 mL</span><span className="text-right pv text-muted"></span>+2</button>
                    <button style={{ height:40 }} onClick={() => setEbl(3)} className={ebl === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">≤100 mL</span><span className="text-right pv text-muted"></span>+3</button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Lowest mean arterial pressure
                    </strong></div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn">
                    <button style={{ height:40 }} onClick={() => setPressure(0)} className={pressure === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">
                        &lt;40 mmHg</span><span className="text-right pv text-muted"></span>0</button>
                    <button style={{ height:40 }} onClick={() => setPressure(1)} className={pressure === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">40-54 mmHg</span><span className="text-right pv text-muted"></span>+1</button>
                    <button style={{ height:40 }} onClick={() => setPressure(2)} className={pressure === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">55-69 mmHg</span><span className="text-right pv text-muted"></span>+2</button>
                    <button style={{ height:40 }} onClick={() => setPressure(3)} className={pressure === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">≥70 mmHg</span><span className="text-right pv text-muted"></span>+3</button>
                </div>
            </div>
            <div className="fields row mb-5">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Lowest heart rate
                    </strong></div>
                    <div>'Pathologic Bradyarrhythmia’ includes sinus arrest, AV (atrioventricular) block or dissociation, junctional or ventricular escape rhythm</div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn">
                    <button style={{ height:40 }} onClick={() => setRate(0)} className={rate === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">
                        &gt; 85 bpm</span><span className="text-right pv text-muted"></span>0</button>
                    <button style={{ height:40 }} onClick={() => setRate(1)} className={rate === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">76-85 bpm</span><span className="text-right pv text-muted"></span>+1</button>
                    <button style={{ height:40 }} onClick={() => setRate(2)} className={rate === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">66-75 bpm</span><span className="text-right pv text-muted"></span>+2</button>
                    <button style={{ height:40 }} onClick={() => setRate(3)} className={rate === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">56-65 bpm</span><span className="text-right pv text-muted"></span>+3</button>
                    <button style={{ height:40 }} onClick={() => setRate(4)} className={rate === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">≤55 bpm</span><span className="text-right pv text-muted"></span>+4</button>
                    <button style={{ height:60 }} onClick={() => setRate(5)} className={rate === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Pathologic bradyarrhythmia <br/>or asystole</span><span className="text-right pv text-muted"></span>0</button>
                </div>
            </div>

            <div className="stick pt-4">
                {
                    output()
                }
            </div>
            <div className="p-2 mb-4" >
                <div>
                    OTHER REFERENCES
                </div>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/17254923/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Gawande AA, Kwaan MR, Regenbogen SE, Lipsitz SA, Zinner MJ. An Apgar score for surgery. J Am Coll Surg. 2007 Feb;204(2):201-8. Epub 2006 Dec 27. PubMed PMID: 17254923.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default RE1258
