import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';
import { Link } from 'react-router-dom';
import Must from './Must';
import NRI from './NRI';
import NRS2002 from './NRS2002';
import NUTRIC from './NUTRIC';
const NutritionCalculators = (props) => {
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };
    useEffect(() => {
        toggleTab(1);
    }, [])

    return (
        <div>
            <div className="backNavigation text-center">
                <Link className="arrow" to={"/" + props.location.state.returnPath}><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to {props.location.state.sourceName}</span></span></Link> NUTRITION CALCULATORS
            </div>
            <div className="main-container calculator-container mt-3">
                <div className="row">
                    <div className="col-md-4 airway-calc-tabs">
                    <button className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(1)} >MUST</button>
                    <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(2)} >NRI</button>
                    <button className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(3)} >NRS-2002</button>
                    <button className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(4)} >NUTRIC</button>                
                    </div>
                    
                    <div className="col-md-8 col-sm-12">
                        <div className="content-tabs">
                            <div
                                className={toggleState === 1 ? "calculator-content  active-content" : "calculator-content"}>
                              <Must/></div>
                              <div
                                className={toggleState === 2 ? "calculator-content  active-content" : "calculator-content"}>
                              <NRI/></div>
                              <div
                                className={toggleState === 3 ? "calculator-content  active-content" : "calculator-content"}>
                              <NRS2002/></div>
                              <div
                                className={toggleState === 4 ? "calculator-content  active-content" : "calculator-content"}>
                              <NUTRIC/></div>
                            </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default NutritionCalculators;
