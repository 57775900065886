import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';


const Nvps = () => {
    const [green, setGreen] = useState(0);
    const [face, setFace] = useState(0);
    const [activity, setActivity] = useState(0);
    const [guarding, setGuarding] = useState(0);
    const [physiology, setPhysiology] = useState(0);
    const [respiratory, setRespiratory] = useState(0);
    const [result, setResult] = useState(0);

    const postData = async () => {

        setResult(face + activity + guarding + physiology + respiratory);

        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [face, activity, guarding, physiology, respiratory]);



    return (
        <div className="rev">
              <h4 className="text-info pl-2">Nonverbal Pain Scale (NVPS) for Nonverbal Patients</h4>
            <p className="pl-2">Quantifies pain in patients unable to speak (due to intubation, dementia, etc)..</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Face</strong></div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  40   }} onClick={() => setFace(0)} className={face === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">No particular expression or smile</span><span className="calc-val ">0</span></button>
                    <button style={{ height:  60   }} onClick={() => setFace(1)} className={face === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Occasional grimace, tearing, frowning,<br/> wrinkled forehead</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  60   }} onClick={() => setFace(2)} className={face === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Frequent grimace, tearing, frowning, <br/>wrinkled forehead</span><span className="calc-val ">+2</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Activity (movement)</strong></div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  40   }} onClick={() => setActivity(0)} className={activity === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Lying quietly, normal position</span><span className="calc-val ">0</span></button>
                    <button style={{ height:  60   }} onClick={() => setActivity(1)} className={activity === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Seeking attention through movement<br/> or slow, cautious movement</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  60   }} onClick={() => setActivity(2)} className={activity === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Restless, excessive activity <br/>and/or withdrawal reflexes</span><span className="calc-val ">+2</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Guarding</strong></div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  60   }} onClick={() => setGuarding(0)} className={guarding === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Lying quietly, no positioning of hands <br/>over areas of the body</span><span className="calc-val ">0</span></button>
                    <button style={{ height:  40   }} onClick={() => setGuarding(1)} className={guarding === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Splinting areas of the body, tense</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  40   }} onClick={() => setGuarding(2)} className={guarding === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Rigid, stiff</span><span className="calc-val ">+2</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Physiology(vital signs)</strong></div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  40   }} onClick={() => setPhysiology(0)} className={physiology === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Baseline vital signs unchanged</span><span className="calc-val ">0</span></button>
                    <button style={{ height:  40   }} onClick={() => setPhysiology(1)} className={physiology === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Change in SBP{">"}20 mmHg or HR{">"}20 bpm</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  40   }} onClick={() => setPhysiology(2)} className={physiology === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Change in SBP{">"}30 mmHg or HR{">"}25 bpm</span><span className="calc-val ">+2</span></button>
                </div>
            </div>
            <div className="fields row mb-5">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Respiratory</strong></div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  40   }} onClick={() => setRespiratory(0)} className={respiratory === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">
                        Baseline RR/SpO₂ synchronous with ventilator</span><span className="calc-val ">0</span></button>
                    <button style={{ height:  60   }} onClick={() => setRespiratory(1)} className={respiratory === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">RR {">"}10 bpm over baseline, 5% decrease SpO₂ or<br/> mild ventilator asynchrony</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  60   }} onClick={() => setRespiratory(2)} className={respiratory === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">RR {">"}20 bpm over baseline, 10% decrease SpO₂ or<br/> severe ventilator asynchrony</span><span className="calc-val ">+2</span></button>
                </div>
            </div>

            <div className="stick">
                {
                    green ?
                        <div className="apfel-score-result-container"  >
                            <div className="point-result-container col-12" >
                                <h1>{result} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                                <div> {result == 0 ? "No Pain" : " "}</div>
                                <div> {result == 1 ? "No Pain" : " "}</div>
                                <div> {result == 2 ? "No Pain" : " "}</div>
                                <div> {result == 3 ? "Moderate Pain" : " "}</div>
                                <div> {result == 4 ? "Moderate Pain" : " "}</div>
                                <div> {result == 5 ? "Moderate Pain" : " "}</div>
                                <div> {result == 6 ? "Moderate Pain" : " "}</div>
                                <div> {result == 7 ? "Severe Pain" : " "}</div>
                                <div> {result == 8 ? "Severe Pain" : " "}</div>
                                <div> {result == 9 ? "Severe Pain" : " "}</div>
                                <div> {result == 10 ? "Severe Pain" : " "}</div><br/>
                                <div>{result >= 3 && result <= 10 ? "Consider analgesia. Sepsis, hypovolemia, and hypoxia need to be resolved prior to interventions." : " "}</div>
                                {/* <div>Consider analgesia. Sepsis, hypovolemia, and hypoxia need to be resolved prior to interventions.</div> */}
                            </div>
                        </div>
                        : <div className="stick"><div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div></div>
                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/14639117/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >Odhner M, et al. Assessing pain control in nonverbal critically ill adults. Dimens Crit Care Nurs. 2003 Nov-Dec;22(6):260-7</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Nvps
