import React from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";


const Settings = (props) => {
    let history = useHistory();
    const gotoChaNGEpsw = () => {
        history.push('/changepassword')
    }
    const gotoTerms = () => {
        history.push('/termsandconditions')
    }
    const gotoPrivacy = () => {
        history.push('/privacypolicy')
    }
    const gotoAbout = () => {
        history.push('/about')
    }



    return (
        <div>
            <div className="backNavigation text-center">
                <Link className="arrow" to="/drawer"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Menu</span></span></Link>SETTINGS
            </div>

            <div className="col-md-12 pl-0 pr-2">

                <div className="conArea d-block">

                    <div className="row justify-content-center ">
                        <div className="col-md-9 savedCases1">
                            <div className="whtbxs1">
                                <ul>
                                    {/* <li>
                        <div className="float-left">
                            <h6>Notifications </h6>
                        </div>
                        <div className="float-right">
                            <div className="material-switch pull-right">
                                <input id="someSwitchOptionPrimary" name="someSwitchOption001" type="checkbox" />
                                <label for="someSwitchOptionPrimary" className="label-primary"></label>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </li> */}

                                    <li className="all-action-subcontainer-content" onClick={()=> gotoChaNGEpsw()}>Change Password</li>
                                    <li className="all-action-subcontainer-content" onClick={()=> gotoTerms()}>Terms  Conditions</li>

                                    {/* 
                    <li  onclick="location.href = 'Dark/Settings.html';">
                        <div className="float-left">
                            <h6>Colour Theme </h6>
                        </div>
                        <div className="float-right">
                            <p>White</p>
                        </div>
                        <div className="clearfix"></div>
                    </li> */}


                                    <li className="all-action-subcontainer-content" onClick={()=> gotoPrivacy()}>Privacy Policy</li>
                                    <li className="all-action-subcontainer-content" onClick={()=> gotoAbout()}>About us</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Settings