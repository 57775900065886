import React, { useEffect, useState } from 'react';
import './CAS1042.css';
const CAS1042 = () => {
    const [green, setGreen] = useState(false);

    const [vocalization, setVocalization] = useState(0);
    const [fexpression, setFexpression] = useState(0);
    const [bodylanguage, setBodylanguage] = useState(0);
    const [physiological, setPhysiological] = useState(0);
    const [physical, setPhysical] = useState(0);
    const [typeOfPain, setTypeOfPain] = useState(0);
    const [result, setResult] = useState(0);

    const postData = async () => {
        setResult(vocalization + fexpression + bodylanguage + physiological + physical);
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [vocalization, fexpression, bodylanguage, physiological, physical, result]);
    const changeresult = () => {

        if ((typeOfPain != 0) && (result > 2)) {
            if (result <= 2) {
                return <div>No {typeOfPain} pain</div>
            }
            else if (result > 2 && result < 8) {
                return <div>Mild &nbsp;{typeOfPain} pain</div>
            }
            else if (result > 7 && result < 14) {
                return <div>Moderate {typeOfPain} pain</div>
            }
            else if (result >= 14) {
                return <div>Severe {typeOfPain} pain</div>

            }
        }

        else {
            return <div>

                <div>{result <= 2 ? "No pain" : " "}</div>
                <div>{result > 2 && result < 8 ? "Mild pain" : " "}</div>
                <div>{result > 7 && result < 14 ? "Moderate pain" : " "}</div>
                <div>{result >= 14 ? "Severe pain" : " "}</div>
            </div>
        }

    }

    const afterPhysicalChange = () => {
        if (result >= 3) {
            return <div >
                <div className="alert alert-info">If score ≥3, use options for categorization and management of pain.</div>
                <div className="fields row">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Type of pain</strong></div>

                    </div>
                    <div className="col-md-7 btn-group-vertical options-btn">
                        <button style={{ height: 40 }} onClick={() => setTypeOfPain("Chronic")} className={typeOfPain === "Chronic" ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Chronic</span></button>
                        <button style={{ height: 40 }} onClick={() => setTypeOfPain("Acute")} className={typeOfPain === "Acute" ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Acute</span></button>
                        <button style={{ height: 40 }} onClick={() => setTypeOfPain("Acute on chronic")} className={typeOfPain === "Acute on chronic" ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Acute on chronic</span></button>
                    </div>
                </div>
            </div>
        }

    }


    return (
        <div className="rev">
            <h5 className="text-info pl-2">Abbey Pain Scale for Dementia Patients</h5>
            <p className="pl-2">Assesses pain in patients with advanced dementia..</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Vocalization</strong></div>
                    <div>Whimpering, crying</div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn">
                    <button style={{ height: 40 }} onClick={() => setVocalization(0)} className={vocalization === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Absent</span><span className="calc-val">0</span></button>
                    <button style={{ height: 40 }} onClick={() => setVocalization(1)} className={vocalization === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Mild</span><span className="calc-val">+1</span></button>
                    <button style={{ height: 40 }} onClick={() => setVocalization(2)} className={vocalization === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Moderate</span><span className="calc-val">+2</span></button>
                    <button style={{ height: 40 }} onClick={() => setVocalization(3)} className={vocalization === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Severe</span><span className="calc-val">+3</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Facial expression</strong></div>
                    <div>Tense, frowning, grimacing, frightened</div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn">
                    <button style={{ height: 40 }} onClick={() => setFexpression(0)} className={fexpression === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Absent</span><span className="calc-val">0</span></button>
                    <button style={{ height: 40 }} onClick={() => setFexpression(1)} className={fexpression === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Mild</span><span className="calc-val">+1</span></button>
                    <button style={{ height: 40 }} onClick={() => setFexpression(2)} className={fexpression === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Moderate</span><span className="calc-val">+2</span></button>
                    <button style={{ height: 40 }} onClick={() => setFexpression(3)} className={fexpression === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Severe</span><span className="calc-val">+3</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Body language</strong></div>
                    <div>Fidgeting, rocking, guarding, withdrawn</div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn">
                    <button style={{ height: 40 }} onClick={() => setBodylanguage(0)} className={bodylanguage === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Absent</span><span className="calc-val">0</span></button>
                    <button style={{ height: 40 }} onClick={() => setBodylanguage(1)} className={bodylanguage === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Mild</span><span className="calc-val">+1</span></button>
                    <button style={{ height: 40 }} onClick={() => setBodylanguage(2)} className={bodylanguage === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Moderate</span><span className="calc-val">+2</span></button>
                    <button style={{ height: 40 }} onClick={() => setBodylanguage(3)} className={bodylanguage === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Severe</span><span className="calc-val">+3</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Physiological changes</strong></div>
                    <div>Temp, pulse or BP elevations, diaphoresis, flushing/pallor</div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn">
                    <button style={{ height: 40 }} onClick={() => setPhysiological(0)} className={physiological === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Absent</span><span className="calc-val">0</span></button>
                    <button style={{ height: 40 }} onClick={() => setPhysiological(1)} className={physiological === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Mild</span><span className="calc-val">+1</span></button>
                    <button style={{ height: 40 }} onClick={() => setPhysiological(2)} className={physiological === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Moderate</span><span className="calc-val">+2</span></button>
                    <button style={{ height: 40 }} onClick={() => setPhysiological(3)} className={physiological === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Severe</span><span className="calc-val">+3</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Physical changes</strong></div>
                    <div>Skin tears, pressure sores, arthritis, contractures, previous injuries</div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn">
                    <button style={{ height: 40 }} onClick={() => setPhysical(0)} className={physical === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Absent</span><span className="calc-val">0</span></button>
                    <button style={{ height: 40 }} onClick={() => setPhysical(1)} className={physical === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Mild</span><span className="calc-val">+1</span></button>
                    <button style={{ height: 40 }} onClick={() => setPhysical(2)} className={physical === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Moderate</span><span className="calc-val">+2</span></button>
                    <button style={{ height: 40 }} onClick={() => setPhysical(3)} className={physical === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Severe</span><span className="calc-val">+3</span></button>
                </div>
            </div>
            <div> {afterPhysicalChange()}</div>

            <div className="stick mt-5" >
                {
                    green ? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container col-12" >
                                <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                                <div>{changeresult()}</div>

                            </div>






                        </div>



                    </>

                        :
                        <></>
                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/14966439/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >Abbey J, et al. The Abbey pain scale: a 1-minute numerical indicator for people with end-stage dementia. Int J Palliat Nurs. 2004 Jan;10(1):6-13</p>
                    </div>
                </a>
            </div>
        </div>
    )
}
export default CAS1042