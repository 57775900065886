import { type } from 'jquery';
import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';
import GuptaPneumoniaRisk from './GuptaPneumoniaRisk';

const GuptaFailureRisk = () => {
    const [green, setGreen] = useState(false);

    const [asa, setAsa] = useState(0);
    const [sepsis, setSepsis] = useState(0);
    const [result, setResult] = useState(0);
    const [result1, setResult1] = useState(0);
   
    const [smoking, setSmoking] = useState(0);
    const [typeOfProcedure, setTypeOfProcedure] = useState(10);
    const [functionalStatus, setFunctionalStatus] = useState(0);
    const postData = async () => {
        setResult(( asa + sepsis + smoking + functionalStatus + parseFloat(typeOfProcedure))- 1.7397);
        setResult1((((Math.pow( 2.7182818285 ,result )))/(1+ Math.pow( 2.7182818285,result )) *100).toFixed(1));

        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [asa, sepsis,functionalStatus,typeOfProcedure, smoking,result ,result1]);

    return (
        <div className="rev">
              <h5 className="text-info pl-2">Gupta Postoperative Respiratory Failure Risk</h5>
        <p className="pl-2">Predicts risk of mechanical ventilation for longer than 48 hrs postop or reintubation within 30 days.</p>
            
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Functional status</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button style={{ height:  40   }}onClick={() => setFunctionalStatus(0)} className={functionalStatus === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Independent</span></button>
                    <button style={{ height:  40   }}onClick={() => setFunctionalStatus(0.7653)} className={functionalStatus === 0.7653 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Partially dependent</span></button>
                    <button style={{ height:  40   }} onClick={() => setFunctionalStatus(1.4046)} className={functionalStatus === 1.4046 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Totally dependent</span></button>
                  
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>ASA class</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2  ">
                    <button style={{ height:  40   }} onClick={() => setAsa(-3.5265)} className={asa === -3.5265 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">1: normal healthy patient</span></button>
                    <button style={{ height:  40   }}onClick={() => setAsa(-2.0008)} className={asa === 	-2.0008 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">2: mild systemic disease</span></button>
                    <button style={{ height:  40   }} onClick={() => setAsa(-0.6201)} className={asa === -0.6201 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">3: severe systemic disease</span></button>
                    <button style={{ height:  90   }}  onClick={() => setAsa(0.2441)} className={asa === 0.2441 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name "  >4: severe systemic disease that<br/> is a constant threat to life (i.e., patient <br/>could die acutely without intervention)</span></button>
                    <button style={{ height:  40   }} onClick={() => setAsa(0)} className={asa === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">5: moribund, not expected to survive without surgery</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Sepsis</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button style={{ height:  40   }}onClick={() => setSepsis(-0.7840)} className={sepsis === -0.7840 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">None</span></button>
                    <button style={{ height:  60   }}onClick={() => setSepsis(0)} className={sepsis === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Preoperative systemic inflammatory <br/>response syndrome</span></button>
                    <button style={{ height:  40   }}onClick={() => setSepsis(0.2752)} className={sepsis === 0.2752 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Preoperative sepsis</span></button>
                    <button style={{ height:  40   }} onClick={() => setSepsis(0.9035)} className={sepsis === 0.9035 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Preoperative septic shock</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Smoking within last year</strong></div>

                </div>
                <div className="col-md-7 btn-group options-btn mb-2">
                    <button style={{ height:  40   }} onClick={() => setSmoking(-0.5739)} className={smoking === -0.5739? "btn   active" : "btn  "}><span className="btn-name ">No</span></button>
                    <button style={{ height:  40   }} onClick={() => setSmoking(0)} className={smoking === 0 ? "btn   active" : "btn "}><span className="btn-name ">yes</span></button>


                </div>
            </div>
            <div className="fields row w-100 mb-5">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Type of procedure</strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={typeOfProcedure}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setTypeOfProcedure(patient);
                        }}
                    >
                        <option value='0'>Choose One</option>
                        <option value='-1.3530'>Anorectal</option>
                        <option value='1.0781' >Aortic</option>
                        <option value='-1.0112' >Bariatric</option>
                        <option value='0.7336' >Brain</option>
                        <option value='-2.6462' >Breast</option>
                        <option value='0.2744' >Cardiac</option>
                        <option value='0.1060' >ENT(expect thyroid/Parathyroid)</option>
                        <option value='0.9694' >Foregut (esophagus,stomach) or hepatopancreatobiliary</option>
                        <option value='-0.5668' >Gallbladder,appendix,adrenals,spleen</option>
                        <option value='0' >Hernia(vental,inguinal,femoral)</option>
                        <option value='0.5737' >Intestinal</option>
                        <option value='-0.5271' >Neck(thyroid/parathyroid)</option>
                        <option value='-1.2431' >Obstetric/gynecologic</option>
                        <option value='-0.8577' >Orthopedic and non-vascularextrimity</option>
                        <option value='0.2416' >Other abdominal</option>
                        <option value='-0.2389' >Peripheral vascular</option>
                        <option value='-0.3206' >Skin</option>
                        <option value='-0.5220' >Spine</option>
                        <option value='0.6715' >Non-esophageal thoracic(lungs,mediastinum,etc)</option>
                        <option value='-2.0080' >Vein</option>
                        <option value='0.3093' >Urology</option>
                    </select>
                </div>
               
            </div>


            <div className="stick mt-5">
                {
                    typeOfProcedure != 10 ? <>
                        <div className="apfel-score-result-container col-12" >
                            <div className="point-result-container col-12" >
                                <h1>{result1}<span style={{ fontWeight: 600, fontSize: '1.5rem', color: 'silver' }}> %</span></h1>
                                <div>Risk of mechanical ventilation for {">"}48 hrs after surgery, or unplanned intubation ≤30 days of surgery</div>


                              



                            </div>






                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <div className="p-2 mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/21757571/">
            <div className="reference-container-card" >
                <div className="reference-img" >
                    <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                </div>
                <p className="reference-paragraph" >Gupta H, Gupta PK, Fang X, et al. Development and validation of a risk calculator predicting postoperative respiratory failure. Chest. 2011;140(5):1207-1215.</p>
            </div>
            </a>
          
            </div>
        </div>
    )
}


export default GuptaFailureRisk;

