import { data } from 'jquery';
import React, { useState, useEffect } from 'react'
import { ReactReduxContext } from 'react-redux';
import { API_ROOT } from '../constants';
import { Link } from 'react-router-dom';
import "../css/drugInfo.css"
import Spinner from '../CustomComponent/Spinner';

const DrugInfo = (props) => {

    const [resData, setResData] = useState([]);
    const [selectedId, setselectedId] = useState(null);
    const [drugInfo, setDrugInfo] = useState([]);
    useEffect(() => {
        setDrugInfo(props.location.state.drugInformation);
        runFunc(props.location.state.drugInformation[0].id);
    },[]);
    const runFunc = (id) => {
        setselectedId(id)
        setResData([]);
        var token = sessionStorage.getItem("token")
        fetch(API_ROOT + `drug-surgeries?token=${token}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "surgery_id": id
            })
        })
            .then(response => response.json())
            .then(res => {
                if (res.status === "true") {
                    setResData(res.data)
                }
            })
    }
    return (
        <div>
            <div className="backNavigation text-center">
                <Link className="arrow" onClick={(e) => { e.preventDefault(); props.history.goBack() }}><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to {props.location.state.sourceName}</span></span></Link>{props.location.state.name}
</div>
            <div className="main-container text-center">
                {
                    drugInfo.map((data, i) => {
                        return (
                            <>  <div className="mt-2">
                                <div className="all-action-subcontainer-content active" style={{ border: data.id === selectedId ? "1px solid #3F2579" : "1px solid #F2F2F2", cursor: "pointer", fontWeight: data.id === selectedId ?'bold':'normal' }} onClick={() => runFunc(data.id)} key={i} >{data.name}</div>
                            </div>
                            </>
                        )
                    })
                }
                <br></br>
                {
                    resData.length !== 0 ?
                        resData.map((data2, index) => {
                            return (
 
                                <>
                                    <table className="table table-striped surgery-table" style={{fontSize:14, width:'100%'}}>
                                        <thead>
                                            <tr border={1}>
                                                <th style={{ width:'33.33%'}}>First Line Antibiotics</th>
                                                <th style={{ width:'33.33%'}}>If mrsa colonization</th>
                                                <th style={{ width:'33.33%'}}>If severe Penicillin</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ width:'33.33%'}}>{data2.first_line_antibiotics?data2.first_line_antibiotics:'-'}</td>
                                                <td style={{ width:'33.33%'}}>{data2.if_mrsa_colonization?data2.if_mrsa_colonization:'-'}</td>
                                                <td style={{ width:'33.33%'}}>{data2.if_severe_penicillin?data2.if_severe_penicillin:'-'}</td>
                                            </tr>
                                            {/* <tr>
                                                <td><input style={{ height: 30, width: 200 }} placeholder="Enter value" className="ant-text-input" /></td>
                                                <td><input style={{ height: 30, width: 200 }} placeholder="Enter value" className="ant-text-input" /></td>
                                                <td><input style={{ height: 30, width: 200 }} placeholder="Enter value" className="ant-text-input" /></td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                    <div className="surgery-table-moble">
                                    <div className="card w-100 mt-2 p-2 " style={{textAlign:'left', border:'1px solid silver'}}>
                                    <div className="mb-2 mt-2"><strong>First Line Antibiotics:</strong></div>
                                    <div style={{ borderBottom:'1px solid silver'}}>{data2.first_line_antibiotics?data2.first_line_antibiotics:'-'}</div> 
                                    <div className="mb-2 mt-2"><strong>If mrsa colonization:</strong></div>
                                    <div style={{ borderBottom:'1px solid silver'}}>{data2.if_mrsa_colonization?data2.if_mrsa_colonization:'-'}</div> 
                                    <div className="mb-2 mt-2"><strong>If severe Penicillin:</strong></div>
                                    <div>{data2.if_severe_penicillin?data2.if_severe_penicillin:'-'}</div> 
                                    </div>
                                    </div>

                                </>
                            )
                        })
                        : <Spinner/>
                }
            </div>
        </div >
    )
}

export default DrugInfo
