import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';

const SOFA = () => {
    const [green, setGreen] = useState(false);
    const [mechinical, setMechinical] = useState(0);
    const [platelets, setPlatelets] = useState(0);

    

    const [tunit2, setTunit2] = useState('kPa');
    const [pao, setPao] = useState([]);
    const [placehldr2, setPlacehldr2] = useState('Norm: 10 - 13.3')
    const [newpao, setNewpao] = useState(0);


    const [fio2, setFio2] = useState(0);
    const [glasgow, setGlasgow] = useState(0);
    const [bilirubin, setBilirubin] = useState(0);
    const [meanPressure, setMeanPressure] = useState(0);
    const [creatinine, setCreatinine] = useState(0);

    const [result, setResult] = useState(0);
    const [result1, setResult1] = useState(0);
    const [pafi, setPafi] = useState(0);



    const postData = async () => {
        if (tunit2 === 'kPa') {
            setNewpao((pao * 7.501).toFixed(2));
        }
        if ((tunit2 === 'mm Hg')) {
            setNewpao(pao);
        }
      if(result >= 400){
        setPafi(0);
      }
      if(result > 299 && result<400){
        setPafi(1);
      }
      if(result > 199 && result<300){
        setPafi(2);
      }
      if(result <= 199 && mechinical === 1){
        setPafi(2);
      }
      if(result >99 && result <199 &&  mechinical === 2){
        setPafi(3);
      }
      if(result < 100 && mechinical === 2){
        setPafi(4);
      }
        setResult(newpao/(fio2 /100));
        setResult1(pafi + platelets +glasgow + bilirubin+ meanPressure +creatinine )
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [tunit2,pao,placehldr2,newpao,fio2,glasgow,bilirubin,meanPressure,creatinine, pafi, mechinical,platelets,result,result1]);

    
    const changeunit2 = () => {
        if (tunit2 === 'kPa') {
            setTunit2('mm Hg');
            setPlacehldr2("Norm: 75 - 100");
            if (pao.length !== 0) {
                setPao((pao * 7.501).toFixed(2));
            }
        }
        if (tunit2 === 'mm Hg') {
            setTunit2('kPa');
            setPlacehldr2("Norm: 10 - 13.3");
            if (pao.length !== 0) {
                setPao((pao / 7.501).toFixed(2));
            }
        }
    }
    const takeUnit2 = (e) => {
        if (tunit2 === 'kPa') {
            let htcm = e.target.value.trim();
            // setChnageht(htcm/2.54);
            setPao(htcm);
            setPlacehldr2("Norm: 10 - 13.33");
        }
        if (tunit2 === 'mm Hg') {
            let htin = e.target.value.trim();
            setPao(htin);
            setPlacehldr2("Norm: 75 - 100");
        }

    }

    return (
        <div className="rev">
               <h5 className="text-info pl-2">Sequential Organ Failure Assessment (SOFA) Score</h5>
        <p className="pl-2">Predicts ICU mortality based on lab results and clinical data.</p>

            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>PaO₂</strong></div>
                </div>
                <div className="col-md-7 frm-field">
                    <input onChange={takeUnit2}
                        placeholder={placehldr2} className="ant-text-input" style={{ height:40 }} value={pao} /><span className="units" onClick={changeunit2} style={{ height:40 }}>{tunit2}&#8651;</span>

                </div>
       
            </div>
            
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>FiO₂</strong></div>
                    <p>Use 21% for room air</p>

                </div>
                <div className="col-md-7 frm-field ">
                    <input style={{ height:40 }} className="ant-text-input " placeholder="Norm: 21" onChange={(e) => setFio2(e.target.value)} /><span style={{ height:40 }} className="units ">%</span>

                </div>
                

            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>On mechanical ventilation Including CPAP</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:40 }} onClick={() => setMechinical(1)} className={mechinical === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">No</span></button>
                    <button style={{ height:40 }} onClick={() => setMechinical(2)} className={mechinical === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Yes</span></button>
                   
                </div>
            </div>

            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Platelets, ×10³/µL</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2 ">
                    <button onClick={() => setPlatelets(0)} className={platelets === 0 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">≥150</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setPlatelets(1)} className={platelets === 1 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">100-149</span><span className="calc-val  ">+1</span></button>
                    <button onClick={() => setPlatelets(2)} className={platelets === 2 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">50-99</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setPlatelets(3)} className={platelets === 3 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">20-49</span><span className="calc-val ">+3</span></button>
                    <button onClick={() => setPlatelets(4)} className={platelets === 4 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name "> &lt;20</span><span className="calc-val ">+4</span></button>
                </div>
            </div>
           
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Glasgow Coma Scale If on sedatives, estimate assumed GCS off sedatives</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2 ">
                    <button onClick={() => setGlasgow(0)} className={glasgow === 0 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">15</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setGlasgow(1)} className={glasgow === 1 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">13-14</span><span className="calc-val  ">+1</span></button>
                    <button onClick={() => setGlasgow(2)} className={glasgow === 2 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">10-12</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setGlasgow(3)} className={glasgow === 3 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">6-9</span><span className="calc-val ">+3</span></button>
                    <button onClick={() => setGlasgow(4)} className={glasgow === 4 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name "> &lt;6</span><span className="calc-val ">+4</span></button>
                </div>
            </div>
            
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Bilirubin, mg/dL (μmol/L)</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2 ">
                    <button onClick={() => setBilirubin(0)} className={bilirubin === 0 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">&lt;1.2 (&lt;20)</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setBilirubin(1)} className={bilirubin === 1 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">1.2–1.9 (20-32)</span><span className="calc-val  ">+1</span></button>
                    <button onClick={() => setBilirubin(2)} className={bilirubin === 2 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">2.0–5.9 (33-101)</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setBilirubin(3)} className={bilirubin === 3 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">6.0–11.9 (102-204)</span><span className="calc-val ">+3</span></button>
                    <button onClick={() => setBilirubin(4)} className={bilirubin === 4 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name "> ≥12.0 (&gt;204)</span><span className="calc-val ">+4</span></button>
                </div>
            </div>
            
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Mean arterial pressure OR administration of vasoactive agents required Listed doses are in units of mcg/kg/min</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2 ">
                    <button onClick={() => setMeanPressure(0)} className={meanPressure === 0 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">No hypotension</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setMeanPressure(1)} className={meanPressure === 1 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">MAP &lt;70 mmHg</span><span className="calc-val  ">+1</span></button>
                    <button onClick={() => setMeanPressure(2)} className={meanPressure === 2 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">DOPamine ≤5 or DOBUTamine (any dose)</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setMeanPressure(3)} className={meanPressure === 3 ? "btn  text-left active" : "btn  text-left"} style={{ height:60 }}><span className="btn-name ">DOPamine &gt;5, EPINEPHrine ≤0.1, or <br/>norEPINEPHrine ≤0.1</span><span className="calc-val ">+3</span></button>
                    <button onClick={() => setMeanPressure(4)} className={meanPressure === 4 ? "btn  text-left active" : "btn  text-left"} style={{ height:60 }}><span className="btn-name ">DOPamine &gt;15, EPINEPHrine &gt;0.1,<br/> or norEPINEPHrine &gt;0.1</span><span className="calc-val ">+4</span></button>
                </div>
            </div>
            
            <div className="fields row mb-5">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Creatinine, mg/dL (μmol/L) (or urine output)</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2 ">
                    <button onClick={() => setCreatinine(0)} className={creatinine === 0 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">&lt;1.2 (&lt;110)</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setCreatinine(1)} className={creatinine === 1 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">1.2–1.9 (110-170)</span><span className="calc-val  ">+1</span></button>
                    <button onClick={() => setCreatinine(2)} className={creatinine === 2 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">2.0–3.4 (171-299)</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setCreatinine(3)} className={creatinine === 3 ? "btn  text-left active" : "btn  text-left"} style={{ height:60 }}><span className="btn-name ">3.5–4.9 (300-440) <br/>or UOP &lt;500 mL/day</span><span className="calc-val ">+3</span></button>
                    <button onClick={() => setCreatinine(4)} className={creatinine === 4 ? "btn  text-left active" : "btn  text-left"} style={{ height:40 }}><span className="btn-name ">≥5.0 (&gt;440) or UOP &lt;200 mL/day</span><span className="calc-val ">+4</span></button>
                </div>
            </div>

            <div className="stick">
                {
                    fio2 != 0 ? <>
                        <div className="apfel-score-result-container col-12 " >
                            <div className="point-result-container col-12" >
                                <h1>{result1} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>

                                     <div>{result1 <= 9  ? "Initial SOFA Scores ≤9 predict ≤33.3% mortality " : " "}</div>
                                    <div>{result1 == 10 || result1== 11 ? "Initial SOFA Scores of 10-11 predict 50.0% mortality" : " "}</div>
                                    <div>{result1 >= 12  ? "Initial SOFA Scores ≥12 predict ≥95.2% mortality " : " "}</div>
                                

                               



                            </div>






                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <div className="p-2 mb-5" >
            <h4>LITERATURE</h4>
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a href="" target="_blank" className="referenceLink">
            <div className="reference-container-card" >
                <div className="reference-img" >
                    <img src="https://pubmed.ncbi.nlm.nih.gov/8844239/" alt="Research Paper" />

                </div>
                <p className="reference-paragraph" >Vincent JL, Moreno R, Takala J, et al. The SOFA (Sepsis-related Organ Failure Assessment) score to describe organ dysfunction/failure. On behalf of the Working Group on Sepsis-Related Problems of the European Society of Intensive Care Medicine. In: Vol 22. 1996:707–710.</p>
            </div>
            </a>
        </div>
        </div>
    )
}


export default SOFA;

