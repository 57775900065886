import React, { useEffect, useState } from 'react';
import './FAT1172.css'

const FAT1172 = () => {
    const [green, setGreen] = useState(false);
    const [number1, setNumber1] = useState([]);
    const [total1, settotal1] = useState("")
    const [total2, settotal2] = useState("")

    const postData = async () => {

        if (number1 === "PRB") {
            settotal1("10mL/kg")
            settotal2("Hct ↑ 3-7%")
        }
        if (number1 === "Who") {
            settotal1("10mL/kg")
            settotal2("Hct ↑ 2-4%")
        }
        if (number1 === "FF") {
            settotal1("10mL/kg")
            settotal2("Coag fector ↑ 15-30%")
        }
        if (number1 === "Cry") {
            settotal1("1-2mL/kg")
            settotal2("Fibrinogen ↑ 50-100 mg/dl")
        }
        if (number1 === "pla") {
            settotal1("5mL/kg")
            settotal2("Platelets ↑ 50-100k")
        }
        if (number1 === "fec") {
            settotal1("90mcg/kg")
            settotal2(" ")
        }
        if (number1 === "fect") {
            settotal1("1unit/kg")
            settotal2("plasma level ↑ 2%")
        }

        setGreen(true);
    }

    useEffect(() => {
        postData();

    }, [number1])

    return (<div className="rev">
        <h5 className="text-info pl-2">Blood products and expected response</h5>
        <div className="fields row w-100">
            <div className="col-md-4">
                <div className="pt-2"><strong>Blood Products</strong>
                </div>
            </div>
            <div className="col-md-8">
                <select className="custom-select"
                    value={number1}
                    onChange={(e) => {
                        const patient = e.target.value;
                        setNumber1(patient);
                    }}>

                    <option value="" onClick={(e) => { setNumber1(e.target.value) }}>Select</option>
                    <option value="PRB">PRBCs</option>
                    <option value="Who" >Whole blood</option>
                    <option value="FF">FFP</option>
                    <option value="Cry">Cryoprecipitate</option>
                    <option value="pla">Platelets</option>
                    <option value="fec">Factor VII</option>
                    <option value="fect">Factor VIII</option>




                </select>
            </div>

        </div>
        <br />
        <div>
            {
                (number1.length !== 0 && total1 && total2) ?

                    <div className="apfel-score-result-container" >

                        <div className="point-result-container" >
                            <h4>Dose: <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}> {total1}</span></h4>
                        </div>
                        <div className="point-result-container" >
                            <h4>Expected Result:  <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>{total2}</span></h4>
                        </div>
                    </div>
                    :
                    <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>
            }
        </div>
    </div>
    )
}

export default FAT1172