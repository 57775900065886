import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';

const BishopScore = () => {
    const [green, setGreen] = useState(false);
  
    const [dilation, setDilation] = useState(null);
    const [effacement, setEffacement] = useState(null);
    const [station, setStation] = useState(null);
    const [position, setPosition] = useState(null);
    const [consistency, setConsistency] = useState(null);




    const [result, setResult] = useState(0);

    const postData = async () => {
        setResult(dilation+effacement+station+position+consistency);
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [dilation,effacement,station,position,consistency,result]);

    return (
        <div className="rev">
             <h5 className="text-info pl-2">Bishop Score for Vaginal Delivery and Induction of Labor</h5>
            <p className="pl-2">EPredicts likelihood of successful vaginal delivery.</p>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Dilation</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn mb-2">
                    <button onClick={() => setDilation(0)} className={dilation === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Closed</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setDilation(1)} className={dilation === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">1-2 cm</span><span className="calc-val ">+1</span></button>
                    <button onClick={() => setDilation(2)} className={dilation === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">3-4 cm</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setDilation(3)} className={dilation === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">≥5 cm</span><span className="calc-val ">+3</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Effacement</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn mb-2 ">
                    <button onClick={() => setEffacement(0)} className={effacement === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">0-30%</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setEffacement(1)} className={effacement === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">40-50%</span><span className="calc-val  ">+1</span></button>
                    <button onClick={() => setEffacement(2)} className={effacement === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">60-70%</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setEffacement(3)} className={effacement === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">≥80%</span><span className="calc-val ">+3</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Station</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn mb-2 ">
                    <button onClick={() => setStation(0)} className={station === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">-3</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setStation(1)} className={station === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">-2</span><span className="calc-val  ">+1</span></button>
                    <button onClick={() => setStation(2)} className={station === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">-1, 0</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setStation(3)} className={station === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">+1, +2</span><span className="calc-val ">+3</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Position</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn mb-2 ">
                    <button onClick={() => setPosition(0)} className={position === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Posterior</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setPosition(1)} className={position === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Mid-position</span><span className="calc-val  ">+1</span></button>
                    <button onClick={() => setPosition(2)} className={position === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Anterior</span><span className="calc-val ">+2</span></button>
                   
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Consistency</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn mb-2 ">
                    <button onClick={() => setConsistency(0)} className={consistency === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Firm</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setConsistency(1)} className={consistency === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moderately firm</span><span className="calc-val  ">+1</span></button>
                    <button onClick={() => setConsistency(2)} className={consistency === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Soft</span><span className="calc-val ">+2</span></button>
                   
                </div>
            </div>


            <div className="stick pt-3">
                {
                   dilation != null  && effacement != null && station != null && position != null && consistency != null ? <>
                        <div className="apfel-score-result-container col-12 " >
                            <div className="point-result-container col-12" >
                                <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>

                              
                                    <div>{result < 6 && result >=0 ? "Scores ≤ 5 suggest an unfavorable cervix, and that induction may be necessary for successful vaginal delivery." : " "}</div>
                                    <div>{result > 5 && result < 8 ? "Scores 6-7 do not definitively predict whether or not induction will be successful. Consider induction or augmentation of labor based on clinical judgment." : " "}</div>
                                    <div>{result >= 8  ? "Scores ≥ 8 suggest spontaneous vaginal delivery is more likely and augmentation or induction may be unnecessary." : " "}</div>
                                    &nbsp;
                                   <div>Note: Success rates tend to vary based on indication for induction and weeks gestation.</div>
                                    


                           
                            </div>






                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/14199536/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Bishop EH. Pelvic scoring for elective induction. Obstet Gynecol. 1964;24: 266–8. PMID 14199536.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}


export default BishopScore;

