const constantObject = {
    HYPERTENSION: {
        "id": 61,
        "user_fav_table": "uf_crises",
        "foreign_key": "crises_id",
        "name": "HYPERTENSION",
        "parent_id": 60,
        "parent_name": "BROAD DIAGNOSIS",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "crises",
        "cp_code": "BD_1314",
        "children": [
            {
                "id": 643,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Differential Diagnosis",
                "parent_id": 61,
                "parent_name": "HYPERTENSION",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "C1277",
                "children": [
                    {
                        "id": 645,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Broad Diagnosis",
                        "parent_id": 643,
                        "parent_name": "Differential Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 646,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Anesthesia Related",
                                "parent_id": 645,
                                "parent_name": "Broad Diagnosis",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 654,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Emergence ",
                                        "parent_id": 646,
                                        "parent_name": "Anesthesia Related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 655,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Hypercarbia ",
                                        "parent_id": 646,
                                        "parent_name": "Anesthesia Related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 656,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Hypoxia ",
                                        "parent_id": 646,
                                        "parent_name": "Anesthesia Related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 657,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Improper transducer height or cuff ",
                                        "parent_id": 646,
                                        "parent_name": "Anesthesia Related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 658,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Inadequate analgesia  ",
                                        "parent_id": 646,
                                        "parent_name": "Anesthesia Related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 659,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Inadequate depth of anesthesia ",
                                        "parent_id": 646,
                                        "parent_name": "Anesthesia Related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 660,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Malignant hyperthermia ",
                                        "parent_id": 646,
                                        "parent_name": "Anesthesia Related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 661,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Medications ",
                                        "parent_id": 646,
                                        "parent_name": "Anesthesia Related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 648,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Patient Comorbidities",
                                "parent_id": 645,
                                "parent_name": "Broad Diagnosis",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 666,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "History of hypertension ",
                                        "parent_id": 648,
                                        "parent_name": "Patient Comorbidities",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 667,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Intracranial pressure elevation ",
                                        "parent_id": 648,
                                        "parent_name": "Patient Comorbidities",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 668,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Pheochromocytoma ",
                                        "parent_id": 648,
                                        "parent_name": "Patient Comorbidities",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 669,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Pre-eclampsia ",
                                        "parent_id": 648,
                                        "parent_name": "Patient Comorbidities",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 670,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Renal diseases ",
                                        "parent_id": 648,
                                        "parent_name": "Patient Comorbidities",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 671,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Thyroid storm ",
                                        "parent_id": 648,
                                        "parent_name": "Patient Comorbidities",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 672,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Urinary retention ",
                                        "parent_id": 648,
                                        "parent_name": "Patient Comorbidities",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 647,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Surgery Related",
                                "parent_id": 645,
                                "parent_name": "Broad Diagnosis",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 662,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Aortic or major artery clamping ",
                                        "parent_id": 647,
                                        "parent_name": "Surgery Related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 663,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Baroreceptor stimulation ",
                                        "parent_id": 647,
                                        "parent_name": "Surgery Related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 664,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Carotid retraction or manipulation - carotid endarterectomy ",
                                        "parent_id": 647,
                                        "parent_name": "Surgery Related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 665,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Tourniquet",
                                        "parent_id": 647,
                                        "parent_name": "Surgery Related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "id": 644,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Most Common Causes",
                        "parent_id": 643,
                        "parent_name": "Differential Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 649,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "History of hypertension ",
                                "parent_id": 644,
                                "parent_name": "Most Common Causes",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 650,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Inadequate anesthesia depth ",
                                "parent_id": 644,
                                "parent_name": "Most Common Causes",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 651,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Intubation or emergence from anesthesia ",
                                "parent_id": 644,
                                "parent_name": "Most Common Causes",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 652,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Medications ",
                                "parent_id": 644,
                                "parent_name": "Most Common Causes",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 653,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Patient in pain ",
                                "parent_id": 644,
                                "parent_name": "Most Common Causes",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    }
                ]
            }
        ]
    },
    HYPOTENSION: {
        "id": 62,
        "user_fav_table": "uf_crises",
        "foreign_key": "crises_id",
        "name": "HYPOTENSION",
        "parent_id": 60,
        "parent_name": "BROAD DIAGNOSIS",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "crises",
        "cp_code": "BD_1315",
        "children": [
            {
                "id": 673,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Confirm Diagnosis",
                "parent_id": 62,
                "parent_name": "HYPOTENSION",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "C1278",
                "children": [
                    {
                        "id": 677,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "CV",
                        "parent_id": 673,
                        "parent_name": "Confirm Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 679,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Check pulse",
                                "parent_id": 677,
                                "parent_name": "CV",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 680,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Check rhythm",
                                "parent_id": 677,
                                "parent_name": "CV",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 681,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Verify that the BP measurement is accurate",
                                "parent_id": 677,
                                "parent_name": "CV",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 682,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Check correlation of A-line and BP cuff measurements",
                                        "parent_id": 681,
                                        "parent_name": "Verify that the BP measurement is accurate",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "id": 678,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Fluid status",
                        "parent_id": 673,
                        "parent_name": "Confirm Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 683,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Verify blood loss from surgical site",
                                "parent_id": 678,
                                "parent_name": "Fluid status",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 676,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Signs & Symptoms:",
                        "parent_id": 673,
                        "parent_name": "Confirm Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null
                    }
                ]
            },
            {
                "id": 674,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Differential Diagnosis",
                "parent_id": 62,
                "parent_name": "HYPOTENSION",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "C1279",
                "children": [
                    {
                        "id": 685,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Broad Diagnosis",
                        "parent_id": 674,
                        "parent_name": "Differential Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 691,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Decreased contractility",
                                "parent_id": 685,
                                "parent_name": "Broad Diagnosis",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 702,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Arrhythmia ",
                                        "parent_id": 691,
                                        "parent_name": "Decreased contractility",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 703,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Cardiomyopathy ",
                                        "parent_id": 691,
                                        "parent_name": "Decreased contractility",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 704,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Compromised valve function ",
                                        "parent_id": 691,
                                        "parent_name": "Decreased contractility",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 705,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Medications: also volatile anesthetic  ",
                                        "parent_id": 691,
                                        "parent_name": "Decreased contractility",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 706,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "MI ",
                                        "parent_id": 691,
                                        "parent_name": "Decreased contractility",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 707,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Myocarditis ",
                                        "parent_id": 691,
                                        "parent_name": "Decreased contractility",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 708,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Sudden elevation in afterload ",
                                        "parent_id": 691,
                                        "parent_name": "Decreased contractility",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 709,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Vagal stimulation ",
                                        "parent_id": 691,
                                        "parent_name": "Decreased contractility",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 690,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Preload related",
                                "parent_id": 685,
                                "parent_name": "Broad Diagnosis",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 694,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Blood loss, significant ",
                                        "parent_id": 690,
                                        "parent_name": "Preload related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 695,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Capillary leak ",
                                        "parent_id": 690,
                                        "parent_name": "Preload related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 696,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Decreased/obstructed venous return ",
                                        "parent_id": 690,
                                        "parent_name": "Preload related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 697,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Dehydration ",
                                        "parent_id": 690,
                                        "parent_name": "Preload related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 698,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Embolism: air, amniotic fluid, fat, pulmonary ",
                                        "parent_id": 690,
                                        "parent_name": "Preload related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 699,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "High intrathoracic pressure ",
                                        "parent_id": 690,
                                        "parent_name": "Preload related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 700,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Patient position ",
                                        "parent_id": 690,
                                        "parent_name": "Preload related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 701,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Tamponade ",
                                        "parent_id": 690,
                                        "parent_name": "Preload related",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 693,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Pulmonary causes",
                                "parent_id": 685,
                                "parent_name": "Broad Diagnosis",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 725,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Auto-PEEP ",
                                        "parent_id": 693,
                                        "parent_name": "Pulmonary causes",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 729,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Embolism",
                                        "parent_id": 693,
                                        "parent_name": "Pulmonary causes",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 728,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Hyperventilation ",
                                        "parent_id": 693,
                                        "parent_name": "Pulmonary causes",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 726,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Pneumothorax ",
                                        "parent_id": 693,
                                        "parent_name": "Pulmonary causes",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 727,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Pulmonary edema ",
                                        "parent_id": 693,
                                        "parent_name": "Pulmonary causes",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 692,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Systemic vasodilation",
                                "parent_id": 685,
                                "parent_name": "Broad Diagnosis",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 710,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Addison's disease ",
                                        "parent_id": 692,
                                        "parent_name": "Systemic vasodilation",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 711,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Anaphylaxis ",
                                        "parent_id": 692,
                                        "parent_name": "Systemic vasodilation",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 712,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Bone cement migration to vascular areas ",
                                        "parent_id": 692,
                                        "parent_name": "Systemic vasodilation",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 713,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Medication related:  ",
                                        "parent_id": 692,
                                        "parent_name": "Systemic vasodilation",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null,
                                        "children": [
                                            {
                                                "id": 719,
                                                "user_fav_table": "uf_crises",
                                                "foreign_key": "crises_id",
                                                "name": "Anesthetics ",
                                                "parent_id": 713,
                                                "parent_name": "Medication related:  ",
                                                "status": "Active",
                                                "is_star": "false",
                                                "user_fav": null,
                                                "cp_table": "crises",
                                                "cp_code": null
                                            },
                                            {
                                                "id": 720,
                                                "user_fav_table": "uf_crises",
                                                "foreign_key": "crises_id",
                                                "name": "LAST ",
                                                "parent_id": 713,
                                                "parent_name": "Medication related:  ",
                                                "status": "Active",
                                                "is_star": "false",
                                                "user_fav": null,
                                                "cp_table": "crises",
                                                "cp_code": null
                                            },
                                            {
                                                "id": 721,
                                                "user_fav_table": "uf_crises",
                                                "foreign_key": "crises_id",
                                                "name": "Narcotics ",
                                                "parent_id": 713,
                                                "parent_name": "Medication related:  ",
                                                "status": "Active",
                                                "is_star": "false",
                                                "user_fav": null,
                                                "cp_table": "crises",
                                                "cp_code": null
                                            },
                                            {
                                                "id": 724,
                                                "user_fav_table": "uf_crises",
                                                "foreign_key": "crises_id",
                                                "name": "Regional block ",
                                                "parent_id": 713,
                                                "parent_name": "Medication related:  ",
                                                "status": "Active",
                                                "is_star": "false",
                                                "user_fav": null,
                                                "cp_table": "crises",
                                                "cp_code": null
                                            },
                                            {
                                                "id": 722,
                                                "user_fav_table": "uf_crises",
                                                "foreign_key": "crises_id",
                                                "name": "Transfusion reaction ",
                                                "parent_id": 713,
                                                "parent_name": "Medication related:  ",
                                                "status": "Active",
                                                "is_star": "false",
                                                "user_fav": null,
                                                "cp_table": "crises",
                                                "cp_code": null
                                            },
                                            {
                                                "id": 723,
                                                "user_fav_table": "uf_crises",
                                                "foreign_key": "crises_id",
                                                "name": "Vasodilator ",
                                                "parent_id": 713,
                                                "parent_name": "Medication related:  ",
                                                "status": "Active",
                                                "is_star": "false",
                                                "user_fav": null,
                                                "cp_table": "crises",
                                                "cp_code": null
                                            }
                                        ]
                                    },
                                    {
                                        "id": 714,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Neuropathy ",
                                        "parent_id": 692,
                                        "parent_name": "Systemic vasodilation",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 715,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Post cardiopulmonary bypass ",
                                        "parent_id": 692,
                                        "parent_name": "Systemic vasodilation",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 716,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Sepsis ",
                                        "parent_id": 692,
                                        "parent_name": "Systemic vasodilation",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 717,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Thyroid disease ",
                                        "parent_id": 692,
                                        "parent_name": "Systemic vasodilation",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 718,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Tourniquet release ",
                                        "parent_id": 692,
                                        "parent_name": "Systemic vasodilation",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "id": 684,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Most Common Causes",
                        "parent_id": 674,
                        "parent_name": "Differential Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 686,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Anesthetic agent ",
                                "parent_id": 684,
                                "parent_name": "Most Common Causes",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 687,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Hypovolemia ",
                                "parent_id": 684,
                                "parent_name": "Most Common Causes",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 688,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Narcotic agents ",
                                "parent_id": 684,
                                "parent_name": "Most Common Causes",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 689,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Regional anesthesia nerve block ",
                                "parent_id": 684,
                                "parent_name": "Most Common Causes",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    }
                ]
            },
            {
                "id": 675,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Treatment",
                "parent_id": 62,
                "parent_name": "HYPOTENSION",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "C1280",
                "children": [
                    {
                        "id": 730,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "COMMUNICATE",
                        "parent_id": 675,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 733,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Communicate the issue with the OR team",
                                "parent_id": 730,
                                "parent_name": "COMMUNICATE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 732,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Consider",
                        "parent_id": 675,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 747,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "A-line, foley for close fluid status monitoring ",
                                "parent_id": 732,
                                "parent_name": "Consider",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 748,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Inserting two large bore IV's ",
                                "parent_id": 732,
                                "parent_name": "Consider",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 749,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Pressors ",
                                "parent_id": 732,
                                "parent_name": "Consider",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 750,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "TEE  ",
                                "parent_id": 732,
                                "parent_name": "Consider",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 751,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Trendelenburg position and leg elevation ",
                                "parent_id": 732,
                                "parent_name": "Consider",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 731,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "GIVE / START",
                        "parent_id": 675,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 734,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "100% O2, high flow",
                                "parent_id": 731,
                                "parent_name": "GIVE / START",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 736,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Consider decreasing the anesthetic dose",
                                "parent_id": 731,
                                "parent_name": "GIVE / START",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 737,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Consider starting pressors",
                                "parent_id": 731,
                                "parent_name": "GIVE / START",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 740,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Ephedrine",
                                        "parent_id": 737,
                                        "parent_name": "Consider starting pressors",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 741,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Epinephrine",
                                        "parent_id": 737,
                                        "parent_name": "Consider starting pressors",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null,
                                        "children": [
                                            {
                                                "id": 743,
                                                "user_fav_table": "uf_crises",
                                                "foreign_key": "crises_id",
                                                "name": "10-100 mcg",
                                                "parent_id": 741,
                                                "parent_name": "Epinephrine",
                                                "status": "Active",
                                                "is_star": "false",
                                                "user_fav": null,
                                                "cp_table": "crises",
                                                "cp_code": null
                                            }
                                        ]
                                    },
                                    {
                                        "id": 739,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Phenylephrine",
                                        "parent_id": 737,
                                        "parent_name": "Consider starting pressors",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 742,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Vasopressin",
                                        "parent_id": 737,
                                        "parent_name": "Consider starting pressors",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null,
                                        "children": [
                                            {
                                                "id": 744,
                                                "user_fav_table": "uf_crises",
                                                "foreign_key": "crises_id",
                                                "name": "1-4 unites",
                                                "parent_id": 742,
                                                "parent_name": "Vasopressin",
                                                "status": "Active",
                                                "is_star": "false",
                                                "user_fav": null,
                                                "cp_table": "crises",
                                                "cp_code": null
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "id": 738,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "If blood loss significant",
                                "parent_id": 731,
                                "parent_name": "GIVE / START",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 746,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Check labs",
                                        "parent_id": 738,
                                        "parent_name": "If blood loss significant",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 745,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Consider transfusion",
                                        "parent_id": 738,
                                        "parent_name": "If blood loss significant",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 735,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "IV fluid bolus",
                                "parent_id": 731,
                                "parent_name": "GIVE / START",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    }
                ]
            }
        ]
    },
    TACHYCARDIA: {
        "id": 63,
        "user_fav_table": "uf_crises",
        "foreign_key": "crises_id",
        "name": "TACHYCARDIA",
        "parent_id": 60,
        "parent_name": "BROAD DIAGNOSIS",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "crises",
        "cp_code": "BD_1316",
        "children": [
            {
                "id": 752,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Confirm Diagnosis",
                "parent_id": 63,
                "parent_name": "TACHYCARDIA",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "C1281"
            },
            {
                "id": 753,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Confirm Diagnosis",
                "parent_id": 63,
                "parent_name": "TACHYCARDIA",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": null,
                "children": [
                    {
                        "id": 755,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "CV",
                        "parent_id": 753,
                        "parent_name": "Confirm Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null
                    },
                    {
                        "id": 754,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Signs & Symptoms:",
                        "parent_id": 753,
                        "parent_name": "Confirm Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null
                    }
                ]
            }
        ]
    },
    AFE: {
        "id": 22,
        "user_fav_table": "uf_crises",
        "foreign_key": "crises_id",
        "name": "AFE",
        "parent_id": 21,
        "parent_name": "CRITICAL EVENTS",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "crises",
        "cp_code": "CE_1317",
        "children": [
            {
                "id": 814,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Confirm Diagnosis",
                "parent_id": 22,
                "parent_name": "AFE",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "C1282",
                "children": [
                    {
                        "id": 817,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Consider amniotic fluid embolism if there is the sudden onset of the following in a pregnant or post-partum patient:",
                        "parent_id": 814,
                        "parent_name": "Confirm Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 822,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Altered mental status",
                                "parent_id": 817,
                                "parent_name": "Consider amniotic fluid embolism if there is the sudden onset of the following in a pregnant or post-partum patient:",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 819,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Cardiovascular collapse: hypotension, tachycardia, arrhythmias, cardiac arrest",
                                "parent_id": 817,
                                "parent_name": "Consider amniotic fluid embolism if there is the sudden onset of the following in a pregnant or post-partum patient:",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 820,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Coagulopathy +/- Disseminated intravascular coagulation (DIC)",
                                "parent_id": 817,
                                "parent_name": "Consider amniotic fluid embolism if there is the sudden onset of the following in a pregnant or post-partum patient:",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 818,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Respiratory distress, decreased O2 saturation",
                                "parent_id": 817,
                                "parent_name": "Consider amniotic fluid embolism if there is the sudden onset of the following in a pregnant or post-partum patient:",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 821,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Seizures",
                                "parent_id": 817,
                                "parent_name": "Consider amniotic fluid embolism if there is the sudden onset of the following in a pregnant or post-partum patient:",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 823,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Unexplained fetal compromise",
                                "parent_id": 817,
                                "parent_name": "Consider amniotic fluid embolism if there is the sudden onset of the following in a pregnant or post-partum patient:",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    }
                ]
            },
            {
                "id": 815,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Differential Diagnosis",
                "parent_id": 22,
                "parent_name": "AFE",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "C1283",
                "children": [
                    {
                        "id": 824,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "RULE OUT",
                        "parent_id": 815,
                        "parent_name": "Differential Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 827,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Air embolism",
                                "parent_id": 824,
                                "parent_name": "RULE OUT",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 829,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Anaphylaxis",
                                "parent_id": 824,
                                "parent_name": "RULE OUT",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 831,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Anesthetic overdose",
                                "parent_id": 824,
                                "parent_name": "RULE OUT",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 828,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Aspiration",
                                "parent_id": 824,
                                "parent_name": "RULE OUT",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 833,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Cardiomyopathy/cardiac valvular abnormality/MI",
                                "parent_id": 824,
                                "parent_name": "RULE OUT",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 825,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Eclampsia",
                                "parent_id": 824,
                                "parent_name": "RULE OUT",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 826,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Hemorrhage",
                                "parent_id": 824,
                                "parent_name": "RULE OUT",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 834,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Local Anesthetic Systemic Toxicity",
                                "parent_id": 824,
                                "parent_name": "RULE OUT",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 830,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Pulmonary embolism",
                                "parent_id": 824,
                                "parent_name": "RULE OUT",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 832,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Sepsis",
                                "parent_id": 824,
                                "parent_name": "RULE OUT",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 835,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Total Spinal",
                                "parent_id": 824,
                                "parent_name": "RULE OUT",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    }
                ]
            },
            {
                "id": 816,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Treatment",
                "parent_id": 22,
                "parent_name": "AFE",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "C1284",
                "children": [
                    {
                        "id": 836,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "COMMUNICATE",
                        "parent_id": 816,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 841,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "CALL FOR CODE CART",
                                "parent_id": 836,
                                "parent_name": "COMMUNICATE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 840,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "CALL FOR HELP",
                                "parent_id": 836,
                                "parent_name": "COMMUNICATE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 842,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "INFORM TEAM",
                                "parent_id": 836,
                                "parent_name": "COMMUNICATE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 839,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "CONSIDER",
                        "parent_id": 816,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 850,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Anticipate massive hemorrhage and DIC.",
                                "parent_id": 839,
                                "parent_name": "CONSIDER",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 849,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Anticipate possible cardiopulmonary arrest and emergent C-section.",
                                "parent_id": 839,
                                "parent_name": "CONSIDER",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 851,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Consider circulatory support: IABP/ECMO/CPB.",
                                "parent_id": 839,
                                "parent_name": "CONSIDER",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 838,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "GIVE / START",
                        "parent_id": 816,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 845,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Establish large volume IV access (upper body best).",
                                "parent_id": 838,
                                "parent_name": "GIVE / START",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 844,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Increase to 100% O2 , high flow.",
                                "parent_id": 838,
                                "parent_name": "GIVE / START",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 847,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Prepare for emergent intubation.",
                                "parent_id": 838,
                                "parent_name": "GIVE / START",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 846,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Support circulation with IV fluid, vasopressors, and inotropes.",
                                "parent_id": 838,
                                "parent_name": "GIVE / START",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 848,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "When possible, place arterial line. Consider central venous access or IO line in humerus.",
                                "parent_id": 838,
                                "parent_name": "GIVE / START",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 837,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "POSITION",
                        "parent_id": 816,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 843,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Place patient in left uterine displacement (LUD)",
                                "parent_id": 837,
                                "parent_name": "POSITION",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    }
                ]
            }
        ]
    },
    ANAPHYLAXIS: {
        "id": 23,
        "user_fav_table": "uf_crises",
        "foreign_key": "crises_id",
        "name": "ANAPHYLAXIS",
        "parent_id": 21,
        "parent_name": "CRITICAL EVENTS",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "crises",
        "cp_code": "CE_1318",
        "children": [
            {
                "id": 852,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Confirm Diagnosis",
                "parent_id": 23,
                "parent_name": "ANAPHYLAXIS",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": null
            },
            {
                "id": 857,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Confirm Diagnosis",
                "parent_id": 23,
                "parent_name": "ANAPHYLAXIS",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "C1285",
                "children": [
                    {
                        "id": 863,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "CV",
                        "parent_id": 857,
                        "parent_name": "Confirm Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 866,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Hypotension (may be severe)",
                                "parent_id": 863,
                                "parent_name": "CV",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 867,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Tachycardia",
                                "parent_id": 863,
                                "parent_name": "CV",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 864,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "PULMONARY",
                        "parent_id": 857,
                        "parent_name": "Confirm Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 868,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "BRONCHOSPASM",
                                "parent_id": 864,
                                "parent_name": "PULMONARY",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 873,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "If severe bronchospasm go to [Shortcut to BRONCHOSPASM Crisis]",
                                        "parent_id": 868,
                                        "parent_name": "BRONCHOSPASM",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 869,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Difficulty breathing",
                                "parent_id": 864,
                                "parent_name": "PULMONARY",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 870,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Hypoxemia",
                                "parent_id": 864,
                                "parent_name": "PULMONARY",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 871,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Increase in peak inspiratory pressure (PIP)",
                                "parent_id": 864,
                                "parent_name": "PULMONARY",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 872,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Tachypnea",
                                "parent_id": 864,
                                "parent_name": "PULMONARY",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 862,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Signs & Symptoms:",
                        "parent_id": 857,
                        "parent_name": "Confirm Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null
                    },
                    {
                        "id": 865,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "SKIN",
                        "parent_id": 857,
                        "parent_name": "Confirm Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 874,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Angioedema (potential airway swelling)",
                                "parent_id": 865,
                                "parent_name": "SKIN",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 875,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Rash/hives",
                                "parent_id": 865,
                                "parent_name": "SKIN",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    }
                ]
            },
            {
                "id": 856,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Crisis Management",
                "parent_id": 23,
                "parent_name": "ANAPHYLAXIS",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": null
            },
            {
                "id": 861,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Crisis Management",
                "parent_id": 23,
                "parent_name": "ANAPHYLAXIS",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "C1289",
                "children": [
                    {
                        "id": 940,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "COMMUNICATE",
                        "parent_id": 861,
                        "parent_name": "Crisis Management",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 944,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Call for help",
                                "parent_id": 940,
                                "parent_name": "COMMUNICATE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 945,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Call for possible code cart",
                                "parent_id": 940,
                                "parent_name": "COMMUNICATE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 946,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Call for TEE",
                                "parent_id": 940,
                                "parent_name": "COMMUNICATE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 947,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Consider ECMO",
                                "parent_id": 940,
                                "parent_name": "COMMUNICATE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 943,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Notify surgeon",
                                "parent_id": 940,
                                "parent_name": "COMMUNICATE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 941,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "If pulseless and unresponsive",
                        "parent_id": 861,
                        "parent_name": "Crisis Management",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 950,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "[Shortcut to ACLS Crisis]",
                                "parent_id": 941,
                                "parent_name": "If pulseless and unresponsive",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 949,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Continue epinephrine 1mg IV boluses and large volume IV fluid",
                                "parent_id": 941,
                                "parent_name": "If pulseless and unresponsive",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 948,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Start CPR",
                                "parent_id": 941,
                                "parent_name": "If pulseless and unresponsive",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 942,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Monitoring duration",
                        "parent_id": 861,
                        "parent_name": "Crisis Management",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 951,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "24h post-recovery (can recur after initial treatment)",
                                "parent_id": 942,
                                "parent_name": "Monitoring duration",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    }
                ]
            },
            {
                "id": 853,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Differential Diagnosis",
                "parent_id": 23,
                "parent_name": "ANAPHYLAXIS",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": null
            },
            {
                "id": 858,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Differential Diagnosis",
                "parent_id": 23,
                "parent_name": "ANAPHYLAXIS",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "C1286",
                "children": [
                    {
                        "id": 876,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Anesthetic Overdose",
                        "parent_id": 858,
                        "parent_name": "Differential Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null
                    },
                    {
                        "id": 877,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "ASPIRATION",
                        "parent_id": 858,
                        "parent_name": "Differential Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 883,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "[Shortcut to ASPIRATION Crisis]",
                                "parent_id": 877,
                                "parent_name": "ASPIRATION",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 878,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Emolus",
                        "parent_id": 858,
                        "parent_name": "Differential Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null
                    },
                    {
                        "id": 879,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "HEMORRHAGE",
                        "parent_id": 858,
                        "parent_name": "Differential Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 884,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "[Shortcut to HEMORRHAGE Crisis]",
                                "parent_id": 879,
                                "parent_name": "HEMORRHAGE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 880,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "LAST",
                        "parent_id": 858,
                        "parent_name": "Differential Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 885,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "[Shortcut to LAST Crisis]",
                                "parent_id": 880,
                                "parent_name": "LAST",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 881,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "MI",
                        "parent_id": 858,
                        "parent_name": "Differential Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null
                    },
                    {
                        "id": 882,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "PNEUMOTHORAX",
                        "parent_id": 858,
                        "parent_name": "Differential Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 887,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "[Shortcut to PNEUMOTHORAX Crisis]",
                                "parent_id": 882,
                                "parent_name": "PNEUMOTHORAX",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    }
                ]
            },
            {
                "id": 860,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Drugs",
                "parent_id": 23,
                "parent_name": "ANAPHYLAXIS",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "C1288",
                "children": [
                    {
                        "id": 930,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Albuterol",
                        "parent_id": 860,
                        "parent_name": "Drugs",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null
                    },
                    {
                        "id": 931,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Diphenhydramine 25-50 mg IV",
                        "parent_id": 860,
                        "parent_name": "Drugs",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null
                    },
                    {
                        "id": 932,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Epinephrine",
                        "parent_id": 860,
                        "parent_name": "Drugs",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 939,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Convert to epinephrine infusion early if needed",
                                "parent_id": 932,
                                "parent_name": "Epinephrine",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 938,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Increase dose every 2 minutes until clinical improvement",
                                "parent_id": 932,
                                "parent_name": "Epinephrine",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 937,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Start at 10-100 micro-g IV",
                                "parent_id": 932,
                                "parent_name": "Epinephrine",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 933,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Methylprednisone 125 mg IV",
                        "parent_id": 860,
                        "parent_name": "Drugs",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null
                    },
                    {
                        "id": 934,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Norepinephrine",
                        "parent_id": 860,
                        "parent_name": "Drugs",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null
                    },
                    {
                        "id": 935,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Ranitidine 50 mg IV",
                        "parent_id": 860,
                        "parent_name": "Drugs",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null
                    },
                    {
                        "id": 936,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Vasopressin",
                        "parent_id": 860,
                        "parent_name": "Drugs",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null
                    }
                ]
            },
            {
                "id": 855,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Drugs",
                "parent_id": 23,
                "parent_name": "ANAPHYLAXIS",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": null
            },
            {
                "id": 854,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Treatment",
                "parent_id": 23,
                "parent_name": "ANAPHYLAXIS",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": null
            },
            {
                "id": 859,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Treatment",
                "parent_id": 23,
                "parent_name": "ANAPHYLAXIS",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "C1287",
                "children": [
                    {
                        "id": 894,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "After patient is stable",
                        "parent_id": 859,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 920,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Consider  corticosteroids",
                                "parent_id": 894,
                                "parent_name": "After patient is stable",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 923,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Methylprednisone 125 mg IV",
                                        "parent_id": 920,
                                        "parent_name": "Consider  corticosteroids",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 918,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Consider H1 antagonist",
                                "parent_id": 894,
                                "parent_name": "After patient is stable",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 921,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Diphenhydramine 25-50 mg IV",
                                        "parent_id": 918,
                                        "parent_name": "Consider H1 antagonist",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 919,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Consider H2 antagonist",
                                "parent_id": 894,
                                "parent_name": "After patient is stable",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 922,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Ranitidine 50 mg IV",
                                        "parent_id": 919,
                                        "parent_name": "Consider H2 antagonist",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "id": 888,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "COMMUNICATE",
                        "parent_id": 859,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 897,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Consider pausing surgery",
                                "parent_id": 888,
                                "parent_name": "COMMUNICATE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 896,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Inform OR team",
                                "parent_id": 888,
                                "parent_name": "COMMUNICATE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 889,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "GIVE / START",
                        "parent_id": 859,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 898,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "100% O2",
                                "parent_id": 889,
                                "parent_name": "GIVE / START",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 891,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "If hypotensive",
                        "parent_id": 859,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 910,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Epinephrine IV in escalating doses every 2 minutes",
                                "parent_id": 891,
                                "parent_name": "If hypotensive",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 913,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Convert to epinephrine infusion early if needed",
                                        "parent_id": 910,
                                        "parent_name": "Epinephrine IV in escalating doses every 2 minutes",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 912,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Increase dose every 2 minutes until clinical improvement",
                                        "parent_id": 910,
                                        "parent_name": "Epinephrine IV in escalating doses every 2 minutes",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 911,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Start at 10-100 micro-g IV",
                                        "parent_id": 910,
                                        "parent_name": "Epinephrine IV in escalating doses every 2 minutes",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 909,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "IV fluid bolus",
                                "parent_id": 891,
                                "parent_name": "If hypotensive",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 908,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "STOP volatile anesthetics and consider amnestic agent",
                                "parent_id": 891,
                                "parent_name": "If hypotensive",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 892,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "If no improvement in hypotension",
                        "parent_id": 859,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 916,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Consider additional IV access and invasive monitors",
                                "parent_id": 892,
                                "parent_name": "If no improvement in hypotension",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 914,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Consider other causes [Shortcut to HYPOTENSION Crisis]",
                                "parent_id": 892,
                                "parent_name": "If no improvement in hypotension",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 915,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Consider vasopressin bolus IV or norepinephrine infusion",
                                "parent_id": 892,
                                "parent_name": "If no improvement in hypotension",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 893,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "If signs of angioedema",
                        "parent_id": 859,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 917,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Consider early intubation",
                                "parent_id": 893,
                                "parent_name": "If signs of angioedema",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 895,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Post-event",
                        "parent_id": 859,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 925,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "If event was severe, consider keeping patient intubated and sedated",
                                "parent_id": 895,
                                "parent_name": "Post-event",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 926,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Monitoring duration",
                                "parent_id": 895,
                                "parent_name": "Post-event",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 929,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "24h post-recovery (can recur after initial treatment)",
                                        "parent_id": 926,
                                        "parent_name": "Monitoring duration",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 927,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Refer the patient for postoperative allergy testing",
                                "parent_id": 895,
                                "parent_name": "Post-event",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 924,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Send labs",
                                "parent_id": 895,
                                "parent_name": "Post-event",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 928,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Serum tryptase level <60 min post-event",
                                        "parent_id": 924,
                                        "parent_name": "Send labs",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "id": 890,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "STOP / AVOID",
                        "parent_id": 859,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 899,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Potential allergens",
                                "parent_id": 890,
                                "parent_name": "STOP / AVOID",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 902,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Antibiotics",
                                        "parent_id": 899,
                                        "parent_name": "Potential allergens",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 905,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Blood",
                                        "parent_id": 899,
                                        "parent_name": "Potential allergens",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 907,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Chlorhexidine",
                                        "parent_id": 899,
                                        "parent_name": "Potential allergens",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 903,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Colloids",
                                        "parent_id": 899,
                                        "parent_name": "Potential allergens",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 906,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Contrast",
                                        "parent_id": 899,
                                        "parent_name": "Potential allergens",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 901,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Latex",
                                        "parent_id": 899,
                                        "parent_name": "Potential allergens",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 900,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Muscle relaxants",
                                        "parent_id": 899,
                                        "parent_name": "Potential allergens",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 904,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Protamine",
                                        "parent_id": 899,
                                        "parent_name": "Potential allergens",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    ASYSTOLE: {
        "id": 25,
        "user_fav_table": "uf_crises",
        "foreign_key": "crises_id",
        "name": "ASYSTOLE",
        "parent_id": 21,
        "parent_name": "CRITICAL EVENTS",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "crises",
        "cp_code": "CE_1319"
    },
    BRADYCARDIA: {
        "id": 26,
        "user_fav_table": "uf_crises",
        "foreign_key": "crises_id",
        "name": "BRADYCARDIA - UNSTABLE",
        "parent_id": 21,
        "parent_name": "CRITICAL EVENTS",
        "status": "Active",
        "is_star": null,
        "user_fav": null,
        "cp_table": "crises",
        "cp_code": "CE_1320"
    },
    LAST: {
        "id": 36,
        "user_fav_table": "uf_crises",
        "foreign_key": "crises_id",
        "name": "LAST",
        "parent_id": 21,
        "parent_name": "CRITICAL EVENTS",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "crises",
        "cp_code": "CE_1321",
        "children": [
            {
                "id": 82,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Confirm Diagnosis",
                "parent_id": 36,
                "parent_name": "LAST",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "C1299",
                "children": [
                    {
                        "id": 91,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "CNS",
                        "parent_id": 82,
                        "parent_name": "Confirm Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 95,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Circumoral numbness",
                                "parent_id": 91,
                                "parent_name": "CNS",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 94,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Metallic taste",
                                "parent_id": 91,
                                "parent_name": "CNS",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 96,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Seizures",
                                "parent_id": 91,
                                "parent_name": "CNS",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 93,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Tinnitus",
                                "parent_id": 91,
                                "parent_name": "CNS",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 92,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "CV",
                        "parent_id": 82,
                        "parent_name": "Confirm Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 98,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Bradycardia",
                                "parent_id": 92,
                                "parent_name": "CV",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 100,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Cardiovascular collapse",
                                "parent_id": 92,
                                "parent_name": "CV",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 97,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Hypotension",
                                "parent_id": 92,
                                "parent_name": "CV",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 99,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Ventricular arrhythmias",
                                "parent_id": 92,
                                "parent_name": "CV",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 90,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Signs & Symptoms",
                        "parent_id": 82,
                        "parent_name": "Confirm Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null
                    }
                ]
            },
            {
                "id": 86,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Crisis Management",
                "parent_id": 36,
                "parent_name": "LAST",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "C1303",
                "children": [
                    {
                        "id": 162,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "If pulseless and unresponsive",
                        "parent_id": 86,
                        "parent_name": "Crisis Management",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 165,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Assess CPR quality, improve if",
                                "parent_id": 162,
                                "parent_name": "If pulseless and unresponsive",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 172,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Arterial line diastolic < 20 mmHg",
                                        "parent_id": 165,
                                        "parent_name": "Assess CPR quality, improve if",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 171,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "ETCO2 < 10 mmHg",
                                        "parent_id": 165,
                                        "parent_name": "Assess CPR quality, improve if",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 167,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Establish airway",
                                "parent_id": 162,
                                "parent_name": "If pulseless and unresponsive",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 173,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Confirm adequate ventilation and oxygenation",
                                        "parent_id": 167,
                                        "parent_name": "Establish airway",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 174,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Consider endotracheal intubation if not established",
                                        "parent_id": 167,
                                        "parent_name": "Establish airway",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 166,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Give < 1 mcg/kg epinephrine",
                                "parent_id": 162,
                                "parent_name": "If pulseless and unresponsive",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 164,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Start CPR",
                                "parent_id": 162,
                                "parent_name": "If pulseless and unresponsive",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 168,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "100-120 compressions per minute; at least 2'' deep (adult); allow chest to recoil",
                                        "parent_id": 164,
                                        "parent_name": "Start CPR",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 169,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Minimize breaks in CPR",
                                        "parent_id": 164,
                                        "parent_name": "Start CPR",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 170,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Rotate compressors q2 min",
                                        "parent_id": 164,
                                        "parent_name": "Start CPR",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "id": 163,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "If refractory to treatment",
                        "parent_id": 86,
                        "parent_name": "Crisis Management",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 175,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Alert personnel for possible Cardiopulmonary Bypass",
                                "parent_id": 163,
                                "parent_name": "If refractory to treatment",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 176,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Consider prolonged resuscitation",
                                "parent_id": 163,
                                "parent_name": "If refractory to treatment",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 177,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Monitor patient post even tin ICU",
                                "parent_id": 163,
                                "parent_name": "If refractory to treatment",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    }
                ]
            },
            {
                "id": 83,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Differential Diagnosis",
                "parent_id": 36,
                "parent_name": "LAST",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "C1300",
                "children": [
                    {
                        "id": 101,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Anaphylaxis",
                        "parent_id": 83,
                        "parent_name": "Differential Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null
                    },
                    {
                        "id": 102,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Embolus",
                        "parent_id": 83,
                        "parent_name": "Differential Diagnosis",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 103,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Air",
                                "parent_id": 102,
                                "parent_name": "Embolus",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 104,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Cement",
                                "parent_id": 102,
                                "parent_name": "Embolus",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 105,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Fat",
                                "parent_id": 102,
                                "parent_name": "Embolus",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 106,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Thrombotic",
                                "parent_id": 102,
                                "parent_name": "Embolus",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    }
                ]
            },
            {
                "id": 85,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Drugs",
                "parent_id": 36,
                "parent_name": "LAST",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "C1302",
                "children": [
                    {
                        "id": 148,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Epinephrine",
                        "parent_id": 85,
                        "parent_name": "Drugs",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 151,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Bolus 1 mcg/kg epinephrine",
                                "parent_id": 148,
                                "parent_name": "Epinephrine",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 152,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Infusion 0.02-0.2 mcg/kg/min",
                                "parent_id": 148,
                                "parent_name": "Epinephrine",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 149,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Intralipid",
                        "parent_id": 85,
                        "parent_name": "Drugs",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 153,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Bolus intralipid 20% 1.5 ml/kg IV over 1 min",
                                "parent_id": 149,
                                "parent_name": "Intralipid",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 156,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Repeat every 3-5 minutes until hemodynamically stable",
                                        "parent_id": 153,
                                        "parent_name": "Bolus intralipid 20% 1.5 ml/kg IV over 1 min",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 157,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Repeat loading dose maximum of 3 total doses",
                                        "parent_id": 153,
                                        "parent_name": "Bolus intralipid 20% 1.5 ml/kg IV over 1 min",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 155,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Maximum dose intralipid",
                                "parent_id": 149,
                                "parent_name": "Intralipid",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 160,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "10 ml/kg over the first 30 min",
                                        "parent_id": 155,
                                        "parent_name": "Maximum dose intralipid",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 154,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Start infusion 0.25 ml/kg/min (if needed)",
                                "parent_id": 149,
                                "parent_name": "Intralipid",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 159,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Continue infusion for at least 10 min after hemodynamic stability restored",
                                        "parent_id": 154,
                                        "parent_name": "Start infusion 0.25 ml/kg/min (if needed)",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 158,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Increase as needed to 0.5 ml/kg/min",
                                        "parent_id": 154,
                                        "parent_name": "Start infusion 0.25 ml/kg/min (if needed)",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "id": 150,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Midazolam",
                        "parent_id": 85,
                        "parent_name": "Drugs",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 161,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Bolus 0.05-0.1 mg/kg IV midazolam",
                                "parent_id": 150,
                                "parent_name": "Midazolam",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    }
                ]
            },
            {
                "id": 84,
                "user_fav_table": "uf_crises",
                "foreign_key": "crises_id",
                "name": "Treatment",
                "parent_id": 36,
                "parent_name": "LAST",
                "status": "Active",
                "is_star": "false",
                "user_fav": null,
                "cp_table": "crises",
                "cp_code": "1301",
                "children": [
                    {
                        "id": 110,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "AVOID",
                        "parent_id": 84,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 128,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Calcium Channel Blockers",
                                "parent_id": 110,
                                "parent_name": "AVOID",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 126,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Propofol",
                                "parent_id": 110,
                                "parent_name": "AVOID",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 127,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Vasopressin",
                                "parent_id": 110,
                                "parent_name": "AVOID",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 107,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "COMMUNICATE",
                        "parent_id": 84,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 120,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Alert for possible CBP",
                                "parent_id": 107,
                                "parent_name": "COMMUNICATE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 119,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Alert for possible code cart",
                                "parent_id": 107,
                                "parent_name": "COMMUNICATE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 118,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Alert for possible intralipid",
                                "parent_id": 107,
                                "parent_name": "COMMUNICATE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 117,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Call for Help",
                                "parent_id": 107,
                                "parent_name": "COMMUNICATE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 116,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Inform OR team",
                                "parent_id": 107,
                                "parent_name": "COMMUNICATE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 111,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "Determine if this is a LAST crisis",
                        "parent_id": 84,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 129,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Confirm local anesthetics",
                                "parent_id": 111,
                                "parent_name": "Determine if this is a LAST crisis",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 131,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Drug dose(s)",
                                        "parent_id": 129,
                                        "parent_name": "Confirm local anesthetics",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 130,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Drug(s) given",
                                        "parent_id": 129,
                                        "parent_name": "Confirm local anesthetics",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 132,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Sites of administration",
                                        "parent_id": 129,
                                        "parent_name": "Confirm local anesthetics",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 133,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Review signs & symptoms",
                                "parent_id": 111,
                                "parent_name": "Determine if this is a LAST crisis",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 108,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "GIVE",
                        "parent_id": 84,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 121,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "100% O2",
                                "parent_id": 108,
                                "parent_name": "GIVE",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 115,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "If acidotic, check ABG",
                        "parent_id": 84,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 147,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Consider A-line",
                                "parent_id": 115,
                                "parent_name": "If acidotic, check ABG",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 146,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Consider serial ABGs",
                                "parent_id": 115,
                                "parent_name": "If acidotic, check ABG",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 113,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "If hypotensive, give epinephrine",
                        "parent_id": 84,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 143,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Bolus 1 mcg/kg IV epinephrine",
                                "parent_id": 113,
                                "parent_name": "If hypotensive, give epinephrine",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 144,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Start infusion 0.02-0.2 mcg/kg/min (if needed)",
                                "parent_id": 113,
                                "parent_name": "If hypotensive, give epinephrine",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 112,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "If LAST crisis, then request intralipid",
                        "parent_id": 84,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 134,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Bolus intralipid 20% 1.5 ml/kg IV over 1 min",
                                "parent_id": 112,
                                "parent_name": "If LAST crisis, then request intralipid",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 138,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Repeat every 3-5 minutes until hemodynamically stable",
                                        "parent_id": 134,
                                        "parent_name": "Bolus intralipid 20% 1.5 ml/kg IV over 1 min",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 139,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Repeat loading dose maximum of 3 total doses",
                                        "parent_id": 134,
                                        "parent_name": "Bolus intralipid 20% 1.5 ml/kg IV over 1 min",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 137,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "If not responding to intralipid, then consider alternate diagnosis OR inadequate resuscitation",
                                "parent_id": 112,
                                "parent_name": "If LAST crisis, then request intralipid",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 136,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Maximum dose intralipid",
                                "parent_id": 112,
                                "parent_name": "If LAST crisis, then request intralipid",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 142,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "10 ml/kg over the first 30 min",
                                        "parent_id": 136,
                                        "parent_name": "Maximum dose intralipid",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            },
                            {
                                "id": 135,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Start infusion 0.25 ml/kg/min (if needed)",
                                "parent_id": 112,
                                "parent_name": "If LAST crisis, then request intralipid",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null,
                                "children": [
                                    {
                                        "id": 141,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Continue infusion for at least 10 min after hemodynamic stability restored",
                                        "parent_id": 135,
                                        "parent_name": "Start infusion 0.25 ml/kg/min (if needed)",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    },
                                    {
                                        "id": 140,
                                        "user_fav_table": "uf_crises",
                                        "foreign_key": "crises_id",
                                        "name": "Increase as needed to 0.5 ml/kg/min",
                                        "parent_id": 135,
                                        "parent_name": "Start infusion 0.25 ml/kg/min (if needed)",
                                        "status": "Active",
                                        "is_star": "false",
                                        "user_fav": null,
                                        "cp_table": "crises",
                                        "cp_code": null
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "id": 114,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "If seizures, give midazolam",
                        "parent_id": 84,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 145,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Bolus 0.05-0.1 mg/kg IV midazolam",
                                "parent_id": 114,
                                "parent_name": "If seizures, give midazolam",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    },
                    {
                        "id": 109,
                        "user_fav_table": "uf_crises",
                        "foreign_key": "crises_id",
                        "name": "STOP",
                        "parent_id": 84,
                        "parent_name": "Treatment",
                        "status": "Active",
                        "is_star": "false",
                        "user_fav": null,
                        "cp_table": "crises",
                        "cp_code": null,
                        "children": [
                            {
                                "id": 125,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Antibiotcs (and other potential triggers for anaphylaxis)",
                                "parent_id": 109,
                                "parent_name": "STOP",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 122,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Local anesthetic drugs",
                                "parent_id": 109,
                                "parent_name": "STOP",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 123,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "N2O",
                                "parent_id": 109,
                                "parent_name": "STOP",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            },
                            {
                                "id": 124,
                                "user_fav_table": "uf_crises",
                                "foreign_key": "crises_id",
                                "name": "Volatile agents",
                                "parent_id": 109,
                                "parent_name": "STOP",
                                "status": "Active",
                                "is_star": "false",
                                "user_fav": null,
                                "cp_table": "crises",
                                "cp_code": null
                            }
                        ]
                    }
                ]
            }
        ]
    },
    PEA: {
        "id": 40,
        "user_fav_table": "uf_crises",
        "foreign_key": "crises_id",
        "name": "PEA",
        "parent_id": 21,
        "parent_name": "CRITICAL EVENTS",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "crises",
        "cp_code": "CE_1322"
    },
    SVTSTABLE: {
        "id": 43,
        "user_fav_table": "uf_crises",
        "foreign_key": "crises_id",
        "name": "SVT - STABLE",
        "parent_id": 21,
        "parent_name": "CRITICAL EVENTS",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "crises",
        "cp_code": "CE_1323"
    },
    SVTUNSTABLE: {
        "id": 44,
        "user_fav_table": "uf_crises",
        "foreign_key": "crises_id",
        "name": "SVT - UNSTABLE",
        "parent_id": 21,
        "parent_name": "CRITICAL EVENTS",
        "status": "Active",
        "is_star": "false",
        "user_fav": null,
        "cp_table": "crises",
        "cp_code": "CE_1324"
    },
    CervicalPlexusBlock: {
        "id": 18,
        "user_fav_table": "uf_regional_n_ane",
        "foreign_key": "regional_n_ane_id",
        "name": "Cervical Plexus Block",
        "parent_id": 14,
        "parent_name": "NECK",
        "pdf_name": null,
        "status": "Active",
        "is_star": null,
        "user_fav": null,
        "cp_table": "regional_and_n_anesthesias",
        "cp_code": "TAB-RNA-001",
        "children": [
            {
                "id": 73,
                "user_fav_table": "uf_regional_n_ane",
                "foreign_key": "regional_n_ane_id",
                "name": "Anatomical Correlation",
                "parent_id": 18,
                "parent_name": "Cervical Plexus Block",
                "pdf_name": "https://crisesreference.s3.amazonaws.com/1667111364anatomical-correlation.pdf",
                "status": "Active",
                "is_star": null,
                "user_fav": null,
                "cp_table": "regional_and_n_anesthesias",
                "cp_code": "RANA1266"
            },
            {
                "id": 74,
                "user_fav_table": "uf_regional_n_ane",
                "foreign_key": "regional_n_ane_id",
                "name": "Anatomy",
                "parent_id": 18,
                "parent_name": "Cervical Plexus Block",
                "pdf_name": "https://crisesreference.s3.amazonaws.com/1667111443anatomy.pdf",
                "status": "Active",
                "is_star": null,
                "user_fav": null,
                "cp_table": "regional_and_n_anesthesias",
                "cp_code": "RANA1267"
            },
            {
                "id": 77,
                "user_fav_table": "uf_regional_n_ane",
                "foreign_key": "regional_n_ane_id",
                "name": "Anesthetic Injection",
                "parent_id": 18,
                "parent_name": "Cervical Plexus Block",
                "pdf_name": "https://crisesreference.s3.amazonaws.com/1667111603local-anesthetic-injection.pdf",
                "status": "Active",
                "is_star": null,
                "user_fav": null,
                "cp_table": "regional_and_n_anesthesias",
                "cp_code": "RANA1270"
            },
            {
                "id": 75,
                "user_fav_table": "uf_regional_n_ane",
                "foreign_key": "regional_n_ane_id",
                "name": "Clinical_pearls",
                "parent_id": 18,
                "parent_name": "Cervical Plexus Block",
                "pdf_name": "https://crisesreference.s3.amazonaws.com/1667111496clinical-pearls.pdf",
                "status": "Active",
                "is_star": null,
                "user_fav": null,
                "cp_table": "regional_and_n_anesthesias",
                "cp_code": "RANA1268"
            },
            {
                "id": 76,
                "user_fav_table": "uf_regional_n_ane",
                "foreign_key": "regional_n_ane_id",
                "name": "Image gallery",
                "parent_id": 18,
                "parent_name": "Cervical Plexus Block",
                "pdf_name": "https://crisesreference.s3.amazonaws.com/1667111539image-gallery.pdf",
                "status": "Active",
                "is_star": null,
                "user_fav": null,
                "cp_table": "regional_and_n_anesthesias",
                "cp_code": "RANA1269"
            },
            {
                "id": 78,
                "user_fav_table": "uf_regional_n_ane",
                "foreign_key": "regional_n_ane_id",
                "name": "Insertion Approach",
                "parent_id": 18,
                "parent_name": "Cervical Plexus Block",
                "pdf_name": "https://crisesreference.s3.amazonaws.com/1667111659needle-insertion-approach.pdf",
                "status": "Active",
                "is_star": null,
                "user_fav": null,
                "cp_table": "regional_and_n_anesthesias",
                "cp_code": "RANA1271"
            },
            {
                "id": 79,
                "user_fav_table": "uf_regional_n_ane",
                "foreign_key": "regional_n_ane_id",
                "name": "Nerve Localization",
                "parent_id": 18,
                "parent_name": "Cervical Plexus Block",
                "pdf_name": "https://crisesreference.s3.amazonaws.com/1667111732nerve-localization.pdf",
                "status": "Active",
                "is_star": null,
                "user_fav": null,
                "cp_table": "regional_and_n_anesthesias",
                "cp_code": "RANA1272"
            },
            {
                "id": 80,
                "user_fav_table": "uf_regional_n_ane",
                "foreign_key": "regional_n_ane_id",
                "name": "Scannning Technoque",
                "parent_id": 18,
                "parent_name": "Cervical Plexus Block",
                "pdf_name": "https://crisesreference.s3.amazonaws.com/1667111779scanning-technique.pdf",
                "status": "Active",
                "is_star": null,
                "user_fav": null,
                "cp_table": "regional_and_n_anesthesias",
                "cp_code": "RANA1273"
            },
            {
                "id": 81,
                "user_fav_table": "uf_regional_n_ane",
                "foreign_key": "regional_n_ane_id",
                "name": "Selected References",
                "parent_id": 18,
                "parent_name": "Cervical Plexus Block",
                "pdf_name": "https://crisesreference.s3.amazonaws.com/1667111824selected-references.pdf",
                "status": "Active",
                "is_star": null,
                "user_fav": null,
                "cp_table": "regional_and_n_anesthesias",
                "cp_code": "RANA1274"
            },
            {
                "id": 82,
                "user_fav_table": "uf_regional_n_ane",
                "foreign_key": "regional_n_ane_id",
                "name": "Video Gallery",
                "parent_id": 18,
                "parent_name": "Cervical Plexus Block",
                "pdf_name": "https://www.youtube.com/watch?v=iE9XbLDkLy8&list=PLA9GdUb_D_T0z8-ZW-q8Lg8jkg3mBOz4s",
                "status": "Active",
                "is_star": null,
                "user_fav": null,
                "cp_table": "regional_and_n_anesthesias",
                "cp_code": "RANA1275"
            }
        ]
    },
    
}


export default constantObject