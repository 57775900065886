import React, { useEffect, useState } from 'react';
import './CAS1047.css';

const CAS1047 = () => {

    const [eye, setEye] = useState(0);
    const [verbal, setVerbal] = useState(0);
    const [motor, setMotor] = useState(0);
    const [result, setResult] = useState(0);



    const postData = async () => {

        if (eye === "NT" || verbal === "NT" || motor === "NT") {
            setResult(500);
        }
        else {
            setResult(eye + verbal + motor);
        }

    }

    useEffect(() => {
        postData();


    }, [eye, verbal, motor, result]);


    const resultVal = () => {
        if (result == 500) {
            return <div>Glasgow Coma Score cannot be calculated (one or more components not testable)</div>
        }
        else if (result != 0) {
            return <>
                <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                <div>Glasgow Coma Score</div>
            </>
        }
    }

    return (
        <div className="rev">
            <h5 className="text-info pl-2">Glasgow Coma Scale/Score (GCS)</h5>
            <p className="pl-2">Coma severity based on Eye (4), Verbal (5), and Motor (6) criteria.</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Best eye response</strong></div>
                    <div>If local injury, edema, or otherwise unable to be assessed, mark "Not testable (NT)"</div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  40   }} onClick={() => setEye(4)} className={eye === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Spontaneously (+4)</span></button>
                    <button style={{ height:  40   }} onClick={() => setEye(3)} className={eye === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">To verbal command (+3)</span></button>
                    <button style={{ height:  40   }} onClick={() => setEye(2)} className={eye === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">To pain (+2)</span></button>
                    <button style={{ height:  40   }} onClick={() => setEye(1)} className={eye === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">No eye opening (+1)</span></button>
                    <button style={{ height:  40   }} onClick={() => setEye("NT")} className={eye === "NT" ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Not testable (NT)</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Best verbal response</strong></div>
                    <div>If intubated or otherwise unable to be assessed, mark "Not testable (NT)"</div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2 ">
                    <button style={{ height:  40   }} onClick={() => setVerbal(5)} className={verbal === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Oriented (+5)</span></button>
                    <button style={{ height:  40   }} onClick={() => setVerbal(4)} className={verbal === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Confused (+4)</span></button>
                    <button style={{ height:  40   }} onClick={() => setVerbal(3)} className={verbal === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Inappropriate words (+3)</span></button>
                    <button style={{ height:  40   }} onClick={() => setVerbal(2)} className={verbal === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Incomprehensible sounds (+2)</span></button>
                    <button style={{ height:  40   }} onClick={() => setVerbal(1)} className={verbal === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">No verbal response (+1)</span></button>
                    <button style={{ height:  40   }} onClick={() => setVerbal("NT")} className={verbal === "NT" ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Not testable/intubated (NT)</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Best motor response</strong></div>
                    <div>If on sedation/paralysis or unable to be assessed, mark "Not testable (NT)"</div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button style={{ height:  40   }} onClick={() => setMotor(6)} className={motor === 6 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Obeys commands (+6)</span></button>
                    <button style={{ height:  40   }} onClick={() => setMotor(5)} className={motor === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Localizes pain (+5)</span></button>
                    <button style={{ height:  40   }} onClick={() => setMotor(4)} className={motor === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Withdrawal from pain (+4)</span></button>
                    <button style={{ height:  40   }} onClick={() => setMotor(3)} className={motor === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Flexion to pain (+3)</span></button>
                    <button style={{ height:  40   }} onClick={() => setMotor(2)} className={motor === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Extension to pain (+2)</span></button>
                    <button style={{ height:  40   }} onClick={() => setMotor(1)} className={motor === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">No motor response (+1)</span></button>
                    <button style={{ height:  40   }} onClick={() => setMotor("NT")} className={motor === "NT" ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Not testable (NT)</span></button>
                </div>
            </div>


            <div className="stick">
                {
                    result != 0 && eye !=0 && verbal!=0 && motor != 0? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container" >
                                {resultVal()}

                            </div>
                            <div className="point-result-container" >
                                <h1>E&#40;{eye}&#41; 	V&#40;{verbal}&#41; 	M&#40;{motor}&#41; </h1>
                                <div>Glasgow Coma Scale</div>

                            </div>
                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>

                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/4136544/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >Teasdale G, Jennett B.Assessment of coma and impaired consciousness. A practical scale. Lancet. 1974 Jul 13;2(7872):81-4.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}


export default CAS1047

