import React, { useEffect, useState } from 'react';
import './RE1248.css';
const RE1248 = () => {
    const [green, setGreen] = useState(true);
    const [number1, setNumber1] = useState(0);
    const [number2, setNumber2] = useState(0);
    const [total, settotal] = useState((number1 / number2) * 1000);

    const postData = async () => {
        if ((number1 < 90) && (number2 < 1001)) {
            settotal((number1 / number2) * 1000);
        }
        else {
            settotal(0);
        }

        setGreen(true);
    }
    const round = () => {

        return Math.round(total);
    }

    const result = () => {
        if (total > 105) {
            return <h7>Likely to fail extubation by RSBI.<br />
                See Pearls/Pitfalls for limitations of negative RSBI  .</h7>

        } else {

            return <h7>Likely successful extubation by RSBI.<br />
                Consider all patient factors before extubating.
                97% sensitive, original study.</h7>
        }

    }
    const valnumber1 = () => {


        if (number1 != 0) {
            if ((number1 < 5) && (number1 != 0)) {
                return <div className="validate">*Too small please check double.</div>

            }
            if ((number1 < 90) && (number1 > 70)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (number1 > 90) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(number1)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }

    }

    const valnumber2 = () => {
        if (number2) {
            if ((number2 < 10) && (number2 != 0)) {
                return <div className="validate">*Very Low;double-check.</div>

            }
            else if ((number2 < 1000) && (number2 > 700)) {
                return <div className="validate">*Very High;double check.</div>

            }
            else if (number2 > 1000) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(number2)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }

    }
    useEffect(() => {
        postData()

    }, [number1, number2, total])
    return (<div className="rev">
        <h5 className="text-info pl-2">Rapid Shallow Breathing Index (RSBI)</h5>
<p className="pl-2">Predicts successful extubation, to be used only as a single component of the clinical picture.</p>
        <div className="fields row">
            <div className="col-md-6">
                <div className="pt-2"><strong>Respiratory rate</strong></div>
            </div>
            <div className="col-md-6 frm-field">
                <input
                    type={number1}
                    onChange={e => setNumber1(+e.target.value)}
                    autoComplete="off"
                    // onBlur={e => {
                    //     if(e.target.value < 12) {
                    //         alert(`Value should not less then 12`)
                    //      } else if (e.target.value > 20) {
                    //         alert(`Value should not greater then 20`)
                    //      } else {
                    //         setNumber1(+e.target.value)
                    //      }
                    // }}
                    placeholder="Norm: 12 - 20"



                    className="ant-text-input fluidInput" style={{ height:  40 }} /><span className="units unitBtnfu" style={{height:"100%"}}>breaths/min</span>
                <div>{valnumber1()}</div>
            </div>
        </div>

        <div className="fields row">
            <div className="col-md-6">
                <div className="pt-2"><strong>Tidal volume</strong></div>
            </div>
            <div className="col-md-6 frm-field">
                <input

                    type={number2}
                    onChange={e => setNumber2(+e.target.value)}
                    autoComplete="off"
                    // onBlur={e => {
                    //     if(e.target.value < 400) {
                    //         alert(`Value should not less then 400`)
                    //      } else if (e.target.value > 600) {
                    //         alert(`Value should not greater then 600`)
                    //      } else {
                    //         setNumber2(+e.target.value)
                    //      }
                    // }}
                    placeholder="Norm: 400 - 600"
                    className="ant-text-input" style={{ width: '78%' }}
                /><span className="units">ml</span>
                <div>  {valnumber2()}</div>
            </div>
        </div><br />
        {!isNaN(total) && isFinite(total) ?
            <div className="stick">
               
                        <div className="apfel-score-result-container" >

                            <div className="point-result-container" >
                                <div>
                                    <h1 className="breath">{round()}<span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}> breaths/min/L </span></h1>
                                    {result()}

                                </div>


                            </div>

                        </div>
                   
            </div>: <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                <h6>Please fill out required fields.</h6></div>
        }
        <br/>

        <div className="p-2 mb-4" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://www.nejm.org/doi/full/10.1056/NEJM199105233242101">
            <div className="reference-container-card" >
                <div className="reference-img" >
                    <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                </div>
                <p className="reference-paragraph" >Yang KL, Tobin MJ. A prospective study of indexes predicting the outcome of trials of weaning from mechanical ventilation. N Engl J Med. 1991;324(21):1445-50.</p>
            </div>
            </a>
        </div>
    </div>
    )
}
export default RE1248;