export const SET_NEW_LAMAX_WEIGHT = "SET_NEW_LAMAX_WEIGHT"
export const SET_NEW_WEIGHT_TYPE = "SET_NEW_WEIGHT_TYPE"
export const SET_NEW_COMORBITIES = "SET_NEW_COMORBITIES"
export const SET_NEW_DRUG_1 = "SET_NEW_DRUG_1"
export const SET_NEW_MIXTURE_DRUG_1 = "SET_NEW_MIXTURE_DRUG_1"

export const SET_NEW_CONC = "SET_NEW_CONC"
export const SET_NEW_ADMIN_VALUE = "SET_NEW_ADMIN_VALUE"
export const SET_NEW_DRUG_2 = "SET_NEW_DRUG_2"
export const SET_NEW_CONC2 = "SET_NEW_CONC2"
export const SET_NEW_MIXTURE_DRUG_2 = "SET_NEW_MIXTURE_DRUG_2"

export const SET_NEW_ADMIN_VALUE2 = "SET_NEW_ADMIN_VALUE2"
export const SET_NEW_LAMAX_HEIGHT = "SET_NEW_LAMAX_HEIGHT"
export const SET_NEW_HEIGHT_TYPE = "SET_NEW_HEIGHT_TYPE"
export const SET_NEW_GENDER_TYPE = "SET_NEW_GENDER_TYPE"
export const SET_MED_TYPE = "SET_MED_TYPE"
export const SET_MED_TYPE2 = "SET_MED_TYPE2"
export const SET_DOC_VALUE1 = "SET_DOC_VALUE1"
export const SET_DOC_VALUE2 = "SET_DOC_VALUE2"
export const SET_MIXTURE_VALUE_A = "SET_MIXTURE_VALUE_A"
export const SET_MIXTURE_VALUE_B = "SET_MIXTURE_VALUE_B"