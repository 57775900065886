import React, { useEffect, useState } from "react";
import "./CAS1094.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const CAS1094 = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [menstrualPeriod, setMenstrualPeriod] = useState(new Date());
  const [ultrasoundDate, setUltrasoundDate] = useState(new Date());
  const [crownInput, setCrownInput] = useState(0);
  const [crownUnit, setCrownUnit] = useState(crownInput);
  const [biparietal, setBiparietal] = useState(0);
  const [biparietalUnit, setBiparietalUnit] = useState(0);
  const [head1, sethead1] = useState(0);
  const [headUnit, setHeadUnit] = useState(0);
  const [result, setResult] = useState(0);

  const [gestationByLMP, setGestationByLMP] = useState(0);
  const [result2, setresult2] = useState(0);
  const [result5, setresult5] = useState(0);
  const [result51, setresult51] = useState(0);
  const [result4, setresult4] = useState(0);
  const [result6, setresult6] = useState(0);
  const [result7, setresult7] = useState(0);
  const [result71, setresult71] = useState(0);
  const [result8, setresult8] = useState(0);
  const [result3, setresult3] = useState(0);
  const [result31, setresult31] = useState(0);

  const postData = async () => {
    let d1 = moment(currentDate);
    let d2 = moment(menstrualPeriod);
    let days = (d1.diff(d2, "days") / 7).toFixed(1);
    setGestationByLMP(days);

    d2.add("280", "days");
    let addday = d2.format("ll");
    setresult2(addday);

    let variable3 = ((40 - result3) * 7).toFixed(0);
    let d3 = moment(ultrasoundDate);
    d3.add(variable3, "days");
    let addday1 = d3.format("ll");

    let variable4 = ((result5 - result3) * 7).toFixed(0);
    let d4 = moment(result4);
    d4.subtract(variable4, "days");
    let addday2 = d4.format("ll");
    setresult6(addday2);

    let variable5 = ((result7 - result5) * 7).toFixed(0);
    let d5 = moment(result6);
    d5.subtract(variable5, "days");
    let addday3 = d5.format("ll");
    if (head1 > 79) {
      setresult8(addday3);
      setresult71(
        Math.pow(
          2.71,
          1.854 +
            (0.010451 * headUnit -
              0.000029919 * (headUnit * headUnit) +
              0.000000043156 * (headUnit * headUnit * headUnit))
        ).toFixed(1)
      );
    } else {
      setresult8(0);
      setresult71(0);
    }
    if (crownInput < 101) {
      setresult4(addday1);
      setresult31(
        (
          5.2876 +
          0.1584 * crownUnit -
          0.0007 * (crownUnit * crownUnit)
        ).toFixed(1)
      );
    } else {
      setresult4(0);
      setresult31(0);
    }

    setresult5(((2 * biparietalUnit + 44.2) / 7).toFixed(2));
    setresult51(((2 * biparietalUnit + 44.2) / 7).toFixed(1));
    setresult7(
      Math.pow(
        2.71,
        1.854 +
          (0.010451 * headUnit -
            0.000029919 * (headUnit * headUnit) +
            0.000000043156 * (headUnit * headUnit * headUnit))
      )
    );
    setresult3(5.2876 + 0.1584 * crownUnit - 0.0007 * (crownUnit * crownUnit));

    setResult();
  };
  useEffect(() => {
    postData();
  }, [
    gestationByLMP,
    currentDate,
    menstrualPeriod,
    result2,
    result5,
    biparietalUnit,
    biparietal,
    crownInput,
    crownUnit,
    headUnit,
    head1,
    result7,
    result3,
    result4,
    result6,
    result31,
    result8,
  ]);

  const validation1 = () => {
    if (head1 < 80 && head1 > 0) {
      return (
        <div className="validate">
          *The minimum value of Head Circumference is 80 mm.
        </div>
      );
    } else if (isNaN(head1)) {
      return <div className="validate">*Please Enter valid-no.</div>;
    }
  };
  const validation2 = () => {
    if (isNaN(biparietal)) {
      return <div className="validate">*Please Enter valid-no.</div>;
    }
  };
  const validation3 = () => {
    if (crownInput > 100) {
      return (
        <div className="validate">
          *The maximum value of Crown Rump Length is 100 mm.
        </div>
      );
    }
    if (isNaN(crownInput)) {
      return <div className="validate">*Please Enter valid-no.</div>;
    }
  };
  return (
    <div className="rev">
      <h4 className="text-info pl-2">
        CAS1094 Gestation by LMP and Ultrasound Biometry
      </h4>
      <br />
      <div class="alert alert-dark text-center" role="alert">
        Input
      </div>
      <div className="fields row">
        <div className="col-md-5">
          <div className="pt-2">
            <strong>Current Date</strong>
          </div>
        </div>
        <div className="col-md-6 frm-field">
          <DatePicker
            className="ant-text-input"
            dateFormat="dd/MM/yyyy"
            isClearable
            showYearDropdown
            scrollableMonthYearDropdown
            selected={currentDate}
            placeholder="Please selecte Date of birth"
            onChange={(date) => setCurrentDate(date)}
          />
        </div>
      </div>
      <div className="fields row">
        <div className="col-md-5">
          <div className="pt-2">
            <strong>Last Menstrual Period </strong>
          </div>
        </div>
        <div className="col-md-6 frm-field">
          <DatePicker
            isClearable
            showYearDropdown
            scrollableMonthYearDropdown
            className="ant-text-input"
            dateFormat="dd/MM/yyyy"
            selected={menstrualPeriod}
            onChange={(date) => setMenstrualPeriod(date)}
          />
        </div>
      </div>

      <div class="alert alert-dark text-center" role="alert">
        Ultrasound Estimation:
      </div>
      <div className="fields row">
        <div className="col-md-5">
          <div className="pt-2">
            <strong>Ultrasound Date</strong>
          </div>
        </div>
        <div className="col-md-6 frm-field">
          <DatePicker
            isClearable
            showYearDropdown
            scrollableMonthYearDropdown
            className="ant-text-input"
            dateFormat="dd/MM/yyyy"
            selected={ultrasoundDate}
            placeholder="Please selecte Date of birth"
            onChange={(date) => setUltrasoundDate(date)}
          />
        </div>
      </div>
      <div class="alert alert-dark text-center" role="alert">
        1st Trimester Measurement
      </div>
      <div className="fields row">
        <div className="col-md-5">
          <div className="pt-2">
            <strong>Crown Rump Length</strong>
          </div>
        </div>
        <div className="col-md-7 frm-field">
          <input
            type={crownInput}
            onChange={(e) => setCrownInput(e.target.value)}
            className="ant-text-input"
            style={{ width: "60%", height: "100%" }}
          />

          <select
            className="custom-select"
            aria-label="Default select example"
            style={{ width: "25%" }}
            value={crownUnit}
            onChange={(e) => {
              const patient1 = e.target.value;

              setCrownUnit(patient1);
            }}
          >
            <option value="0">select</option>
            <option value={crownInput * 1}>mm</option>
            <option value={10 * crownInput}>cm</option>
            <option value={25.4 * crownInput}>in</option>
            <option value={0.001 * crownInput}>micm</option>
            <option value={1000 * crownInput}>m</option>
            <option value={0.000001 * crownInput}>nm</option>
            <option value={914.4 * crownInput}>yd</option>
          </select>
          <div>{validation3()}</div>
        </div>
      </div>

      <div class="alert alert-dark text-center" role="alert">
        2nd and 3rd Trimester Measurements
      </div>
      <div className="fields row">
        <div className="col-md-5">
          <div className="pt-2">
            <strong>Biparietal Diameter</strong>
          </div>
        </div>
        <div className="col-md-7 frm-field">
          <input
            type={biparietal}
            onChange={(e) => setBiparietal(e.target.value)}
            className="ant-text-input"
            style={{ width: "60%", height: "100%" }}
          />

          <select
            className="custom-select"
            aria-label="Default select example"
            style={{ width: "25%" }}
            value={biparietalUnit}
            onChange={(e) => {
              const patient2 = e.target.value;

              setBiparietalUnit(patient2);
            }}
          >
            <option value="0">select</option>
            <option value={biparietal * 1}>mm</option>
            <option value={10 * biparietal}>cm</option>
            <option value={25.4 * biparietal}>in</option>
            <option value={0.001 * biparietal}>micm</option>
            <option value={1000 * biparietal}>m</option>
            <option value={0.000001 * biparietal}>nm</option>
            <option value={914.4 * biparietal}>yd</option>
          </select>
          <div>{validation2()}</div>
        </div>
      </div>
      <div className="fields row">
        <div className="col-md-5">
          <div className="pt-2">
            <strong>Head Circumference</strong>
          </div>
        </div>
        <div className="col-md-7 frm-field">
          <input
            type={head1}
            onChange={(e) => sethead1(e.target.value)}
            className="ant-text-input"
            style={{ width: "60%", height: "100%" }}
          />

          <select
            className="custom-select"
            aria-label="Default select example"
            style={{ width: "25%" }}
            value={headUnit}
            onChange={(e) => {
              const patient3 = e.target.value;

              setHeadUnit(patient3);
            }}
          >
            <option value="0">select</option>
            <option value={head1 * 1}>mm</option>
            <option value={10 * head1}>cm</option>
            <option value={25.4 * head1}>in</option>
            <option value={0.001 * head1}>micm</option>
            <option value={1000 * head1}>m</option>
            <option value={0.000001 * head1}>nm</option>
            <option value={914.4 * head1}>yd</option>
          </select>
          <div>{validation1()}</div>
        </div>
      </div>

      <div className="stick mt-5">
        {result != 0 ? (
          <>
            <div className="apfel-score-result-container ">
              <div className="point-result-container">
                <div>Gestation by LMP : {gestationByLMP} Weeks</div>
                <div>EDC by LMP :{result2} </div>
                <div>Gestation by CRL : {result31} Weeks</div>
                <div>EDC by CRL : {result4}</div>
                <div>Gestation by BPD : {result51} Weeks </div>
                <div>EDC by BPD : {result6} </div>
                <div>Gestation by HC : {result71} Weeks</div>
                <div>EDC by HC : {result8}</div>
              </div>
            </div>
          </>
        ) : (
          <div className="text-white p-2 green-bg">
            <h1>Result:</h1>
            <br />
            <h6>Please fill out required fields.</h6>
          </div>
        )}
      </div>
      <div className="p-2">
        <h6>ORIGINAL/PRIMARY REFERENCE</h6>
        <a
          className="referenceLink"
          target="_blank"
          href="https://pubmed.ncbi.nlm.nih.gov/9014279/"
        >
          <div className="reference-container-card">
            <div className="reference-img">
              <img
                src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png"
                alt="Research Paper"
              />
            </div>
            <p className="reference-paragraph">
              Mul T, Mongelli M, Gardosi J. A comparative analysis of
              second-trimester ultrasound dating formulae in pregnancies
              conceived with artificial reproductive techniques. Ultrasound
              Obstet Gynecol. 1996 Dec;8(6):397-402. PubMed ID: 9014279
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default CAS1094;
