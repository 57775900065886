import { SET_ACTION_LIBRARY_DATA, PREOP_CLICK, POSTOP_CLICK, INTRAOP_CLICK, SET_SEARCH_DRUG_DATA, SET_ISSUB, SET_SUB_NAME,
     SET_SUB_ID, SET_SUB_CATEGORY, SET_SUB_SUB_CATEGORY, SET_SUB_SUB, SET_SUB_SUB_SUB_CATEGORY, SET_SUB_SUB_ID, SET_SUB_SUB_SUB,
      SET_SUB4_CATEGORY, SET_SUB4, SET_SUB4_ID, SET_DRUG_LIST, SET_LEVEL_1, SET_SUB_CRISES, SET_LEVEL_2
     } from "./ActionsSummaryActionTypes.js"


export const preopEnable = preopstatus => {
    return {
        type: PREOP_CLICK,
        payload: preopstatus

    }
}

export const postopEnable = poststaus => {
    return {
        type: POSTOP_CLICK,
        payload: poststaus
    }
}

export const intraopEnable = intraopstaus => {
    return {
        type: INTRAOP_CLICK,
        payload: intraopstaus
    }
}

export const setActionLibraryData = actionLibraryData => {
    return {
        type: SET_ACTION_LIBRARY_DATA,
        payload: actionLibraryData
    }
}

export const setSearchOptionsData = searchOptions => {
    return {
        type: SET_SEARCH_DRUG_DATA,
        payload: searchOptions
    }
}
export const setSub = isSub => {
    return {
        type: SET_ISSUB,
        payload: isSub
    }
}
export const setsubName = subName => {
    return {
        type: SET_SUB_NAME,
        payload: subName
    }
}
export const setSubId = subId => {
    return {
        type: SET_SUB_ID,
        payload: subId
    }
}
export const setSubCategory = subCategory => {
    return {
        type: SET_SUB_CATEGORY,
        payload: subCategory
    }
}
export const setSubSubCategory = subsubCategory => {
    return {
        type: SET_SUB_SUB_CATEGORY,
        payload: subsubCategory
    }
}
export const setSubSub = subsub => {
    return {
        type: SET_SUB_SUB,
        payload: subsub
    }
}
export const setsubsubsubCategory = subsubsubCategory => {
    return {
        type: SET_SUB_SUB_SUB_CATEGORY,
        payload: subsubsubCategory
    }
} 
export const setsubsubId = subsubId => {
    return {
        type: SET_SUB_SUB_ID,
        payload: subsubId
    }
}
export const setsubsubsub = subsubsub => {
    return {
        type: SET_SUB_SUB_SUB,
        payload: subsubsub
    }
}
export const setSub4Category = sub4Category => {
    return {
        type: SET_SUB4_CATEGORY,
        payload: sub4Category
    }
}
export const setSub4 = sub4 => {
    return {
        type: SET_SUB4,
        payload: sub4
    }
}
export const setSub4Id = sub4Id => {
    return {
        type: SET_SUB4_ID,
        payload: sub4Id
    }
}
export const setDrugsList = drugsList => {
    return {
        type: SET_DRUG_LIST,
        payload: drugsList
    }
}
export const setLevel1= level1 =>{
    return {
        type: SET_LEVEL_1,
        payload: level1
    }
}
export const setLevel2= level2 =>{
    return {
        type: SET_LEVEL_2,
        payload: level2
    }
}
export const setSubcrises = subcrises => {
    return {
        type: SET_SUB_CRISES,
        payload: subcrises
    }
}