import React, { useEffect, useState } from 'react';

import '../css/ApfelScore.css';
const CAGE = () => {
    const [green, setGreen] = useState(false);
    const [question1, setQuestion1] = useState(0);
    const[question2,setQuestion2]=useState(0);
    const[question3,setQuestion3]=useState(0);
    const[question4,setQuestion4]=useState(0);
    const[cageScore,setCageScore]=useState(0);
   



    const postData = async () => {

        setCageScore(question1+question2 +question3 +question4);
      

        setGreen(true);

    }
    const abcresult = ()=>{
            if(cageScore==0 || cageScore==1){
               return <>Screening negative.</>

            }
            else if(cageScore==2 || cageScore==3 ){
                return <>Scores of 2 or higher had a 93% sensitivity/76% specificity for the identification of “excessive drinking” and a 91% sensitivity/77% specificity for the identification of alcoholism.</>
            }
           
            else if(cageScore==4){
                return <div>Scores of 2 or higher had a 93% sensitivity/76% specificity for the identification of “excessive drinking” and a 91% sensitivity/77% specificity for the identification of alcoholism.
                    <br/> <br/>Some clinicians also consider the “eye opener” question as highly concerning for unhealthy drinking behavior, even if all other questions are answered negatively.
                </div>
            }
            
    }
    useEffect(() => {
        postData();

    }, [ question1, question2,question3,question4])

    return (<div className="rev">
        <h5 className="text-info pl-2">CAGE Questions for Alcohol Use</h5>
        <p className="pl-2">Screens for excessive drinking and alcoholism.</p>

        <div className="fields row">
            <div className="col-md-5 col-sm-12">
                <div className="pt-2"><strong>Have you ever felt you needed to Cut down on your drinking?</strong></div>
            </div>
            <div className="col-md-7 col-sm-12 btn-group text-left">
                <button  className={question1 === 0 ? "btn fluidBtns active text-white" : "btn fluidBtns "} onClick={() => setQuestion1(0)}>No <span className="calc-val text-right">(0)</span></button>
                <button  className={question1 === 1 ? "btn fluidBtns active text-white" : "btn fluidBtns"} onClick={() => setQuestion1(1)}>Yes <span className="calc-val text-right">(+1)</span></button>

            </div>

        </div>

        <div className="fields row">
            <div className="col-md-5 col-sm-12">
                <div className="pt-2"><strong>Have people Annoyed you by criticizing your drinking?</strong></div>
            </div>
            <div className="col-md-7 col-sm-12 btn-group text-left">
                <button  className={question2 === 0 ? "btn fluidBtns active text-white" : "btn fluidBtns "} onClick={() => setQuestion2(0)}>No <span className="calc-val text-right">(0)</span></button>
                <button  className={question2 === 1 ? "btn fluidBtns active text-white" : "btn fluidBtns "} onClick={() => setQuestion2(1)}>Yes <span className="calc-val text-right">(+1)</span></button>

            </div>

        </div>
        <div className="fields row">
            <div className="col-md-5 col-sm-12">
                <div className="pt-2"><strong>Have you ever felt Guilty about drinking?</strong></div>
            </div>
            <div className="col-md-7 col-sm-12 btn-group text-left">
                <button  className={question3 === 0 ? "btn fluidBtns active text-white" : "btn fluidBtns"} onClick={() => setQuestion3(0)}>No <span className="calc-val text-right">(0)</span></button>
                <button  className={question3 === 1 ? "btn fluidBtns active text-white" : "btn fluidBtns"} onClick={() => setQuestion3(1)}>Yes <span className="calc-val text-right">(+1)</span></button>

            </div>

        </div>
        <div className="fields row">
            <div className="col-md-5 col-sm-12">
                <div className="pt-2"><strong>Have you ever felt you needed a drink first thing in the morning (Eye-opener) to steady your nerves or to get rid of a hangover?</strong></div>
            </div>
            <div className="col-md-7 col-sm-12 btn-group text-left">
                <button style={{ height: '60%' }} className={question4 === 0 ? "btn fluidBtns active text-white" : "btn fluidBtns"} onClick={() => setQuestion4(0)}>No <span className="calc-val text-right">(0)</span></button>
                <button style={{ height: '60%' }} className={question4 === 1 ? "btn fluidBtns active text-white" : "btn fluidBtns"} onClick={() => setQuestion4(1)}>Yes <span className="calc-val text-right">(+1)</span></button>

            </div>

        </div>
        
       <br/>
        <div className= " stick">
            {
                green ?
                    <div className="apfel-score-result-container" >

                        <div className="point-result-container col-12" >
                            <div>
                            <h1>{cageScore} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                                {abcresult()}        
                            </div>



                        </div>

                    </div>
                    :
                    <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>
            }
        </div>
        <br/>
        <div className="p-2 mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a  className="referenceLink" target="_blank" href = "https://pubmed.ncbi.nlm.nih.gov/6471323/">
            <div className="reference-container-card" >
                <div className="reference-img" >
                    <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                </div>
                <p className="reference-paragraph" >Ewing JA. Detecting alcoholism. The CAGE questionnaire. JAMA. 1984 Oct 12;252(14):1905-7. PubMed PMID: 6471323.</p>
            </div>
            </a>           
        </div>
    </div>
    )
}
export default CAGE;