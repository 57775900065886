import React, { useEffect, useState } from 'react';
import { API_ROOT } from '../constants';
import { Link } from 'react-router-dom';
import Spinner from '../CustomComponent/Spinner';
const SearchResults = (props) => {
    const [drugs, setDrugs] = useState([]);
    useEffect(() => {

        getDrugDetails();

    }, []);

    const getDrugDetails = () => {
        var token = sessionStorage.getItem("token")
        fetch(API_ROOT + `search-action-library?token=${token}`, {
            method: 'POST',

            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({

                search_text: props.location.state.search_text
            })
        })

            .then(response => response.json())
            .then(res => {
                if (res.status === "true") {
                    setDrugs(res.data);
                    Object.keys(drugs)
                  
                }
            })

    }
    //   props.location.state.subName
    const gotoCrises = (name) => {
        name = name.replace('/', '~')
        props.history.push({
            pathname: "/last"+":"+name,
            state: {
                name: name,
                sourceName: 'Search',
                returnPath: 'searchresults' 
            }
        })
    }
    const gotoDrugs = (name, id) => {
        props.history.push({
            pathname: "/resultOfAction",
            state: {
                subName: name,
                subId:id,
                sourceName: 'Search',
                returnPath: 'searchresults',
                drugsonFly: props.location.state.drugsonFly
 
            }
        })
    }
    const gotoAirway = (name) => {
        props.history.push({
            pathname: "/afterAirway",
            state: {
                name:name,
                value: name,
                sourceName: 'Search',
                returnPath: 'searchresults' 
            }
        })
    }
    const gotoFluids = (name) => {
        let index = 0;
        if (name === 'ABC Score for Massive Transfusion') {
         index = 1;
        }
        if (name === 'Blood Volume Calculation') {
         index = 2;
        }
        if (name === 'FENa') {
         index = 3;
        }
        if (name === 'FEUrea') {
         index = 4;
        }
        if (name === 'Intraoperative Fluid Dosing in Adult Patients') {
         index = 5;
        }
        if (name === 'Intrauterine RBC Transfusion Dosage') {
         index = 6;
        }
        if (name === 'Maximum allowable blood loss without transfusion') {
         index = 7;
        }
        if (name === 'Plasma Volume') {
         index = 8;
        }
        if (name === 'PORT score') {
         index = 9;
        }
        if (name === 'RIFLE Criteria for AKI') {
         index = 10;
        }
        if (name === 'Serum Osmolality / Osmolarity') {
         index = 11;
        }
        if (name === 'Sickle Cell RBC Exchange Volume') {
         index = 12;
        }
        if (name === 'Blood products and expected results') {
         index = 13;
        }
     props.history.push({
         pathname: "/fluidstransfusionscalculators",
         state: {
             index: index ,
            //  categoryName: category,
             sourceName: 'Search',
             returnPath: 'searchresults'
         }
         
     });
    }
   
    return (
        <div>
            {/* <div className="backNavigation text-center">
                <Link className="arrow" to={"/" + props.location.state.returnPath}><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to {props.location.state.sourceName}</span></span></Link>SEARCH RESULTS: {props.location.state.search_text}
                </div> */}

            <div className="main-container">
            
                {drugs.length !== 0 ?
                <div>
                   
                
                {
                drugs['AIRWAY & EQUIPMENTS'].length ?
                <div>
                    <span className="badge badge-secondary">AIRWAY &amp; VENTILATION</span>
                    <table className="table table-striped table-hover mt-2">
                        <thead className="bg-header text-center text-white stickheader">
                            <tr>
                                <td>Name</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                drugs['AIRWAY & EQUIPMENTS'].map((drugData, i) => {
                                    return (
                                        <tr className="text-center" key={i}>
                                            <td><span>Airway &amp; Ventilation</span> / <span>{drugData.parent_name}</span> {drugData.parent_name? <>/</> : <></>}<span onClick={() => gotoAirway(drugData.name)} className="sectionLink">{drugData.name}</span></td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                    </div>: <></>
                   
                }
                 {
                drugs['CRISES'].length ?
                <div>
                    <span className="badge badge-secondary">CRISES</span>
                    <table className="table table-striped table-hover mt-2">
                        <thead className="bg-header text-center text-white stickheader">
                            <tr>
                                <td>Name</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                drugs['CRISES'].map((drugData, key) => {
                                    return (
                                        <tr className="text-center" key={key}>
                                            <td style={{fontWeight:620}}><span>CRISES</span> / <span>{drugData.parent_name}</span> {drugData.parent_name? <>/</> : <></>} <span  className="sectionLink" onClick={() => gotoCrises(drugData.name)}>{drugData.name}</span></td>
                                           </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                    </div>: <></>
                   
                }
                {
                   drugs['DRUGS'].length ?
                   <div>
                      <span className="badge badge-secondary">DRUGS</span>
                        <table className="table table-striped table-hover mt-2">
                            <thead className="bg-header text-center text-white stickheader">
                                <tr>
                                    <th>Drug name</th>
                                    <th>Admin Route</th>
                                    <th>Indication</th>
                                    <th>Dose</th>
                                    <th>Calculated dose</th>
                                    <th>Infusion dose</th>
                                    <th>Calculated Infusion dose</th>
                                    <th>Admin notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    drugs['DRUGS'].map((drugData, key) => {
                                        return (
                                            <tr className="text-center" key={key}>
                                                <td><span onClick={() => gotoDrugs(drugData.drug_brand_name, drugData.id)} className="sectionLink">{drugData.drug_brand_name}</span></td>
                                                <td>{drugData.administration_route}</td>
                                                <td>{drugData.indication}</td>
                                                <td>{drugData.bolus_dose_from}{drugData.bolus_dose_from ? <>-</> : <></>} {drugData.bolus_dose_to} {drugData.bolus_dose_unit}</td>
                                                <td>{drugData.bolus_dose_min}{drugData.bolus_dose_min ? <>-</> : <></>} {drugData.bolus_dose_max} {drugData.bolus_dose_final_unit}</td>
                                                <td>{drugData.infusion_dose_from}{drugData.infusion_dose_from ? <>-</> : <></>} {drugData.infusion_dose_to} {drugData.infusion_dose_unit}</td>
                                                <td>{drugData.infusion_dose_min}{drugData.infusion_dose_min ? <>-</> : <></>} {drugData.infusion_dose_max} {drugData.infusion_dose_final_unit}</td>
                                                <td>{drugData.administration_notes}</td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                        </div> : <></>

                }
               
                {
                drugs['FLUIDS AND TRANSFUSIONS'].length ?
                <div>
                    <span className="badge badge-secondary">FLUIDS &amp; TRANSFUSIONS</span>
                    <table className="table table-striped table-hover mt-2">
                        <thead className="bg-header text-center text-white stickheader">
                            <tr>
                                <td>Drug name</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                drugs['FLUIDS AND TRANSFUSIONS'].map((drugData, i) => {
                                    return (
                                        <tr className="text-center" key={i}>
                                            <td><span>Fluids and Transfusions</span> <i className="fa fa-arrow-right"></i> <span>{drugData.parent_name}</span> {drugData.parent_name? <i className="fa fa-arrow-right"></i> : <></>}<span onClick={() => gotoFluids(drugData.name)}>{drugData.name}</span></td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                    </div>: <></>
                   
                }
            </div>:<Spinner/>
}
            </div> 
        </div>
    )
}
export default SearchResults;