import React, { useEffect, useState } from 'react';
import './FAT1171.css'

const FAT1171 = () => {
    const [green, setGreen] = useState(false);
    const [patientSex, setPatientSex] = useState('');
    const [patientHeight, setPatientHeight] = useState([]);
    const [patientWeight, setPatientWeight] = useState([]);
    const [patientHematocrit, setPatientHematocrit] = useState(0);
    const [patientInitialHgbs, setPatientInitialHgbs] = useState(0);
    const [goalHgbs, setGoalHgbs] = useState(0);
    const [patientRbcs, setPatientRbcs] = useState(0);
    const [tunit, setTunit] = useState('cm');
    const [placehldr, setPlacehldr] = useState('Norm: 152 - 213');
    const [tunit1, setTunit1] = useState('kg');

    const [exchange_volume, setExchange_volume] = useState(0);
    const [rbcvol, setRbcvol] = useState(0);
    const [calunit, setCalunit] = useState(0);
    const [newpatientHeight, setNewpatientHeight] = useState(0);
    const [newpatientWeight, SetNewpatientWeight] = useState(0);


    const postData = async () => {

        if (tunit === 'cm') {
            if (patientHeight < 229) {
                setNewpatientHeight(patientHeight);

            }
            else {
                setNewpatientHeight(0);
            }
        }
        if (tunit === 'in') {
            if (patientHeight < 90.7638) {

                setNewpatientHeight((patientHeight * 2.54).toFixed(2));
            }
            else {
                setNewpatientHeight(0);
            }
        }
        if (tunit1 === 'kg') {
            if (patientWeight < 273) {

                SetNewpatientWeight(patientWeight);
            }
            else {
                SetNewpatientWeight(0);
            }
        }
        if (tunit1 === 'lbs') {
            if (patientWeight < 601) {

                SetNewpatientWeight((patientWeight / 2.2046226218488).toFixed(2));
            }
            else {
                SetNewpatientWeight(0);
            }
        }


        if (newpatientHeight != 0 && newpatientWeight != 0 && patientHematocrit < 101 && patientInitialHgbs < 101 && goalHgbs < 31 && patientRbcs < 101) {
            if (patientSex === "female") {

                setRbcvol((((0.3561 * ((newpatientHeight * 0.01) * (newpatientHeight * 0.01) * (newpatientHeight * 0.01)) + 0.03308 * (newpatientWeight) + 0.1833) * 1000) * patientHematocrit / 100).toFixed(3));
                setCalunit((rbcvol * Math.log(patientInitialHgbs / goalHgbs) / patientRbcs * 100 / 350).toFixed(0));
                setExchange_volume(((rbcvol * Math.log(patientInitialHgbs / goalHgbs) / patientRbcs) * 100).toFixed(0));

            }
            if (patientSex === "male") {

                setRbcvol((((0.3669 * ((newpatientHeight * 0.01) * (newpatientHeight * 0.01) * (newpatientHeight * 0.01)) + 0.03219 * (newpatientWeight) + 0.6041) * 1000) * patientHematocrit / 100).toFixed(3));
                setCalunit((rbcvol * Math.log(patientInitialHgbs / goalHgbs) / patientRbcs * 100 / 350).toFixed(0));
                setExchange_volume(((rbcvol * Math.log(patientInitialHgbs / goalHgbs) / patientRbcs) * 100).toFixed(0));


            }

        }
        else {
            setRbcvol(0)
            setExchange_volume(0);
        }

        setGreen(true);


    }
    const validation1 = () => {
        if (tunit === 'cm') {
            if ((patientHeight <= 12) && (patientHeight != 0)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if ((patientHeight <= 228) && (patientHeight >= 214)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if ((patientHeight >= 229)) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(patientHeight)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }

        }

        if (tunit === 'in') {
            if (patientHeight !== 0) {
                if ((patientHeight < 48.0315) && (patientHeight > 0)) {
                    return <div className="validate">*Too low; please change to proceed.</div>

                }
                if ((patientHeight < 90.1575) && (patientHeight > 79.9213)) {
                    return <div className="validate">*Very High; double check.</div>

                }
                if (patientHeight > 89.7638) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(patientHeight)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
    }
    const validation2 = () => {
        if (tunit1 === 'kg') {
            if ((patientWeight <= 272) && (patientWeight >= 227) && (patientWeight != 0)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (patientWeight >= 273) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(patientWeight)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }

        }


        if (tunit1 === 'lbs') {
            if ((patientWeight < 55) && (patientWeight != 0)) {
                return <div className="validate">*Too low; please change to proceed.</div>

            }
            if ((patientWeight <= 600) && (patientWeight >= 301)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (patientWeight >= 601) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(patientWeight)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    const validation3 = () => {

        if ((patientHematocrit <= 9) && (patientHematocrit != 0)) {
            return <div className="validate">*Very Low;double check.</div>

        }
        if ((patientHematocrit <= 100) && (patientHematocrit >= 41)) {
            return <div className="validate">*Very High;double check.</div>

        }
        if (patientHematocrit >= 101) {
            return <div className="validate">*Too high; please change to proceed.</div>

        }
        else if (isNaN(patientHematocrit)) {
            return <div className="validate">*Please Enter valid-no.</div>

        }
    }
    const validation4 = () => {

        if ((patientInitialHgbs <= 19) && (patientInitialHgbs != 0)) {
            return <div className="validate">*Very Low;double check.</div>

        }

        if (patientInitialHgbs >= 101) {
            return <div className="validate">*Too high; please change to proceed.</div>

        }
        else if (isNaN(patientInitialHgbs)) {
            return <div className="validate">*Please Enter valid-no.</div>

        }
    }
    const validation5 = () => {



        if ((goalHgbs >= 31) && (goalHgbs != 0)) {
            return <div className="validate">*Too high; please change to proceed.</div>

        }
        else if (isNaN(goalHgbs)) {
            return <div className="validate">*Please Enter valid-no.</div>

        }
    }
    const validation6 = () => {

        if ((patientRbcs <= 54) && (patientRbcs != 0)) {
            return <div className="validate">*Very Low;double check.</div>

        }
        if ((patientRbcs <= 100) && (patientRbcs >= 86)) {
            return <div className="validate">*Very High;double check.</div>

        }

        if (patientRbcs >= 101) {
            return <div className="validate">*Too high; please change to proceed.</div>

        }
        else if (isNaN(patientRbcs)) {
            return <div className="validate">*Please Enter valid-no.</div>

        }
    }


    useEffect(() => {
        postData();

    }, [patientSex, patientHeight, patientWeight, patientHematocrit, patientInitialHgbs, goalHgbs, patientRbcs, exchange_volume, rbcvol, newpatientHeight, newpatientWeight])

    const changeunit = () => {
        if (patientHeight === 0) {
            if (tunit === 'cm') {
                setTunit('in');

            }
            if (tunit === 'in') {
                setTunit('cm');

            }
        } else if (patientHeight !== 0) {
            if (tunit === 'cm') {
                setTunit('in');
                setPlacehldr("Norm: 60 - 84");
                if (patientHeight.length !== 0) {

                    setPatientHeight((patientHeight / 2.54).toFixed(2));
                }

            }
            if (tunit === 'in') {
                setTunit('cm');
                setPlacehldr("Norm: 152 - 213");
                if (patientHeight.length !== 0) {

                    setPatientHeight((patientHeight * 2.54).toFixed(2));
                }

            }
        }
    }
    const takeUnit = (e) => {
        if (tunit === 'cm') {
            setPatientHeight(e.target.value);

        }
        if (tunit === 'in') {
            let ht = e.target.value;
            setPatientHeight(ht);
        }
    }
    //////////////////////////////////////////
    const changeunit1 = () => {
        if (patientWeight === 0) {
            if (tunit1 === 'kg') {
                setTunit1('lbs');

            }
            if (tunit1 === 'lbs') {
                setTunit1('kg');

            }
        } else if (patientWeight !== 0) {
            if (tunit1 === 'kg') {
                setTunit1('lbs');
                if (patientWeight.length !== 0) {
                    setPatientWeight((patientWeight * 2.2046226218488).toFixed(2));

                }

            }
            if (tunit1 === 'lbs') {
                setTunit1('kg');
                if (patientWeight.length !== 0) {

                    setPatientWeight((patientWeight / 2.2046226218488).toFixed(2));
                }

            }
        }
    }
    const takeUnit1 = (e) => {
        if (tunit1 === 'kg') {
            setPatientWeight(e.target.value);


        }
        if (tunit1 === 'lbs') {
            let ht = e.target.value;
            setPatientWeight(ht);

        }
    }


    return (<div className="rev">
    <h5 className="text-info pl-2">Sickle Cell RBC Exchange Volume</h5>
<p className="pl-2">Estimates the volume of donor RBCs needed for RBC exchange in patients with sickle cell disease.</p>
    <div className="fields row">
        <div className="col-md-5">
            <div className="pt-2"><strong>Patient sex</strong></div>
        </div>
        <div className="col-md-7 btn-group pl-5">
            <button style={{ height: 40 }} className={patientSex === "male" ? "btn active text-white" : "btn "} onClick={() => setPatientSex('male')}>Male</button>
            <button style={{ height: 40 }} className={patientSex === "female" ? "btn active text-white" : "btn "} onClick={() => setPatientSex('female')}>Female</button>

        </div>
    </div>

    <div className="fields row">
        <div className="col-md-5">
            <div className="pt-2"><strong>Patient height</strong></div>
        </div>
        <div className="col-md-7 frm-field">
            <input style={{ height:40 }} 
            type="number"
            onChange={takeUnit}
            placeholder={placehldr}
            className="ant-text-input" 
              value={patientHeight}/><span className="units" onClick={changeunit} style={{height:40}}>{tunit}&#8651;</span>
            <div>{validation1()}</div>
        </div>
    </div>
    <div className="fields row">
        <div className="col-md-5">
            <div className="pt-2"><strong>Patient weight</strong></div>
        </div>
        <div className="col-md-7 frm-field">
            <input style={{height:40}} 
            type="number"
             onChange={takeUnit1}
           
            className="ant-text-input"   value={patientWeight}/><span className="units" onClick={changeunit1} style={{height:40}}>{tunit1}&#8651;</span>
            <div>{validation2()}</div>
        </div>
    </div>
    <div className="fields row">
        <div className="col-md-5">
            <div className="pt-2"><strong>Patient hematocrit</strong></div>
            <div>"Normal" indicates likely normal ranges for sickle cell patients</div>
        </div>
        <div className="col-md-7 frm-field">
            <input type="number" className="ant-text-input fluidInput" placeholder="Norm: 20 - 30" onChange={(e) => setPatientHematocrit(e.target.value)} style={{height: 40 }} /><span  className="units unitBtnfu" style={{height:40}}>%</span>
            <div>{validation3()}</div>
        </div>
    </div>
    <div className="fields row">
        <div className="col-md-5">
            <div className="pt-2"><strong>Patient's initial HgbS</strong></div>
            <div>If exchange is required for acute complication and pre-exchange HgbS % is unknown, can presume 100% HgbS</div>
        </div>
        <div className="col-md-7 frm-field">
            <input type="number" className="ant-text-input fluidInput" placeholder="Norm: 20 - 100" onChange={(e) => setPatientInitialHgbs(e.target.value)} style={{height: 40 }}/><span className="units unitBtnfu" style={{height: 40 }}>%</span>
            <div>{validation4()}</div>
        </div>
    </div>
    <div className="fields row">
        <div className="col-md-5">
            <div className="pt-2"><strong>Goal HgbS</strong></div>
        </div>
        <div className="col-md-7 frm-field">
            <input type="number" style={{height:40}} className="ant-text-input" placeholder="Norm: 1 - 30" onChange={(e) => setGoalHgbs(e.target.value)} /><span className="units" style={{height:40}}>%</span>
            <div>{validation5()}</div>
        </div>
    </div>
    <div className="fields row">
        <div className="col-md-5">
            <div className="pt-2"><strong>Hematocrit of transfused RBCs</strong></div>
        </div>
        <div className="col-md-7 frm-field">
            <input type="number" style={{height:40}} className="ant-text-input" placeholder="Norm: 55 - 85" onChange={(e) => setPatientRbcs(e.target.value)} /><span className="units" style={{height:40}}>%</span>
            <div>{validation6()}</div>
        </div>
    </div>
  <br/>
    <div className = "stick">
        {
            exchange_volume !== 0 && !isNaN(exchange_volume) && isFinite(exchange_volume) ?
                <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                    <h1>{exchange_volume} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>
                            ml </span></h1>
                       <div>RBC exchange replacement volume</div>
                    </div>
                    <div className="percentage-result-container" >
                    <h1>{calunit} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>
                            units</span></h1>
                       <div>Approximate number of units, assuming 350 mL RBCs per unit</div>
                        </div>
                </div>
                :<div>
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                <h6>Please fill out required fields.</h6></div></div>
  }
        </div>
        <div className="reference-container mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a href="https://www.ncbi.nlm.nih.gov/nlmcatalog/101666102" target="_blank" className="referenceLink">
            <div className="reference-container-card" >
                <div className="reference-img" >
                    <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                </div>
                <p className="reference-paragraph" >Brecher ME, Shaz BH, Schwartz J. Look This Up, Too! A Quick Reference in Apheresis. 3rd Ed. AABB Press, Bethesda MD 2013.</p>
            </div>
            </a>
        </div>

</div>
)
}

export default FAT1171