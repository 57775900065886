import {SET_DISP_START} from './HeaderActionTypes';
const initialState = {
    dispstart: true
}
const HeaderActionsReducers = (state = initialState, action) => {
    switch (action.type) {
        case SET_DISP_START:
            return {
                ...state,
                dispstart: action.payload
            }
            default: return state
        }
    }
    export default HeaderActionsReducers;