import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';
import { Link } from 'react-router-dom';
import PortFluidCalculator from '../Screen/PortFluidCalculator';
import Care from '../Screen/Care';
import CssCardio from '../Screen/CssCardio';
import DetskRiskIndex from '../Screen/DetskRiskIndex';
import PediatricCardiovascularcalculator from '../Screen/PediatricCardiovascularcalculator';
import RcriCardiovascularCalculator from '../Screen/RcriCardiovascularCalculator';
import TisdaleCardiovascularCalculator from '../Screen/TisdaleCardiovascularCalculator';
import Acc from '../Screen/Acc';
import CardicOutput from '../Screen/CardicOutput';
import MeanArterialCardiovascular from '../Screen/MeanArterialCardiovascular';
import NyhaCardiovascularCalculator from '../Screen/NyhaCardiovascularCalculator';
import PulsePressureCardiovascular from '../Screen/PulsePressureCardiovascular';
import CHA2DS2VASc from './CHA2DS2VASc';
import FluidBloodVolume from './FluidBloodVolume';
import CardiacRiskIndex from './CardiacRiskIndex';
import IfdaFluidCalculator from './IfdaFluidCalculator';
import FluidMaximumAbl from './FluidMaximunAbl';
import SirsSepsis from './SirsSepsis';
import SIRSpediatric from './SIRSpediatric';
import EuroScoreCardivascular from './EuroScoreCardivascular';
const CardiovascularCalculators = (props) => {
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
    };
    useEffect(() => {
        toggleTab(1);
    }, [])

    return (
        <div>
            <div className="backNavigation text-center" style={props.hide ? {display:'none'} : null}>
                <Link className="arrow" to="/actionLibrary"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Action Library</span></span></Link> CARDIOVASCULAR CALCULATORS
            </div>
            <div className="main-container calculator-container mt-3">
                <div className="row">
                    <div className="col-md-4 airway-calc-tabs">
                    <button className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(1)} >ACC/AHA CV Risk Calculator (2013)</button>
                            <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(2)} >Blood Volume Calculation</button>
                        <button className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(3)} >CARE</button>
                        <button className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(4)} >Cardiac Output (Fick's Formula)</button>
                        <button className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(5)} >Cardiac Risk Index (Goldman Criteria)</button>
                        <button className={toggleState === 6 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(6)} >CCS</button>
                            <button className={toggleState === 7 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(7)} >CHA2DS2 VASc</button>
                        <button className={toggleState === 8 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(8)} >Detsk Modified Cardiac Risk Index</button>
                        <button className={toggleState === 9 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(9)} >EuroSCORE II</button>
                        <button className={toggleState === 10 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(10)} >Intraoperative Fluid Dosing in Adult Patients</button>
                        <button className={toggleState === 11 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(11)} >Maximum allowable blood loss without transfusion</button>
                            <button className={toggleState === 12 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(12)} >Mean Arterial Pressure</button>
                        <button className={toggleState === 13 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(13)} >NYHA</button>
                        <button className={toggleState === 14 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(14)} >PORT score</button>
                         <button className={toggleState === 15 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(15)} >Pulse Pressure</button>
                            <button className={toggleState === 16 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(16)} >RCRI</button>
                            <button className={toggleState === 17 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(17)} >SIRS, Sepsis and Septic Shock</button>
                            <button className={toggleState === 18 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(18)} >SIRS - Pediatric</button>
                            <button className={toggleState === 19 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(19)} >Tisdale</button>
                            <button className={toggleState === 20 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(20)} >Vital Signs - Pediatric</button>
                    </div>
                    <div className="col-md-8 col-sm-12">
                        <div className="content-tabs">
                            <div
                                className={toggleState === 1 ? "calculator-content  active-content" : "calculator-content"}>
                              <Acc/>
                            </div>
                            <div className={toggleState === 2 ? "calculator-content  active-content" : "calculator-content"}>
                            <FluidBloodVolume/>
                            </div>
                            <div className={toggleState === 3 ? "calculator-content  active-content" : "calculator-content"}>
                            <Care/>
                            </div>

                            <div
                                className={toggleState === 4 ? "calculator-content  active-content" : "calculator-content"} >
                               <CardicOutput/>
                            </div>

                            <div
                                className={toggleState === 5 ? "calculator-content  active-content" : "calculator-content"}>
                               <CardiacRiskIndex/>
                            </div>
                            <div
                                className={toggleState === 6 ? "calculator-content  active-content" : "calculator-content"}>
                                <CssCardio/>
                            </div>
                            <div className={toggleState === 7 ? "calculator-content  active-content" : "calculator-content"}>
                            <CHA2DS2VASc/>
                            </div>
                            <div className={toggleState === 8 ? "calculator-content  active-content" : "calculator-content"}>
                           <DetskRiskIndex/>
                            </div>

                            <div
                                className={toggleState === 9 ? "calculator-content  active-content" : "calculator-content"} >
                               <EuroScoreCardivascular/>
                            </div>

                            <div
                                className={toggleState === 10 ? "calculator-content  active-content" : "calculator-content"}>
                               <IfdaFluidCalculator/>
                            </div>
                            <div
                                className={toggleState === 11 ? "calculator-content  active-content" : "calculator-content"}>
                               <FluidMaximumAbl/>
                            </div>
                            <div
                                className={toggleState === 12 ? "calculator-content  active-content" : "calculator-content"}>
                                <MeanArterialCardiovascular/>
                            </div>
                            <div
                                className={toggleState === 13 ? "calculator-content  active-content" : "calculator-content"}>
                               <NyhaCardiovascularCalculator/>
                            </div>
                            <div
                                className={toggleState === 14 ? "calculator-content  active-content" : "calculator-content"}>
                               <PortFluidCalculator/>
                            </div>
                            <div
                                className={toggleState === 15 ? "calculator-content  active-content" : "calculator-content"}>
                                <PulsePressureCardiovascular/> 
                            </div>
                            
                            <div className={toggleState === 16 ? "calculator-content  active-content" : "calculator-content"}>
                            <RcriCardiovascularCalculator/>
                            </div>
                            <div className={toggleState === 17 ? "calculator-content  active-content" : "calculator-content"}>
                            <SirsSepsis/>
                            </div>
                            <div className={toggleState === 18 ? "calculator-content  active-content" : "calculator-content"}>
                            <SIRSpediatric/>
                            </div>
                            <div className={toggleState === 19 ? "calculator-content  active-content" : "calculator-content"}>
                            <><TisdaleCardiovascularCalculator/></>
                            </div>
                            <div className={toggleState === 20 ? "calculator-content  active-content" : "calculator-content"}>
                            <PediatricCardiovascularcalculator/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default CardiovascularCalculators;