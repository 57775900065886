import React, { useEffect, useState } from 'react';
import './CAS1039.css';

const CAS1039 = () => {
    const [temp, setTemp] = useState(0);
    const [heart, setHeart] = useState(0);
    const [respiratory, setRespiratory] = useState(0);
    const [wbc, setWbc] = useState(0);
    const [suspected, setSuspected] = useState(0);
    const [lactic, setLactic] = useState(0);
    const [severe, setSevere] = useState(0);
    const [evidence, setEvidence] = useState(0)
    const [sirs, setSirs] = useState(0);


    const postData = async () => {
        setSirs(temp + heart + respiratory + wbc + suspected + lactic + severe + evidence);
    }
    useEffect(() => {
        postData();


    }, [temp, heart, respiratory, wbc, suspected, lactic, severe, evidence]);




    const result1 = () => {
        
        if ((sirs == 2) && (suspected == 0) && (lactic == 0) && (severe == 0) && (evidence == 0)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <div>This patient meets SIRS criteria.</div>
                </div>
            </div>
        }
        if ((sirs == 3) &&(suspected == 0) && (lactic == 0) && (severe == 0) && (evidence == 0)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result- col-12" >
                    <div>This patient meets SIRS criteria.</div>
                </div>
            </div>
        }
        if ((sirs == 4) &&(suspected == 0) && (lactic == 0) && (severe == 0) && (evidence == 0)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <div>This patient meets SIRS criteria.</div>
                </div>
            </div>
        }

        if ((sirs == 1)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <div>This patient does not meet SIRS criteria. For other causes of shock, see the Next Steps section..</div>
                </div>
            </div>
        }
        if ((suspected == 0) && (sirs == 7) && (lactic == 1) && (severe == 1) && (evidence == 1)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <div>This patient meets multiple organ dysfunction syndrome..</div>
                </div>
            </div>
        }
        if ((suspected == 0) && (sirs == 5) && (lactic == 1) && (severe == 0) && (evidence == 0)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <div>This patient meets severe sepsis criteria..</div>
                </div>
            </div>
        }

        if ((suspected == 1) && (sirs == 6) && (lactic == 1) && (severe == 0) && (evidence == 0)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <div>This patient meets sepsis criteria. Follow your guidelines for sepsis, which typically include aggressive fluid resuscitation, early, broad-spectrum antibiotics, ICU consultation, CVP evaluation, and occasionally pressors and transfusion..</div>
                </div>
            </div>
        }
        if ((suspected == 1) && (sirs == 7) && (lactic == 1) && (severe == 1) && (evidence == 0)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <div>This patient meets sepsis criteria. Follow your guidelines for sepsis, which typically include aggressive fluid resuscitation, early, broad-spectrum antibiotics, ICU consultation, CVP evaluation, and occasionally pressors and transfusion..</div>
                </div>
            </div>
        }
        if ((suspected == 1) && (sirs == 8) && (lactic == 1) && (severe == 1) && (evidence == 1)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <div>This patient meets sepsis criteria. Follow your guidelines for sepsis, which typically include aggressive fluid resuscitation, early, broad-spectrum antibiotics, ICU consultation, CVP evaluation, and occasionally pressors and transfusion..</div>
                </div>
            </div>
        }
        if ((suspected == 0) && (sirs == 6) && (lactic == 1) && (severe == 1) && (evidence == 0)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <div>This patient meets sepsis criteria. Follow your guidelines for sepsis, which typically include aggressive fluid resuscitation, early, broad-spectrum antibiotics, ICU consultation, CVP evaluation, and occasionally pressors and transfusion..</div>
                </div>
            </div>
        }
        if ((suspected == 0) && (sirs == 5) && (lactic == 0) && (severe == 1) && (evidence == 0)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <div>This patient meets sepsis criteria. Follow your guidelines for sepsis, which typically include aggressive fluid resuscitation, early, broad-spectrum antibiotics, ICU consultation, CVP evaluation, and occasionally pressors and transfusion..</div>
                </div>
            </div>
        }
        if ((suspected == 0) && (sirs == 5) && (lactic == 0) && (severe == 0) && (evidence == 1)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <div>This patient meets sepsis criteria. Follow your guidelines for sepsis, which typically include aggressive fluid resuscitation, early, broad-spectrum antibiotics, ICU consultation, CVP evaluation, and occasionally pressors and transfusion..</div>
                </div>
            </div>
        }
        if ((suspected == 1) && (sirs == 7) && (lactic == 0) && (severe == 1) && (evidence == 1)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <div>This patient meets sepsis criteria. Follow your guidelines for sepsis, which typically include aggressive fluid resuscitation, early, broad-spectrum antibiotics, ICU consultation, CVP evaluation, and occasionally pressors and transfusion..</div>
                </div>
            </div>
        }
        if ((suspected == 0) && (sirs == 5) && (lactic == 1) && (severe == 1) && (evidence == 1)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <div>This patient meets multiple organ dysfunction syndrome..</div>
                </div>
            </div>
        }
        if ((suspected == 1) && (sirs == 5) && (lactic == 1) && (severe == 1) && (evidence == 0)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <div>This patient meets multiple organ dysfunction syndrome..</div>
                </div>
            </div>
        }
        if ((suspected == 0) && (sirs == 5) && (lactic == 0) && (severe == 1) && (evidence == 0)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <div>This patient meets septic shock criteria.</div>
                </div>
            </div>
        }
        if ((suspected == 1) && (sirs == 6) && (lactic == 1) && (severe == 1) && (evidence == 1) && (respiratory == 1) && (wbc == 1)&& (temp ==0 ) && (heart == 0)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <div>This patient meets multiple organ dysfunction syndrome. Follow your guidelines for sepsis, which typically include aggressive fluid resuscitation, early, broad-spectrum antibiotics, ICU consultation, CVP evaluation, and occasionally pressors and transfusion.
                       .</div>
                </div>
            </div>
        }
        if ((suspected == 1) && (sirs == 4) && (lactic == 1) && (severe == 1) && (evidence == 1) && (respiratory == 0) && (wbc == 0)&& (temp ==0 ) && (heart == 0)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container col-12" >
                    <div>This patient meets multiple organ dysfunction syndrome. Follow your guidelines for sepsis, which typically include aggressive fluid resuscitation, early, broad-spectrum antibiotics, ICU consultation, CVP evaluation, and occasionally pressors and transfusion.
                       .</div>
                </div>
            </div>
        }
        if (sirs == 8) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <div>            return <div className="apfel-score-result-container" >
                        <div className="point-result-container" >
                            <div>This patient meets severe sepsis criteria.</div>
                        </div>
                    </div></div>
                </div>
            </div>

        }


        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }

    return (
        <div className="rev">
            <h5 className="text-info pl-2">SIRS, Sepsis, and Septic Shock Criteria</h5>
            <p className="pl-2">Defines the severity of sepsis and septic shock..</p>
            <div class="alert alert-dark" role="alert">
                SIRS Criteria (≥ 2 meets SIRS definition)
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Temp &gt; 38°C (100.4°F) or &lt; 36°C (96.8°F)</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={temp === 0 ? "btn active" : "btn"} onClick={() => setTemp(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }} key="2" className={temp === 1 ? "btn active" : "btn"} onClick={() => setTemp(1)}>Yes<span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Heart rate &gt; 90</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={heart === 0 ? "btn active" : "btn"} onClick={() => setHeart(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }} key="2" className={heart === 1 ? "btn active" : "btn"} onClick={() => setHeart(1)}>Yes<span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Respiratory rate &gt; 20 or PaCO₂ &lt; 32 mm Hg</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={respiratory === 0 ? "btn active" : "btn"} onClick={() => setRespiratory(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }} key="2" className={respiratory === 1 ? "btn active" : "btn"} onClick={() => setRespiratory(1)}>Yes<span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>WBC &gt; 12,000/mm³, &gt; 4,000/mm³, or&gt; 10% bands</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={wbc === 0 ? "btn active" : "btn"} onClick={() => setWbc(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }} key="2" className={wbc === 1 ? "btn active" : "btn"} onClick={() => setWbc(1)}>Yes<span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div class="alert alert-dark" role="alert">
                Sepsis Criteria (SIRS + Source of Infection)

            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Suspected or present source of infectio</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={suspected === 0 ? "btn active" : "btn"} onClick={() => setSuspected(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }} key="2" className={suspected === 1 ? "btn active" : "btn"} onClick={() => setSuspected(1)}>Yes<span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div class="alert alert-dark" role="alert">
                Severe Sepsis Criteria (Organ Dysfunction, Hypotension, or Hypoperfusion)
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Lactic acidosis, SBP &lt;90 or SBP drop ≥ 40 mm Hg of normal</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={lactic === 0 ? "btn active" : "btn"} onClick={() => setLactic(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }} key="2" className={lactic === 1 ? "btn active" : "btn"} onClick={() => setLactic(1)}>Yes<span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div class="alert alert-dark" role="alert">
                Septic Shock Criteria
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Severe sepsis with hypotension, despite adequate fluid resuscitation</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={severe === 0 ? "btn active" : "btn"} onClick={() => setSevere(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }} key="2" className={severe === 1 ? "btn active" : "btn"} onClick={() => setSevere(1)}>Yes<span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div class="alert alert-dark" role="alert">
                Multiple Organ Dysfunction Syndrome Criteria
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Evidence of ≥ 2 organs failing</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={evidence === 0 ? "btn active" : "btn"} onClick={() => setEvidence(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }} key="2" className={evidence === 1 ? "btn active" : "btn"} onClick={() => setEvidence(1)}>Yes<span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="stick pt-3">{
                result1()
            }</div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >International Guidelines for Management of Severe Sepsis and Septic Shock: 2012</p>
                    </div>
                </a>
            </div>
            <div className="p-2" >
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/1303622/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Bone RC, Balk RA, Cerra FB, Dellinger RP, Fein AM, Knaus WA, Schein RM, Sibbald WJ. Definitions for sepsis and organ failure and guidelines for the use of innovative therapies in sepsis. The ACCP/SCCM Consensus Conference Committee. American College of Chest Physicians/Society of Critical Care Medicine.Chest. 1992 Jun;101(6):1644-55.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default CAS1039;
