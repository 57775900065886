export const SET_BLOOD_PRESSURE_SYSTOLIC_BP = "SET_BLOOD_PRESSURE_SYSTOLIC_BP"
export const SET_BLOOD_PRESSURE_DIASTOLIC_BP = "SET_BLOOD_PRESSURE_DIASTOLIC_BP"
export const SET_WEIGHT = "SET_WEIGHT"
export const SET_HEIGHT = "SET_HEIGHT"
export const SET_MOOD = "SET_MOOD"
export const SET_MOOD_OTHER_TEXT = "SET_MOOD_OTHER_TEXT"
export const SET_BMI = "SET_BMI"
export const SET_JAUNDICE = "SET_JAUNDICE"
export const SET_GENERAL_APPERANCE_OTHER_LIST = "SET_GENERAL_APPERANCE_OTHER_LIST"
export const SET_MALLAMPATI_SCORE = "SET_MALLAMPATI_SCORE"
export const SET_MOUTH_OPENING = "SET_MOUTH_OPENING"
export const SET_MOUTH_OPENING_OTHER_TEXT = "SET_MOUTH_OPENING_OTHER_TEXT"
export const SET_BEARDED = "SET_BEARDED"
export const SET_CERVICAL_SPINE_MOBILITY = "SET_CERVICAL_SPINE_MOBILITY"
export const SET_CERVICAL_SPINE_MOBILITY_OTHER_TEXT = "SET_CERVICAL_SPINE_MOBILITY_OTHER_TEXT"
export const SET_NECK_CIRCUMFERENCE_LESS_THAN_42CM = "SET_NECK_CIRCUMFERENCE_LESS_THAN_42CM"
export const TEMPOROMANDIBULAR_JOINT_MOBILITY = "TEMPOROMANDIBULAR_JOINT_MOBILITY"
export const TEMPOROMANDIBULAR_JOINT_MOBILITY_OTHER_TEXT = "TEMPOROMANDIBULAR_JOINT_MOBILITY_OTHER_TEXT"
export const SET_TEETH = "SET_TEETH"
export const SET_THROMENTAL_DISTANCE = "SET_THROMENTAL_DISTANCE"
export const SET_AIRWAY_OTHER_LISTED = "SET_AIRWAY_OTHER_LISTED"
export const SET_AUSCULTATION = "SET_AUSCULTATION"
export const SET_COUGHING = "SET_COUGHING"
export const SET_THORACIC_EXPANSION = "SET_THORACIC_EXPANSION"
export const SET_OXYGEN_SATURATION_ON_ROOM_AIR = "SET_OXYGEN_SATURATION_ON_ROOM_AIR"
export const SET_PULMONARY_OTHER_LISTED = "SET_PULMONARY_OTHER_LISTED"
export const SET_PULSE_RATE = "SET_PULSE_RATE"
export const SET_AUSCULTATION_FOR_MURMURS = "SET_AUSCULTATION_FOR_MURMURS"
export const SET_EDEMA_LOWER_EXTREMITY = "SET_EDEMA_LOWER_EXTREMITY"
export const SET_JUGULAR_VENOUS_DISTENSION = "SET_JUGULAR_VENOUS_DISTENSION"
export const SET_BP = "SET_BP"
export const SET_CARDIOVASCULAR_OTHER_LISTED = "SET_CARDIOVASCULAR_OTHER_LISTED"
export const SET_MOTOR_AND_SENSORY_FUNCTION = "SET_MOTOR_AND_SENSORY_FUNCTION"
export const SET_MOTOR_AND_SENSORY_FUNCTION_OTHER_TEXT = "SET_MOTOR_AND_SENSORY_FUNCTION_OTHER_TEXT"
export const SET_COGNITIVE_FUNCTION = "SET_COGNITIVE_FUNCTION"
export const SET_COGNITIVE_FUNCTION_OTHER_TEXT = "SET_COGNITIVE_FUNCTION_OTHER_TEXT"
export const SET_NEUROLOGIC_OTHER_LISTED = "SET_NEUROLOGIC_OTHER_LISTED"
export const SET_PETECHIAE = "SET_PETECHIAE"
export const SET_BRUISING = "SET_BRUISING"
export const SET_CLINICAL_EVIDENCE_OF_ANEMIA = "SET_CLINICAL_EVIDENCE_OF_ANEMIA"
export const SET_HEMATOLOGIC_OTHER_LISTED = "SET_HEMATOLOGIC_OTHER_LISTED"
export const SET_PERIPHERAL_IV = "SET_PERIPHERAL_IV"
export const SET_GAUGE = "SET_GAUGE"
export const SET_LOCATION = "SET_LOCATION"
export const SET_CENTRAL_IV_ULTRASOUND_FOR_LINE_PLACEMENT = "SET_CENTRAL_IV_ULTRASOUND_FOR_LINE_PLACEMENT"
export const SET_CENTRAL_IV_CATHERTER_TYPE = "SET_CENTRAL_IV_CATHERTER_TYPE"
export const SET_CENTRAL_IV_CATHERTER_TYPE_OTHER_TEXT = "SET_CENTRAL_IV_CATHERTER_TYPE_OTHER_TEXT"
export const SET_CENTRAL_IV_PULMONARY_ARTERIAL_CATHETER = "SET_CENTRAL_IV_PULMONARY_ARTERIAL_CATHETER"
export const SET_ARTERIAL_ULTRASOUND_FOR_LINE_PLACEMENT = "SET_ARTERIAL_ULTRASOUND_FOR_LINE_PLACEMENT"
export const SET_ARTERIAL_GUAGE = "SET_ARTERIAL_GUAGE"
export const SET_ARTERIAL_LOCATION = "SET_ARTERIAL_LOCATION"
export const SET_ARTERIAL_LOCATION_OTHER_LIST = "SET_ARTERIAL_LOCATION_OTHER_LIST"
export const SET_PHYSICAL_EXAM_OTHER_LISTED = "SET_PHYSICAL_EXAM_OTHER_LISTED"
export const SET_AUSCULTATION_MAIN_TYPE = "SET_AUSCULTATION_MAIN_TYPE"
export const SET_AUSCULTATION_SUB_TYPE = "SET_AUSCULTATION_SUB_TYPE"
export const SET_AUSCULTATION_SUB_SUB_TYPE = "SET_AUSCULTATION_SUB_SUB_TYPE"
export const SET_MURMUR_PRESENT = "SET_MURMUR_PRESENT"
export const SET_MURMUR_TYPE_LOCATION = "SET_MURMUR_TYPE_LOCATION"






































