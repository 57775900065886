import React, { useEffect, useState } from "react";
import "./CAS1091.css";

const CAS1091 = () => {
  const [green, setGreen] = useState(0);
  const [tag, setTag] = useState(0);
  const [breathing, setBreating] = useState(0);
  const [movement, setMovement] = useState(0);
  const [tone, setTone] = useState(0);
  const [afv, setAfv] = useState(0);
  const [nst, setNst] = useState(0);
  const [nst2, setNst2] = useState(0);
  const [result, setResult] = useState(0);

  const postData = async () => {
    setResult(breathing + movement + tone + afv + nst + nst2);

    setGreen(true);
  };
  useEffect(() => {
    postData();
  }, [breathing, movement, tone, afv, nst, nst2]);

  const afterPhysicalChange = () => {
    if (tag == 1) {
      return (
        <div>
          <div className="alert alert-dark">
            Cases of severe fetal anemia, i.e., in hemolytic disease of the
            fetus and newborn (HDFN).
            <br />
            Hemolytic disease of the fetus and newborn (HDFN) can occur when
            pregnant females have an RBC alloantibody directed against an
            antigen expressed on fetal RBCs. When alloimmunization is detected
            during pregnancy, there is generally regular monitoring of both the
            antibody titer and the fetal middle cerebral artery ultrasound for
            signs of anemia. In instances of severe fetal anemia, IUT may be
            used as a treatment and to suppress fetal erythropoiesis.
            <br />
            There are other rare indications for IUT that can be determined by
            specialists in high risk pregnancy.
          </div>
        </div>
      );
    }
    if (tag == 2) {
      return (
        <div>
          <div className="alert alert-dark">
            TDetermining fetal weight and fetal hematocrit both involve
            ultrasound-based estimations.
            <br />
            A pre-transfusion specimen at the time of umbilical vein access can
            be used for a direct antiglobulin test (DAT), fetal blood type, RBC
            antigen typing, bilirubin, hemoglobin/hematocrit, and platelet
            count.
            <br />
            Transfused blood for IUT should be group O, negative for antigens
            against which mother has alloantibodies, crossmatch compatible with
            mother’s plasma, irradiated, CMV seronegative and/or leukocyte
            reduced, and hemoglobin S negative.
            <br />
            Some experts recommend upper transfusion volume limits for IUT in
            order to avoid complications such as hyperviscosity and increased
            umbilical venous pressure.
            <br />
            Hematocrit of tranfused units varies by the type of storage
            solution, ranging between 55-85%; for specific information, contact
            your local blood bank or maternal-fetal medicine expert.
          </div>
        </div>
      );
    }
    if (tag == 3) {
      return (
        <div>
          <div className="alert alert-dark">
            HDFN can have severe consequences, including fetal loss. While IUT
            can successfully treat HDFN in many instances, it also comes with
            associated risks. Determining proper volume of RBCs to transfuse to
            appropriately increase fetal hematocrit is an important step in this
            process.
          </div>
        </div>
      );
    }
  };
  const Newfiels = () => {
    if (breathing == 0 && movement == 0 && tone == 0 && afv == 0) {
      return (
        <div>
          <div className="fields row">
            <div className="col-md-6">
              <div className="pt-2">
                <strong>Nonstress test (NST)</strong>
              </div>
            </div>
            <div className="col-md-6 btn-group-vertical options-btn mb-2">
              <button
                style={{ height: "100%" }}
                onClick={() => setNst(0)}
                className={
                  nst === 0 ? "btn  text-left active" : "btn  text-left"
                }
              >
                <span className="btn-name ">
                  0 or 1 acceleration within 20–40 min
                </span>
                <span className="calc-val ">0</span>
              </button>
              <button
                style={{ height: "100%" }}
                onClick={() => setNst(2)}
                className={
                  nst === 2 ? "btn  text-left active" : "btn  text-left"
                }
              >
                <span className="btn-name ">
                  ≥2 accelerations of ≥15 beats/min for ≥15 sec within 20–40 min
                </span>
                <span className="calc-val ">+2</span>
              </button>
            </div>
          </div>
        </div>
      );
    }
    if (breathing == 2 && movement == 2 && tone == 2 && afv == 2) {
      return (
        <div>
          <div className="fields row">
            <div className="col-md-6">
              <div className="pt-2">
                <strong>Nonstress test (NST)</strong>
              </div>
              <div>May omit if all four sonographic components are normal</div>
            </div>
            <div className="col-md-6 btn-group-vertical options-btn mb-2">
              <button
                style={{ height: "100%" }}
                onClick={() => setNst2(0)}
                className={
                  nst2 === 0 ? "btn  text-left active" : "btn  text-left"
                }
              >
                <span className="btn-name ">
                  0 or 1 acceleration within 20–40 min
                </span>
                <span className="calc-val "></span>
              </button>
              <button
                style={{ height: "100%" }}
                onClick={() => setNst2(2)}
                className={
                  nst2 === 2 ? "btn  text-left active" : "btn  text-left"
                }
              >
                <span className="btn-name ">
                  ≥2 accelerations of ≥15 beats/min for ≥15 sec within 20–40 min
                </span>
                <span className="calc-val "></span>
              </button>
              <button
                style={{ height: "100%" }}
                onClick={() => setNst2(3)}
                className={
                  nst2 === 3 ? "btn  text-left active" : "btn  text-left"
                }
              >
                <span className="btn-name "> NST not done</span>
                <span className="calc-val "></span>
              </button>
            </div>
          </div>
        </div>
      );
    }
  };
  const result1 = () => {
    if (result >= 0 && result <= 2) {
      return (
        <div className="apfel-score-result-container">
          <div className="point-result-container">
            <h2>
              {" "}
              {result}{" "}
              <span
                style={{ fontWeight: 400, fontSize: "1rem", color: "silver" }}
              >
                points
              </span>
            </h2>
            <div>Almost certain fetal asphyxia</div>
          </div>
          <div className="point-result-container">
            <div>Delivery recomended</div>
          </div>
        </div>
      );
    }
    if (result == 4) {
      return (
        <div className="apfel-score-result-container">
          <div className="point-result-container">
            <h2>
              {" "}
              {result}{" "}
              <span
                style={{ fontWeight: 400, fontSize: "1rem", color: "silver" }}
              >
                points
              </span>
            </h2>
            <div>Probable fetal asphyxia</div>
          </div>
          <div className="point-result-container">
            <div>Repeat testing same day; if ≤6, delivery recommended</div>
          </div>
        </div>
      );
    }
    if (result == 6) {
      return (
        <div className="apfel-score-result-container">
          <div className="point-result-container">
            <h2>
              {" "}
              {result}{" "}
              <span
                style={{ fontWeight: 400, fontSize: "1rem", color: "silver" }}
              >
                points
              </span>
            </h2>
            <div>Possible fetal asphyxia, normal AFV</div>
          </div>
          <div className="point-result-container">
            <div>
              Delivery recommended if {">"}36 weeks with favorable cervix or repeat
              test ≤6; if repeat test {">"}6, observe and repeat per protocol
            </div>
          </div>
        </div>
      );
    }
    if (result == 8) {
      return (
        <div className="apfel-score-result-container">
          <div className="point-result-container">
            <h2>
              {" "}
              {result}{" "}
              <span
                style={{ fontWeight: 400, fontSize: "1rem", color: "silver" }}
              >
                points
              </span>
            </h2>
            <div>Normal, non-asphyxiated fetus (normal AFV)</div>
          </div>
          <div className="point-result-container">
            <div>
              No fetal indication for intervention; repeat testing per protocol
            </div>
          </div>
        </div>
      );
    }
    if (result == 10) {
      return (
        <div className="apfel-score-result-container">
          <div className="point-result-container">
            <h2>
              {" "}
              {result}{" "}
              <span
                style={{ fontWeight: 400, fontSize: "1rem", color: "silver" }}
              >
                points
              </span>
            </h2>
            <div>Normal, non-asphyxiated fetus</div>
          </div>
          <div className="point-result-container">
            <div>
              No fetal indication for intervention; repeat test weekly except in
              diabetic patients and post-term pregnancy (twice weekly)
            </div>
          </div>
        </div>
      );
    }
    if (nst2 == 3) {
      return (
        <div className="apfel-score-result-container">
          <div className="point-result-container">
            <h2>
              {" "}
              {8}{" "}
              <span
                style={{ fontWeight: 400, fontSize: "1rem", color: "silver" }}
              >
                points
              </span>
            </h2>
            <div>Normal, non-asphyxiated fetus (normal AFV)</div>
          </div>
          <div className="point-result-container">
            <div>
              No fetal indication for intervention; repeat testing per protocol
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-white p-2 green-bg">
          <h1>Result:</h1>
          <br />
          <h6>Please fill out required fields.</h6>
        </div>
      );
    }
  };

  return (
    <div className="rev">
      <h5 className="text-info pl-2">Fetal Biophysical Profile (FBP) Score</h5>
      <p className="pl-2">
        Predicts need for urgent delivery based on sonographic and nonstress
        testing..
      </p>
      <div className="fields row">
        <div className="col-md-9 btn-group pl-4">
          <button
            style={{ height: 40 }}
            className={tag === 1 ? "btn active text-white" : "btn "}
            onClick={() => setTag(1)}
          >
            When to Use <span className="calc-val text-right"></span>
          </button>
          <button
            style={{ height: 40 }}
            className={tag === 2 ? "btn active text-white" : "btn "}
            onClick={() => setTag(2)}
          >
            Pearls/Pitfalls<span className="calc-val text-right"></span>
          </button>
          <button
            style={{ height: 40 }}
            className={tag === 3 ? "btn active text-white" : "btn "}
            onClick={() => setTag(3)}
          >
            Why Use<span className="calc-val text-right"></span>
          </button>
        </div>
      </div>
      <div> {afterPhysicalChange()}</div>
      <div className="fields row">
        <div className="col-md-6">
          <div className="pt-2">
            <strong>Fetal breathing</strong>
          </div>
        </div>
        <div className="col-md-6 btn-group-vertical options-btn mb-2">
          <button
           style={{ height: 40 }}
            onClick={() => setBreating(0)}
            className={
              breathing === 0 ? "btn  text-left active" : "btn  text-left"
            }
          >
            <span className="btn-name ">
              &lt; 30 sec of breathing within 30 min
            </span>
            <span className="calc-val ">0</span>
          </button>
          <button
           style={{ height: 60 }}
            onClick={() => setBreating(2)}
            className={
              breathing === 2 ? "btn  text-left active" : "btn  text-left"
            }
          >
            <span className="btn-name ">
              ≥1 episode of rhythmic breathing<br/> lasting ≥30 sec within 30 min
            </span>
            <span className="calc-val ">+2</span>
          </button>
        </div>
      </div>
      <div className="fields row">
        <div className="col-md-6">
          <div className="pt-2">
            <strong>Fetal movement</strong>
          </div>
        </div>
        <div className="col-md-6 btn-group-vertical options-btn mb-2">
          <button
            style={{ height: 40 }}
            onClick={() => setMovement(0)}
            className={
              movement === 0 ? "btn  text-left active" : "btn  text-left"
            }
          >
            <span className="btn-name ">&lt; 3 discrete movements</span>
            <span className="calc-val ">0</span>
          </button>
          <button
            style={{ height: 60 }}
            onClick={() => setMovement(2)}
            className={
              movement === 2 ? "btn  text-left active" : "btn  text-left"
            }
          >
            <span className="btn-name ">
              ≥3 discrete body or limb<br/> movements within 30 min
            </span>
            <span className="calc-val ">+2</span>
          </button>
        </div>
      </div>
      <div className="fields row">
        <div className="col-md-6">
          <div className="pt-2">
            <strong>Fetal tone</strong>
          </div>
        </div>
        <div className="col-md-6 btn-group-vertical options-btn mb-2">
          <button
            style={{ height: 40 }}
            onClick={() => setTone(0)}
            className={tone === 0 ? "btn  text-left active" : "btn  text-left"}
          >
            <span className="btn-name ">0 extension/flexion events</span>
            <span className="calc-val ">0</span>
          </button>
          <button
           style={{ height: 60 }}
            onClick={() => setTone(2)}
            className={tone === 2 ? "btn  text-left active" : "btn  text-left"}
          >
            <span className="btn-name ">
              ≥1 episode of extremity extension and<br/> subsequent return to flexion
            </span>
            <span className="calc-val ">+2</span>
          </button>
        </div>
      </div>
      <div className="fields row">
        <div className="col-md-6">
          <div className="pt-2">
            <strong>Amniotic fluid volume (AFV)</strong>
          </div>
          <div>If largest vertical amniotic fluid pocket ≤2 cm</div>
        </div>
        <div className="col-md-6 btn-group-vertical options-btn mb-2">
          <button
            style={{ height: 40 }}
            onClick={() => setAfv(0)}
            className={afv === 0 ? "btn  text-left active" : "btn  text-left"}
          >
            <span className="btn-name ">
              Largest single vertical pocket ≤2 cm
            </span>
            <span className="calc-val ">0</span>
          </button>
          <button
            style={{ height: 60 }}
            onClick={() => setAfv(2)}
            className={afv === 2 ? "btn  text-left active" : "btn  text-left"}
          >
            <span className="btn-name ">
              A pocket of amniotic fluid ≥2 cm in 2<br/> perpendicular planes (2x2 cm
              pocket)
            </span>
            <span className="calc-val ">+2</span>
          </button>
        </div>
      </div>
      <div>{Newfiels()}</div>
      <div className="stick pt-2 mt-5">{result1()}</div>
      <div className="p-2">
        <h6>ORIGINAL/PRIMARY REFERENCE</h6>
        <a
          className="referenceLink"
          target="_blank"
          href="https://pubmed.ncbi.nlm.nih.gov/7355965/"
        >
          <div className="reference-container-card">
            <div className="reference-img">
              <img
                src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png"
                alt="Research Paper"
              />
            </div>
            <p className="reference-paragraph">
              Manning FA, Platt LD, Sipos L. Antepartum fetal evaluation:
              development of a fetal biophysical profile. Am J Obstet Gynecol.
              1980;136(6):787-95..
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default CAS1091;
