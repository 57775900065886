import React, { useEffect, useState } from 'react';
import user from "../images/user-imgss.jpg";
import { Link } from 'react-router-dom'
import { API_ROOT } from '../constants'

const EditProfile = (props) => {
    const [firstName, setfirstName] = useState(null);
    const [lastName, setlastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [countrycode,setCountrycode]=useState(null)
    const [mobile, setMobile] = useState(null);
    const [profileImg,setProfileImg]=useState({img_url:"", img_data:"", img_name:""});

    useEffect(() => {
        getProfile()
    }, []);

    const handleChangeUploadImage = e => {
        if (e.target.files.length) {
            setProfileImg({...profileImg,
                ['img_url']: URL.createObjectURL(e.target.files[0]),
                ['img_data']: e.target.files[0],
                ['img_name']: e.target.files[0].name
            });
        }
    };

    // console.log("profile img = ", profileImg); 

    const saveUserProfile = () => {
        if (firstName === "") {
            alert("Please enter first name")
        } if (lastName === "") {
            alert("Please enter last name")

        }
        else if (email === "") {
            alert("please enter email");
        } else if (mobile === "") {
            alert("please enter phoneNumber");
        } else {
            var token = sessionStorage.getItem("token");

            let formdata = new FormData();

            formdata.append("profile_photo_path", profileImg.img_data);
            formdata.append("email", email);
            formdata.append("first_name", firstName);
            formdata.append("last_name", lastName);
            formdata.append("country_code", countrycode);
            formdata.append("mobile_number", mobile);


            fetch(API_ROOT + `update-profile`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: formdata

            })
            .then(response => response.json())
            .then(res => {
                if (res.status === "true") {
                    alert(res.message);
                    props.history.push({
                        pathname: "/myprofile",
                });
                }
            })
        }

    }
    const editBtnClick = () => {
        document.getElementById('myFile').click();
    }


    const getProfile = () => {
        var token = sessionStorage.getItem("token")
        fetch(API_ROOT + `get-profile`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(res => {
            if (res.status === "true" && res.message === "User profile data.") {

                setfirstName(res.data.first_name);
                setlastName(res.data.last_name);
                setEmail(res.data.email);
                setCountrycode(res.data.country_code);
                setMobile(res.data.mobile_number);
                setProfileImg({...profileImg, ['img_url']: res.data.profile_photo_url});
            } else {
                alert('data not found.');
            }
        })
    }

    return (
        <>
            <div className="backNavigation text-center">
                EDIT PROFILE
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-12">
                        <div className="profile-container">
                            <div className="user-profile">
                           
                                <img src={profileImg.img_url} className="user-profile-pic" />

                                <input type="file" id="myFile" name="filename" onChange={handleChangeUploadImage} accept="image/*" multiple="false"/>
                                <i className="fa fa-pencil edit-image" onClick={editBtnClick}></i>
                               
                                {/* <input onChange={(e) => setFile(e.target.files)} id="file" type="file" className="form-control"/> */}
                                <h3 className="username">{firstName === null ? "" : firstName}  {lastName === null ? "" : lastName}</h3>

                                <p className="user-email">{email === null ? "" : email}</p>
                            </div>
                            <div className="user-profile-show">
                                <ul>

                                    <li className="mb-3">
                                        <label>First name</label>
                                        <input value={firstName} onChange={(e) => setfirstName(e.target.value)} id="first-name" className="form-control" aria-describedby="emailHelp" placeholder="Enter First Name" />
                                    </li>
                                    <li className="mb-3">
                                    <label>Last name</label>
                                        <input value={lastName} onChange={(e) => setlastName(e.target.value)} id="last=name" className="form-control" aria-describedby="emailHelp" placeholder="Enter Last Name" />
                                    </li>
                                    <li className="mb-3">
                                    <label>Email address</label>
                                        <input value={email} onChange={(e) => setEmail(e.target.value)} id="email" type="email" className="form-control" aria-describedby="emailHelp" placeholder="Enter Email Id" />

                                    </li>
                                    <li className="mb-3">
                                        <label>Phone number</label>
                                        <div class="input-group">
                                        <div class="input-group-prepend">
                                        <select className="form-control" value={countrycode} onChange={(e)=>{setCountrycode(e.target.value)}}>
                                        <option value="1">+1(USA)</option>
                                        <option value="91">+91(IND)</option></select>
                                    </div>

                                        <input value={mobile} onChange={(e) => setMobile(e.target.value)} id="mobile" type="numeric" className="form-control" aria-describedby="emailHelp" placeholder="Enter Phone no" />
                                        </div>
                                    </li>
                                    <li className="mb-3" >
                                        <div onClick={() => saveUserProfile()} className="save-button-container mt-3 mb-3" style={{margin:'0 auto'}} >
                                            UPDATE
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* profile part ends here */}

        </>
    )
}

export default EditProfile;