import React, { useEffect, useState } from 'react'
import './Print.css'
import axios from 'axios'
import { API_ROOT } from '../../../constants'

const Print = (props) => {

  const [caseInfo, setcaseInfo] = useState(null)
  const [otherInfo, setotherInfo] = useState(null)

  useEffect(() => {
    getCaseSummaryDataAsPerId(props.match.params.id)
  }, [])

  const getCaseSummaryDataAsPerId = async (caseId) => {
    try {
      const token = sessionStorage.getItem("token")
      const res = await axios.get(API_ROOT + `case-summary-by-id/${caseId}?token=${token}`)
      setcaseInfo(res.data.data)
      setotherInfo(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  const formatDate = (date) => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }


  return (
    <div style={{ display: "flex", justifyContent: 'center' }}>
      <div style={{ width: 1140 }}>
        <button className='print-button' onClick={() => window.print()}>Print Case Summary</button>
        <div className='print-container' id="print">
          <div className='row1'>
            {(caseInfo?.full_name!=null) &&
              <div>Anesthesia One user : {caseInfo?.full_name}</div>
            }
            </div>
            <div className='row1 pt-1'>
            {(caseInfo?.age!=null ||caseInfo?.age_type!=null) &&
              <div>Age : <span>{caseInfo?.age} {caseInfo?.age ? caseInfo?.age_type: ""}</span></div>
            }
            {(caseInfo?.id!=null) &&
        <div>Case No : <span>{caseInfo?.id}</span></div>
           
            }
            {(caseInfo?.gender!=null)&&
              <div>Gender : <span>{caseInfo?.gender}</span></div>
            
            }
            {(caseInfo?.patient_type_name!=null)&&
 <div>Patient Type : <span>{caseInfo?.patient_type_name}</span></div>
           
            }
            {(caseInfo?.weight!=null||caseInfo?.weight_type!=null)&&
  <div>Weight : <span>{caseInfo?.weight} {caseInfo?.weight ? caseInfo?.weight_type: ""}</span></div>
         
            }
            {(caseInfo?.height!=null||caseInfo?.height_type!=null)&&
 <div>Height : <span>{caseInfo?.height} {caseInfo?.height ? caseInfo?.height_type: ""}</span></div>
            
            }
            {(caseInfo?.status!=null)&&
   <div>Status : <span>{caseInfo?.status}</span></div>
         
            }
            {formatDate(caseInfo?.created_at)!=null &&
   <div>Register on : <span>{caseInfo?.created_at == null?"":formatDate(caseInfo?.created_at)}</span></div>
         
            }
          </div>

          <div className='row2'>
            {otherInfo?.asa_ps?.length?
              <div className='title'>ASA-PS :
              <div className='subtitle'>
                {
                  otherInfo?.asa_ps?.map(data => {
                    return <span key={data.id}>{data.name}</span>
                  })
                }
              </div>
            </div>:<></>
            }
            
            {otherInfo?.surgeryTypes?.length?
              <div className='title'>Surgery Type :
              <div className='subtitle'>
                {
                  otherInfo?.surgeryTypes?.map(data => {
                    return <span key={data.id}>{data.name}</span>
                  })
                }
              </div>
            </div>:<></>

            }
            
            {otherInfo?.anesthesiaTypes?.length?
              <div className='title'>Anesthesia Type :
              <div className='subtitle'>
                {
                  otherInfo?.anesthesiaTypes?.map(data => {
                    return <span key={data.id}>{data.name}</span>
                  })
                }
              </div>
            </div>:
            <></>
            }
            {caseInfo?.npo_status_materials!=null&&
              <div className='title'>NPO Status :
              <div className='subtitle'>
                {
                  <span>{caseInfo?.npo_status_materials}</span>
                }
              </div>
            </div>
            }
            
            {otherInfo?.medications?.length?
              <div className='title'>Medications :
              <div className='subtitle'>
                {
                  otherInfo?.medications?.map(data => {
                    return <span key={data.id}>{data.name}</span>
                  })
                }
              </div>
            </div>:<></>
            }
            
            {otherInfo?.allergies?.length?
              <div className='title'>Allergies :
              <div className='subtitle'>
                {
                  otherInfo?.allergies?.map(data => {
                    return <span key={data.id}>{data.name}</span>
                  })
                }
              </div>
            </div>:<></>
            }
            
            {otherInfo?.midical_histories?.length?
              <div className='title'>Medical History :
              <div className='subtitle'>
                {
                  otherInfo?.midical_histories?.map(data => {
                    return <span key={data.id}>{data.name}</span>
                  })
                }
              </div>
            </div>:<></>
            }
            
            {otherInfo?.surgeryHistories?.length?
              <div className='title'>Surgical History :
              <div className='subtitle'>
                {
                  otherInfo?.surgeryHistories?.map(data => {
                    return <span key={data.id}>{data.name}</span>
                  })
                }
              </div>
            </div>:<></>
            }
            
            {otherInfo?.anesthesia_histories?.length?
              <div className='title'>Anesthesia History :
              <div className='subtitle'>
                {
                  otherInfo?.anesthesia_histories?.map(data => {
                    return <span key={data.id}>{data.name}</span>
                  })
                }
              </div>
            </div>:<></>
            }
            
            {caseInfo?.case_note_message!=null&&
              <div className='title'>Case Note :
              <div className='subtitle'>
                <span>{caseInfo?.case_note_message}</span>
              </div>
            </div>
            }
            
            {caseInfo?.case_note_image&&
              <div className='title'>Case Image :
              <div className='subtitle'>
                <img src={caseInfo?.case_note_image} alt="" />
              </div>
            </div>
            }
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Print