import React, { useEffect, useState, useContext } from 'react'
import "./index.css"
import { Link } from 'react-router-dom'
import { API_ROOT } from '../../../constants'
import axios from 'axios'
import { Button, Modal } from 'react-bootstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css';
import Spinner from '../../../CustomComponent/Spinner';
import AppContext from '../../../contextStore/DataProvider'
// import AppContext from '../contextStore/DataProvider'

const Crises = (props) => {
    const [confirm_setconfirm_diagnosis, setconfirm_diagnosis] = useState([])
    const [differential_diagnosis, setdifferential_diagnosis] = useState([])
    const [Treatment, setTreatment] = useState([])
    const [Drugs, setDrugs] = useState([])
    const [crisis_Management, setCrisis_Management] = useState([])
    const [referenceData, setreferenceData] = useState([])
    const [value, setValue] = useState();
    const [display_cd, setDisplay_cd] = useState('false');
    const [display_dd, setDisplay_dd] = useState('false');
    const [display_tx, setDisplay_tx] = useState('false');
    const [display_drg, setDisplay_drg] = useState('false');
    const [display_cr, setDisplay_cr] = useState('false');
    const [display_ref, setDisplay_ref] = useState('false');
    const [selectedTiles, setselectedTiles] = useState("")
    const [referenceArray] = useState([])
    const [refOfPages, setrefOfPages] = useState([])
    const [keywords, setKeywords] = useState([])
    const [show, setShow] = useState(false);
    const [largeImg, setLargeImg] = useState([]);
    const [showNav, setShowNav] = useState([]);
    const [loading, setLoading] = useState(false);
    const [keys, setKeys] = useState([]);
    const window_width = window.innerWidth;
    const handleClose = () => { setShow(false) };
    const handleShow = (e) => {
        setLargeImg(e.target.src);
        setShow(true);

    };
    const context = useContext(AppContext)

    useEffect(() => {
        let path = window.location.pathname;
        let param_length = path.split(':').length;
        let params = window.location.pathname.split(':')[param_length - 1];
        params = params.replace(/%20/g, " ")
        let exp = params.replace(/~/g, "/")
        setValue(exp);
        let texts = ['ASYSTOLE', 'BRADYCARDIA - UNSTABLE','BROAD DIAGNOSIS', 'PEA', 'SVT - STABLE', 'SVT - UNSTABLE','SVT - UNSTABLE', 'VT/VF - PULSELESS', 'OBSTETRIC - ACLS & ATLS', 'AFE', 'ANAPHYLAXIS', 'Anaphylaxis', 'ASYSTOLE', 'BRADYCARDIA - UNSTABLE', 'LAST','Local anesthetic toxicity', 'PEA', 'Hypotension', 'HYPERTENSION', 'HYPOTENSION', 'TACHYCARDIA']
        // let texts = ['PEA', 'ANAPHYLAXIS', 'ASPIRATION', 'ASYSTOLE','BRADYCARDIA - UNSTABLE','BRONCHOSPASM', 'DELAYED EMERGENCE', 'DIFFICULT AIRWAY','ELEVATED AIRWAY PRESSURE','PALS - COMING SOON', 'HEMORRHAGE','VAE','MH', 'MI', 'OXYGEN FAILURE', 'LAST', 'OBSTETRIC - ACLS & ATLS','POWER FAILURE', 'PNEUMOTHORAX', 'POST-PARTUM HEMORRHAGE', 'URGENT/EMERGENT C-SECTION',  'ACLS & ATLS', 'NEWBORN RESUSCITATION','TOTAL SPINAL', 'MATERNAL COLLAPSE', 'HYPERTENSIVE DISORDERS','TRANSFUSION REACTIONS', 'VF/VF - PULSELESS', 'ASPIRATION','BRONCHOSPASM', 'DELAYED EMERGENCE', 'DIFFICULT AIRWAY', 'ELEVATED AIRWAY PRESSURE', 'FIRE - AIRWAY', 'FIRE - PATIENT', 'HEMORRHAGE', 'HYPERKALEMIA', 'LARYNGOSPASM','BRADYCARDIA', 'HYPOXEMIA', 'HYPOCAPNEA', 'HYPERCAPNEA']
        //    let texts = []
        //    JSON.stringify(sessionStorage.getItem('subcrises')).forEach(f => {
        //     texts.push(f)
        //    });
        texts = [...new Set(texts)];
        setKeywords(texts);
        setShowData([])
        setconfirm_diagnosis([])
        setDisplay_cd(false)
        setdifferential_diagnosis([])
        setDisplay_dd(false)
        setTreatment([])
        setDisplay_tx(false)
        setDrugs([])
        setDisplay_drg(false)
        setCrisis_Management([])
        setDisplay_cr(false)
        setDisplay_ref(false)
        setselectedTiles([])
        setrefOfPages([])
        referenceArray.length = 0
        callApi();

    }, [props.data?.name])


    const callApi = async () => {
        setLoading(true)
        try {
            const token = sessionStorage.getItem('token')
            const body = {
                "name": props.data?.name,
                "parent_name": props.data?.parent_name
            }
            const url = API_ROOT + `crises-data?token=${token}`
            const res = await axios.post(url, body, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })

            let x = [];

            if (res.data.status === "true") {

                //  res.data.data
                for (const key of Object.keys(res.data.data)) {
                    x.push(key)

                    if (key === "Confirm Diagnosis") {
                        setShowData(res.data.data[key].crises_sub_type)
                        setconfirm_diagnosis(res.data.data[key].crises_sub_type)
                        if (res.data.data[key].crises_sub_type.length !== 0) {
                            setDisplay_cd(true)
                        }
                    }
                    if (key === "Differential Diagnosis") {
                        setdifferential_diagnosis(res.data.data[key].crises_sub_type)
                        if (res.data.data[key].crises_sub_type.length !== 0) {
                            setDisplay_dd(true)

                        }
                    }
                    if (key === "Treatment") {
                        setTreatment(res.data.data[key].crises_sub_type)
                        if (res.data.data[key].crises_sub_type.length !== 0) {
                            setDisplay_tx(true)
                        }
                    }
                    if (key === "Drugs") {
                        setDrugs(res.data.data[key].crises_sub_type)
                        if (res.data.data[key].crises_sub_type.length !== 0) {
                            setDisplay_drg(true)
                        }

                    }

                    if (key === "Crisis Management") {
                        setCrisis_Management(res.data.data[key].crises_sub_type)
                        if (res.data.data[key].crises_sub_type.length !== 0) {
                            setDisplay_cr(true)
                        }
                    }
                    if (key === "Reference") {
                        res.data.data[key].crises_sub_type.map((data) => {
                            referenceArray.push(data)
                        })
                        setDisplay_ref(true)
                        //referenceArray.push(res.data.data[key].crises_sub_type)
                        res.data.data[key].crises_sub_type.map((data) => {
                            if (data.name === "img") {
                                var array = data.image.split(",")
                                setselectedTiles(array[0])
                                setrefOfPages(array)

                            }
                        })

                    }
                }
                setKeys(x)

            }
            setLoading(false)

        }
        catch (error) {
        }

    }





    const [showData, setShowData] = useState([])
    const array = [
        {
            name: "Confirm Diagnosis",
            dn: "Dx",
            show: display_cd,
        },
        {
            name: "Differential Diagnosis",
            dn: "DDx",
            show: display_dd
        },
        {
            name: "Treatment",
            dn: "Tx",
            show: display_tx
        },
        {
            name: "Drugs",
            dn: "Drugs",
            show: display_drg
        },
        {
            name: "Crises Management",
            dn: "Crises",
            show: display_cr
        },
        {
            name: "Reference",
            dn: "Ref",
            show: display_ref
        },

    ]


    const handleData = (data) => {
        // alert(JSON.stringify(data))
        setShowNav(data.name)
        if (data.name === "Confirm Diagnosis") {
            setShowData(confirm_setconfirm_diagnosis)
        }
        else if (data.name === "Differential Diagnosis") {

            setShowData(differential_diagnosis)
        }
        else if (data.name === "Treatment") {
            setShowData(Treatment)
        }
        else if (data.name === "Drugs") {

            setShowData(Drugs)
        }
        else if (data.name === "Crises Management") {

            setShowData(crisis_Management)
        }
        else if (data.name === "Reference" || data.name === "Ref") {
            setShowData(refOfPages)
        }
    }
  const linking = () => {
    
  }
    const wrapKeywordWithLink = (keyword, link) => {
        return `<a href=${link}>${keyword}</a>`
        // return (`${`<span onClick=${linking} style={{color:'blue'}}>${keyword.trim()}</span>`}`)
    }
   
    const transformContent = (content, keywords) => {
        let temp = content

        keywords.forEach(keyword2 => {
            let endp = keyword2.split(" ").join("")
            temp = temp.replace(keyword2.trim(), wrapKeywordWithLink(keyword2, "/last:" + `${endp.trim()}`));
        })
        return temp
    }
    const clickonLink = (name) => {
        props.history.push({
            pathname: "/last",
            state: {
                name: name,
                sourceName: 'Action Library',
                returnPath: 'allaction'
            }
        })
    }

    useEffect(() => {
        if (keys.length > 0) {
            if (keys.includes("Confirm Diagnosis")) {
                setShowNav('Dx')
                setShowData(confirm_setconfirm_diagnosis)
            } else {
                setShowNav('DDx')
                setShowData(differential_diagnosis)
            }
        }
    }, [keys])


    let data = ["C1277", "C1278", "C1279", "C1280", "C1281", "C1282", "C1283", "C1284", "C1285", "C1286", "C1287", "C1288", "C1289", "C1290", "C1291", "C1292", "C1293", "C1294", "C1295", "C1296", "C1297", "C1298", "C1299", "C1300", "C1301", "C1302", "C1303", "C1304", "C1305", "C1306", "C1307", "C1308", "C1309", "C1310", "C1311", "C1312"]

    return (
        <div>

            <div>

                <div className="result-action rev w-100">
              
                    <h5 className="text-info pl-2 text-center">{props.data?.name}</h5>

                <>
                    <div className="bar">
                        {
                            array.map((data, key) => {
                                return (
                                    <>
                                        {
                                            showNav === "" ?
                                                <>
                                                    <button key={key} className="normal-p btn " onClick={() => handleData(data)} title={data.name}>{window_width >= 360 && window_width <= 428 ? <>{data?.dn}</> : <>{data?.name}</>}</button>
                                                </>
                                                :
                                                <>
                                                    {
                                                        showNav === data.dn || showNav === data.name ?
                                                            <>  {data.show === true ?
                                                                <button title={data.name} key={key} className="normal-p btn active-nav" onClick={() => handleData(data)}>{window_width >= 360 && window_width <= 428 ? <>{data?.dn}</> : <>{data?.name}</>}</button> : <></>
                                                            }
                                                            </>
                                                            :
                                                            <>  {data.show === true ?
                                                                <button key={key} className="normal-p btn" onClick={() => handleData(data)} title={data.name}>
                                                                    {window_width >= 360 && window_width <= 428 ? <>{data?.dn}</> : <>{data?.name}</>}
                                                                </button> : <></>

                                                            }

                                                            </>
                                                    }
                                                </>
                                        }
                                    </>
                                )
                            })
                        }
                    </div>

                    <div className="data-shown">
                        {
                            showData.map((data, key) => {
                                if (showNav === "Reference") {
                                    return (
                                        <>
                                        <div className="ref-card-wrapper bg-light">
                                            <Tabs  style={{width:'100%'}}>
                                                <TabList >
                                                    <Tab>Links</Tab>
                                                    <Tab>Images</Tab>
                                                </TabList>
                                                <TabPanel>
                                                    {
                                                        referenceArray.length ?
                                                            <ul>
                                                                {
                                                                    referenceArray.map((data) => {
                                                                        if (data.name === "ref") {
                                                                            return (
                                                                                <li className="ref-card1" style={{ listStyleType: 'square', borderBottom: '1px solid silver' }}

                                                                                >
                                                                                    <Link onClick={() => window.open(data.short_url, "_blank")} >
                                                                                        <label style={{cursor:'pointer'}}>{data.reference}</label>
                                                                                    </Link >
                                                                                </li>

                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </ul> : <></>
                                                    }</TabPanel>
                                                <TabPanel>
                                                    {
                                                        refOfPages.length ? <ul style={{padding:0}}>
                                                            {refOfPages.map((d) => {
                                                                return (
                                                                    <li > <img onClick={handleShow} style={{ width: "100%", height: "100%", alignSelf: 'center', boxShadow: '0 0 10px rgb(70, 68, 68)', marginBottom: 10, cursor:'pointer'}} src={d} /></li>
                                                                    // onClick={handleShow}
                                                                )
                                                            })
                                                            }
                                                           </ul> : <></>
                                                    }

                                                </TabPanel>
                                            </Tabs>

                                        </div>
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            <div style={{ fontWeight: 'bold' }} key={key} dangerouslySetInnerHTML={{ __html: transformContent(data.name, keywords) }}></div>

                                            <ul>
                                                {
                                                    data.crises_sub_type.map((data2, key) => {
                                                        return (
                                                            <>
                                                                <li key={key} style={{ listStyleType: 'square' }}>
                                                                    {data2.crises_sub_type.length !== 0 ?
                                                                        <div style={{ fontWeight: 600, marginTop: 20 }} dangerouslySetInnerHTML={{ __html: transformContent(data2.name, keywords) }}>
                                                                        </div> :
                                                                        <div dangerouslySetInnerHTML={{ __html: transformContent(data2.name, keywords) }}></div>
                                                                    }
                                                                    <ul>

                                                                        {
                                                                            data2.crises_sub_type ?
                                                                                <>
                                                                                    {
                                                                                        data2.crises_sub_type.map((data3, key) => {

                                                                                            return (
                                                                                                <> {
                                                                                                    data3.crises_sub_type.length !== 0 ?
                                                                                                        <li key={key} style={{ listStyleType: 'disc', fontWeight: 500 }} dangerouslySetInnerHTML={{ __html: transformContent(data3.name, keywords) }}></li> :
                                                                                                        <li key={key} style={{ listStyleType: 'disc' }} dangerouslySetInnerHTML={{ __html: transformContent(data3.name, keywords) }}></li>
                                                                                                }
                                                                                                    {
                                                                                                        data3.crises_sub_type ?
                                                                                                            <>

                                                                                                                <ul>
                                                                                                                    {

                                                                                                                        data3.crises_sub_type.map((data4, key) => {
                                                                                                                            return (
                                                                                                                                <>

                                                                                                                                    <li key={key} style={{ paddingLeft: 10, listStyleType: 'circle' }} dangerouslySetInnerHTML={{ __html: transformContent(data4.name, keywords) }}></li>


                                                                                                                                </>
                                                                                                                            )
                                                                                                                        })

                                                                                                                    }
                                                                                                                </ul>

                                                                                                            </> : <>No data to display</>
                                                                                                    }
                                                                                                </>
                                                                                            )

                                                                                        })
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <> No data to display
                                                                                </>
                                                                        }</ul>
                                                                </li>
                                                            </>

                                                        )
                                                    })
                                                }</ul>
                                        </>
                                    )
                                }
                            })
                        }
                    </div>
                </></div>
                {loading === true ? <Spinner /> : <></>}
            </div>
            {show ?
            <div className="static-modal" style={{backgroundColor: 'rgba(0,0,0,0.1)', width:'100%', height:'100vh',left:0,top:0, position:'fixed', zIndex:999, alignItems:'center'}}>
                <Modal.Dialog  style={{ position: 'absolute'}} size="lg" className="modBox"

                >
                    <Modal.Header>
                        <Modal.Title>Reference Large View </Modal.Title>
                        <i className="fas fa-times" style={{ cursor: 'hand' }} onClick={handleClose}></i>
                    </Modal.Header>
                    <Modal.Body style={{background:'transparent'}} className="modbody"> <img src={largeImg} width="100%" /></Modal.Body>
                </Modal.Dialog>
                
                </div> : <></>
            }
        </div>
    )
}


export {
    Crises
}


