export const SET_MASK_3 = "SET_MASK_3"
export const SET_LMA_3 = "SET_LMA_3"
export const SET_INTUBATION_3 = "SET_INTUBATION_3"
export const SET_SUB_INTUBATION_3 = "SET_SUB_INTUBATION_3"
export const SET_LUNG_INTUBATION_3 = "SET_LUNG_INTUBATION_3"
export const SET_LUNG_SUB_INTUBATION_3 = "SET_LUNG_SUB_INTUBATION_3"
export const SET_LUNG_SUB_SUB_INTUBATION_3 = "SET_LUNG_SUB_SUB_INTUBATION_3"
export const SET_LARYNGOSCOPE_MAIN_3 = "SET_LARYNGOSCOPE_MAIN_3"
export const SET_LARYNGOSCOPE_SUB_TYPE_3 = "SET_LARYNGOSCOPE_SUB_TYPE_3"
export const SET_LARYNGOSCOPE_SUB_SUB_TYPE_3 = "SET_LARYNGOSCOPE_SUB_SUB_TYPE_3"
export const SET_AIRWAY_ADJUNCT_3 = "SET_AIRWAY_ADJUNCT_3"
export const SET_AIRWAY_ADJUNCT_TYPE_3 = "SET_AIRWAY_ADJUNCT_TYPE_3"
export const SET_AIRWAY_OTHER_3 = "SET_AIRWAY_OTHER_3"
export const SET_AIRWAY_CALCULATORS_3 = "SET_AIRWAY_CALCULATORS_3"
export const SET_AIRWAY_NOTES_3 = "SET_AIRWAY_NOTES_3"