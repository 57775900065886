import React  from 'react';
import logo from '../images/logo-small.png';

const Spinner = () => {
return (
<div className="static-modal" style={{backgroundColor: 'rgba(0,0,0,0.1)', width:'100%', height:'100vh',left:0,top:0, position:'fixed', zIndex:999, alignItems:'center', margin:'0 auto'}}>
<div className="loader" style={{marginTop:'10%'}}>
    {/* <img src={logo} className="logo" /> */}
</div>
</div>
)
}
export default Spinner;