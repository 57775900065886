import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';

const ORT = () => {
    const [set, setSex] = useState(null)
    const [age, setAge] = useState(0)
    const [hps, setHps] = useState(0)
    const [hps1, setHps1] = useState(0)
    const [hod, setHod] = useState(0)
    const [hoa, setHoa] = useState(0)
    const [pha, setPha] = useState(0)
    const [phi, setPhi] = useState(0)
    const [php, setPhp] = useState(0)
    const [fha, setFha] = useState(0)
    const [fha1, setFha1] = useState(0)
    const [fhi, setFhi] = useState(0)
    const [fhi1, setFhi1] = useState(0)
    const [fhp, setFhp] = useState(0)
    const [fhp1, setFhp1] = useState(0)



    const [result, setResult] = useState(0)


    const postData = async () => {
        if (set === 'no') {
            if (hps === 0) {
                setHps1(0);
            }
            if (hps === 3) {
                setHps1(3);
            }

            if (fha === 0) {
                setFha1(0)
            }
            if (fha === 1) {
                setFha1(1)
            }

            if (fhi === 0) {
                setFhi1(0)
            }
            if (fhi === 2) {
                setFhi1(2)
            }
        }
        if (set === 'yes') {
            if (hps === 0) {
                setHps1(0);
            }
            if (hps === 3) {
                setHps1(0);
            }
            if (fha === 0) {
                setFha1(0)
            }
            if (fha === 1) {
                setFha1(3)
            }

            if (fhi === 0) {
                setFhi1(0)
            }
            if (fhi === 2) {
                setFhi1(3)
            }
        }

        setResult(age + hod + hoa + php + pha + phi + fhp + hps1 + fha1 + fhi1)

    }
    useEffect(() => {
        postData();


    }, [set, age, hps, hod, hoa, php, pha, phi, fha, fhi, fhp, hps1, fha1, fhi1]);

    const result1 = () => {
        if(set != null){

            if((result >= 0 ) && (result <=3)) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container col-12" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>Low risk for future opioid-related aberrant behaviors. 5.6% of low-risk patients had aberrant behaviors.</div>
                    </div>
                </div>
            } else if ((result >= 4 ) && (result<=7)) {
                return <div className="apfel-score-result-container" >
                   <div className="point-result-container col-12" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>Moderate risk for future opioid-related aberrant behaviors. 28% of moderate-risk patients had aberrant behaviors.</div>
                    </div>
                   
                   
                </div>
            } else if (result >= 8) {
                return <div className="apfel-score-result-container" >
                     <div className="point-result-container col-12" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>High risk for future opioid-related aberrant behaviors. 91% of high-risk patients had aberrant behaviors.</div>
                    </div>
                 
                   
                </div>
            }
        }
        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }
    return (
        <div className="rev">
            <h5 className="text-info pl-2">Opioid Risk Tool (ORT) for Narcotic Abuse</h5>
            <p className="pl-2">Estimates risk of opioid-related aberrant behaviors..</p>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Sex</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }}  key="1" className={set === 'no' ? "btn active" : "btn "} onClick={() => setSex('no')}>Female<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }}  key="2" className={set === 'yes' ? "btn active" : "btn "} onClick={() => setSex('yes')}>Male<span className="ml-4 text-muted"></span></button>
                </div>

            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Age 16-45</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }}  key="1" className={age === 0 ? "btn active" : "btn"} onClick={() => setAge(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }}  key="2" className={age === 1 ? "btn active" : "btn"} onClick={() => setAge(1)} >Yes<span className="ml-4 text-muted"></span></button>
                </div>

            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>History of preadolescent sexual abuse</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }}  key="1" className={hps === 0 ? "btn active" : "btn"} onClick={() => setHps(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }}  key="2" className={hps === 3 ? "btn active" : "btn"} onClick={() => setHps(3)} >Yes<span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>History of depression</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }}  key="1" className={hod === 0 ? "btn active" : "btn"} onClick={() => setHod(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }}  key="2" className={hod === 1 ? "btn active" : "btn"} onClick={() => setHod(1)}>Yes<span className="ml-4 text-muted"></span></button>
                </div>

            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>History of ADD, OCD, bipolar disorder, or schizophrenia</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }}  key="1" className={hoa === 0 ? "btn active" : "btn"} onClick={() => setHoa(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }}  key="2" className={hoa === 2 ? "btn active" : "btn"} onClick={() => setHoa(2)} >Yes<span className="ml-4 text-muted"></span></button>
                </div>

            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Personal history of alcohol abuse
                    </strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }}  key="1" className={pha === 0 ? "btn active" : "btn"} onClick={() => setPha(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }}  key="2" className={pha === 3 ? "btn active" : "btn"} onClick={() => setPha(3)}>Yes<span className="ml-4 text-muted"></span></button>
                </div>

            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Personal history of illegal drug abuse
                    </strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }}  key="1" className={phi === 0 ? "btn active" : "btn"} onClick={() => setPhi(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }}  key="2" className={phi === 4 ? "btn active" : "btn"} onClick={() => setPhi(4)}>Yes<span className="ml-4 text-muted"></span></button>
                </div>

            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Personal history of prescription drug abuse</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }}  key="1" className={php === 0 ? "btn active" : "btn"} onClick={() => setPhp(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }}  key="2" className={php === 5 ? "btn active" : "btn"} onClick={() => setPhp(5)} >Yes<span className="ml-4 text-muted"></span></button>
                </div>

            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Family history of alcohol abuse</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }}  key="1" className={fha === 0 ? "btn active" : "btn"} onClick={() => setFha(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }}  key="2" className={fha === 1 ? "btn active" : "btn"} onClick={() => setFha(1)} >Yes<span className="ml-4 text-muted"></span></button>
                </div>

            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Family history of illegal drug abuse</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }}  key="1" className={fhi === 0 ? "btn active" : "btn"} onClick={() => setFhi(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }}  key="2" className={fhi === 2 ? "btn active" : "btn"} onClick={() => setFhi(2)}>Yes<span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Family history of prescription drug abuse
                    </strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }}  key="1" className={fhp === 0 ? "btn active" : "btn"} onClick={() => setFhp(0)}>No<span className="ml-4 text-muted"></span></button>
                    <button style={{ height:  40   }}  key="2" className={fhp === 4 ? "btn active" : "btn"} onClick={() => setFhp(4)}>Yes<span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="stick pt-2">
            {
                result1()
            }
        </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/16336480/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >Webster LR, Webster RM. Predicting aberrant behaviors in opioid-treated patients: preliminary validation of the Opioid Risk Tool. Pain Med. 2005 Nov-Dec;6(6):432-42. PubMed PMID: 16336480.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default ORT
