import React, { useEffect, useState } from 'react';
import './RE1179.css';

const RE1179 = () => {
    const [consciousness, setConsciousness] = useState(0);
    const [mobility, setMobility] = useState(0);
    const [breathing, setBreathing] = useState(0);
    const [circulation, setCirculation] = useState(0);
    const [color, setColor] = useState(0);


    const [result, setResult] = useState(0);
    const postData = async () => {
        setResult(consciousness + mobility + breathing + circulation + color);
    }
    useEffect(() => {
        postData();


    }, [consciousness, mobility, breathing, circulation, color]);
    return (
        <div className="rev">
            <h5 className="text-info pl-2">Aldrete Score</h5>
            <p className="pl-2">Evaluates recovery after anesthesia and patient readiness to be discharged from PACU. </p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Consciousness</strong></div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  40   }} onClick={() => setConsciousness(2)} className={consciousness === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Fully awake</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40   }} onClick={() => setConsciousness(1)} className={consciousness === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Arousable</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  40   }} onClick={() => setConsciousness(0)} className={consciousness === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Not responding</span><span className="calc-val ">0</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Mobility</strong></div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  60   }} onClick={() => setMobility(2)} className={mobility === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Able to move four extremities <br/>on command </span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  60   }} onClick={() => setMobility(1)} className={mobility === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Able to move two extremities <br/>on command</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  60   }} onClick={() => setMobility(0)} className={mobility === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Able to move 0 extremities <br/>on command </span><span className="calc-val ">0</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Breathing</strong></div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  40   }} onClick={() => setBreathing(2)} className={breathing === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Able to breathe deeply</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40   }} onClick={() => setBreathing(1)} className={breathing === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Dyspnea</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  40   }} onClick={() => setBreathing(0)} className={breathing === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Apnea</span><span className="calc-val ">0</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Circulation</strong></div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  60   }} onClick={() => setCirculation(2)} className={circulation === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Systemic BP ≠ 20% of the <br/>preanesthetic level</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  60   }} onClick={() => setCirculation(1)} className={circulation === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Systemic BP between 20% and <br/>49% of the preanesthetic level</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  60   }} onClick={() => setCirculation(0)} className={circulation === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Systemic BP ≠ 50% of the preanesthetic<br/> level</span><span className="calc-val ">0</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Color</strong></div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  40   }} onClick={() => setColor(2)} className={color === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Normal</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40   }} onClick={() => setColor(1)} className={color === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Pale, jaundiced, blotchy</span><span className="calc-val ">+1</span></button>
                    <button  style={{ height:  40   }} onClick={() => setColor(0)} className={color === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Cyanotic</span><span className="calc-val ">0</span></button>
                </div>
            </div>
            <div className="stick pt-3">
                {
                    (result) ? <>
                        <div className="apfel-score-result-container col-12 " >
                            <div className="point-result-container col-12" >
                                <h1> Aldrete Score = {result}</h1>
                                <div>Interpretation: Patients with values of 9 or greater who have an appropriate escort can be discharged from the unit.</div>
                            </div>

                        </div>
                    </>
                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/5534693/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" ><a href="https://www.ncbi.nlm.nih.gov/pubmed/5534693" target="_blank" rel="noopener noreferrer nofollow">A postanesthetic recovery score</a></p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default RE1179
