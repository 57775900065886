import React, { useEffect, useState } from 'react';
import './CAS1052.css';

const CAS1052 = () => {
    const [patient, setPatient] = useState(0);
    const [result, setResult] = useState(0);
    const postData = async () => {
        setResult(patient);
    }

    useEffect(() => {
        postData();
    }, [patient]);


    const result1 = () => {
        
            if (patient == 2) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container col-12" >
                        <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                        <div>A SMS score of 2 correlates with a low risk of need for intubation, neurosurgical intervention or mortality (like a GCS of 14-15).</div>
                    </div>
                </div>
            }
            if (patient == 1) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container col-12" >
                        <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                        <div>Patients with an SMS of &gt;2 are at significantly increased risk of having a significant traumatic brain injury and prompt evaluation and head CT imaging is indicated for these patients..</div>

                    </div>
                </div>
            }
            if (patient == 3) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container col-12" >
                        <h1>{0} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                        <div>Patients with an SMS of &gt;2 are at significantly increased risk of having a significant traumatic brain injury and prompt evaluation and head CT imaging is indicated for these patients</div>
                    </div>
                </div>
            }
        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }
    return (
        <div className="rev">
            <h4 className="text-info pl-2">Simplified Motor Score (SMS)</h4>
            <p className="pl-2">Simplifies assessment of head trauma patients compared to the GCS..</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Patient response</strong><br />Score best response</div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  40   }} onClick={() => setPatient(2)} className={patient === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Obeys commands</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40   }} onClick={() => setPatient(1)} className={patient === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Localizes pain</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  40   }} onClick={() => setPatient(3)} className={patient === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Withdrawals to pain or worse</span><span className="calc-val ">0</span></button>
                </div>
            </div>
            <div className='stick pt-3'>
                {
                    result1()
                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/15635308/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >Gill M, Windemuth R, Steele R, Green SM. A comparison of the Glasgow Coma Scale score to simplified alternative scores for the prediction of traumatic brain injury outcomes. Ann Emerg Med. 2005 Jan;45(1):37-42.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default CAS1052
