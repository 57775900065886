import React, { useEffect, useState } from 'react';
import './RE1240.css';
const RE1240 = () => {

    const [number1, setNumber1] = useState([]);
    const [number2, setNumber2] = useState([]);
    const [number3, setNumber3] = useState([]);
    const [square, setSquare] = useState([]);
    const [number4, setNumber4] = useState([]);
    const [number5, setNumber5] = useState([]);
    const [number6, setNumber6] = useState([]);
    const [grf, setGrf] = useState(false);



    const [total1, setTotal1] = useState(0);
    const [total7, setTotal7] = useState(0);
    const [total8, setTotal8] = useState(0);
    const [total9, setTotal9] = useState(0);
    const [height, setHeight] = useState([]);
    const [weight, setWeight] = useState([]);
    const [tunit, setTunit] = useState('m');
    const [tunit1, setTunit1] = useState('kg');
    const [apply,setApply] = useState('Notapply');


    const postData = async () => {
        if (apply === 'apply') {
            setSquare(total1);     
        }


        setTotal8((parseInt(number1) + parseInt(number2) + parseInt(number3) + parseInt(number4) + parseInt(number5) + parseInt(number6) + parseInt(total7)))
        setTotal1(((Math.sqrt((weight * height) / 3600)) * 10).toFixed(2))
    }
    useEffect(() => {

        postData()
        result7()
        result()
    }, [number1, number2, number3, number4, number5, number6, square, height, weight,apply,total1])
    const changeunit = () => {
        if (tunit === 'm') {
            setTunit('in');

            if (height.length !== 0) {

                setHeight((height / 2.54).toFixed(2));
            }
        }
        if (tunit === 'in') {
            setTunit('m');

            if (height.length !== 0) {
                setHeight((height * 2.54).toFixed(2));

            }
        }
    }
    const takeUnit = (e) => {
        if (tunit === 'm') {
            let htcm = e.target.value;
                setHeight(htcm);
        }
        if (tunit === 'in') {
            let htin = e.target.value;
            setHeight(htin);
        }

    }


    const changeunit1 = () => {

        if (tunit1 === 'kg') {
            setTunit1('lbs');
            if (weight.length !== 0) {

                setWeight((weight * 2.2046226218488).toFixed(2));
            }
        }
        if (tunit1 === 'lbs') {
            setTunit1('kg');
            if (weight.length !== 0) {

                setWeight((weight / 2.2046226218488).toFixed(2));
            }
        }

    }
    const takeUnit1 = (e) => {
        if (tunit1 === 'kg') {
            setWeight(e.target.value);
        }
        if (tunit1 === 'lbs') {
            let ht = e.target.value;
            setWeight(ht);

        }
    }
  
  
     
    const result7 = () => {
        if (square) {
            if (square < 1.7) {
                setTotal7(6);
            }
            if (square == 1.7) {
                setTotal7(4);

            }
            if (square == 1.9) {
                setTotal7(2);

            }
            if (square == 2) {
                setTotal7(2);

            }
           
          
            if (square == 2.1) {
                setTotal7(1);

            }
            if (square > 2.3) {
                setTotal7(0);

            }
        }
    }
    const result = () => {

        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 0){
            setTotal9(4)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7))== 1) {
            setTotal9(5)
        }
        if((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 2) {
            setTotal9(6)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 3) {
            setTotal9(8)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 4) {
            setTotal9(10)
        }
        if((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 5) {
            setTotal9(11.9)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 6) {
            setTotal9(14.2)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 7) {
            setTotal9(16.8)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 8) {
            setTotal9(19.8)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 9) {
            setTotal9(23.1)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 10) {
            setTotal9(26.9)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 11) {
            setTotal9(31)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 12) {
            setTotal9(35.4)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 13) {
            setTotal9(40.1)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 14) {
            setTotal9(45)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 15) {
            setTotal9(50)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 16) {
            setTotal9(55)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 17) {
            setTotal9(59.9)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 18) {
            setTotal9(64.6)
        }
        if((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 19) {
            setTotal9(69)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 20) {
            setTotal9(73.1)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 21) {
            setTotal9(76.9)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 22) {
            setTotal9(80.2)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 23 ){
            setTotal9(83.2)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 24) {
            setTotal9(85.8)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 25) {
            setTotal9(88.1)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 26) {
            setTotal9(90)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 27) {
            setTotal9(91.7)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 28) {
            setTotal9(93.1)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 29) {
            setTotal9(94.3)
        }
        if ((parseInt(number1)+parseInt(number2)+parseInt(number3)+parseInt(number4)+parseInt(number5)+parseInt(number6)+parseInt(total7)) == 30) {
            setTotal9(95.3)
        }

    }

  
const applyresult = (e)=>{
if(apply != 'apply'){
    let mm = e.target.value;
    setSquare(mm);
}
else{
    setSquare(total1);
}
}

    return (<div className="rev">
        <h5 className="text-info pl-2">PORT score for PeriOperative Risk of blood Transfusion in cardiac surgery by ACTA</h5>

        <p className="pl-2">Predict risk of blood transfusion after elective cardiac surgery using the ACTA-PORT score.</p>

        <div className="fields row w-100">
            <div className="col-md-5">
                <div className="pt-2"><strong>Age ≥70?</strong>
                </div>
            </div>
            <div className="col-md-7">
                <select className="custom-select" aria-label="Age"
                    value={number1}
                    onChange={(e) => {
                        const patient = e.target.value;

                        setNumber1(patient);
                    }}
                  >

                    <option value="">select</option>
                    <option value= '1'>Yes</option>
                    <option value= '0' >No</option>
                </select>
            </div>

        </div>
       
                <div className="fields row w-100">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Gender</strong>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <select className="custom-select" aria-label="Default select example"
                            value={number2}
                            onChange={(e) => {
                                const saho = e.target.value;
                              
                                setNumber2(saho);
                            }}>

                            <option value="">Select</option>
                            <option value='1'>Female</option>
                    <option value='0' >Male</option>
                        </select>
                    </div>
                </div>
  
    
                <div className="fields row w-100">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Hemoglobin?</strong>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <select className="custom-select" aria-label="Default select example"
                            value={number3}
                            onChange={(e) => {
                                const deba = e.target.value;
                             
                                setNumber3(deba);
                            }}>

                            <option value="">Select</option>
                             <option value="9">&lt;110 g/L</option>
                    <option value="8" >110-119 g/L</option>
                    <option value="6">120-129 g/L</option>
                    <option value="3" >130-139 g/L</option>
                    <option value="2">140-149 g/L</option>
                    <option value="0" >≥150 g/L</option>
                        </select>
                    </div>
                </div>
        
     
            <div className="fields row">
                <div className="col-md-4">
                    <div className="pt-2"><strong>Body Surface Area ?</strong></div>
                </div>
                <div className="col-md-8 pl-5 frm-field">
                    <>
                    <input

type="number"
                        onChange={applyresult}
                       
                        className="ant-text-input text-port"   value={square}/><span  className="units">m2</span></>
                <> <button style={{ height: '100%', marginLeft: 10 }} className="save-button-container" onClick={() => { setGrf(true) }}>Calculate</button></>

                {/* <div>{total1}</div> */}
               



            </div>
           
        </div>
        {grf === true ?
            <div className="fields row">
                <div className="col-md-12 frm-field" style={{ justifyContent: 'space-evenly' }}>
                    <><span className="pt-2">Weight:</span><input className="ant-text-input ml-1" value={weight} onChange={takeUnit1}
                        style={{ width: '40%' }} type="number" /> <span className="units mr-1" onClick={changeunit1}>{tunit1}&#8651;</span>
                        <span className="pt-2">Height:</span><input type="number" className="ant-text-input  ml-1" value={height} onChange={takeUnit} style={{ width: '40%' }} /><span className="units mr-1" onClick={changeunit}>{tunit}&#8651;</span></>
                      
                    <><button className="save-button-container" style={{ width: '10%' }} onClick={() => {setApply("apply"); setGrf(false);}}>Apply</button></>
                    {/* <div>{apply}</div> */}
                </div>
            </div> : <></>
        }
        <div className="fields row w-100">
            <div className="col-md-5">
                <div className="pt-2"><strong>Euroscore?</strong>
                </div>
            </div>
            <div className="col-md-7">
                <select className="custom-select" aria-label="Default select example"
                    value={number4}
                    onChange={(e) => {
                        const chutu = e.target.value;

                        setNumber4(chutu);
                    }}>
                    <option value="">Select</option>
                    <option value="0">0</option>
                    <option value="2" >1</option>
                    <option value="3">2</option>
                    <option value="4" >3-8</option>
                    <option value="5">≥9</option>
                </select>
            </div>
        </div>
      
 
        <div className="fields row w-100">
            <div className="col-md-5">
                <div className="pt-2"><strong>Creatinine?</strong>
                </div>
            </div>
            <div className="col-md-7">
                <select className="custom-select" aria-label="Default select example"
                    value={number5}
                    onChange={(e) => {
                        const tukuna = e.target.value;
                       
                        setNumber5(tukuna);
                    }}>

                    <option value="">Select</option>
                    <option value="0">&lt;88 μmol/L</option>
                    <option value="1" >88-176 μmol/L </option>
                    <option value="3">≥177 μmol/L </option>
                </select>
            </div>
        </div>
        <div className="fields row w-100">
            <div className="col-md-5">
                <div className="pt-2"><strong>Type of operation?</strong>
                </div>
            </div>
            <div className="col-md-7">
                <select className="custom-select" aria-label="type of operation"
                    value={number6}
                    onChange={(e) => {
                        const rama = e.target.value;
                        setNumber6(rama);
                    }}>

                    <option value>Select</option>
                    <option value="2">CABG or Valve</option>
                    <option value="5" >Combination </option>
                    <option value="0">Other</option>
                </select>
            </div>
        </div>


       <br/>
        <div className = "stick">
            {
                total8 ?
                    <div className="apfel-score-result-container" >
                        <div className="point-result-container" >
                            <h1 className="breath">{total8} </h1>
                            <div>ACTA-PORT Score</div>
                        </div>
                        <div className="point-result-container" >
                            <h1 className="breath">{total9}%</h1>
                            <div>Estimated Risk of Peri-Operative Blood Transfusion</div>
                        </div>
                    </div>
                    :
                    <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>
            }
        </div>
        <div className="p-2 mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://www.sciencedirect.com/science/article/pii/S0007091217537526">
            <div className="reference-container-card" >
                <div className="reference-img" >
                    <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                </div>
                <p className="reference-paragraph" >A A Klein, T Collier, J Yeates, L F Miles, S N Fletcher, C Evans, T Richards.

The ACTA PORT-score for predicting perioperative risk of blood transfusion for adult cardiac surgery.

British Journal of Anaesthesia 2017 September 1, 119 (3): 394-401</p>
            </div>
            </a><br/>
            
            </div>
    </div>
    )
}
export default RE1240;