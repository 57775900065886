import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';

const DetskRiskIndex = () => {
    const [green, setGreen] = useState(false);
    
    

    const [age, setAge] = useState(0);
    const [priorMyocardial, setPriorMyocardial] = useState(0);
    const [unstablEangina, setUnstablEangina] = useState(0);
    const [anginaPectoris, setAnginaPectoris] = useState(0);
    const [alveolarPulmonaryEdema, setAlveolarPulmonaryEdema] = useState(0);
    const [suspectedCritical, setSuspectedCritical] = useState(0);
    const [arrhythmia, setArrhythmia] = useState(0);
    const [arrhythmia1, setArrhythmia1] = useState(0);
    const [emergencySurgery, setEmergencySurgery] = useState(0);
    const [generalMedical, setGeneralMedical] = useState(0);

    const [result, setResult] = useState(0);

    const postData = async () => {
        if((arrhythmia == 1 ) || (arrhythmia == 5  )){
            setArrhythmia1(5);
             
         }
         else{
             setArrhythmia1(0);
         }
        
        setResult(age + priorMyocardial+unstablEangina+anginaPectoris +suspectedCritical + alveolarPulmonaryEdema +arrhythmia1 +emergencySurgery + generalMedical );
        setGreen(true);
      
    }
    useEffect(() => {
       
        postData();


    }, [age,priorMyocardial,unstablEangina, anginaPectoris,result,suspectedCritical,alveolarPulmonaryEdema,arrhythmia ,emergencySurgery ,arrhythmia1, generalMedical]);



  

    return (
        <div className="rev">
               <h5 className="text-info pl-2">Detsky Modified Cardiac Risk Index</h5>
              <p className="pl-2">Predicts risk of cardiovascular complications in patients undergoing noncardiac surgery.</p>
            <div>
                <div className="fields row">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Age</strong></div>

                    </div>
                    <div className="col-md-7 btn-group-vertical options-btn mb-2">
                        <button onClick={() => setAge(0)} className={age === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name  ">70 and below (0 points)</span></button>
                        <button onClick={() => setAge(5)} className={age === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Above 70 (5 points)</span></button>
                        

                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Prior myocardial infarction</strong></div>

                    </div>
                    <div className="col-md-7 btn-group-vertical options-btn mb-2">
                        <button onClick={() => setPriorMyocardial(0)} className={priorMyocardial === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name  ">No (0 points)</span></button>
                        <button onClick={() => setPriorMyocardial(10)} className={priorMyocardial === 10 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Within the last 6 months (10 points)</span></button>
                        <button onClick={() => setPriorMyocardial(5)} className={priorMyocardial === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">More than 6 months ago (5 points)</span></button>
                        

                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Unstable angina in the last 6 months</strong></div>

                    </div>
                    <div className="col-md-7 btn-group-vertical options-btn mb-2">
                        <button onClick={() => setUnstablEangina(0)} className={unstablEangina === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name  ">No (0 points)</span></button>
                        <button onClick={() => setUnstablEangina(10)} className={unstablEangina === 10 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Yes (10 points)</span></button>
                        

                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Angina pectoris</strong></div>

                    </div>
                    <div className="col-md-7 btn-group-vertical options-btn mb-2">
                        <button onClick={() => setAnginaPectoris(0)} className={anginaPectoris === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name  ">No (0 points)</span></button>
                        <button onClick={() => setAnginaPectoris(10)} className={anginaPectoris === 10 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Class III angina (10 points)</span></button>
                        <button onClick={() => setAnginaPectoris(20)} className={anginaPectoris === 20 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Class IV angina (20 points)</span></button>
                        

                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Alveolar pulmonary edema</strong></div>

                    </div>
                    <div className="col-md-7 btn-group-vertical options-btn mb-2">
                        <button onClick={() => setAlveolarPulmonaryEdema(0)} className={alveolarPulmonaryEdema === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name  ">No (0 points)</span></button>
                        <button onClick={() => setAlveolarPulmonaryEdema(10)} className={alveolarPulmonaryEdema === 10 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">In the last week (10 points)</span></button>
                        <button onClick={() => setAlveolarPulmonaryEdema(5)} className={alveolarPulmonaryEdema === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Ever (5 points)</span></button>
                        

                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Suspected critical aortic stenosis</strong></div>

                    </div>
                    <div className="col-md-7 btn-group-vertical options-btn mb-2">
                        <button onClick={() => setSuspectedCritical(0)} className={suspectedCritical === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">No (0 points)</span></button>
                        <button onClick={() => setSuspectedCritical(20)} className={suspectedCritical === 20 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Yes (20 points)</span></button>
                        

                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Arrhythmia</strong></div>

                    </div>
                    <div className="col-md-7 btn-group-vertical options-btn mb-2">
                        <button onClick={() => setArrhythmia(0)} className={arrhythmia === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name  ">Sinus or sinus with PACs (0 points)</span></button>
                        <button onClick={() => setArrhythmia(1)} className={arrhythmia === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Other than sinus or sinus with PACs (5 points)</span></button>
                        <button onClick={() => setArrhythmia(5)} className={arrhythmia === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">More than 5 premature ventricular beats (5 points)</span></button>
                        

                    </div>
                    
                
                </div>
                <div className="fields row">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>Emergency surgery</strong></div>

                    </div>
                    <div className="col-md-7 btn-group-vertical options-btn mb-2">
                        <button onClick={() => setEmergencySurgery(0)} className={emergencySurgery === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name  ">No (0 points)</span></button>
                        <button onClick={() => setEmergencySurgery(10)} className={emergencySurgery === 10 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Yes (10 points)</span></button>
                        

                    </div>
                </div>
                <div className="fields row">
                    <div className="col-md-5">
                        <div className="pt-2"><strong>General medical status</strong></div>

                    </div>
                    <div className="col-md-7 btn-group-vertical options-btn mb-2">
                        <button onClick={() => setGeneralMedical(0)} className={generalMedical === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name  ">No (0 points)</span></button>
                        <button onClick={() => setGeneralMedical(5)} className={generalMedical === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Yes (5 points)</span></button>
                        

                    </div>
                </div>
            </div>


            <div className="stick">
                {
                   (result != 0) ? <>
                        <div className="apfel-score-result-container col-12 " >
                            <div className="point-result-container col-12" >
                                <h5> Detsky Modified Cardiac Risk Score = {result} </h5>
                                <>{result > 30 ? <div><span className = "font-weight-bold" >Interpretation: </span>This result indicates a <strong> high risk of cardiovascular complications </strong> after non cardiac surgery.</div> : " "}</>
                                <div>{result > 15 && result < 31 ? <div> <span className = "font-weight-bold" >Interpretation: </span>This result indicates a <strong> moderate risk of cardiovascular complications </strong> non cardiac surgery.</div> : " "}</div>
                                <>{result < 16 ? <div><span className = "font-weight-bold" >Interpretation: </span>This result indicates a <strong> low risk of cardiovascular complications </strong> after non cardiac surgery.</div>: " "}</>
                             
                            </div>






                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <br />
        <div className="p-2" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/3772593/">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Detsky AS, Abrams HB, McLaughlin JR, Drucker DJ, Sasson Z, Johnston N, Scott JG, Forbath N, Hilliard JR. Predicting cardiac complications in patients undergoing non-cardiac surgery. J Gen Intern Med. 1986; 1(4):211-9.</p>
                </div>
            </a>
            <br/>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/3778043/">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Detsky AS, Abrams HB, Forbath N, Scott JG, Hilliard JR. Cardiac assessment for patients undergoing noncardiac surgery. A multifactorial clinical risk index. Arch Intern Med. 1986; 146(11):2131-4.</p>
                </div>
            </a>
        </div>
        </div>
    )
}


export default DetskRiskIndex;

