import React, { useEffect, useState } from 'react';
import './RE1215.css';

const RE1215 = () => {
    return (
        <div className="rev">
            <h5 className="text-info pl-2">Table 1 Frailty assessement</h5>
            <br/>
  
            <table className="table table-bordered  table-white bg-white ">
                <thead className="table-secondary" >
                    <tr>
                        <th >Frailty Criteria</th>
                        <th >Description</th>
                    </tr>
                </thead>
                <tbody >
                    <tr >
                        <td >Shrinking: weight loss</td>
                        <td>Unintentional weight loss ≥5 kg within the previous year</td>

                    </tr>
                    <tr >
                        <td >Weakness: reduced grip strength (dominant hand), by gender and body mass index (BMI)</td>

                        <tr>
                            <td className="font-italic" >Male <br/></td>
                            <td className="font-italic">Female <br/></td>
                        </tr>
                        <tr>          
                            <td >BMI ≤24: ≤29 kg &emsp;&emsp;&emsp;&emsp;&ensp;</td>
                            <td>BMI ≤23: ≤17 kg  &emsp;&emsp;&emsp;&emsp;&ensp;</td> 
                        </tr>
                        <tr>
                            <td >BMI 24.1-26: ≤30 kg</td>
                            <td>	BMI 23.1-26: ≤17,3 kg</td>
                        </tr>
                        <tr>
                            <td >BMI 26.1-28: ≤30 kg</td>
                            <td>BMI 26.1-29: ≤18 kg</td>
                        </tr>
                        <tr>
                            <td >  BMI &gt;28: ≤32 kg </td>
                            <td>  BMI &gt;29: ≤21 kg </td>
                        </tr>


                    </tr>
                    <tr>
                        <td>Exhaustion: answering C or D to the following question</td>
                        
                        <tr>
                            <td >How often in the past week did the following apply:“I felt that everything I did was an effort.” “I could not get going.”</td>
                        </tr>
                        <tr>
                            <td > a) Never or rarely</td>
                        </tr>
                        <tr>
                            <td >b) Sometimes</td>
                        </tr>
                        <tr>
                            <td > c) Often</td>
                        </tr>
                        <tr>
                            <td > d) Most of the time</td>
                        </tr>
                    

                    </tr>
                    <tr>
                        <td >Gait Speed: slow walking speed (15 ft. = 4,57 m), dynamic start, by gender and height</td>
                        <tr>
                            <td className="font-italic" >Male</td>
                            <td className="font-italic">Female</td>
                        </tr>
                        <tr>
                            <td >Height ≤ 173 cm: ≥ 7 s &emsp;&emsp;&ensp;</td>
                            <td>Height ≤ 159 cm: ≥ 7 s &emsp;&emsp;&ensp;</td>
                        </tr>
                        <tr>
                            <td >Height &gt; 173 cm: ≥ 6 s </td>
                            <td>Height &gt; 159 cm: ≥ 6 s </td>
                        </tr>

                    </tr>
                    <tr>
                        <td>Low activity</td>
                        <tr>
                            <td >Metabolic Equivalent Tasks &lt; 3 </td>
                   
                        </tr>

                    </tr>
                    <tr>
                        <td >Number of positive criteria</td>
                        <td >Frail: ≥3 criteria Intermediate / pre-frail: 1–2 criteria</td>

                    </tr>
                </tbody>
            </table>
            <p className="font-weight-bold text-center ">*Frailty criteria utilized in the analysis, adapted from Fried [7]; BMI Body Mass Index</p>
          
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://bmcanesthesiol.biomedcentral.com/articles/10.1186/s12871-019-0880-x">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >Routine frailty assessment predicts postoperative complications in elderly patients across surgical disciplines – a retrospective observational study</p>
                    </div>
                </a>
            </div>
        </div>
    )
}


export default RE1215;

