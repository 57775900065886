import React, { useEffect, useState } from 'react';
import './RE1257.css';
const RE1257 = () => {
    const [green, setGreen] = useState(true);
    const [snore, setSnore] = useState(0);
    const [weekness, setWeekness] = useState(0);
    const [bloodPressure, setBloodPressure] = useState(0);
    const [age, setAge] = useState(0);
    const [neck, setNeck] = useState(0);
    const [gender, setGender] = useState(0);
    const [stopBreath, setStopBreath] = useState(0);
    const [bmi, setBmi] = useState(0);
    const [exchange_volume, setExchange_volume] = useState(0);
    const [scrollpos, setScrollPos] = useState({ bottom: 0 });


    const postData = async () => {
        setGreen(true);
        setExchange_volume(snore + weekness + bloodPressure + age + neck + gender + stopBreath + bmi);
    }
    useEffect(() => {
        postData()

    }, [snore, weekness, bloodPressure, age, neck, gender, stopBreath, bmi, exchange_volume])
    return (
        <div className="rev">
            <h5 className="text-info pl-2">STOP-BANG Score for Obstructive Sleep Apnea</h5>
            <p className="pl-2">Screens for obstructive sleep apnea.</p>
            <div className="alert alert-info">Ask the patient the following:</div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Do you snore loudly?</strong></div>
                    <div>Louder than talking or loud enough to be heard through closed doors</div>
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={snore === 0 ? "btn active" : "btn"} onClick={() => setSnore(0)}>No  <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={snore === 1 ? "btn active" : "btn"} onClick={() => setSnore(1)}>Yes <span className="ml-4 text-muted">+1</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Do you often feel tired, fatigued, or sleepy during the daytime?</strong></div>
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={weekness === 0 ? "btn active" : "btn"} onClick={() => setWeekness(0)}>No <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={weekness === 1 ? "btn active" : "btn"} onClick={() => setWeekness(1)}>Yes <span className="ml-4 text-muted">+1</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Has anyone observed you stop breathing during sleep?</strong></div>
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={stopBreath === 0 ? "btn active" : "btn"} onClick={() => setStopBreath(0)}>No <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={stopBreath === 1 ? "btn active" : "btn"} onClick={() => setStopBreath(1)}>Yes <span className="ml-4 text-muted">+1</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Do you have (or are you being treated for) high blood pressure?</strong></div>
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={bloodPressure === 0 ? "btn active" : "btn"} onClick={() => setBloodPressure(0)}>No  <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={bloodPressure === 1 ? "btn active" : "btn"} onClick={() => setBloodPressure(1)}>Yes <span className="ml-4 text-muted">+1</span></button>

                </div>
            </div>
            <div className="alert alert-info">Objective measures:</div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>BMI</strong></div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn">
                    <button onClick={() => setBmi(0)} className={bmi === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">≤35 kg/m²</span><span className="text-right pv text-muted">0</span></button>
                    <button onClick={() => setBmi(1)} className={bmi === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">&gt;35 kg/m²</span><span className="text-right pv text-muted">+1</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Age</strong></div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn">
                    <button onClick={() => setAge(0)} className={age === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">≤50 years</span><span className="text-right pv text-muted">0</span></button>
                    <button onClick={() => setAge(1)} className={age === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">&gt;50 years</span><span className="text-right pv text-muted">+1</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Neck circumference</strong></div>
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={neck === 0 ? "btn active" : "btn"} onClick={() => setNeck(0)}>≤40 cm <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40}} key="2" className={neck === 1 ? "btn active" : "btn"} onClick={() => setNeck(1)}>&gt;40 cm <span className="ml-4 text-muted">+1</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Gender</strong></div>
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button style={{ height: 40}} key="1" className={gender === 0 ? "btn active" : "btn"} onClick={() => setGender(0)}>Female <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={gender === 1 ? "btn active" : "btn"} onClick={() => setGender(1)}>Male <span className="ml-4 text-muted">+1</span></button>

                </div>
            </div>
            
            {exchange_volume !== 0 ?
                <div className="stick">
                    {
                        green ?
                            <div className="apfel-score-result-container" style={scrollpos} >
                                <div className="point-result-container" >
                                    <h1>{exchange_volume} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                                    STOP BANG
                                </div>
                                <div className="percentage-result-container" >
                                    <h1>
                                        {exchange_volume >= 3 ? "High" : "Low"}

                                    </h1> risk of OSA
                                </div>
                            </div>
                            :
                            <></>
                    }
                </div> : <div className="stick"><div className="text-white p-2 green-bg" style={scrollpos}><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div></div>
            }
            <br />
            <div className="p-2 mb-4" >
                <div>
                    ORIGINAL/PRIMARY REFERENCE
                </div>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/18431116/">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Chung F, Yegneswaran B, Liao P, et al. STOP questionnaire: a tool to screen patients for obstructive sleep apnea. Anesthesiology. 2008;108(5):812-21.</p>
                </div>
                </a>
            </div>
        </div>
    )
}
export default RE1257;