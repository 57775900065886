export const SET_AGEE = "SET_AGEE"
export const SET_AGEE_TYPE = "SET_AGEE_TYPE"
export const SET_GENDER_TYPE = "SET_GENDER_TYPE"
export const SET_PATIENT_TYPE = "SET_PATIENT_TYPE"
export const SET_PATIENT_HEIGHT = "SET_PATIENT_HEIGHT"
export const SET_PATIENT_HEIGHT_TYPE = "SET_PATIENT_HEIGHT_TYPE"
export const SET_PATIENT_WEIGHT = "SET_PATIENT_WEIGHT"
export const SET_PATIENT_WEIGHT_TYPE = "SET_PATIENT_WEIGHT_TYPE"
export const SET_ASAPS = "SET_ASAPS"
export const SET_EMERGENCY = "SET_EMERGENCY"
export const SET_SURGERY_NAME = "SET_SURGERY_NAME"
export const SET_SUB_SURGERY_NAME = "SET_SUB_SURGERY_NAME"
export const SET_CATEGORY_SURGERY_NAME = "SET_CATEGORY_SURGERY_NAME"
export const SET_SUB_SUB_SURGERY_NAME = "SET_SUB_SUB_SURGERY_NAME"
export const SET_SUB_SUB_SUB_SURGERY_NAME = "SET_SUB_SUB_SUB_SURGERY_NAME"
export const SET_ANAESTHASIA_TYPE = "SET_ANAESTHASIA_TYPE"
export const SET_SUB_ANAESTHASIA_TYPE = "SET_SUB_ANAESTHASIA_TYPE"
export const SET_SUB_SUB_ANAESTHASIA_TYPE = "SET_SUB_SUB_ANAESTHASIA_TYPE"
export const SET_SUB_SUB_SUB_ANAESTHASIA_TYPE = "SET_SUB_SUB_SUB_ANAESTHASIA_TYPE"
export const SET_MEDICAL_HISTORY = "SET_MEDICAL_HISTORY"
export const SET_MEDICAL_HISTORY_OTHER ="SET_MEDICAL_HISTORY_OTHER"
export const SET_REMOVE_MEDICAL_HISTORY = "SET_REMOVE_MEDICAL_HISTORY"
export const SET_MEDICATION = "SET_MEDICATION"
export const REMOVE_MEDICATION = "REMOVE_MEDICATION"
export const SET_NON_LISTED_MEDICATION = "SET_NON_LISTED_MEDICATION"
export const SET_LAST_FOOD_TIME = "SET_LAST_FOOD_TIME"
export const SET_TIME_TYPE = "SET_TIME_TYPE"
export const SET_INDIGESTED_MATERIAL = "SET_INDIGESTED_MATERIAL"
export const SET_ALLERGIES = "SET_ALLERGIES"
export const SET_NOT_LISTED_ALLERGIES = "SET_NOT_LISTED_ALLERGIES"
export const SET_SURGERY_HISTORY_NAME = "SET_SURGERY_HISTORY_NAME"
export const SET_SUB_SURGERY_HISTORY_NAME = "SET_SUB_SURGERY_HISTORY_NAME"
export const SET_SUB_SUB_SURGERY_HISTORY_NAME = "SET_SUB_SUB_SURGERY_HISTORY_NAME"
export const SET_SUB_SUB_SUB_SURGERY_HISTORY_NAME = "SET_SUB_SUB_SUB_SURGERY_HISTORY_NAME"
export const SET_SUB_SUB_SUB_SUB_SURGERY_HISTORY_NAME = "SET_SUB_SUB_SUB_SUB_SURGERY_HISTORY_NAME"
export const SET_NON_LISTED_SURGERY_HISTORY_NAME = "SET_NON_LISTED_SURGERY_HISTORY_NAME"
export const SET_ANESTHASIA_HISTORY = "SET_ANESTHASIA_HISTORY"
export const REMOVE_ANESTHASIA_HISTORY = "REMOVE_ANESTHASIA_HISTORY"
export const SET_ANESTHASIA_INPUT = "SET_ANESTHASIA_INPUT"
export const SET_ANESTHASIA_INPUT_FAMILY = "SET_ANESTHASIA_INPUT_FAMILY"
export const SET_SAVE_CASE_NAME = "SET_SAVE_CASE_NAME"
// Note page message
export const SET_NOTE_MESSAGE = "SET_NOTE_MESSAGE"
export const SET_FILE_NAME = "SET_FILE_NAME"
export const SET_FILE_NAME_2 = "SET_FILE_NAME_2"
export const SET_FILE_NAME_3 = "SET_FILE_NAME_3"
export const RESET = "RESET"