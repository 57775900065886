import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';
import { Link } from 'react-router-dom';
import HasBledScore from './HasBledScore';
import FourTScore from './FourTScore';
import SickleCellRBCExchangeVolume from './SickleCellRBCExchangeVolume';
import BloodProductFluid from './BloodProductFluid';
const HematologicCalculators = (props) => {
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };
    useEffect(() => {
        toggleTab(1);
    }, [])

    return (
        <div>
            <div className="backNavigation text-center">
                <Link className="arrow" to="/actionLibrary"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Action Library</span></span></Link> HEMATOLOGIC CALCULATORS
            </div>
            <div className="main-container calculator-container mt-3">
                <div className="row">
                    <div className="col-md-4 airway-calc-tabs">
                    <button className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(1)} >HAS-BLED Score</button>
                        <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(2)} >Sickle Cell RBC Exchange Volume</button>
                        <button className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(3)} >Blood products and expected results</button>
                        <button className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(4)} >4T's Score for Heparin-Induced Thrombocytopenia</button>
                       
                    </div>
                    <div className="col-md-8 col-sm-12">
                        <div className="content-tabs">
                            <div
                                className={toggleState === 1 ? "calculator-content  active-content" : "calculator-content"}>
                             <HasBledScore/>
                            </div>
                            <div className={toggleState === 2 ? "calculator-content  active-content" : "calculator-content"}>
                            <SickleCellRBCExchangeVolume/>
                            </div>

                            <div
                                className={toggleState === 3 ? "calculator-content  active-content" : "calculator-content"} >
                               <BloodProductFluid/>
                            </div>

                            <div
                                className={toggleState === 4 ? "calculator-content  active-content" : "calculator-content"}>
                               <FourTScore/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default HematologicCalculators;