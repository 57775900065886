import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';

const RcriCardiovascularCalculator = () => {
    const [green, setGreen] = useState(0)
    const [surgery, setSurgery] = useState(0)
    const [ischemic, setIschemic] = useState(0)
    const [congestive, setCongestive] = useState(0)
    const [cerebrovascular, setCarebrovascular] = useState(0)
    const [insulin, setInsulin] = useState(0)
    const [creatinine, setCreatinine] = useState(0)
    const [result, setResult] = useState(0)
    const postData = async () => {
        setGreen(true);
        setResult(surgery + ischemic + congestive + cerebrovascular + insulin + creatinine);
    }
    useEffect(() => {
        postData()

    }, [surgery, ischemic, congestive, cerebrovascular, insulin, creatinine])

    const output = () => {

      
        if ((result == 0)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container " >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>Class I Risk</div>
                </div>
                <div className="point-result-container" >
                    <div>
                        <h1>3.9<span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>&nbsp;%</span></h1>
                        <br />
                        <div>30-day risk of death, MI, or cardiac arrest</div>
                        <br />
                        <div>
                            From Duceppe 2017, based on pooled data from 5 high quality external validations (4 prospective). These numbers are higher than those often quoted from the now-outdated original study (Lee 1999). See Evidence for details.</div>
                    </div>
                </div>
            </div>
        }
        else if ((result == 1)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>Class II Risk</div>
                </div>
                <div className="point-result-container" >
                    <div>
                        <h1>6.0<span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>&nbsp;%</span></h1>
                        <br />
                        <div>30-day risk of death, MI, or cardiac arrest</div>
                        <br />
                        <div>
                            From Duceppe 2017, based on pooled data from 5 high quality external validations (4 prospective). These numbers are higher than those often quoted from the now-outdated original study (Lee 1999). See Evidence for details.</div>
                    </div>
                </div>
            </div>
        }
        else if ((result == 2)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>Class III Risk</div>
                </div>
                <div className="point-result-container" >
                    <div>
                        <h1>10.1<span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>&nbsp;%</span></h1>
                        <br />
                        <div>30-day risk of death, MI, or cardiac arrest</div>
                        <br />
                        <div>
                            From Duceppe 2017, based on pooled data from 5 high quality external validations (4 prospective). These numbers are higher than those often quoted from the now-outdated original study (Lee 1999). See Evidence for details.</div>
                    </div>
                </div>
            </div>
        }
        else if ((result >= 3)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>Class IV Risk</div>
                </div>
                <div className="point-result-container" >
                    <div>
                        <h1>15.0<span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>&nbsp;%</span></h1>
                        <br />
                        <div>30-day risk of death, MI, or cardiac arrest</div>
                        <br />
                        <div>
                            From Duceppe 2017, based on pooled data from 5 high quality external validations (4 prospective). These numbers are higher than those often quoted from the now-outdated original study (Lee 1999). See Evidence for details.</div>
                    </div>
                </div>
            </div>
        }
    
        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }

    return (
        <div className="rev">
            <h5 className="text-info pl-2">Revised Cardiac Risk Index for Pre-Operative Risk</h5>
            <p className="pl-2">Estimates risk of cardiac complications after noncardiac surgery.</p>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Elevated-risk surgery</strong></div>
                    <div className="pt-2">Intraperitoneal; intrathoracic; suprainguinal vascular </div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={surgery === 0 ? "btn active" : "btn"} onClick={() => setSurgery(0)}>No  <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={surgery === 1 ? "btn active" : "btn"} onClick={() => setSurgery(1)}>Yes <span className="ml-4 text-muted">+1</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>History of ischemic heart disease</strong></div>
                    <div className="pt-2">History of myocardial infarction (MI); history of positive exercise test; current chest pain considered due to myocardial ischemia; use of nitrate therapy or ECG with pathological Q waves
                    </div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={ischemic === 0 ? "btn active" : "btn"} onClick={() => setIschemic(0)}>No  <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={ischemic === 1 ? "btn active" : "btn"} onClick={() => setIschemic(1)}>Yes <span className="ml-4 text-muted">+1</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>History of congestive heart failure</strong></div>
                    <div className="pt-2">Pulmonary edema, bilateral rales or S3 gallop; paroxysmal nocturnal dyspnea; chest x-ray (CXR) showing pulmonary vascular redistribution
                    </div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={congestive === 0 ? "btn active" : "btn"} onClick={() => setCongestive(0)}>No  <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={congestive === 1 ? "btn active" : "btn"} onClick={() => setCongestive(1)}>Yes <span className="ml-4 text-muted">+1</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>History of cerebrovascular disease</strong></div>
                    <div className="pt-2">Prior transient ischemic attack (TIA) or stroke
                    </div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={cerebrovascular === 0 ? "btn active" : "btn"} onClick={() => setCarebrovascular(0)}>No  <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={cerebrovascular === 1 ? "btn active" : "btn"} onClick={() => setCarebrovascular(1)}>Yes <span className="ml-4 text-muted">+1</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Pre-operative treatment with insulin</strong></div>

                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={insulin === 0 ? "btn active" : "btn"} onClick={() => setInsulin(0)}>No  <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={insulin === 1 ? "btn active" : "btn"} onClick={() => setInsulin(1)}>Yes <span className="ml-4 text-muted">+1</span></button>

                </div>
            </div>
            <div className="fields row mb-5">
                <div className="col-md-6">
                    <div ><strong>Pre-operative creatinine &gt;2 mg/dL / 176.8 µmol/L</strong></div>

                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={creatinine === 0 ? "btn active" : "btn"} onClick={() => setCreatinine(0)}>No  <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={creatinine === 1 ? "btn active" : "btn"} onClick={() => setCreatinine(1)}>Yes <span className="ml-4 text-muted">+1</span></button>

                </div>
            </div>

            <div className="stick pt-3">
                {
                    output()
                }
            </div>
            <div className="p-2 mb-4" >
                <div>
                    ORIGINAL/PRIMARY REFERENCE
                </div>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/904659/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Goldman L, Caldera DL, Nussbaum SR, Southwick FS, Krogstad D, Murray B, Burke DS, O'Malley TA, Goroll AH, Caplan CH, Nolan J, Carabello B, Slater EE. Multifactorial index of cardiac risk in noncardiac surgical procedures. N Engl J Med. 1977 Oct 20;297(16):845-50.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default RcriCardiovascularCalculator
