import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

const PrivacyPolicy=(props)=>{
  const agree = () => {
    props.history.push({
      pathname: "/signup",
      state: {
          agree: true,
      }
  })
  }
  const disagree = () => {
    props.history.push({
      pathname: "/signup",
      state: {
        agree: false,
      }
  })
  }
    return(
        <div><style>{"\.header {\ display:none; \ } \ "}</style>
            <Navbar/>
        <div className="container tnc mt-5 mb-5" >

             <h3 className="font-weight-bold mt-3 text-center">
          Privacy Policy Page
        </h3>
        <p className="para mb-5 mt-2">
          At Anesthesia One, we are committed to respecting your privacy. This
          privacy policy describes how Anesthesia One and its subsidiaries,
          parent company and affiliated companies ("Anesthesia One," "we",
          "our", or “us”) may collect, use, share and disclose information about
          users who use our website http://www.anesthesiaone.com/, Anesthesia
          One Online (website) and/or our Anesthesia One mobile application(s),
          displaying this policy as well as any communication mechanisms (e.g.,
          emails or newsletters) from us in connection with our services and
          that display this privacy policy ("Services"). This policy does not
          cover websites, applications or services displaying different privacy
          statements. </p>
        <h4 className="font-weight-bold mt-3 text-left">
          1. What information does Anesthesia One collect?
        </h4>
        <h4 className="font-weight-bold mt-3 text-left">
          Information you provide us.
        </h4>
        <p className="para mb-5 mt-2">
          1. When you create a new account in our mobile application or website,
          we may ask for your first and last name, email, creation of a
          password, occupation, country of practice and if your country of
          residence is the United States, we may ask for your zip code. We will
          then present you with a list of Health Care Providers (“HCPs”) with
          your same name/zip code combination and available National Provider
          Identification (“NPI”) numbers, based on data from the National Plan &
          Provider Enumeration System (“NPPES”) registry. You will be asked to
          select the correct NPI profile that represents you. Once you claim
          your profile, we will ask about your specialty and any applicable
          sub-specialty to designate in your account;<br />
          2. When you create a new account or update an existing account in
          Anesthesia One Online, we ask for your full name, email address(es),
          creation of password, country of residence, work zip code, occupation
          and specialty/subspecialty information (as applicable). You may also
          provide information related to the medical school you attended
          including the country, state, name, graduation year, former last name
          (as applicable) and date of birth. We may also ask you to provide
          other pieces of information, including, but not limited to,
          information related to your residency (including year(s) attended),
          information related to your practice, information related to your
          hospital affiliation and your billing address. This process may also
          include a series of security related questions;
          <br />
          3. We will collect date of birth when you participate in Continuing
          Medical Education (“CME”) programs offered through our Services;
          <br />
          4. We may ask you for other types of information like demographic
          information, for example, when you participate in a survey or product
          test or when you enter a contest or other promotional event;<br />
          5. We may also collect and track information related to your current
          and historic subscription status, search queries, discount codes used,
          and other interactions with the Services which may inherently be
          associated with other identifiable characteristics of you. For
          example, we may collect a discount code that is directly tied to your
          employer;
          <br />
          5. Through your interaction and use of http://www.anesthesiaone.com/
          and/or request for information related to our Services, we may also
          collect the following information from you: first and last name, email
          address(es), phone, address including a zip code,
          employer/organization name, employment title, school affiliation
          information (as applicable), information you provide related to your
          interest in Anesthesia One and our Services (for example, nature of
          your request (for example, what product(s) you are interested in and
          why you are interested in Anesthesia One), number of licenses you are
          interested in, product interest, etc.), school affiliation
          information, and the text you enter into free text fields;
          <br />
          6. We may also collect any information you provide to us through use
          of the Services. For example, if you use the ‘Feedback’ functionality
          within the Services, we will collect the rating you provide as well as
          any information you enter the free text field;
          <br />
          7. We do not collect or process any sensitive data such as: race or
          ethnic origin; political opinions; religious or philosophical beliefs;
          genetic or biometric data; health or mortality; or sexual orientation.
          <br />
        </p>
        <h4 className="font-weight-bold mt-3">
          Information we may automatically collect.
        </h4>
        <p className="para mb-5 mt-2">
          When you visit our website http://www.anesthesiaone.com/ use our
          Services or interact with communications we may send to you in
          connection with your use of our Services (for example, emails or
          newsletters), some information is automatically collected. For
          example, when you visit or use our Services your computer's operating
          system, Internet Protocol (IP) address, access times, browser type and
          language, geo-location and the website you visited before our site are
          collected and logged automatically. We also collect information in
          connection with your use of the Services and your interaction with the
          Services or your interaction with communications sent to you in
          connection with your use of our Services including, but not limited
          to, your clicks and searches through your use of the Services,
          advertisements, your interaction with the content made available
          through the Services, messages and communications (e.g. emails or
          newsletters) surfaced through or in connection with your use of the
          Services (whether sponsored or not sponsored), emails, newsletters,
          push notifications, In App messages or other messaging from us (“User
          Behavior Information”). We may combine this automatically collected
          information with other information we collect about you through your
          use of the Services or received from other 3rd party sources (for
          example), your NPI available data from the National Plan & Provider
          Enumeration System (“NPPES”). Your use of our Services or our website
          are treated as your consent to the automatic collection of the data
          described herein. Additionally, when you use our Services or interact
          with communications sent to you in connection with our Services, we
          may automatically collect data about your device such as your device
          ID, type of device you use, operating system version and information
          related to your use of the Services.
        </p>
        <h4 className="font-weight-bold mt-3">
          Information we receive from third parties.
        </h4>
        <p className="para mb-5 mt-2">
          We may also collect additional information about you from third
          parties to assist us in providing you with Services. For example, we
          may obtain commercially or publicly available information about you
          from third parties or purchase email lists from third-parties for
          advertising and marketing purposes. We may also receive information
          from third-parties who provide services to us through web-beacons and
          other technologies or as otherwise discussed in this Privacy Policy
          and use such information for marketing, sales, and advertising
          purposes
        </p>
        <h4 className="font-weight-bold mt-3">
          2. How does Anesthesia One use the information we collect?
        </h4>
        <p className="para mb-5 mt-2">
          We use information collected (including User Behavior Information)
          through our Services for purposes described in this policy or for
          those purposes disclosed to you in our Services. For example, we may
          use your information to:<br />
          1. Operate and improve our Services;<br />2. Share information you
          provide to us and/or User Behavior Information with clients and
          prospective clients (for example, pharmaceutical companies and other
          advertising clients) about your use or interaction with the Services,
          interaction with promotional and non-promotional content contained
          within the Services as well as interaction with communications you
          receive in connection with the Services (for example, emails and
          newsletters);<br />3. To provide you access to CME content through
          the Services; Share User Behavior Information insights with existing
          or prospective clients;<br />4. Respond to your comments and
          questions and provide customer service;<br />5. Provide, deliver
          and improve upon products and services you request;<br />6. Send
          you related information, including confirmations, invoices, technical
          notices, updates, security alerts, and support and administrative
          messages;<br />7. Better understand you so that we may tailor
          messaging and services to you based on your interests, preferences,
          needs and specialties;<br />8. Communicate with you via email,
          alerts (push notifications and/or in app alerts) and other messaging
          outlets about commercial, non-commercial, sponsored and non-sponsored
          information, FDA and product safety alerts, new drugs and
          pharmaceutical studies and related information. By accepting the terms
          of this policy, you are opting in to receiving such communications
          from us;<br />9. Send you information via email about products and
          services offered by us, our affiliates, and our partners. By accepting
          the terms of this policy, you are opting in to receive such emails
          from us. Send you invitations, by email or other means, to participate
          in market research survey opportunities. By accepting the terms of
          this policy, you are opting in to receive such invitations from us;
          Link or combine your information with other information we collect
          through our Services information collected through your interaction
          with communications received in connection with the Services, with
          information receive from third parties.
        </p>
        <h4 className="font-weight-bold mt-3">
          3. How does Anesthesia One protect your information?
        </h4>
        <p className="para mb-5 mt-2">
          We take appropriate measures to protect the information that we
          receive about you from unauthorized access, disclosure, alteration, or
          destruction. When we collect certain sensitive information (such as
          geolocation), we encrypt the transmission of that information using
          secure socket layer technology (SSL). No method of transmission over
          the Internet or electronic storage is 100% secure; as a result, we
          cannot guarantee absolute security. If you have any questions about
          security, you can contact us at anesthesiaoneplus@gmail.com.
        </p>
        <h4 className="font-weight-bold mt-3">
          4. Will Anesthesia One share the information it collects with others?
        </h4>
        <p className="para mb-5 mt-2">
          We will share your information with third parties in the ways that are
          described in this privacy policy. We may share information as follows:
          <br />
          1. With your consent; With our third party vendors, consultants,
          agents, and other service providers with whom we contract to help us
          provide or improve our Services. For example, we may work with
          companies to host and maintain our data, provide messaging services,
          analyze our data or provide marketing assistance;<br />2. To
          provide our commercial clients and prospective commercial clients,
          such as pharmaceutical companies and their advertising agencies, with
          your information for audience or user matching purposes;<br />3. To
          provide our commercial clients and prospective commercial clients (for
          example, pharmaceutical companies and their advertising agencies) with
          your information when you engage with promotional and nonpromotional
          content (including related communications) through or in connection
          with our Services and information about the type of engagement (e.g.,
          whether you viewed, interacted with or requested information about
          such promotional content);<br />4. In connection with providing you
          access to CME content through the Services, your information and/or
          User Behavior Information will be shared with relevant Accrediting
          Organizations, Accredited Education Providers, Medical Education
          Companies and Medical Education Sponsors (collectively referred to as
          “Continuing Medical Education Partners”) who have funded, created, or
          distributed the CME content through the Services. An Accrediting
          Organization is a body that provides the criteria, policies, and
          standards to Accredited Education Providers. An Accredited Education
          Provider is an entity that has created medical education materials and
          has been given accreditation by an Accrediting Organization. A Medical
          Education Company participates in the coordination and distribution of
          accredited medical education content. A Medical Education Sponsor is a
          company or organization that provides funding to create medical
          education content;<br />5. To (i) comply with laws or to respond to
          lawful requests and legal process, (ii) to protect the rights and
          property of our agents, customers, and others including to enforce our
          agreements, policies and terms of use or (iii) in an emergency to
          protect the safety of Anesthesia One, its customers, or any person,
          and in certain situations, we may be required to disclose information
          in response to lawful requests by public authorities, including
          meeting national security or law enforcement requirements;<br />6.
          In connection with or during negotiation of any merger, financing,
          acquisition or bankruptcy transaction or proceeding involving sale or
          transfer of all or a portion of our business or assets to another
          company;<br />7. Share aggregated information such as statistics
          about our customers, sales, product usage or traffic patterns, and
          related website or Services information as well as User Behavior
          Information with clients, prospective clients or third party vendors
          who need access to the information in order to provide the Services;
          <br />8. When you engage in market research activities, we will
          share your information with the market research clients;<br />9. If
          you post a comment or message in our blog or other public forums
          hosted by us, it will be shared publicly. We display personal
          testimonials of satisfied customers on our site in addition to other
          endorsements. With your consent, we may post your testimonial along
          with your name.<br />10. We do not sell or disclose the information
          of minors under the age of sixteen without affirmative authorization.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          5. Your choices about the information we collect.
        </h4>
        <p className="para mb-5 mt-2">
          You have the option of correcting, updating, deleting and/or changing
          information by emailing anesthesiaoneplus@gmail.com or by updating
          your profile. If you would like to update your password or change or
          update your credit card information, please email
          anesthesiaoneplus@gmail.com for instructions on how to make such
          password or credit card changes or updates. We will retain the
          information we collect for as long as your account is active or as
          needed to provide you Services, subject to legal obligations, in order
          to comply with our legal obligations, resolve disputes, and enforce
          our agreements. If you wish to cancel your account or request that we
          no longer use your information to provide you services, contact us at
          anesthesiaoneplus@gmail.com. You may opt out of any promotional emails
          at any time by following the unsubscribe instructions in the
          promotional emails you receive from us or by sending an email to
          anesthesiaoneplus@gmail.com. If you have questions or concerns about
          market research programs we may conduct you can send an email to
          anesthesiaoneplus@gmail.com
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          6. How are "tracking technologies" used in/on our Services?
        </h4>
        <p className="para mb-5 mt-2">
          Technologies such as cookies, beacons, tags, and scripts are used by
          Anesthesia One and our marketing partners, affiliates, or analytics or
          service providers. These technologies are used in analyzing trends,
          administering the site, tracking users’ movements on both the native
          iOS and Android apps, as well as http://www.anesthesiaone.com/, and to
          gather information about our user base. For example, we may receive
          reports based on the use of these technologies on an individual as
          well as aggregated basis. In doing so, we may collect personally
          identifiable and non-personally identifiable information (including,
          but not limited to, domain type, browser type and version, service
          provider and IP address, referring/exit pages, operating system,
          date/time stamp, email address, and click-stream data). We may also
          compile statistics about how our visitors collectively interact with
          the Services. We may also supplement this information we collect with
          other publicly available or commercial sources to build out user
          profiles for improved targeting and to share with our advertisers for
          their services. We may use both session and persistent cookies to help
          us provide you with a more personalized experience and improve our
          services. Users can control the use of cookies at the individual
          browser level. If you reject cookies, you may still use our site, but
          your ability to use some features or areas of our site may be limited.
          Cookies are bits of electronic information that a website may transfer
          to a visitor’s computer to identify specific information about that
          visitor’s use of that website. Session cookies or transient cookies
          are cookies that are stored in temporary memory and are not retained
          when the browser is closed. Session cookies do not collect information
          from your computer and typically will store information in the form of
          a session identification that does not personally identify you.
          Persistent cookies assign a unique identification to your computer and
          are typically stored on your computer’s hard drive and are used to
          help track clicks as you use or access the Services. Persistent
          cookies remain on your computer until affirmatively deleted by you. We
          may also use cookies set by third parties with whom we have entered
          into agreements which may enable us to obtain analytics information
          about the use of the Services. You can set your browser not to accept
          cookies or to notify you when you are sent a cookie, giving you the
          opportunity to decide whether or not to accept it. You may also use
          commonly available tools in your browser to remove cookies which may
          have been placed onto your computer. We may also use web beacons or
          clear.gifs. Web beacons or clear.gifs, and similar technologies are
          pieces of code placed on a web page to collect data on the users of a
          specific web page.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          Server Logs and Widgets:
        </h4>
        <p className="para mb-5 mt-2">
          We may use web server logs. A web server log is a record of activity
          created by a computer that delivers certain webpages to your browser.
          Certain activities that you perform utilizing the Services may record
          information in server logs. For example, the server log may record the
          search term(s) you use, or the link you clicked on to bring you to the
          Services. The server log may also record information about your
          browser, such as your IP address and the cookies set on your browser.
          We may also use widgets. A widget is generally an application that can
          be embedded in a webpage, and which can provide real-time information
          to the webpage. Widgets are often provided by third parties to enable
          collection of data about website usage. We may use mobile analytics
          software to allow us to better understand the functionality of our
          Services. This software may record information such as, but not
          limited to, how often you use the application, the events that occur
          within the application, usage information, performance data, and where
          the application was downloaded from. We or third parties with whom we
          may partner to provide certain features within our Services or to
          display advertising based upon your browsing activity use LSOs (Local
          Shared Objects), which are similar to cookies, to collect and store
          information. Various browsers may offer their own management tools for
          removing LSOs. We may partner with third parties to either display
          advertising on our website and mobile applications or to manage our
          advertising on other sites. Our third party partners may use
          technologies such as cookies to gather information about your
          activities on this site and other sites in order to provide you
          targeted advertising based upon your browsing activities and
          interests. Our Services do not respond to browser “Do Not Track”
          signals. By using or accessing the Services and/or by contacting us
          and/or by providing any of your information, you give us your consent
          to track your activities using the technologies described above, as
          well as similar technologies developed in the future, and that we may
          use such tracking technologies in the emails we send you.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          7. California Privacy Rights Notice
        </h4>
        <p className="para mb-5 mt-2">
          Effective Date: January 1st, 2020 This Privacy Notice for California
          Residents supplements the information contained in above included
          Anesthesia One Privacy Policy and applies solely to all visitors,
          users, and others who reside in the State of California ("consumers"
          or "you"). We adopt this notice to comply with the California Consumer
          Privacy Act of 2018 (CCPA) and any terms defined in the CCPA have the
          same meaning when used in this Notice. Where noted in this Notice, the
          CCPA temporarily exempts Personal Information reflecting a written or
          verbal business-to-business communication ("B2B Personal Information")
          from some its requirements.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          Information We Collect
        </h4>
        <p className="para mb-5 mt-2">
          We collect information that identifies, relates to, describes,
          references, is reasonably capable of being associated with, or could
          reasonably be linked, directly or indirectly, with a particular
          consumer, household, or device ("Personal Information"). Personal
          Information does not include:<br />
          1. Publicly available information from government records.<br />2.
          Deidentified or aggregated consumer information.<br />3.
          Information excluded from the CCPA's scope, like: <br />Health or
          medical information covered by the Health Insurance Portability and
          Accountability Act of 1996 (HIPAA) and the California Confidentiality
          of Medical Information Act (CMIA) or clinical trial data;<br />
          <br />
          We collected the following categories of Personal Information from you
          within the last twelve (12) months:<br /> <br />
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Category</th>
                <th scope="col">Examples</th>
                <th scope="col">Collected</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A. Identifiers.</td>
                <td>
                  A real name, alias, postal address, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, account name, Social Security number, driver's
                  license number, passport number, or other similar identifiers.
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>
                  B. Personal Information categories listed in the California
                  Customer Records statute (Cal. Civ. Code § 1798.80(e)).
                </td>
                <td>
                  A name, signature, Social Security number, physical
                  characteristics or description, address, telephone number,
                  passport number, driver's license or state identification card
                  number, insurance policy number, education, employment,
                  employment history, bank account number, credit card number,
                  debit card number, or any other financial information, medical
                  information, or health insurance information. Some Personal
                  Information included in this category may overlap with other
                  categories.
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>
                  C. Protected classification characteristics under California
                  or federal law.
                </td>
                <td>
                  Age (40 years or older), race, color, ancestry, national
                  origin, citizenship, religion or creed, marital status,
                  medical condition, physical or mental disability, sex
                  (including gender, gender identity, gender expression,
                  pregnancy or childbirth and related medical conditions),
                  sexual orientation, veteran or military status, genetic
                  information (including familial genetic information).
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>E. Biometric information.</td>
                <td>
                  Genetic, physiological, behavioral, and biological
                  characteristics, or activity patterns used to extract a
                  template or other identifier or identifying information, such
                  as, fingerprints, faceprints, and voiceprints, iris or retina
                  scans, keystroke, gait, or other physical patterns, and sleep,
                  health, or exercise data.
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>F. Internet or other similar network activity..</td>
                <td>
                  Browsing history, search history, information on a consumer's
                  interaction with a website, application, or advertisement.
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>G. Geolocation data.</td>
                <td>Physical location or movements.</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>H. Sensory data.</td>
                <td>
                  Audio, electronic, visual, thermal, olfactory, or similar
                  information.
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>I. Professional or employment-related information.</td>
                <td>Current or past job history or performance evaluations.</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>
                  J. Non-public education information (per the Family
                  Educational Rights and Privacy Act (20 U.S.C. Section 1232g,
                  34 C.F.R. Part 99)).
                </td>
                <td>
                  Education records directly related to a student maintained by
                  an educational institution or party acting on its behalf, such
                  as grades, transcripts, class lists, student schedules,
                  student identification codes, student financial information,
                  or student disciplinary records.
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>K. Inferences drawn from other Personal Information.</td>
                <td>
                  Profile reflecting a person's preferences, characteristics,
                  psychological trends, predispositions, behavior, attitudes,
                  intelligence, abilities, and aptitudes.
                </td>
                <td>YES</td>
              </tr>
            </tbody>
          </table>
          <br />
          We obtain the categories of Personal Information listed above from the
          following categories of sources:<br />
          Directly from you. For example, from your use of the Services.
          <br />
          Indirectly from you. For example, from observing your actions on our
          website your interaction with our Services.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          Use of Personal Information
        </h4>
        <p className="para mb-5 mt-2">
          We may use, sell, or disclose the Personal Information we collect for
          one or more of the following purposes:<br />
          1. To fulfill or meet the reason you provided the information. For
          example, responding to questions about services;<br />2. processing
          payment or provision of services.<br />3. To create, maintain,
          customize, and secure your account with us.<br />4. To process your
          requests, purchases, transactions, and payments and prevent
          transactional fraud. To provide you with support and to respond to
          your inquiries, including to investigate and address your concerns and
          monitor and improve our responses.<br />5. To personalize your
          experience through our Services and to deliver content and product and
          service offerings relevant to your interests, including targeted
          offers and ads through our Website, third-party sites, and via email
          or text message with your consent, where required by law.<br />6.
          To help maintain the safety, security, and integrity of our Website,
          products and services, databases and other technology assets, and
          business.<br />7. For testing, research, analysis, and product
          development, including to develop and improve our Website, products,
          and services.<br />8. To respond to law enforcement requests and as
          required by applicable law, court order, or governmental regulations.
          <br />9. As described to you when collecting your Personal
          Information or as otherwise set forth in the CCPA.<br />10. To
          provide our paying commercial clients or prospective commercial
          clients (for example, pharmaceutical companies) with information about
          your use of the Services and Usage Behavior Information.<br />11.
          To provide our Continuing Medical Education Partners with information
          about your use of the Services, Usage Behavior Information, and
          information as required for your CME credit(s), Continuing Medical
          Education Partner’s reporting obligations to the relevant
          accreditation bodies, and/or for their internal recordkeeping and
          regulatory purposes.<br />12. To evaluate or conduct a merger,
          divestiture, restructuring, reorganization, dissolution, or other sale
          or transfer of some or all of our assets, whether as a going concern
          or as part of bankruptcy, liquidation, or similar proceeding, in which
          Personal Information held by us is among the assets transferred.
          <br />
          <br />
          We will not collect additional categories of Personal Information or
          use the Personal Information we collected for materially different,
          unrelated, or incompatible purposes without providing you notice.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          Sharing Personal Information
        </h4>
        <p className="para mb-5 mt-2">
          We may disclose your Personal Information to a third party for a
          business purpose or sell your Personal Information, subject to your
          right to opt-out of those sales (see Personal Information Sales
          Opt-Out and Opt-In Rights, below). We share your Personal Information
          with the following categories of third parties:
          <br />
          1. Service providers.<br />
          2. Clients that pay for use of the information including
          pharmaceutical companies.<br />3. Continuing Medical Education
          Partners.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          Disclosures of Personal Information for a Business Purpose
        </h4>
        <p className="para mb-5 mt-2">
          In the preceding twelve (12) months, we have disclosed the following
          categories of Personal Information for a business purpose: Category A:
          Identifiers. Category B: California Customer Records Personal
          Information categories. Category C: Protected classification
          characteristics under California or federal law. Category D:
          Commercial information. Category F: Internet or other similar network
          activity. Category G: Geolocation data. Category I: Professional or
          employment-related information. Category K: Inferences drawn from
          other Personal Information. We disclose your Personal Information for
          a business purpose to the following categories of third parties:
          <br />
          1. Service providers.<br />
          2.Third Party Partners
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          Sales of Personal Information
        </h4>
        <p className="para mb-5 mt-2">
          In the preceding twelve (12) months, Company has sold the following
          categories of Personal Information:<br /> A. Identifiers.<br />{" "}
          B. California Customer Records Personal Information categories.
          <br /> D. Commercial information.<br /> F. Internet or other
          similar network activity.<br /> I. Professional or
          employment-related information.<br /> K. Inferences drawn from
          other Personal Information.<br />
          We sell your Personal Information to the following categories of third
          parties:<br />1. Commercial clients (including prospective
          commercial clients). For example, pharmaceutical companies, media
          agencies that represent such pharmaceutical clients and medical
          societies as well as Continuing Medical Education Partners.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          Your Rights and Choices
        </h4>
        <p className="para mb-5 mt-2">
          The CCPA provides consumers (California residents) with specific
          rights regarding their Personal Information. This section describes
          your CCPA rights and explains how to exercise those rights.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          Sales of Personal Information
        </h4>
        <p className="para mb-5 mt-2">
          In the preceding twelve (12) months, Company has sold the following
          categories of Personal Information:<br /> A. Identifiers.<br />{" "}
          B. California Customer Records Personal Information categories.
          <br /> D. Commercial information.<br /> F. Internet or other
          similar network activity.<br /> I. Professional or
          employment-related information.<br /> K. Inferences drawn from
          other Personal Information.<br />
          We sell your Personal Information to the following categories of third
          parties:<br />1. Commercial clients (including prospective
          commercial clients). For example, pharmaceutical companies, media
          agencies that represent such pharmaceutical clients and medical
          societies as well as Continuing Medical Education Partners.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          Access to Specific Information and Data Portability Rights
        </h4>
        <p className="para mb-5 mt-2">
          You have the right to request that we disclose certain information to
          you about our collection and use of your Personal Information over the
          past 12 months. Once we receive and confirm your verifiable consumer
          request (see Exercising Access, Data Portability, and Deletion
          Rights), we will disclose to you:<br />1. The categories of
          Personal Information we collected about you.<br />2. The categories
          of sources for the Personal Information we collected about you.
          <br />3. Our business or commercial purpose for collecting or
          selling that Personal Information.<br />4. The categories of third
          parties with whom we share that Personal Information.<br />5. The
          specific pieces of Personal Information we collected about you (also
          called a data portability request).<br />6. If we sold or disclosed
          your Personal Information for a business purpose, two separate lists
          disclosing:<br />
          <br /> sales, identifying the Personal Information categories that
          each category of recipient purchased;<br />
          <br /> and disclosures for a business purpose, identifying the
          Personal Information categories that each category of recipient
          obtained.
          <br />
          We do not provide these access and data portability rights for B2B
          Personal Information.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          Deletion Request Rights
        </h4>
        <p className="para mb-5 mt-2">
          You have the right to request that we delete any of your Personal
          Information that we collected from you and retained, subject to
          certain exceptions. Once we receive and confirm your verifiable
          consumer request (see Exercising Access, Data Portability, and
          Deletion Rights), we will delete (and direct our service providers to
          delete) your Personal Information from our records, unless an
          exception applies, including B2B Personal Information.<br />
          We may deny your deletion request if retaining the information is
          necessary for us or our service provider(s) to:<br />1. Complete
          the transaction for which we collected the Personal Information,
          provide a good or service that you requested, take actions reasonably
          anticipated within the context of our ongoing business relationship
          with you, fulfill the terms of a written warranty or product recall
          conducted in accordance with federal law, or otherwise perform our
          contract with you.<br />2. Detect security incidents, protect
          against malicious, deceptive, fraudulent, or illegal activity, or
          prosecute those responsible for such activities.<br />3. Debug
          products to identify and repair errors that impair existing intended
          functionality.<br />4. Exercise free speech, ensure the right of
          another consumer to exercise their free speech rights, or exercise
          another right provided for by law.<br />5. Comply with the
          California Electronic Communications Privacy Act (Cal. Penal Code §
          1546 et. seq.).<br />6. Engage in public or peer-reviewed
          scientific, historical, or statistical research in the public interest
          that adheres to all other applicable ethics and privacy laws, when the
          information's deletion may likely render impossible or seriously
          impair the research's achievement, if you previously provided informed
          consent.<br />7. Enable solely internal uses that are reasonably
          aligned with consumer expectations based on your relationship with us.
          <br />8. Comply with a legal obligation.<br />9. Make other
          internal and lawful uses of that information that are compatible with
          the context in which you provided it.
          <br>
          </br>
          We do not provide these deletion rights for B2B Personal
            Information.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          Exercising Access, Data Portability, and Deletion Rights
        </h4>
        <p className="para mb-5 mt-2">
          To exercise the access, data portability, and deletion rights
          described above, please submit a verifiable consumer request to us by
          either:<br />1. Email - anesthesiaoneplus@gmail.com <br />
          2. Address - 664 Heiden Drive, Hummelstown, Pa, 17036<br />Only
          you, or someone legally authorized to act on your behalf, may make a
          verifiable mer request on behalf of your minor child. To designate an
          authorized agent, please call the phone number referenced
          above.consumer request related to your Personal Information. You may
          also make a verifiable consumer request on behalf of your minor child.
          To designate an authorized agent, please call the phone number
          referenced above.<br />You may only make a verifiable consumer
          request for access or data portability twice within a 12-month period.
          The verifiable consumer request must:<br />1. Provide sufficient
          information that allows us to reasonably verify you are the person
          about whom we collected Personal Information or an authorized
          representative, which may include: Name, address, usernames (where
          applicable) and email address.<br />2. We may use this information
          to surface a series of security questions to you to verify your
          identity.<br />3. Describe your request with sufficient detail that
          allows us to properly understand, evaluate, and respond to it.
          <br />Please also note that any support issues related to your use
          of Anesthesia One must be sent to anesthesiaoneplus@gmail.com.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          Personal Information Sales Opt-Out and Opt-In Rights
        </h4>
        <p className="para mb-5 mt-2">
          If you are 16 years of age or older, you have the right to direct us
          to not sell your Personal Information at any time (the "right to
          opt-out"). We do not sell the Personal Information of consumers we
          actually know are less than 16 years of age, unless we receive
          affirmative authorization (the "right to opt-in") from either the
          consumer who is at least 13 but not yet 16 years of age, or the parent
          or guardian of a consumer less than 13 years of age. Consumers who
          opt-in to Personal Information sales may opt-out of future sales at
          any time. To exercise the right to opt-out, you (or your authorized
          representative) may submit a request to us: Emailing us at
          anesthesiaoneplus@gmail.com Once you make an opt-out request, we will
          wait at least twelve (12) months before asking you to reauthorize
          Personal Information sales. However, you may change your mind and opt
          back into Personal Information sales at any time by: Emailing us at
          anesthesiaoneplus@gmail.com We will only use Personal Information
          provided in an opt-out request to review and comply with the request.
          For Access, Data Portability, Deletion, or Sale Opt Out rights, we
          cannot respond to your request or provide you with Personal
          Information if we cannot verify your identity or authority to make the
          request and confirm the Personal Information relates to you. Making a
          verifiable consumer request does not require you to create an account
          with us. We will only use Personal Information provided in a
          verifiable consumer request to verify the requestor's identity or
          authority to make the request.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          Response Timing and Format
        </h4>
        <p className="para mb-5 mt-2">
          We endeavor to respond to a verifiable consumer request within
          forty-five (45) days of its receipt. If we require more time, we will
          inform you of the reason and extension period in writing. Any
          disclosures we provide will cover the 12-month period preceding the
          verifiable consumer request's receipt. The response we provide will
          also explain the reasons we cannot comply with a request, if
          applicable. For data portability requests, we will select a format to
          provide your Personal Information that is readily useable and should
          allow you to transmit the information from one entity to another
          entity without hindrance.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">Non-Discrimination</h4>
        <p className="para mb-5 mt-2">
          We will not discriminate against you for exercising any of your CCPA
          rights. Unless permitted by the CCPA, we will not:<br />1. Deny you
          goods or services.<br />2. Charge you different prices or rates for
          goods or services, including through granting discounts or other
          benefits, or imposing penalties.<br />3. Provide you a different
          level or quality of goods or services.<br />4. Suggest that you may
          receive a different price or rate for goods or services or a different
          level or quality of goods or services.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          Other California Privacy Rights
        </h4>
        <p className="para mb-5 mt-2">
          California's "Shine the Light" law (Civil Code Section § 1798.83)
          permits users of our Website that are California residents to request
          certain information regarding our disclosure of Personal Information
          to third parties for their direct marketing purposes. To make such a
          request to:<br />
          1. Email - anesthesiaoneplus@gmail.com <br />2. Address - 664
          Heiden Drive, Hummelstown, Pa, 17036
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          8. Changes to Our Privacy Notice
        </h4>
        <p className="para mb-5 mt-2">
          We reserve the right to amend this privacy notice at our discretion
          and at any time. When we make changes to this privacy notice, we will
          post the updated notice on the Website and update the notice's
          effective date. Your continued use of our Services following the
          posting of changes constitutes your acceptance of such changes.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          Contact Information and Feedback
        </h4>
        <p className="para mb-5 mt-2">
          If you have general questions about your Anesthesia One account,
          including application issues, questions over payment, or technical
          questions, please email anesthesiaoneplus@gmail.com. If you have any
          questions or comments about this privacy notice, the ways in which we
          collect and use your information described here, your choices and
          rights regarding such use, or wish to exercise your rights under
          California law, please do not hesitate to contact us at: Data
          Protection Officer Anesthesia One, 664 Heiden Drive, Hummelstown, Pa,
          17036 anesthesiaoneplus@gmail.com
        </p>
        <hr/>
        <h2 className="font-weight-bold text-center mt-3">
          Terms Of Service 
        </h2>
        <p className="para mt-2 mb-5">
          The Services (as defined below) are provided to you in consideration
          for your agreement with, and subject to, the following Terms of Use,
          which may be updated from time to time without notice to you, along
          with any additional terms or conditions that are referenced herein or
          that otherwise may apply to specific portions of the Services or the
          Anesthesia One website(s). Your use of the Services after the Terms of
          Use have been updated shall be deemed to constitute acceptance by you
          of any modifications, additions or deletions to the Terms of Use
          caused by such update. You must ensure that anyone accessing the
          Services via your account complies with the Terms of Use. You will
          always be able to view the most current version of these Terms of Use
          by clicking on a link at the bottom of any page within the Anesthesia
          One, Inc. website located at http://www.anesthesiaone.com/. By using
          the Services, you agree to these Terms of Use, whether or not you have
          registered with Anesthesia One. If you do not agree to all of these
          Terms of Use, do not use the Services.
        </p>
        <h4 className="font-weight-bold text-left mt-3">
          Scope of These Terms of Use
        </h4>
        <p className="para mt-2 mb-5">
          References to "Anesthesia One", "we", "us" or "our" in these Terms of
          Use mean Anesthesia One, including any company that Anesthesia One
          controls (for example a subsidiary that it owns). We provide
          Anesthesia One members with access to a wide array of resources that
          may be provided to you in a variety of mediums and devices now known
          or hereinafter developed, including mobile applications. These
          resources include, without limitation, news, tools and applications,
          sponsored programming and communication tools, including content
          licensed from our licensors, such as the American Medical Association
          (collectively, the "Services").
        </p>
        <h4 className="font-weight-bold mt-3">Our Information</h4>
        <p className="para mt-2 mb-5">
          The information that we make available through the Services is
          intended for physicians and other healthcare professionals. Updating
          certain information, including Current Procedural Terminology (CPT®),
          through the Services is dependent on maintaining agreements with our
          licensors. While we hope you find the Services helpful, you should
          remember that it is not meant to serve as a substitute for your own
          clinical judgment as a healthcare professional and you should evaluate
          and independently verify the information and results from the Services
          we provide. We do not provide professional advice or recommend
          particular products through our Services. If you are a consumer who
          chooses to use the professional-level information available to you
          through the Services, you do so at your own risk and you should not
          rely on that information as professional medical advice or use it to
          replace any relationship with your physician or other qualified
          healthcare professional. Individuals with any type of medical
          condition are specifically cautioned to seek professional medical
          advice before beginning any sort of health treatment. For medical
          concerns, including decisions about medications and other treatments,
          consumers should always consult their physician or, in serious cases,
          seek immediate assistance from emergency personnel. The Anesthesia
          One' drug interaction checker included in the Services is based on
          two-drug combinations. The results returned by the checker reflect
          only paired interactions and compatibility and do not reflect
          interactions or incompatibilities arising from combinations of more
          than two drugs at a time.
        </p>
        <h4 className="font-weight-bold mt-3">
          Anesthesia One Provider Directory
        </h4>
        <p className="para mb-5 mt-2">
          The Anesthesia One Provider Directory is provided for informational
          purposes only. You assume full responsibility for the communications
          with any individual you contact through the Anesthesia One Provider
          Directory. The database of information which drives the Anesthesia One
          Provider Directory does not contain sufficient information with which
          to verify credentials under the standards of the Joint Commission on
          Accreditation of Healthcare Organizations (JCAHO), National Committee
          for Quality Assurance (NCQA) of the Utilization Review Accreditation
          Committee (URAC), and use of the Anesthesia One Provider Directory to
          verify the credentials of physicians is prohibited. The inclusion of a
          healthcare professional in the Anesthesia One Provider Directory is
          not an endorsement of any such healthcare professional by Anesthesia
          One, any of its licensors, affiliates or any third party sponsor of
          any component of the Services
        </p>
        <h4 className="font-weight-bold mt-3">Proprietary Rights</h4>
        <p className="para mb-5 mt-2">
          Anesthesia One owns all rights to its logos and trademarks used in
          connection with the Services. All other logos and trademarks used in
          connection with the Services are the property of their respective
          owners. CPT is copyrighted and licensed by the American Medical
          Association. CPT is a registered trademark of the American Medical
          Association. You acknowledge and agree that the Services and
          information, content and software presented to you through the
          Services or used in connection with the Services contain proprietary
          and confidential information that is protected under the U.S. and
          international intellectual property laws, including copyright,
          trademarks, service marks, patents or other proprietary rights and
          laws. Except as expressly authorized by us or our licensors, you agree
          not to sell, rewrite, modify, reproduce, distribute (via the Internet
          or other public computer-based information system), redistribute,
          create derivative works (including translating), rent or provide any
          information presented to you through the Services, in whole or in
          part, to an unauthorized party. Further, you are prohibited from
          using, downloading, publishing, republishing, transferring, selling,
          leasing, licensing, duplicating, or "scraping" for commercial or any
          other purpose any database, in whole or in part, in any medium
          whatsoever, underlying any of the Services including, without
          limitation, the Anesthesia One Provider Directory and the Anesthesia
          One Rx drug reference tool. The Services are being provided to you on
          a non-exclusive, non-transferable basis solely for your internal use
          in the U.S. You may view the information provided through the Services
          online or download or print individual articles for backup or archival
          purposes only. All copies of the information provided through the
          Services must include any trademark or copyright notices or
          disclaimers, and you may not remove any trademark or copyright notices
          or disclaimers from our or our licensors' materials. We and our
          licensors reserve all other rights not granted in these Terms of Use.
          You agree not to access the Services by any means other than through
          the interface that is provided by us for use in accessing the
          Services.
        </p>
        <h4 className="font-weight-bold mt-3">Linking to Us</h4>
        <p className="para mb-5 mt-2">
          Usually, we don't mind if you include a simple link from your website
          to our website(s). However, you must first ask our permission if you
          intend to frame our website(s) or incorporate all or a portion of our
          website(s) into a different site or product in such a way that is not
          clear to the users that we are the source of the content. You are not
          allowed to link to us if you engage in the publication or promotion of
          illegal, obscene, or offensive content, or if the link in any way
          negatively impacts our reputation.
        </p>
        <h4 className="font-weight-bold mt-3">
          Passwords and User Restrictions
        </h4>
        <p className="para mb-5 mt-2">
          You may be required to create an Anesthesia One account in order to
          access the Services. By creating an account, you represent and warrant
          that all information that you provide on the registration form is
          current, complete and accurate to the best of your knowledge. You
          agree to maintain and properly update your registration information so
          that it remains current, complete and accurate. During the
          registration process, you may be required to choose a password. You
          acknowledge and agree that Anesthesia One may rely on this password to
          identify you. You are responsible for all use of your Anesthesia One
          account, regardless of whether you authorized such access or use, and
          for ensuring that all use of your account complies fully with the
          provisions of these Terms of Use. You agree to notify Anesthesia One
          immediately of all unauthorized use of your account and if the
          security or secrecy of your account login information has been
          compromised. You may be held responsible for any losses incurred by
          Anesthesia One or any other user of the Service that is in any way
          related to your failure to maintain the security of your account
          information. By using the Services, you represent that you are 18
          years of age or older.
        </p>
        <h4 className="font-weight-bold mt-3">
          Member Conduct when Using Community and Communication Services
        </h4>
        <p className="para mb-5 mt-2">
          If you use a community and communication feature of the Services
          (each, a "Community and Communication Service"), you are responsible
          for all communications, information, data, text, music, sound,
          graphics, messages and other material ("Content") that you upload,
          post, transmit, email or otherwise distribute through a Community and
          Communication Service. Neither we nor our licensors are responsible
          for the consequences of the Content posted by you or any other party
          through a Community and Communication Service, and as such, do not
          guarantee the accuracy, integrity or quality of such Content. You
          understand that by using a Community and Communication Service, you
          may be exposed to Content that is offensive or objectionable. In no
          event will we be liable in any way for any Content or for any loss or
          damage of any kind incurred as a result of the use of any Content
          uploaded, posted, transmitted, emailed or otherwise made available
          through a Community and Communication Service. In cases where you feel
          threatened or believe someone else is in danger, you should contact
          your local law enforcement agency immediately. If you think you may
          have a medical emergency, call your doctor or 911 immediately. When
          you use a Community and Communication Service, you agree not to:
          Violate local, state, national, or international laws; Post, upload,
          email, transmit or otherwise distribute any Content that infringes on
          the intellectual property rights of others or on the privacy or
          publicity rights of others; Post, upload, email, transmit or otherwise
          distribute any Content that is unlawful, harmful, obscene, defamatory,
          threatening, harassing, abusive, slanderous, hateful, or embarrassing
          to any other person or entity as we may determine in our sole
          discretion; Harm minors in any way; Post advertisements or
          solicitations of business; Forge headers or otherwise manipulate
          identifiers in order to disguise the origin of any Content transmitted
          through a Community and Communication Service; Post, upload, email,
          transmit or otherwise distribute chain letters, pyramid schemes,
          unsolicited or unauthorized advertising or spam; Impersonate another
          person or business entity or stalk or otherwise harass another person;
          Post, upload, email, transmit or otherwise distribute viruses or other
          harmful computer code designed to interrupt, destroy or limit the use
          of any computer software or hardware; Harvest or otherwise collect
          information about others, including email addresses; Allow any other
          person or entity to use your identification for posting or viewing
          comments; Interfere with or disrupt a Community and Communication
          Service or computers, networks or other hardware connected to a
          Community and Communication Service, or disregard any requirements or
          policies of networks connected to a Community and Communication
          Service; Engage in any other conduct that restricts or inhibits any
          other person from using or enjoying a Community and Communication
          Service, or which, in our sole judgment, exposes us or our licensors,
          customers or suppliers to any liability or detriment of any type; Fail
          to respect other members' privacy. This includes revealing another
          member's password, phone number, address, or any other personally
          identifiable information; Create member names, or post, solicit or
          send messages, text or photographs that are sexually explicit, that
          denigrate, threaten, abuse or harm others in any way; or We may (but
          are not obligated to) do any or all of the following without notice:
          Record or pre-screen the dialogue through a Community and
          Communication Service; Investigate an allegation that a communication
          does not conform to the terms of this section and determine in our
          sole discretion to remove or request the removal of the Content;
          Remove Content which is abusive, objectionable, illegal, or
          disruptive, or that otherwise fails to conform with these Terms of
          Use; Terminate your access to any or all Community and Communication
          Services upon our determination that you have violated these Terms of
          Use; or Edit Content. You agree that you must evaluate, and bear all
          risks associated with, the use of any Content, including any reliance
          on the accuracy, completeness, or usefulness of such Content. You
          acknowledge, consent and agree that we may investigate your use of a
          Community and Communication Service in order to determine whether a
          violation of the Terms of Use has occurred or to comply with any
          applicable law, regulation, governmental request or legal process. You
          agree and acknowledge that the processing and transmission of a
          Community and Communication Service, including your Content, may
          involve transmissions over various networks and devices and
          modifications may be required for such transmissions.
        </p>
        <h4 className="font-weight-bold mt-3">
          Information that You Provide to the Services
        </h4>
        <p className="para mb-5 mt-2">
          When you submit information to areas of the Services that are publicly
          available, you give us an irrevocable, perpetual license to use,
          reproduce, modify, adapt, publicly perform and publicly display that
          information in connection with the Services. We will consider requests
          to remove information that you make publicly available through the
          Services on an individual basis (contact us at the email address we
          provide at the end of this document). See our Privacy Policy section
          below for an explanation of how we use information that you provide to
          the Services and your rights to change or delete it. We ask that you
          not post any messages with misleading, false, or inappropriate
          language or statements. We reserve the right to remove any Content
          that we deem offensive or fraudulent at any time without your consent,
          as further described below. We cannot and do not assume any
          responsibility or liability for any information you submit in
          connection with the Services, or your or third parties use or misuse
          of information transmitted or received using the Services.
        </p>
        <h4 className="font-weight-bold mt-3">
          General Practices Regarding Use and Storage
        </h4>
        <p className="para mb-5 mt-2">
          You acknowledge that Anesthesia One may establish general practices
          and limits concerning use of the Services, including without
          limitation the maximum number of days that messages, postings or other
          uploaded Content will be retained by the Services, the maximum number
          of messages that may be sent from or received by an account on the
          Services, the maximum size of any message that may be sent from or
          received by an account on the Services, the maximum disk space that
          will be allotted on Anesthesia One' servers on your behalf, and the
          maximum number of times (and the maximum duration for which) you may
          access the Services in a given period of time. You agree that
          Anesthesia One has no responsibility or liability for the deletion or
          failure to store any messages and other communications or other
          Content maintained or transmitted by the Services. You acknowledge
          that Anesthesia One reserves the right to modify these general
          practices and limits from time to time. You expressly agree that
          Anesthesia One may preserve any transmittal or communication by you
          through the Services, or any service offered through the Services, and
          may disclose that information if legally required to do so or if
          Anesthesia One determines that the disclosure is reasonably necessary
          to enforce these Terms of Use or to protect any rights hereunder or to
          respond to claims of wrongdoing by others. Please visit our Privacy
          Policy for our complete policy on what information we collect, how we
          may use that information and when we share it.
        </p>
        <h4 className="font-weight-bold mt-3">Privacy Policy</h4>
        <p className="para mb-5 mt-2">
          When you use our mobile applications, in addition to information
          described in our Privacy Policy, such as your profile and contact
          information, we automatically collect certain information from your
          device including your precise geolocation, information about your
          devices such as the device type, device ID, operating system, wireless
          service provider and information about the operation of our
          application and your usage of our application, including features you
          used, pages you viewed, and when and for how long you used the
          application. By using Anesthesia One mobile applications, you agree
          that Anesthesia One may collect this information and use, transmit,
          process, and store that information as described in Anesthesia One'
          Privacy Policy including to provide and improve our Services.
          Anesthesia One does not make any representations or guaranty about
          whether the Services are adequate to meet your privacy and security
          compliance obligations under HIPAA or any other standards, laws, rules
          or regulations. You are responsible for assessing your privacy and
          security needs and determining whether your use of our Services is
          appropriate to comply with your obligations. You can review our
          Privacy Policy at http://www.anesthesiaone.com/privacy.
        </p>
        <h4 className="font-weight-bold mt-3">Equipment</h4>
        <p className="para mb-5 mt-2">
          When using the Services, information will be transmitted over a medium
          that may be beyond the control of Anesthesia One, our licensors or
          suppliers. Accordingly, neither Anesthesia One, its licensors nor
          suppliers assume liability for or relating to the delay, failure,
          interruption or corruption of any data or other information
          transmitted in connection with your use of the Services. You are
          responsible for obtaining and maintaining all connectivity, computer
          software, hardware and other equipment needed to access the Services
          and all charges related to the same.
        </p>
        <h4 className="font-weight-bold mt-3">
          Laws that Govern this Agreement
        </h4>
        <p className="para mb-5 mt-2">
          We control those components of the Services made available through our
          respective websites from our offices within the State of Pennsylvania
          in the U.S. The Services are to be used only in the U.S. Since the
          laws of each state or country able to access the Services may differ,
          by accessing the Services, you agree that the statutes and laws of the
          State of Pennsylvania, without regard to choice of laws principles,
          will apply to all matters relating to the use of the Services. No
          waiver of any of these Terms of Use shall be deemed a further or
          continuing waiver of such terms or any other term. We make no
          representation that materials made available through the Services are
          appropriate or available for use in other locations, and accessing
          them from territories where their contents are illegal is prohibited.
          If you access the Services from outside the U.S., you are responsible
          for compliance with the laws of your jurisdiction.
        </p>
        <h4 className="font-weight-bold mt-3">U.S. Government Rights</h4>
        <p className="para mb-5 mt-2">
          The Services include CPT which is commercial technical data and/or
          computer databases and/or commercial computer software and/or
          commercial computer software documentation, as applicable, which was
          developed exclusively at private expense by the American Medical
          Association, 515 North State Street, Chicago, Illinois 60654. U.S.
          government rights to use, modify, reproduce, release, perform,
          display, or disclose these technical data and/or computer databases
          and/or computer software and/or computer software documentation are
          subject to the limited rights restrictions of DFARS 252.227-7015(b)(2)
          (November 1995) and/or subject to the restrictions of DFARS
          227.7202-1(a) (June 1995) and DFARS 227.7202-3(a) (June 1995), as
          applicable, for U.S. Department of Defense procurements and the
          limited rights restrictions of FAR 52.227-14 (December 2007) and/or
          subject to the restricted rights provisions of FAR 52.227-14 (December
          2007) and FAR 52.227-19 (December 2007), as applicable, and any
          applicable agency FAR supplements, for non-Department of Defense
          Federal procurements.
        </p>
        <h4 className="font-weight-bold mt-3">Consequences</h4>
        <p className="para mb-5 mt-2">
          We may also take any legal action we think is appropriate. If your
          violation of these Terms of Use causes harm to others, you agree to
          hold us and our licensors and affiliates harmless against any
          liability for that harm. If there is any dispute between us concerning
          these Terms of Use or your use of the Services, you agree to submit
          the dispute to non-binding mediation, followed by binding arbitration.
          Both the mediation and the arbitration will be governed under the
          rules of the American Arbitration Association, and the venue for the
          arbitration will be Pennsylvania.
        </p>
        <h4 className="font-weight-bold mt-3">Termination and Modification</h4>
        <p className="para mb-5 mt-2">
          You agree that we may, under certain circumstances and without prior
          notice, discontinue, temporarily or permanently, the Services (or any
          part thereof) or eliminate your account, any associated email address,
          and remove any information you uploaded or provided to the Services
          with or without notice. Cause for termination shall include, but not
          be limited to, (a) breaches or violations of these Terms of Use or
          other incorporated agreements or guidelines, (b) requests by law
          enforcement or other government agencies, (c) a request by you
          (self-initiated account deletions), (d) discontinuance or material
          modification to the Services (or any part thereof), (e) unexpected
          technical or security issues or problems, (f) extended periods of
          inactivity, and/or (g) your engagement in fraudulent or illegal
          activities. You agree that all terminations for cause shall be made at
          our sole discretion, and neither we nor our licensors shall be liable
          to you or any third party for any termination of your account, any
          associated email address, or access to the Services or any portion
          thereof.
        </p>
        <h4 className="font-weight-bold mt-3">Survival</h4>
        <p className="para mb-5 mt-2">
          The following provisions survive the expiration or termination of
          these Terms of Use for any reason whatsoever: Liability, Member
          Conduct, Proprietary Rights, Indemnity, Laws that Govern this
          Agreement and Consequences.
        </p>
        <h4 className="font-weight-bold mt-3">Liability</h4>
        <p className="para mb-5 mt-2">
          Your use of the Services is at your own risk. The Services and
          information included therein are provided on an "as is" basis. WE AND
          OUR LICENSORS, INCLUDING THE AMERICAN MEDICAL ASSOCIATION, AND
          SUPPLIERS, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIM ALL
          WARRANTIES, EITHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
          INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, ACCURACY, NON-INFRINGEMENT OF THIRD PARTIES' RIGHTS,
          FITNESS FOR PARTICULAR PURPOSE AND THAT THE SERVICES WILL MEET YOUR
          REQUIREMENTS AS AN END USER OF THE SERVICES. NEITHER Anesthesia One
          NOR ITS LICENSORS OR SUPPLIERS WARRANT THAT THE SERVICES WILL BE
          UNINTERRUPTED OR ERROR FREE. Without limiting the foregoing, we, our
          licensors, and our suppliers make no representations or warranties
          about the following: The accuracy, reliability, completeness,
          currentness, or timeliness of the Services or information contained
          therein. The satisfaction of any government regulations requiring
          disclosure of information on prescription drug products or the
          approval or compliance of any software tools available through the
          Services. In no event will we, our licensors, our suppliers, or any
          third parties mentioned on our website(s) be liable for any damages
          (including, without limitation, incidental, consequential and special
          damages, personal injury/wrongful death, lost profits for sequence,
          accuracy or completeness of information included in the Services, or
          damages resulting from lost data or business interruption) resulting
          from the use of, misuse of, inability to use or interpretation of the
          information contained in the Services or information contained
          therein, whether based on warranty, contract, tort, or any other legal
          theory, and whether or not we, our licensors, our suppliers, or any
          third parties mentioned within the Services are advised of the
          possibility of such damages. We, our licensors, our suppliers, or any
          third parties mentioned within the Services shall be liable only to
          the extent of actual damages incurred by you, not to exceed the U.S.
          $100. Notwithstanding the foregoing, the American Medical
          Association's sole responsibility under these Terms of Use is to make
          available to us replacement copies of the CPT in the event the CPT
          data is not intact. We, our licensors, our suppliers, or any third
          parties mentioned within the Services are not liable for any personal
          injury, including death, caused by your use or misuse of the Services
          or any information contained therein. Any claims arising in connection
          with your use of the Services must be brought within one (1) year of
          the date of the event giving rise to such action occurred. Remedies
          under these Terms of Use are exclusive and are limited to those
          expressly provided for in these Terms of Use.
        </p>
        <h4 className="font-weight-bold mt-3">Indemnity</h4>
        <p className="para mb-5 mt-2">
          You agree to defend, indemnify, and hold us and our officers,
          directors, employees, agents, licensors, and suppliers, harmless from
          and against any claims, actions or demands, liabilities and
          settlements including without limitation, reasonable legal and
          accounting fees, resulting from, or alleged to result from, your
          violation of these Terms of Use.
        </p>
        <h4 className="font-weight-bold mt-3">
          Notice and Take Down Procedures and Copyright Agent
        </h4>
        <p className="para mb-5 mt-2">
          If you believe any materials accessible on or from the Services
          infringe your copyright, you may request removal of those materials
          (or access thereto) from the Services by contacting Anesthesia One at
          anesthesiaoneplus@gmail.com and providing the following information:
          Identification of the copyrighted work that you believe to be
          infringed. Please describe the work, and where possible include a copy
          or the location (e.g., URL) of an authorized version of the work.
          Identification of the material that you believe to be infringing and
          its location. Please describe the material, and provide us with its
          URL or any other pertinent information that will allow us to locate
          the material. Your name, address, telephone number and (if available)
          e-mail address. A statement that you have a good faith belief that the
          complained of use of the materials is not authorized by the copyright
          owner, its agent, or the law. A statement that the information that
          you have supplied is accurate, and indicating that "under penalty of
          perjury," you are the copyright owner or are authorized to act on the
          copyright owner's behalf. A signature or the electronic equivalent
          from the copyright holder or authorized representative. In an effort
          to protect the rights of copyright owners, we maintain a policy for
          the termination, in appropriate circumstances, of subscribers and
          account holders who are repeat infringers.
        </p>
        <h4 className="font-weight-bold mt-3">Additional Terms</h4>
        <p className="para mb-5 mt-2">
          Some of the Services implement the Google Maps web mapping service.
          Your use of Google Maps is subject to Google's terms of use, located
          at http://www.google.com/intl/en_us/help/terms_maps.html.
        </p>
        <h4 className="font-weight-bold mt-3">Complete Agreement</h4>
        <p className="para mb-5 mt-2">
          Except as expressly provided in a particular "legal notice" on the
          website, these Terms of Use constitutes the entire agreement between
          you and Anesthesia One with respect to your use (and prior use) of the
          Services. If any provision of these Terms of Use is deemed to be
          unenforceable as written by a court of competent jurisdiction, the
          parties intend that such provision shall be modified to the extent
          necessary to make the provision enforceable. If the provision cannot
          be modified in this manner to make it enforceable, the parties intend
          that the provision shall be removed and the remaining provisions shall
          remain in full force and effect.
        </p>
        <h4 className="font-weight-bold mt-3 text-left">
          Questions or Concerns about Our Terms of Use
        </h4>
        <p className="para mb-5 mt-2">
          For questions or concerns about these terms of use,<br /> Email -
          anesthesiaoneplus@gmail.com <br /> Address - 664 Heiden Drive,
          Hummelstown, Pa, 17036
        </p>
        </div>
        <div className="container text-center">
          <button className="btn btn-secondary" onClick={agree} style={{width:90}}>Agree</button>&nbsp;
          <button className="btn btn-secondary" onClick={disagree} style={{width:90}}>Disagree</button>
        </div>
        <Footer/>
        </div>
    )
}

export default PrivacyPolicy