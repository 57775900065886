import React, { useEffect, useState } from 'react';
import './CAS1032.css';

const CAS1032 = () => {
    const [green, setGreen] = useState(false);
    const [serum, setSerum] = useState([]);
    const [placehldr2, setPlacehldr2] = useState('Norm: 35-55');
    const [tunit2, setTunit2] = useState('g/L');
    const [tunit1, setTunit1] = useState('kg');
    const [pweight, setPweight] = useState([]);
    const [tunit3, setTunit3] = useState('kg');
    const [pweight2, setPweight2] = useState([]);
    const [nweserum, setNewserum] = useState(0);
    const [newPweight, setNewPweight] = useState(0);
    const [newPweight2, setNewPweight2] = useState(0);


    const [result, setResult] = useState(0);

    const postData = async () => {
        if (tunit2 === 'g/L') {
            if (serum) {
                setNewserum(parseFloat(serum).toFixed(1));

            }
            else {
                setNewserum(0);
            }
        }
        if (tunit2 === 'g/dL') {
            if (serum) {

                setNewserum(parseFloat(serum * 10).toFixed(2));
            }
            else {
                setNewserum(0);
            }
        }
        if (tunit1 === 'kg') {
            if (pweight) {

                setNewPweight(parseFloat(pweight).toFixed(2));
            }
            else {
                setNewPweight(0);
            }
        }
        if (tunit1 === 'lbs') {
            if (pweight) {

                setNewPweight(parseFloat(pweight / 2.2046226218488).toFixed(2));
            }
            else {
                setNewPweight(0);
            }
        }
        if (tunit3 === 'kg') {
            if (pweight2) {

                setNewPweight2(parseFloat(pweight2).toFixed(2));
            }
            else {
                setNewPweight2(0);
            }
        }
        if (tunit3 === 'lbs') {
            if (pweight2) {

                setNewPweight2(parseFloat(pweight2 / 2.2046226218488).toFixed(2));
            }
            else {
                setNewPweight2(0);
            }
        }


       if((newPweight<600) && (nweserum<70) && (newPweight2<600)){
        if (newPweight != 0 && newPweight2 != 0 && nweserum != 0) {
            setResult((parseFloat((1.519 * nweserum) + (41.7 * (newPweight / newPweight2)))).toFixed(1))
        }}
        else {
            setResult(0);

        }
    
        setGreen(true);

    }
    useEffect(() => {
        postData();

    }, [serum, pweight, pweight2, newPweight, newPweight2, nweserum])


    const validation2 = () => {

        if (tunit2 === 'g/L') {

            if ((serum < 10) && (serum > 1)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if ((serum < 71) && (serum > 61)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (serum > 70) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(serum)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
        if (tunit2 === 'g/dL') {

            if ((serum == 7)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (serum >= 8) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(serum)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }

        }
    }




    const changeunit2 = () => {
        if (serum === 0) {
            if (tunit2 === 'g/L') {
                setTunit2('g/dL');

            }
            if (tunit2 === 'g/dL') {
                setTunit2('g/L');

            }
        } else if (serum !== 0) {
            if (tunit2 === 'g/L') {
                setTunit2('g/dL');
                setPlacehldr2("Norm: 3.5- 5.5");
                if (serum.length !== 0) {

                    setSerum((serum / 10).toFixed(2));
                }

            }
            if (tunit2 === 'g/dL') {
                setTunit2('g/L');
                setPlacehldr2("Norm: 35- 55");
                if (serum.length !== 0) {

                    setSerum((serum * 10).toFixed(2));
                }

            }
        }
    }
    const takeUnit2 = (e) => {
        if (tunit2 === 'g/L') {
            setSerum(e.target.value);


        }
        if (tunit2 === 'g/dL') {

            setSerum(e.target.value);

        }
    }

    const valnumber1 = () => {


        if (tunit1 === 'kg') {

            if ((pweight < 273) && (pweight > 226)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (pweight > 272) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(pweight)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
        if (tunit1 === 'lbs') {
            if ((pweight < 601) && (pweight > 500)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (pweight > 600) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(pweight)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    
    const changeunit1 = () => {
        if (pweight === 0) {
            if (tunit1 === 'kg') {
                setTunit1('lbs');

            }
            if (tunit1 === 'lbs') {
                setTunit1('kg');

            }
        } else if (pweight !== 0) {
            if (tunit1 === 'kg') {
                setTunit1('lbs');
                if (pweight.length !== 0) {
                    setPweight((pweight * 2.2046226218488).toFixed(2));
                }
            }
            if (tunit1 === 'lbs') {
                setTunit1('kg');
                if (pweight.length !== 0) {

                    setPweight((pweight / 2.2046226218488).toFixed(2));
                }
            }
        }
    }
    const takeUnit1 = (e) => {
        if (tunit1 === 'kg') {
            setPweight(e.target.value);
        }
        if (tunit1 === 'lbs') {
            let ht = e.target.value;
            setPweight(ht);
        }
    }
    const changeunit3 = () => {
        if (pweight2 === 0) {
            if (tunit3 === 'kg') {
                setTunit3('lbs');

            }
            if (tunit3 === 'lbs') {
                setTunit3('kg');

            }
        } else if (pweight2 !== 0) {
            if (tunit3 === 'kg') {
                setTunit3('lbs');
                if (pweight2.length !== 0) {
                    setPweight2((pweight2 * 2.2046226218488).toFixed(2));
                }
            }
            if (tunit3 === 'lbs') {
                setTunit3('kg');
                if (pweight2.length !== 0) {

                    setPweight2((pweight2 / 2.2046226218488).toFixed(2));
                }
            }
        }
    }
    const takeUnit3 = (e) => {
        if (tunit3 === 'kg') {
            setPweight2(e.target.value);
        }
        if (tunit3 === 'lbs') {
            let ht = e.target.value;
            setPweight2(ht);
        }
    }
    const valnumber3 = () => {


        if (tunit3 === 'kg') {

            if ((pweight2 < 273) && (pweight2 > 226)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (pweight2 > 272) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(pweight2)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
        if (tunit3 === 'lbs') {
            if ((pweight2 < 601) && (pweight2 > 500)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (pweight2 > 600) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(pweight2)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }

    const result1 = () => {
        if (result == 0) {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>
            )
        }

        if (result < 83.5) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>NRI Score</div>
                </div>
                <div className="point-result-container" >
                    <h1>Sever <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span> </h1>
                    <div>Risk of malnutrition</div>
                </div>
            </div>
        }
        if ((result >= 83.5) && (result <= 97.5)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>NRI Score</div>
                </div>
                <div className="point-result-container" >
                    <h1>Moderate  <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span> </h1>
                    <div>Risk of malnutrition</div>
                </div>
            </div>
        }
        if ((result >= 97.6) && (result <= 100.0)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>NRI Score</div>
                </div>
                <div className="point-result-container" >
                    <h1>Mild  <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
                    <div>Risk of malnutrition</div>
                </div>
            </div>
        }
        if ((result >= 101.0)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>NRI Score</div>
                </div>
                <div className="point-result-container" >
                    <h1>No risk</h1>
                    <div>Risk of malnutrition</div>
                </div>
            </div>
        }
        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }
    return (
        <div className="rev">
            <h5 className="text-info pl-2">Nutritional Risk Index (NRI)</h5>
            <p className="pl-2">Quantifies malnutrition, validated in many populations.</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Serum albumin</strong></div>
                </div>
                <dii className="col-md-7 frm-field">
                    <input type="number"
                        onChange={takeUnit2}
                        placeholder={placehldr2}
                        className="ant-text-input" style={{ width: "77%" }} value={serum} /><span className="units" onClick={changeunit2}>{tunit2}&#8651;</span>
                    <div>{validation2()}</div>
                </dii>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Present weight</strong></div>
                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        onChange={takeUnit1}
                        className="ant-text-input" style={{ width: "77%" }} value={pweight} /><span className="units" onClick={changeunit1}>{tunit1}&#8651;</span>
                         <div>{valnumber1()}</div>
                </div>

            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Usual weight</strong></div>
                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        onChange={takeUnit3}
                        className="ant-text-input" style={{ width: "77%" }} value={pweight2} /><span className="units" onClick={changeunit3}>{tunit3}&#8651;</span>
                         <div>{valnumber3()}</div>
                </div>
            </div>
            <div className="stick pt-2">
                {
                    result1()
                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/3124597/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >Buzby GP, Williford WO, Peterson OL, et al. A randomized clinical trial of total parenteral nutrition in malnourished surgical patients: the rationale and impact of previous clinical trials and pilot study on protocol design. Am J Clin Nutr. 1988;47(2 Suppl):357-65.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default CAS1032
