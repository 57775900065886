import React, { useEffect, useState } from "react";
import "./CAS1041.css";

const CAS1041 = () => {
  const [green, setGreen] = useState(false);
  const [from, setFrom] = useState("");
  const [newfrom, setnewFrom] = useState(0);
  const [to, setTo] = useState("");
  const [newto, setNewto] = useState(0);

  const [drugDose, setDrugDose] = useState(0);

  const [result, setResult] = useState(0);

  const postData = async () => {
    // if (to === "Betamethasone" || to === "Dexamethasone") {
    //     setNewto(0.75);
    // }
    // if (to === "Cortisone") {
    //     setNewto(25);
    // }
    // if (to === "Hydrocortisone") {
    //     setNewto(20);
    // }

    // if (to === "MethylPrednisoLONE" || to === "Triamcinolone") {
    //     setNewto(4);
    // }
    // if (to === "PrednisoLONE" || to === "PredniSONE") {
    //     setNewto(5);
    // }
    if (to === "Betamethasone" || to === "Dexamethasone") {
      setNewto(0.75);
    }
    if (to === "Cortisone") {
      setNewto(25);
    }
    if (to === "Hydrocortisone") {
      setNewto(20);
    }

    if (to === "MethylPrednisoLONE" || to === "Triamcinolone") {
      setNewto(4);
    }
    if (to === "PrednisoLONE" || to === "PredniSONE") {
      setNewto(5);
    }

    if (from === "Betamethasone" || from === "Dexamethasone") {
      setnewFrom(0.75);
    }
    if (from === "Cortisone") {
      setnewFrom(25);
    }
    if (from === "Hydrocortisone") {
      setnewFrom(20);
    }

    if (from === "MethylPrednisoLONE" || from === "Triamcinolone") {
      setnewFrom(4);
    }
    if (from === "PrednisoLONE" || from === "PredniSONE") {
      setnewFrom(5);
    }

    setResult(((drugDose * newto) / newfrom).toFixed(1));

    setGreen(true);
  };
  useEffect(() => {
    postData();
  }, [from, to, newto, drugDose, newfrom, result]);

  const resultfields = () => {
    if (from === to) {
      return (
        <div>
          <h1>N/A</h1>
          <div>
            Conversion not applicable if converting between the same steroid
          </div>
        </div>
      );
    } else {
      if (
        (from == "Betamethasone" ||
          from === "Cortisone" ||
          from === "Dexamethasone" ||
          from === "Hydrocortisone" ||
          to == "Betamethasone" ||
          to === "Cortisone" ||
          to === "Dexamethasone" ||
          to === "Hydrocortisone") &&
        from != to
      ) {
        return (
          <div>
            <h1>
              {result}{" "}
              <span
                style={{ fontWeight: 600, fontSize: "1rem", color: "silver" }}
              >
                mg
              </span>
            </h1>
            <div>Approximate {to} Equivalence to:</div>
            <div>
              {drugDose}mg of {from}
            </div>
            <div>
              You are converting to a drug with a shorter half-life, consider
              increasing dosing frequency.
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <h1>
              {result}{" "}
              <span
                style={{ fontWeight: 600, fontSize: "1rem", color: "silver" }}
              >
                mg
              </span>
            </h1>
            <div>Approximate {to} Equivalence to:</div>
            <div>
              {drugDose}mg of {from}
            </div>
          </div>
        );
      }
    }
  };

  const validation1 = () => {
    if (drugDose < 100001 && drugDose > 1000) {
      return <div className="validate">*Very High;double check.</div>;
    }
    if (drugDose > 100000) {
      return (
        <div className="validate">*Too high; please change to proceed.</div>
      );
    } else if (isNaN(drugDose)) {
      return <div className="validate">*Please Enter valid-no.</div>;
    }
  };
  return (
    <div className="rev">
      <h5 className="text-info pl-2">Steroid Conversion Calculator</h5>
      <p className="pl-2">
        Converts steroid dosages using dosing equivalencies.
      </p>
      <div className="fields row">
        <div className="col-md-5">
          <div className="pt-2">
            <strong>Converting From:</strong>
          </div>
        </div>
        <div className="col-md-7 btn-group-vertical options-btn mb-2">
          <button
            onClick={() => setFrom("Betamethasone")}
            className={
              from === "Betamethasone"
                ? "btn  text-left active"
                : "btn  text-left"
            }
          >
            <span className="btn-name ">Betamethasone (IV)</span>
          </button>
          <button
            onClick={() => setFrom("Cortisone")}
            className={
              from === "Cortisone" ? "btn  text-left active" : "btn  text-left"
            }
          >
            <span className="btn-name ">Cortisone (PO)</span>
          </button>
          <button
            onClick={() => setFrom("Dexamethasone")}
            className={
              from === "Dexamethasone"
                ? "btn  text-left active"
                : "btn  text-left"
            }
          >
            <span className="btn-name ">Dexamethasone (IV or PO)</span>
          </button>
          <button
            onClick={() => setFrom("Hydrocortisone")}
            className={
              from === "Hydrocortisone"
                ? "btn  text-left active"
                : "btn  text-left"
            }
          >
            <span className="btn-name ">Hydrocortisone (IV or PO)</span>
          </button>
          <button
            onClick={() => setFrom("MethylPrednisoLONE")}
            className={
              from === "MethylPrednisoLONE"
                ? "btn  text-left active"
                : "btn  text-left"
            }
          >
            <span className="btn-name ">MethylPrednisoLONE (IV or PO)</span>
          </button>
          <button
            onClick={() => setFrom("PrednisoLONE")}
            className={
              from === "PrednisoLONE"
                ? "btn  text-left active"
                : "btn  text-left"
            }
          >
            <span className="btn-name ">PrednisoLONE (PO)</span>
          </button>
          <button
            onClick={() => setFrom("PredniSONE")}
            className={
              from === "PredniSONE" ? "btn  text-left active" : "btn  text-left"
            }
          >
            <span className="btn-name ">PredniSONE (PO)</span>
          </button>
          <button
            onClick={() => setFrom("Triamcinolone")}
            className={
              from === "Triamcinolone"
                ? "btn  text-left active"
                : "btn  text-left"
            }
          >
            <span className="btn-name ">Triamcinolone (IV)</span>
          </button>
        </div>
      </div>
      <div className="fields row">
        <div className="col-md-5">
          <div className="pt-2">
            <strong>Drug Dosage in mg</strong>
          </div>
        </div>
        <div className="col-md-7 frm-field">
          <input type="number"
            style={{ width: "90%" }}
            className="ant-text-input "
            onChange={(e) => setDrugDose(e.target.value)}
          />
          <span className="units ">mg</span>
          <div>{validation1()}</div>
        </div>
      </div>
      <div className="fields row">
        <div className="col-md-5">
          <div className="pt-2">
            <strong>Converting To:</strong>
          </div>
        </div>
        <div className="col-md-7 btn-group-vertical options-btn mb-2 ">
          <button
            onClick={() => setTo("Betamethasone")}
            className={
              to === "Betamethasone"
                ? "btn  text-left active"
                : "btn  text-left"
            }
          >
            <span className="btn-name ">Betamethasone (IV)</span>
          </button>
          <button
            onClick={() => setTo("Cortisone")}
            className={
              to === "Cortisone" ? "btn  text-left active" : "btn  text-left"
            }
          >
            <span className="btn-name ">Cortisone (PO)</span>
          </button>
          <button
            onClick={() => setTo("Dexamethasone")}
            className={
              to === "Dexamethasone"
                ? "btn  text-left active"
                : "btn  text-left"
            }
          >
            <span className="btn-name ">Dexamethasone (IV or PO)</span>
          </button>
          <button
            onClick={() => setTo("Hydrocortisone")}
            className={
              to === "Hydrocortisone"
                ? "btn  text-left active"
                : "btn  text-left"
            }
          >
            <span className="btn-name ">Hydrocortisone (IV or PO)</span>
          </button>
          <button
            onClick={() => setTo("MethylPrednisoLONE")}
            className={
              to === "MethylPrednisoLONE"
                ? "btn  text-left active"
                : "btn  text-left"
            }
          >
            <span className="btn-name ">MethylPrednisoLONE (IV or PO)</span>
          </button>
          <button
            onClick={() => setTo("PrednisoLONE")}
            className={
              to === "PrednisoLONE" ? "btn  text-left active" : "btn  text-left"
            }
          >
            <span className="btn-name ">PrednisoLONE (IV or PO)</span>
          </button>
          <button
            onClick={() => setTo("PredniSONE")}
            className={
              to === "PredniSONE" ? "btn  text-left active" : "btn  text-left"
            }
          >
            <span className="btn-name ">PredniSONE (PO)</span>
          </button>
          <button
            onClick={() => setTo("Triamcinolone")}
            className={
              to === "Triamcinolone"
                ? "btn  text-left active"
                : "btn  text-left"
            }
          >
            <span className="btn-name ">Triamcinolone (IV)</span>
          </button>
        </div>
      </div>

      <div className="stick">
        {to != 0 && drugDose != 0 && drugDose < 100001 && from != 0 ? (
          <>
            <div className="apfel-score-result-container col-12">
              <div className="point-result-container col-12">
                {resultfields()}
              </div>
            </div>
          </>
        ) : (
          <div className="text-white p-2 green-bg">
            <h1>Result:</h1>
            <br />
            <h6>Please fill out required fields.</h6>
          </div>
        )}
      </div>
      <div className="p-2">
        <h6>ORIGINAL/PRIMARY REFERENCE</h6>
        <a
          className="referenceLink"
          target="_blank"
          href="https://pubmed.ncbi.nlm.nih.gov/888843/"
        >
          <div className="reference-container-card">
            <div className="reference-img">
              <img
                src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png"
                alt="Research Paper"
              />
            </div>
            <p className="reference-paragraph">
              Meikle AW and Tyler FH. Potency and duration of action of
              glucocorticoids. Am J of Med 1977;63;200.(456).
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default CAS1041;
