import React, { useState, useEffect, useMemo, useContext } from 'react'
import { API_ROOT } from '../../../constants'
import Spinner from '../../../CustomComponent/Spinner';
import Alert from '../../../CustomComponent/Alert';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import '../../../css/index.css';
import FontAwesome from 'react-fontawesome';
import { Link, useHistory } from 'react-router-dom';
import './CaseLogs.css'
import Recent from '../../../Screen/Recent';

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <input
            id="search"
            type="text"
            style={{ height: 40, fontSize: 12, padding: '0px 10px', borderRadius: "5px 0px 0px 5px", border: '1px solid #808080' }}
            placeholder="Filter by case name"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <button style={{ height: 40, width: 40, border: '1px solid #0069D9', backgroundColor: '#44948B', color: '#fff', borderRadius: "0px 5px 5px 0px" }} type="button" onClick={onClear}>
            <i class="fa fa-search" aria-hidden="true"></i>
        </button>
    </>
);


const CaseLogs = (props) => {
    const [caseSummaryList, setcaseSummaryList] = useState([])
    const [loading, setLoading] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [sharedData, setSharedData] = useState([]);
    const [prAll, setPrAll] = useState([]);
    const [others, setOthers] = useState([]);
    const window_width = window.innerWidth;
    const window_height = window.innerHeight;
    let history = useHistory();
    let token = sessionStorage.getItem("token");
    let caseArray = [];
    let caselists = new Array();
    let otherInfo = new Array();

    const columns = [
        {
            name: 'Case ID',
            selector: (row) => row.id,
        },
        {
            name: 'Case Name',
            selector: (row) => row.name,
        },
        {
            name: 'Date',
            selector: (row) => row.created_at,
            format: (row) => moment(row.created_at).format('lll'),
        },
        {
            name: 'Actions',
            cell: (row) => <>
                {/* share clicked temporary set to false because we have to disable it  */}
                <i className='fa fa-share mx-1 text-primary' style={{ cursor: 'pointer' }} onClick={() => { history.push(`/export/${row.id}`) }}></i>
                <i className='fa fa-print mx-1 text-info' style={{ cursor: 'pointer', color: '#46948B' }} onClick={() => history.push(`/case-logs/preview/${row.id}`)}></i>
                {/* <i className="fa fa-light fa-eye mx-1" style={{ cursor: 'pointer' }} onClick={() => history.push(`/export/${row.id}`)}></i> */}
                {row.publish === "true" ? <></> : <i className='fa fa-pencil mx-1' style={{ cursor: 'pointer', color: '#3F2579' }} onClick={() => history.push(`/case-logs/edit/${row.id}`)}></i>}
                <i onClick={() => deleteCaseSummaryAsPerId(row.id)} className='fa fa-trash mx-1' style={{ cursor: 'pointer', color: 'orangered' }}></i>
            </>
        }
    ];


    useEffect(() => {
        if (sessionStorage.getItem("token") === null) {
            setLoading(false);
            setAlertVisible(true);
            setMessage('User session expired');
        }
        getCaseSummaryList();
        getAllSummary();

    }, [])
    const getAllSummary = () => {
        caseArray.forEach(mp => {
            fetch(API_ROOT + `case-summary-by-id/${mp}?token=${token}`)
                .then(response => response.json())
                .then(res => {
                    otherInfo.push(res)
                    caselists.push(res.data);

                })
        })
        setPrAll(caselists);
        setOthers(otherInfo)
    }
    const closeAlert = () => {
        setAlertVisible(false);
        props.history.push({
            pathname: '/login'
        })
    }
    const getCaseSummaryList = () => {

        fetch(API_ROOT + `user-case-summaries?token=${token}`)
            .then(response => response.json())
            .then(res => {
                if (res.status === "true" && res.message === "User Case Summarries") {
                    res.data.forEach(dt => {
                        caseArray.push(dt.id)
                    });
                    setcaseSummaryList(res.data);

                    setLoading(true);

                }
            }).then(() => {
                if (prAll.length === 0) {
                    getAllSummary();
                }
            }

            )

    }

    const deleteCaseSummaryAsPerId = (casesummaryId) => {
        fetch(API_ROOT + `case-summary/${casesummaryId}?token=${token}`, {
            method: 'DELETE',
        })
            .then(response => response.json())
            .then(res => {
                alert('Case has been deleted')
                window.location.reload()
            })
    }

    const formatedDate = (dated) => {
        var strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var inputDate = new Date(dated);
        var d = inputDate.getDate();
        var m = strArray[inputDate.getMonth()];
        var y = inputDate.getFullYear();
        return '' + (d <= 9 ? '0' + d : d) + '-' + m + '-' + y;
    }
    const shareIt = () => {
        alert('The ability to share cases with other anesthesia one members and print cases is coming soon');
    }

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = caseSummaryList.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
    );
    const printAllPage = () => {
        console.log(prAll)


        // "Sorry this feature is yet to activate !"

        if (prAll.length != 0) {
            history.push({ pathname: 'case-logs/detailed', state: { rowData: prAll, otherInfo: others } });
        }

    }
    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (<>{caseSummaryList.length > 0 ? <>
            <div className="p-1"><button className="btn-primary" style={{ background: "#44948B", border: "1px solid #44948B" }} onClick={() => { printAllPage() }}>View/Print All</button></div>

            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        </> : <></>}

        </>
        );
    }, [filterText, resetPaginationToggle, caseSummaryList]);


    const handleClearMoble = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
    };

    const goToStartCase = () => {
        if (caseSummaryList.length >= 3) return alert("You have crossed free subscription limit!")
        props.history.push("/start-a-case")
    }

    return (
        <div>
            <div className="container">

                <div className="col-md-12 pl-0 pr-2">

                    <div className="conArea d-block">
                        {window_width >= 360 && window_width <= 428 ?
                            <div className="savedCases">
                                <h5>My Case Logs</h5>
                                <table clasName="table caselogMob" style={{ marginBottom: 20, width: '100%' }}>
                                    <tr>
                                        <td colSpan={3}><button className="btn-primary" onClick={() => { printAllPage() }} style={{ background: "#44948B", border: "1px solid #44948B" }}>View/Print All</button></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '65%' }}>
                                            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClearMoble} filterText={filterText} />
                                        </td>
                                        <td style={{ width: '35%' }}><button onClick={goToStartCase} className='btn btn-sm text-white pt-2 start-btn' >
                                            <FontAwesome
                                                className="super-crazy-colors"
                                                name="plus"
                                                size="1x"
                                                // spin
                                                style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
                                            /> START A CASE</button></td>
                                    </tr>
                                </table>
                                {filteredItems.length > 0 ? <>

                                    <ul className="savdcard mt-1">
                                        {loading ?

                                            (filteredItems).map((data) => {
                                                return (
                                                    <li  >
                                                        <div onClick={() => history.push(`/case-logs/view/${data.id}`)}
                                                            className="cszeDetail">
                                                            <div className="caption"><strong>Case ID:{data.id}</strong></div>
                                                            <div className="caption"><strong>{data.name}</strong></div>
                                                            <div className="date mt-1 mb-2">{formatedDate(data.created_at)}</div>
                                                        </div>

                                                        <div className="btn-group" style={{ width: '100%' }}>
                                                            <button className="btn action-btn" onClick={() => { history.push(`/export/${data.id}`) }} style={{ width: '50px', cursor: 'pointer' }}><i className="fa fa-share mx-1 text-primary"></i></button>
                                                            <button className="btn action-btn" onClick={() => history.push(`/case-logs/preview/${data.id}`)} style={{ width: '50px' }}> <i className="fa fa-print mx-1" style={{ color: '#46948B' }}></i></button>
                                                            {/* <button className="btn action-btn" onClick={() => history.push(`/case-logs/view/${data.id}`)} style={{ width: '50px' }}><i className="fa fa-light fa-eye mx-1"></i></button> */}
                                                            {data.publish === 'true' ? <></> : <button className="btn action-btn" onClick={() => history.push(`/case-logs/edit/${data.id}`)} style={{ width: '50px' }}><i className='fa fa-pencil mx-1' style={{ color: '#3F2579' }}></i></button>}
                                                            <button className='btn action-btn' onClick={() => deleteCaseSummaryAsPerId(data.id)} style={{ width: '50px' }}><i className='fa fa-trash mx-1' style={{ cursor: 'pointer', color: 'orangered' }}></i>
                                                            </button>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </li>

                                                )
                                            })
                                            : <Spinner />
                                        }
                                    </ul>
                                </> : <> <div style={{ textAlign: 'center', padding: 10, backgroundColor: '#F1F1F1' }}>No Cases added</div></>
                                }
                            </div>
                            : <> {loading ? <DataTable
                                title="MY CASE LOGS"
                                data={filteredItems}
                                columns={columns}
                                defaultSortField="created_at"
                                paginationResetDefaultPage={resetPaginationToggle}
                                striped={true}
                                pagination
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                                noDataComponent={"No records found!"}
                                responsive={true}
                                actions={
                                    <button onClick={goToStartCase} className='btn btn-sm text-white text-right pt-2 start-btn' >
                                        <FontAwesome
                                            className="super-crazy-colors"
                                            name="plus"
                                            size="1x"
                                            // spin
                                            style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
                                        /> START A CASE</button>}

                            /> : <Spinner />
                            }
                            </>
                        }
                    </div>
                </div>
            </div>
            <Alert isOpen={alertVisible} message={message} label="Go to Login" closeAlert={() => { closeAlert() }} />
        </div>

    )
}

export default CaseLogs
