import React, { useEffect, useState } from 'react';
import './RE1234.css';

const RE1234 = () => {
    const [green,setGreen]= useState(0);
const[age,setAge]= useState(0);
const[apache,setApache]= useState(0);
const[sofa,setSofa]= useState(0);
const[comorbidities,setComorbidities]= useState(0);
const[icu, setIcu] = useState(0);
const[il,setIl] = useState(0);
const[result,setResult]= useState(0);
const postData = async () => {
    setResult(age+apache+ sofa+comorbidities+icu+il);
    setGreen(0);
}
useEffect(() => {
    postData();

}, [age,apache,sofa,comorbidities,icu,il,]);



const result1 = () => {
     
    if (result == 0) {
        return <div className="apfel-score-result-container" >
            <div className="point-result-container" >
                <h1>{1} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                <div>NUTRIC Score</div>
            </div>
            <div className="point-result-container" >
                <h1>Low <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
                <div>~1% mortality at 28 days</div>
                <div>Consider aggressive nutrition therapy (enteral or parenteral).</div>
            </div>
        </div>
    }
    if (result == 1) {
        return <div className="apfel-score-result-container" >
        <div className="point-result-container" >
            <h1>{result} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>points</span></h1>
            <div>NUTRIC Score</div>
        </div>
        <div className="point-result-container" >
            <h1>Low <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
            <div>~2% mortality at 28 days</div>
            <div>Consider aggressive nutrition therapy (enteral or parenteral).</div>
        </div>
    </div>
    }
    if (result == 2) {
        return <div className="apfel-score-result-container" >
        <div className="point-result-container" >
            <h1>{result} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>points</span></h1>
            <div>NUTRIC Score</div>
        </div>
        <div className="point-result-container" >
            <h1>Low <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
            <div>~3% mortality at 28 days</div>
            <div>Consider aggressive nutrition therapy (enteral or parenteral).</div>
        </div>
    </div>
    }
    if (result == 3) {
        return <div className="apfel-score-result-container" >
        <div className="point-result-container" >
            <h1>{result} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>points</span></h1>
            <div>NUTRIC Score</div>
        </div>
        <div className="point-result-container" >
            <h1>Low <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
            <div>~8% mortality at 28 days</div>
            <div>Consider aggressive nutrition therapy (enteral or parenteral).</div>
        </div>
    </div>
    }
    if (result == 4) {
        return <div className="apfel-score-result-container" >
        <div className="point-result-container" >
            <h1>{result} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>points</span></h1>
            <div>NUTRIC Score</div>
        </div>
        <div className="point-result-container" >
            <h1>Low <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
            <div>~11% mortality at 28 days</div>
            <div>Consider aggressive nutrition therapy (enteral or parenteral).</div>
        </div>
    </div>
    }
    if (result == 5) {
        return <div className="apfel-score-result-container" >
        <div className="point-result-container" >
            <h1>{result} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>points</span></h1>
            <div>NUTRIC Score</div>
        </div>
        <div className="point-result-container" >
            <h1>Low <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
            <div>~20% mortality at 28 days</div>
            <div>Consider aggressive nutrition therapy (enteral or parenteral).</div>
        </div>
    </div>
    }
    if (result == 6) {
        return <div className="apfel-score-result-container" >
        <div className="point-result-container" >
            <h1>{result} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>points</span></h1>
            <div>NUTRIC Score</div>
        </div>
        <div className="point-result-container" >
            <h1>High <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
            <div>~30% mortality at 28 days</div>
            <div>Consider aggressive nutrition therapy (enteral or parenteral).</div>
        </div>
    </div>
    }
    if (result == 7) {
        return <div className="apfel-score-result-container" >
        <div className="point-result-container" >
            <h1>{result} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>points</span></h1>
            <div>NUTRIC Score</div>
        </div>
        <div className="point-result-container" >
            <h1>High <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
            <div>~45% mortality at 28 days</div>
            <div>Consider aggressive nutrition therapy (enteral or parenteral).</div>
        </div>
    </div>
    }
    if (result == 8) {
        return <div className="apfel-score-result-container" >
        <div className="point-result-container" >
            <h1>{result} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>points</span></h1>
            <div>NUTRIC Score</div>
        </div>
        <div className="point-result-container" >
            <h1>High<span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
            <div>~58% mortality at 28 days</div>
            <div>Consider aggressive nutrition therapy (enteral or parenteral).</div>
        </div>
    </div>
    }
    if (result == 9) {
        return <div className="apfel-score-result-container" >
        <div className="point-result-container" >
            <h1>{result} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>points</span></h1>
            <div>NUTRIC Score</div>
        </div>
        <div className="point-result-container" >
            <h1>High <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
            <div>~70% mortality at 28 days</div>
            <div>Consider aggressive nutrition therapy (enteral or parenteral).</div>
        </div>
    </div>
    }
    if (result == 10) {
        return <div className="apfel-score-result-container" >
        <div className="point-result-container" >
            <h1>{result} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>points</span></h1>
            <div>NUTRIC Score</div>
        </div>
        <div className="point-result-container" >
            <h1>High <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
            <div>~80% mortality at 28 days</div>
            <div>Consider aggressive nutrition therapy (enteral or parenteral).</div>
        </div>
    </div>
    }
else {
    return (
        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
            <h6>Please fill out required fields.</h6></div>

    )
}

}
    return (
        <div className="rev">
             <h5 className="text-info pl-2">Nutrition Risk in the Critically Ill (NUTRIC) Score</h5>
            <p className="pl-2">Identifies critically ill patients who can benefit from nutrition therapy, recommended by ACG guidelines..</p>
              <div className="fields row">
                <div className="col-md-5">
                    <div ><strong>Age, years</strong></div>
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={age === 0 ? "btn active" : "btn"} onClick={() => setAge(0)}>&lt;50%<span className="ml-4 text-muted">0</span></button>
                <button style={{ height:  40   }} key="2" className={age === 1 ? "btn active" : "btn"} onClick={() => setAge(1)}>50-74%<span className="ml-4 text-muted">+1</span></button>
                    <button style={{ height:  40   }} key="2" className={age === 2 ? "btn active" : "btn"} onClick={() => setAge(2)}>&gt;75% <span className="ml-4 text-muted">+2</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>APACHE II</strong></div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  40   }} onClick={() => setApache(0)} className={ apache === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name small">&lt;15</span><span className="calc-val small">0</span></button>
                    <button style={{ height:  40   }} onClick={() => setApache(1)} className={apache=== 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name small">15-19</span><span className="calc-val small">+1</span></button>
                    <button  style={{ height:  40   }} onClick={() => setApache(2)} className={apache === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name small">20-27</span><span className="calc-val small">+2</span></button>
                    <button style={{ height:  40   }} onClick={() => setApache(3)} className={apache === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name small">≥28</span><span className="calc-val small">+3</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>SOFA Score</strong></div>
                    <div>Initial, on presentation.</div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={sofa === 0 ? "btn active" : "btn"} onClick={() => setSofa(0)}> &lt;6 <span className="ml-4 text-muted">0</span></button>
                <button style={{ height:  40   }} key="2" className={sofa === 1 ? "btn active" : "btn"} onClick={() => setSofa(1)}>6-9<span className="ml-4 text-muted">+1</span></button>
                    <button style={{ height:  40   }} key="2" className={sofa=== 2 ? "btn active" : "btn"} onClick={() => setSofa(2)}>≥10<span className="ml-4 text-muted">+2</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Number of comorbidities</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }}key="1" className={comorbidities === 0 ? "btn active" : "btn"} onClick={() => setComorbidities(0)}>0-1<span className="ml-4 text-muted">0</span></button>
                <button style={{ height:  40   }} key="2" className={comorbidities === 1 ? "btn active" : "btn"} onClick={() => setComorbidities(1)}>≥2<span className="ml-4 text-muted">+1</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Days in hospital to ICU admit</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={icu === 0 ? "btn active" : "btn"} onClick={() => setIcu(0)}>0<span className="ml-4 text-muted">0</span></button>
                <button style={{ height:  40   }} key="2" className={icu === 1 ? "btn active" : "btn"} onClick={() => setIcu(1)}>≥1<span className="ml-4 text-muted">+1</span></button>
                </div>
            </div>
            <div className="fields row mb-5">
                <div className="col-md-6">
                    <div ><strong>IL-6, µ/mL</strong></div>
                    <div>Optional</div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={il === 0 ? "btn active" : "btn"} onClick={() => setIl(0)}>0–399<span className="ml-4 text-muted">0</span></button>
                <button style={{ height:  40   }} key="2" className={il === 1 ? "btn active" : "btn"} onClick={() => setIl(1)}>≥400<span className="ml-4 text-muted">+1</span></button>
                </div>
            </div>
           <div className="stick pt-2">
               {
                   result1()
               }
           </div>
           <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/22085763/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >Heyland DK, Dhaliwal R, Jiang X, Day AG. Identifying critically ill patients who benefit the most from nutrition therapy: the development and initial validation of a novel risk assessment tool. Crit Care. 2011;15(6):R268..</p>
                    </div>
                </a>
            </div>
    </div>
    )
}

export default RE1234
