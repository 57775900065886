import { React, useState, useEffect } from 'react';
import '../css/ApfelScore.css';
const BmiANDBsa = () => {
    const [green, setGreen] = useState(false);
    const [weight, setWeight] = useState([]);
    const [height, setHeight] = useState([]);
    const [targetBmi, setTargetBmi] = useState([]);


    const [placehldr1, setPlaceholder1] = useState("Norm: 152-213");
    const [placehldr3, setPlacehldr3] = useState("Norm: 120-170");
    const [tunit, setTunit] = useState("kg");
    const [tunit2, setTunit2] = useState("cm");

    const [newweight, setNewweight] = useState(0);
    const [newheight, setNewheight] = useState(0);
    const [result1, setResult1] = useState(0);
    const [result2, setResult2] = useState(0);

    const [changingunit, setChangingunit] = useState("kg");
    const [changingunit2, setChangingunit2] = useState("kg");







    useEffect(() => {
        postData();

    }, [weight, height, , placehldr1, placehldr3, newweight, tunit, tunit2, newheight, result2, result1, changingunit, changingunit2])




    const postData = async () => {



        if (tunit === 'kg') {
            if (weight < 273) {
                setNewweight(Math.round((weight) * 100) / 100);

            }
            else {
                setNewweight(0);
            }
        }
        if (tunit === 'lbs') {
            if (weight < 601) {
                setNewweight(Math.round((weight / 2.20) * 100) / 100);

            }
            else {
                setNewweight(0);
            }
        }
        if (tunit2 === 'cm') {
            if (height < 229) {
                setNewheight(height / 2.54);

            }
            else {
                setNewheight(0);

            }

        }
        if (tunit2 === 'in') {
            if (height < 90.7638) {
                setNewheight(height);

            }
            else {
                setNewheight(0);

            }

        }
        setResult1((newweight / ((newheight * 0.0254) * (newheight * 0.0254))).toFixed(0));
        setResult2((Math.sqrt(((newheight * 2.54) * newweight) / 3600)).toFixed(2));
        setGreen(true);

    }



    const changeunit = () => {
        if (weight === 0) {
            if (tunit === 'kg') {
                setTunit('lbs');

            }
            if (tunit === 'lbs') {
                setTunit('kg');

            }
        } else if (weight !== 0) {
            if (tunit === 'kg') {

                setTunit('lbs');
                if (weight.length !== 0) {
                    setWeight((weight * 2.2046226218488).toFixed(2));
                }
            }
            if (tunit === 'lbs') {

                setTunit('kg');
                if (weight.length !== 0) {
                    setWeight((weight / 2.2046226218488).toFixed(2));
                }

            }
        }
    }

    const takeUnit = (e) => {
        if (tunit === 'kg') {
            let htcm = (e.target.value);

            setWeight(htcm);


        }
        if (tunit === 'lbs') {
            let htin = (e.target.value);

            setWeight(htin);

        }

    }


    const changeunit2 = () => {
        if (height === 0) {
            if (tunit2 === 'cm') {
                setTunit2('in');

            }
            if (tunit2 === 'in') {
                setTunit2('cm');

            }
        } else if (height !== 0) {
            if (tunit2 === 'cm') {
                setTunit2('in');
                setPlaceholder1("Norm: 60 - 84");
                if (height.length !== 0) {

                    setHeight((height / 2.54).toFixed(2));
                }

            }
            if (tunit2 === 'in') {
                setTunit2('cm');
                setPlaceholder1("Norm: 152 - 213");
                if (height.length !== 0) {

                    setHeight((height * 2.54).toFixed(2));
                }

            }
        }
    }
    const takeunit2 = (e) => {
        if (tunit2 === 'cm') {
            setHeight(e.target.value);

        }
        if (tunit2 === 'in') {
            let ht = e.target.value;
            setHeight(ht);
        }
    }






    const validation1 = () => {
        if (tunit2 === 'cm') {
            if ((height <= 12) && (height != 0)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if ((height <= 228) && (height >= 214)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if ((height >= 229)) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(height)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }

        }

        if (tunit2 === 'in') {
            if (height !== 0) {
                if ((height < 48.0315) && (height > 0)) {
                    return <div className="validate">*Too low; please change to proceed.</div>

                }
                if ((height < 90.1575) && (height > 79.9213)) {
                    return <div className="validate">*Very High; double check.</div>

                }
                if (height > 89.7638) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(height)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
    }


    const validation2 = () => {
        if (tunit === 'kg') {
            if ((weight <= 272) && (weight >= 227) && (weight != 0)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (weight >= 273) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(weight)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }

        }


        if (tunit === 'lbs') {
            if ((weight < 55) && (weight != 0)) {
                return <div className="validate">*Too low; please change to proceed.</div>

            }
            if ((weight <= 600) && (weight >= 301)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (weight >= 601) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(weight)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    const validation3 = () => {
        if ((targetBmi < 10) && (targetBmi != 0)) {
            return <div className="validate">*Very low; double-check.</div>

        }
        if ((targetBmi < 51) && (targetBmi > 30)) {
            return <div className="validate">*Very High;double check.</div>

        }
        if (targetBmi > 50) {
            return <div className="validate">*Too high; please change to proceed.</div>

        }
        else if (isNaN(targetBmi)) {
            return <div className="validate">*Please Enter valid-no.</div>

        }
    }
    const showresult = () => {
        if (result1 < 18.5 && result1 > 0) {
            return <div>Underweight </div>
        }
        if (result1 < 25 && result1 > 18.49) {
            return <div>Normal weight</div>
        }
        if (result1 < 30 && result1 > 24.9) {
            return <div>	Overweight</div>
        }
        if (result1 < 35 && result1 > 29.9) {
            return <div>(Class 1 obesity)</div>
        }
        if (result1 < 40 && result1 > 34.9) {
            return <div>(Class 2 obesity)</div>
        }
        if (result1 >= 40.0) {
            return <div>(Class 3 obesity)</div>
        }
    }
    const showtargetBmi = () => {
        if (targetBmi != 0) {
            return <>
                <h1>{targetBmi} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>kg</span></h1>
                <div>Target weight for BMI {targetBmi} kg/m²</div>
            </>
        }
    }


    return (<div className="rev">
        <h5 className="text-info pl-2">Body Mass Index (BMI) and Body Surface Area (BSA)</h5>
        <p className="pl-2">Calculates body mass index and body surface area.</p>

        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Weight</strong></div>
                {/* <p>Optional, for calculating adjusted body weight in obese patients</p> */}
            </div>
            <div className="col-md-7 frm-field">
                <input type="number"

                    onChange={takeUnit}

                    className="ant-text-input" style={{ height:40 }} value={weight} /><span className="units" onClick={changeunit}>{tunit}&#8651;</span>

                <div>{validation2()}</div>
            </div>


        </div>

        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Height</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input style={{height:40}} type="number"
                    onChange={takeunit2}
                    placeholder={placehldr1}
                    className="ant-text-input"
                    value={height} /><span className="units" onClick={changeunit2}>{tunit2}&#8651;</span>
                <div>{validation1()}</div>

            </div>
  
        </div>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Target BMI</strong></div>
                <p>Optional, to determine weight required to achieve target BMI</p>

            </div>
            <div className="col-md-7 frm-field">
                <input type="number" style={{ height:40 }} className="ant-text-input " placeholder="Norm: 20-25" onChange={(e) => setTargetBmi(e.target.value)} /><span style={{ height: 40 }} className="units ">kg/m²</span>
                <div>{validation3()}</div>
            </div>
            
        </div>




        <div className="stick mt-5">
            {
                height != 0 && newheight != 0 && newweight != 0 && targetBmi < 51 ?
                    <div className="apfel-score-result-container" >
                        <div className="point-result-container" >
                            <h1>{result1} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>kg/m²</span></h1>
                            <div>Body Mass Index</div>
                            <div>{showresult()}</div>

                        </div>
                        <div className="point-result-container" >
                            <h1>{result2} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>m²</span></h1>
                            <div>Body Surface Area</div>
                        </div>
                        <div className="point-result-container" >
                            {showtargetBmi()}
                        </div>
                    </div>
                    :
                    <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>
            }
        </div>
        <br />
        <div className="p-2 mb-5" >
            <h4>LITERATURE</h4>

            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a href="https://pubmed.ncbi.nlm.nih.gov/16768059/" target="_blank" className="referenceLink">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Gadzik J. 'How much should I weigh?' Quetelet's equation, upper weight limits, and BMI prime.Connecticut Medicine. (2006). 70 (2): 81–8. PMID 16768059.</p>
                </div>
            </a>
        </div>
    </div>
    )
}
export default BmiANDBsa;