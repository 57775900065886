import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../images/logo-1.png"
import "../css/style.css"
const Navbar = () => {
    const userInfo = sessionStorage.getItem('token');
    return (
        <>
            <div className="stickheader">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <img src={logo}></img>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto main-nav">
                            {!userInfo ? <>
                            <li className="nav-item">
                                <NavLink activeClassName="active" className="nav-link" exact to="/">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink activeClassName="active" className="nav-link" exact to="/about">About</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink activeClassName="active" className="nav-link" exact to="/contact">Contact</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink activeClassName="active" className="nav-link" exact to="/login">Login</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink activeClassName="active" className="nav-link" exact to="/signup">Signup</NavLink>
                            </li>
                            </>: <>
                            <li className="nav-item">
                                <NavLink activeClassName="active" className="nav-link" exact to="/index">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink activeClassName="active" className="nav-link" exact to="/about">About</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink activeClassName="active" className="nav-link" exact to="/contact">Contact</NavLink>
                            </li>
                            </>
}
                        </ul>
                    </div>
                </nav>
            </div>
        </>
    );
};

export default Navbar;
