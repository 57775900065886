import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "./SearchBox.css"

class SearchBox extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: "",
      categories: props.categories

    };
  }

  onChange = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
  };

  onClick = e => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
  };

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: true,
        userInput: filteredSuggestions[activeSuggestion]
      });
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;

    if (this.props.showUserSuggestion) {
      if (this.props.searchData.length) {
        suggestionsListComponent = (
          <ul class="suggestions" style={this.props.LandingPage ? {  left: 0 } : null}>
            {this.props.searchData.map((suggestion, index) => {
              if (suggestion.name === undefined) {
                return null
              } else {
                return (
                  <li key={index} onClick={() => this.props.resetSearchAndCallActiveId(null, suggestion)}>
                    {suggestion.name}
                  </li>
                );
              }
            })}
          </ul>
        );
      } /*else {
        suggestionsListComponent = (
          <div class="no-suggestions">
            <em>No suggestion availble</em>
          </div>
        );
      }*/
    }

    return (
      <Fragment>
        <div className="search-box-main-wrapper">
          <form className="search-wrapper" onSubmit={(e) => e.preventDefault()}>
            <div className="form-group"  style={{width:'100%', background:'#FFF', paddingTop:15}}>
              {this.state?.categories ? <select value={this.props.activeCategory} className="form-control w-50 mr-1" onChange={(e) => this.props.changeActiveCategory(e.target.value, true)}>
                <option value="All">All</option>
                {this.state?.categories.map((ct) => {
                  return (
                    <option value={ct.name}>{ct.name}</option>
                  )
                })
                }
              </select> : <></>
              }
              <input
                type="text"
                onMouseDown={() => this.props.search("")}
                onChange={this.props.onSearchChange}
                onKeyPress={(e) => e.key === 'Enter' ? this.props.search("") : null}
                value={this.props.userInputSearch}
                className="search-text-input form-control p-2"
              />
              {
                this.props.searchLoading ? <div className="search-icon" style={{ padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                </div> : <i className="fa fa-search search-icon"></i>
              }
            </div>
          </form>
        </div>
        {suggestionsListComponent}
        {/* {
        this.props.suggestions.length?
        <ul className="suggestions">
        {this.props.suggestions?.map((nm, i)=>{return(
          <li key={i} onClick={onClick}>{nm}</li>
        )})}
        </ul>:<></>
  } */}

      </Fragment>
    );
  }
}

export default SearchBox;
