import React from 'react'
import PE1313 from './PE1313/PE1313'

const PreoperativeEvaluation = ({ id }) => {
    const data = {
        CP_1325: <PE1313 />
    }

    return (
        <React.Fragment>
            {data[id]}
        </React.Fragment>
    )
}

export {
    PreoperativeEvaluation
}