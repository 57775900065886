import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';


const ApfelScore = () => {
    const [gender, setGender] = useState(0);
    const [smoking, setSmoking] = useState(0);
    const [ponv, setPonv] = useState(0);
    const [opioids, setOpioids] = useState(0);

    const [result, setResult] = useState(0);
    const postData = async () => {
        setResult(gender + smoking + ponv + opioids);
     
    }
    useEffect(() => {
        postData();


    }, [gender, smoking, ponv, opioids]);

    const result1 = () => {

        if (result == 0) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>Apfel Score</div>
                </div>
                <div className="point-result-container" >
                    <h1>10 <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>%</span></h1>
                    <div>24-hour risk of PONV
                    </div>
                </div>
            </div>
        }
        if (result == 1) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>Apfel Score</div>
                </div>
                <div className="point-result-container" >
                    <h1>21 <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>%</span></h1>
                    <div>24-hour risk of PONV
                    </div>
                </div>
            </div>
        }
        if (result == 2) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>Apfel Score</div>
                </div>
                <div className="point-result-container" >
                    <h1>39 <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>%</span></h1>
                    <div>24-hour risk of PONV
                    </div>
                </div>
            </div>
        }
        if (result == 3) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>Apfel Score</div>
                </div>
                <div className="point-result-container" >
                    <h1>61 <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>%</span></h1>
                    <div>24-hour risk of PONV
                    </div>
                </div>
            </div>
        }
        if (result == 4) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>Apfel Score</div>
                </div>
                <div className="point-result-container" >
                    <h1>79 <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>%</span></h1>
                    <div>24-hour risk of PONV
                    </div>
                </div>
            </div>
        }
        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }

    return (
        <div className="rev">
            <h5 className="text-info pl-2">Apfel Score for Postoperative Nausea and Vomiting</h5>
            <p className="pl-2">Predicts risk of postoperative nausea and vomiting (PONV).</p>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Gender</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:'100%' }} key="1" className={gender === 0 ? "btn active" : "btn"} onClick={() => setGender(0)}>Male<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height:'100%' }} key="2" className={gender === 1 ? "btn active" : "btn"} onClick={() => setGender(1)}>Female<span className="ml-4 text-muted">+1</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Smoking status</strong></div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn mb-2">
                    <button style={{ height:'100%' }} onClick={() => setSmoking(0)} className={smoking === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name small">
                        Smoker</span><span className="calc-val small">0</span></button>
                    <button onClick={() => setSmoking(1)} className={smoking === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name small">
                        Nonsmoker</span><span className="calc-val small">+1</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>History of motion sickness or PONV</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:'100%' }} key="1" className={ponv === 0 ? "btn active" : "btn"} onClick={() => setPonv(0)}>No  <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height:'100%' }} key="2" className={ponv === 1 ? "btn active" : "btn"} onClick={() => setPonv(1)}>Yes <span className="ml-4 text-muted">+1</span></button>
                </div>
            </div>

            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Use of postoperative opioids</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:'100%' }} key="1" className={opioids === 0 ? "btn active" : "btn"} onClick={() => setOpioids(0)}>No  <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height:'100%' }} key="2" className={opioids === 1 ? "btn active" : "btn"} onClick={() => setOpioids(1)}>Yes <span className="ml-4 text-muted">+1</span></button>
                </div>
            </div>
            <div className="stick pt-3">
                {
                    result1()
                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubs.asahq.org/anesthesiology/article/91/3/693/37359/A-Simplified-Risk-Score-for-Predicting">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >Apfel CC, Läärä E, Koivuranta M, Greim CA, Roewer N. A simplified risk score for predicting postoperative nausea and vomiting: conclusions from cross-validations between two centers. Anesthesiology. 1999;91(3):693-700.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default ApfelScore
