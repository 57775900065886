import React, { useEffect, useState } from "react";
import "./CAS1095.css";

const CAS1095 = () => {
  const [green, setGreen] = useState(0);
  const [tag, setTag] = useState(null);
  const [maternal, setMetrnal] = useState(null);
  const [vaginalbirth, setVaginalbrith] = useState(null);
  const [cesarean, setCesarean] = useState(null);
  const [cervical, setCervical] = useState(null);
  const [cervicaldilation, setCervicaldilation] = useState(null);

  const [result, setResult] = useState(0);
  const postData = async () => {
    setResult(maternal + vaginalbirth + cesarean + cervical + cervicaldilation);

    setGreen(true);
  };
  useEffect(() => {
    postData();
  }, [maternal, vaginalbirth, cesarean, cervical, cervicaldilation, result]);

  const afterPhysicalChange = () => {
    if (tag == 1) {
      return (
        <div>
          <div className="alert alert-dark">
            Women in labor with history of caesarean section delivery.
          </div>
        </div>
      );
    }
    if (tag == 2) {
      return (
        <div>
          <div className="alert alert-dark">
            The VBAC Risk Score for Successful Vaginal Delivery (Flamm Model) is
            a simple scoring system for use at labor admission to predict which
            patients will have a successful vaginal birth after prior caesarean
            section (VBAC)..
            <br />
            It includes cervical assessment which can only be used after
            admission for labor.
            <br />A high VBAC Risk Score predicts success, but a low score does
            not necessarily predict failure; clinical judgment should be always
            considered.
          </div>
        </div>
      );
    }
    if (tag == 3) {
      return (
        <div>
          <div className="alert alert-dark">
            Determining which laboring patients are likely to have successful or
            unsuccessful vaginal delivery would be helpful for labor planning
            and shared decision making.
          </div>
        </div>
      );
    }
  };
  const result1 = () => {
    if (
      maternal != null &&
      vaginalbirth != null &&
      cesarean != null &&
      cervical != null &&
      cervicaldilation != null
    ) {
      if (result >= 0 && result <= 2) {
        return (
          <div className="apfel-score-result-container">
            <div className="point-result-container">
              <h1>
                {result}{" "}
                <span
                  style={{ fontWeight: 600, fontSize: "1rem", color: "silver" }}
                >
                  points
                </span>
              </h1>
              <div>49% of women with successful VBAC</div>
            </div>
          </div>
        );
      }
      if (result == 3) {
        return (
          <div className="apfel-score-result-container">
            <div className="point-result-container">
              <h1>
                {result}{" "}
                <span
                  style={{ fontWeight: 600, fontSize: "1rem", color: "silver" }}
                >
                  points
                </span>{" "}
              </h1>
              <div>60% of women with successful VBAC</div>
            </div>
          </div>
        );
      }
      if (result == 4) {
        return (
          <div className="apfel-score-result-container">
            <div className="point-result-container">
              <h1>
                {result}{" "}
                <span
                  style={{ fontWeight: 600, fontSize: "1rem", color: "silver" }}
                >
                  points
                </span>
              </h1>
              <div>67% of women with successful VBAC</div>
            </div>
          </div>
        );
      }
      if (result == 5) {
        return (
          <div className="apfel-score-result-container">
            <div className="point-result-container">
              <h1>
                {result}{" "}
                <span
                  style={{ fontWeight: 600, fontSize: "1rem", color: "silver" }}
                >
                  points
                </span>
              </h1>
              <div>77% of women with successful VBAC</div>
            </div>
          </div>
        );
      }
      if (result == 6) {
        return (
          <div className="apfel-score-result-container">
            <div className="point-result-container">
              <h1>
                {result}{" "}
                <span
                  style={{ fontWeight: 600, fontSize: "1rem", color: "silver" }}
                >
                  points
                </span>
              </h1>
              <div>89% of women with successful VBAC</div>
            </div>
          </div>
        );
      }
      if (result == 7) {
        return (
          <div className="apfel-score-result-container">
            <div className="point-result-container">
              <h1>
                {result}{" "}
                <span
                  style={{ fontWeight: 600, fontSize: "1rem", color: "silver" }}
                >
                  points
                </span>
              </h1>
              <div>93% of women with successful VBAC</div>
            </div>
          </div>
        );
      }
      if (result >= 8 && result <= 10) {
        return (
          <div className="apfel-score-result-container">
            <div className="point-result-container">
              <h1>
                {result}{" "}
                <span
                  style={{ fontWeight: 600, fontSize: "1rem", color: "silver" }}
                >
                  points
                </span>
              </h1>
              <div>95% of women with successful VBAC</div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="text-white p-2 green-bg">
          <h1>Result:</h1>
          <br />
          <h6>Please fill out required fields.</h6>
        </div>
      );
    }
  };
  return (
    <div className="rev">
      <h5 className="text-info pl-2">
        VBAC Risk Score for Successful Vaginal Delivery (Flamm Model)
      </h5>
      <p className="pl-2">
        Predicts likelihood of successful vaginal birth after previous caesarean
        section (VBAC).
      </p>
      <div className="fields row">
        <div className="col-md-9 btn-group pl-4">
          <button
            style={{ height: 40 }}
            className={tag === 1 ? "btn active text-white" : "btn "}
            onClick={() => setTag(1)}
          >
            No <span className="calc-val text-right"></span>
          </button>
          <button
            style={{ height: 40 }}
            className={tag === 2 ? "btn active text-white" : "btn "}
            onClick={() => setTag(2)}
          >
            Yes<span className="calc-val text-right"></span>
          </button>
          <button
            style={{ height: 40 }}
            className={tag === 3 ? "btn active text-white" : "btn "}
            onClick={() => setTag(3)}
          >
            Yes<span className="calc-val text-right"></span>
          </button>
        </div>
      </div>
      <div> {afterPhysicalChange()}</div>
      <div className="fields row">
        <div className="col-md-6">
          <div className="pt-1">
            <strong>Maternal age &lt; 40 years</strong>
          </div>
        </div>
        <div className="col-md-6 btn-group pl-4">
          <button
            style={{ height: "100%" }}
            className={maternal === 0 ? "btn active text-white" : "btn "}
            onClick={() => setMetrnal(0)}
          >
            No <span className="calc-val text-right">0</span>
          </button>
          <button
            style={{ height: "100%" }}
            className={maternal === 2 ? "btn active text-white" : "btn "}
            onClick={() => setMetrnal(2)}
          >
            Yes<span className="calc-val text-right">+2</span>
          </button>
        </div>
      </div>
      <div className="fields row">
        <div className="col-md-5">
          <div className="pt-2">
            <strong>Vaginal birth history</strong>
          </div>
        </div>
        <div className="col-md-7 btn-group-vertical options-btn mb-2">
          <button
            onClick={() => setVaginalbrith(4)}
            className={
              vaginalbirth === 4 ? "btn  text-left active" : "btn  text-left"
            }
          >
            <span className="btn-name ">
              Vaginal birth before and after first cesarean delivery
            </span>
            <span className="calc-val ">+4</span>
          </button>
          <button
            onClick={() => setVaginalbrith(2)}
            className={
              vaginalbirth === 2 ? "btn  text-left active" : "btn  text-left"
            }
          >
            <span className="btn-name ">
              Vaginal birth after first cesarean delivery
            </span>
            <span className="calc-val ">+2</span>
          </button>
          <button
            onClick={() => setVaginalbrith(1)}
            className={
              vaginalbirth === 1 ? "btn  text-left active" : "btn  text-left"
            }
          >
            <span className="btn-name ">
              Vaginal birth before cesarean delivery
            </span>
            <span className="calc-val ">+1</span>
          </button>
          <button
            onClick={() => setVaginalbrith(0)}
            className={
              vaginalbirth === 0 ? "btn  text-left active" : "btn  text-left"
            }
          >
            <span className="btn-name ">No previous vaginal birth</span>
            <span className="calc-val ">0</span>
          </button>
        </div>
      </div>
      <div className="fields row">
        <div className="col-md-6">
          <div className="pt-1">
            <strong>
              Reason other than failure to progress for first cesarean delivery
            </strong>
          </div>
        </div>
        <div className="col-md-6 btn-group pl-4">
          <button
            style={{ height: "100%" }}
            className={cesarean === 0 ? "btn active text-white" : "btn "}
            onClick={() => setCesarean(0)}
          >
            No <span className="calc-val text-right">0</span>
          </button>
          <button
            style={{ height: "100%" }}
            className={cesarean === 1 ? "btn active text-white" : "btn "}
            onClick={() => setCesarean(1)}
          >
            Yes&nbsp;<span className="calc-val text-right">+1</span>
          </button>
        </div>
      </div>
      <div className="fields row">
        <div className="col-md-5">
          <div className="pt-2">
            <strong>Cervical effacement at admission</strong>
          </div>
        </div>
        <div className="col-md-7 btn-group-vertical options-btn mb-2">
          <button
            onClick={() => setCervical(2)}
            className={
              cervical === 2 ? "btn  text-left active" : "btn  text-left"
            }
          >
            <span className="btn-name ">&gt; 75%</span>
            <span className="calc-val ">+2</span>
          </button>
          <button
            onClick={() => setCervical(1)}
            className={
              cervical === 1 ? "btn  text-left active" : "btn  text-left"
            }
          >
            <span className="btn-name ">25-75%</span>
            <span className="calc-val ">+1</span>
          </button>
          <button
            onClick={() => setCervical(0)}
            className={
              cervical === 0 ? "btn  text-left active" : "btn  text-left"
            }
          >
            <span className="btn-name ">&lt; 25%</span>
            <span className="calc-val ">0</span>
          </button>
        </div>
      </div>
      <div className="fields row mb-5">
        <div className="col-md-6">
          <div className="pt-1">
            <strong>Cervical dilation ≥ 4 cm at admission</strong>
          </div>
        </div>
        <div className="col-md-6 btn-group pl-4">
          <button
            style={{ height: "100%" }}
            className={
              cervicaldilation === 0 ? "btn active text-white" : "btn "
            }
            onClick={() => setCervicaldilation(0)}
          >
            No <span className="calc-val text-right">0</span>
          </button>
          <button
            style={{ height: "100%" }}
            className={
              cervicaldilation === 1 ? "btn active text-white" : "btn "
            }
            onClick={() => setCervicaldilation(1)}
          >
            Yes<span className="calc-val text-right">+1</span>
          </button>
        </div>
      </div>
      <div className="stick pt-3">{result1()}</div>
      <div className="p-2 mb-4">
        <div>ORIGINAL/PRIMARY REFERENCE</div>
        <a
          className="referenceLink"
          target="_blank"
          href="https://pubmed.ncbi.nlm.nih.gov/9397100/"
        >
          <div className="reference-container-card">
            <div className="reference-img">
              <img
                src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png"
                alt="Research Paper"
              />
            </div>
            <p className="reference-paragraph">
              Flamm BL, Geiger AM. Vaginal birth after cesarean delivery: an
              admission scoring system. Obstet Gynecol. 1997;90(6):907-10.
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default CAS1095;
