import React, { useEffect, useState } from 'react';
import './FAT1170.css'

const FAT1170 = () => {
    const [green, setGreen] = useState(false);
    const [number1, setNumber1] = useState([]);
    const [number2, setNumber2] = useState([]);
    const [number3, setNumber3] = useState([]);
    const [number4, setNumber4] = useState([]);
    const [number5, setNumber5] = useState([]);
    const [total1, settotal1] = useState(2 * number1 + (number2 / 2.8) + (number3 / 18) + (number4 / 4.6))
    const [total2, settotal2] = useState(2 * number1 + (number2 / 2.8) + (number3 / 18) + (number4 / 3.7))
    const [tunit, setTunit] = useState('mmol/L');
    const [placehldr, setPlacehldr] = useState('Norm: 136 - 145')
    const [tunit1, setTunit1] = useState('mmol/L');
    const [placehldr1, setPlacehldr1] = useState('Norm: 2.9-7.1')
    const [tunit2, setTunit2] = useState('mmol/L');
    const [placehldr2, setPlacehldr2] = useState('Norm: 62 - 115')
    const [tunit4, setTunit4] = useState('mmol/L');
    const [placehldr4, setPlacehldr4] = useState('Norm: 275-295')
    const [tunit3, setTunit3] = useState('mmol/L');
    const [placehldr3, setPlacehldr3] = useState('Norm: 0-17.36')
    const [newNumber, setnewNumber] = useState(0);
    const [newNumber1, setnewNumber1] = useState(0);
    const [newNumber2, setnewNumber2] = useState(0);
    const [newNumber3, setnewNumber3] = useState(0);
    const [newNumber4, setnewNumber4] = useState(0);


    const postData = async () => {
        if (tunit === 'mmol/L') {
            setnewNumber(number1)
        }
        if (tunit === 'mEq/L') {
            setnewNumber(number1 * 1)
        }
        if (tunit1 === 'mmol/L') {
            setnewNumber1(number2)
        }
        if (tunit1 === 'mg/dl') {
            setnewNumber1(number2 * 0.357)
        }
        if (tunit2 === 'mmol/L') {
            setnewNumber2(number3)
        }
        if (tunit2 === 'mg/dL') {
            setnewNumber2((number3 / 18).toFixed(2))
        }
        if (tunit3 === 'mmol/L') {
            setnewNumber3(number4)
        }
        if (tunit3 === 'mg/dl') {
            setnewNumber3(number4 * 0.217)
        }
        if (tunit4 === 'mmol/kg') {
            setnewNumber4(number5)
        }
        if (tunit4 === 'mOsm/kg') {
            setnewNumber4(number5 * 1)
        }

        if ((number1 < 200) && (number2 < 179) && (number3 < 88) && (number4 < 152) && (number5 < 700)) {


            settotal1(Math.round(2 * newNumber + (newNumber1 / 2.8) + (newNumber2 / 18) + (newNumber3 / 4.6)))
            settotal2(Math.round(2 * newNumber + (newNumber1 / 2.8) + (newNumber2 / 18) + (newNumber3 / 3.7) - newNumber4))

            setGreen(true);
        } else {
            settotal1(0);
            settotal2(0)

        }
    }
    useEffect(() => {
        postData()
    }, [number1, number2, number3, number4, number5, newNumber, newNumber1, newNumber2, newNumber3, newNumber4])

    const valnumber1 = () => {

        if (tunit === 'mmol/L') {
            if (number1 != 0) {
                if ((number1 < 100) && (number1 != 0) && (number1 > 0)) {
                    return <div className="validate">*Too low please check change to proceed</div>

                }
                if ((number1 < 201) && (number1 > 170)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number1 > 200) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number1)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
        if (tunit === 'mEq/L')
            if (number1 != 0) {
                if ((number1 < 100) && (number1 != 0) && (number1 > 0)) {
                    return <div className="validate">*Too small please change to proceed</div>

                }
                if ((number1 < 201) && (number1 > 170)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number1 > 200) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number1)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
    }
    const valnumber2 = () => {

        if (tunit1 === 'mmol/L') {
            if (number2 != 0) {
                if (number2 <= 0) {
                    return <div className="validate"> Too low;Pleage change to proceed</div>
                }

                if ((number2 < 179) && (number2 > 107)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number2 > 178) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number2)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
        if (tunit1 === 'mg/dL') {
            if (number2 != 0) {
                if (number2 <= 0) {
                    return <div className="validate">Too low ;please change to proceed</div>
                }

                if ((number2 < 501) && (number2 > 301)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number2 > 500) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number2)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
    }

    const valnumber3 = () => {

        if (tunit2 === 'mmol/L') {
            if (number3 != 0) {
                if ((number3 <= 0)) {
                    return <div className="validate">*Too small please change to Proceed</div>

                }
                if ((number3 < 89) && (number3 > 55)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number3 > 88) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number3)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
        if (tunit2 === 'mg/dL') {
            if (number3 != 0) {
                if ((number3 < 40) && (number3 != 0) && (number3 > 0)) {
                    return <div className="validate">*Too small please check double.</div>

                }
                if ((number3 < 2001) && (number3 > 1001)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number3 > 2000) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number3)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
    }
    const valnumber4 = () => {

        if (tunit3 === 'mmol/L') {
            if (number4 != 0) {
                // if ((number4 < 89) && (number4 != 0) &&(number4 >0)) {
                //     return <div className="validate">*Too small please check double.</div>

                // }
                if ((number4 < 152) && (number4 > 86)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number4 > 152) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number4)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
        if (tunit3 === 'mg/dL') {
            if (number4 != 0) {
                // if ((number4 < 89) && (number4 != 0) &&(number4 >0)) {
                //     return <div className="validate">*Too small please check double.</div>

                // }
                if ((number4 < 701) && (number4 > 400)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number4 > 700) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number4)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
    }
    const valnumber5 = () => {

        if (tunit4 === 'mmol/Kg') {
            if (number5 != 0) {
                // if ((number5 < 89) && (number5 != 0) &&(number5 >0)) {
                //     return <div className="validate">*Too small please check double.</div>

                // }
                if ((number5 < 152) && (number5 > 86)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number5 > 152) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number5)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        } if (tunit4 === 'mOsm/kg')
            if (number5 != 0) {
                if ((number5 < 270) && (number5 != 0) && (number5 > 0)) {
                    return <div className="validate">*Too small please check double.</div>

                }
                if ((number5 < 700) && (number5 > 350)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number5 > 700) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number5)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
    }

    const changeunit = () => {
        if (number1 === 0) {
            if (tunit === 'mmol/L') {
                setTunit('mEq/L');

            }
            if (tunit === 'mEq/L') {
                setTunit('mmol/L');

            }
        } else if (number1 !== 0) {
            if (tunit === 'mmol/L') {
                setPlacehldr("Norm: 136-145");
                setTunit('mEq/L');
                if (number1.length !== 0) {
                    setNumber1((number1 * 1).toFixed(2))
                }
            }
            if (tunit === 'mEq/L') {
                setPlacehldr("Norm: 136-145");
                setTunit('mmol/L');
                if (number1.length !== 0) {
                    setNumber1((number1 * 1).toFixed(2))
                }

            }
        }
    }
    const takeUnit = (e) => {
        if (tunit === 'mmol/L') {
            setNumber1(e.target.value);
            setPlacehldr("Norm: 136-145");

        }
        if (tunit === 'mEq/L') {
            let ht = e.target.value;
            setNumber1(ht);
            setPlacehldr("Norm: 136-145");
        }
    }

    const changeunit1 = () => {
        if (number2 === 0) {
            if (tunit1 === 'mmol/L') {
                setTunit1('mg/dL');

            }
            if (tunit1 === 'mg/dL') {
                setTunit1('mmol/L');

            }
        } else if (number2 !== 0) {
            if (tunit1 === 'mmol/L') {
                setPlacehldr1("Norm: 8-20");
                setTunit1('mg/dL');
                if (number2.length !== 0) {
                    setNumber2((number2 / 0.357).toFixed(2))
                }
            }
            if (tunit1 === 'mg/dL') {
                setPlacehldr1("Norm: 2.9-7.1");
                setTunit1('mmol/L');
                if (number2.length !== 0) {
                    setNumber2((number2 * 0.357).toFixed(2))
                }

            }
        }
    }
    const takeUnit1 = (e) => {
        if (tunit1 === 'mmol/L') {
            setNumber2(e.target.value);
            setPlacehldr1("Norm: 2.9-7.1");

        }
        if (tunit1 === 'mg/dL') {
            let ht = e.target.value;
            setNumber2(ht);
            setPlacehldr1("Norm: 8-20");
        }
    }
    const changeunit2 = () => {
        if (number3 === 0) {
            if (tunit2 === 'mmol/L') {
                setTunit2('mg/dL');

            }
            if (tunit2 === 'mg/dL') {
                setTunit2('mmol/L');

            }
        } else if (number3 !== 0) {
            if (tunit2 === 'mmol/L') {
                setPlacehldr2("Norm: 70-105");
                setTunit2('mg/dL');
                if (number3.length !== 0) {
                    setNumber3((number3 * 18).toFixed(2))
                }
            }
            if (tunit2 === 'mg/dL') {
                setPlacehldr2("Norm: 3.9-5.8");
                setTunit2('mmol/L');
                if (number3.length !== 0) {
                    setNumber3((number3 / 18).toFixed(2))
                }

            }
        }
    }
    const takeUnit2 = (e) => {
        if (tunit2 === 'mmol/L') {
            setNumber3(e.target.value);
            setPlacehldr2("Norm: 3.9-5.8");

        }
        if (tunit2 === 'mg/dL') {
            let ht = e.target.value;
            setNumber3(ht);
            setPlacehldr2("Norm: 70-105");
        }
    }
    const changeunit3 = () => {
        if (number4 === 0) {
            if (tunit3 === 'mmol/L') {
                setTunit3('mg/dL');

            }
            if (tunit3 === 'mg/dL') {
                setTunit3('mmol/L');

            }
        } else if (number4 !== 0) {
            if (tunit3 === 'mmol/L') {
                setPlacehldr3("Norm: 0-80");
                setTunit3('mg/dL');
                if (number4.length !== 0) {
                    setNumber4((number4 / 0.217).toFixed(2))
                }
            }
            if (tunit3 === 'mg/dL') {
                setPlacehldr3("Norm: 0-17.36");
                setTunit3('mmol/L');
                if (number4.length !== 0) {
                    setNumber4((number4 * 0.217).toFixed(2))
                }

            }
        }
    }
    const takeUnit3 = (e) => {
        if (tunit3 === 'mmol/L') {
            setNumber4(e.target.value);
            setPlacehldr3("Norm: 0-17.36");

        }
        if (tunit3 === 'mg/dL') {
            let ht = e.target.value;
            setNumber4(ht);
            setPlacehldr3("Norm: 0-80");
        }
    }
    const changeunith4 = () => {
        if (number5 === 0) {
            if (tunit4 === 'mmol/L') {
                setTunit4('mEq/L');

            }
            if (tunit4 === 'mEq/L') {
                setTunit4('mmol/L');

            }
        } else if (number5 !== 0) {
            if (tunit4 === 'mmol/L') {
                setPlacehldr4("Norm: 275-295");
                setTunit4('mEq/L');
                if (number5.length !== 0) {
                    setNumber5((number5 * 1).toFixed(2))
                }
            }
            if (tunit4 === 'mEq/L') {
                setPlacehldr4("Norm: 275-295");
                setTunit4('mmol/L');
                if (number5.length !== 0) {
                    setNumber5((number5 * 1).toFixed(2))
                }

            }
        }
    }
    const takeUnit4 = (e) => {
        if (tunit4 === 'mmol/L') {
            setNumber5(e.target.value);
            setPlacehldr4("Norm: 275-295");

        }
        if (tunit4 === 'mEq/L') {
            let ht = e.target.value;
            setNumber5(ht);
            setPlacehldr4("Norm: 275-295");
        }
    }




    return (<div className="rev">
        <h5 className="text-info pl-2">Serum Osmolality/Osmolarity</h5>
        <p className="pl-2">Calculates expected serum osmolarity, for comparison to measured osmolarity to detect unmeasured compounds in the serum.</p>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Sodium</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input type="number"
                    onChange={takeUnit}
                    placeholder={placehldr}




                    className="ant-text-input" style={{ height:40 }} value={number1} /><span className="units" onClick={changeunit} style={{ height:40 }}>{tunit}&#8651;</span>
                <div>{valnumber1()}</div>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Bun</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input type="number"
                    onChange={takeUnit1}
                    placeholder={placehldr1}


                    className="ant-text-input" style={{ height:40 }} value={number2} /><span className="units" onClick={changeunit1} style={{ height:40 }}>{tunit1}&#8651;</span>
                <div>{valnumber2()}</div>

            </div>
        </div>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Glocose</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input type="number"
                    onChange={takeUnit2}
                    placeholder={placehldr2}

                    className="ant-text-input" style={{ height:40 }} value={number3} /><span className="units" onClick={changeunit2} style={{ height:40 }}>{tunit2}&#8651;</span>
                <div>{valnumber3()}</div>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Serum alcohol concentration</strong></div>
                <div>Enter 80, not 0.08 if in US units (mg/dL)</div>
            </div>
            <div className="col-md-7 frm-field">
                <input type="number"
                    onChange={takeUnit3}
                    placeholder={placehldr3}
                    className="ant-text-input" value={number4} style={{ height:40 }} /><span className="units unitBtnfu" style={{ height:40 }} onClick={changeunit3}>{tunit3}&#8651;</span>
                <div>{valnumber4()}</div>
            </div>
        </div>

        <div className="fields row mb-5">
            <div className="col-md-5">
                <div className="pt-2"><strong>Measure serum osm</strong></div>
                <div>Normal value is 285 mmol/kg</div>
            </div>
            <div className="col-md-7 frm-field">
                <input type="number"
                    onChange={takeUnit4}
                    placeholder={placehldr4}
                    className="ant-text-input fluidInput" value={number5} style={{ height:40 }} /><span className="units unitBtnfu" style={{ height:40 }} onClick={changeunith4}>{tunit4}&#8651;</span>
                <div>{valnumber5()}</div>
            </div>
        </div>

        <br />
        <div className="stick-result">
            {
                (total1 && total2) ?
                    <div className="apfel-score-result-container" >
                        <div className="point-result-container" >
                            <h1 className="breath">{total1} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>breaths/min/L</span></h1>
                            <div>Osm</div>
                            <p>Normal serum osmolality = 285-295 mOsm/kg</p>

                        </div>
                        <div className="percentage-result-container" >
                            <h1>{total2} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>
                                Normal Serum Osm Gap</span></h1>
                            <div> (Measured-Calculated) is -14 to +10.</div>

                        </div>

                    </div>
                    :
                    <div className="text-white p-2 green-bg" ><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>
            }
        </div>
        <br />

        <div className="p-2 mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a href="https://www.sciencedirect.com/science/article/abs/pii/S0196064401166667" target="_blank" className="referenceLink">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Purssell RA, Pudek M, Brubacher J, Abu-Laban RB.Derivation and validation of a formula to calculate the contribution of ethanol to the osmolal gap.Ann Emerg Med 2001;38 (6): 653–9.doi:10.1067/mem.2001.119455. PMID 11719745.</p>
                </div>
            </a>
        </div>
    </div>
    )
}

export default FAT1170