import React, { useEffect, useState } from 'react';
import './RE1231.css';

const RE1231 = () => {
    const [quality, setQuality] = useState(0);
    const [number1, SetNumber1] = useState(0);
    const [number2, SetNumber2] = useState(0);
    const [number3, SetNumber3] = useState(0);
    const [number4, SetNumber4] = useState(0);
    const [number5, SetNumber5] = useState(0);
    const [number6, SetNumber6] = useState(0);
    const [number7, SetNumber7] = useState(0);
    const [number8, SetNumber8] = useState(0);
    const [number9, SetNumber9] = useState(0);
    const [number10, SetNumber10] = useState(0);
    const [result, setResult] = useState(0);

    const postData = async () => {

        setResult(parseInt(number1) + parseInt(number2) + parseInt(number3) + parseInt(number4) + parseInt(number5) + parseInt(number6) + parseInt(number7) + parseInt(number8) + parseInt(number9) + parseInt(number10));

    }
    useEffect(() => {
        postData();


    }, [number1, number2, number3, number4, number5, number6, number7, number8, number9, number10]);
    const valnumber1 = () => {
        if (number1 != 0) {
            if ((number1 == 11)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (number1 >= 12) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(number1)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    const valnumber2 = () => {
        if (number2 != 0) {
            if ((number2 == 11)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (number2 >= 12) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(number2)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    const valnumber3 = () => {
        if (number3 != 0) {
            if ((number3 == 11)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (number3 >= 12) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(number3)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    const valnumber4 = () => {
        if (number4 != 0) {
            if ((number4 == 11)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (number4 >= 12) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(number4)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    const valnumber5 = () => {
        if (number5 != 0) {
            if ((number5 == 11)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (number5 >= 12) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(number5)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    const valnumber6 = () => {
        if (number6 != 0) {
            if ((number6 == 11)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (number6 >= 12) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(number6)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    const valnumber7 = () => {
        if (number7 != 0) {
            if ((number7 == 11)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (number7 >= 12) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(number7)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    const valnumber8 = () => {
        if (number8 != 0) {
            if ((number8 == 11)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (number8 >= 12) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(number8)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    const valnumber9 = () => {
        if (number9 != 0) {
            if ((number9 == 11)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (number9 >= 12) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(number9)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    const valnumber10 = () => {
        if (number10 != 0) {
            if ((number10 == 11)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (number10 >= 12) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(number10)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }

    const result1 = () => {
        if (quality) {
            if (quality == 3) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                        <div>The higher the score (maximum 100 points), the worse the neuropathic pain. Consider appropriate analgesia..</div>
                    </div>
                    <div className="point-result-container" >
                        <div>Time quality of pain: background pain present all the time, with occasional flare-ups (breakthrough pain)</div>
                    </div>
                </div>
            }
            if (quality == 1) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                        <div>The higher the score (maximum 100 points), the worse the neuropathic pain. Consider appropriate analgesia..</div>
                        <div></div>
                    </div>
                    <div className="point-result-container" >
                        <div>Time quality of pain: single type of pain present all the time</div>
                    </div>
                </div>
            }
            if (quality == 2) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                        <div>The higher the score (maximum 100 points), the worse the neuropathic pain. Consider appropriate analgesia..</div>
                        <div></div>
                    </div>
                    <div className="point-result-container" >
                        <div>Time quality of pain: single type of pain only sometimes present</div>
                    </div>

                </div>
            }

        }
        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }
    return (
        <div className="rev">
            <h5 className="text-info pl-2">Neuropathy Pain Scale (NPS)</h5>
            <p className="pl-2">Quantifies severity of neuropathic pain.</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>How intense is the pain?</strong></div>
                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        placeholder="Norm: 0-10" onChange={(e) => SetNumber1(e.target.value)}
                        className="ant-text-input"  />
                          <div>{valnumber1()}</div>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>How sharp is the pain?</strong><br />‘Like a knife’</div>
                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        placeholder="Norm: 0-10" onChange={(e) => SetNumber2(e.target.value)} className="ant-text-input" style={{height:40}}/>
                          <div>{valnumber2()}</div>
                </div>

            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>How hot is the pain?</strong><br />‘On fire’</div>
                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        placeholder="Norm: 0-10" onChange={(e) => SetNumber3(e.target.value)} className="ant-text-input" style={{height:40}}/>
                          <div>{valnumber3()}</div>
                </div>

            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>How dull is the pain?</strong></div>
                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        placeholder="Norm: 0-10" onChange={(e) => SetNumber4(e.target.value)} className="ant-text-input" style={{height:40}}/>
                          <div>{valnumber4()}</div>
                </div>

            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>How cold is the pain?</strong><br />‘Freezing’</div>
                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        placeholder="Norm: 0-10" onChange={(e) => SetNumber5(e.target.value)} className="ant-text-input" style={{height:40}}/>
                          <div>{valnumber5()}</div>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>How sensitive is the skin to light touch?</strong><br />‘Raw skin’</div>
                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        placeholder="Norm: 0-10" onChange={(e) => SetNumber6(e.target.value)} className="ant-text-input" style={{height:40}}/>
                          <div>{valnumber6()}</div>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>How itchy is the pain?</strong></div>
                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        placeholder="Norm: 0-10" onChange={(e) => SetNumber7(e.target.value)} className="ant-text-input" style={{height:40}}/>
                          <div>{valnumber7()}</div>
                </div>

            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>How unpleasant is the pain?</strong><br />‘Intolerable’</div>
                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        placeholder="Norm: 0-10" onChange={(e) => SetNumber8(e.target.value)} className="ant-text-input" style={{height:40}}/>
                          <div>{valnumber8()}</div>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>If the pain is deep, how intense is the deep pain?</strong><br />Mark 0 if no deep pain</div>
                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        placeholder="Norm: 0-10" onChange={(e) => SetNumber9(e.target.value)} className="ant-text-input" style={{height:40}}/>
                          <div>{valnumber9()}</div>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>If the pain is on the surface, how intense is the surface pain?</strong><br />Mark 0 if no surface pain</div>
                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        placeholder="Norm: 0-10" onChange={(e) => SetNumber10(e.target.value)} className="ant-text-input" style={{height:40}}/>
                          <div>{valnumber10()}</div>
                </div>
            </div>
            <div className="fields row mb-5">
                <div className="col-md-5">
                    <div className="pt-2"><strong>What is the time quality of the pain?</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{height:60}} onClick={() => setQuality(3)} className={quality === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Background pain present all the time, with occasional<br/> flare-ups (breakthrough pain)</span><span className="calc-val "></span></button>
                    <button style={{height:40}} onClick={() => setQuality(1)} className={quality === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Single type of pain present all the time</span><span className="calc-val "></span></button>
                    <button style={{height:40}} onClick={() => setQuality(2)} className={quality === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Single type of pain only sometimes present</span><span className="calc-val "></span></button>
                </div>
            </div>
            <div className="stick">
                {
                    result1()

                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/9040716/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >Galer BS, Jensen MP. Development and preliminary validation of a pain measure specific to neuropathic pain: the Neuropathic Pain Scale. Neurology. 1997 Feb;48(2):332-8</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default RE1231
