import React, { useEffect, useState } from 'react';
import './RE1230.css';
import mallampati from '../../../images/mallampati.png'

const RE1230 = () => {
    const [green, setGreen] = useState(true);
    const [visualization, setVisualization] = useState('');
    const [exchange_volume, setExchange_volume] = useState('');
    const [scrollpos, setScrollPos] = useState({ bottom: 0 });

    const postData = async () => {
        setExchange_volume(visualization);
    }
    useEffect(() => {
        postData()

    }, [visualization, exchange_volume])
    return (<div className="rev">
        <h5 className="text-info pl-2">Modified Mallampati Classification</h5>
        <p className="pl-2">Stratifies predicted difficulty of endotracheal intubation based on anatomic features.</p>
        <div className="fields row">
            <div className="col-md-12 text-center">
                <img src={mallampati} style={{ width: '100%', height: '100%' }} />
            </div>
        </div>

        <div className="fields row">
            <div className="col-md-4">
                <div className="pt-2"><strong>Visualization:</strong></div>
            </div>
            <div className="col-md-8 btn-group-vertical options-btn">
                <button onClick={() => setVisualization('I')} className={visualization === 'I' ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Faucial pillars, soft palate, and uvula visualized</span></button>
                <button onClick={() => setVisualization('II')} className={visualization === 'II' ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Faucial pillars and soft palate visualized, but uvula masked by tongue base</span></button>
                <button onClick={() => setVisualization('III')} className={visualization === 'III' ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Only soft palate visualized</span></button>
                <button onClick={() => setVisualization('IV')} className={visualization === 'IV' ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Soft palate not visualized</span></button>

            </div>
        </div>
        {visualization ?
            <div className="stick">

                <div className="apfel-score-result-container" style={scrollpos} >
                    <div className="point-result-container" >

                        <h2> Class {exchange_volume}</h2>
                        Modified Mallampati Classification
                    </div>


                </div>
            </div> :
            <div className="stick">
                <div className="text-white p-2 green-bg" style={scrollpos}><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>
            </div>
        }
        <div className="reference-container mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/4027773/">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Mallampati SR, Gatt SP, Gugino LD, et al. A clinical sign to predict difficult tracheal intubation: a prospective study. Can Anaesth Soc J. 1985;32(4):429-34.</p>
                </div>
            </a>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/3592174/">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Samsoon GL, Young JR. Difficult tracheal intubation: a retrospective study. Anaesthesia. 1987;42(5):487-90.</p>
                </div>
            </a>
        </div>
    </div>
    )
}
export default RE1230;