import { React, useState, useEffect } from 'react';
import '../css/ApfelScore.css';
import upperLips from '../images/upper-lips.png'

const UpperLipBiteTestAirwayCalculator = () => {
    const [green, setGreen] = useState(true);
    const [visualization, setVisualization] = useState('');
    const [exchange_volume, setExchange_volume] = useState('');
    const [scrollpos, setScrollPos] = useState({ bottom: 0 });

    const postData = async () => {
        setGreen(true);
        setExchange_volume(visualization);
    }
    useEffect(() => {
        postData()

    }, [visualization, exchange_volume])
    return (<div className="rev">
        <h5 className="text-info pl-2">Upper Lip Bite Test</h5>
        <div className="fields row">
            <div className="col-md-12 text-center">
                <img src={upperLips} style={{ width: '95%', height: '100%' }} />
            </div>
        </div>

        <div className="fields row">
            <div className="col-md-2">

            </div>
            <div className="col-md-8 btn-group-vertical options-btn">
                <button onClick={() => setVisualization('I')} className={visualization === 'I' ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Lower incisors can bite upper lip above the vermilion line</span></button>
                <button onClick={() => setVisualization('II')} className={visualization === 'II' ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Lower incisors can bite upper lip below vermilion line</span></button>
                <button onClick={() => setVisualization('III')} className={visualization === 'III' ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Lower incisors cannot bite upper lip</span></button>
            </div>
        </div><br />
        {visualization ?
            <div className="stick">
                {
                    green ?
                        <div className="apfel-score-result-container" style={scrollpos}>
                            <div className="point-result-container" >

                                <h2> Class {exchange_volume}</h2>
                                Upper Lip Bite Test
                            </div>

                        </div>
                        :
                        <></>
                }
            </div> : <div>
                <div className="text-white p-2 green-bg" style={scrollpos}><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div></div>
        }
 <div className="reference-container mb-4" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a href="https://pubmed.ncbi.nlm.nih.gov/30190773/" target="_blank" className="referenceLink">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Khan Z.H. (2014) Airway Assessment: A Critical Appraisal. In: Khan Z. (eds) Airway Management. Springer, Cham. https://doi.org/10.1007/978-3-319-08578-4_2</p>
                </div>
                </a>
            </div>
    </div>
    )
}
export default UpperLipBiteTestAirwayCalculator;