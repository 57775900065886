import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';

const WintersFormula = () => {
    const [green, setGreen] = useState(false);
    const [fexpression, setFexpression] = useState(0);
    const [upperlimb, setUpperlimb] = useState(0);
    const [mvantilation, setMvantilation] = useState(0);



    const [number3, setNumber3] = useState([]);
    const [tunit1, setTunit1] = useState('mmol/L');
    const [placehldr1, setPlacehldr1] = useState('Norm:23-28')
    const [result1, setResult1] = useState(0);
    const [result2, setResult2] = useState(0);
    const [result3, setResult3] = useState(0);
    const [result4, setResult4] = useState(0);

    const postData = async () => {
        if(number3 < 101){
            setResult3((result1 * 0.1333).toFixed(1))
            setResult4((result2 * 0.1333).toFixed(1))
            setResult1(((1.5 * number3) + 8 + 2).toFixed(0));
            setResult2(((1.5 * number3) + 8 - 2).toFixed(0))
        }
        else{
            setResult3(0);
            setResult4(0);
            setResult1(0);
            setResult2(0);
        }
   
       

        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [number3, tunit1, placehldr1, result1, result2, result3, result4]);

    const changeunit1 = () => {
        if (number3 === 0) {
            if (tunit1 === 'mmol/L') {
                setTunit1('mEq/L');

            }
            if (tunit1 === 'mEq/L') {
                setTunit1('mmol/L');

            }
        } else if (number3 !== 0) {
            if (tunit1 === 'mmol/L') {
                setPlacehldr1("Norm: 23-28");
                setTunit1('mEq/L');
                if (number3.length !== 0) {
                    setNumber3(Math.round(number3 * 1).toFixed(2))
                }
            }
            if (tunit1 === 'mEq/L') {
                setPlacehldr1("Norm: 23-28");
                setTunit1('mmol/L');
                if (number3.length !== 0) {
                    setNumber3(Math.round(number3 * 1))
                }
            }
        }
    }
    const takeUnit1 = (e) => {
        if (tunit1 === 'mmol/L') {
            setNumber3(e.target.value);
            setPlacehldr1("Norm: 23-28");

        }
        if (tunit1 === 'mEq/L') {
            let ht = e.target.value;
            setNumber3(ht);
            setPlacehldr1("Norm:23-28");
        }
    }

    const validation1 = () => {
        if ((number3 < 101) && (number3 > 60)) {
            return <div className="validate">*Very High;double check.</div>

        }
      
            if (number3 > 100) {
                return <div className="validate" >Too high; please change to proceed.</div>

            }
            if (isNaN(number3)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
   
    
    }


    const outputresult = () => {
        if (tunit1 === 'mmol/L') {
            return <>
                <h1>{result4} - {result3}  <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>kPa</span></h1>
                <div>Expected pCO2 Compensation.</div>
            </>
        }
        if (tunit1 === 'mEq/L') {
            return <>
                <h1>{result2} - {result1}  <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>mm Hg</span></h1>
                <div>Expected pCO2 Compensation.</div>
            </>
        }
    }

    return (
        <div className="rev">
            <h5 className="text-info pl-2">Winters' Formula for Metabolic Acidosis Compensation</h5>
            <p className="pl-2">Calculates the expected pCO₂ compensation in a purely metabolic acidosis.</p>
            <div className="fields row">
                <div className="col-md-5 col-sm-12">
                    <div className="pt-2"><strong>Bicarbonate</strong></div>
                </div>
                <div className="col-md-7 col-sm-12 frm-field">
                    <input
                        onChange={takeUnit1}
                        placeholder={placehldr1}

                        className="ant-text-input" style={{ width: "65%" }} value={number3} /><span className="units" onClick={changeunit1}>{tunit1}&#8651;</span>
                <div>{validation1()}</div>
                </div>
               
            </div>


            <div className="stick mt-5">
                {
                    result4 != 0.8 && result1 !=0 ? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container" >
                                {outputresult()}
                            </div>

                        </div>

                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <br />
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://www.acpjournals.org/doi/10.7326/0003-4819-66-2-312?articleid=681186">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Albert MS, Dell RB, Winters RW. Quantitative Displacement of Acid-Base Equilibrium in Metabolic Acidosis. Ann Intern Med. 1967;66:312-322. doi:10.7326/0003-4819-66-2-312.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}


export default WintersFormula;

