import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AppContext from '../contextStore/DataProvider';
import constantObject from '../views/pages/home/constantObject';
const DrugLink = (props) => {
const history = useHistory();
const context = useContext(AppContext);
let data = [
    {
        "id": 232,
        "drug_id": 36,
        "drug_brand_name": "Fentanyl",
        "cp_code": "DBN_0232",
        "age_days_from": 6570,
        "age_days_to": 40000,
        "patient_type_id": 3,
        "organ_system_id": 11,
        "bolus_dose_from": 25,
        "bolus_dose_to": 100,
        "bolus_dose_unit": "mcg",
        "bolus_dose_min": null,
        "bolus_dose_max": null,
        "bolus_dose_final_unit": "mcg",
        "infusion_dose_from": null,
        "infusion_dose_to": null,
        "infusion_dose_unit": null,
        "infusion_dose_min": null,
        "infusion_dose_max": null,
        "infusion_dose_final_unit": null,
        "indication_id": 20,
        "administration_id": 1,
        "administration_notes": null,
        "created_at": null,
        "updated_at": "2021-10-31T13:26:11.000000Z",
        "name": "Fentanyl",
        "d_name": "ANALGESICS, OPIOIDS",
        "organ_systems_name": "Neurological",
        "administration_route": "IV",
        "indication": "Analgesia",
        "patient_type_name": "Adult",
        "parent_name": "ANALGESICS, OPIOIDS",
        "parent_id": 36
      },
]

const navigateToAllAction = () => {

    let did = ''
    let pathtogo = window.location.pathname.split(':')[1];
    context.changeActiveId(null);
    context.activeId = null;
    
    if(pathtogo == 'Etomidate') {
        context.changeActiveId('DBN_0075');
        context.activeId = 'DBN_0075';
        props.history.push({
            pathname: "/actionLibrary",
            state: { id: 75 },
            cp_table:'drugs',
            data:{
                "id": 75,
                "drug_id": 106,
                "drug_brand_name": "Etomidate",
                "cp_code": "DBN_0075",
                "cp_table":'drugs',
                "age_days_from": 6570,
                "age_days_to": 40000,
                "patient_type_id": 3,
                "organ_system_id": 11,
                "bolus_dose_from": 0.2,
                "bolus_dose_to": 0.6,
                "bolus_dose_unit": "mg/kg",
                "bolus_dose_min": null,
                "bolus_dose_max": null,
                "bolus_dose_final_unit": "mg",
                "infusion_dose_from": null,
                "infusion_dose_to": null,
                "infusion_dose_unit": null,
                "infusion_dose_min": null,
                "infusion_dose_max": null,
                "infusion_dose_final_unit": null,
                "indication_id": 33,
                "administration_id": 1,
                "administration_notes": "LBW; Typically 0.3 mg/kg",
                "created_at": null,
                "updated_at": "2021-10-17T01:30:01.000000Z",
                "name": "Etomidate",
                "d_name": "SEDATIVES AND HYPNOTICS",
                "organ_systems_name": "Neurological",
                "administration_route": "IV",
                "indication": "GA",
                "patient_type_name": "Adult",
                "parent_name": "SEDATIVES AND HYPNOTICS",
                "parent_id": 106
              },
        })
    }
    if(pathtogo == 'Fentanyl') {
        context.changeActiveId('DBN_0232');
        context.activeId = 'DBN_0232'
        did = 232
       props.history.push({
            pathname: "/actionLibrary",
            state: { id: 232, parent_id:36 , name:'Fentanyl' },
            cp_table:'drugs',
            data: {
                "id": 78,
                "drug_id": 36,
                "drug_brand_name": "Fentanyl",
                "cp_code": "DBN_0078",
                "cp_table":'drugs',
                "age_days_from": 1,
                "age_days_to": 6569,
                "patient_type_id": 2,
                "organ_system_id": 11,
                "bolus_dose_from": 0.5,
                "bolus_dose_to": 1,
                "bolus_dose_unit": "mcg/kg",
                "bolus_dose_min": null,
                "bolus_dose_max": null,
                "bolus_dose_final_unit": "mcg",
                "infusion_dose_from": 1,
                "infusion_dose_to": 2,
                "infusion_dose_unit": "mcg/kg/hr",
                "infusion_dose_min": null,
                "infusion_dose_max": null,
                "infusion_dose_final_unit": "mcg/hr",
                "indication_id": 20,
                "administration_id": 1,
                "administration_notes": "LBW",
                "created_at": null,
                "updated_at": "2021-10-17T11:21:15.000000Z",
                "name": "Fentanyl",
                "d_name": "ANALGESICS, OPIOIDS",
                "organ_systems_name": "Neurological",
                "administration_route": "IV",
                "indication": "Analgesia",
                "patient_type_name": "Pediatric",
                "parent_name": "ANALGESICS, OPIOIDS",
                "parent_id": 36
              }

        })
    }
    if(pathtogo == 'Midazolam') {
        context.changeActiveId('DBN_0118');
        context.activeId = 'DBN_0118'
        did = 232
       props.history.push({
            pathname: "/actionLibrary",
            state: { id: 118},
            cp_table:'drugs',
            data: {
                "id": 118,
                "drug_id": 106,
                "drug_brand_name": "Midazolam",
                "cp_code": "DBN_0118",
                "cp_table": "drugs",
                "age_days_from": 1,
                "age_days_to": 6569,
                "patient_type_id": 2,
                "organ_system_id": 11,
                "bolus_dose_from": null,
                "bolus_dose_to": 0.2,
                "bolus_dose_unit": "mg/kg",
                "bolus_dose_min": null,
                "bolus_dose_max": null,
                "bolus_dose_final_unit": "mg",
                "infusion_dose_from": null,
                "infusion_dose_to": null,
                "infusion_dose_unit": null,
                "infusion_dose_min": null,
                "infusion_dose_max": null,
                "infusion_dose_final_unit": null,
                "indication_id": 35,
                "administration_id": 9,
                "administration_notes": null,
                "created_at": null,
                "updated_at": "2021-10-17T01:55:51.000000Z",
                "name": "Midazolam",
                "d_name": "SEDATIVES AND HYPNOTICS",
                "organ_systems_name": "Neurological",
                "administration_route": "Intranasal",
                "indication": "Anxiety",
                "patient_type_name": "Pediatric",
                "parent_name": "SEDATIVES AND HYPNOTICS",
                "parent_id": 106
            }

        })
    }
   
    if(pathtogo == 'Ketamine') {
        context.changeActiveId('DBN_0100');
        context.activeId = 'DBN_0100'
       props.history.push({
            pathname: "/actionLibrary",
            state: { id: 100},
            cp_table:'drugs',
            data: {
                "id": 100,
                "drug_id": 106,
                "drug_brand_name": "Ketamine",
                "cp_code": "DBN_0100",
                "cp_table": "drugs",
                "age_days_from": 1,
                "age_days_to": 6569,
                "patient_type_id": 2,
                "organ_system_id": 11,
                "bolus_dose_from": 0.5,
                "bolus_dose_to": 2,
                "bolus_dose_unit": "mg/kg",
                "bolus_dose_min": null,
                "bolus_dose_max": null,
                "bolus_dose_final_unit": "mg",
                "infusion_dose_from": 0.1,
                "infusion_dose_to": 0.2,
                "infusion_dose_unit": "mcg/kg/hr",
                "infusion_dose_min": null,
                "infusion_dose_max": null,
                "infusion_dose_final_unit": "mcg/hr",
                "indication_id": 33,
                "administration_id": 1,
                "administration_notes": "LBW",
                "created_at": null,
                "updated_at": "2021-10-17T01:42:08.000000Z",
                "name": "Ketamine",
                "d_name": "SEDATIVES AND HYPNOTICS",
                "organ_systems_name": "Neurological",
                "administration_route": "IV",
                "indication": "GA",
                "patient_type_name": "Pediatric",
                "parent_name": "SEDATIVES AND HYPNOTICS",
                "parent_id": 106
              }

        })
    }
    if(pathtogo == 'Propofol') {
        context.changeActiveId('DBN_0156');
        context.activeId = 'DBN_0156'
       props.history.push({
            pathname: "/actionLibrary",
            state: { id: 100},
            cp_table:'drugs',
            data: {
                "id": 156,
                "drug_id": 106,
                "drug_brand_name": "Propofol",
                "cp_code": "DBN_0156",
                "cp_table": "drugs",
                "age_days_from": 6570,
                "age_days_to": 40000,
                "patient_type_id": 3,
                "organ_system_id": 11,
                "bolus_dose_from": 1,
                "bolus_dose_to": 2.5,
                "bolus_dose_unit": "mg/kg",
                "bolus_dose_min": null,
                "bolus_dose_max": null,
                "bolus_dose_final_unit": "mg",
                "infusion_dose_from": 100,
                "infusion_dose_to": 200,
                "infusion_dose_unit": "mcg/kg/min",
                "infusion_dose_min": null,
                "infusion_dose_max": null,
                "infusion_dose_final_unit": "mcg/min",
                "indication_id": 33,
                "administration_id": 1,
                "administration_notes": null,
                "created_at": null,
                "updated_at": "2021-10-17T01:59:16.000000Z",
                "name": "Propofol",
                "d_name": "SEDATIVES AND HYPNOTICS",
                "organ_systems_name": "Neurological",
                "administration_route": "IV",
                "indication": "GA",
                "patient_type_name": "Adult",
                "parent_name": "SEDATIVES AND HYPNOTICS",
                "parent_id": 106
              }

        })
    }
    if(pathtogo == 'Dexamethasone') {
        context.changeActiveId('DBN_0174');
        context.activeId = 'DBN_0174'
       props.history.push({
            pathname: "/actionLibrary",
            state: { id: 174},
            cp_table:'drugs',
            data: {
                "id": 174,
                "drug_id": 100,
                "drug_brand_name": "Dexamethasone",
                "cp_code": "DBN_0174",
                "cp_table": "drugs",
                "age_days_from": 1,
                "age_days_to": 6569,
                "patient_type_id": 10,
                "organ_system_id": 16,
                "bolus_dose_from": null,
                "bolus_dose_to": 0.15,
                "bolus_dose_unit": "mg/kg",
                "bolus_dose_min": null,
                "bolus_dose_max": null,
                "bolus_dose_final_unit": "mg",
                "infusion_dose_from": null,
                "infusion_dose_to": null,
                "infusion_dose_unit": null,
                "infusion_dose_min": null,
                "infusion_dose_max": null,
                "infusion_dose_final_unit": null,
                "indication_id": 2,
                "administration_id": 1,
                "administration_notes": "Bolus dose max 4mg / dose; Avoid in certain oncologic kids",
                "created_at": null,
                "updated_at": "2021-10-19T17:58:20.000000Z",
                "name": "Dexamethasone",
                "d_name": "ANTIEMETICS",
                "organ_systems_name": "Endocrine",
                "administration_route": "IV",
                "indication": "PONV prophylaxis",
                "patient_type_name": "All",
                "parent_name": "ANTIEMETICS",
                "parent_id": 100
              }

        })
    }
    if(pathtogo == 'Dexmedetomidine') {
        context.changeActiveId('DBN_0052');
        context.activeId = 'DBN_0052'
       props.history.push({
            pathname: "/actionLibrary",
            state: { id: 52},
            cp_table:'drugs',
            data: {
                "id": 52,
                "drug_id": 106,
                "drug_brand_name": "Dexmedetomidine",
                "cp_code": "DBN_0052",
                "cp_table": "drugs",
                "age_days_from": 1,
                "age_days_to": 6569,
                "patient_type_id": 2,
                "organ_system_id": 11,
                "bolus_dose_from": 0.5,
                "bolus_dose_to": 1,
                "bolus_dose_unit": "mcg/kg",
                "bolus_dose_min": null,
                "bolus_dose_max": null,
                "bolus_dose_final_unit": "mcg",
                "infusion_dose_from": 0.2,
                "infusion_dose_to": 1,
                "infusion_dose_unit": "mcg/kg/hr",
                "infusion_dose_min": null,
                "infusion_dose_max": null,
                "infusion_dose_final_unit": "mcg/hr",
                "indication_id": 34,
                "administration_id": 1,
                "administration_notes": "Bolus dose = load over 10 min",
                "created_at": null,
                "updated_at": "2021-10-17T01:26:42.000000Z",
                "name": "Dexmedetomidine",
                "d_name": "SEDATIVES AND HYPNOTICS",
                "organ_systems_name": "Neurological",
                "administration_route": "IV",
                "indication": "MAC",
                "patient_type_name": "Pediatric",
                "parent_name": "SEDATIVES AND HYPNOTICS",
                "parent_id": 106
              }

        })
    }
    if(pathtogo == 'Remifentanil') {
        context.changeActiveId('DBN_0159');
        context.activeId = 'DBN_0159'
       props.history.push({
            pathname: "/actionLibrary",
            state: { id: 159},
            cp_table:'drugs',
            data: {
                "id": 159,
                "drug_id": 36,
                "drug_brand_name": "Remifentanil",
                "cp_code": "DBN_0159",
                "cp_table": "drugs",
                "age_days_from": 1,
                "age_days_to": 6569,
                "patient_type_id": 2,
                "organ_system_id": 11,
                "bolus_dose_from": 0.5,
                "bolus_dose_to": 1,
                "bolus_dose_unit": "mcg/kg",
                "bolus_dose_min": null,
                "bolus_dose_max": null,
                "bolus_dose_final_unit": "mcg",
                "infusion_dose_from": 0.1,
                "infusion_dose_to": 1,
                "infusion_dose_unit": "mcg/kg/min",
                "infusion_dose_min": null,
                "infusion_dose_max": null,
                "infusion_dose_final_unit": "mcg/min",
                "indication_id": 38,
                "administration_id": 1,
                "administration_notes": "LBW",
                "created_at": null,
                "updated_at": "2021-10-17T11:29:08.000000Z",
                "name": "Remifentanil",
                "d_name": "ANALGESICS, OPIOIDS",
                "organ_systems_name": "Neurological",
                "administration_route": "IV",
                "indication": "TIVA",
                "patient_type_name": "Pediatric",
                "parent_name": "ANALGESICS, OPIOIDS",
                "parent_id": 36
            }
        })
    }
    if(pathtogo == 'Rocuronium') {
        context.changeActiveId('DBN_0264');
        context.activeId = 'DBN_0264'
       props.history.push({
            pathname: "/actionLibrary",
            state: { id: 264},
            cp_table:'drugs',
            data: {
                "id": 264,
                "drug_id": 120,
                "drug_brand_name": "Rocuronium",
                "cp_code": "DBN_0264",
                "cp_table": "drugs",
                "age_days_from": 0,
                "age_days_to": 40150,
                "patient_type_id": 10,
                "organ_system_id": 17,
                "bolus_dose_from": null,
                "bolus_dose_to": 0.6,
                "bolus_dose_unit": "mg/kg",
                "bolus_dose_min": null,
                "bolus_dose_max": null,
                "bolus_dose_final_unit": "mg",
                "infusion_dose_from": 10,
                "infusion_dose_to": 12,
                "infusion_dose_unit": "mcg/kg/min",
                "infusion_dose_min": null,
                "infusion_dose_max": null,
                "infusion_dose_final_unit": "mcg/min",
                "indication_id": 31,
                "administration_id": 1,
                "administration_notes": "IBW; Standard 0.6 mg/kg IV; RSI 1.2 mg/kg IV; Maintenance bolus 0.1-0.2 mg/kg; Maintenance infusion 10-12 mcg/kg/min",
                "created_at": "2021-10-16T18:56:45.000000Z",
                "updated_at": "2021-10-24T14:16:53.000000Z",
                "name": "Rocuronium",
                "d_name": "NEUROMUSCULAR BLOCKADE",
                "organ_systems_name": "Musculoskeletal",
                "administration_route": "IV",
                "indication": "NMB",
                "patient_type_name": "All",
                "parent_name": "NEUROMUSCULAR BLOCKADE",
                "parent_id": 120
            }
        })
    }
    if(pathtogo == 'Succinylcholine') {
        context.changeActiveId('DBN_0219');
        context.activeId = 'DBN_0219'
       props.history.push({
            pathname: "/actionLibrary",
            state: { id: 219},
            cp_table:'drugs',
            data: {
                "id": 219,
                "drug_id": 120,
                "drug_brand_name": "Succinylcholine",
                "cp_code": "DBN_0219",
                "cp_table": "drugs",
                "age_days_from": 1,
                "age_days_to": 6569,
                "patient_type_id": 2,
                "organ_system_id": 17,
                "bolus_dose_from": 0.25,
                "bolus_dose_to": 0.5,
                "bolus_dose_unit": "mg/kg",
                "bolus_dose_min": null,
                "bolus_dose_max": null,
                "bolus_dose_final_unit": "mg",
                "infusion_dose_from": null,
                "infusion_dose_to": null,
                "infusion_dose_unit": "",
                "infusion_dose_min": null,
                "infusion_dose_max": null,
                "infusion_dose_final_unit": "",
                "indication_id": 31,
                "administration_id": 1,
                "administration_notes": "TBW; 0.1-1 mg/dose",
                "created_at": null,
                "updated_at": "2021-10-16T19:28:10.000000Z",
                "name": "Succinylcholine",
                "d_name": "NEUROMUSCULAR BLOCKADE",
                "organ_systems_name": "Musculoskeletal",
                "administration_route": "IV",
                "indication": "NMB",
                "patient_type_name": "Pediatric",
                "parent_name": "NEUROMUSCULAR BLOCKADE",
                "parent_id": 120
            }
        })
    }
    if(pathtogo == 'Glycopyrrolate') {
        context.changeActiveId('DBN_0234');
        context.activeId = 'DBN_0234'
       props.history.push({
            pathname: "/actionLibrary",
            state: { id: 234},
            cp_table:'drugs',
            data: {
                "id": 234,
                "drug_id": 120,
                "drug_brand_name": "Glycopyrrolate",
                "cp_code": "DBN_0234",
                "cp_table": "drugs",
                "age_days_from": 6570,
                "age_days_to": 40000,
                "patient_type_id": 3,
                "organ_system_id": 12,
                "bolus_dose_from": 7,
                "bolus_dose_to": 14,
                "bolus_dose_unit": "mcg/kg",
                "bolus_dose_min": null,
                "bolus_dose_max": null,
                "bolus_dose_final_unit": "mcg",
                "infusion_dose_from": null,
                "infusion_dose_to": null,
                "infusion_dose_unit": null,
                "infusion_dose_min": null,
                "infusion_dose_max": null,
                "infusion_dose_final_unit": null,
                "indication_id": 32,
                "administration_id": 1,
                "administration_notes": "Bolus dose max 1mg; Administer 0.2mg for every 1mg of Neostigmine",
                "created_at": null,
                "updated_at": "2021-11-10T20:09:30.000000Z",
                "name": "Glycopyrrolate",
                "d_name": "NEUROMUSCULAR BLOCKADE",
                "organ_systems_name": "Cardiovascular",
                "administration_route": "IV",
                "indication": "NMB Reversal",
                "patient_type_name": "Adult",
                "parent_name": "NEUROMUSCULAR BLOCKADE",
                "parent_id": 120
            }
        })
    }
    if(pathtogo == 'Neostigmine') {
        context.changeActiveId('DBN_0243');
        context.activeId = 'DBN_0243'
       props.history.push({
            pathname: "/actionLibrary",
            state: { id: 243},
            cp_table:'drugs',
            data: {
                "id": 243,
                "drug_id": 120,
                "drug_brand_name": "Neostigmine",
                "cp_code": "DBN_0243",
                "cp_table": "drugs",
                "age_days_from": 6570,
                "age_days_to": 40000,
                "patient_type_id": 3,
                "organ_system_id": 17,
                "bolus_dose_from": 30,
                "bolus_dose_to": 70,
                "bolus_dose_unit": "mcg/kg",
                "bolus_dose_min": null,
                "bolus_dose_max": null,
                "bolus_dose_final_unit": "mcg",
                "infusion_dose_from": null,
                "infusion_dose_to": null,
                "infusion_dose_unit": null,
                "infusion_dose_min": null,
                "infusion_dose_max": null,
                "infusion_dose_final_unit": null,
                "indication_id": 32,
                "administration_id": 1,
                "administration_notes": "Bolus dose max 5mg; Administer with Glycopyrrolate 7-14 mcg/kg",
                "created_at": null,
                "updated_at": "2021-10-19T16:46:52.000000Z",
                "name": "Neostigmine",
                "d_name": "NEUROMUSCULAR BLOCKADE",
                "organ_systems_name": "Musculoskeletal",
                "administration_route": "IV",
                "indication": "NMB Reversal",
                "patient_type_name": "Adult",
                "parent_name": "NEUROMUSCULAR BLOCKADE",
                "parent_id": 120
            }
        })
    }
    if(pathtogo == 'Sugammadex') {
        context.changeActiveId('DBN_0249');
        context.activeId = 'DBN_0249'
       props.history.push({
            pathname: "/actionLibrary",
            state: { id: 249},
            cp_table:'drugs',
            data: {
                "id": 249,
                "drug_id": 120,
                "drug_brand_name": "Sugammadex",
                "cp_code": "DBN_0249",
                "cp_table": "drugs",
                "age_days_from": 6570,
                "age_days_to": 40000,
                "patient_type_id": 3,
                "organ_system_id": 17,
                "bolus_dose_from": null,
                "bolus_dose_to": null,
                "bolus_dose_unit": "",
                "bolus_dose_min": null,
                "bolus_dose_max": null,
                "bolus_dose_final_unit": "",
                "infusion_dose_from": null,
                "infusion_dose_to": null,
                "infusion_dose_unit": null,
                "infusion_dose_min": null,
                "infusion_dose_max": null,
                "infusion_dose_final_unit": null,
                "indication_id": 32,
                "administration_id": 1,
                "administration_notes": "For rocuronium or vecuronium blockade\r\n>= 2 twitches on TOF: 2mg/kg, ABW; \r\n1-2 post-tetanic counts and no twitch response on TOF: 4 mg/kg, ABW;\r\nImmediate reversal: 16 mg/kg, ABW",
                "created_at": null,
                "updated_at": "2021-10-17T00:45:33.000000Z",
                "name": "Sugammadex",
                "d_name": "NEUROMUSCULAR BLOCKADE",
                "organ_systems_name": "Musculoskeletal",
                "administration_route": "IV",
                "indication": "NMB Reversal",
                "patient_type_name": "Adult",
                "parent_name": "NEUROMUSCULAR BLOCKADE",
                "parent_id": 120
            }
        })
    }
    if(pathtogo == 'Ondansetron') {
        context.changeActiveId('DBN_0308');
        context.activeId = 'DBN_0308'
       props.history.push({
            pathname: "/actionLibrary",
            state: { id: 308},
            cp_table:'drugs',
            data: {
                "id": 308,
                "drug_id": 100,
                "drug_brand_name": "Ondansetron",
                "cp_code": "DBN_0308",
                "cp_table": "drugs",
                "age_days_from": 6569,
                "age_days_to": 40100,
                "patient_type_id": 3,
                "organ_system_id": 15,
                "bolus_dose_from": 4,
                "bolus_dose_to": 8,
                "bolus_dose_unit": "mg",
                "bolus_dose_min": null,
                "bolus_dose_max": null,
                "bolus_dose_final_unit": "mg",
                "infusion_dose_from": null,
                "infusion_dose_to": null,
                "infusion_dose_unit": null,
                "infusion_dose_min": null,
                "infusion_dose_max": null,
                "infusion_dose_final_unit": null,
                "indication_id": 2,
                "administration_id": 2,
                "administration_notes": null,
                "created_at": "2021-10-19T18:08:14.000000Z",
                "updated_at": "2021-10-19T18:08:14.000000Z",
                "name": "Ondansetron",
                "d_name": "ANTIEMETICS",
                "organ_systems_name": "Other",
                "administration_route": "PO",
                "indication": "PONV prophylaxis",
                "patient_type_name": "Adult",
                "parent_name": "ANTIEMETICS",
                "parent_id": 100
            }
        })
    }
    if(pathtogo == 'Lidocaine') {
        context.changeActiveId('DBN_0314');
        context.activeId = 'DBN_0314'
       props.history.push({
            pathname: "/actionLibrary",
            state: { id: 314},
            cp_table:'drugs',
            data: {
                "id": 314,
                "drug_id": 122,
                "drug_brand_name": "Lidocaine",
                "cp_code": "DBN_0314",
                "cp_table": "drugs",
                "age_days_from": 0,
                "age_days_to": 40100,
                "patient_type_id": 10,
                "organ_system_id": 11,
                "bolus_dose_from": 1,
                "bolus_dose_to": 2,
                "bolus_dose_unit": "mg/kg",
                "bolus_dose_min": null,
                "bolus_dose_max": null,
                "bolus_dose_final_unit": "mg",
                "infusion_dose_from": 1.5,
                "infusion_dose_to": 3,
                "infusion_dose_unit": "mg/kg/h",
                "infusion_dose_min": null,
                "infusion_dose_max": null,
                "infusion_dose_final_unit": "mg/h",
                "indication_id": 20,
                "administration_id": 1,
                "administration_notes": null,
                "created_at": "2021-10-19T18:25:52.000000Z",
                "updated_at": "2021-10-19T18:25:52.000000Z",
                "name": "Lidocaine",
                "d_name": "ANALGESICS, NONOPIOIDS",
                "organ_systems_name": "Neurological",
                "administration_route": "IV",
                "indication": "Analgesia",
                "patient_type_name": "All",
                "parent_name": "ANALGESICS, NONOPIOIDS",
                "parent_id": 122
            }
        })
    }
    if(pathtogo == "localanesthesia")  {
        props.history.push({pathname: "/actionLibrary",
        state: { id: 108},
        cp_table:'drugs',
        data: 
            {
                "id": 108,
                "user_fav_table": "uf_drugs",
                "foreign_key": "drugs_id",
                "name": "LOCAL ANESTHETICS",
                "parent_id": null,
                "parent_name": null,
                "status": "Active",
                "is_star": "false",
                "user_fav": "1",
                "cp_table": "drugs",
                "cp_code": "D1108"
              }

        })
    }
  
   
}
useEffect(() => {
    navigateToAllAction();
    
   
},[])
return (
    <></>
)
}
export default DrugLink;
