import React, { useEffect, useState } from 'react';
import './RE1176.css';

const RE1176 = () => {
    const [green, setGreen] = useState(false);
    const [penetrating, setPenetrating] = useState(0);
    const[posativefocused,setPosativefocused]=useState(0);
    const[arrivalsystolic,setArrivalsystolic]=useState(0);
    const[arrivalheart,setArrivalheart]=useState(0);
    const[abcscore,setAbcscore]=useState(0);


    const postData = async () => {

        setAbcscore(penetrating+posativefocused +arrivalsystolic +arrivalheart);
    

        setGreen(true);

    }
    const abcresult = ()=>{
            if(abcscore==1){
               return <>Interpretation: Massive transfusion (10 units or more of PRBCs) Unlikely – 10%.</>

            }
            else if(abcscore==2){
                return <>Interpretation: Massive transfusion (10 units or more of PRBCs) Likely – 41%.</>
            }
            else if(abcscore==3){
                return <>Interpretation: Massive transfusion (10 units or more of PRBCs) Likely – 48%.</>
            }
            else if(abcscore==4){
                return <>Interpretation: Massive transfusion (10 units or more of PRBCs) Highly likely – 100%.</>
            }else{
                return <>Interpretation: Massive transfusion (10 units or more of PRBCs) Unlikely – 1%.</>
            }
            
    }
    useEffect(() => {
        postData();

    }, [ penetrating, posativefocused,arrivalsystolic,arrivalheart])

    return (<div className="rev">
        <h5 className="text-info pl-2">ABC Score for Massive Transfusion</h5>
        <p className="pl-2">Determines the necessity of triggering massive transfusion (MT) protocol and likelihood of administration of pRBC. </p>

        <div className="fields row">
            <div className="col-md-5 col-sm-12">
                <div className="pt-2"><strong>Penetrating mechanism of injury</strong></div>
            </div>
            <div className="col-md-7 col-sm-12 btn-group text-left">
                <button  className={penetrating === 0 ? "btn fluidBtns active text-white" : "btn fluidBtns "} onClick={() => setPenetrating(0)}>No <span className="calc-val text-right">(0)</span></button>
                <button  className={penetrating === 1 ? "btn fluidBtns active text-white" : "btn fluidBtns"} onClick={() => setPenetrating(1)}>Yes <span className="calc-val text-right">(+1)</span></button>

            </div>

        </div>

        <div className="fields row">
            <div className="col-md-5 col-sm-12">
                <div className="pt-2"><strong>Positive focused assessment sonography for trauma (FAST)</strong></div>
            </div>
            <div className="col-md-7 col-sm-12 btn-group text-left">
                <button  className={posativefocused === 0 ? "btn fluidBtns active text-white" : "btn fluidBtns "} onClick={() => setPosativefocused(0)}>No <span className="calc-val text-right">(0)</span></button>
                <button  className={posativefocused === 1 ? "btn fluidBtns active text-white" : "btn fluidBtns "} onClick={() => setPosativefocused(1)}>Yes <span className="calc-val text-right">(+1)</span></button>

            </div>

        </div>
        <div className="fields row">
            <div className="col-md-5 col-sm-12">
                <div className="pt-2"><strong>Arrival systolic blood pressure of 90 mmHg or less</strong></div>
            </div>
            <div className="col-md-7 col-sm-12 btn-group text-left">
                <button  className={arrivalsystolic === 0 ? "btn fluidBtns active text-white" : "btn fluidBtns"} onClick={() => setArrivalsystolic(0)}>No <span className="calc-val text-right">(0)</span></button>
                <button  className={arrivalsystolic === 1 ? "btn fluidBtns active text-white" : "btn fluidBtns"} onClick={() => setArrivalsystolic(1)}>Yes <span className="calc-val text-right">(+1)</span></button>

            </div>

        </div>
        <div className="fields row">
            <div className="col-md-5 col-sm-12">
                <div className="pt-2"><strong>Arrival heart rate of 120 bpm or above</strong></div>
            </div>
            <div className="col-md-7 col-sm-12 btn-group text-left">
                <button  className={arrivalheart === 0 ? "btn fluidBtns active text-white" : "btn fluidBtns"} onClick={() => setArrivalheart(0)}>No <span className="calc-val text-right">(0)</span></button>
                <button className={arrivalheart === 1 ? "btn fluidBtns active text-white" : "btn fluidBtns"} onClick={() => setArrivalheart(1)}>Yes <span className="calc-val text-right">(+1)</span></button>

            </div>

        </div>
        
       <br/>
        <div>
            {
                abcscore ?
                    <div className="apfel-score-result-container" >

                        <div className="point-result-container" >
                            <div>
                                <span><h4 className="breath">ABC Score = {abcscore} </h4></span>
                                {abcresult()}        
                            </div>



                        </div>

                    </div>
                    :
                    <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>
            }
        </div>
        <br/>
        <div className="p-2 mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a  className="referenceLink" target="_blank" href = "https://pubmed.ncbi.nlm.nih.gov/19204506/">
            <div className="reference-container-card" >
                <div className="reference-img" >
                    <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                </div>
                <p className="reference-paragraph" >Nunez TC, Voskresensky IV, Dossett LA, Shinall R, Dutton WD, Cotton BA. Early prediction of massive transfusion in trauma: simple as ABC (assessment of blood consumption)? J Trauma. 2009; 66(2):346-52.</p>
            </div>
            </a>           
        </div>
    </div>
    )
}
export default RE1176;