import React, { useEffect, useState } from 'react';
import './AAV1004.css'

const AAV1004 = () => {
    const [green, setGreen] = useState(false);
    const [number1, setNumber1] = useState(0);

    const [total, settotal] = useState(0);
    const [total1, settotal1] = useState(0);

    const postData = async () => {

        if(number1 <= 121){
            settotal(((number1 / 4) + 4).toFixed(1));
            settotal1(((number1 / 4) + 3).toFixed(1));
        
        }
        else{
            settotal(0);
            settotal1(0);

        }
        setGreen(true);
    }

        const valnumber1 = () => {


            if ( number1 != 0) {
                
                if ((number1 < 121) && (number1 > 100)) {
                    return <div className="validate">*Very High;double check.</div>
    
                }
                if (number1 > 121) {
                    return <div className="validate">*Too high; please change to proceed.</div>
    
                }
                else if (isNaN(number1)) {
                    return <div className="validate">*Please Enter valid-no.</div>
    
                }
            }
    
        }
    

    useEffect(() => {
        postData()

    }, [number1, total, total1])

    const result = () => {
        if (number1 <= 12) {
            return <div  >
                {(number1 && total && total1) ? <div className="apfel-score-result-container">
                    <div className="point-result-container" >
                        <h1>
                        {number1==1 || number1==2 || number1==3  ? '4-4.5' : ''}
                       { number1==4 ?'5.0' :''  }
                       {number1==5 || number1==6 || number1==7  ? '5-5.5' : ''}
                       { number1==8 ?'6.0' :''  }
                       {number1==9 || number1==10 || number1==11  ? '6-6.5 ' : ''}
                       { number1==12 ?'7.0' :''  }
               
                        <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}> mm tube</span> </h1>
                        Predicted Uncuffed Tube Size 
                    </div>
                    <div className="point-result-container" >
                        <h1 className="breath">
                        {number1==1 || number1==2 || number1==3  ? '3-3.5' : ''}
                       { number1==4 ?'4.0' :''  }
                       {number1==5 || number1==6 || number1==7  ? '4-4.5' : ''}
                       { number1==8 ?'5.0' :''  }
                       {number1==9 || number1==10 || number1==11  ? '5-5.5 ' : ''}
                       { number1==12 ?'6.0' :''  }

                        <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}> mm tube</span></h1>
                        Predicted Cuffed Tube Size 
                    </div>
                </div>: <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>
                }
            </div>



        } else {
            return (
                <div><br/>
            <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <div>
                        By age 12, most experts recommend switching to adult ETT sizes.

                    </div>
                </div>
            </div>
            </div>
            )
        }

    }

    return (<div className="rev">
        <h5 className="text-info pl-2">Pediatric Endotracheal Tube (ETT) Size</h5>
<p className="pl-2">Estimates ET tube size based on age.</p>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Age</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input
                    type={number1}
                    
                    onChange={e => setNumber1(+e.target.value)}
                    autoComplete="off"
                    className="ant-text-input" style={{ width: '70%' }} /><span className="units">Years</span>
                    <div>{valnumber1()}</div>
            </div>
        </div>

        <div className="stick-result"><br/>
            {
                result()

            }
        </div>  
        <div className="p-2 mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/10149098/">
            <div className="reference-container-card" >
                <div className="reference-img" >
                    <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                </div>
                <p className="reference-paragraph" >Singh NC et al, Physiological responses to endotracheal and oral suctioning in paediatric patients: the influence of endotracheal tube sizes and suction pressures. Clin Intensive Care. 1991; 2: 345-350.</p>
            </div>
            </a>
        </div>
    </div>
    )
}

export default AAV1004