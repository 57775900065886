export const SET_MASK = "SET_MASK"
export const SET_LMA = "SET_LMA"
export const SET_INTUBATION = "SET_INTUBATION"
export const SET_SUB_INTUBATION = "SET_SUB_INTUBATION"
export const SET_LUNG_INTUBATION = "SET_LUNG_INTUBATION"
export const SET_LUNG_SUB_INTUBATION = "SET_LUNG_SUB_INTUBATION"
export const SET_LUNG_SUB_SUB_INTUBATION = "SET_LUNG_SUB_SUB_INTUBATION"
export const SET_LARYNGOSCOPE_MAIN = "SET_LARYNGOSCOPE_MAIN"
export const SET_LARYNGOSCOPE_SUB_TYPE = "SET_LARYNGOSCOPE_SUB_TYPE"
export const SET_LARYNGOSCOPE_SUB_SUB_TYPE = "SET_LARYNGOSCOPE_SUB_SUB_TYPE"
export const SET_AIRWAY_ADJUNCT = "SET_AIRWAY_ADJUNCT"
export const SET_AIRWAY_ADJUNCT_TYPE = "SET_AIRWAY_ADJUNCT_TYPE"
export const SET_AIRWAY_OTHER = "SET_AIRWAY_OTHER"
export const SET_AIRWAY_CALCULATORS = "SET_AIRWAY_CALCULATORS"
export const SET_AIRWAY_NOTES = "SET_AIRWAY_NOTES"