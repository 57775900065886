import { React, useState, useEffect } from 'react';
import './RE1196.css';
const RE1196 = () => {
    const [green, setGreen] = useState(false);
    const [weight, setWeight] = useState([]);
    const [height, setHeight] = useState([]);
    const [sao, setSao] = useState([]);
    const [svo, setSvo] = useState([]);
    const [heartRate, setHeartRate] = useState([]);
    const [hemoglobin2, setHemoglobin2] = useState([]);
    const [age, setAge] = useState([]);
    const [placehldr1, setPlaceholder1] = useState("Norm: 152-213");
    const [placehldr3, setPlacehldr3] = useState("Norm: 120-170");

    const [number2, setNumber2] = useState([]);


    const [twohr, setTwohr] = useState(0);
    const [threehr, setThreehr] = useState([]);
    const [fourhr, setFourhr] = useState(0);
    const [tunit, setTunit] = useState("kg");
    const [tunit2, setTunit2] = useState("cm");
    const [tunit3, setTunit3] = useState('g/L');


    const [newweight, setNewweight] = useState(0);
    const [newheight, setNewheight] = useState(0);
    const [newhemoglobin2, setNewhemoglobin2] = useState(0);
    const [vo2, setvo2] = useState(0);
    const [bsa, setbsa] = useState(0);
    const [cardiacOutput, setCardiacOutput] = useState(0);
    const [cardiacIndex, setCardiacIndex] = useState(0);
    const [strokeVolume, setStrokeVolume] = useState(0);
    useEffect(() => {
        postData();

    }, [weight, height, sao, svo, heartRate, hemoglobin2, age, placehldr1, placehldr3, number2, , threehr, fourhr, tunit, tunit2, tunit3, newheight, newweight, newhemoglobin2, vo2, bsa, cardiacOutput])




    const postData = async () => {


        setbsa(Math.sqrt(newweight * newheight / 3600));
        if (age == 2) {
            setvo2(110 * (Math.sqrt(newweight * newheight / 3600)));
        }
        else {
            setvo2(125 * (Math.sqrt(newweight * newheight / 3600)));
        }


        if (tunit === 'kg') {
            if (weight < 273) {

                setNewweight((((weight) * 100) / 100).toFixed(1));
            }
            else {
                setNewweight(0);
            }

        }
        if (tunit === 'lbs') {
            if (weight < 601) {
                setNewweight((((weight / 2.2046226218488) * 100) / 100).toFixed(1));

            }
            else {
                setNewweight(0);
            }

        }
        if (tunit2 === 'cm') {
            if (height < 229) {

                setNewheight(height);
            }
            else {
                setNewheight(0);
            }

        }
        if (tunit2 === 'in') {

            if (height < 90.7638) {

                setNewheight((height * 2.54).toFixed(2));
            }
            else {
                setNewheight(0);
            }

        }

        if (tunit3 === 'g/L') {

if(hemoglobin2 >4 && hemoglobin2 < 251 ){

    setNewhemoglobin2(parseFloat(hemoglobin2));
}
else{
    setNewhemoglobin2(0);
}

        }
        if (tunit3 === 'g/dL') {
            if(hemoglobin2 < 26){

                setNewhemoglobin2(parseFloat(hemoglobin2 * 10));
            }
            else{
                setNewhemoglobin2(0);
            }


        }

        if (sao > 39 && sao < 101 && svo < 101 && heartRate < 301) {
            setCardiacOutput(((vo2 / ((sao - svo) * newhemoglobin2 * 13.4)) * 1000).toFixed(1));
            setCardiacIndex((cardiacOutput / bsa).toFixed(1));
            setStrokeVolume(Math.round((cardiacOutput / heartRate) * 1000))
        }
        else {
            setCardiacOutput(0);
            setCardiacIndex(0);
            setStrokeVolume(0);
        }

        setGreen(true);

    }



    const changeunit = () => {
        if (weight === 0) {
            if (tunit === 'kg') {
                setTunit('lbs');

            }
            if (tunit === 'lbs') {
                setTunit('kg');

            }
        } else if (weight !== 0) {
            if (tunit === 'kg') {

                setTunit('lbs');
                if (weight.length !== 0) {
                    setWeight((weight * 2.2046226218488).toFixed(2));
                }
            }
            if (tunit === 'lbs') {

                setTunit('kg');
                if (weight.length !== 0) {
                    setWeight((weight / 2.2046226218488).toFixed(2));
                }

            }
        }
    }

    const takeUnit = (e) => {
        if (tunit === 'kg') {
            let htcm = (e.target.value);

            setWeight(htcm);


        }
        if (tunit === 'lbs') {
            let htin = (e.target.value);

            setWeight(htin);

        }

    }


    const changeunit2 = () => {
        if (height === 0) {
            if (tunit2 === 'cm') {
                setTunit2('in');

            }
            if (tunit2 === 'in') {
                setTunit2('cm');

            }
        } else if (height !== 0) {
            if (tunit2 === 'cm') {
                setTunit2('in');
                setPlaceholder1("Norm: 60 - 84");
                if (height.length !== 0) {

                    setHeight((height / 2.54).toFixed(2));
                }

            }
            if (tunit2 === 'in') {
                setTunit2('cm');
                setPlaceholder1("Norm: 152 - 213");
                if (height.length !== 0) {

                    setHeight((height * 2.54).toFixed(2));
                }

            }
        }
    }
    const takeunit2 = (e) => {
        if (tunit2 === 'cm') {
            setHeight(e.target.value);

        }
        if (tunit2 === 'in') {
            let ht = e.target.value;
            setHeight(ht);
        }
    }

    const changeunit3 = () => {
        if (hemoglobin2 === 0) {
            if (tunit3 === 'g/L') {
                setTunit3('g/dL');
                setPlacehldr3("Norm: 12- 17");

            }
            if (tunit3 === 'g/dL') {
                setTunit3('g/L');

            }
        } else if (hemoglobin2 !== 0) {
            if (tunit3 === 'g/L') {

                setTunit3('g/dL');
                setPlacehldr3("Norm: 12- 17");
                if (hemoglobin2.length !== 0) {

                    setHemoglobin2((hemoglobin2 / 10).toFixed(2));
                }

            }
            if (tunit3 === 'g/dL') {
                setPlacehldr3("Norm: 120- 170");
                setTunit3('g/L');
                if (hemoglobin2.length !== 0) {

                    setHemoglobin2((hemoglobin2 * 10).toFixed(2));
                }

            }
        }
    }
    const takeUnit3 = (e) => {
        if (tunit3 === 'g/L') {
            setHemoglobin2(e.target.value);



        }
        if (tunit3 === 'g/dL') {
            let ht = e.target.value;
            setHemoglobin2(ht);

        }
    }

    const validation1 = () => {
        if (tunit2 === 'cm') {
            if ((height <= 12) && (height != 0)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if ((height <= 228) && (height >= 214)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if ((height >= 229)) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(height)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }

        }

        if (tunit2 === 'in') {
            if (height !== 0) {
                if ((height < 48.0315) && (height > 0)) {
                    return <div className="validate">*Too low; please change to proceed.</div>

                }
                if ((height < 90.1575) && (height > 79.9213)) {
                    return <div className="validate">*Very High; double check.</div>

                }
                if (height > 89.7638) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(height)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
    }

    const validation2 = () => {
        if (tunit === 'kg') {
            if ((weight <= 272) && (weight >= 227) && (weight != 0)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (weight >= 273) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(weight)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }

        }


        if (tunit === 'lbs') {
            if ((weight < 55) && (weight != 0)) {
                return <div className="validate">*Too low; please change to proceed.</div>

            }
            if ((weight <= 600) && (weight >= 301)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (weight >= 601) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(weight)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    const validation5 = () => {
        if (tunit3 === 'g/L') {
            if ((hemoglobin2 < 5) && (hemoglobin2 != 0)) {
                return <div className="validate">*Too low; please change to proceed.</div>

            }
            if ((hemoglobin2 < 40) && (hemoglobin2 > 4)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if ((hemoglobin2 < 251) && (hemoglobin2 > 200)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (hemoglobin2 > 250) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(hemoglobin2)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }

        }
        if (tunit3 === 'g/dL') {
            if ((hemoglobin2 < 4) && (hemoglobin2 > 0)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if ((hemoglobin2 < 26) && (hemoglobin2 > 20)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (hemoglobin2 > 25) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(hemoglobin2)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }

    }

    const validation3 = () => {
        if ((sao < 40) && (sao > 0)) {
            return <div className="validate">*Too low; please change to proceed.</div>

        }

        if (sao > 100) {
            return <div className="validate">*Too high; please change to proceed.</div>

        }
        else if (isNaN(sao)) {
            return <div className="validate">*Please Enter valid-no.</div>

        }
    }
    const validation4 = () => {
        if ((svo < 40) && (svo > 0)) {
            return <div className="validate">*Very Low;double check.</div>

        }
        if ((svo < 101) && (svo > 80)) {
            return <div className="validate">*Very High;double check.</div>

        }
        if (svo > 100) {
            return <div className="validate">*Too high; please change to proceed.</div>

        }
        else if (isNaN(svo)) {
            return <div className="validate">*Please Enter valid-no.</div>

        }
    }
    const validation6 = () => {
        if ((heartRate < 20) && (heartRate > 0)) {
            return <div className="validate">*Very Low;double check.</div>

        }
        if ((heartRate < 301) && (heartRate > 200)) {
            return <div className="validate">*Very High;double check.</div>

        }
        if (heartRate > 300) {
            return <div className="validate">*Too high; please change to proceed.</div>

        }
        else if (isNaN(heartRate)) {
            return <div className="validate">*Please Enter valid-no.</div>

        }
    }

    return (<div className="rev">
        <h5 className="text-info pl-2">Cardiac Output (Fick’s Formula)</h5>
        <p className="pl-2">Calculates cardiac output, cardiac index, and stroke volume.</p>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Weight</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input type="number"

                    onChange={takeUnit}

                    className="ant-text-input" style={{ width: "79%" }} value={weight} /><span className="units" onClick={changeunit}>{tunit}&#8651;</span>
                <div>{validation2()}</div>

            </div>
        </div>


        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Patient height</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input style={{ width: "79%" }} type="number"
                    onChange={takeunit2}
                    placeholder={placehldr1}
                    className="ant-text-input"
                    value={height} /><span className="units" onClick={changeunit2}>{tunit2}&#8651;</span>
                <div>{validation1()}</div>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>SaO₂</strong></div>
                <div>As measured on ABG</div>
            </div>
            <div className="col-md-7 frm-field">
                <input style={{ width: "85%" }} type="number" className="ant-text-input " placeholder="Norm: 95 - 100" onChange={(e) => setSao(e.target.value)} /><span className="units ">%</span>
                <div>{validation3()}</div>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>SvO₂</strong></div>
                <div>As measured on mixed venous gas from PA catheter</div>
            </div>
            <div className="col-md-7 frm-field">
                <input type="number" style={{ width: "85%", height:40 }} className="ant-text-input " placeholder="Norm: 60 - 80" onChange={(e) => setSvo(e.target.value)} /><span style={{ height: 40 }} className="units ">%</span>
                <div>{validation4()}</div>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Hemoglobin</strong></div>


            </div>
            <div className="col-md-7 frm-field">
                <input
                    onChange={takeUnit3}
                    placeholder={placehldr3}
                    type="number"
                    className="ant-text-input fluidInput" value={hemoglobin2} style={{ height: '100%', width: "77%" }} /><span className="units unitBtn" style={{ height:  40 }} onClick={changeunit3} >{tunit3}&#8651;</span>

                <div>{validation5()}</div>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Heart rate</strong></div>

            </div>
            <div className="col-md-7 frm-field">
                <input style={{ width: "70%" }} type="number" className="ant-text-input " placeholder="Norm: 60 - 100" onChange={(e) => setHeartRate(e.target.value)} /><span className="units ">beats/min</span>
                <div>{validation6()}</div>
            </div>
        </div>
        <div className="fields row mb-4">
            <div className="col-md-6">
                <div className="pt-2"><strong>Age</strong></div>

            </div>
            <div className="col-md-6 btn-group-vertical options-btn  ">
                <button onClick={() => setAge(1)} className={age === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name "> &lt;70 years</span></button>
                <button onClick={() => setAge(2)} className={age === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name "> ≥70 years</span></button>

            </div>

        </div>

        <div className="stick">
            {
                (!isNaN(cardiacOutput) && !isNaN(cardiacIndex) && !isNaN(strokeVolume) && isFinite(strokeVolume)) && weight!=0 ?
                    <div className="apfel-score-result-container" >
                        <div className="point-result-container" >
                            <h3>{cardiacOutput} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>L/min</span></h3>
                            <div>Cardiac output</div>

                        </div>
                        <div className="point-result-container" >
                            <h3>{cardiacIndex} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>L/min/m²</span></h3>
                            <div>Cardiac index</div>
                        </div>
                        <div className="point-result-container" >
                            <h3>{strokeVolume} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}> mL/beat</span></h3>
                            <div>Stroke volume</div>
                        </div>

                    </div>
                    :
                    <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>
            }
        </div>
        <br />
        <div className="p-2" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/5416840/">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Lafarge CG, Miettinen OS. The estimation of oxygen consumption. Cardiovasc Res. 1970;4(1):23-30.</p>
                </div>
            </a>
        </div>
    </div>
    )
}
export default RE1196;