import React, { useEffect, useState, useContext } from 'react'
import "../css/last.css"
import { Link, useLocation } from 'react-router-dom'
import { API_ROOT } from '../constants'
import axios from 'axios'
import { useParams } from 'react-router-dom';
import { Card, Modal, Form } from 'react-bootstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css';
import AppContext from '../contextStore/DataProvider'
const Last = (props) => {
    const { paramset } = useParams()
    const [confirm_setconfirm_diagnosis, setconfirm_diagnosis] = useState([])
    const [differential_diagnosis, setdifferential_diagnosis] = useState([])
    const [Treatment, setTreatment] = useState([])
    const [Drugs, setDrugs] = useState([])
    const [crisis_Management, setCrisis_Management] = useState([])
    const [referenceData, setreferenceData] = useState([])
    const [value, setValue] = useState();
    const [display_cd, setDisplay_cd] = useState('false');
    const [display_dd, setDisplay_dd] = useState('false');
    const [display_tx, setDisplay_tx] = useState('false');
    const [display_drg, setDisplay_drg] = useState('false');
    const [display_cr, setDisplay_cr] = useState('false');
    const [display_ref, setDisplay_ref] = useState('false');
    const [selectedTiles, setselectedTiles] = useState("")
    const [referenceArray] = useState([])
    const [refOfPages, setrefOfPages] = useState([]);
    const context = useContext(AppContext)

    const [keywords, setKeywords] = useState([])
    const [show, setShow] = useState(false);
    const [largeImg, setLargeImg] = useState([]);
    const [showNav, setShowNav] = useState([]);

    const handleClose = () => setShow(false);
    const navigateToAllAction = () => {
        let pathtogo = window.location.pathname.split(':')[1]?.toUpperCase();
        context.changeActiveId(null);
        context.activeId = null;
        let parent = ''
        if(pathtogo == 'ASYSTOLE') {
            context.changeActiveId('A_1338');
            context.activeId = 'A_1338'
        }
        if(pathtogo == 'BRADYCARDIA-UNSTABLE') {
            context.changeActiveId('A_1339');
            context.activeId = 'A_1339'
        }
        if(pathtogo == 'SVT-STABLE') {
            context.changeActiveId('A_1342');
            context.activeId = 'A_1342'
        }
        if(pathtogo == 'SVT-UNSTABLE') {
            context.changeActiveId('A_1343');
            context.activeId = 'A_1343'
        }
        if(pathtogo == 'VT/VF-PULSELESS') {
            context.changeActiveId('A_1344');
            context.activeId = 'A_1344'
        }
        if(pathtogo == 'OBSTETRIC-ACLS & ATLS') {
            context.changeActiveId('A_1340');
            context.activeId = 'A_1340'
        }
        if(pathtogo == 'PEA') {
            context.changeActiveId('A_1341');
            context.activeId = 'A_1341'
        }
        if(pathtogo == 'HYPERTENSION') {
            context.changeActiveId('BD_1314');
            context.activeId = 'BD_1314'
        }
        if(pathtogo == 'HYPOTENSION') {
            context.changeActiveId('BD_1315');
            context.activeId = 'BD_1315'
        }
        if(pathtogo == 'BROADDIAGNOSIS') {
            context.changeActiveId('BD_1374');
            context.activeId = 'BD_1374'
        }
        if(pathtogo == 'CRITICALEVENTS') {
            context.changeActiveId('CE_1373');
            context.activeId = 'CE_1373'
        }
        if(pathtogo == 'AFE') {
            context.changeActiveId('CE_1317');
            context.activeId = 'CE_1317'
            parent = "CRITICAL EVENTS"
        }
        if(pathtogo == 'ANAPHYLAXIS') {
            context.changeActiveId('CE_1318');
            context.activeId = 'CE_1318'
        }
        if(pathtogo == 'LAST') {
            context.changeActiveId('CE_1321');
            context.activeId = 'CE_1321'
        }
        if(pathtogo == 'LOCALANESTHETICTOXICITY') {
            context.changeActiveId('CE_1321');
            context.activeId = 'CE_1321'
        }
        props.history.push({ pathname: "/actionLibrary", state:{
            name:pathtogo,
            parent_name: parent
            } })
    }
    const handleShow = (e) => {
        setShow(true);
        setLargeImg(e.target.src)
    };
    const [keys, setKeys] = useState([]);
    const window_width = window.innerWidth;
let location = useLocation();
    useEffect(() => {
        let path = window.location.pathname;
        let param_length = path.split(':').length;
        let params = window.location.pathname.split(':')[param_length - 1];
        params = params.replace(/%20/g, " ")
        let exp = params.replace(/~/g, "/")
        setValue(exp);
        let texts = ['ASYSTOLE', 'BRADYCARDIA - UNSTABLE', 'PEA', 'SVT - STABLE', 'SVT - UNSTABLE', 'VT/VF - PULSELESS', 'OBSTETRIC - ACLS & ATLS', 'PALS - COMING SOON', 'AFE', 'ANAPHYLAXIS', 'ASPIRATION', 'ASYSTOLE', 'BRADYCARDIA - UNSTABLE', 'BRONCHOSPASM', 'DELAYED EMERGENCE', 'DIFFICULT AIRWAY', 'ELEVATED AIRWAY PRESSURE', 'FIRE - AIRWAY', 'FIRE - PATIENT', 'HEMORRHAGE', 'HYPERKALEMIA', 'LARYNGOSPASM', 'LAST', 'MH', 'MI', 'OXYGEN FAILURE', 'PEA', 'POWER FAILURE', 'PNEUMOTHORAX', 'SVT - STABLE', 'SVT - UNSTABLE', 'TOTAL SPINAL', 'TRANSFUSION REACTIONS', 'VAE', 'VF/VF - PULSELESS', 'POST-PARTUM HEMORRHAGE', 'URGENT/EMERGENT C-SECTION', 'MATERNAL COLLAPSE', 'HYPERTENSIVE DISORDERS', 'ACLS & ATLS', 'NEWBORN RESUSCITATION', 'HYPERTENSION', 'HYPOTENSION', 'TACHYCARDIA', 'BRADYCARDIA', 'HYPOXEMIA', 'HYPOCAPNEA', 'HYPERCAPNEA']

        // let texts = ['PEA', 'ANAPHYLAXIS', 'ASPIRATION', 'ASYSTOLE','BRADYCARDIA - UNSTABLE','BRONCHOSPASM', 'DELAYED EMERGENCE', 'DIFFICULT AIRWAY','ELEVATED AIRWAY PRESSURE', 'HEMORRHAGE', 'LAST', 'OBSTETRIC - ACLS & ATLS', 'VF/VF - PULSELESS']
        //    let texts = []
        //    JSON.stringify(sessionStorage.getItem('subcrises')).forEach(f => {
        //     texts.push(f)
        //    });
        texts = [...new Set(texts)];
        setKeywords(texts);
        callApi();

    }, [value])


    const callApi = async () => {
        if (value) {

            try {

                const token = sessionStorage.getItem('token')
                const body = {
                    "name": value
                }
                const url = API_ROOT + `crises-data?token=${token}`
                const res = await axios.post(url, body, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                console.log(res.data)
                let x = [];
                if (res.data.status === "true") {
                    //  res.data.data
                    for (const key of Object.keys(res.data.data)) {
                        x.push(key)

                        if (key === "Confirm Diagnosis") {
                            setShowData(res.data.data[key].crises_sub_type)
                            setconfirm_diagnosis(res.data.data[key].crises_sub_type)
                            if (res.data.data[key].crises_sub_type.length !== 0) {
                                setDisplay_cd(true)
                            }
                        }
                        if (key === "Differential Diagnosis") {
                            setdifferential_diagnosis(res.data.data[key].crises_sub_type)
                            if (res.data.data[key].crises_sub_type.length !== 0) {
                                setDisplay_dd(true)

                            }
                        }
                        if (key === "Treatment") {
                            setTreatment(res.data.data[key].crises_sub_type)
                            if (res.data.data[key].crises_sub_type.length !== 0) {
                                setDisplay_tx(true)
                            }
                        }
                        if (key === "Drugs") {
                            setDrugs(res.data.data[key].crises_sub_type)
                            if (res.data.data[key].crises_sub_type.length !== 0) {
                                setDisplay_drg(true)
                            }

                        }

                        if (key === "Crisis Management") {
                            setCrisis_Management(res.data.data[key].crises_sub_type)
                            if (res.data.data[key].crises_sub_type.length !== 0) {
                                setDisplay_cr(true)
                            }
                        }
                        if (key === "Reference") {
                            res.data.data[key].crises_sub_type.map((data) => {
                                referenceArray.push(data)
                            })
                            setDisplay_ref(true)
                            //referenceArray.push(res.data.data[key].crises_sub_type)
                            res.data.data[key].crises_sub_type.map((data) => {
                                if (data.name === "img") {
                                    var array = data.image.split(",")
                                    setselectedTiles(array[0])
                                    setrefOfPages(array)

                                }
                            })

                        }
                    }
                    setKeys(x)

                }
            }
            catch (error) {
            }
        }
    }





    const [showData, setShowData] = useState([])
    const array = [
        {
            name: "Confirm Diagnosis",
            dn: "Dx",
            show: display_cd,
        },
        {
            name: "Differential Diagnosis",
            dn: "DDx",
            show: display_dd
        },
        {
            name: "Treatment",
            dn: "Tx",
            show: display_tx
        },
        {
            name: "Drugs",
            dn: "Drugs",
            show: display_drg
        },
        {
            name: "Crises Management",
            dn: "Crises",
            show: display_cr
        },
        {
            name: "Reference",
            dn: "Reference",
            show: display_ref
        },

    ]


    const handleData = (data) => {
        // alert(JSON.stringify(data))
        setShowNav(data.dn)
        if (data.name === "Confirm Diagnosis") {
            setShowData(confirm_setconfirm_diagnosis)
        }
        else if (data.name === "Differential Diagnosis") {

            setShowData(differential_diagnosis)
        }
        else if (data.name === "Treatment") {
            setShowData(Treatment)
        }
        else if (data.name === "Drugs") {

            setShowData(Drugs)
        }
        else if (data.name === "Crises Management") {

            setShowData(crisis_Management)
        }
        else if (data.name === "Reference") {
            setShowData(refOfPages)
        }
    }
    const transformContent = (content, keywords) => {
        let temp = content

        keywords.forEach(keyword2 => {

            temp = temp.replace(keyword2, wrapKeywordWithLink(keyword2, "/last:" + `${keyword2}`));

        })
        return temp
    }
    const clickonLink = (name) => {
        props.history.push({
            pathname: "/last",
            state: {
                name: name,
                sourceName: 'Action Library',
                returnPath: 'allaction'
            }
        })
    }
    //   onClick=${props.location.state.name=keyword}
    const wrapKeywordWithLink = (keyword, link) => {
        return `<a href=${link}>${keyword}</a>`
    }
    console.log(showData)
    console.log("Check", showNav)

    useEffect(() => {
        navigateToAllAction();
        if (keys.length > 0) {
            if (keys.includes("Confirm Diagnosis")) {
                setShowNav('Dx')
                setShowData(confirm_setconfirm_diagnosis)
            } else {
                setShowNav('DDx')
                setShowData(differential_diagnosis)
            }
        }
    }, [keys])

    return (
        <div>
            <div className="backNavigation text-center">
                <Link className="arrow" onClick={(e) => { e.preventDefault(); props.history.goBack() }}><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Action Library</span></span></Link> {value}
            </div>
            <h4>We are redirecting</h4>
            <div>
                <>
                    <div className="bar">
                        {
                            array.map((data, key) => {
                                return (
                                    <>
                                        {
                                            showNav === "" ?
                                                <>
                                                    <button key={key} className="normal-p btn " onClick={() => handleData(data)}>{ window_width >= 360 && window_width <= 428 ?<>{data?.dn}</>:<>{data?.name}</>}</button>
                                                </>
                                                :
                                                <>
                                                    {
                                                        showNav === data.dn ?
                                                            <>  {data.show === true ?
                                                                <button key={key} className="normal-p btn active-nav" onClick={() => handleData(data)}>{ window_width >= 360 && window_width <= 428 ?<>{data?.dn}</>:<>{data?.name}</>}</button> : <></>
                                                            }
                                                            </>
                                                            :
                                                            <>  {data.show === true ?
                                                                <button key={key} className="normal-p btn" onClick={() => handleData(data)}>{ window_width >= 360 && window_width <= 428 ?<>{data?.dn}</>:<>{data?.name}</>}</button> : <></>
                                                            }

                                                            </>
                                                    }
                                                </>
                                        }
                                    </>
                                )
                            })
                        }
                    </div>

                    <div className="data-shown">
                        {
                            showData.map((data, key) => {
                                if (showNav === "Reference") {
                                    return (
                                        <div className="ref-card-wrapper">
                                            <Tabs>
                                                <TabList>
                                                    <Tab>Links</Tab>
                                                    <Tab>Images</Tab>
                                                </TabList>
                                                <TabPanel>
                                                    {
                                                        referenceArray.length ?
                                                            <ul>
                                                                {
                                                                    referenceArray.map((data) => {
                                                                        if (data.name === "ref") {
                                                                            return (
                                                                                <li className="ref-card1" style={{ listStyleType: 'square', borderBottom: '1px solid silver' }}

                                                                                >
                                                                                    <Link to={data.reference} >
                                                                                        <label>{data.reference}</label>
                                                                                    </Link >
                                                                                </li>

                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </ul> : <></>
                                                    }</TabPanel>
                                                <TabPanel>
                                                    {
                                                        refOfPages.length ? <ul>
                                                            {refOfPages.map((d) => {
                                                                return (
                                                                    <li > <img style={{ width: "30%", height: "60%", alignSelf: 'center', boxShadow: '0 0 10px rgb(70, 68, 68)', marginBottom: 10 }} src={d} onClick={handleShow} /></li>

                                                                )
                                                            })
                                                            }
                                                        </ul> : <></>

                                                    }
                                                </TabPanel>
                                            </Tabs>

                                        </div>
                                    )
                                } else {
                                    return (
                                        <>
                                            <div style={{ fontWeight: 'bold' }} key={key}>{data.name}</div>
                                            <ul>
                                                {
                                                    data.crises_sub_type.map((data2, key) => {
                                                        return (
                                                            <>
                                                                <li key={key} style={{ listStyleType: 'square' }}>
                                                                    {data2.crises_sub_type.length !== 0 ?
                                                                        <div style={{ fontWeight: 600, marginTop: 20 }} dangerouslySetInnerHTML={{ __html: transformContent(data2.name, keywords) }}>
                                                                        </div> :
                                                                        <div dangerouslySetInnerHTML={{ __html: transformContent(data2.name, keywords) }}></div>
                                                                    }
                                                                    <ul>

                                                                        {
                                                                            data2.crises_sub_type ?
                                                                                <>
                                                                                    {
                                                                                        data2.crises_sub_type.map((data3, key) => {

                                                                                            return (
                                                                                                <> {
                                                                                                    data3.crises_sub_type.length !== 0 ?
                                                                                                        <li key={key} style={{ listStyleType: 'disc', fontWeight: 500 }}>{data3.name}</li> :
                                                                                                        <li key={key} style={{ listStyleType: 'disc' }} dangerouslySetInnerHTML={{ __html: transformContent(data3.name, keywords) }}></li>
                                                                                                }
                                                                                                    {
                                                                                                        data3.crises_sub_type ?
                                                                                                            <>

                                                                                                                <ul>
                                                                                                                    {

                                                                                                                        data3.crises_sub_type.map((data4, key) => {
                                                                                                                            return (
                                                                                                                                <>

                                                                                                                                    <li key={key} style={{ paddingLeft: 10, listStyleType: 'circle' }} dangerouslySetInnerHTML={{ __html: transformContent(data4.name, keywords) }}></li>


                                                                                                                                </>
                                                                                                                            )
                                                                                                                        })

                                                                                                                    }
                                                                                                                </ul>
                                                                                                            </> : <>No data to display</>
                                                                                                    }
                                                                                                </>
                                                                                            )

                                                                                        })
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <> No data to display
                                                                                </>
                                                                        }</ul>
                                                                </li>
                                                            </>

                                                        )
                                                    })
                                                }</ul>
                                        </>
                                    )
                                }
                            })
                        }
                    </div>
                </>

            </div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton style={{ background: '#FFF' }}>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Reference Image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={largeImg} width="100%" />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Last


