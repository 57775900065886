import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';
const EGRIAirwayCalculator = () => {
    const [mOpening, setmOpening] = useState(0);
    const [thyroMental, setThyroMental] = useState(0);
    const [patientWeight, setPatientWeight] = useState(0);
    const [mallClass, setMallClass] = useState(0);
    const [neckMovement, setNeckMovement] = useState(0);
    const [prognath, setPrognath] = useState(0);
    const [intubation, setIntubation] = useState(0);
    const [exchange_volume, setExchange_volume] = useState(0);
    const [scrollpos, setScrollPos] = useState({ bottom: 0 });
    const postData = async () => {
       // console.log(mallClass===3?parseInt(2):mallClass)
        setExchange_volume(parseInt(mOpening) + parseInt(thyroMental) + parseInt(patientWeight) + (mallClass===3?parseInt(2):parseInt(mallClass)) + parseInt(neckMovement) + parseInt(prognath) + parseInt(intubation));
    }
    useEffect(() => {
        postData();


    }, [mOpening, thyroMental, patientWeight, mallClass, neckMovement, prognath, intubation]);
   
    return (<div className="rev">
        <h5 className="text-info pl-2">El-Ganzouri Risk Index (EGRI) for Difficult Airway</h5>
        <p className="pl-2">Predicts risk of difficult airway.</p>
        <div className="fields row">
            <div className="col-md-4">
                <div className="pt-2"><strong>Mouth opening</strong></div>
            </div>
            <div className="col-md-8 btn-group text-left">
                <button style={{ height: 40 }} key="1" className={mOpening === 0 ? "btn active" : "btn"} onClick={() => setmOpening(0)}>≥4 cm <span className="text-right pv text-muted">0</span></button>
                <button style={{ height: 40 }} key="2" className={mOpening === 1 ? "btn active" : "btn"} onClick={() => setmOpening(1)}> &lt;4 cm <span className="text-right pv text-muted">+1</span></button>

            </div>
        </div>

        <div className="fields row">
            <div className="col-md-4">
                <div className="pt-2"><strong>Thyromental distance</strong></div>
            </div>
            <div className="col-md-8 btn-group-vertical options-btn">
                <button onClick={() => setThyroMental(0)} className={thyroMental === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">&lt;6.5 cm</span><span className="text-right pv text-muted">0</span></button>
                <button onClick={() => setThyroMental(1)} className={thyroMental === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">6.0-6.5 cm</span><span className="text-right pv text-muted">+1</span></button>
                <button onClick={() => setThyroMental(2)} className={thyroMental === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">&lt;6.0 cm</span><span className="text-right pv text-muted" >+2</span></button>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-4">
                <div className="pt-2"><strong>Modified Mallampati Classification</strong></div>
            </div>
            <div className="col-md-8 btn-group-vertical options-btn">
                <button onClick={() => setMallClass(0)} className={mallClass === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">I (soft palate, fauces, uvula, and pillars seen)</span><span className="text-right pv text-muted">0</span></button>
                <button onClick={() => setMallClass(1)} className={mallClass === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">II (soft palate, fauces, and uvula seen)</span><span className="text-right pv text-muted">+1</span></button>
                <button onClick={() => setMallClass(2)} className={mallClass === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">III (soft palate and base of uvula seen)</span><span className="text-right pv text-muted">+2</span></button>
                <button onClick={() => setMallClass(3)} className={mallClass === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">IV (soft palate not visible)</span><span className="text-right pv text-muted">+2</span></button>

            </div>
        </div>
        <div className="fields row">
            <div className="col-md-4">
                <div className="pt-2"><strong>Neck movement</strong></div>
            </div>
            <div className="col-md-8 btn-group text-left">
                <button onClick={() => setNeckMovement(0)} style={{ height: 40 }} className={neckMovement === 0 ? "btn active" : "btn "}>&lt;90° <span className="text-right pv text-muted">0</span></button>
                <button onClick={() => setNeckMovement(1)} style={{ height: 40 }} className={neckMovement === 1 ? "btn active" : "btn "}> 80-90° <span className="text-right pv text-muted">+1</span></button>
                <button onClick={() => setNeckMovement(2)} style={{ height: 40 }} className={neckMovement === 2 ? "btn active" : "btn "}> &lt;80° <span className="text-right pv text-muted">+2</span></button>

            </div>
        </div>
        <div className="fields row">
            <div className="col-md-4">
                <div className="pt-2"><strong>Ability to prognath (advance lower jaw forward)</strong></div>
            </div>
            <div className="col-md-8 btn-group text-left">
                <button style={{ height: 40, width: 80 }} className={prognath === 0 ? "btn active" : "btn "} onClick={() => setPrognath(0)}>Yes <span className="text-right pv text-muted">0</span></button>
                <button style={{ height: 40, width: 80 }} className={prognath === 1 ? "btn active" : "btn "} onClick={() => setPrognath(1)}>No <span className="text-right pv text-muted">+1</span></button>

            </div>
        </div>

        <div className="fields row">
            <div className="col-md-4">
                <div className="pt-2"><strong>Weight</strong></div>
            </div>
            <div className="col-md-8 btn-group-vertical options-btn">
                <button onClick={() => setPatientWeight(0)} className={patientWeight === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">&lt;90 kg (198.4 lbs)</span><span className="text-right pv text-muted">0</span></button>
                <button onClick={() => setPatientWeight(1)} className={patientWeight === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">90-110 kg (198.4-242.5 lbs)</span><span className="text-right pv text-muted">+1</span></button>
                <button onClick={() => setPatientWeight(2)} className={patientWeight === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">&lt;110 kg (242.5 lbs)</span><span className="text-right pv text-muted">+2</span></button>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-4">
                <div className="pt-2"><strong>History of difficult intubation</strong></div>
            </div>
            <div className="col-md-8 btn-group-vertical options-btn">
                <button onClick={() => setIntubation(0)} className={intubation === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">None</span><span className="text-right pv text-muted">0</span></button>
                <button onClick={() => setIntubation(1)} className={intubation === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Questionable</span><span className="text-right pv text-muted">+1</span></button>
                <button onClick={() => setIntubation(2)} className={intubation === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Definite</span><span className="text-right pv text-muted">+2</span></button>
            </div>
        </div>
        {/* <div className="pb-2">
            <button onClick={postData} className="save-button-container" style={{ margin: '0 auto' }}>Submit</button>
        </div> */}
         <div className="stick">

            <div className="apfel-score-result-container" style={scrollpos} >
                <div className="point-result-container" >
                    <h1>{exchange_volume} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    EGRI
                </div>
                <div className="percentage-result-container" >
                    <h1>{exchange_volume >= 4 ? "High" : "Low"}<span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}> risk</span></h1>
                    <div>Risk of difficult airway</div>
                    {exchange_volume >= 4 ? "93.8 % specificity" : ""}
                </div>
            </div>

        </div>
        <br />
        <div className="p-2 mb-5" >
            <h4>LITERATURE</h4>
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a href="https://pubmed.ncbi.nlm.nih.gov/8638791/" target="_blank" className="referenceLink">
            <div className="reference-container-card" >
                <div className="reference-img" >
                    <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                </div>
                <p className="reference-paragraph" >El-ganzouri AR, Mccarthy RJ, Tuman KJ, Tanck EN, Ivankovich AD. Preoperative airway assessment: predictive value of a multivariate risk index. Anesth Analg. 1996;82(6):1197-204.</p>
            </div>
            </a>
        </div>
    </div>
    )
}
export default EGRIAirwayCalculator;