import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';
const AnionGap = () => {
    const [green, setGreen] = useState(false);


    const [sodium, setSodium] = useState([]);
    const [tunit, setTunit] = useState('mmol/L');
    const [placehldr, setPlacehldr] = useState('Norm:136-145')


    const [chloride, setChloride] = useState([]);
    const [newChloride, setnewChloride] = useState(0);
    const [tunit1, setTunit1] = useState('mmol/L');
    const [placehldr1, setPlacehldr1] = useState('Norm:98-106')

    const [bicarbonate, setBicarbonate] = useState([]);
    const [newbicarbonate, setNewbicarbonate] = useState(0);
    const [tunit3, setTunit3] = useState('mmol/L');
    const [placehldr3, setPlacehldr3] = useState('Norm:23-28')


    const [albumin, setAibumin] = useState([]);
    const [newalbumin, setNewalbumin] = useState(0);
    const [tunit4, setTunit4] = useState('g/L');
    const [placehldr4, setPlacehldr4] = useState('Norm: 35-55 ');

    const [anionResult, setAnionResult] = useState(0);
    const [albuminCorrected, setAlbuminCorrected] = useState(0);
    const [deltaGap1, setDeltaGap1] = useState(0);
    const [deltaGap2, setDeltaGap2] = useState(0);
    const [deltaRatio1, setDeltaRatio1] = useState(0);
    const [deltaRatio2, setDeltaRatio2] = useState(0);
    const [unitconversions, setUnitconversions] = useState("");







    const postData = async () => {
        setGreen(true);
        if (tunit4 === 'g/L') {

            if (albumin < 71) {

                setNewalbumin((parseFloat(albumin / 10)).toFixed(2));
            }
            else {
                setNewalbumin(0);
            }

        }
        if (tunit4 === 'g/dL') {

            if (albumin <7.10) {

                setNewalbumin(parseInt(albumin).toFixed(2));
            }
            else {
                setNewalbumin(0);
            }
        }

        if (tunit1 === 'mmol/L') {
            if (chloride > 69 && chloride < 131) {
                setnewChloride(parseInt(chloride));
            }
            else {
                setnewChloride(0);

            }

        }
        if (tunit1 === 'mEq/L') {

            if (chloride > 69 && chloride < 131) {
                setnewChloride(parseInt(chloride * 1));
            }
            else {
                setnewChloride(0);

            }

        }
        if (tunit3 === 'mmol/L') {


            setNewbicarbonate(parseInt(bicarbonate));

        }
        if (tunit3 === 'mEq/L') {


            setNewbicarbonate(parseInt(bicarbonate * 1));
        }



        if (tunit === 'mmol/L' && tunit1 === 'mmol/L' && tunit3 === 'mmol/L') {
            setUnitconversions('mmol/L')
        }
        else {
            setUnitconversions('mEq/L')
        }


        if (bicarbonate < 101 && (sodium > 99 && sodium < 201)) {
            setAnionResult(sodium - (newChloride + newbicarbonate));
            setDeltaGap1((anionResult - 12).toFixed(1));
            setDeltaRatio1(deltaGap1 / (24 - newbicarbonate));

            setAlbuminCorrected((anionResult + (2.5 * (4 - newalbumin))).toFixed(1));
            setDeltaGap2((albuminCorrected - 12).toFixed(1));
            setDeltaRatio2((deltaGap2 / (24 - newbicarbonate)).toFixed(1));
        }
        else {
            setAnionResult(0);
            setDeltaGap1(0);
            setDeltaRatio1(0);

            setAlbuminCorrected(0);
            setDeltaGap2(0);
            setDeltaRatio2(0);
        }




    }
    useEffect(() => {


        postData();


    }, [newbicarbonate, sodium, newChloride, chloride, bicarbonate, albumin, tunit, tunit1, tunit3, tunit4, placehldr4, anionResult, albuminCorrected, newalbumin, deltaGap1, deltaGap2, deltaRatio1, deltaRatio2, newalbumin, unitconversions, anionResult]);
    const changeunit = () => {
        if (sodium === 0) {
            if (tunit === 'mmol/L') {
                setTunit('mEq/L');

            }
            if (tunit === 'mEq/L') {
                setTunit('mmol/L');

            }
        } else if (sodium !== 0) {
            if (tunit === 'mmol/L') {
                setPlacehldr("Norm: 136-145");
                setTunit('mEq/L');
                if (sodium.length !== 0) {
                    setSodium(Math.round(sodium * 1).toFixed(2))
                }
            }
            if (tunit === 'mEq/L') {
                setPlacehldr("Norm: 136-145");
                setTunit('mmol/L');
                if (sodium.length !== 0) {
                    setSodium(Math.round(sodium * 1))
                }
            }
        }
    }
    const takeunit = (e) => {
        if (tunit === 'mmol/L') {
            setSodium(e.target.value);
            setPlacehldr("Norm: 136-145");

        }
        if (tunit === 'mEq/L') {
            let ht = e.target.value;
            setSodium(ht);
            setPlacehldr("Norm:136-145");
        }
    }



    const changeunit1 = () => {
        if (chloride === 0) {
            if (tunit1 === 'mmol/L') {
                setTunit1('mEq/L');

            }
            if (tunit1 === 'mEq/L') {
                setTunit1('mmol/L');

            }
        } else if (chloride !== 0) {
            if (tunit1 === 'mmol/L') {
                setPlacehldr1("Norm: 98-106");
                setTunit1('mEq/L');
                if (chloride.length !== 0) {
                    setChloride(Math.round(chloride * 1).toFixed(2))
                }
            }
            if (tunit1 === 'mEq/L') {
                setPlacehldr1("Norm: 98-106");
                setTunit1('mmol/L');
                if (chloride.length !== 0) {
                    setChloride(Math.round(chloride * 1))
                }
            }
        }
    }
    const takeUnit1 = (e) => {
        if (tunit1 === 'mmol/L') {
            setChloride(e.target.value);
            setPlacehldr1("Norm: 98-106");

        }
        if (tunit1 === 'mEq/L') {
            let ht = e.target.value;
            setChloride(ht);
            setPlacehldr1("Norm:98-106");
        }
    }
    const changeunit3 = () => {
        if (bicarbonate === 0) {
            if (tunit3 === 'mmol/L') {
                setTunit3('mEq/L');

            }
            if (tunit3 === 'mEq/L') {
                setTunit3('mmol/L');

            }
        } else if (bicarbonate !== 0) {
            if (tunit3 === 'mmol/L') {
                setPlacehldr3("Norm: 23-28");
                setTunit3('mEq/L');
                if (bicarbonate.length !== 0) {
                    setBicarbonate(Math.round(bicarbonate * 1).toFixed(2))
                }
            }
            if (tunit3 === 'mEq/L') {
                setPlacehldr3("Norm: 23-28");
                setTunit3('mmol/L');
                if (bicarbonate.length !== 0) {
                    setBicarbonate(Math.round(bicarbonate * 1))
                }
            }
        }
    }
    const takeUnit3 = (e) => {
        if (tunit3 === 'mmol/L') {
            setBicarbonate(e.target.value);
            setPlacehldr3("Norm: 23-28");

        }
        if (tunit3 === 'mEq/L') {
            let ht = e.target.value;
            setBicarbonate(ht);
            setPlacehldr3("Norm:23-28");
        }
    }



    const changeunit4 = () => {
        if (albumin === 0) {
            if (tunit4 === 'g/L') {
                setTunit4('g/dL');
                setPlacehldr4("Norm: 3.5- 5.5");

            }
            if (tunit4 === 'g/dL') {
                setTunit4('g/L');

            }
        } else if (albumin !== 0) {
            if (tunit4 === 'g/L') {

                setTunit4('g/dL');
                setPlacehldr4("Norm: 3.5- 5.5");
                if (albumin.length !== 0) {

                    setAibumin((albumin / 10).toFixed(2));
                }

            }
            if (tunit4 === 'g/dL') {
                setPlacehldr4("Norm: 35-55");
                setTunit4('g/L');
                if (albumin.length !== 0) {

                    setAibumin((albumin * 10).toFixed(2));
                }

            }
        }
    }
    const takeunit4 = (e) => {
        if (tunit4 === 'g/L') {
            setAibumin(e.target.value);


        }
        if (tunit4 === 'g/dL') {
            let ht = e.target.value;
            setAibumin(ht);

        }
    }

    const validation3 = () => {
        if ((bicarbonate < 101) && (bicarbonate > 60)) {
            return <div className="validate">*Very High;double check.</div>

        }

        if (bicarbonate > 100) {
            return <div className="validate" >Too high; please change to proceed.</div>

        }
        if (isNaN(bicarbonate)) {
            return <div className="validate">*Please Enter valid-no.</div>

        }

    }


    const valnumber1 = () => {

        if (sodium !== 0) {
            if ((sodium < 100) && (sodium > 0)) {
                return <div className="validate">*Too low; please change to proceed.</div>

            }
            if ((sodium < 201) && (sodium > 170)) {
                return <div className="validate">*Very High; double check.</div>

            }
            if (sodium > 200) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(sodium)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }

        }

    }

    const validation2 = () => {


        if ((chloride < 70) && (chloride > 0)) {
            return <div className="validate">*Too low; please change to proceed.</div>

        }
        if ((chloride < 131) && (chloride > 120)) {
            return <div className="validate">*Very High; double check.</div>

        }
        if (chloride > 130) {
            return <div className="validate">*Too high; please change to proceed.</div>

        }
        else if (isNaN(chloride)) {
            return <div className="validate">*Please Enter valid-no.</div>

        }

    }

    const validation4 = () => {

        if (tunit4 === 'g/L') {
            if (albumin != 0) {
                if ((albumin < 10) && (albumin > 0)) {
                    return <div className="validate">*Very low; double-check.</div>

                }
                if ((albumin < 71) && (albumin > 60)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (albumin > 70) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(albumin)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
        if (tunit4 === 'g/dL') {
            if (albumin !== 0) {
                if ((albumin < 0.90) && (albumin > 0)) {
                    return <div className="validate">*Very low; double-check..</div>

                }
                if ((albumin < 7.10) && (albumin > 6.00)) {
                    return <div className="validate">*Very High; double check.</div>

                }
                if (albumin > 7.00) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(albumin)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }

    }





    return (
        <div className="rev">
            <h5 className="text-info pl-2">Anion Gap</h5>
            <p className="pl-2">Evaluates states of metabolic acidosis.</p>
            <div className="fields row">
                <div className="col-md-5 col-sm-12">
                    <div className="pt-2"><strong>Sodium</strong></div>
                </div>
                <div className="col-md-7 col-sm-12 frm-field">
                    <input type="number"
                        onChange={takeunit}
                        placeholder={placehldr}


                        className="ant-text-input" style={{ width: "65%" }} value={sodium} /><span className="units" onClick={changeunit}>{tunit}&#8651;</span>
                    <div>{valnumber1()}</div>
                </div>
            </div>

            <div className="fields row">
                <div className="col-md-5 col-sm-12">
                    <div className="pt-2"><strong>Chloride</strong></div>
                </div>
                <div className="col-md-7 col-sm-12 frm-field">
                    <input type="number"
                        onChange={takeUnit1}
                        placeholder={placehldr1}


                        className="ant-text-input" style={{ width: "65%" }} value={chloride} /><span className="units" onClick={changeunit1}>{tunit1}&#8651;</span>
                    <div>{validation2()}</div>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5 col-sm-12">
                    <div className="pt-2"><strong>Bicarbonate</strong></div>
                </div>
                <div className="col-md-7 col-sm-12 frm-field">
                    <input type="number"
                        onChange={takeUnit3}
                        placeholder={placehldr3}


                        className="ant-text-input" style={{ width: "65%" }} value={bicarbonate} /><span className="units" onClick={changeunit3}>{tunit3}&#8651;</span>
                    <div>{validation3()}</div>
                </div>
            </div>



            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Albumin</strong></div>

                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        onChange={takeunit4}
                        placeholder={placehldr4}

                        className="ant-text-input fluidInput" value={albumin} style={{ height: '100%', width: '75%' }} /><span className="units unitBtn" style={{ height:  40 }} onClick={changeunit4} >{tunit4}&#8651;</span>

                    <div>{validation4()}</div>
                </div>
            </div>
            <br />
            <div>
                {
                    !isNaN(anionResult) && !isNaN(albuminCorrected) && anionResult!=0 ?
                        <div className="apfel-score-result-container" >
                            <div className="point-result-container" >
                                <h1>{anionResult} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>{unitconversions}</span></h1>
                                <div>Anion gap</div>
                                <div className="small">Delta gap: {deltaGap1} mEq/L</div>
                                <div className="small">Delta ratio: {deltaRatio1}; suggests high anion gap acidosis with concurrent metabolic alkalosis (or compensated respiratory acidosis)</div>


                            </div>
                            <div className="point-result-container" >
                                <h1>{albuminCorrected} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>{unitconversions}</span></h1>
                                <div>Albumin corrected anion gap; suggests non-anion gap acidosis.</div>
                                <div className="small">Albumin corrected delta gap: {deltaGap2}  mEq/L</div>
                                <div className="small">Albumin corrected delta ratio: {deltaRatio2}</div>


                            </div>

                        </div>
                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <br />
            <div className="p-2 mb-5" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/895822/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Oh MS, Carroll HJ. The anion gap. N. Engl. J. Med. 1977; 297 (15): 814–7.doi:10.1056/NEJM197710132971507. PMID 895822.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}
export default AnionGap;