import React, { useEffect, useState } from 'react';
import './RE1226.css';

const RE1226 = () => {
    const [green, setGreen] = useState([]);
    const [mateenal, setMaternal] = useState(0);
    const [fetalCell, setFetalCell] = useState(0);
    const [result3, setResult3] = useState([]);
    const [result4,setResult4] = useState(500000);
    const postData = async () => {

        setResult3(parseInt(((mateenal*(fetalCell/100))/30).toFixed(0)));
        setResult4(parseInt(result3+1))
       

        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [mateenal,fetalCell,result3,result4]);

    const valnumber1 = () => {
        if (mateenal != 0) {
            if ((mateenal < 1000) && (mateenal >= 1)) {
                return <div className="validate">Very low; double-check.</div>

            }
            if ((mateenal < 10001) && (mateenal >= 8001)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (mateenal >= 10001) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(mateenal)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    const valnumber2 = () => {
        if (fetalCell != 0) {
            if (fetalCell >= 11) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(fetalCell)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    return (
        <div className="rev">
            <h5 className="text-info pl-2">Maternal-Fetal Hemorrhage Rh(D) Immune Globulin Dosage</h5>
            <p className="pl-2">Calculates amount of RhIG to be administered to the mother, at the time of delivery, to prevent hemolytic disease of the fetus and newborn (HDFN).</p>
            <div className="fields row">
                <div className="col-md-5 col-sm-12">
                    <div className="pt-2"><strong>Maternal blood volume</strong></div>
                </div>
                <div className="col-md-7 col-sm-12 frm-field">
                    <input type="number"
                        placeholder='Norm:2500-6000' className="ant-text-input" onChange={(e) => setMaternal(e.target.value)} style={{ width: '77%', height: 50 }} /><span style={{ height: 50 }} className="units" >mL</span>
                          <div>{valnumber1()}</div>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5 col-sm-12">
                    <div className="pt-2"><strong>Fetal cells in mother
                    </strong></div>
                </div>
                <div className="col-md-7 col-sm-12 frm-field">
                    <input type="number"
                        placeholder='Norm:0 - 0' className="ant-text-input" onChange={(e) => setFetalCell(e.target.value)} style={{ width: '77%', height: 50 }} /><span style={{ height: 50 }} className="units" >%</span>
                          <div>{valnumber2()}</div>
                </div>
            </div>
            <div className="stick pt-3">
                {
                    mateenal !=0 && fetalCell != "" ? <>
                        <div className="apfel-score-result-container col-12 " >
                            <div className="point-result-container col-12" >
                                <h1>{result4} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>vial</span></h1>
                                <div>RhIG to give (assumes each vial is 30 mL).</div>
                            </div>

                        </div>
                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <div className="p-2 mb-4" >
                <div>
                    ORIGINAL/PRIMARY REFERENCE
                </div>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Kennedy, M, Delaney M, Scrape S. Perinatal issues in transfusion practice. In: Fung M, Grossman B, Hillyer C, Westhoff C, eds. Technical Manual.18th ed. Bethesda, MD: AABB; 2014:561–569..</p>
                    </div>
                </a>
            </div>

        </div>
    )
}

export default RE1226