import React, { useState, useEffect, useContext } from 'react'
import Header from '../CustomComponent/Header'
import Tab from '../CustomComponent/Tab'
import '../css/index.css'
import '../css/Favourite.css'
import { Link } from 'react-router-dom'
import BurgerMenuModal from '../CustomComponent/BurgerMenuModal'
import { API_ROOT } from '../constants'
import { useDispatch, useSelector } from 'react-redux'
import { setActionLibraryData } from '../redux/ActionsSummaryActions';
import { setDrugData, setLevel1, setLevel2 } from '../redux/FavouriteActions';
import Spinner from '../CustomComponent/Spinner'
import Alert from '../CustomComponent/Alert';
import { useHistory } from "react-router-dom";
import AppContext from '../contextStore/DataProvider'

const Favorites = (props) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const context = useContext(AppContext)
    const favouriteArray = useSelector(state => state.favourite.favourites)
    const actionSummaryType = useSelector(state => state.favourite.actionSummaryType)
    const categorySelected = useSelector(state => state.favourite.categorySelected)
    const drugData = useSelector(state => state.favourite.drugData);
    const [burgerMenu, setburgerMenu] = useState(false)
    const [favouriteData, setfavouriteData] = useState([])
    const [airway_and_equipments, setairway_and_equipments] = useState([])
    const [case_tips, setcase_tips] = useState([])
    const [crises, setcrises] = useState([])
    const [drugs, setdrugs] = useState([])
    const [preoperative_evaluations, setpreoperative_evaluations] = useState([])
    const [regional_and_n_anesthesias, setregional_and_n_anesthesias] = useState([])
    const [checklists, setChecklists] = useState([])
    const [localanesthesia, setLocalanesthesia] = useState([])
    const [fluids, setFluid] = useState([])
    const [calculations_and_rs, setCalculations_and_rs] = useState([])
    const [value, setValue] = useState({});
    const [dData, setDData] = useState([]);
    const [dcat, setDcat] = useState('');
    const [drgList, setDrgList] = useState([]);
    const [level1, setLevel1] = useState([]);
    const [level2, setLevel2] = useState([]);
    const [alertVisible, setAlertVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    //const level1 = useSelector(state => state.favourite.level1);
    //const level2 = useSelector(state => state.favourite.level2);


    useEffect(() => {
        //if (props.location.state !== "undefined") {
        getFavourite();


        getDrugLibrary();


        // }
    }, [

    ])

    const getDrugLibrary = () => {
        var token = sessionStorage.getItem("token")
        fetch(API_ROOT + `action-library-data?token=${token}`, {
            method: 'GET',

            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(res => {
                const drg = [];
                const antibiotic_level1 = [];
                const antibiotic_level2 = [];
                const tempd1 = [];
                const tempd2 = [];
                if (res.status === "true") {
                    let dataplus = res.data.drugs;
                    dataplus.forEach(element => {
                        if (element.name === "ANTIBIOTICS") {
                            element?.children.forEach(ob => {
                                if (ob.name === 'ANTIBIOTIC CLASS') {
                                    ob?.children.forEach(sb => {
                                        antibiotic_level1.push(
                                            {
                                                name: sb.name,
                                                id: sb.id,
                                                category: 'Drugs'
                                            }
                                        )
                                    });
                                    antibiotic_level1.forEach(d => {
                                        drugData.forEach(c => {

                                            if (d.name === c.name) {
                                                tempd1.push(d)
                                            }
                                        })
                                    })
                                    setLevel1(antibiotic_level1);

                                }
                                if (ob.name === 'ANTIBIOTICS BY SURGERY TYPE') {
                                    ob?.children.forEach(sb => {
                                        antibiotic_level2.push(
                                            {
                                                name: sb.name,
                                                id: sb.id,
                                                sub_type: sb.sub_type,
                                                category: 'Drugs',
                                                drugInfo: sb.children
                                            }
                                        )
                                    });
                                    antibiotic_level2.forEach(d => {
                                        drugData.forEach(c => {
                                            if (c.name === d.name) {
                                                tempd2.push(d)
                                            }
                                        })
                                    })
                                    setLevel2(antibiotic_level2)
                                }
                            })
                        }

                    })

                    // setDrugs(drg);


                    let restOfDrugs = []
                    restOfDrugs = [...tempd1, ...tempd2];



                    //let result2 = dataplus.filter(obj=> obj.drug_name===restOfDrugs.name).length > 0

                    //listedDrugs = drugs;
                    let result2 = dataplus.filter(drug => restOfDrugs.every(drug2 => !drug2.name.includes(drug.name)))
                    let drgremaining = [];
                    result2.forEach(ar => {
                        drgremaining.push({
                            name: ar.name,
                            id: ar.id
                        })
                    })
                    setDrgList(drgremaining)
                    // dispatch(setActionLibraryData(obj));

                    //setactionLibraryData(obj)


                }

            });
    }






    // const saveFavouriteList = () => {
    //     let airwayandequipmentsForRequest = []
    //     airway_and_equipments.map((data) => {
    //         let obj = {
    //             id: ''
    //         }

    //         obj.id = data.id
    //         airwayandequipmentsForRequest.push(obj)
    //     })

    //     let casetipsForRequest = []
    //     case_tips.map((data) => {
    //         let obj = {
    //             id: ''
    //         }

    //         obj.id = data.id
    //         casetipsForRequest.push(obj)
    //     })

    //     let crisesForRequest = []
    //     crises.map((data) => {
    //         let obj = {
    //             id: ''
    //         }

    //         obj.id = data.id
    //         crisesForRequest.push(obj)
    //     })

    //     let drugsForRequest = []
    //     drugs.map((data) => {
    //         let obj = {
    //             id: ''
    //         }

    //         obj.id = data.id
    //         drugsForRequest.push(obj)
    //     })

    //     let preoperative_evaluationForRequest = []
    //     preoperative_evaluations.map((data) => {
    //         let obj = {
    //             id: ''
    //         }

    //         obj.id = data.id
    //         preoperative_evaluationForRequest.push(obj)
    //     })

    //     let regional_and_neuraxial_anesthesiaForRequest = []
    //     regional_and_n_anesthesias.map((data) => {
    //         let obj = {
    //             id: ''
    //         }

    //         obj.id = data.id
    //         regional_and_neuraxial_anesthesiaForRequest.push(obj)
    //     })
    //     let checklistsForRequest = []
    //     checklists.map((data) => {
    //         let obj = {
    //             id: ''
    //         }

    //         obj.id = data.id
    //         checklistsForRequest.push(obj)
    //     })
    //     let localAnesthesiaForRequest = []
    //     localanesthesia.map((data) => {
    //         let obj = {
    //             id: ''
    //         }

    //         obj.id = data.id
    //         localAnesthesiaForRequest.push(obj)
    //     })
    //     let fluidnTransForRequest = []
    //     fluids.map((data) => {
    //         let obj = {
    //             id: ''
    //         }

    //         obj.id = data.id
    //         fluidnTransForRequest.push(obj)
    //     })
    //     let calculationForRequest = []
    //     calculations_and_rs.map((data) => {
    //         let obj = {
    //             id: ''
    //         }

    //         obj.id = data.id
    //         calculationForRequest.push(obj)
    //     })
    //     var token = sessionStorage.getItem("token")
    //     const body= JSON.stringify({
    //         crises: crisesForRequest,
    //         case_tips:casetipsForRequest,
    //         airway_and_equipments: airwayandequipmentsForRequest,
    //         drugs: drugsForRequest,
    //         preoperative_evaluation: preoperative_evaluationForRequest,
    //         regional_and_neuraxial_anesthesia: regional_and_neuraxial_anesthesiaForRequest,
    //         fluid_transfusions:fluidnTransForRequest,
    //         checklists:checklistsForRequest,
    //         calculations_and_rs:calculationForRequest,
    //     })

    //         fetch(API_ROOT + `save-user-favourites?token=${token}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: body
    //         })
    //             .then(response => response.json())
    //             .then(res => {

    //                 
    //                 if (res.status === "true") {
    //                     alert(res.message)
    //                 }
    //             })





    // }


    const deleteFavourite = async (array, itemId) => {
        const confirmBox = window.confirm(
            "Do you really want to remove favorite item?"
        )
        if (confirmBox === true) {
            var case_tips = []
            var airway_and_equipments = []
            var crises = []
            var drugs = []
            var preoperative_evaluation = []
            var regional_and_neuraxial_anesthesia = []
            var checklists = []
            var local_anesthesia = []
            var fluids = []
            var calcs_and_rs = []

            if (array === "Case Tips") {
                case_tips.push(itemId)
            }
            if (array === "Airway & Equipments") {
                airway_and_equipments.push(itemId)
            }
            if (array === "Crises") {
                crises.push(itemId)
            }
            if (array === "Drugs") {
                drugs.push(itemId)
            }
            if (array === "Preoperative Evaluation") {
                preoperative_evaluation.push(itemId)
            }

            if (array === "Checklists") {
                checklists.push(itemId)
            }

            if (array === "Regional Anesthesia") {
                regional_and_neuraxial_anesthesia.push(itemId)
            }

            if (array === "Local Anesthesia") {
                local_anesthesia.push(itemId)
            }

            if (array === "Fluids And Transfusions") {
                fluids.push(itemId)
            }

            if (array === "Calculations And Scores") {
                calcs_and_rs.push(itemId)
            }
            const token = await sessionStorage.getItem('token');
            fetch(API_ROOT + `delete-user-favourites?token=${token}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    airway_and_equipments: airway_and_equipments,
                    case_tips: case_tips,
                    crises: crises,
                    drugs: drugs,
                    preoperative_evaluation: preoperative_evaluation,
                    regional_and_neuraxial_anesthesia: regional_and_neuraxial_anesthesia,
                    checklists: checklists,
                    localanesthesia: local_anesthesia,
                    fluid_transfusions: fluids,
                    calculations_and_rs: calcs_and_rs
                })

            })
                .then(response => response.json())
                .then(res => {
                    if (res.status === "true" && res.message === "User favourites deleted") {
                        window.location.reload();
                        alert('Favorite item deleted successfully');
                        getFavourite()
                    } else {
                        alert(JSON.stringify(res.data))
                    }
                })
        } else {
            return false
        }
    }


    // const addnewData = () => {

    //     var tp =[...new Set(categorySelected)]
    //     for(var i=0;i<tp.length;i++){

    //     if (actionSummaryType === "favourite") {
    //         if (tp[i] === "Crises") {
    //             favouriteArray.map((data) => {

    //                 if(data.parentName==="Crises"){
    //                 let obj = {
    //                     id: '',
    //                     name: ''
    //                 }
    //                 obj.id = data.id
    //                 obj.name = data.name
    //                 crises.push(obj)
    //             }
    //             })
    //         } else if (tp[i] === "Case Tips") {
    //             favouriteArray.map((data) => {
    //                 let obj = {
    //                     id: '',
    //                     name: ''
    //                 }
    //                 obj.id = data.id
    //                 obj.name = data.name
    //                 case_tips.push(obj)
    //             })
    //         } else if (tp[i] === "Airway & Equipments") {
    //             favouriteArray.map((data) => {
    //                 if(data.parentName==="Airway & Equipments"){
    //                 let obj = {
    //                     id: '',
    //                     name: ''
    //                 }
    //                 obj.id = data.id
    //                 obj.name = data.name
    //                 airway_and_equipments.push(obj)
    //             }
    //             })

    //         } else if (tp[i] === "Drugs") {

    //             favouriteArray.map((data) => {
    //                 if(data.parentName==="Drugs"){
    //                 let obj = {
    //                     id: '',
    //                     drug_name: ''
    //                 }
    //                 obj.id = data.id
    //                 obj.drug_name = data.name
    //                 drugs.push(obj)
    //             }
    //             })
    //         }
    //         else if (tp === "Preoperative Evaluation") {
    //             favouriteArray.map((data) => {
    //                 let obj = {
    //                     id: '',
    //                     name: ''
    //                 }
    //                 obj.id = data.id
    //                 obj.name = data.name
    //                 preoperative_evaluations.push(obj)
    //             })

    //         }
    //         else if (tp[i] === "Regional Anesthesia") {
    //             favouriteArray.map((data) => {
    //                 let obj = {
    //                     id: '',
    //                     name: ''
    //                 }
    //                 obj.id = regional_and_n_anesthesias.length + 1
    //                 obj.name = data.name
    //                 regional_and_n_anesthesias.push(obj)
    //             })


    //         }
    //         else if (tp[i] === "Checklists") {
    //             favouriteArray.map((data) => {
    //                 let obj = {
    //                     id: '',
    //                     name: ''
    //                 }
    //                 obj.id = checklists.length + 1
    //                 obj.name = data.name
    //                 checklists.push(obj)
    //             })


    //         }
    //         else if (tp[i] === "Local Anesthesia") {
    //             favouriteArray.map((data) => {
    //                 let obj = {
    //                     id: '',
    //                     name: ''
    //                 }
    //                 obj.id = localanesthesia.length + 1
    //                 obj.name = data.name
    //                 localanesthesia.push(obj)
    //             })


    //         }

    //         else if (tp[i] === "Calculations And Scores") {
    //             favouriteArray.map((data) => {
    //                 let obj = {
    //                     id: '',
    //                     name: ''
    //                 }
    //                 obj.id = calculations_and_rs.length + 1
    //                 obj.name = data.name
    //                 calculations_and_rs.push(obj)
    //             })


    //         }

    //         else if (tp[i] === "Fluids And Transfusions") {
    //             favouriteArray.map((data) => {
    //                 if(data.parentName==="Fluids And Transfusions"){
    //                 let obj = {
    //                     id: '',
    //                     name: ''
    //                 }
    //                 obj.id = data.id
    //                 obj.name = data.name
    //                 fluids.push(obj)
    //             }
    //             })


    //         }
    //         setValue({})
    //     }
    // }

    // }

    const getFavourite = async () => {
        var token = sessionStorage.getItem('token')
        fetch(API_ROOT + `get-user-favourites?token=${token}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(res => {
                setLoading(true)
                if (res.status === "true") { // && res.message === "User favourites data"

                    setDData(res?.drugs.filter((d) => { return d.user_fav === "1" }).sort(SortArray1));
                    setairway_and_equipments(res?.airway_and_equipments.filter((d) => { return d.user_fav === "1" }).sort(SortArray))
                    setcase_tips(res.case_tips.filter((d) => { return d.user_fav === "1" }))
                    setcrises(res?.crises.filter((d) => { return d.user_fav === "1" }).sort(SortArray))
                    setdrugs(res?.drugs.filter((d) => { return d.user_fav === "1" }).sort(SortArray1))
                    dispatch(setDrugData(res.drugs.filter((d) => { return d.user_fav === "1" }).sort(SortArray1)))
                    setpreoperative_evaluations(res.preoperative_evaluations.filter((d) => { return d.user_fav === "1" }))
                    setregional_and_n_anesthesias(res.regional_and_n_anesthesias.filter((d) => { return d.user_fav === "1" }))
                    setChecklists(res.checklists.filter((d) => { return d.user_fav === "1" }))
                    // setLocalanesthesia(res.drugs)
                    setFluid(res?.fluid_transfusions.filter((d) => { return d.user_fav === "1" }).sort(SortArray))
                    setCalculations_and_rs(res?.calculations_and_rs.filter((d) => { return d.user_fav === "1" }).sort(SortArray))
                } else {
                    // alert(res.message)
                    setAlertVisible(true);
                    setMessage(res.message);
                    setLoading(true)
                }
            })
        function SortArray(x, y) {
            return x.name.localeCompare(y.name);
        }
        function SortArray1(x, y) {
            return x.name.localeCompare(y.name);
        }

    }
    const closeAlert = () => {
        setAlertVisible(false);
        props.history.push({
            pathname: '/login'
        })
    }
    const favClick = (name, category, id, drugInfo) => {
        level2.forEach((lv) => {
            if (id === lv.id) {
                drugInfo = lv?.drugInfo;
            }
        })

        if (category === 'crises') {
            name = name.replace('/', '~')
            props.history.push({
                pathname: "/last" + ":" + name,
                state: {
                    name: name,
                    categoryName: category,
                    sourceName: 'Favorites',
                    returnPath: 'favourite',
                    drugInformation: drugInfo
                }

            });
        }
        if (category === 'preoperative') {
            name = name.replace('/', '~')
            props.history.push({
                pathname: '/preoperativeevaluation',
                state: {
                    name: name,
                    categoryName: category,
                    sourceName: 'Favorites',
                    returnPath: 'favourite',
                }

            });
        }
        if (category === 'riskevalution') {
            name = name.replace('/', '~')
            props.history.push({
                pathname: '/riskevalution',
                state: {
                    name: name,
                    categoryName: category,
                    sourceName: 'Favorites',
                    returnPath: 'favourite',
                }

            });
        }
        if (category === 'regionalanesthesia') {
            var token = sessionStorage.getItem("token")

            fetch(API_ROOT + `get-specific-block-links?token=${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "block_id": id
                })
            })
                .then(response => response.json())
                .then(res => {
                    if (res.status === "true") {
                        props.history.push({
                            pathname: "/subRegionalAnesthesia",
                            state: {
                                data: res.data,
                                name: name,
                                categoryName: category,
                                sourceName: 'Favorites',
                                returnPath: 'favourite'
                            }
                        })
                    }
                })

        }
        if (category === 'airwayscalculator') {
            let index = 0;
            if (name === 'Airway Equipment Compatibility') {
                index = 1;
            }
            if (name === 'EGRI') {
                index = 2;
            }
            if (name === 'ETT Depth (Adult) and Tidal Volume') {
                index = 3;
            }
            if (name === 'ETT Depth (Pediatric)') {
                index = 4;
            }
            if (name === 'Horowitz Index') {
                index = 5;
            }
            if (name === 'Modified Mallampati Classification') {
                index = 6;
            }
            if (name === 'ROX Index') {
                index = 7;
            }
            if (name === 'RSBI') {
                index = 8;
            }
            if (name === 'STOP-BANG') {
                index = 9;
            }
            if (name === 'Upper Lip Bite Test') {
                index = 10;
            }
            props.history.push({
                pathname: "/airwayequipmentcalculators",
                state: {
                    index: index,
                    categoryName: category,
                    sourceName: 'Favorites',
                    returnPath: 'favourite'
                }

            });
        }
        if (category === 'fluids') {
            let index = 0;
            if (name === 'ABC Score for Massive Transfusion') {
                index = 1;
            }
            if (name === 'Blood Volume Calculation') {
                index = 2;
            }
            if (name === 'FENa') {
                index = 3;
            }
            if (name === 'FEUrea') {
                index = 4;
            }
            if (name === 'Intraoperative Fluid Dosing in Adult Patients') {
                index = 5;
            }
            if (name === 'Intrauterine RBC Transfusion Dosage') {
                index = 6;
            }
            if (name === 'Maximum allowable blood loss without transfusion') {
                index = 7;
            }
            if (name === 'Plasma Volume') {
                index = 8;
            }
            if (name === 'PORT score') {
                index = 9;
            }
            if (name === 'RIFLE Criteria for AKI') {
                index = 10;
            }
            if (name === 'Serum Osmolality / Osmolarity') {
                index = 11;
            }
            if (name === 'Sickle Cell RBC Exchange Volume') {
                index = 12;
            }
            if (name === 'Blood products and expected results') {
                index = 13;
            }
            props.history.push({
                pathname: "/fluidstransfusionscalculators",
                state: {
                    index: index,
                    categoryName: category,
                    sourceName: 'Favorites',
                    returnPath: 'favourite'
                }

            });
        }
        if (category === 'drugs') {

            if (level1.filter(obj => obj.name === name).length > 0) {

                props.history.push({
                    pathname: "/resultOfAction",
                    state: {
                        index: level1.findIndex(dr => dr.name === name),
                        subName: name,
                        subId: id,
                        parentName: 'Drugs',
                        subParent: "ANTIBIOTIC CLASS",
                        categoryName: category,
                        sourceName: 'Favorites',
                        returnPath: 'favourite',
                        drugsonFly: level1
                    }

                });
            }
            if (level2.filter(obj => obj.name === name).length > 0) {

                props.history.push({
                    pathname: "/druginfo",
                    state: {
                        index: 0,
                        name: name,
                        subId: id,
                        drugInformation: drugInfo,
                        categoryName: category,
                        sourceName: 'Favorites',
                        returnPath: 'favourite',
                    }

                });
            } if (drgList.filter(obj => obj.name === name).length > 0) {
                props.history.push({
                    pathname: "/resultOfAction",
                    state: {
                        index: drgList.findIndex(dr => dr.name === name),
                        parentName: 'Drugs',
                        subName: name,
                        subId: id,
                        categoryName: category,
                        sourceName: 'Favorites',
                        returnPath: 'favourite',
                        drugsonFly: drgList
                    }

                });
            }
        }
        if (category === 'calculationsandscores') {
            if (name === "HEMATOLOGIC") {
                props.history.push({
                    pathname: "/hematologiccalculators",
                    state: {
                        categoryName: category,
                        sourceName: 'Favorites',
                        returnPath: 'favourite'
                    }

                });
            }
            if (name === "CARDIOVASCULAR") {
                props.history.push({
                    pathname: "/cardiovascularcalculators",
                    state: {
                        categoryName: category,
                        sourceName: 'Favorites',
                        returnPath: 'favourite'
                    }

                });
            }

            if (name === "INFECTIOUS DISEASE") {
                props.history.push({
                    pathname: "/infectiousdiseasecalculators",
                    state: {
                        categoryName: category,
                        sourceName: 'Favorites',
                        returnPath: 'favourite'
                    }

                });
            }
            if (name === "MUSCULOSKELETAL") {
                props.history.push({
                    pathname: "/musculoskeletalcalculators",
                    state: {
                        categoryName: category,
                        sourceName: 'Favorites',
                        returnPath: 'favourite'
                    }

                });
            }
            if (name === "NEUROLOGICAL") {
                props.history.push({
                    pathname: "/neurologicalcalculators",
                    state: {
                        categoryName: category,
                        sourceName: 'Favorites',
                        returnPath: 'favourite'
                    }

                });
            }
            if (name === "NUTRITION") {
                props.history.push({
                    pathname: "/nutritioncalculators",
                    state: {
                        categoryName: category,
                        sourceName: 'Favorites',
                        returnPath: 'favourite'
                    }

                });
            }
            if (name.trim() === "GASTROINTESTINAL") {
                props.history.push({
                    pathname: "/gastrointestinalcalculators",
                    state: {
                        categoryName: category,
                        sourceName: 'Favorites',
                        returnPath: 'favourite'
                    }

                });
            }
            if (name === "ONCOLOGIC") {
                props.history.push({
                    pathname: "/oncologiccalculators",
                    state: {
                        categoryName: category,
                        sourceName: 'Favorites',
                        returnPath: 'favourite'
                    }

                });
            }
            if (name === "PSYCHIATRIC") {
                props.history.push({
                    pathname: "/psychiatriccalcalculators",
                    state: {
                        categoryName: category,
                        sourceName: 'Favorites',
                        returnPath: 'favourite'
                    }

                });
            }
            if (name === "PULMONARY") {
                props.history.push({
                    pathname: "/pulmonarycalculators",
                    state: {
                        categoryName: category,
                        sourceName: 'Favorites',
                        returnPath: 'favourite'
                    }

                });
            }
            if (name === "RENAL") {
                props.history.push({
                    pathname: "/renalcalculators",
                    state: {
                        categoryName: category,
                        sourceName: 'Favorites',
                        returnPath: 'favourite'
                    }

                });
            }
            if (name === "REPRODUCTIVE") {
                props.history.push({
                    pathname: "/reproductivecalculators",
                    state: {
                        categoryName: category,
                        sourceName: 'Favorites',
                        returnPath: 'favourite'
                    }

                });
            }
            if (name === "RHEUMATOLOGIC") {
                props.history.push({
                    pathname: "/rheumatologiccalculators",
                    state: {
                        categoryName: category,
                        sourceName: 'Favorites',
                        returnPath: 'favourite'
                    }

                });
            }
        }
    }


    const openFavorite = (data) => {
        context.changeActiveId(data.cp_code)
        context.changeFavorite(true)
        context.changeActiveListData(data)
        history.push("/actionLibrary")
    }


    return (
        <div>
            <div className="favouriteBox container">
                <div className="row">
                    {/* <div className="row"> */}
                    {/* <div className="col-md-2"></div> */}
                    {/* <div className="col-md-8"> */}
                    {/* style={{ marginBottom: 10, position: "relative", right: 100 }} */}
                    {/* <div className="col-md-6 col-sm-12">
                         <div className="card letter default-favourite">
                            <div className="card-header-custom"><strong>Default Favourite</strong></div>
                            *<ul className="list-group list-group-flush">
                                <li style={{ cursor: "pointer" }} onClick={() => props.history.push({
                                    pathname: '/actionLibrary',
                                    state: "Case Tips"
                                })} className="list-group-item"><span className="check fa fa-check-circle "></span>Case Tips</li>
                                <li className="list-group-item"><span className="check fa fa-check-circle"></span><Link style={{ cursor: "pointer", listStyle: "none", textDecoration: "none", color: "black" }} to="/crises">Crises</Link></li>
                                <li style={{ cursor: "pointer" }} onClick={() => props.history.push({
                                    pathname: '/actionLibrary',
                                    state: "Drugs"
                                })} className="list-group-item"><span className="check fa fa-check-circle"></span>Drugs</li>
                                <ul>
                                    <li style={{ cursor: "pointer" }} onClick={() => props.history.push({
                                        pathname: '/actionLibrary',
                                        state: "ANTIBIOTICS"
                                    })} className="list-group-item"><span className="list-circle"></span>Antibiotics</li>
                                    <li style={{ cursor: "pointer" }} onClick={() => props.history.push({
                                        pathname: '/actionLibrary',
                                        state: "EMERGENCY DRUGS"
                                    })} className="list-group-item"><span className="list-circle"></span>Emergency Drugs</li>
                                </ul>

                                <li className="list-group-item"><span className="check fa fa-check-circle"></span><Link style={{ listStyle: "none", textDecoration: "none", color: "black", cursor: "pointer" }} to="/note">Insert Notes</Link></li>
                                <li style={{ cursor: "pointer" }} onClick={() => props.history.push({
                                    pathname: '/newAfterLamax',
                                    state: "Return To Favourite"
                                })} className="list-group-item"><span className="check fa fa-check-circle"></span>LA MAX Calculator</li>
                                <li className="list-group-item"><span className="check fa fa-check-circle"></span><Link style={{ listStyle: "none", textDecoration: "none", color: "black", cursor: "pointer" }} to="/preoperativeevaluation">Preoperative Evaluation</Link></li>
                                <li style={{ cursor: "pointer" }} onClick={() => props.history.push({
                                    pathname: '/actionLibrary',
                                    state: "Regional Anesthesia"
                                })} className="list-group-item"><span className="check fa fa-check-circle"></span>Regional &amp; Neuraxial Anesthesia</li>
                            </ul>

                        </div>

                    </div> */}
                    <div className="col-md-12 col-sm-12">
                        {loading ?
                            <div className="user-favourite">
                                <div className="card-header-custom"><p className="float-left text-infoma" style={{ fontWeight: 'bold', padding: 5 }}>USER FAVORITES</p></div>


                                {airway_and_equipments.length === 0 && case_tips.length === 0 && crises.length === 0 && drugs.length === 0 && preoperative_evaluations.length === 0 && regional_and_n_anesthesias.length === 0 && checklists.length === 0 && fluids.length === 0 && calculations_and_rs.length === 0 ?
                                    <div className="container d-flex m-2">

                                        You Do Not Have Any Favorites Yet
                                        {/* <Spinner/> */}
                                    </div> :
                                    <div>

                                        {airway_and_equipments.length === 0 ?
                                            <></> :
                                            <div className="all-action-subcontainer-content" >
                                                <i className="material-icons down-icon" >play_arrow</i>
                                                <div style={{ cursor: "pointer", fontWeight: "bold" }} >AIRWAY &amp; EQUIPMENTS</div>
                                            </div>
                                        }
                                        <div className="all-action-subcontainer" >
                                            {
                                                airway_and_equipments.map((data, key) => {
                                                    if (data.user_fav === "1") {
                                                        return (
                                                            <div className="all-action-subcontainer-content" key={data.id} style={{ paddingLeft: 40 }
                                                            }>
                                                                <i className="material-icons dropdown-icon" >play_arrow</i>
                                                                {/* <div className="sub-category-circle" ></div> */}
                                                                <div onClick={() => openFavorite(data)} className="favLink">
                                                                    {data.name}</div>
                                                                <span className="cancel-list" onClick={() => deleteFavourite("Airway & Equipments", data.id)}><i className="fa fa-times-circle"></i></span>

                                                            </div>

                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                        {
                                            calculations_and_rs.length === 0 ?
                                                <></> :
                                                <div className="all-action-subcontainer-content" >
                                                    <i className="material-icons down-icon" > play_arrow</i >
                                                    <div style={{ cursor: "pointer", fontWeight: "bold" }} > CALCULATIONS AND SCORES</div >
                                                </div >
                                        }
                                        <div className="all-action-subcontainer" >
                                            {
                                                calculations_and_rs.map((data) => {
                                                    if (data.user_fav === "1") {
                                                        return (
                                                            <div className="all-action-subcontainer-content" key={data.id} style={{ paddingLeft: 40 }
                                                            }>
                                                                <i className="material-icons dropdown-icon" > play_arrow</i >
                                                                <div style={{ cursor: "pointer", width: '75%' }} onClick={() => openFavorite(data)}>
                                                                    {data.name}</div >
                                                                <span className="cancel-list" onClick={() => deleteFavourite("Calculations And Scores", data.id)}> <i className="fa fa-times-circle"></i></span >

                                                            </div >

                                                        )
                                                    }
                                                })
                                            }
                                        </div >
                                        {crises.length === 0 ?
                                            <></> :
                                            <div className="all-action-subcontainer-content" >
                                                <i className="material-icons down-icon" >play_arrow</i>
                                                <div style={{ cursor: "pointer", fontWeight: "bold" }} >CRISES</div>
                                            </div>
                                        }
                                        <div className="all-action-subcontainer" >
                                            {
                                                crises.map((data, key) => {
                                                    if (data.user_fav === "1") {
                                                        return (
                                                            <div className="all-action-subcontainer-content" key={data.id} style={{ paddingLeft: 40 }
                                                            }>
                                                                <i className="material-icons dropdown-icon" >play_arrow</i>
                                                                {/* <div className="sub-category-circle" ></div> */}
                                                                <div key={key} onClick={() => openFavorite(data)} className="favLink">
                                                                    {data.name}</div>
                                                                <span className="cancel-list" onClick={() => deleteFavourite("Crises", data.id)}><i className="fa fa-times-circle"></i></span>

                                                            </div>

                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                        {
                                            drugData.length === 0 ?
                                                <></> :
                                                <div className="all-action-subcontainer-content" >
                                                    <i className="material-icons down-icon" > play_arrow</i >
                                                    <div style={{ cursor: "pointer", fontWeight: "bold" }} > DRUGS</div >
                                                </div >
                                        }
                                        <div className="all-action-subcontainer" >
                                            {
                                                drugData.map((data) => {
                                                    data.cp_table = "drugs"
                                                    if (data.user_fav === "1") {
                                                        return (
                                                            <div className="all-action-subcontainer-content" key={data.id} style={{ paddingLeft: 40 }
                                                            }>
                                                                <i className="material-icons dropdown-icon" >play_arrow</i>
                                                                {/* <div className="sub-category-circle" ></div > */}
                                                                <div onClick={() => openFavorite(data)} className="favLink">
                                                                    {data.name}</div>
                                                                {/* {data.parent_drug_name ? <span>- {data.parent_drug_name}</span>:<></>} */}
                                                                <span className="cancel-list" onClick={() => deleteFavourite("Drugs", data.id)}> <i className="fa fa-times-circle"></i></span >

                                                            </div >

                                                        )
                                                    }
                                                })
                                            }
                                        </div >
                                        {
                                            fluids.length === 0 ?
                                                <></> :
                                                <div className="all-action-subcontainer-content" >
                                                    <i className="material-icons down-icon" > play_arrow</i >
                                                    <div style={{ cursor: "pointer", fontWeight: "bold" }} > FLUID TRANSFUSION</div >
                                                </div >
                                        }
                                        <div className="all-action-subcontainer" >
                                            {
                                                fluids.map((data) => {
                                                    if (data.user_fav === "1") {
                                                        return (
                                                            <div className="all-action-subcontainer-content" key={data.id} style={{ paddingLeft: 40 }
                                                            }>
                                                                {/* <div className="sub-category-circle" ></div > */}
                                                                <i className="material-icons dropdown-icon" >play_arrow</i>
                                                                <div onClick={() => openFavorite(data)} className="favLink">
                                                                    {data.name}</div >
                                                                <span className="cancel-list" onClick={() => deleteFavourite("Fluids And Transfusions", data.id)}> <i className="fa fa-times-circle"></i></span >

                                                            </div >

                                                        )
                                                    }
                                                })
                                            }
                                        </div >
                                        {/* {
                                        case_tips.length === 0 ?
                                            <div className="all-action-subcontainer-content" >
                                                <i className="material-icons dropdown-icon" > play_arrow</i>
                                                <div  > CASE TIPS</div >
                                            </div > :
                                            <div className="all-action-subcontainer-content" >
                                                <i className="material-icons down-icon" > play_arrow</i >
                                                <div style={{ cursor: "pointer", fontWeight: "bold"  }} > CASE TIPS</div >
                                            </div >
                                    }  */}
                                        {/* <div className="all-action-subcontainer" >
                                        {
                                            case_tips.map((data) => {
                                                if(data.user_fav==="1"){
                                                return (
                                                    <div className="all-action-subcontainer-content" key={data.id} style={{ paddingLeft: 40 }
                                                    }>
                                                        <i className="material-icons dropdown-icon" > play_arrow</i>
                                                        <div style={{width:'75%', cursor:'pointer'}}>
                                                            {data.name}</div >
                                                        <span className="cancel-list" onClick={() => deleteFavourite("Case Tips", data.id)}> <i className="fa fa-times-circle"></i></span >

                                                    </div >

                                                )
                                                }
                                            })
                                        }
                                    </div >  */}
                                        {/* {
                                        preoperative_evaluations.length === 0 ?
                                            <div className="all-action-subcontainer-content" style={{ paddingLeft: 10 }
                                        }>
                                                <i className="material-icons dropdown-icon" > play_arrow</i>
                                                <div  > PREOPERATIVE EVALUATION</div >
                                            </div > :
                                            <div className="all-action-subcontainer-content" >
                                                <i className="material-icons down-icon" > play_arrow</i >
                                                <div style={{ cursor: "pointer", fontWeight: "bold"  }} > PREOPERATIVE EVALUATION</div >
                                            </div >
                                    }  */}
                                        {/* <div className="all-action-subcontainer" >
                                        {
                                            preoperative_evaluations.map((data) => {
                                                if(data.user_fav==="1"){
                                                return (
                                                    <div className="all-action-subcontainer-content" key={data.id} style={{ paddingLeft: 40 }
                                                    }>
                                                         <i className="material-icons dropdown-icon" > play_arrow</i>
                                                        <div style={{width: '75%'}}  onClick={() => favClick(data.name, 'preoperative', data.id, data.children)}>
                                                            {data.name}</div >
                                                        <span className="cancel-list" onClick={() => deleteFavourite("Preoperative Evaluations", data.id)}> <i className="fa fa-times-circle"></i></span >

                                                    </div >

                                                )
                                                }
                                            })
                                        }
                                    </div >  */}
                                        {
                                            (preoperative_evaluations.reduce((sum, value) => {
                                                if (value.user_fav === "1") {
                                                    sum += 1
                                                }
                                                return sum
                                            },0)) > 0 ?
                                            <div className="all-action-subcontainer-content" >
                                                    <i className="material-icons down-icon" > play_arrow</i >
                                                    <div style={{ cursor: "pointer", fontWeight: "bold" }} > RISK EVALUATION</div >
                                                </div >
                                                 :
                                               <></>
                                        }
                                        <div className="all-action-subcontainer" >
                                            {
                                                preoperative_evaluations.map((data) => {
                                                    if (data.user_fav === "1") {
                                                        return (
                                                            <div className="all-action-subcontainer-content" key={data.id} style={{ paddingLeft: 40 }
                                                            }>
                                                                <i className="material-icons dropdown-icon" > play_arrow</i>
                                                                <div style={{ width: '75%' }} onClick={() => openFavorite(data)}>
                                                                    {data.name}</div >
                                                                <span className="cancel-list" onClick={() => deleteFavourite("Preoperative Evaluation", data.id)}> <i className="fa fa-times-circle"></i></span >

                                                            </div >

                                                        )
                                                    }
                                                })
                                            }
                                        </div >
                                        {
                                            regional_and_n_anesthesias.length === 0 ?
                                                <></> :
                                                <div className="all-action-subcontainer-content" >
                                                    <i className="material-icons down-icon" > play_arrow</i >
                                                    <div style={{ cursor: "pointer", fontWeight: "bold" }} > REGIONAL & NEURAXIAL ANESTHESIA</div >
                                                </div >
                                        }
                                        <div className="all-action-subcontainer" >
                                            {
                                                regional_and_n_anesthesias.map((data) => {
                                                    if (data.user_fav === "1") {
                                                        return (
                                                            <div className="all-action-subcontainer-content" key={data.id} style={{ paddingLeft: 40 }
                                                            }>
                                                                <i className="material-icons dropdown-icon" > play_arrow</i >
                                                                <div onClick={() => openFavorite(data)} className="favLink">
                                                                    {data.name}</div >
                                                                <span className="cancel-list" onClick={() => deleteFavourite("Regional Anesthesia", data.id)}> <i className="fa fa-times-circle"></i></span >

                                                            </div >

                                                        )
                                                    }
                                                })}
                                        </div >
                                        {/* {
                                        checklists.length === 0 ?
                                            <div className="all-action-subcontainer-content" >
                                                <i className="material-icons dropdown-icon" > play_arrow</i>
                                                <div  > CHECKLISTS</div >
                                            </div > :
                                            <div className="all-action-subcontainer-content" >
                                                <i className="material-icons down-icon" > play_arrow</i >
                                                <div style={{ cursor: "pointer", fontWeight: "bold"  }} > CHECKLISTS</div >
                                            </div >
                                    }  */}
                                        {/* <div className="all-action-subcontainer" >
                                        {
                                            checklists.map((data) => {
                                                if(data.user_fav==="1"){
                                                return (
                                                    <div className="all-action-subcontainer-content" key={data.id} style={{ paddingLeft: 40 }
                                                    }>
                                                         <i className="material-icons down-icon" > play_arrow</i >
                                                        <div style={{width:'75%', cursor:"pointer"}} >
                                                            {data.name}</div >
                                                        <span className="cancel-list" onClick={() => deleteFavourite("Checklists", data.id)}> <i className="fa fa-times-circle"></i></span >

                                                    </div >

                                                )
                                                }
                                            })
                                        }
                                    </div >  */}
                                        {/* {
                                        localanesthesia.length === 0 ?
                                            <div className="all-action-subcontainer-content" >
                                                <i className="material-icons dropdown-icon" > play_arrow</i>
                                                <div  style={{ cursor: "pointer", fontWeight: "bold"  }}> LOCAL ANESTHESIA</div >
                                            </div > :
                                            <div className="all-action-subcontainer-content" >
                                                <i className="material-icons down-icon" > play_arrow</i >
                                                <div style={{ cursor: "pointer", fontWeight: "bold"  }} > LOCAL ANESTHESIA</div >
                                            </div >
                                    } 
                                     <div className="all-action-subcontainer" >
                                        {
                                            localanesthesia.map((data) => {
                                                if(data.user_fav==="1"){
                                                return (
                                                    <div className="all-action-subcontainer-content" key={data.id} style={{ paddingLeft: 40 }
                                                    }>
                                                         <i className="material-icons down-icon" > play_arrow</i >
                                                        <div style={{cursor:"pointer", width:"75%"}}>
                                                            {data.name}</div >
                                                        <span className="cancel-list" onClick={() => deleteFavourite("Local Anesthesia", data.id)}> <i className="fa fa-times-circle"></i></span >

                                                    </div >

                                                )
                                                }
                                            })
                                        }
                                    </div >  */}


                                    </div >
                                }
                            </div > : <Spinner />
                        }
                    </div >
                    {/*  <div className="beta" style={{ margin: '0 auto', width: '100%' }}>
                        <button onClick={() => saveFavouriteList()} className="save-button-container" style={{ margin: '0 auto' }}><i className="fa fa-save">&nbsp;</i>Save</button>
                    </div>
                    */}

                </div >
            </div >
            <Alert isOpen={alertVisible} message={message} label="Go to Login" closeAlert={() => { closeAlert() }} />

        </div>

    )
}


export default Favorites
