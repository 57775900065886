import React, { useEffect, useState } from 'react';
import './FAT1166.css'

const FAT1166 = () => {
    const [green, setGreen] = useState(false);
    const [age, setAgg] = useState(0);
    const [weight, setWeight] = useState([]);
    const [hemoglobin1, setHemoglobin1] = useState([]);
    const [hemoglobin2, setHemoglobin2] = useState([]);
    const [tunit1, setTunit1] = useState('kg');
    const [tunit2, setTunit2] = useState('g/L');
    const [placehldr2, setPlacehldr2] = useState('Norm: 120 - 170');
    const [tunit3, setTunit3] = useState('g/L');
    const [placehldr3, setPlacehldr3] = useState('Norm: 120 - 170');
    const [newweight, setNewweight] = useState(0);
    const [newhemoglobin1, setNewhemoglobin1] = useState(0);
    const [newhemoglobin2, setNewhemoglobin2] = useState(0)
    const [resultabl, setResultabl] = useState(0);


    const postData = async () => {
        if (tunit1 === 'kg') {
            if (weight < 273) {

                setNewweight(parseFloat(weight).toFixed(5));
            }
            else {
                setNewweight(0);
            }
        }
        if (tunit1 === 'lbs') {
            if (weight < 601) {

                setNewweight(parseFloat(weight / 2.2046226218488).toFixed(5));
            }
            else {
                setNewweight(0);
            }
        }

        if (tunit2 === 'g/L') {
            if (hemoglobin1 > 4 && hemoglobin1 < 251) {
                setNewhemoglobin1(parseFloat(hemoglobin1));

            }
            else {
                setNewhemoglobin1(0);
            }
        }
        if (tunit2 === 'g/dL') {
            if (hemoglobin1 < 26) {

                setNewhemoglobin1(parseFloat(hemoglobin1 * 10));
            }
            else {
                setNewhemoglobin1(0);
            }
        }

        if (tunit3 === 'g/L') {
            if (hemoglobin2 > 4 && hemoglobin2 < 251) {

                setNewhemoglobin2(parseFloat(hemoglobin2));
            }
            else {
                setNewhemoglobin2(0);
            }
        }
        if (tunit3 === 'g/dL') {
            if (hemoglobin2 < 26) {

                setNewhemoglobin2(parseFloat(hemoglobin2 * 10));
            }
            else {
                setNewhemoglobin2(0);
            }
        }


        if (newhemoglobin1 != 0 && newhemoglobin2 != 0 && newweight != 0) {
            if (age === 75) {
                setResultabl(((75 * newweight * (newhemoglobin1 - newhemoglobin2) / ((newhemoglobin1 + newhemoglobin2) / 2)).toFixed(1)))
            }
            else if (age === 65) {
                setResultabl(((65 * newweight * (newhemoglobin1 - newhemoglobin2) / ((newhemoglobin1 + newhemoglobin2) / 2)).toFixed(1)))
            }
            else if (age === 80) {
                setResultabl(((80 * newweight * (newhemoglobin1 - newhemoglobin2) / ((newhemoglobin1 + newhemoglobin2) / 2)).toFixed(1)))
            }
            else if (age === 85) {
                setResultabl(((85 * newweight * (newhemoglobin1 - newhemoglobin2) / ((newhemoglobin1 + newhemoglobin2) / 2)).toFixed(1)))
            }
            else if (age === 96) {
                setResultabl(((96 * newweight * (newhemoglobin1 - newhemoglobin2) / ((newhemoglobin1 + newhemoglobin2) / 2)).toFixed(1)))
            }



        }
        else {
            setResultabl(0);

        }

        setGreen(true);

    }


    const validation1 = () => {

        if (tunit1 === 'kg') {
            if ((weight < 273) && (weight > 229)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (weight > 272) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(weight)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }

        }
        if (tunit1 === 'lbs') {
            if ((weight <= 600) && (weight >= 501)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (weight >= 601) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(weight)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }

    }
    const validation2 = () => {

        if (tunit2 === 'g/L') {
            if ((hemoglobin1 < 5) && (hemoglobin1 > 0)) {
                return <div className="validate">*Too Low;please change to proceed</div>

            }
            if ((hemoglobin1 < 40) && (hemoglobin1 > 4)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if ((hemoglobin1 < 251) && (hemoglobin1 > 200)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (hemoglobin1 > 250) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(hemoglobin1)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
        if (tunit2 === 'g/dL') {
            if ((hemoglobin1 < 4) && (hemoglobin1 > 0)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if ((hemoglobin1 < 26) && (hemoglobin1 > 20)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (hemoglobin1 > 25) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(hemoglobin1)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }

        }
    }

    const validation3 = () => {
        if (tunit3 === 'g/L') {
            if ((hemoglobin2 < 5) && (hemoglobin2 != 0)) {
                return <div className="validate">*Too low; please change to proceed.</div>

            }
            if ((hemoglobin2 < 40) && (hemoglobin2 > 4)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if ((hemoglobin2 < 251) && (hemoglobin2 > 200)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (hemoglobin2 > 250) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(hemoglobin2)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }

        }
        if (tunit3 === 'g/dL') {
            if ((hemoglobin2 < 4) && (hemoglobin2 > 0)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if ((hemoglobin2 < 26) && (hemoglobin2 > 20)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (hemoglobin2 > 25) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(hemoglobin2)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }



    }

    useEffect(() => {
        postData();

    }, [age, weight, hemoglobin1, hemoglobin2, newweight, newhemoglobin1, newhemoglobin2])

    const changeunit1 = () => {
        if (weight === 0) {
            if (tunit1 === 'kg') {
                setTunit1('lbs');

            }
            if (tunit1 === 'lbs') {
                setTunit1('kg');

            }
        } else if (weight !== 0) {
            if (tunit1 === 'kg') {
                setTunit1('lbs');
                if (weight.length !== 0) {

                    setWeight((weight * 2.2046226218488).toFixed(2));
                }


            }
            if (tunit1 === 'lbs') {
                setTunit1('kg');
                if (weight.length !== 0) {

                    setWeight((weight / 2.2046226218488).toFixed(2));

                }

            }
        }
    }
    const takeUnit1 = (e) => {
        if (tunit1 === 'kg') {
            setWeight(e.target.value);


        }
        if (tunit1 === 'lbs') {
            let ht = e.target.value;
            setWeight(ht);

        }
    }
    /////////////////////////////////////////////////////////
    const changeunit2 = () => {
        if (hemoglobin1 === 0) {
            if (tunit2 === 'g/L') {
                setTunit2('g/dL');

            }
            if (tunit2 === 'g/dL') {
                setTunit2('g/L');

            }
        } else if (hemoglobin1 !== 0) {
            if (tunit2 === 'g/L') {
                setTunit2('g/dL');
                setPlacehldr2("Norm: 12- 17");
                if (hemoglobin1.length !== 0) {

                    setHemoglobin1((hemoglobin1 / 10).toFixed(2));
                }

            }
            if (tunit2 === 'g/dL') {
                setTunit2('g/L');
                setPlacehldr2("Norm: 120- 170");
                if (hemoglobin1.length !== 0) {

                    setHemoglobin1((hemoglobin1 * 10).toFixed(2));
                }

            }
        }
    }
    const takeUnit2 = (e) => {
        if (tunit2 === 'g/L') {
            setHemoglobin1(e.target.value);


        }
        if (tunit2 === 'g/dL') {

            setHemoglobin1(e.target.value);

        }
    }
    ////////////////////////////////////////////////////////////////////
    const changeunit3 = () => {
        if (hemoglobin2 === 0) {
            if (tunit3 === 'g/L') {
                setTunit3('g/dL');
                setPlacehldr2("Norm: 12- 17");

            }
            if (tunit3 === 'g/dL') {
                setTunit3('g/L');

            }
        } else if (hemoglobin2 !== 0) {
            if (tunit3 === 'g/L') {

                setTunit3('g/dL');
                setPlacehldr3("Norm: 12- 17");
                if (hemoglobin2.length !== 0) {

                    setHemoglobin2((hemoglobin2 / 10).toFixed(2));
                }

            }
            if (tunit3 === 'g/dL') {
                setPlacehldr3("Norm: 120- 170");
                setTunit3('g/L');
                if (hemoglobin2.length !== 0) {

                    setHemoglobin2((hemoglobin2 * 10).toFixed(2));
                }

            }
        }
    }
    const takeUnit3 = (e) => {
        if (tunit3 === 'g/L') {
            setHemoglobin2(e.target.value);



        }
        if (tunit3 === 'g/dL') {
            let ht = e.target.value;
            setHemoglobin2(ht);

        }
    }



    return (<div className="rev">
        <h5 className="text-info pl-2">Maximum Allowable Blood Loss (ABL) Without Transfusion</h5>
        <p className="pl-2">Estimates the maximum allowable blood loss intraoperatively before transfusion should be considered.</p>
        <div className="fields row">
            <div className="col-md-4">
                <div className="pt-2"><strong>Age</strong></div>
            </div>
            <div className="col-md-8 btn-group-vertical">
                <button style={{height:40}} onClick={() => setAgg(75)} className={age === 75 ? "btn  text-left active text-white" : "btn  text-left"}><span className="btn-name">Adult Man</span><span className="calc-val"></span></button>
                <button style={{height:40}} onClick={() => setAgg(65)} className={age === 65 ? "btn  text-left active text-white" : "btn  text-left"}><span className="btn-name">Adult Woman</span><span className="calc-val"></span></button>
                <button style={{height:40}} onClick={() => setAgg(80)} className={age === 80 ? "btn  text-left active text-white" : "btn  text-left"}><span className="btn-name">Infant</span><span className="calc-val"></span></button>
                <button style={{height:40}} onClick={() => setAgg(85)} className={age === 85 ? "btn  text-left active text-white" : "btn  text-left"}><span className="btn-name">Neonate</span><span className="calc-val"></span></button>
                <button style={{height:40}} onClick={() => setAgg(96)} className={age === 96 ? "btn  text-left active text-white" : "btn  text-left"}><span className="btn-name">Premature neonate</span><span className="calc-val"></span></button>

            </div>
        </div>

        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Weight</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input
                    onChange={takeUnit1}
                    type="number"

                    className="ant-text-input" style={{ width: "77%" }} value={weight} /><span className="units" onClick={changeunit1}>{tunit1}&#8651;</span>
                <div>{validation1()}</div>

            </div>
        </div>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Hemoglobin (initial)</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input
                    onChange={takeUnit2}
                    placeholder={placehldr2}
                    type="number"
                    className="ant-text-input" style={{ width: "77%" }} value={hemoglobin1} /><span className="units" onClick={changeunit2}>{tunit2}&#8651;</span>
                <div>{validation2()}</div>

            </div>
        </div>

        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Hemoglobin (final)</strong></div>
                <div>Lowest acceptable hemoglobin, by clinical judgment</div>
            </div>
            <div className="col-md-7 frm-field">
                <input
                    onChange={takeUnit3}
                    placeholder={placehldr3}
                    type="number"
                    className="ant-text-input fluidInput" value={hemoglobin2} style={{ height: '50%' }} /><span className="units unitBtn" style={{ height: '50%' }} onClick={changeunit3} >{tunit3}&#8651;</span>
                <div>{validation3()}</div>

            </div>
        </div>
        <br />

        <div className="stick-result">

            {
                (age && weight && hemoglobin1 && hemoglobin2 && resultabl !== 0) ?

                    <div className="apfel-score-result-container" >

                        <div className="point-result-container" >
                            <div>

                                <h1>{resultabl} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>mL</span> </h1>
                                <div>Allowable Blood Loss</div>


                            </div>
                        </div>
                    </div>
                    :
                    <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>


            }
        </div>
        <div className="p-2 mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/6829965/">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Gross JB. Estimating Allowable Blood Loss Corrected for Dilution. Anesthesiology 58.2 (1983): 277-280.</p>
                </div>
            </a>
        </div>
    </div>
    )
}

export default FAT1166