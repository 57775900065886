import React, { useEffect, useState } from 'react';
import './RE1218.css';

const RE1218 = () => {
    const [green, setGreen] = useState(false);
    const [copd, setCopd] = useState(0);
    const [asa, setAsa] = useState(0);
    const [sepsis, setSepsis] = useState(0);
    const [result, setResult] = useState(0);
    const [result1, setResult1] = useState(0);
    const [age, setAge] = useState(0);
    const [smoking, setSmoking] = useState(0);
    const [typeOfProcedure, setTypeOfProcedure] = useState(0);
    const [functionalStatus, setFunctionalStatus] = useState(0);
    const postData = async () => {
        setResult((copd + asa + sepsis + (age*0.0144)+ smoking + functionalStatus + parseFloat(typeOfProcedure))- 2.8977);
        setResult1((((Math.pow(2.71828 ,result )))/(1+ Math.pow(2.71828 ,result )) *100).toFixed(1));

        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [copd, asa, sepsis,functionalStatus,typeOfProcedure, smoking,age,result,result1]);

    return (
        <div className="rev">
              <h5 className="text-info pl-2">Gupta Postoperative Pneumonia Risk</h5>
        <p className="pl-2">Predicts risk of pneumonia after surgery.</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Age</strong></div>

                </div>
                <div className="col-md-7 frm-field">
                    <input type="number" className="ant-text-input " onChange={(e) => setAge(e.target.value)} /><span className="units ">years</span>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>COPD
                        COPD causing functional disability or hospitalization, or FEV1 &lt;75%</strong></div>

                </div>
                <div className="col-md-7 btn-group options-btn mb-2">
                    <button onClick={() => setCopd(-0.4553 )} className={copd === -0.4553 ? "btn   active" : "btn  "} style={{height:40}}><span className="btn-name ">No</span></button>
                    <button onClick={() => setCopd(0)} className={copd === 0 ? "btn   active" : "btn "} style={{height:40}}><span className="btn-name ">yes</span></button>


                </div>
         
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Functional status</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button style={{height:40}} onClick={() => setFunctionalStatus(0)} className={functionalStatus === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Independent</span></button>
                    <button style={{height:40}} onClick={() => setFunctionalStatus(0.7653)} className={functionalStatus === 0.7653 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Partially dependent</span></button>
                    <button style={{height:40}} onClick={() => setFunctionalStatus(0.9400)} className={functionalStatus === 0.9400 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Totally dependent</span></button>
                  
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>ASA class</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2  ">
                    <button style={{height:40}} onClick={() => setAsa(-3.0225)} className={asa === -3.0225 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">1: normal healthy patient</span></button>
                    <button style={{height:40}} onClick={() => setAsa(-1.6057)} className={asa === 	-1.6057 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">2: mild systemic disease</span></button>
                    <button style={{height:40}} onClick={() => setAsa(-0.4915)} className={asa === -0.4915 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">3: severe systemic disease</span></button>
                    <button style={{height:100}} onClick={() => setAsa(0.0123)} className={asa === 0.0123 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name "  >4: severe systemic disease that <br/>is a constant threat to life (i.e., patient could<br/> die acutely without intervention)</span></button>
                    <button style={{height:60}} onClick={() => setAsa(0)} className={asa === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">5: moribund, not expected to survive<br/> without surgery</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Sepsis</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button style={{height:40}} onClick={() => setSepsis(-0.7641)} className={sepsis === -0.7641 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">None</span></button>
                    <button style={{height:60}} onClick={() => setSepsis(0)} className={sepsis === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Preoperative systemic inflammatory<br/> response syndrome</span></button>
                    <button style={{height:40}} onClick={() => setSepsis(-0.0842)} className={sepsis === -0.0842 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Preoperative sepsis</span></button>
                    <button style={{height:40}} onClick={() => setSepsis(0.1048)} className={sepsis === 0.1048 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Preoperative septic shock</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Smoking within last year</strong></div>

                </div>
                <div className="col-md-7 btn-group options-btn mb-2">
                    <button style={{height:40}} onClick={() => setSmoking(-0.4306)} className={smoking === -0.4306 ? "btn   active" : "btn  "}><span className="btn-name ">No</span></button>
                    <button style={{height:40}} onClick={() => setSmoking(0)} className={smoking === 0 ? "btn   active" : "btn "}><span className="btn-name ">yes</span></button>


                </div>
            </div>
            <div className="fields row w-100 mb-5">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Type of procedure</strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={typeOfProcedure}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setTypeOfProcedure(patient);
                        }}
                    >
                        <option value='0'>Choose One</option>
                        <option value='-0.8470'>Anorectal</option>
                        <option value='0.7178' >Aortic</option>
                        <option value='-0.6282.4' >Bariatric</option>
                        <option value='0.6841' >Brain</option>
                        <option value='-2.3318' >Breast</option>
                        <option value='0.1382' >Cardiac</option>
                        <option value='-0.3665' >ENT(expect thyroid/Parathyroid)</option>
                        <option value='1.0660' >Foregut (esophagus,stomach) or hepatopancreatobiliary</option>
                        <option value='-0.3951' >Gallbladder,appendix,adrenals,spleen</option>
                        <option value='0' >Hernia(vental,inguinal,femoral)</option>
                        <option value='0.6169' >Intestinal</option>
                        <option value='-0.0872' >Neck(thyroid/parathyroid)</option>
                        <option value='-0.4101' >Obstetric/gynecologic</option>
                        <option value='-0.5415' >Orthopedic and non-vascularextrimity</option>
                        <option value='0.4021' >Other abdominal</option>
                        <option value='-0.4519' >Peripheral vascular</option>
                        <option value='-0.5075' >Skin</option>
                        <option value='-0.5672' >Spine</option>
                        <option value='0.8901' >Non-esophageal thoracic(lungs,mediastinum,etc)</option>
                        <option value='-1.4760' >Vein</option>
                        <option value='0.1076' >Urology</option>
                    </select>
                </div>
             
            </div>


            <div className="stick mt-5">
                {
                    age != 0 ? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container" >
                                <h1>{result1}<span style={{ fontWeight: 600, fontSize: '1.5rem', color: 'silver' }}> %</span></h1>
                                <div>Risk of postoperative pneumonia</div>


                              



                            </div>






                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <div className="p-2 mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/24182703/">
            <div className="reference-container-card" >
                <div className="reference-img" >
                    <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                </div>
                <p className="reference-paragraph" >Gupta H, Gupta PK, Schuller D, et al. Development and validation of a risk calculator for predicting postoperative pneumonia. Mayo Clin Proc. 2013;88(11):1241-9.</p>
            </div>
            </a>
          
            </div>
        </div>
    )
}


export default RE1218;

