import React, { useEffect, useState } from 'react';
import './CAS1044.css';

const CAS1044 = () => {
    const [green, setGreen] = useState(false);
    const [fexpression, setFexpression] = useState(0);
    const [upperlimb, setUpperlimb] = useState(0);
    const [mvantilation, setMvantilation] = useState(0);
    const [result, setResult] = useState(0);

    const postData = async () => {
        setResult(fexpression + upperlimb + mvantilation);
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [fexpression, upperlimb, mvantilation, result]);

    return (
        <div className="rev">
            <h5 className="text-info pl-2">Behavioral Pain Scale (BPS) for Pain Assessment in Intubated Patients</h5>
            <p className="pl-2">Quantifies pain in intubated patients.</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Facial expression</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  40 }} onClick={() => setFexpression(1)} className={fexpression === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Relaxed</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  40 }} onClick={() => setFexpression(2)} className={fexpression === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Partially tightened (e.g., brow lowering)</span><span className="calc-val ">+2</span></button>
                    <button style={{ height:  40 }} onClick={() => setFexpression(3)} className={fexpression === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Fully tightened (e.g., eyelid closing)</span><span className="calc-val ">+3</span></button>
                    <button style={{ height:  40 }} onClick={() => setFexpression(4)} className={fexpression === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Grimacing</span><span className="calc-val ">+4</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Upper limb movements</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2 ">
                    <button style={{ height:  40 }} onClick={() => setUpperlimb(1)} className={upperlimb === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">No movement</span><span className="calc-val ">+1</span></button>
                    <button style={{ height:  40 }} onClick={() => setUpperlimb(2)} className={upperlimb === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Partially bent</span><span className="calc-val  ">+2</span></button>
                    <button style={{ height:  40 }} onClick={() => setUpperlimb(3)} className={upperlimb === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Fully bent with finger flexion</span><span className="calc-val ">+3</span></button>
                    <button style={{ height:  40 }} onClick={() => setUpperlimb(4)} className={upperlimb === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Permanently retracted</span><span className="calc-val ">+4</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Compliance with mechanical ventilation</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button  style={{ height:  40 }} onClick={() => setMvantilation(1)} className={mvantilation === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Tolerating movement</span><span className="calc-val ">+1</span></button>
                    <button style = {{ height:  40 }}   onClick={() => setMvantilation(2)} className={mvantilation === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Coughing but tolerating ventilation for most of the time</span><span className="calc-val ">+2</span></button>
                    <button style = {{ height:  40 }}  onClick={() => setMvantilation(3)} className={mvantilation === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Fighting ventilator</span><span className="calc-val ">+3</span></button>
                    <button style = {{ height:  40 }}   onClick={() => setMvantilation(4)} className={mvantilation === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Unable to control ventilation</span><span className="calc-val ">+4</span></button>
                </div>
            </div>


            <div className="stick">
                {
                    result != 0  && fexpression !=0  && upperlimb!=0 && mvantilation !=0 ? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container col-12" >
                                <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                                <div>{(result >0 && result < 4  )? "No pain" : " "}</div>
                                <div>{result == 4 || result == 5 ? "Mild pain" : " "}</div>
                                <div>{result >= 6 && result <= 11 ? "Unacceptable amount of pain" : " "}</div>
                                <div>{result == 12 ? "Maximum pain" : " "}</div>
                                <div>{result >= 6 && result <= 12 ? "Consider further sedation or other analgesia." : " "}</div>



                            </div>






                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/11801819/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >Payen JF, Bru O, Bosson JL, et al. Assessing pain in critically ill sedated patients by using a behavioral pain scale. Crit Care Med. 2001 Dec;29(12): 2258-63</p>
                    </div>
                </a>
            </div>
        </div>
    )
}


export default CAS1044

