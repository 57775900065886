import React, { useEffect, useState } from 'react';
import './CAS1023.css';

const CAS1023 = () => {
    const [qtc, setQtc] = useState(0)
    const [result, setResult] = useState(0);
    const postData = async () => {
        setResult();
    }
    useEffect(() => {
        postData();
    }, [qtc]);

    const output = () => {

        if (qtc) {
            if ((qtc == 1)) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <h1>Class I</h1>
                        <div>NYHA Heart Failure Classification</div>
                    </div>
                </div>
            }
            else if ((qtc == 2)) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <h1>Class II</h1>
                        <div>NYHA Heart Failure Classification</div>
                    </div>
                </div>
            }
            else if ((qtc == 3)) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <h1>Class III</h1>
                        <div>NYHA Heart Failure Classification</div>
                    </div>
                </div>
            }
            else if ((qtc >= 4)) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <h1>Class IV</h1>
                        <div>NYHA Heart Failure Classification</div>
                    </div>

                </div>
            }
        }

        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }
    return (
        <div className="rev">
            <h5 className="text-info pl-2">New York Heart Association (NYHA) Functional Classification for Heart Failure</h5>
            <p className="pl-2">Stratifies severity of heart failure by symptoms.</p>

            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Physical ability</strong></div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn">
                    <button style={{ height: 100 }} onClick={() => setQtc(1)} className={qtc === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">No limitation of physical activity; ordinary<br/> physical activity does not cause undue fatigue,<br/> palpitation, or dyspnea</span><span className="text-right pv text-muted"></span></button>
                    <button style={{ height: 100 }} onClick={() => setQtc(2)} className={qtc === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Slight limitation of physical activity; comfortable <br/>at rest; ordinary physical activity results<br/> in fatigue, palpitation, or dyspnea</span><span className="text-right pv text-muted"></span></button>
                    <button style={{ height: 100 }} onClick={() => setQtc(3)} className={qtc === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Marked limitation of physical activity;<br/> comfortable at rest; less than ordinary activity <br/>causes fatigue, palpitation, or dyspnea</span><span className="text-right pv text-muted"></span></button>
                    <button style={{ height: 100 }} onClick={() => setQtc(4)} className={qtc === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">Unable to carry on any physical activity<br/> without discomfort; symptoms of heart failure at<br/> rest; if any physical activity is undertaken,<br/> discomfort increases</span><span className="text-right pv text-muted"></span></button>
                </div>
            </div>

            <div className="stick pt-4">
                {
                    output()
                }
            </div>
            <div className="p-2 mb-4" >
                <div>
                    OTHER REFERENCES
                </div>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/7296795/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Goldman L,Hashimoto B, Cook EF, Loscalzo A.Comparative reproducibility and validity of systems for assessing cardiovascular fun ctional class: advantages of a new specific activity scale.Circulation.1981;64(6):1227-34</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default CAS1023
