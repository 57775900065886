import React, { useEffect, useState } from 'react';
import './RE1244.css';

const RE1244 = () => {

    const [altered, setAltered] = useState(0);
    const [respiratory, setRespiratory] = useState(0);
    const [systolic, setSystolic] = useState(0);


    const[result,setResult]= useState(0);

    const postData = async () => {
        setResult(altered + respiratory + systolic);
    }
    useEffect(() => {
        postData();


    }, [altered, respiratory, systolic]);


    const result1 = () => {

        if ((result >= 0) && (result <= 1)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>qSOFA Score</div>
                </div>
                <div className="point-result-container" >
                    <h1>Not High Risk </h1>
                    <div>If sepsis is still suspected, continue to monitor, evaluate, and initiate treatment as appropriate, including serial qSOFA assessments.</div>
                </div>
            </div>
        }
        if ((result >= 2) && (result <= 3)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>qSOFA Score</div>
                </div>
                <div className="point-result-container" >
                    <h1>High Risk </h1>
                    <div>qSOFA Scores 2-3 are associated with a 3- to 14-fold increase in in-hospital mortality. Assess for evidence of organ dysfunction with blood testing including serum lactate and calculation of the full SOFA Score.
                    </div>
                    <br/>
                    <div>Patients meeting these qSOFA criteria should have infection considered even if it was previously not.</div>
                </div>
            </div>
        }
        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }
    return (
        <div className="rev">
            <h5 className="text-info pl-2">qSOFA (Quick SOFA) Score for Sepsis</h5>
            <p className="pl-2">Identifies high-risk patients for in-hospital mortality with suspected infection outside the ICU.</p>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Altered mental status
                        GCS &gt;15</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={altered === 0 ? "btn active" : "btn"} onClick={() => setAltered(0)}>No  <span className="ml-4 text-muted"></span></button>
                    <button style={{ height: 40 }} key="2" className={altered === 1 ? "btn active" : "btn"} onClick={() => setAltered(1)}>Yes <span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Respiratory rate ≥22</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={respiratory === 0 ? "btn active" : "btn"} onClick={() => setRespiratory(0)}>No  <span className="ml-4 text-muted"></span></button>
                    <button style={{ height: 40 }} key="2" className={respiratory === 1 ? "btn active" : "btn"} onClick={() => setRespiratory(1)}>Yes <span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="fields row mb-5">
                <div className="col-md-6">
                    <div ><strong>Respiratory rate ≥22</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={systolic === 0 ? "btn active" : "btn"} onClick={() => setSystolic(0)}>No  <span className="ml-4 text-muted"></span></button>
                    <button style={{ height: 40 }} key="2" className={systolic === 1 ? "btn active" : "btn"} onClick={() => setSystolic(1)}>Yes <span className="ml-4 text-muted"></span></button>
                </div>
            </div>
            <div className="stick pt-3" >
                {
                    result1()
                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://jamanetwork.com/journals/jama/fullarticle/2492875">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" ><a href="https://www.ncbi.nlm.nih.gov/pubmed/5534693" target="_blank" rel="noopener noreferrer nofollow">Seymour CW, Liu VX, Iwashyna TJ, et al. Assessment of Clinical Criteria for Sepsis: For the Third International Consensus Definitions for Sepsis and Septic Shock (Sepsis-3). JAMA. 2016;315(8):762-774.</a></p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default RE1244
