import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../CustomComponent/Header'
import Tab from '../CustomComponent/Tab'
import '../css/AllActionForActionSummary.css'
import BurgerMenuModal from '../CustomComponent/BurgerMenuModal'
import AllAction from './AllAction'
import ActionSummary from './ActionSummary'
import { Link } from 'react-router-dom'
import { API_ROOT } from '../constants'
import { useDispatch, useSelector } from 'react-redux'
import { setExtraFavourites, setActionSummaryType, setActionSummaryCategory } from '../redux/FavouriteActions'
import Favorites from './Favorites';
import Spinner from '../CustomComponent/Spinner';
import Alert from '../CustomComponent/Alert';
import Axios from 'axios'
const AllActionForActionSummary = (props) => {
    const [sub4Category, setSub4Category] = useState([])
    const [sub4, setSub4] = useState(false)
    const [sub4Id, setSub4Id] = useState(null)
    const [subsubsub, setsubsubsub] = useState(false)
    const [subsubsubCategory, setsubsubsubCategory] = useState([])
    const [subsubId, setsubsubId] = useState(null)
    const [isSub, setSub] = useState(false)
    const [actionLibraryData, setactionLibraryData] = useState([])
    const [burgerMenu, setburgerMenu] = useState(false)
    const [subCategory, setsubCategory] = useState([])
    const [subsubCategory, setsubsubCategory] = useState([])
    const [subsub, setsubsub] = useState(false)
    const [subId, setsubId] = useState(null)
    const [subName, setsubName] = useState(null)
    const [actionSummaryData, setactionSummaryData] = useState([])
    const [value, setValue] = useState();
    const [isChecked, setisChecked] = useState(false)
    const [loading, setLoading] = useState(false);
    const [airway_and_equipments, setairway_and_equipments] = useState([])
    const [case_tips, setcase_tips] = useState([])
    const [crises, setcrises] = useState([])
    const [drugs, setdrugs] = useState([])
    const [preoperative_evaluations, setpreoperative_evaluations] = useState([])
    const [regional_and_n_anesthesias, setregional_and_n_anesthesias] = useState([])
    const [checklists, setChecklists] = useState([])
    const [localanesthesia, setLocalanesthesia] = useState([])
    const [fluids, setFluid] = useState([])
    const [calculations_and_rs, setCalculations_and_rs] = useState([])
    const [alertVisible, setAlertVisible] = useState(false);
    const [message, setMessage] = useState('');
    //redux
    const favouriteArray = useSelector(state => state.favourite.favourites)
    const actionSummaryType = useSelector(state => state.favourite.actionSummaryType)
    const categorySelected = useSelector(state => state.favourite.categorySelected)

    const dispatch = useDispatch()

    const refresh = () => {
        // it re-renders the component
        setValue({});
    }
    useEffect(() => {
        getActionLibrary()
        var urlParams = new URLSearchParams(window.location.search)
        dispatch(setActionSummaryType(urlParams.get('addinto')))
    }, [])


    const closeAlert = () => {
        setAlertVisible(false);
    }
    const getActionLibrary = () => {
        var token = sessionStorage.getItem("token")
        fetch(API_ROOT + `action-library-data?token=${token}`, {
            method: 'GET',

            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(res => {
                if (res.status === "true") {
                    const obj = [
                        {
                            name: "AIRWAY & VENTILATION",
                            displayName: "AIRWAY & VENTILATION",
                            data: res.data.airwayAndEquipments
                        },
                        {
                            name: "Calculations And Scores",
                            displayName: "CALCULATIONS AND SCORES",
                            data: res.data.calculations_and_rs,
                        },
                        {
                            name: "Crises",
                            displayName: "CRISES",
                            data: res.data.crises
                        },

                        {
                            name: "Drugs",
                            displayName: "DRUGS",
                            data: res.data.drugs
                        },

                        {
                            name: "Fluids And Transfusions",
                            displayName: "FLUIDS AND TRANSFUSIONS",
                            data: res.data.fluids_and_transfusions
                        },
                        // {
                        //     name: "Case Tips",
                        //     displayName: "CASE TIPS",
                        //     data: res.data.caseTips
                        // },
                        // {
                        //     name: "Preoperative Evaluation",
                        //     displayName: "PREOPERATIVE EVALUATION",
                        //     data: res.data.preoperativeEvaluations
                        // }
                        // {
                        //     name: "Preoperative Evaluation",
                        //     displayName: "PREOPERATIVE EVALUATION",
                        //     data: [] //res.data.preoperativeEvaluations
                        // },
                        // {
                        //     name: "Risk Evaluation",
                        //     displayName: "RISK EVALUATION",
                        //     data: []
                        // },
                        , {
                            name: "Regional Anesthesia",
                            displayName: "REGIONAL & NEURAXIAL ANESTHESIA",
                            data: res.data.regionalAndNAnesthesia
                        },
                        // {
                        //     name: "Checklists",
                        //     displayName: "CHECKLISTS",
                        //     data: res.data.checklists
                        // },
                        // {
                        //     name: "Local Anesthesia",
                        //     displayName: "LOCAL ANESTHESIA",
                        //     data: res.data.local_anesthetic_max
                        // },

                        
                    ]


                    setactionLibraryData(obj);
                    setLoading(true);
                }
            })
    }

    const removeSelectedItems = (name) => {
        let filterData = actionSummaryData.filter(data => name !== data)
        setactionSummaryData(filterData)
    }



    const burgerMenuClick = () => {
        props.history.push('/drawer')

    }

    // const onClickCategory = (name) => {
    //     setsubId(null)
    //     setsubName(name)
    //     dispatch(setActionSummaryCategory(name))
    //     for (var i = 0; i < actionLibraryData.length; i++) {
    //         if (actionLibraryData[i].name === name) {
    //             setsubCategory(actionLibraryData[i].data)
    //             setSub(!isSub)

    //         }
    //     }
    // }
    const onClickCategory = (name) => {
        if (name === 'Preoperative Evaluation') {
            return false;
        }
        if (name === 'Risk Evaluation') {
            return false;
        } else {
        setsubName(name);
        dispatch(setActionSummaryCategory(name))
        actionLibraryData.forEach((al) => {

            if (al.name != "Preoperative Evaluation" && al.name === name) {
                if (al.data){
                setsubId(null);
                let sortedSubCrises = al.data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                setsubCategory(sortedSubCrises)
                setSub(true);
                }
            }
            })
    }
    return true
}

    const onClickSubCategory = (id, name,selectedCategory) => {
        setsubId(id)
        for (var i = 0; i < subCategory.length; i++) {
            if (subCategory[i].id === id  && name === "Crises") {
                if (subCategory[i].children) {
                setsubsub(true)
                let sortedSubSubCrises = subCategory[i].children.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                // sortedSubSubCrises.forEach(element => {
                //     element.select = false
                // })
                setsubsubCategory(sortedSubSubCrises)
            }
            } else if (subCategory[i].id === id && name === "Case Tips") {
                if (subCategory[i].children) {
                setsubsub(true)
                // subCategory[i].children.forEach(element => {
                //     element.select = false
                // })
                setsubsubCategory(subCategory[i].children)
                }
            } else if (subCategory[i].id === id && name === "AIRWAY & VENTILATION") {
                if (subCategory[i].children) {
                setsubsub(true)
                setsubsubCategory(subCategory[i].children)
                }
            } else if (subCategory[i].id === id && name === "Drugs") {
                if (subCategory[i].children) {
                setsubsub(true)
                // subCategory[i].children.forEach(element => {
                //     element.name = element.drugName;
                // });
                setsubsubCategory(subCategory[i].children)
            }

            } 
            // else if (subCategory[i].id === id && name === "Preoperative Evaluation") {
            //     if (subCategory[i].children) {
            //     setsubsub(true)
            //     setsubsubCategory(subCategory[i].children)
            //     }

            // } 
            else if (subCategory[i].id === id && name === "Regional Anesthesia") {
                if (subCategory[i].children) {
                 setsubsub(true)
                setsubsubCategory(subCategory[i].children)
                }
            }
            else if (subCategory[i].id === id && name === "Checklists") {
                if (subCategory[i].children) {
                setsubsub(true)
                setsubsubCategory(subCategory[i].children)
                }

            }
            else if (subCategory[i].id === id && name === "Local Anesthesia") {
                if (subCategory[i].children) {
                setsubsub(true)
                setsubsubCategory(subCategory[i].children)
                }
            }
            else if (subCategory[i].id === id && name === "Fluids And Transfusions") {
                if(selectedCategory==="Fluids & Transfusions Calculators"){
                    if (subCategory[i].children) {
                    setsubsub(true)
                    setsubsubCategory(subCategory[i].children)
                    }
    
                }
            }
            else if (subCategory[i].id === id && name === "Calculations And Scores") {
                if (subCategory[i].children) {
                setsubsub(true)
                // subCategory[i].children.forEach(element => {
                //     element.select = false
                // })
                setsubsubCategory(subCategory[i].children)
            }
            }
        }
        
    }

    const lastListClicked = (id, dataid, name, drug_name) => {
        setsubsubId(id)
        for (var i = 0; i < subsubCategory.length; i++) {
            if (subsubCategory[i].id === id && name === "Drugs") {
                if (subsubCategory[i].children) {
                // setsubsubsub(true)
                // subsubCategory[i].children.forEach(element => {
                //     element.select = false
                // })
                setsubsubsubCategory(subsubCategory[i].children)
            }
            }
            else if (subsubCategory[i].id === id && name === "AIRWAY & VENTILATION") {
                // setsubsubsub(true)
                if (subsubCategory[i].children) {

                setsubsubsubCategory(subsubCategory[i].children)
                }
            }
            else if (subsubCategory[i].id === id && name === "Case Tips") {
                // setsubsubsub(true)
                if (subsubCategory[i].children) {

                // subsubCategory[i].children.forEach(element => {
                //     element.select = false
                // })
                setsubsubsubCategory(subsubCategory[i].children)
            }
            }
            else if (subsubCategory[i].id === id && name === "Checklists") {
                if (subsubCategory[i].children) {
                // setsubsubsub(true)
                // subsubCategory[i].children.forEach(element => {
                //     element.select = false
                // })
                setsubsubsubCategory(subsubCategory[i].children)
            }
            }
            else if (subsubCategory[i].id === id && name === "Regional Anesthesia") {
                // setsubsubsub(true)
                if (subsubCategory[i].children) {
                setsubsubsubCategory(subsubCategory[i].children)
                }
            }
            else if (subsubCategory[i].id === id && name === "Local Anesthesia") {
                // setsubsubsub(true)
                if (subsubCategory[i].children) {
                setsubsubsubCategory(subsubCategory[i].children)
                }
            }
            else if (subsubCategory[i].id === id && name === "Fluids And Transfusions") {
                // setsubsubsub(true)
                if (subsubCategory[i].children) {

                setsubsubsubCategory(subsubCategory[i].children)
                }

            }
            setsubsubsub(!subsubsub)
        }
    }

    const handleNewPage = () => {

    }

    const lastLastClick = (id, drug_name, name, subName) => {
        setSub4Id(id)
        for (var i = 0; i < subsubsubCategory.length; i++) {

            if (subsubsubCategory[i].id === id && name === "AIRWAY & VENTILATION") {
                // setSub4(true)
                if (subsubsubCategory[i].children) {

                // subsubsubCategory[i].children.forEach(element => {
                //     element.select = false
                // })
                setSub4Category(subsubsubCategory[i].children)
            }
            }
            else if (subsubsubCategory[i].id === id && name === "Regional Anesthesia") {
                // setSub4(true)
                if (subsubsubCategory[i].children) {

                // subsubsubCategory[i].children.forEach(element => {
                //     element.select = false
                // })
                setSub4Category(subsubsubCategory[i].children)
            }
            }
            else if (subsubsubCategory[i].id === id && name === "Fluids And Transfusions") {
                // setSub4(true)
                if (subsubsubCategory[i].children) {

                // subsubsubCategory[i].children.forEach(element => {
                //     element.select = false
                // })
                setSub4Category(subsubsubCategory[i].children)
            }
            }
            setSub4(!sub4)
        }
    }

    const lastestClick = () => {

    }




    const sendDataToActionSummary = () => {
        let surgery_histories_data = []
        actionSummaryData.map((data) => {
            surgery_histories_data.push(data)
        })
        dispatch(setExtraFavourites(surgery_histories_data))
        if (actionSummaryType !== "favourite") {
            if (actionSummaryType !== "riskevalution") {
                if (actionSummaryType === "premedication") {
                    props.history.push({
                        pathname: '/premedication',
                        state: { actionSummaryType: actionSummaryType, actionSummary: surgery_histories_data, categorySelected: subName }
                    })

                } else {
                    if (actionSummaryType === "induction") {
                        props.history.push({
                            pathname: '/induction',
                            state: { actionSummaryType: actionSummaryType, actionSummary: surgery_histories_data, categorySelected: subName }
                        })
                    } else {
                        props.history.push({
                            pathname: '/startacase/actionsummary',
                            state: { actionSummaryType: actionSummaryType, actionSummary: surgery_histories_data }
                        })
                    }
                }
            } else {
                props.history.push({
                    pathname: '/riskevalution',
                    state: { actionSummaryType: actionSummaryType, actionSummary: surgery_histories_data, categorySelected: subName }
                })

            }
        } else {
            if (categorySelected.length) {
                addnewData()
            }

            props.history.push({
                pathname: '/favourite',
                state: { actionSummaryType: actionSummaryType, actionSummary: surgery_histories_data, categorySelected: "true" }
            })
        }


    }

    const addnewData = () => {
        var tp = [...new Set(categorySelected)]
        for (var i = 0; i < tp.length; i++) {
            if (actionSummaryType === "favourite") {
                if (tp[i] === "Crises") {
                    if (actionSummaryData.length > 0) {
                    actionSummaryData.map((data) => {
                        if (data.parentName === "Crises") {
                            let obj = {
                                id: '',
                                name: ''
                            }
                            obj.id = data.id
                            obj.name = data.name
                            crises.push(obj)
                        }
                    })
                }
                } else if (tp[i] === "Case Tips") {
                    if (actionSummaryData.length > 0) {
                    actionSummaryData.map((data) => {
                        let obj = {
                            id: '',
                            name: ''
                        }
                        obj.id = data.id
                        obj.name = data.name
                        case_tips.push(obj)
                    })
                }
                } else if (tp[i] === "AIRWAY & VENTILATION") {
                    if (actionSummaryData.length > 0) {
                    actionSummaryData.map((data, key) => {
                        if (data.parentName === "AIRWAY & VENTILATION") {
                            let obj = {
                                id: '',
                                name: '',
                                index: '',
                            }
                            obj.id = data.id;
                            obj.name = data.name;
                            obj.index = data.id;
                            airway_and_equipments.push(obj)
                        }
                    })
                }

                } else if (tp[i] === "Drugs") {
                    if (actionSummaryData.length > 0) {
                    actionSummaryData.map((data) => {
                        if (data.parentName === "Drugs") {
                            let obj = {
                                id: '',
                                drug_name: ''
                            }
                            obj.id = data.id
                            obj.drug_name = data.name
                            drugs.push(obj)
                        }
                    })
                }
                }
                else if (tp === "Preoperative Evaluation") {
                    if (actionSummaryData.length > 0) {
                    actionSummaryData.map((data) => {
                        let obj = {
                            id: '',
                            name: ''
                        }
                        obj.id = data.id
                        obj.name = data.name
                        obj.is_star = "false"
                        obj.user_fav= null
                        obj.user_fav_table= "uf_preop_evals"
                        obj.foreign_key= "preop_evals_id"
                        preoperative_evaluations.push(obj)
                    })
                }
                }
                else if (tp[i] === "Regional Anesthesia") {
                    if (actionSummaryData.length > 0) {
                    actionSummaryData.map((data) => {
                        let obj = {
                            id: '',
                            name: ''
                        }
                        obj.id = data.id
                        obj.name = data.name
                        regional_and_n_anesthesias.push(obj)
                    })
                }

                }
                else if (tp[i] === "Checklists") {
                    if (actionSummaryData.length > 0) {
                    actionSummaryData.map((data) => {
                        let obj = {
                            id: '',
                            name: ''
                        }
                        obj.id = data.id
                        obj.name = data.name
                        checklists.push(obj)
                    })

                }
                }
                else if (tp[i] === "Local Anesthesia") {
                    if (actionSummaryData.length > 0) {
                    actionSummaryData.map((data) => {
                        let obj = {
                            id: '',
                            name: ''
                        }
                        obj.id = data.id
                        obj.name = data.name
                        localanesthesia.push(obj)
                    })
                }

                }

                else if (tp[i] === "Calculations And Scores") {
                    if (actionSummaryData.length > 0) {
                    actionSummaryData.map((data) => {
                       
                        let obj = {
                            id: '',
                            name: ''
                        }
                        obj.id = data.id
                        obj.name = data.name
                        calculations_and_rs.push(obj)
                    })
                    
                }

                }

                else if (tp[i] === "Fluids And Transfusions") {
                    if (actionSummaryData.length > 0) {
                    actionSummaryData.map((data) => {
                        if (data.parentName === "Fluids And Transfusions") {
                            let obj = {
                                id: '',
                                name: ''
                            }
                            obj.id = data.id
                            obj.name = data.name
                            fluids.push(obj)
                        }
                    })
                }

                }
            }
        }
        saveFavouriteList()

    }

    const saveFavouriteList = () => {
        let airwayandequipmentsForRequest = []
        airway_and_equipments.map((data) => {
            let obj = {
                id: ''
            }

            obj.id = data.id
            airwayandequipmentsForRequest.push(obj)
        })

        let casetipsForRequest = []
        case_tips.map((data) => {
            let obj = {
                id: ''
            }

            obj.id = data.id
            casetipsForRequest.push(obj)
        })

        let crisesForRequest = []
        crises.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        crises.map((data) => {
            let obj = {
                id: ''
            }

            obj.id = data.id
            crisesForRequest.push(obj)
        })

        let drugsForRequest = []
        drugs.sort((a, b) => (a.drug_name > b.drug_name) ? 1 : ((b.drug_name > a.drug_name) ? -1 : 0));

        drugs.map((data) => {
            let obj = {
                id: ''
            }

            obj.id = data.id
            drugsForRequest.push(obj)
        })

        let preoperative_evaluationForRequest = []
        preoperative_evaluations.map((data) => {
            let obj = {
                id: ''
            }

            obj.id = data.id
            preoperative_evaluationForRequest.push(obj)
        })

        let regional_and_neuraxial_anesthesiaForRequest = []
        regional_and_n_anesthesias.map((data) => {
            let obj = {
                id: ''
            }

            obj.id = data.id
            regional_and_neuraxial_anesthesiaForRequest.push(obj)
        })
        let checklistsForRequest = []
        checklists.map((data) => {
            let obj = {
                id: ''
            }

            obj.id = data.id
            checklistsForRequest.push(obj)
        })
        let localAnesthesiaForRequest = []
        localanesthesia.map((data) => {
            let obj = {
                id: ''
            }

            obj.id = data.id
            localAnesthesiaForRequest.push(obj)
        })
        let fluidnTransForRequest = []
        fluids.map((data) => {
            let obj = {
                id: ''
            }

            obj.id = data.id
            fluidnTransForRequest.push(obj)
        })
        let calculationForRequest = []
        calculations_and_rs.map((data) => {
            let obj = {
                id: ''
            }

            obj.id = data.id
            calculationForRequest.push(obj)
        })
        var token = sessionStorage.getItem("token")
        const body = JSON.stringify({
            crises: crisesForRequest,
            case_tips: casetipsForRequest,
            airway_and_equipments: airwayandequipmentsForRequest,
            drugs: drugsForRequest,
            preoperative_evaluation: preoperative_evaluationForRequest,
            regional_and_neuraxial_anesthesia: regional_and_neuraxial_anesthesiaForRequest,
            fluid_transfusions: fluidnTransForRequest,
            checklists: checklistsForRequest,
            calculations_and_rs: calculationForRequest,
        })
        fetch(API_ROOT + `save-user-favourites?token=${token}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        })
            .then(response => response.json())
            .then(res => {
                if (res.status === "true") {
                    let msg = res.message
                    alert(msg);

                }
            })




    }





    const addintoActionSummary = async(id, user_fav_table, foreign_key, is_star, name) => {
        console.log(id, user_fav_table, foreign_key, is_star, name)
        var token = sessionStorage.getItem("token")
        if (is_star === "0"||is_star===null) {
            
            let body = {
                "id": id,
                "user_fav_table": user_fav_table,
                "foreign_key": foreign_key,
                "user_fav": true,
            }
            let response = await Axios.post(API_ROOT + `save-user-favourite?token=${token}`, body)
            if (response.data.status === "true") {
                alert(response.data.message);
                props.history.push({
                    pathname: '/favourite'
                })
            } else {
                alert(response.data.message);
            }  
        } else if (is_star === "1") {
            let body = {
                "id": id,
                "user_fav_table": user_fav_table,
                "foreign_key": foreign_key,
                "user_fav": false,
            }
            let response = await Axios.post(API_ROOT + `save-user-favourite?token=${token}`, body)
            if (response.data.status === "true") {
                alert(response.data.message);
                props.history.push({
                                             pathname: '/favourite'
                                         })
            } else {
                alert(response.data.message);
            }
        }
    }

    let ArrayIgnore = ["CASE TIPS", 'PREOPERATIVE EVALUATION', "REGIONAL ANESTHESIA", "CHECKLISTS", "LOCAL ANESTHESIA", "CALCULATIONS AND SCORES"]

    return (
        <div  >
            <div className="backNavigation text-center">
                <Link className="arrow" to="/favourite"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Favourites</span></span></Link>ALL ACTIONS FOR ACTION SUMMARY
            </div>
            <div className="all-action-container w-100 mt-3" >

                {/* <div className="btn-group">
                    <button className="btn sub-nav active"><Link to='/startacase/casesummary'>
                        CASE SUMMARY
                    </Link>
                    </button>
                    <button className="btn sub-nav">
                        <Link to='/startacase/actionsummary'>
                            ACTION SUMMARY
                        </Link>
                    </button>
                </div> */}
                <div className="main-container allactons  mt-3">
                    <div className="w-100">
                        {/* <div className="action-library row" >
                            <div className="col-md-6 col-sm-12">
                                Action Library
                            </div>
                            <div className="col-md-6 col-sm-12"></div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-12 col-sm-12">

                                <div className="actionsTree-container">
                                    {loading ?
                                        (actionLibraryData).map((data) => {
                                            return (
                                                <div  >
                                                    {
                                                        <>
                                                            {isSub && subName === data.name ?
                                                                <div className="all-action-subcontainer-content" onClick={() => {
                                                                    if (isSub === false) {
                                                                        onClickCategory(data.name)
                                                                    } else {
                                                                        if (subName !== data.name) {
                                                                            onClickCategory(data.name)

                                                                        } else {
                                                                            setSub(!isSub)
                                                                        }
                                                                    }

                                                                }}>
                                                                    <i className="material-icons down-icon" >play_arrow</i>
                                                                    <div style={{ cursor: "pointer", fontWeight: "bold" }}  >{data.displayName}</div>
                                                                    
                                                                </div>

                                                                :<>{
                                                                    (data.name === 'Preoperative Evaluation' || data.name === 'Risk Evaluation') ?
                                                                    <div className="all-action-subcontainer-content">
                                                                    <i className="material-icons dropdown-icon" >play_arrow</i>
                                                                    <div style={{ cursor: "pointer", width: '70%' }}  >{data.displayName}</div>
                                                                    <div>{
                                                                   data.user_fav == -1 ? <i onClick={() => {  addintoActionSummary(1, "uf_preop_evals", "preop_evals_id", "1", data.name) }} class="material-icons">star</i> : <i onClick={() => {  addintoActionSummary(1, "uf_preop_evals", "preop_evals_id", "0", data.name) }} class="material-icons">star_border</i>  
                                                                     } </div>
                                                                </div>
                                                                    :   
                                                                <div className="all-action-subcontainer-content" onClick={() => {
                                                                    if (isSub === false) {
                                                                        onClickCategory(data.name)
                                                                    } else {
                                                                        if (subName !== data.name) {
                                                                            onClickCategory(data.name)

                                                                        } else {
                                                                            setSub(!isSub)
                                                                        }
                                                                    }
                                                                }}>
                                                                    <i className="material-icons dropdown-icon" >play_arrow</i>
                                                                    <div style={{ cursor: "pointer" }}  >{data.displayName}</div>
                                                                </div>
                                                               
                                        }
                                                                </>

                                                            }
                                                            
                                                        </>
                                                    }

                                                    {isSub && subName === data.name ?
                                                        subCategory.map((data1) => {
                                                           
                                                            return (
                                                                <div>
                                                                    {
                                                                        subId == data1.id && subsub ?

                                                                            <div style={{ paddingLeft: 40 }} className="all-action-subcontainer-content" onClick={() => { subsub === false ? onClickSubCategory(data1.id, data.name, data1.name) : setsubsub(!subsub) }}>
                                                                                <i className="material-icons down-icon" >play_arrow</i>
                                                                                <div style={{ cursor: "pointer", fontWeight: "bold", width: '70%' }}  >{data1.name ? data1.name : data1.drug_name}</div>
                                                                                {/* {(subName === "Drugs" && data1.drug_name !== "ANTIBIOTICS") ?
                                                                                    <div>
                                                                                    {
                                                                                        data1.user_fav==="1" ? <i onClick={() => {  addintoActionSummary(data1.id, data1.user_fav_table, data1.foreign_key, data1.user_fav, data.name, data?.children) }} class="material-icons">star</i> : <i onClick={() => {  addintoActionSummary(data1.id, data1.user_fav_table, data1.foreign_key, data1.user_fav, data.name, data?.children) }} class="material-icons">star_border</i>
                                                                                    }
                                                                                    </div>
                                                                                    :
                                                                                    <>
                                                                                    </>
                                                                                } */}
                                                                                

                                                                            </div> :
                                                                            <div style={{ paddingLeft: 40 }} className="all-action-subcontainer-content" onClick={() => { subsub === false ? onClickSubCategory(data1.id, data.name, data1.name) : setsubsub(!subsub) }} >
                                                                                <i className="material-icons dropdown-icon" >play_arrow</i>
                                                                                <div style={{ cursor: "pointer", width: '70%' }}  >{data1.name ? data1.name : data1.drug_name}</div>

                                                                                {( subName === "Drugs" && data1.name !== "ANTIBIOTICS") ?
                                                                                    <>
                                                                                        {
                                                                                            data1.user_fav==="1" ? <i onClick={() => { addintoActionSummary(data1.id, data1.user_fav_table, data1.foreign_key, data1.user_fav, data.name, data?.children) }} class="material-icons">star</i> : <i onClick={() => { addintoActionSummary(data1.id, data1.user_fav_table, data1.foreign_key, data1.user_fav, data.name, data?.children) }} class="material-icons">star_border</i>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                    </>
                                                                                }
                                                                            </div>}

                                                                    {
                                                                        data.name === "Crises" && subsub && data1.id === subId ?
                                                                            subsubCategory.map((data2) => {
                                                                                return (
                                                                                    <div style={{ paddingLeft: 60 }} className="all-action-subcontainer-content-for-subcategory" onClick={() => lastListClicked(data2.id, data1.id, data.name, data2.name)} >
                                                                                        {/* <div className="sub-category-circle" ></div> */}
                                                                                        <i className="material-icons dropdown-icon">play_arrow</i>
                                                                                        <div style={{ cursor: "pointer", width: '70%' }} >{data2.name}</div>
                                                                                        <div>
                                                                                        {
                                                                                            data2.user_fav==="1" ? <i onClick={() => {  addintoActionSummary(data2.id, data2.user_fav_table, data2.foreign_key, data2.user_fav, data.name) }} class="material-icons">star</i> : <i onClick={() => {  addintoActionSummary(data2.id, data2.user_fav_table, data2.foreign_key, data2.user_fav, data.name) }} class="material-icons">star_border</i>
                                                                                        }
                                                                                    </div>
                                                                                    </div>
                                                                                )
                                                                            }) : <></>

                                                                    }
                                                                    {
                                                                        data.name === "Case Tips" && subsub && data1.id === subId ?
                                                                            subsubCategory.map((data2) => {
                                                                                return (
                                                                                    <div>
                                                                                        <div style={{ paddingLeft: 60 }} className="all-action-subcontainer-content-for-subcategory" onClick={() => lastListClicked(data2.id, data1.id, data.name, data2.name)}>
                                                                                            <i className="material-icons dropdown-icon" >play_arrow</i>

                                                                                            <div style={{ cursor: "pointer" }} >{data2.name}</div>
                                                                                            
                                                                                        </div>
                                                                                        {
                                                                                            subsubsub && subsubId === data2.id ?

                                                                                                subsubsubCategory.map((data3) => {
                                                                                                    return (
                                                                                                        <div style={{ paddingLeft: 90 }} className="all-action-subcontainer-content-for-subcategory" >
                                                                                                            <i className="material-icons dropdown-icon" >play_arrow</i>
                                                                                                            <div onClick={() => handleNewPage(data3.id, data.name)} style={{ cursor: "pointer", width: '70%' }}>{data3.name}</div>
                                                                                                            <div>
                                                                                                            {
                                                                                                                data3.user_fav==="1" ? <i onClick={() => { addintoActionSummary(data3.id, data3.user_fav_table, data3.foreign_key, data3.user_fav, data.name) }} class="material-icons">star</i> : <i onClick={() => { data3.select = true; addintoActionSummary(data3.id, data3.user_fav_table, data3.foreign_key, data3.user_fav, data.name) }} class="material-icons">star_border</i>
                                                                                                            }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                                : <></>
                                                                                        }
                                                                                    </div>

                                                                                )
                                                                            }) : <></>
                                                                    }
                                                                    {
                                                                        data.name === "AIRWAY & VENTILATION" && subsub && data1.id === subId ?
                                                                            subsubCategory.map((data2) => {
                                                                                return (
                                                                                    <div>
                                                                                        <div style={{ paddingLeft: 60 }} className="all-action-subcontainer-content-for-subcategory" onClick={() => lastListClicked(data2.id, data1.id, data.name)}>
                                                                                            {/* <div className="sub-category-circle" ></div> */}
                                                                                            <i className="material-icons dropdown-icon" style={{ color: '#CFCFCF' }} >play_arrow</i>
                                                                                            <div style={{ cursor: "pointer", width: '70%' }} >{data2.name}</div>
                                                                                            <div>
                                                                                                            {
                                                                                                                data2.user_fav==="1" ? <i onClick={() => {  addintoActionSummary(data2.id, data2.user_fav_table, data2.foreign_key, data2.user_fav, data.name) }} class="material-icons">star</i> : <i onClick={() => {  addintoActionSummary(data2.id, data2.user_fav_table, data2.foreign_key, data2.user_fav, data.name) }} class="material-icons">star_border</i>
                                                                                                            }
                                                                                            </div>

                                                                                        </div>
                                                                                        {
                                                                                            subsubsub && subsubId === data2.id ?

                                                                                                subsubsubCategory.map((data3) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <div className="sub-category-circle" style={{ marginLeft: 95, position: "relative", top: 18 }} ></div>
                                                                                                            <i className="material-icons dropdown-icon" style={{ color: '#CFCFCF' }} >play_arrow</i>
                                                                                                            <div onClick={() => lastLastClick(data3.id, data2.id, data.name)} style={{ cursor: "pointer", marginLeft: 110 }}>{data3.name}</div>
                                                                                                            {
                                                                                                                data3.user_fav==="1" ? <i onClick={() => {  addintoActionSummary(data3.id, data3.user_fav_table, data3.foreign_key, data3.user_fav, data.name) }} class="material-icons">star</i> : <i onClick={() => {  addintoActionSummary(data3.id, data3.user_fav_table, data3.foreign_key, data3.user_fav, data.name) }} class="material-icons">star_border</i>
                                                                                        }
                                                                                                            <div>
                                                                                                                {
                                                                                                                    sub4 && sub4Id === data3.id ?
                                                                                                                        sub4Category.map((data4) => {
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    <div className="sub-category-circle" style={{ marginLeft: 130, position: "relative", top: 18 }} ></div>
                                                                                                                                    <div style={{ marginLeft: 150 }}>{data4.name}</div>
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        })
                                                                                                                        : <></>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                                : <></>
                                                                                        }
                                                                                    </div>

                                                                                )
                                                                            }) : <></>
                                                                    }
                                                                    {
                                                                        data.name === "Drugs" && subsub && data1.id === subId ?
                                                                            subsubCategory.map((data2) => {
                                                                                return (
                                                                                    <div>
                                                                                        <div style={{ paddingLeft: 70, width: '100%' }} className="all-action-subcontainer-content-for-subcategory" onClick={() => lastListClicked(data2.id, data1.id, data.name, data2.drug_name)}>
                                                                                            <i className="material-icons dropdown-icon" style={{ color: '#CFCFCF' }} >play_arrow</i>

                                                                                            <div style={{ cursor: "pointer", width: '70%' }} >{data2.name}</div>

                                                                                        </div>

                                                                                        {
                                                                                            subsubsub && subsubId === data2.id ?

                                                                                                subsubsubCategory.map((data3) => {
                                                                                                    return (
                                                                                                        <> <div style={{ paddingLeft: 90, width: '100%' }} className="all-action-subcontainer-content-for-subcategory" >
                                                                                                            <i className="material-icons dropdown-icon" style={{ color: '#CFCFCF' }} >play_arrow</i>

                                                                                                            <div style={{ cursor: "pointer", width: '69%' }}>{data3.name}</div>
                                                                                                            <div>
                                                                                                            {
                                                                                                                data3.user_fav==="1" ? <i onClick={() => { addintoActionSummary(data3.id, data3.user_fav_table, data3.foreign_key, data3.user_fav, data.name, data?.children) }} class="material-icons">star</i> : <i onClick={() => {  addintoActionSummary(data3.id, data3.user_fav_table, data3.foreign_key, data3.user_fav, data.name) }} class="material-icons">star_border</i>
                                                                                                            }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                                : <></>
                                                                                        }
                                                                                    </div>

                                                                                )
                                                                            }) : <></>
                                                                    }
                                                                    {/* {
                                                                        data.name === "Preoperative Evaluation" 
                                                                        && subsub && data1.id === subId ?
                                                                            subsubCategory.map((data2) => {
                                                                                return (
                                                                                    <div style={{ paddingLeft: 60 }} className="all-action-subcontainer-content-for-subcategory" onClick={() => lastListClicked(data2.id, data1.id, data.name, data2.name)}>
                                                                                        <i className="material-icons dropdown-icon" >play_arrow</i>

                                                                                        <div style={{ cursor: "pointer", width: '70%' }} >{data2.name}</div>
                                                                                        <div>
                                                                                                            {
                                                                                                                data2.user_fav==="1" ? <i onClick={() => {  addintoActionSummary(data2.id, data2.user_fav_table, data2.foreign_key, data2.user_fav, data.name) }} class="material-icons">star</i> : <i onClick={() => {  addintoActionSummary(data2.id, data2.user_fav_table, data2.foreign_key, data2.user_fav, data.name) }} class="material-icons">star_border</i>
                                                                                                            }
                                                                                        </div>

                                                                                    </div>

                                                                                )
                                                                            }) : <></>
                                                                    } */}
                                                                    {
                                                                        data.name === "Regional Anesthesia" && subsub && data1.id === subId ?
                                                                            subsubCategory.map((data2, key) => {
                                                                                return (
                                                                                    <>
                                                                                   {subsubsub && subsubId === data2.id ?
                                                                                <div style={{ paddingLeft: 60 }} className="all-action-list-subcontainer-content" onClick={() => lastListClicked(data2.id, data1.id, data.name, data2.name)}>
                                                                                    <i className="material-icons down-icon" >play_arrow</i>
                                                                                    <div style={{ cursor: "pointer" }}>{data2.name}</div>
                                                                                </div> :
                                                                                <div style={{ paddingLeft: 60 }} className="all-action-list-subcontainer-content" onClick={() => { lastListClicked(data2.id, data1.id, data.name, data2.name, key); }}>
                                                                                    <i className="material-icons dropdown-icon" >play_arrow</i>

                                                                                    <div style={{ cursor: "pointer" }} >{data2.name}</div>

                                                                                </div>
                                                                            }
                                                                                        
                                                                                        {
                                                                                            subsubsub && subsubId === data2.id ?

                                                                                                subsubsubCategory.map((data3) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            
                                                                                                            {/* <div style={{ paddingLeft: 80 }} className="all-action-subcontainer-content-for-subcategory" onClick={() => lastLastClick(data3.id, data2.id, data.name)}>
                                                                                                            <i className="material-icons dropdown-icon" >play_arrow</i>
                                                                                                            <div style={{ cursor: "pointer", width: '70%' }} >{data3.name}</div>
                                                                                                            
                                                                                                            </div> */}
                                                                                                            {sub4 && sub4Id == data3.id ?
                                                                                                        <div style={{ paddingLeft: 80 }} className="all-action-list-subcontainer-content" onClick={() => { sub4 === false ? lastLastClick(data3.id, data2.id, data.name) : setSub4(false) }}>
                                                                                                            <i className="material-icons down-icon" >play_arrow</i>
                                                                                                            <div style={{ cursor: "pointer" }}>{data3.name}</div>

                                                                                                        </div> :
                                                                                                        <div style={{ paddingLeft: 80 }} className="all-action-list-subcontainer-content" onClick={() => { sub4 === false ? lastLastClick(data3.id, data2.id, data.name) : setSub4(false) }}>
                                                                                                            <i className="material-icons dropdown-icon" >play_arrow</i>

                                                                                                            <div style={{ cursor: "pointer" }}>{data3.name}</div>

                                                                                                        </div>
                                                                                                    }
                                                                                                            <div>
                                                                                                                {
                                                                                                                    sub4 && sub4Id === data3.id ?
                                                                                                                        sub4Category.map((data4) => {
                                                                                                                            return (
                                                                                                                                <div style={{ paddingLeft: 100 }} className="all-action-subcontainer-content-for-subcategory" onClick={() => lastestClick(data4.id, data3.id, data4.name, data.name)}>
                                                                                                                                    <i className="material-icons dropdown-icon" >play_arrow</i>
                                                                                                                                    <div style={{ cursor: "pointer", width: '70%' }} >{data4.name}</div>
                                                                                                                                    <div  >
                                                                                                                                    {
                                                                                                                                        data4.user_fav==="1" ? <i onClick={() => {  addintoActionSummary(data4.id, data4.user_fav_table, data4.foreign_key, data4.user_fav, data.name) }} class="material-icons">star</i> : <i onClick={() => {  addintoActionSummary(data4.id, data4.user_fav_table, data4.foreign_key, data4.user_fav, data.name) }} class="material-icons">star_border</i>
                                                                                                                                     }
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        })
                                                                                                                        : <></>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                                : <></>
                                                                                        }
                                                                                    </>

                                                                                )
                                                                            }) : <></>
                                                                    }
                                                                    {/* {
                                                                        data.name === "Checklists" && subsub && data1.id === subId ?
                                                                            subsubCategory.map((data2) => {

                                                                                return (
                                                                                    <div>
                                                                                        <div style={{ paddingLeft: 60 }} className="all-action-list-subcontainer-content" onClick={() => lastListClicked(data2.id, data1.id, data.name)}>
                                                                                        <i className="material-icons dropdown-icon" style={{ color: '#CFCFCF' }} >play_arrow</i>

                                                                                            <div style={{ cursor: "pointer", width:"70%" }} >{data2.name}</div>
                                                                                            <div>
                                                                                            {
                                                                                                data2.user_fav==="1" ? <i onClick={() => {  addintoActionSummary(data2.id, data2.user_fav_table, data2.foreign_key, data2.user_fav, data.name) }} class="material-icons">star</i> : <i onClick={() => {  addintoActionSummary(data2.id, data2.user_fav_table, data2.foreign_key, data2.user_fav, data.name) }} class="material-icons">star_border</i>
                                                                                            }
                                                                                            </div>

                                                                                        </div>
                                                                                        {
                                                                                            subsubsub && subsubId === data2.id ?

                                                                                                subsubsubCategory.map((data3) => {
                                                                                                    return (
                                                                                                        <div style={{ paddingLeft: 80 }} className="all-action-list-subcontainer-content">
                                                                                                            <i className="material-icons dropdown-icon" style={{ color: '#CFCFCF' }} >play_arrow</i>
                                                                                                            <div style={{ cursor: "pointer", width:"70%" }} >{data3.name}</div>
                                                                                                            {
                                                                                                                data3.user_fav==="1" ? <i onClick={() => {  addintoActionSummary(data3.id, data3.user_fav_table, data3.foreign_key, data3.user_fav, data.name) }} class="material-icons">star</i> : <i onClick={() => {  addintoActionSummary(data3.id, data3.user_fav_table, data3.foreign_key, data3.user_fav, data.name) }} class="material-icons">star_border</i>
                                                                                        }
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                                : <></>
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            }) : <></>
                                                                    } */}

                                                                    {
                                                                        data.name === "Fluids And Transfusions" && subsub && data1.id === subId ?
                                                                            subsubCategory.map((data2) => {
                                                                                {/*  */ }
                                                                                return (
                                                                                    <div>
                                                                                        <div>

                                                                                            <div style={{ paddingLeft: 60 }} className="all-action-list-subcontainer-content" onClick={() => lastListClicked(data2.id, data1.id, data.name)}>
                                                                                                {/* <div className="sub-category-circle" ></div> */}
                                                                                                <i className="material-icons dropdown-icon" style={{ color: '#CFCFCF' }} >play_arrow</i>
                                                                                                <div style={{ cursor: "pointer", width: '70%' }} >{data2.name}</div>
                                                                                                <div>
                                                                                                {
                                                                                                    data2.user_fav==="1" ? <i onClick={() => { addintoActionSummary(data2.id, data2.user_fav_table, data2.foreign_key, data2.user_fav, data.name) }} class="material-icons">star</i> : <i onClick={() => {  addintoActionSummary(data2.id, data2.user_fav_table, data2.foreign_key, data2.user_fav, data.name) }} class="material-icons">star_border</i>
                                                                                                }
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                subsubsub && subsubId === data2.id ?

                                                                                                    subsubsubCategory.map((data3) => {
                                                                                                        return (
                                                                                                            <div className="all-action-list-subcontainer-content" onClick={() => lastLastClick(data3.id, data2.id, data.name)} style={{ paddingLeft: 100 }}>
                                                                                                                <i className="material-icons dropdown-icon" style={{ color: '#CFCFCF' }} >play_arrow</i>
                                                                                                                <div style={{ width: '70%' }}>{data3.name}</div>
                                                                                                                {
                                                                                                                    data3.user_fav==="1" ? <i onClick={() => { addintoActionSummary(data3.id, data3.user_fav_table, data3.foreign_key, data3.user_fav, data.name) }} class="material-icons">star</i> : <i onClick={() => {  addintoActionSummary(data3.id, data3.user_fav_table, data3.foreign_key, data3.user_fav, data.name) }} class="material-icons">star_border</i>
                                                                                        }
                                                                                                                <div>
                                                                                                                    {
                                                                                                                        sub4 && sub4Id === data3.id ?
                                                                                                                            sub4Category.map((data4) => {
                                                                                                                                return (
                                                                                                                                    <>
                                                                                                                                        <div className="sub-category-circle" style={{ marginLeft: 165, position: "relative", top: 18 }} ></div>
                                                                                                                                        <div style={{ marginLeft: 180 }}>{data4.name}</div>
                                                                                                                                        {
                                                                                                                                            data4.user_fav==="1" ? <i onClick={() => {  addintoActionSummary(data4.id, data4.user_fav_table, data4.foreign_key, data4.user_fav, data.name) }} class="material-icons">star</i> : <i onClick={() => {  addintoActionSummary(data4.id, data4.user_fav_table, data4.foreign_key, data4.user_fav, data.name) }} class="material-icons">star_border</i>
                                                                                        }
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                            })
                                                                                                                            : <></>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    })
                                                                                                    : <></>
                                                                                            }
                                                                                        </div>



                                                                                    </div>
                                                                                )
                                                                            }) : <></>
                                                                    }
                                                                    {
                                                                        data.name === "Calculations And Scores" && subsub && data1.id === subId ?
                                                                            subsubCategory.map((data2) => {

                                                                                return (<>
                                                                                { (data2.name != "PSYCHIATRIC" && data2.name != "ONCOLOGIC") ?
                                                                                    <div style={{ paddingLeft: 60 }} className="all-action-list-subcontainer-content" onClick={() => lastListClicked(data2.id, data1.id, data2.name)}>
                                                                                        <i className="material-icons dropdown-icon" >play_arrow</i>

                                                                                        <div style={{ cursor: "pointer", width:'70%' }} >{data2.name}</div>
                                                                                        <div>
                                                                                        {
                                                                                            data2.user_fav==="1" ? <i onClick={() => {  addintoActionSummary(data2.id, data2.user_fav_table, data2.foreign_key, data2.user_fav, data.name) }} class="material-icons">star</i> : <i onClick={() => { addintoActionSummary(data2.id, data2.user_fav_table, data2.foreign_key, data2.user_fav, data.name) }} class="material-icons">star_border</i>
                                                                                        }
                                                                                        </div>
                                                                                    </div>:<></>
                                                                    }
                                                                                    </>

                                                                                )
                                                                            }) : <></>
                                                                    }


                                                                </div>
                                                            )
                                                        }) : <></>
                                                    }
                                                </div>
                                            )
                                        }) : <Spinner />
                                    }
                                </div>
                            </div>
                            {/* <div className="col-md-4 pt-3 pl-2 col-sm-12 cart">
                                <div className="stickheader-cart">
                                    <button onClick={() => sendDataToActionSummary()} className="btn submit-button-container" ><i className="fa fa-plus-circle">&nbsp;&nbsp;</i> ADD TO FAVOURITES</button>
                                    {
                                        actionSummaryData.length == 0 ? <div className="list-of-action-summary-no-Select">Nothing selected</div> :

                                            actionSummaryData.map((data) => {
                                                return (
                                                    <div className="list-of-action-summary" ><span onClick={() => removeSelectedItems(data)}><i className="fa fa-close removeIcon"></i></span><span className="text-center">{data.name}</span> <i className="fa fa-check addedIcon m-2"></i></div>
                                                )
                                            })
                                    }
                                </div>
                            </div> */}



                        </div>
                    </div>
                </div>
            </div>
            <Alert isOpen={alertVisible} message={message} closeAlert={() => { closeAlert() }} />

        </div>

    )
}
export default AllActionForActionSummary
