import React from 'react'
import RE1174 from './RE1174/RE1174'
import RE1175 from './RE1175/RE1175'
import RE1176 from './RE1176/RE1176'
import RE1177 from './RE1177/RE1177'
import RE1178 from './RE1178/RE1178'
import RE1179 from './RE1179/RE1179'
import RE1180 from './RE1180/RE1180'
import RE1181 from './RE1181/RE1181'
import RE1182 from './RE1182/RE1182'
import RE1183 from './RE1183/RE1183'
import RE1184 from './RE1184/RE1184'
import RE1185 from './RE1185/RE1185'
import RE1186 from './RE1186/RE1186'
import RE1187 from './RE1187/RE1187'
import RE1188 from './RE1188/RE1188'
import RE1189 from './RE1189/RE1189'
import RE1190 from './RE1190/RE1190'
import RE1191 from './RE1191/RE1191'
import RE1192 from './RE1192/RE1192'
import RE1193 from './RE1193/RE1193'
import RE1194 from './RE1194/RE1194'
import RE1195 from './RE1195/RE1195'
import RE1196 from './RE1196/RE1196'
import RE1197 from './RE1197/RE1197'
import RE1198 from './RE1198/RE1198'
import RE1199 from './RE1199/RE1199'
import RE1200 from './RE1200/RE1200'
import RE1201 from './RE1201/RE1201'
import RE1202 from './RE1202/RE1202'
import RE1203 from './RE1203/RE1203'
import RE1204 from './RE1204/RE1204'
import RE1205 from './RE1205/RE1205'
import RE1206 from './RE1206/RE1206'
import RE1207 from './RE1207/RE1207'
import RE1208 from './RE1208/RE1208'
import RE1209 from './RE1209/RE1209'
import RE1210 from './RE1210/RE1210'
import RE1211 from './RE1211/RE1211'
import RE1212 from './RE1212/RE1212'
import RE1213 from './RE1213/RE1213'
import RE1214 from './RE1214/RE1214'
import RE1215 from './RE1215/RE1215'
import RE1216 from './RE1216/RE1216'
import RE1217 from './RE1217/RE1217'
import RE1218 from './RE1218/RE1218'
import RE1219 from './RE1219/RE1219'
import RE1220 from './RE1220/RE1220'
import RE1221 from './RE1221/RE1221'
import RE1222 from './RE1222/RE1222'
import RE1223 from './RE1223/RE1223'
import RE1224 from './RE1224/RE1224'
import RE1225 from './RE1225/RE1225'
import RE1226 from './RE1226/RE1226'
import RE1227 from './RE1227/RE1227'
import RE1228 from './RE1228/RE1228'
import RE1229 from './RE1229/RE1229'
import RE1230 from './RE1230/RE1230'
import RE1231 from './RE1231/RE1231'
import RE1232 from './RE1232/RE1232'
import RE1233 from './RE1233/RE1233'
import RE1234 from './RE1234/RE1234'
import RE1235 from './RE1235/RE1235'
import RE1236 from './RE1236/RE1236'
import RE1237 from './RE1237/RE1237'
import RE1238 from './RE1238/RE1238'
import RE1239 from './RE1239/RE1239'
import RE1240 from './RE1240/RE1240'
import RE1241 from './RE1241/RE1241'
import RE1242 from './RE1242/RE1242'
import RE1243 from './RE1243/RE1243'
import RE1244 from './RE1244/RE1244'
import RE1245 from './RE1245/RE1245'
import RE1246 from './RE1246/RE1246'
import RE1247 from './RE1247/RE1247'
import RE1248 from './RE1248/RE1248'
import RE1249 from './RE1249/RE1249'
import RE1250 from './RE1250/RE1250'
import RE1251 from './RE1251/RE1251'
import RE1252 from './RE1252/RE1252'
import RE1253 from './RE1253/RE1253'
import RE1254 from './RE1254/RE1254'
import RE1255 from './RE1255/RE1255'
import RE1256 from './RE1256/RE1256'
import RE1257 from './RE1257/RE1257'
import RE1258 from './RE1258/RE1258'
import RE1259 from './RE1259/RE1259'
import RE1260 from './RE1260/RE1260'
import RE1261 from './RE1261/RE1261'
import RE1262 from './RE1262/RE1262'
import RE1263 from './RE1263/RE1263'
import RE1264 from './RE1264/RE1264'
import RE1265 from './RE1265/RE1265'
import RE1266 from './RE1266/RE1266'
import RE1267 from './RE1267/RE1267'

const RiskEvaluation = ({ id }) => {
    const data = {
        RE1174: <RE1174 />,
        RE1175: <RE1175 />,
        RE1176: <RE1176 />,
        RE11_77: <RE1177 />,
        RE1177: <RE1178 />,
        RE1179: <RE1180 />,
        RE1178: <RE1179 />,
        RE1180: <RE1181 />,
        RE1181: <RE1182 />,
        RE1182: <RE1183 />,
        RE1183: <RE1184 />,
        RE1184: <RE1185 />,
        RE1185: <RE1186 />,
        RE1186: <RE1187 />,
        RE1187: <RE1188 />,
        RE1188: <RE1189 />,
        RE1189: <RE1190 />,
        RE1190: <RE1191 />,
        RE1191: <RE1192 />,
        RE1192: <RE1193 />,
        RE1193: <RE1194 />,
        RE1194: <RE1195 />,
        RE1195: <RE1196 />,
        RE1196: <RE1197 />,
        RE1197: <RE1198 />,
        RE1198: <RE1199 />,
        RE1199: <RE1200 />,
        RE1200: <RE1201 />,
        RE1201: <RE1202 />,
        RE1202: <RE1203 />,
        RE1203: <RE1204 />,
        RE1204: <RE1205 />,
        RE1205: <RE1206 />,
        RE1206: <RE1207 />,
        RE1207: <RE1208 />,
        RE1208: <RE1209 />,
        RE1209: <RE1210 />,
        RE1210: <RE1211 />,
        RE1211: <RE1212 />,
        RE1212: <RE1213 />,
        RE1213: <RE1214 />,
        RE1214: <RE1215 />,
        RE1215: <RE1216 />,
        RE1216: <RE1217 />,
        RE1217: <RE1218 />,
        RE1218: <RE1219 />,
        RE1219: <RE1220 />,
        RE1220: <RE1221 />,
        RE1221: <RE1222 />,
        RE1222: <RE1223 />,
        RE1223: <RE1224 />,
        RE1224: <RE1225 />,
        RE1225: <RE1226 />,
        RE1226: <RE1227 />,
        RE1227: <RE1228 />,
        RE1228: <RE1229 />,
        RE1229: <RE1230 />,
        RE1230: <RE1231 />,
        RE1231: <RE1232 />,
        RE1232: <RE1233 />,
        RE1233: <RE1234 />,
        RE1234: <RE1235 />,
        RE1235: <RE1236 />,
        RE1236: <RE1237 />,
        RE1237: <RE1238 />,
        RE1238: <RE1239 />,
        RE1239: <RE1240 />,
        RE1240: <RE1241 />,
        RE1241: <RE1242 />,
        RE1242: <RE1243 />,
        RE1243: <RE1244 />,
        RE1244: <RE1245 />,
        RE1245: <RE1246 />,
        RE1246: <RE1247 />,
        RE1247: <RE1248 />,
        RE1248: <RE1249 />,
        RE1249: <RE1250 />,
        RE1250: <RE1251 />,
        RE1251: <RE1252 />,
        RE1252: <RE1253 />,
        RE1253: <RE1254 />,
        RE1254: <RE1255 />,
        RE1255: <RE1256 />,
        RE1256: <RE1257 />,
        RE1257: <RE1258 />,
        RE1258: <RE1259 />,
        RE1259: <RE1260 />,
        RE1260: <RE1262 />,
        RE1261: <RE1267 />,
        RE1262: <RE1263 />,
        RE1263: <RE1264 />,
        RE1264: <RE1265 />,
        RE1265: <RE1266 />,
    }

    return (
        <React.Fragment>
            {data[id]}
        </React.Fragment>
    )
}

export default RiskEvaluation