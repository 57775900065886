import React,{useEffect,useState} from 'react';
import logo from "../images/logo.png";
import user from "../images/user-imgss.jpg";
import {Link} from 'react-router-dom' 
import Header from '../CustomComponent/Header'
import BurgerMenuModal from '../CustomComponent/BurgerMenuModal'
import {API_ROOT} from '../constants'
import Spinner from '../CustomComponent/Spinner';

const MyProfile = (props) => {
    const [firstName,setfirstName]=useState(null)
    const [lastName,setlastName]=useState(null)
    const [email,setEmail]=useState(null)
    const [countrycode,setCountrycode]=useState(null)
    const [mobile,setMobile]=useState(null)
    const [profileImg,setProfileImg]=useState(null)
    const [profileData, setProfileData]=useState([])
   

    useEffect(() => {
        getProfile()
     }, [])
  

    const getProfile=()=>{
        var token= sessionStorage.getItem("token")
        fetch(API_ROOT+`get-profile` ,{
        method: 'POST',
        headers: {
            'Accept':'application/json',
            'Authorization': 'Bearer ' + token
        }
     
    })
        .then(response => response.json())
        .then(res =>{
            if(res.status==="true"&&res.message==="User profile data."){
            setProfileData(res.data)
            setfirstName(res.data.first_name)
            setlastName(res.data.last_name)
            setEmail(res.data.email)
            setCountrycode(res.data.country_code)
            setMobile(res.data.mobile_number)
            setProfileImg(res.data.profile_photo_url)
            }else{
                alert('data not found.')
            }
        })
    }
    return (
        <>
           
            <div className="backNavigation text-center">
                MY PROFILE
            </div>
            {profileData.length != 0 ?
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-12">
                        <div className="profile-container">
                            <div className="user-profile">
                                <img src={profileImg} className="user-profile-pic"></img>
                                <h3 className="username">{firstName===null?"":firstName}  {lastName===null?"":lastName}</h3>
                                <p className="user-email">{email===null?"":email}</p>
                                <span className="profile_edit_btn"><Link to="/editprofile" ><i  className="fa fa-pencil ">&nbsp;</i> Edit</Link></span>
                            </div>
                            <div className="user-profile-show">
                                <ul>
                                    <li>
                                        <span>First name</span>
                                        <p id="firstname" >{firstName===null?"":firstName} </p>
                                        <hr />
                                    </li>
                                    <li>
                                        <span>Last name</span>
                                        <p id="lastname" >{lastName===null?"":lastName} </p>
                                        <hr />
                                    </li>
                                    <li>
                                        <span>Email address</span>
                                        <p id="email" >{email===null?"":email}  </p>
                                        <hr />
                                    </li>
                                    <li>
                                        <span>Phone number</span>
                                        <p id="phoneNumber" >+{countrycode} {mobile===null? "": mobile} </p>
                                        <hr />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>:<Spinner/>
            }
        </>
    )
}

export default MyProfile;