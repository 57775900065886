import React from 'react';
import { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';
import Logo from '../../../images/logo-1.png';
import Feedback from '../../../Screen/Feedback';
import './UserFeeds.css';
import Spinner from '../../Spinner';
import { API_ROOT } from '../../../constants';
const UserFeeds = (props) => {
    const [show, setShow] = useState(false);
    const [showcontact, setShowcontact] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [name, setName] = useState([]);
    const [email, setEmail] = useState([]);
    const [contactno, setContactNo] = useState([]);
    const [query, setQuery] = useState([]);
    const [loading, setLoading] = useState(false);
    const closeContact = () => setShowcontact(false);
    const openContact = () => setShowcontact(true);

    const sendMessage = () => {
        if (email?.length != 0 && query?.length != 0) {
            var token = sessionStorage.getItem("token");

            let formdata = new FormData();
            setLoading(true)
            formdata.append("name", name);
            formdata.append("email", email);
            formdata.append("contact_number", contactno);
            formdata.append("query", query);


            fetch(API_ROOT + `contact_us`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: formdata

            })
                .then(response => response.json())
                .then(res => {
                    if (res.status_code === 200) {
                        setLoading(false)
                        alert("Thank you for submitting, your query would be answerd shortly !");
                        closeContact();
                    }
                })
        } else {
            alert('Please provide email or query values to submit')
        }
    }
    return (<>
        <hr />
        <div className="row pt-2 stick" style={{ background: 'rgb(169 202 198)', zIndex: 8 }}>
            <div className="col-md-8 col-xs-6">
                <table>
                    <tr>
                        {/* <td className="pt-1 pl-2 pr-1 "><i className="fa fa-thumbs-up text-success" >&nbsp;</i>Like</td>
                <td className="pt-1 pl-2 pr-1"><i className="fa fa-thumbs-down text-danger">&nbsp;</i>Unlike</td> */}
                        <td className="pt-1 pl-2 pr-1 feedback-section" onClick={handleShow} style={{ cursor: 'pointer' }}><i className="fa fa-rss" style={{ color: 'orange' }} title="Feedback">&nbsp;</i>Feedback</td>
                        <td className="pt-1 pl-2 pr-1 feedback-section"  onClick={openContact} style={{ cursor: 'pointer' }}><i className="fa fa-envelope-square" title="Contact Us" style={{ color: '#44948B'}}>&nbsp;</i>Contact Us</td>

                    </tr>
                </table>
            </div>
            <div className="col-md-4 col-xs-6 text-right"><img src={Logo} alt="logo" className="userFeed" /></div>
        </div>
        {show ?
            <div className="static-modal" style={{ backgroundColor: 'rgba(0,0,0,0.1)', width: '100%', zIndex: 11, height: '100vh', left: 0, top: 0, position: 'fixed' }}>
                <Modal.Dialog className="modal-dilogbox"

                >
                    <Modal.Header>
                        <Modal.Title>Please add your valuable feedback/suggestions. </Modal.Title>
                        <i className="fas fa-times" style={{ cursor: 'pointer' }} onClick={handleClose}></i>
                    </Modal.Header>
                    <Feedback calcname={props?.calcname} />
                </Modal.Dialog></div> : <></>
        }
        {showcontact ?
            <div className="static-modal" style={{ backgroundColor: 'rgba(0,0,0,0.1)', width: '100%', zIndex: 11, height: '100vh', left: 0, top: 0, position: 'fixed' }}>
                <Modal.Dialog className="modal-dilogbox"

                >
                    <Modal.Header>
                        <Modal.Title>Please send your Queries/Questions. </Modal.Title>
                        <i className="fas fa-times" style={{ cursor: 'pointer' }} onClick={closeContact}></i>
                    </Modal.Header>
                   
                    <p className="mt-2 mb-2 p-5">We Are Here To Help And Find You The Best Solution For Your Health Problem And Help You Stay Healthy... </p>
                    <div className="form-section container col-lg-8 col-md-8 col-10 mb-5">
                        {loading == true ? <Spinner /> : <></>}
                        <form>
                            <div className="form-group">
                                <label for="exampleInputName">Name</label>
                                <input value={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control" id="exampleInputName" aria-describedby="NameHelp" placeholder="Enter Name" autocomplete="off" />
                            </div>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Email address</label>
                                <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email" autocomplete="off" />
                            </div>
                            <div className="form-group">
                                <label for="exampleInputPhone">Contact Number</label>
                                <input value={contactno} onChange={(e) => setContactNo(e.target.value)} type="tel" className="form-control" id="exampleInputPhone" aria-describedby="telHelp" placeholder="Enter Contact Number" autocomplete="off" />
                            </div>
                            <div className="form-group">
                                <label for="exampleFormControlTextarea1">Your Query</label>
                                <textarea value={query} onChange={(e) => setQuery(e.target.value)} className="form-control" id="exampleFormControlTextarea1" placeholder="Ask Us Anything..." rows="5" autocomplete="off"></textarea>
                            </div>
                            <div className="text-center">
                                <button type="button" className="btn btn-primary" style={{background:'#44948B'}} onClick={sendMessage}>Submit</button>
                            </div>
                        </form>
                    </div>
                </Modal.Dialog></div> : <></>
        }

    </>
    )
}
export default UserFeeds;