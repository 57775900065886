import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';
import { Link } from 'react-router-dom';
import Bops from '../Screen/Bops';
import Bps from '../Screen/Bps';
import Abbey from '../Screen/Abbey';
import Cheops from '../Screen/Cheops';
import GcsAdult from '../Screen/GcsAdult';
import Nvps from '../Screen/Nvps';
import Sms from '../Screen/Sms';
import CiwaAr from '../Screen/CiwaAr';
import StanfordSleepinessScale from '../Screen/StanfordSleepinessScale';
import GcsPediatric from '../Screen/GcsPediatric';
import Nps from '../Screen/Nps';
import Mme from '../Screen/Mme';
const NeurologicalCalculators = (props) => {
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };
    useEffect(() => {
        toggleTab(1);
    }, [])

    return (
        <div>
            <div className="backNavigation text-center">
                <Link className="arrow" to={"/" + props.location.state.returnPath}><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to {props.location.state.sourceName}</span></span></Link> NEUROLOGICAL CALCULATORS
            </div>
            <div className="main-container calculator-container mt-3">
                <div className="row">
                    <div className="col-md-4 airway-calc-tabs">
                    <button className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(1)} >Abbey</button>
                        <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(2)} >BOPS</button>
                        <button className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(3)} >BPS</button>
                        <button className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(4)} >CHEOPS</button>
                        <button className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(5)} >CIWA-Ar</button>
                        <button className={toggleState === 6 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(6)} >GCS - Adult</button>
                        <button className={toggleState === 7 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(7)} >GCS - Pediatric</button>
                        <button className={toggleState === 8 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(8)} >MME</button>
                        <button className={toggleState === 9 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(9)} >NPS</button>
                        <button className={toggleState === 10 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(10)} >NVPS</button>
                         <button className={toggleState === 11 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(11)} >SMS</button>
                            <button className={toggleState === 12 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(12)} >Stanford Sleepiness Scale</button>
                    </div>
                    <div className="col-md-8 col-sm-12">
                        <div className="content-tabs">
                            <div
                                className={toggleState === 1 ? "calculator-content  active-content" : "calculator-content"}>
                              <Abbey/>
                            </div>
                            <div className={toggleState === 2 ? "calculator-content  active-content" : "calculator-content"}>
                            <Bops/>
                            </div>

                            <div
                                className={toggleState === 3 ? "calculator-content  active-content" : "calculator-content"} >
                                <Bps/>
                            </div>

                            <div
                                className={toggleState === 4 ? "calculator-content  active-content" : "calculator-content"}>
                                <Cheops/>
                            </div>
                            <div
                                className={toggleState === 5 ? "calculator-content  active-content" : "calculator-content"}>
                                <CiwaAr/>
                            </div>
                            <div className={toggleState === 6 ? "calculator-content  active-content" : "calculator-content"}>
                            <GcsAdult/>
                            </div>

                            <div
                                className={toggleState === 7 ? "calculator-content  active-content" : "calculator-content"} >
                                <GcsPediatric/>
                            </div>

                            <div
                                className={toggleState === 8 ? "calculator-content  active-content" : "calculator-content"}>
                                <Mme/>
                            </div>
                            <div
                                className={toggleState === 9 ? "calculator-content  active-content" : "calculator-content"}>
                                <Nps/>
                            </div>
                            <div
                                className={toggleState === 10 ? "calculator-content  active-content" : "calculator-content"}>
                                <Nvps/>
                            </div>
                            <div
                                className={toggleState === 11 ? "calculator-content  active-content" : "calculator-content"}>
                                <Sms/>
                            </div>
                            <div
                                className={toggleState === 12 ? "calculator-content  active-content" : "calculator-content"}>
                                <StanfordSleepinessScale/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default NeurologicalCalculators;