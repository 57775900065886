import {
    SET_ACTION_LIBRARY_DATA, PREOP_CLICK, POSTOP_CLICK, INTRAOP_CLICK, SET_SEARCH_DRUG_DATA, SET_ISSUB, SET_SUB_NAME,
    SET_SUB_ID, SET_SUB_CATEGORY, SET_SUB_SUB_CATEGORY, SET_SUB_SUB, SET_SUB_SUB_SUB_CATEGORY, SET_SUB_SUB_ID, SET_SUB_SUB_SUB, SET_SUB4_CATEGORY,
    SET_SUB4, SET_SUB4_ID, SET_DRUG_LIST, SET_LEVEL_1, SET_LEVEL_2, SET_SUB_CRISES,
} from "./ActionsSummaryActionTypes.js"

const initialState = {
    preop: true,
    intraop: false,
    postop: false,
    allActionLibraryData: [],
    allSearchOptions: [],
    isSub: false,
    subName: null,
    subId: null,
    subCategory: [],
    subsubCategory: [],
    subsub: false,
    subsubsubCategory: [],
    subsubId: null,
    subsubsub: false,
    sub4Category: [],
    sub4: false,
    sub4Id: null,
    drugsList: [],
    level1: [],
    level2: [],
    subcrises: [],


}

const actionSummaryReducers = (state = initialState, action) => {
    switch (action.type) {
        case PREOP_CLICK:
            return {
                ...state,
                preop: action.payload,
                intraop: false,
                postop: false

            }

        case POSTOP_CLICK:
            return {
                ...state,
                postop: action.payload,
                preop: false,
                intraop: false

            }
        case INTRAOP_CLICK:
            return {
                ...state,
                intraop: action.payload,
                preop: false,
                postop: false

            }
        case SET_ACTION_LIBRARY_DATA:
            return {
                ...state,
                allActionLibraryData: action.payload
            }
        case SET_SEARCH_DRUG_DATA:
            return {
                ...state,
                allSearchOptions: action.payload
            }
        case SET_ISSUB:
            return {
                ...state,
                isSub: action.payload


            }
        case SET_SUB_NAME:
            return {
                ...state,
                subName: action.payload


            }
        case SET_SUB_ID:
            return {
                ...state,
                subId: action.payload


            }
        case SET_SUB_CATEGORY:
            return {
                ...state,
                subCategory: action.payload
            }
        case SET_SUB_SUB_CATEGORY:
            return {
                ...state,
                subsubCategory: action.payload
            }
        case SET_SUB_SUB:
            return {
                ...state,
                subsub: action.payload


            }
        case SET_SUB_SUB_SUB_CATEGORY:
            return {
                ...state,
                subsubsubCategory: action.payload
            }
        case SET_SUB_SUB_ID:
            return {
                ...state,
                subsubId: action.payload
            }
        case SET_SUB_SUB_SUB:
            return {
                ...state,
                subsubsub: action.payload
            }
        case SET_SUB4_CATEGORY:
            return {
                ...state,
                sub4Category: action.payload
            }
        case SET_SUB4:
            return {
                ...state,
                sub4: action.payload
            }
        case SET_SUB4_ID:
            return {
                ...state,
                sub4Id: action.payload
            }
        case SET_DRUG_LIST:
            return {
                ...state,
                drugsList: action.payload
            }
        case SET_LEVEL_1:
            return {
                ...state,
                level1: action.payload
            }
        case SET_LEVEL_2:
                return {
                    ...state,
                    level2: action.payload
                }    
        case SET_SUB_CRISES:
                return {
                    ...state,
                    subcrises: action.payload
                }    
        default: return state

    }

}

export default actionSummaryReducers