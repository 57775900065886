import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';

const DRIP = () => {
    const [antibiotic, setAntibiotic] = useState(0);
    const [resident, setResident] = useState(0);
    const [feeding, setFeeding] = useState(0);
    const [prior, setPrior] = useState(0);
    const [hospital, setHospital] = useState(0);
    const [chronic, setChronic] = useState(0);
    const [poor, setPoor] = useState(0);
    const [ppi, setPpi] = useState(0);
    const [Active, setActive] = useState(0);
    const [mrsa, setMrsa] = useState(0);



    const [result, setResult] = useState(0);
    const postData = async () => {
        setResult(antibiotic + resident + feeding + prior + hospital + chronic + poor + ppi + Active + mrsa);
    }
    useEffect(() => {
        postData();


    }, [antibiotic, resident, feeding, prior, hospital, chronic, poor, ppi, Active, mrsa]);
    const result1 = () => {

        if (result < 4) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>DRIP Score</div>
                </div>
                <div className="point-result-container" >
                    <h1>Low <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
                    <div>Scores &lt;4 were associated with lower risk of drug-resistant pneumonia. Consider treating without extended-spectrum antibiotics.</div>
                </div>
            </div>
        }
        if ((result >= 4)) {
            return <div className="apfel-score-result-container" >
                <div className="point-result-container" >
                    <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                    <div>DRIP Score</div>
                </div>
                <div className="point-result-container" >
                    <h1>HIGH <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
                    <div>Scores ≥4 were associated with higher risk of drug-resistant pneumonia. Extended-spectrum antibiotics likely necessary.</div>
                </div>
            </div>
        }
        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>
            )
        }

    }

    return (
        <div className="rev">
            <h5 className="text-info pl-2">Drug Resistance in Pneumonia (DRIP) Score</h5>
            <p className="pl-2">Predicts risk for community-acquired pneumonia due to drug-resistant pathogens.</p>
            <div class="alert alert-dark" role="alert">
                Major Risk Factors
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Antibiotic use within 60 days</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={antibiotic === 0 ? "btn active" : "btn"} onClick={() => setAntibiotic(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height:  40   }} key="2" className={antibiotic === 2 ? "btn active" : "btn"} onClick={() => setAntibiotic(2)}>Yes<span className="ml-4 text-muted">+2</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Long term care resident</strong></div>
                    <div>Not including assisted living or group home facilities</div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={resident === 0 ? "btn active" : "btn"} onClick={() => setResident(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height:  40   }}  key="2" className={resident === 2 ? "btn active" : "btn"} onClick={() => setResident(2)}>Yes<span className="ml-4 text-muted">+2</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Tube feeding</strong></div>
                    <div>NG, nasojejunal, or PEG</div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={feeding === 0 ? "btn active" : "btn"} onClick={() => setFeeding(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height:  40   }}key="2" className={feeding === 2 ? "btn active" : "btn"} onClick={() => setFeeding(2)}>Yes<span className="ml-4 text-muted">+2</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Prior drug-resistant pneumonia diagnosis within 1 year</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={prior === 0 ? "btn active" : "btn"} onClick={() => setPrior(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height:  40   }} key="2" className={prior === 2 ? "btn active" : "btn"} onClick={() => setPrior(2)}>Yes<span className="ml-4 text-muted">+2</span></button>
                </div>
            </div>
            <div class="alert alert-dark" role="alert">
                Minor Risk Factors
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Hospitalization within 60 days</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={hospital === 0 ? "btn active" : "btn"} onClick={() => setHospital(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height:  40   }} key="2" className={hospital === 1 ? "btn active" : "btn"} onClick={() => setHospital(1)}>Yes<span className="ml-4 text-muted">+1</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Chronic pulmonary disease</strong></div>

                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={chronic === 0 ? "btn active" : "btn"} onClick={() => setChronic(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height:  40   }} key="2" className={chronic === 1 ? "btn active" : "btn"} onClick={() => setChronic(1)}>Yes<span className="ml-4 text-muted">+1</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Poor functional status
                        Karnofsky Performance Status &lt;70 or non-ambulatory status
                    </strong></div>

                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={poor === 0 ? "btn active" : "btn"} onClick={() => setPoor(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height:  40   }} key="2" className={poor === 1 ? "btn active" : "btn"} onClick={() => setPoor(1)}>Yes<span className="ml-4 text-muted">+1</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>H2 blocker or PPI within 14 days
                    </strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={ppi === 0 ? "btn active" : "btn"} onClick={() => setPpi(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height:  40   }} key="2" className={ppi === 1 ? "btn active" : "btn"} onClick={() => setPpi(1)}>Yes<span className="ml-4 text-muted">+1</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Active wound care at time of admission
                    </strong></div>

                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={Active === 0 ? "btn active" : "btn"} onClick={() => setActive(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height:  40   }} key="2" className={Active === 1 ? "btn active" : "btn"} onClick={() => setActive(1)}>Yes<span className="ml-4 text-muted">+1</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>MRSA colonization within 1 year
                    </strong></div>

                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={mrsa === 0 ? "btn active" : "btn"} onClick={() => setMrsa(0)}>No<span className="ml-4 text-muted">0</span></button>
                    <button style={{ height:  40   }} key="2" className={mrsa === 1 ? "btn active" : "btn"} onClick={() => setMrsa(1)}>Yes<span className="ml-4 text-muted">+1</span></button>
                </div>
            </div>
            <div className="stick pt-3">
                {
                    result1()
                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://journals.asm.org/doi/pdf/10.1128/AAC.03071-15">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Webb BJ, Dascomb K, Stenehjem E, et al. Derivation and Multicenter Validation of the Drug Resistance in Pneumonia Clinical Prediction Score. Antimicrob Agents Chemother. 2016;60(5):2652-63.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default DRIP
