import { React, useState, useEffect } from 'react';
import './RE1222.css';
const RE1222 = () => {
    const [green, setGreen] = useState(false);
    const [weight, setWeight] = useState([]);
    const [height, setHeight] = useState([]);

    const [sex, setSex] = useState([]);
    const [placehldr1, setPlaceholder1] = useState("Norm: 152-213");
    const [placehldr3, setPlacehldr3] = useState("Norm: 120-170");
    const [tunit, setTunit] = useState("kg");
    const [tunit2, setTunit2] = useState("cm");

    const [newweight, setNewweight] = useState(0);
    const [newheight, setNewheight] = useState(0);
    const [result1, setResult1] = useState(0);
    const [result2, setResult2] = useState(0);
    const [result3, setResult3] = useState(0);
    const [result4, setResult4] = useState(0);
    const [result5, setResult5] = useState(0);
    const [result6, setResult6] = useState(0);
    const [changingunit, setChangingunit] = useState("kg");
    const [changingunit2, setChangingunit2] = useState("kg");








    useEffect(() => {
        postData();

    }, [weight, height, sex, placehldr1, placehldr3, newweight, tunit, tunit2, newheight, result2, result3, result1, changingunit, changingunit2, result4])




    const postData = async () => {



        if (tunit === 'kg') {
            if (weight < 273) {
                setNewweight(Math.round((weight) * 100) / 100);

            }
            else {
                setNewweight(0);
            }
        }
        if (tunit === 'lbs') {
            if(weight < 601){
                setNewweight(Math.round((weight / 2.20) * 100) / 100);

            }
            else{
                setNewweight(0);
            }
        }
        if (tunit2 === 'cm') {
            if (height < 229) {
                setNewheight(height / 2.54);

            }
            else {
                setNewheight(0);

            }

        }
        if (tunit2 === 'in') {
            if (height < 90.7638) {
                setNewheight(height);

            }
            else {
                setNewheight(0);

            }

        }
        if (tunit === 'kg' && tunit2 === 'cm') {
            setChangingunit("kg")
        }
        else {
            setChangingunit("lbs")
        }
        if (changingunit === "kg") {
            setChangingunit2("lbs")
        }
        if (changingunit === "lbs") {

            setChangingunit2("kg")
        }


        if (sex === 2) {
            if (newheight > 60) {
                setResult1((50 + 2.3 * (newheight - 60)).toFixed(0));

            }
            else {
                setResult1(50);
            }
            if (newheight > 60) {

                setResult2((result1 * 2.20).toFixed(0))
            }
            else {
                setResult2(110)
            }

        }
        else {
            if (newheight > 60) {
                setResult1(Math.round(45.5 + 2.3 * (newheight - 60)));

            }
            else {
                setResult1(45);
            }

            if ((newheight > 60)) {
                setResult2((result1 * 2.20).toFixed(0))

            }
            else {
                setResult2(100)
            }
        }


        setResult3((parseFloat(result1) + 0.4 * (newweight - result1)).toFixed(0));

        setResult4((parseFloat(result3) * 2.20).toFixed(0));
        setResult5((newweight / result1 * 100).toFixed(0));
        setResult6((newweight / result1).toFixed(1))

        setGreen(true);

    }



    const changeunit = () => {
        if (weight === 0) {
            if (tunit === 'kg') {
                setTunit('lbs');

            }
            if (tunit === 'lbs') {
                setTunit('kg');

            }
        } else if (weight !== 0) {
            if (tunit === 'kg') {

                setTunit('lbs');
                if (weight.length !== 0) {
                    setWeight((weight * 2.2046226218488).toFixed(2));
                }
            }
            if (tunit === 'lbs') {

                setTunit('kg');
                if (weight.length !== 0) {
                    setWeight((weight / 2.2046226218488).toFixed(2));
                }

            }
        }
    }

    const takeUnit = (e) => {
        if (tunit === 'kg') {
            let htcm = (e.target.value);

            setWeight(htcm);


        }
        if (tunit === 'lbs') {
            let htin = (e.target.value);

            setWeight(htin);

        }

    }


    const changeunit2 = () => {
        if (height === 0) {
            if (tunit2 === 'cm') {
                setTunit2('in');

            }
            if (tunit2 === 'in') {
                setTunit2('cm');

            }
        } else if (height !== 0) {
            if (tunit2 === 'cm') {
                setTunit2('in');
                setPlaceholder1("Norm: 60 - 84");
                if (height.length !== 0) {

                    setHeight((height / 2.54).toFixed(2));
                }

            }
            if (tunit2 === 'in') {
                setTunit2('cm');
                setPlaceholder1("Norm: 152 - 213");
                if (height.length !== 0) {

                    setHeight((height * 2.54).toFixed(2));
                }

            }
        }
    }
    const takeunit2 = (e) => {
        if (tunit2 === 'cm') {
            setHeight(e.target.value);

        }
        if (tunit2 === 'in') {
            let ht = e.target.value;
            setHeight(ht);
        }
    }




    const changeResult = () => {
        if (changingunit === "kg") {
            return <>{result1}</>

        }
        if (changingunit === "lbs") {
            return <>{result2}</>

        }
    }
    const changeResult2 = () => {
        if (changingunit2 === "kg") {
            return <>{result1}</>

        }
        if (changingunit2 === "lbs") {
            return <>{result2}</>

        }
    }

    const changeResult3 = () => {
        if (changingunit === "kg") {
            return <>{result3}</>

        }
        if (changingunit === "lbs") {
            return <>{result4}</>

        }
    }
    const changeResult4 = () => {
        if (changingunit2 === "kg") {
            return <>{result3}</>

        }
        if (changingunit2 === "lbs") {
            return <>{result4}</>

        }
    }


    const validation1 = () => {
        if (tunit2 === 'cm') {
            if ((height <= 12) && (height != 0)) {
                return <div className="validate">*Very Low;double check.</div>

            }
            if ((height <= 228) && (height >= 214)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if ((height >= 229)) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(height)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }

        }

        if (tunit2 === 'in') {
            if (height !== 0) {
                if ((height < 48.0315) && (height > 0)) {
                    return <div className="validate">*Too low; please change to proceed.</div>

                }
                if ((height < 90.1575) && (height > 79.9213)) {
                    return <div className="validate">*Very High; double check.</div>

                }
                if (height > 89.7638) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(height)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
    }


    const validation2 = () => {
        if (tunit === 'kg') {
            if ((weight <= 272) && (weight >= 227) && (weight != 0)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (weight >= 273) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(weight)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }

        }


        if (tunit === 'lbs') {
            if ((weight < 55) && (weight != 0)) {
                return <div className="validate">*Too low; please change to proceed.</div>

            }
            if ((weight <= 600) && (weight >= 301)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (weight >= 601) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(weight)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    const showABW = () => {
        if (result1 <= newweight) {
            return <>
                <h3>{changeResult3()}<span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}> {changingunit}</span></h3>
                <div>Adjusted Body Weight</div>
                <br />
                <div>Equivalent to {changeResult4()} {changingunit2}</div>
            </>
        }
        if ((result1 > newweight) && weight != 0) {
            return <div>
                Adjusted body weight only applicable if actual body weight is greater than ideal body weight
            </div>

        }



    }

    return (<div className="rev">
        <h5 className="text-info pl-2">Ideal Body Weight and Adjusted Body Weight</h5>
        <p className="pl-2">Calculates ideal body weight (Devine formula) and adjusted body weight.</p>
        <div className="fields row mb-4">
            <div className="col-md-6">
                <div className="pt-2"><strong>Sex</strong></div>

            </div>
            <div className="col-md-6 btn-group options-btn mb-5 ">
                <button style={{height:40}} onClick={() => setSex(1)} className={sex === 1 ? "btn active" : "btn  "}><span className="btn-name "> Female</span></button>
                <button style={{height:40}} onClick={() => setSex(2)} className={sex === 2 ? "btn  active" : "btn  "}><span className="btn-name "> Male</span></button>

            </div>

        </div>

        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Height</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input style={{ width: "79%" }}
                    onChange={takeunit2}
                    placeholder={placehldr1}
                    className="ant-text-input"
                    type="number"
                    value={height} /><span className="units" onClick={changeunit2}>{tunit2}&#8651;</span>
                <div>{validation1()}</div>

            </div>

        </div>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Actual body weight</strong></div>
                {/* <p>Optional, for calculating adjusted body weight in obese patients</p> */}
            </div>
            <div className="col-md-7 frm-field">
                <input
type="number"
                    onChange={takeUnit}

                    className="ant-text-input" style={{ width: "79%" }} value={weight} /><span className="units" onClick={changeunit}>{tunit}&#8651;</span>

                <div>{validation2()}</div>
            </div>

        </div>




        <div className="stick mt-5">
            {
                sex != 0 && height != 0 && newheight !=0 && newweight != 0 ?
                    <div className="apfel-score-result-container" >
                        <div className="point-result-container" >
                            <h3>{changeResult()}<span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}> {changingunit}</span></h3>
                            <div>Ideal Body Weight</div>
                            <br />
                            <div>Equivalent to {changeResult2()} {changingunit2}</div>
                            <div>{weight != 0 ? <div>Actual body weight is {result5}% ({result6}x) ideal body weight </div> : " "}</div>

                        </div>
                        <div className="point-result-container" >

                            {showABW()}

                        </div>

                    </div>
                    :
                    <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>
            }
        </div>
        <br />
        <div className="p-2 mb-5" >
            <h4>LITERATURE</h4>
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a href="" target="_blank" className="referenceLink">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Devine BJ. Gentamicin therapy. Drug Intell Clin Pharm. 1974;8:650–655.</p>
                </div>
            </a>
        </div>
    </div>
    )
}
export default RE1222;