import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { API_ROOT } from '../constants'
import logo from '../images/logo.png'

const ChangePassword = (props) => {
    const [oldPassword, setoldPassword] = useState(null)
    const [password, setpassword] = useState(null)
    const [confirmPassword, setconfirmPassword] = useState(null)


    const requestChangePassword = () => {
        if (oldPassword === "") {
            alert("Please enter old password")
        } else if (password === "") {
            alert("Please enter new password")
        } else if (confirmPassword === "") {
            alert("Please enter confirm password")
        } else
            if (password === confirmPassword) {
                var token = sessionStorage.getItem("token");
                //var token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hZG1pbi5hbmVzdGhlc2lhb25lLmNvbVwvYXBpXC9sb2dpbiIsImlhdCI6MTYzNTQ5MDkyMiwiZXhwIjoxNjM2NzAwNTIyLCJuYmYiOjE2MzU0OTA5MjIsImp0aSI6IjJ5VW9ReUdGbU43UDVwVFYiLCJzdWIiOjM5LCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.5sqcjt_Vea-cbUqzsmwiYvh06z5uMJwq5nt14SUyrEQ"
                fetch(API_ROOT + `change-password?old_password=${oldPassword}&new_password=${password}`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',

                        'Authorization': 'Bearer ' + token
                    }

                })
                    .then(response => response.json())
                    .then(res => {
                        if (res.status === "true" && res.message === "Password is updated successfully.") {
                            alert("Password is updated successfully.");
                        sessionStorage.removeItem('token')
                            window.location = '/login';

                        }

                    })

            } else {
                alert("password not same...")
            }
    }


    return (
        <>
            <div><style>{"\.header {\ display:none; \ } \ "}</style>
                <header>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 header-right">
                                <h1 className="logo">
                                    <a href="index-after-login.html"><img src={logo} /></a>
                                </h1>
                                {/* <div className="usersDetail">
                        <i className="fii icon-user" aria-hidden="true"></i>
                    </div> */}
                            </div>
                        </div>
                    </div>
                </header>
                <div className="subsBG">
                    <div className="container">
                        <div className="row justify-content-center ">
                            <div className="col-md-5 whtbx sign-up-page">
                                <h3>Change Password</h3>
                                <div className="main-container profile-container ">
                                    <form >
                                        <div className="form-group pt-3">
                                            <label for="exampleInputEmail1">Old Password</label>
                                            <input onChange={(e) => setoldPassword(e.target.value)} type="password" className="form-control" id="exampleInputEmail1" placeholder="Enter Old Password" />
                                        </div>
                                        <div className="form-group ">
                                            <label for="exampleInputPassword1">New Password</label>
                                            <input onChange={(e) => setpassword(e.target.value)} type="password" className="form-control" id="exampleInputPassword1" placeholder="New Password" />
                                        </div>
                                        <div className="form-group pb-3">
                                            <label for="exampleInputPassword1">Confirm New Password</label>
                                            <input onChange={(e) => setconfirmPassword(e.target.value)} type="password" className="form-control" id="exampleInputPassword1" placeholder="Confirm New Password" />
                                        </div>
                                    </form>
                                </div>
                                <button onClick={() => requestChangePassword()} className="btn ">change</button>
                            </div>
                        </div>
                        <p className="stexts text-center">Already have an account? <Link to="/login" >Sign in here</Link></p>
                    </div>
                </div>
                <footer>
                    <p>Calculations must be re-checked and should not be used alone to guide patient care, nor should they substitute for clinical judgment. See our full disclaimer.</p>
                    <span>Anesthesia One © 2023 · All Rights Reserved · <a href="/termsandconditions">Terms of Use </a>· <a href="/privacypolicy">Privacy Policy</a></span>
                </footer>

            </div>
        </>
    )
}

export default ChangePassword;