import React, { useEffect, useState } from 'react'
import '../css/RiskEvalution.css'
import { API_ROOT } from '../constants'
import "../css/crises.css"
import { Link } from 'react-router-dom'
import Spinner from '../CustomComponent/Spinner';



const Crises = (props) => {
    const [actionLibraryData, setactionLibraryData] = useState([])
    const [subname, setSubname] = useState("")
    const [fileName, setFileName] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        var token = sessionStorage.getItem("token")
        fetch(API_ROOT + `action-library-data?token=${token}`, {
            method: 'GET',

            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(res => {
                if (res.status === "true") {
                    // res.data.drugs.forEach(element => {
                    //     element.name = element.name;
                    // });
                   
                    const obj = [{
                        name: "Crises",
                        displayName: "CRISES",
                        data: res.data.crises
                    }]
                    setactionLibraryData(obj);

                    setLoading(true);
                }
            })
    }, [])

    const handleCrises = (obj) => {
        let name = obj.name.replace('/', '~')

        props.history.push({
            pathname: "/last"+":"+ name,
            state: {
                name: obj.name,
                sourceName: 'Crices',
                returnPath: 'crices'
            }
        })
    }
    return (
        <div>

            <div className="backNavigation text-center">
            <Link className="arrow" onClick={(e)=> {e.preventDefault();props.history.goBack()}}><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to {props.location.state.sourceName}</span></span></Link> {props.location.state.name}
                {/* <Link className="arrow" to="/actionLibrary"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Action Library</span></span></Link>CRISES */}
                <span style={{ position: "absolute", right: "15%" }}>
                    CASENAME:{fileName === null ? '-' : fileName}
                </span>
                <h4 className="hidn">CRISES</h4>
            </div>
            <div className="main-container mt-3">
                {loading ?

                    (actionLibraryData).map((data) => {
                        console.log("Test", data)

                        return (
                            <>
                                {data.data.map((data1, key) => {
                                    return (
                                        <>
                                            <div className="all-action-list-subcontainer-content" key={key}>
                                                {/* <Link className="crisess-name">{index + 1}</Link>
                                                <Link className="names">{data1.name}</Link> */}
                                                <i className="material-icons down-icon" >play_arrow</i>
                                                <div>{data1.name}</div>
                                            </div>
                                            {data1.children ? 
                                            <div>
                                                {
                                                    data1.children.map((name, key) => {
                                                        return (
                                                            <div className="all-action-list-subcontainer-content" key={key}  onClick={() => {
                                                                handleCrises(name)

                                                            }} style={{paddingLeft:80}}>
                                                                <i className="material-icons dropdown-icon" >play_arrow</i>
                                                                <div>{name.name}</div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>: <></>
                                }


                                        </>
                                    )
                                })}
                            </>
                        )
                    }) : <Spinner />
                }

            </div>
        </div>

    )
}

export default Crises
