import React, { useEffect, useState } from 'react';
import './CAS1063.css';

const CAS1063 = () => {
    const [height, setHeight] = useState([]);
    const [gender, setGender] = useState("");
    const [total, settotal] = useState(0);
    const [tidal6, setTidal6] = useState(0);
    const [tidal8, setTidal8] = useState(0);
    const [ibw6, setIbw6] = useState(0);
    const [ibw8, setIbw8] = useState(0);
    const [tunit, setTunit] = useState('cm');
    const [placehldr, setPlacehldr] = useState('Norm: 152 - 213')
    const [newNumber, setNewNumber] = useState(0)
    const postData = async () => {
        if(tunit === 'cm'){
            setNewNumber(height)
    }
    if(tunit === 'in'){
        setNewNumber(height*2.54)
    }

    if ((gender && height) && (height < 228) && (height >= 122)) {
        if (tunit === 'cm') {
            settotal(Math.round((0.1 * height) + 4));
            if (gender === 'male') {
                setTidal6((Math.round(50 + (2.3 * ((newNumber / 2.54) - 60)))) * 6);
                setTidal8((Math.round(50 + (2.3 * ((newNumber / 2.54) - 60)))) * 8);
                setIbw6(Math.round(50 + (2.3 * ((newNumber / 2.54) - 60))));
                setIbw8(Math.round(50 + (2.3 * ((newNumber / 2.54) - 60))));
            }
            if (gender === 'female') {
                setTidal6((Math.round(45.5 + (2.3 * ((newNumber / 2.54) - 60)))) * 6);
                setTidal8((Math.round(45.5 + (2.3 * ((newNumber / 2.54) - 60)))) * 8);
                setIbw6(Math.round(45.5 + (2.3 * ((newNumber / 2.54) - 60))));
                setIbw8(Math.round(45.5 + (2.3 * ((newNumber / 2.54) - 60))));
            }
        }
        if (tunit === 'in') {
            if (gender === 'male') {
                setTidal6((Math.round(50 + (2.3 * ((newNumber) - 60)))) * 6);
                setTidal8((Math.round(50 + (2.3 * ((newNumber) - 60)))) * 8);
                setIbw6(Math.round(50 + (2.3 * ((newNumber) - 60))));
                setIbw8(Math.round(50 + (2.3 * ((newNumber) - 60))));
            }
            if (gender === 'female') {
                setTidal6((Math.round(45.5 + (2.3 * ((newNumber) - 60)))) * 6);
                setTidal8((Math.round(45.5 + (2.3 * ((newNumber) - 60)))) * 8);
                setIbw6(Math.round(45.5 + (2.3 * ((newNumber) - 60))));
                setIbw8(Math.round(45.5 + (2.3 * ((newNumber) - 60))));
            }
        }
    } else {
            setTidal6(0);
            setTidal8(0);
            setIbw6(0);
            setIbw8(0);
            settotal(0);

        }

    }


    const valnumber1 = () => {

        if (tunit === 'cm') {
            if (height !== 0) {
                if ((height <= 121) && (height > 0)) {
                    return <div className="validate">*Too low; please change to proceed.</div>

                }
                if ((height < 229) && (height > 203)) {
                    return <div className="validate">*Very High; double check.</div>

                }
                if (height > 228) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(height)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
        if (tunit === 'in') {
            if (height !== 0) {
                if ((height < 48.0315) && (height > 0)) {
                    return <div className="validate">*Too low; please change to proceed.</div>

                }
                if ((height < 90.1575) && (height > 79.9213)) {
                    return <div className="validate">*Very High; double check.</div>

                }
                if (height > 89.7638) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(height)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }

    }



    useEffect(() => {
        if (tunit !== 'in') {
            postData();
        }

    }, [height, gender, tidal6, tidal8, ibw6, ibw8,newNumber])
    const changeunit = () => {
        if (height === 0) {
            if (tunit === 'cm') {
                setTunit('in');

            }
            if (tunit === 'in') {
                setTunit('cm');

            }
        } else if (height !== 0) {
            if (tunit === 'cm') {
                setPlacehldr("Norm: 60 - 84");
                setTunit('in');
              if(height.length !== 0){
                setHeight((height / 2.54).toFixed(2));
              }
            }
            if (tunit === 'in') {
                setPlacehldr("Norm: 152 - 213");
                setTunit('cm');
                if(height.length !== 0){
                setHeight((height * 2.54).toFixed(2));
                }

            }
        }
    }
    const takeUnit = (e) => {
        if (tunit === 'cm') {
            setHeight(e.target.value);
            setPlacehldr("Norm: 152 - 213");

        }
        if (tunit === 'in') {
            let ht = e.target.value;
            setHeight(ht);
            setPlacehldr("Norm: 60 - 84");
        }
        
    }
    const changetoCm = () => {
        if(tunit==='in'){changeunit()}
    }





    return (<div className="rev">
        <h5 className="text-info pl-2">Endotracheal Tube (ETT) Depth and Tidal Volume Calculator</h5>
<p className="pl-2">Estimates depth of optimal ETT placement and target tidal volume by height.</p>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Height</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input type="number"

                    onChange={takeUnit}
                    onBlur= {changetoCm}
                    placeholder={placehldr}
                    className="ant-text-input" style={{ width: '77%' }} value={height} /><span className="units" onClick={changeunit}>{tunit}&#8651;</span>
                <div>{valnumber1()}</div>
            </div>
        </div>

        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Gender</strong></div>
            </div>
            <div className="col-md-7 btn-group text-left">
                <button style={{ height: 40, width: 80 }} className={gender === 'female' ? "btn active" : "btn"} onClick={() =>  setGender('female') }>Female</button>
                <button style={{ height: 40, width: 80 }} className={gender === 'male' ? "btn active" : "btn"} onClick={() => setGender('male') }>Male</button>

            </div>
        </div>
        <br />
        <div className="stick">
            {total !== 0 ?
                <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <h1>{total} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>cm</span></h1>
                        ETT depth from front teeth
                    </div>
                    <div className="point-result-container" >
                        <h1>{tidal6} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>ml</span></h1>
                        Tidal volume if target is 6 mL/kg, IBW {ibw6} kg
                    </div>
                    <div className="point-result-container" >
                        <h1>{tidal8} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>ml</span></h1>
                        Tidal volume if target is 8 mL/kg, IBW {ibw8} kg
                    </div>
                </div> : <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>
            }

        </div><br/>
        <div className="p-2 mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/10793162/">
            <div className="reference-container-card" >
                <div className="reference-img" >
                    <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                </div>
                <p className="reference-paragraph" >ARDSnet investigators.  Ventilation with lower tidal volumes as compared with traditional tidal volumes for acute lung injury and the acute respiratory distress syndrome.  N Engl J Med.  2000; May;342(18): 1301-1308.</p>
            </div>
            </a><br/>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/16518983/">
            <div className="reference-container-card" >
                <div className="reference-img" >
                    <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                </div>
                <p className="reference-paragraph" >Techanivate A, Kumwilaisak K, Samranrean S. Estimation of the proper length of orotracheal intubation by Chula formula. J Med Assoc Thai. 2005 Dec;88(12):1838-46</p>
            </div>
            </a>
        </div>
    </div>
    )
}
export default CAS1063