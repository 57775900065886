import React, { useEffect, useState } from 'react';
import './CAS1058.css';

const CAS1058 = () => {
    const [green, setGreen] = useState(false);

    const [result, setResult] = useState(0);
    const [result1, setResult1] = useState(0);


    const [atmPressure, setAtmPressure] = useState([]);
    const [tunit, setTunit] = useState('kPa');
   
    const [newatmPressure, setNewatmPressure] = useState(0);

    const [tunit2, setTunit2] = useState('kPa');
    const [pao, setPao] = useState([]);
    const [placehldr2, setPlacehldr2] = useState('Norm: 10 - 13.3')
    const [newpao, setNewpao] = useState(0);

    const [fio2, setFio2] = useState(0);

    const [tunit3, setTunit3] = useState('kPa');
    const [paCo2, setPaco2] = useState([]);
    const [placehldr3, setPlacehldr3] = useState('Norm: 5.054 - 5.586')
    const [newpaCo2, setnewpaCo2] = useState(0);

    const [age, setAge] = useState(0);


    const [unitConversion, setUnitconversion] = useState('kPa');




    const postData = async () => {
        if (tunit === 'kPa') {
            setNewatmPressure(atmPressure);
        }
        if ((tunit === 'mm Hg')) {
            setNewatmPressure((atmPressure / 7.501).toFixed(2));
        }
        if (tunit2 === 'kPa') {
            setNewpao(pao);
        }

        if ((tunit2 === 'mm Hg')) {
            setNewpao((pao / 7.501).toFixed(2));
        }

        if (tunit3 === 'kPa') {
            setnewpaCo2(paCo2);

        }
        if ((tunit3 === 'mm Hg')) {
            setnewpaCo2((paCo2 / 7.501).toFixed(2));
        }



        if (tunit === 'mm Hg' || tunit2 === 'mm Hg' || tunit3 === 'mm Hg') {
            setUnitconversion('mm Hg');
            setResult((((fio2 / 100) * (newatmPressure - 6.266) - (newpaCo2 / 0.8) - newpao) * 7.501).toFixed(1));
            setResult1((((age / 4) + 4)).toFixed(1));
        }
        else {
            setResult(((fio2 / 100) * (newatmPressure - 6.266) - (newpaCo2 / 0.8) - newpao).toFixed(1));
            setResult1((((age / 4) + 4) / 7.501).toFixed(1));
            setUnitconversion('kPa');
        }


        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [atmPressure, newatmPressure, pao, newpao, fio2, paCo2, newpaCo2, age, tunit, tunit2, tunit3, placehldr3, placehldr2,placehldr3, result, result1]);

    const changeunit = () => {
        if (tunit === 'kPa') {
            setTunit('mm Hg');
      
            if (atmPressure.length !== 0) {
                setAtmPressure((atmPressure * 7.501).toFixed(2));
            }
            // setAtmPressure((atmPressure / 2.54).toFixed(2));
        }
        if (tunit === 'mm Hg') {
            setTunit('kPa');
         
            // setAtmPressure((atmPressure * 2.54).toFixed(2));
            if (atmPressure.length !== 0) {
                setAtmPressure((atmPressure / 7.501).toFixed(2));
            }
        }
    }
    const takeUnit = (e) => {
        if (tunit === 'kPa') {
            let htcm = e.target.value.trim();
            // setChnageht(htcm/2.54);
            setAtmPressure(htcm);
       

        }
        if (tunit === 'mm Hg') {
            let htin = e.target.value.trim();
            setAtmPressure(htin);
      

        }

    }


    const changeunit2 = () => {
        if (tunit2 === 'kPa') {
            setTunit2('mm Hg');
            setPlacehldr2("Norm: 75 - 100");
            if (pao.length !== 0) {
                setPao((pao * 7.501).toFixed(2));
            }
            // setPao((pao / 2.54).toFixed(2));
        }
        if (tunit2 === 'mm Hg') {
            setTunit2('kPa');
            setPlacehldr2("Norm: 10 - 13.3");
            // setPao((pao * 2.54).toFixed(2));
            if (pao.length !== 0) {
                setPao((pao / 7.501).toFixed(2));
            }
        }
    }
    const takeUnit2 = (e) => {
        if (tunit2 === 'kPa') {
            let htcm = e.target.value.trim();
            // setChnageht(htcm/2.54);
            setPao(htcm);
            setPlacehldr2("Norm: 10 - 13.33");

        }
        if (tunit2 === 'mm Hg') {
            let htin = e.target.value.trim();
            setPao(htin);
            setPlacehldr2("Norm: 75 - 100");


        }

    }

    const changeunit3 = () => {
        if (tunit3 === 'kPa') {
            setTunit3('mm Hg');
            setPlacehldr3("Norm: 38 - 42");
            if (paCo2.length !== 0) {
                setPaco2((paCo2 * 7.501).toFixed(2));
            }
            // setPaco2((paCo2 / 2.54).toFixed(2));
        }
        if (tunit3 === 'mm Hg') {
            setTunit3('kPa');
            setPlacehldr3("Norm: 5.054 - 5.586");
            // setPaco2((paCo2 * 2.54).toFixed(2));
            if (paCo2.length !== 0) {
                setPaco2((paCo2 / 7.501).toFixed(2));
            }
        }
    }
    const takeUnit3 = (e) => {
        if (tunit3 === 'kPa') {
            let htcm = e.target.value.trim();
            // setChnageht(htcm/2.54);
            setPaco2(htcm);
            setPlacehldr3("Norm: 5.054 - 5.586");

        }
        if (tunit3 === 'mm Hg') {
            let htin = e.target.value.trim();
            setPaco2(htin);
            setPlacehldr3("Norm: 38 - 42");


        }

    }


    return (
        <div className="rev">
            <h5 className="text-info pl-2">A-a O₂ Gradient</h5>
            <p className="pl-2">Assesses for degree of shunting and V/Q mismatch.</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Atmospheric pressure</strong></div>
                    <p>Use 760 mm Hg (101.33 kPa) at sea level</p>
                </div>
                <div className="col-md-7 frm-field">
                    <input  onChange={takeUnit} type="number"
                       className="ant-text-input" style={{ height:40}} value={atmPressure} /><span style={{ height:40}} className="units" onClick={changeunit}>{tunit}&#8651;</span>

                </div>
      
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>PaO₂</strong></div>
                </div>
                <div className="col-md-7 frm-field">
                    <input onChange={takeUnit2} type="number"
                        placeholder={placehldr2} className="ant-text-input" style={{ height: 40 }} value={pao} /><span className="units" onClick={changeunit2} style={{ height: 40 }}>{tunit2}&#8651;</span>

                </div>
            </div>
       
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>FiO₂</strong></div>
                    <p>Use 21% for room air</p>

                </div>
                <div className="col-md-7 frm-field ">
                    <input style={{ height: 40 }} type="number" className="ant-text-input " placeholder="Norm: 21" onChange={(e) => setFio2(e.target.value)} /><span style={{ height: 40 }}  className="units ">%</span>

                </div>

            </div>

            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>PaCO₂</strong></div>
                </div>
                <div className="col-md-7 frm-field">
                    <input onChange={takeUnit3}
                        placeholder={placehldr3} type="number" className="ant-text-input" style={{ height: 40 }} value={paCo2} /><span className="units" onClick={changeunit3} style={{ height: 40 }}>{tunit3}&#8651;</span>

                </div>
       
            </div>

            <div className="fields row mb-5">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Age (for expected A-a gradient)</strong></div>

                </div>
                <div className="col-md-7 frm-field ">
                    <input style={{ height: 40 }} type="number" className="ant-text-input "  onChange={(e) => setAge(e.target.value)} /><span className="units " style={{ height: 40 }}>Years</span>

                </div>

            </div>



            <div className="stick mt-4">
                {
                    result != 0 ? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container" >
                                <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>{unitConversion}</span></h1>

                                <div>A-a Gradient</div>
                            </div>
                            <div className="point-result-container" >
                                <h1>{result1} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>{unitConversion}</span></h1>

                                <div>Expected A-a Gradient for Age</div>
                            </div>





                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <br/>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/436542/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Helmholz HF Jr. The abbreviated alveolar air equation. Chest. 1979 Jun;75(6):748</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default CAS1058

