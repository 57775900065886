import React from 'react'
// Cardiovascular
import CAS1011 from './organAndSystem/cardiovascular/CAS1011/CAS1011'
import CAS1012 from './organAndSystem/cardiovascular/CAS1012/CAS1012'
import CAS1013 from './organAndSystem/cardiovascular/CAS1013/CAS1013'
import CAS1014 from './organAndSystem/cardiovascular/CAS1014/CAS1014'
import CAS1015 from './organAndSystem/cardiovascular/CAS1015/CAS1015'
import CAS1016 from './organAndSystem/cardiovascular/CAS1016/CAS1016'
import CAS1017 from './organAndSystem/cardiovascular/CAS1017/CAS1017'
import CAS1018 from './organAndSystem/cardiovascular/CAS1018/CAS1018'
import CAS1019 from './organAndSystem/cardiovascular/CAS1019/CAS1019'
import CAS1020 from './organAndSystem/cardiovascular/CAS1020/CAS1020'
import CAS1021 from './organAndSystem/cardiovascular/CAS1021/CAS1021'
import CAS1022 from './organAndSystem/cardiovascular/CAS1022/CAS1022'
import CAS1023 from './organAndSystem/cardiovascular/CAS1023/CAS1023'
import CAS1024 from './organAndSystem/cardiovascular/CAS1024/CAS1024'
import CAS1025 from './organAndSystem/cardiovascular/CAS1025/CAS1025'
import CAS1026 from './organAndSystem/cardiovascular/CAS1026/CAS1026'
import CAS1027 from './organAndSystem/cardiovascular/CAS1027/CAS1027'
import CAS1028 from './organAndSystem/cardiovascular/CAS1028/CAS1028'
import CAS1029 from './organAndSystem/cardiovascular/CAS1029/CAS1029'
import CAS1030 from './organAndSystem/cardiovascular/CAS1030/CAS1030'

// Gastrointestinal
import CAS1031 from './organAndSystem/gastrointestinal/CAS1031/CAS1031'
import CAS1032 from './organAndSystem/gastrointestinal/CAS1032/CAS1032'
import CAS1033 from './organAndSystem/gastrointestinal/CAS1033/CAS1033'
import CAS1034 from './organAndSystem/gastrointestinal/CAS1034/CAS1034'

// Hematologic
import CAS1035 from './organAndSystem/hematologic/CAS1035/CAS1035'
import CAS1036 from './organAndSystem/hematologic/CAS1036/CAS1036'
import CAS1037 from './organAndSystem/hematologic/CAS1037/CAS1037'
import CAS1038 from './organAndSystem/hematologic/CAS1038/CAS1038'

// Infectious Disease
import CAS1039 from './organAndSystem/infectiousDisease/CAS1039/CAS1039'
import CAS1040 from './organAndSystem/infectiousDisease/CAS1040/CAS1040'

// Musculoskeletal
import CAS1041 from './organAndSystem/musculoskeletal/CAS1041/CAS1041'

// Neurological
import CAS1042 from './organAndSystem/neurological/CAS1042/CAS1042'
import CAS1043 from './organAndSystem/neurological/CAS1043/CAS1043'
import CAS1044 from './organAndSystem/neurological/CAS1044/CAS1044'
import CAS1045 from './organAndSystem/neurological/CAS1045/CAS1045'
import CAS1046 from './organAndSystem/neurological/CAS1046/CAS1046'
import CAS1047 from './organAndSystem/neurological/CAS1047/CAS1047'
import CAS1048 from './organAndSystem/neurological/CAS1048/CAS1048'
import CAS1049 from './organAndSystem/neurological/CAS1049/CAS1049'
import CAS1050 from './organAndSystem/neurological/CAS1050/CAS1050'
import CAS1051 from './organAndSystem/neurological/CAS1051/CAS1051'
import CAS1052 from './organAndSystem/neurological/CAS1052/CAS1052'
import CAS1053 from './organAndSystem/neurological/CAS1053/CAS1053'

// Nutrition
import CAS1054 from './organAndSystem/nutrition/CAS1054/CAS1054'
import CAS1055 from './organAndSystem/nutrition/CAS1055/CAS1055'
import CAS1056 from './organAndSystem/nutrition/CAS1056/CAS1056'
import CAS1057 from './organAndSystem/nutrition/CAS1057/CAS1057'


// Pulmonary
import CAS1058 from './organAndSystem/pulmonary/CAS1058/CAS1058'
import CAS1059 from './organAndSystem/pulmonary/CAS1059/CAS1059'
import CAS1060 from './organAndSystem/pulmonary/CAS1060/CAS1060'
import CAS1061 from './organAndSystem/pulmonary/CAS1061/CAS1061'
import CAS1062 from './organAndSystem/pulmonary/CAS1062/CAS1062'
import CAS1063 from './organAndSystem/pulmonary/CAS1063/CAS1063'
import CAS1064 from './organAndSystem/pulmonary/CAS1064/CAS1064'
import CAS1065 from './organAndSystem/pulmonary/CAS1065/CAS1065'
import CAS1066 from './organAndSystem/pulmonary/CAS1066/CAS1066'
import CAS1067 from './organAndSystem/pulmonary/CAS1067/CAS1067'
import CAS1068 from './organAndSystem/pulmonary/CAS1068/CAS1068'
import CAS1069 from './organAndSystem/pulmonary/CAS1069/CAS1069'
import CAS1070 from './organAndSystem/pulmonary/CAS1070/CAS1070'
import CAS1071 from './organAndSystem/pulmonary/CAS1071/CAS1071'
import CAS1072 from './organAndSystem/pulmonary/CAS1072/CAS1072'

// Renal
import CAS1073 from './organAndSystem/renal/CAS1073/CAS1073'
import CAS1074 from './organAndSystem/renal/CAS1074/CAS1074'
import CAS1075 from './organAndSystem/renal/CAS1075/CAS1075'
import CAS1076 from './organAndSystem/renal/CAS1076/CAS1076'
import CAS1077 from './organAndSystem/renal/CAS1077/CAS1077'
import CAS1078 from './organAndSystem/renal/CAS1078/CAS1078'
import CAS1079 from './organAndSystem/renal/CAS1079/CAS1079'
import CAS1080 from './organAndSystem/renal/CAS1080/CAS1080'
import CAS1081 from './organAndSystem/renal/CAS1081/CAS1081'
import CAS1082 from './organAndSystem/renal/CAS1082/CAS1082'
import CAS1083 from './organAndSystem/renal/CAS1083/CAS1083'
import CAS1084 from './organAndSystem/renal/CAS1084/CAS1084'
import CAS1085 from './organAndSystem/renal/CAS1085/CAS1085'
import CAS1086 from './organAndSystem/renal/CAS1086/CAS1086'
import CAS1087 from './organAndSystem/renal/CAS1087/CAS1087'
import CAS1088 from './organAndSystem/renal/CAS1088/CAS1088'

// Reproductive
import CAS1089 from './organAndSystem/reproductive/CAS1089/CAS1089'
import CAS1090 from './organAndSystem/reproductive/CAS1090/CAS1090'
import CAS1091 from './organAndSystem/reproductive/CAS1091/CAS1091'
import CAS1092 from './organAndSystem/reproductive/CAS1092/CAS1092'
import CAS1093 from './organAndSystem/reproductive/CAS1093/CAS1093'
import CAS1094 from './organAndSystem/reproductive/CAS1094/CAS1094'
import CAS1095 from './organAndSystem/reproductive/CAS1095/CAS1095'

// Rheumatologic
import CAS1096 from './organAndSystem/rheumatologic/CAS1096/CAS1096'



const CalculationsAndScores = ({ id }) => {

    const data = {
        // Cardiovascular
        CAS1011: <CAS1011 />,
        CAS1012: <CAS1012 />,
        CAS1013: <CAS1013 />,
        CAS1014: <CAS1014 />,
        CAS1015: <CAS1015 />,
        CAS1016: <CAS1016 />,
        CAS1017: <CAS1017 />,
        CAS1018: <CAS1018 />,
        CAS1019: <CAS1019 />,
        CAS1020: <CAS1020 />,
        CAS1021: <CAS1021 />,
        CAS1022: <CAS1022 />,
        CAS1023: <CAS1023 />,
        CAS1024: <CAS1024 />,
        CAS1025: <CAS1025 />,
        CAS1026: <CAS1026 />,
        CAS1027: <CAS1027 />,
        CAS1028: <CAS1028 />,
        CAS1029: <CAS1029 />,
        CAS1030: <CAS1030 />,

        //Gastrointestinal
        CAS1031: <CAS1031 />,
        CAS1032: <CAS1032 />,
        CAS1033: <CAS1033 />,
        CAS1034: <CAS1034 />,

        //Hematologic
        CAS1035: <CAS1035 />,
        CAS1036: <CAS1036 />,
        CAS1037: <CAS1037 />,
        CAS1038: <CAS1038 />,

        // Infectious Disease
        CAS1039: <CAS1039 />,
        CAS1040: <CAS1040 />,

        // Musculoskeletal
        CAS1041: <CAS1041 />,

        // Neurological
        CAS1042: <CAS1042 />,
        CAS1043: <CAS1043 />,
        CAS1044: <CAS1044 />,
        CAS1045: <CAS1045 />,
        CAS1046: <CAS1046 />,
        CAS1047: <CAS1047 />,
        CAS1048: <CAS1048 />,
        CAS1049: <CAS1049 />,
        CAS1050: <CAS1050 />,
        CAS1051: <CAS1051 />,
        CAS1052: <CAS1052 />,
        CAS1053: <CAS1053 />,

        // Nutrition
        CAS1054: <CAS1054 />,
        CAS1055: <CAS1055 />,
        CAS1056: <CAS1056 />,
        CAS1057: <CAS1057 />,

        // Pulmonary
        CAS1058: <CAS1058 />,
        CAS1059: <CAS1059 />,
        CAS1060: <CAS1060 />,
        CAS1061: <CAS1061 />,
        CAS1062: <CAS1062 />,
        CAS1063: <CAS1063 />,
        CAS1064: <CAS1064 />,
        CAS1065: <CAS1065 />,
        CAS1066: <CAS1066 />,
        CAS1067: <CAS1067 />,
        CAS1068: <CAS1068 />,
        CAS1069: <CAS1069 />,
        CAS1070: <CAS1070 />,
        CAS1071: <CAS1071 />,
        CAS1072: <CAS1072 />,

        // Renal
        CAS1072: <CAS1072 />,
        CAS1073: <CAS1073 />,
        CAS1074: <CAS1074 />,
        CAS1075: <CAS1075 />,
        CAS1076: <CAS1076 />,
        CAS1077: <CAS1077 />,
        CAS1078: <CAS1078 />,
        CAS1079: <CAS1079 />,
        CAS1080: <CAS1080 />,
        CAS1081: <CAS1081 />,
        CAS1082: <CAS1082 />,
        CAS1083: <CAS1083 />,
        CAS1084: <CAS1084 />,
        CAS1085: <CAS1085 />,
        CAS1086: <CAS1086 />,
        CAS1087: <CAS1087 />,
        CAS1088: <CAS1088 />,

        // Reproductive
        CAS1088: <CAS1089 />,
        CAS1090: <CAS1090 />,
        CAS1091: <CAS1091 />,
        CAS1092: <CAS1092 />,
        CAS1093: <CAS1093 />,
        CAS1094: <CAS1094 />,
        CAS1095: <CAS1095 />,

        // Rheumatologic
        CAS1096: <CAS1096 />,
    }


    return (
        <React.Fragment>
           {data[id]}
        </React.Fragment>
    )
}

export {
    CalculationsAndScores
} 