import { React, useState, useEffect } from 'react';
import '../css/ApfelScore.css';

const HorowitzIndexAirwayCalculator = () => {
    const [pao, setPao] = useState([]);
    const [fio, setFio] = useState(0);
    const [tunit, setTunit] = useState('kPa');
    const [placehldr, setPlacehldr] = useState('Norm: 10 - 13.3')
    const [exchange_volume, setExchange_volume] = useState(0);
    const[newpao,setNewpao]=useState(0);

    const postData = async () => {
        
        if(tunit === 'kPa'){
            if(pao >1 && pao < 87){

                setNewpao(pao);
            }
            else{
                setNewpao(0);
            }
        }
        if((tunit === 'mm Hg')){
            if(pao > 9 && pao < 651){

                setNewpao((pao / 7.501).toFixed(2));
            }
            else{

                setNewpao(0);
            }
        }


        if ((pao && fio) && (newpao != 0) && (fio < 101)) {
            setExchange_volume(((((newpao * 7.52) / fio) * 100).toFixed(0)));
        }
        else {
            setExchange_volume(0);
          
        }
    }


    const valnumber1 = () => {

        if (tunit === 'kPa') {
        if (pao !== 0) {
            if ((pao < 2) && (pao >0)) {
                return <div className="validate">*Too small; please change to proceed.</div>

            }
            if ((pao < 87) && (pao > 79)) {
                return <div className="validate">*Very High; double check.</div>

            }
            if (pao > 86) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(pao)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    if (tunit === 'mm Hg') {
        if (pao !== 0) {
            if ((pao < 10) && (pao >0)) {
                return <div className="validate">*Too small; please change to proceed.</div>

            }
            if ((pao < 651) && (pao > 600)) {
                return <div className="validate">*Very High; double check.</div>

            }
            if (pao > 650) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(pao)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    }
    const valnumber2 = () => {
        if (fio != 0) {
            if ((fio < 10) && (fio != 0)) {
                return <div className="validate">*Too Low; please change to proceed.</div>

            }
            if ((fio < 20) && (fio >9)) {
                return <div className="validate">*Too small; please check double.</div>

            }
          
            if (fio > 100) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(fio)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }

    }


    useEffect(() => {
        
            postData()
        

    }, [pao,newpao, fio, exchange_volume]);

    const changeunit = () => {
        if (tunit === 'kPa') {
            setTunit('mm Hg');
            setPlacehldr("Norm: 75 - 100");
            if (pao.length !== 0) {
                setPao((pao * 7.501).toFixed(2));
            } 
            // setPao((pao / 2.54).toFixed(2));
        }
        if (tunit === 'mm Hg') {
            setTunit('kPa');
            setPlacehldr("Norm: 10 - 13.3");
            // setPao((pao * 2.54).toFixed(2));
            if (pao.length !== 0) {
            setPao((pao / 7.501).toFixed(2));
            }
        }
    }
    const takeUnit = (e) => {
        if (tunit === 'kPa') {
            let htcm = e.target.value.trim();
            // setChnageht(htcm/2.54);
            setPao(htcm);
            setPlacehldr("Norm: 10 - 13.33");

        }
        if (tunit === 'mm Hg') {
            let htin = e.target.value.trim();
            setPao(htin);
            setPlacehldr("Norm: 75 - 100");


        }

    }


    return (<div className="rev">
        <h5 className="text-info pl-2">Horowitz Index for Lung Function (P/F Ratio)</h5>
<p className="pl-2">Assesses lung function, particularly in intubated patients.</p>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>PaO₂</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input onChange={takeUnit}
                    placeholder={placehldr} className="ant-text-input" style={{ width: '65%' }} value={pao} /><span className="units" onClick={changeunit}>{tunit}&#8651;</span>
                         <div>{valnumber1()}</div>
                       
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>FiO₂</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input onChange={(e) => setFio(e.target.value)} className="ant-text-input" style={{ width: '81%' }} /><span className="units">%</span>
                <div>{valnumber2()}</div>
            </div>
        </div><br />
        <div className="stick">
            {pao && fio && !isNaN(exchange_volume) && (exchange_volume !== 0) ?
                <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <h1>{exchange_volume} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>mmHg</span></h1>
                        Horowitz Index (P/F ratio)
                    </div>
                    <div className="percentage-result-container" >
                        <h1>{exchange_volume > 300 ? "Not ARDS" : ""}
                            {exchange_volume > 200 && exchange_volume < 300 ? "Mild" : ""}
                            {exchange_volume > 100 && exchange_volume < 200 ? "Moderate" : ""}
                            {exchange_volume <= 100 ? "Severe" : ""}
                        </h1>
                        <div>Severity of ARDS</div>
                    </div>
                    {exchange_volume < 300 ?
                        <div className="percentage-result-container" >

                            <h1>
                                {exchange_volume > 200 && exchange_volume < 300 ? "27" : ""}
                                {exchange_volume > 100 && exchange_volume < 200 ? "32" : ""}
                                {exchange_volume <= 100 ? "45" : ""}<span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}> %</span></h1>
                            <div>Mortality</div>


                        </div> : <></>
                    }

                </div> : <div className="text-white p-2 green-bg" ><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>


            }

        </div>
        <br/>
 
      
        <div className="p-2 mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://www.mdapp.co/horowitz-index-for-lung-function-pf-ratio-calculator-381/">
            <div className="reference-container-card" >
                <div className="reference-img" >
                    <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                </div>
                <p className="reference-paragraph" >Horovitz JH, Carrico CJ, Shires GT. Pulmonary response to major injury. Arch Surg. 1974;108(3):349-55.</p>
            </div>
            </a>
        </div>
    </div>
    )
}
export default HorowitzIndexAirwayCalculator;





