import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';

const Abg = () => {
    const [green, setGreen] = useState(false);
    const [ph, setPh] = useState(0);
    const [co2, setCo2] = useState(0);
    const [hco3, setHco3] = useState(0);
    const postData = async () => {
   
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [ ph,co2,hco3]);
    const resultFields = () =>{
        if((ph <7.35 && co2 >45  && (hco3 >21 && hco3 <27)) || (ph <7.35 && co2 >45  && (hco3 >26)) || ((ph >7.36 && ph<7.46) && co2 >45  && hco3 >26 )){
            return <>
            <div>Acidosis respiratoria</div>
            <div>Parcialmente compensada</div>
            </>
        }
        else if((ph > 7.45 && co2 <35  && (hco3 >21 && hco3 <27)) || (ph >7.45 && co2 <35  && (hco3 <22)) || ((ph >7.36 && ph<7.46) && co2 <35  && hco3 <22 )){
            return <>
             <div>Alkalosis Respiratory 	</div>
             <div>Parcialmente compensada</div>
            </>
        }
        else if((ph < 7.35 && (co2 >34 && co2 <46 ) && hco3 <22) || (ph <7.35 && co2 <35  && (hco3 <22)) || ((ph >7.36 && ph<7.46) && co2 <35  && hco3 <22 )){
            return <>
             <div>Acidosis Metabolic </div>
             <div>Parcialmente compensada</div>
            </>
        }
        else if((ph > 7.45 && (co2 >34 && co2 <46 ) && hco3 >26) || (ph <7.45 && co2 >45  && (hco3 >26)) || ((ph >7.36 && ph<7.46) && co2 >45  && hco3 >26 )){
            return <>
            <div>Alkalosis Metabolic </div>
            <div>Parcialmente compensada</div>
            </>
        }
        else{
            return <>
            <div>Sin determinar ver tratornos mixtos.</div>
            </>
        }
    }

    return (
        <div className="rev">
          
          <h5 className="text-info pl-2">GSA interpretaci�n</h5>

            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>pH</strong></div>

                </div>
                <div className="col-md-7 frm-field">
                    <input type="number" style={{ width: "90%" }} className="ant-text-input " onChange={(e) => setPh(e.target.value)} /><span className="units ">Years</span>

                </div>
            </div>
            
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>CO2</strong></div>

                </div>
                <div className="col-md-7 frm-field">
                    <input type="number" style={{ width: "90%" }} className="ant-text-input "  onChange={(e) => setCo2(e.target.value)} /><span className="units ">Years</span>

                </div>
            </div>
            
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>HCO3</strong></div>

                </div>
                <div className="col-md-7 frm-field">
                    <input type="number" style={{ width: "90%" }} className="ant-text-input "  onChange={(e) => setHco3(e.target.value)} /><span className="units ">Years</span>

                </div>
            </div>
            <div className="stick mt-5">
                {
                    ph !=0 && co2 !=0 && hco3 !=0? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container" >
                                    {resultFields()}                          
                            </div>
                        </div>
                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>
                }
            </div>
        </div>
    )
}


export default Abg;

