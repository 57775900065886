import React, { useEffect, useState,useContext } from 'react'
import { API_ROOT } from '../../../constants'
import Select from 'react-select'
import "../../../css/newLamaxHeader.css";
import "../../../css/ApfelScore.css";
import { useDispatch, useSelector } from 'react-redux'
import { setNewComorbities, setNewGenderType, setNewHeightType, setNewLamaxHeight, setNewLamaxWeight, setNewWeightType } from '../../../redux/NewLamaxActions'
import { Link, useHistory } from 'react-router-dom'
import AppContext from '../../../contextStore/DataProvider'
import { Modal } from 'react-bootstrap';
const LAMAX1001 = (props) => {
    const context =useContext(AppContext)
    const [comorbitiesValue, setComorbitiesValue] = useState([])
    const dispatch = useDispatch()
    const newWeightType = useSelector(state => state.newLamax.newWeightType)
    const newHeightType = useSelector(state => state.newLamax.newHeightType)
    const weight2 = useSelector(state => state.newLamax.weight2)
    const height2 = useSelector(state => state.newLamax.height2)
    const newGenderValue = useSelector(state => state.newLamax.newGenderValue)
    const [comorbities, setComorbities] = useState([])
    const [checked, setChecked] = useState(false);
    const [mass, setMass] = useState("Manual Entry")
    const [arr, setarr] = useState([])
    const [refresh, setrefresh] = useState({})
    const [show, setShow] = useState(false);
    // const [button, setButton] = useState(false)
    let history = useHistory()
    useEffect(() => {
        getLAMAXData()

    }, [])
    const clearState=()=>{
        dispatch(setNewLamaxWeight(""))
        dispatch(setNewLamaxHeight(""))
        dispatch(setNewGenderType(""))
        dispatch(setNewComorbities(""))
        dispatch(setNewHeightType(""))
        dispatch(setNewWeightType(""))
    }

    useEffect(() => {

    }, [refresh])

    const getLAMAXData = () => {
        var token = sessionStorage.getItem('token')
        fetch(API_ROOT + `la-max-drugs?token=${token}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(res => {
                if (res.status === "true" && res.message === "LA Max Drugs") {
                    res.data.comorbidity.forEach(
                        res => {
                            if (res.comorbidities === "None") {

                                res.select = true
                            } else {
                                res.select = false
                            }
                        }

                    )

                    setComorbities(res.data.comorbidity)
                } else {
                    alert(res.message)
                }
            })

    }

    const weightOptions = [
        { value: 'Manual Entry', label: 'Actual body weight' },
        { value: 'Calculated Lean Body Mass', label: "Calculated Lean Body Mass" },
    ];
    const weightTypes = [
        { value: 'KG', label: 'KG' },
        { value: 'LB', label: "LB" },
    ];

    const heightTypes = [
        { value: 'CM', label: 'CM' },
        { value: 'IN', label: "IN" },
    ];

    const genderTypes = [
        { value: 'MALE', label: 'MALE' },
        { value: 'FEMALE', label: "FEMALE" },
    ];
    const [burgerMenu, setburgerMenu] = useState(false)
    const burgerMenuClick = () => {
        props.history.push('/drawer')
    }
    const ddChange = (data) => {
        // alert(data.value)
        setMass(data.value)
    }

    // var obj = {
    //     name: "",
    //     value: "",
    //     msg: ""
    // }

    // const onSelectComborbities = (comorbidityName, comorbidityValue, comorbidityMsg) => {
    //     obj = {
    //         name: comorbidityName,
    //         value: comorbidityValue,
    //         msg: comorbidityMsg
    //     }
    //     comorbitiesValue.push(obj)
    //     arr.push(comorbidityName)
    // }

    const onSelectComorbidity = (id) => {
        for (var i = 0; i < comorbities.length; i++) {
            if (comorbities[i].id === id && comorbities[i].select === false) {

                if (comorbities[i].comorbidities === "None") {
                    onselectNoneOption()
                }
                if (comorbities[0].comorbidities === "None" && comorbities[0].select === true) {
                    comorbities[0].select = false
                }

                comorbities[i].select = true
                global.totalReduction = global.totalReduction + parseInt(comorbities[i].reduction_per) / 100
                if (comorbities[i].warning_msg !== "" && comorbities[i].warning_msg !== null) {
                    alert(comorbities[i].warning_msg)
                }

                setrefresh({})

            }
        }




    }

    const onDeselectComorbidity = (id) => {
        for (var i = 0; i < comorbities.length; i++) {
            if (comorbities[i].id === id && comorbities[i].select === true) {

                comorbities[i].select = false
                global.totalReduction = global.totalReduction - parseInt(comorbities[i].reduction_per) / 100
                setrefresh({})
            }
        }

    }

    const onselectNoneOption = () => {
        for (var i = 0; i < comorbities.length; i++) {
            if (comorbities[i].select === true) {
                comorbities[i].select = false
                global.totalReduction = 0
            }
        }

    }

    const handleClose = () => { setShow(false) };
    
    const navigateToAllAction = () => {
        // localStorage.removeItem('activeId')
        // context.activeListData=null;
        // context.activeId=null;
        // const cp_code ='D1108'
        // context.changeActiveId(cp_code)
        
        

        
        //  state: { value: "preoperativeevaluation" } })
    }
    return (
        <div>
           <div className="rev stick-lamax"><h5 className="text-info pl-2" style={{position:'relative'}}>LOCAL ANESTHETIC MAX <span className="moreInfo" onClick={() => {setShow(true)}}>more information</span></h5>
           </div>
            <div className="rev">
                <div  style={{ margin: '0 auto', background: 'rgb(242, 242, 242)' }}>
                <div className="text-center p-3 mt-3">
                    <div><strong>Enter Patient data</strong></div>
                </div>
                    <div className="fields row">
                        <div className="col-md-5 col-sm-12 pt-2">
                            <strong>WEIGHT CALCULATION</strong>
                        </div>
                        <div className="col-md-7 col-sm-12 frm-field pl-5">
                            {/* <select className="custom-select" onChange={(e) => setMass(e.target.value)} placeholder="Select Weight Type">
                                {weightOptions.map((data, key) => {
                                    return (
                                        <option key={key} value={data.value} defaultValue={weightOptions.filter(opt => opt.label === "Manual Entry")}>{data.label}</option>
                                    )
                                })
                                }
                            </select> */}
                            <Select className="newLamaxDd" defaultValue={weightOptions.filter(opt => opt.label === "Actual body weight")} onChange={(value) => ddChange(value)} options={weightOptions}  />

                        </div>
                    </div>
                    {
                        mass === "Manual Entry" ?
                            <div className="fields row">
                                <div className="col-md-5 col-xs-12 pt-2">
                                    <strong>INPUT WEIGHT</strong>
                                </div>
                                <div className="col-md-4 col-xs-12">
                                    <input type="number" value={weight2}  style={{height:40,width:'100%'}} className="ant-text-input" onChange={(e) => dispatch(setNewLamaxWeight(e.target.value))} placeholder="Weight" />
                                   </div>
                                   <div className="col-md-3">
                                    <div className="btn-group">
                                        {
                                            weightTypes.map((data, i) => {
                                                if (data.value === "KG" && newWeightType === "") {
                                                    return (
                                                        <button key={i} style={{ height: 40 }} className="btn active" onClick={() => {dispatch(setNewWeightType(data)); if (data.label === "LB") {dispatch(setNewLamaxWeight((weight2*2.205).toFixed(2)))} else if (data.label === 'KG') {dispatch(setNewLamaxWeight((weight2/2.205).toFixed(2)))}}}>{data.label}</button>
                                                    )
                                                }
                                                else {
                                                    if (data.value === newWeightType.value) {
                                                        return (
                                                            <button key={i} style={{ height: 40  }} className="btn active" onClick={() => {dispatch(setNewWeightType(data));if (data.label === "LB") {dispatch(setNewLamaxWeight((weight2*2.205).toFixed(2)))} else if (data.label === 'KG') {dispatch(setNewLamaxWeight((weight2/2.205).toFixed(2)))}}}>{data.label}</button>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <button key={i} style={{height: 40  }} className="btn " onClick={() =>{dispatch(setNewWeightType(data));if (data.label === "LB") {dispatch(setNewLamaxWeight((weight2*2.205).toFixed(2)))} else if (data.label === 'KG') {dispatch(setNewLamaxWeight((weight2/2.205).toFixed(2)))}}}>{data.label}</button>
                                                        )
                                                    }
                                                }

                                            })
                                        }
                                    </div>
                                </div>
                            </div> : <>
                                <div className="fields row">
                                    <div className="col-md-5 pt-2">
                                        <strong>GENDER</strong>
                                    </div>
                                    <div className="col-md-7 frm-field pl-5">
                                        <div className="btn-group">
                                            {
                                                genderTypes.map((data, i) => {
                                                    if (data.value === newGenderValue.value) {
                                                        return (
                                                            <button style={{ height: 40 }} className="btn active weight-type-button-selected-of-patient-profile" onClick={() => dispatch(setNewGenderType(data))}>{data.label}</button>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <button style={{ height: 40 }} className="btn weight-type-button-of-patient-profile" onClick={() => dispatch(setNewGenderType(data))}>{data.label}</button>
                                                        )
                                                    }

                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="fields row">
                                    <div className="col-md-5 pt-2">
                                        <strong>WEIGHT</strong>
                                    </div>
                                    <div className="col-md-7 frm-field">
                                        <input type="number" value={weight2} style={{ height: 40, width: '74%' }} onChange={(e) => dispatch(setNewLamaxWeight(e.target.value))} placeholder="Weight" className="ant-text-input mr-2" />
                                        <div className="btn-group">
                                            {
                                                weightTypes.map((data, i) => {
                                                    if (data.value === newWeightType.value) {
                                                        return (
                                                            <button style={{ height: 40 }} className="btn active weight-type-button-selected-of-patient-profile" onClick={() => dispatch(setNewWeightType(data))}>{data.label}</button>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <button style={{ height: 40 }} className="btn weight-type-button-of-patient-profile" onClick={() => dispatch(setNewWeightType(data))}>{data.label}</button>
                                                        )
                                                    }

                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="fields row">
                                    <div className="col-md-5 pt-2">
                                        <strong>HEIGHT</strong>
                                    </div>
                                    <div className="col-md-7 frm-field">
                                        <input type="number" style={{ height: 40, width: '74%' }} onChange={(e) => dispatch(setNewLamaxHeight(e.target.value))} placeholder="Height" className="ant-text-input mr-2" />
                                        <div className="btn-group">
                                            {
                                                heightTypes.map((data, i) => {
                                                    if (data.value === newHeightType.value) {
                                                        return (
                                                            <button style={{ height: 40 }} className="btn active weight-type-button-selected-of-patient-profile" onClick={() => dispatch(setNewHeightType(data))}>{data.label}</button>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <button style={{ height: 40 }} className="btn  weight-type-button-of-patient-profile" onClick={() => dispatch(setNewHeightType(data))}>{data.label}</button>
                                                        )
                                                    }

                                                })
                                            }
                                        </div>

                                    </div>
                                </div>{(weight2 && height2) ?
                                    <div className="green-bg p-3 text-center text-white stick"><h5>The Calculated Lean Body Mass is {newGenderValue.label ? newGenderValue.label === "MALE" ? (((0.407 * weight2) + (0.267 * height2) - 19.2).toFixed(1)) : (((0.252 * weight2) + (0.473 * height2) - 48.3).toFixed(1)) : 0}KG</h5></div> :
                                    <div className="text-white p-2 green-bg stick"><h2>Result:</h2><br />
                                        <h6>Please fill out required fields.</h6></div>
                                }
                            </>

                    }
                    <br />
                    
                    <div className="fields row">
                        <div className="col-md-5 pt-2"><strong>SELECT COMORBITIES</strong></div>
                        <div className="col-md-7 pl-5 btn-group-vertical">
                        {
                            comorbities.map((data) => {
                                if (data.select) {
                                    return (

                                        <div style={{ cursor: 'pointer', height:40 }} onClick={() => { data.select ? onDeselectComorbidity(data.id) : onSelectComorbidity(data.id) }} className="btn active select-comoribities-list-2">
                                            <div className="selected-comorbidity">{data.comorbidities}</div>
                                        </div>

                                    )
                                } else {
                                    return (
                                        <div style={{ cursor: 'pointer', height:40 }} onClick={() => { data.select ? onDeselectComorbidity(data.id) : onSelectComorbidity(data.id) }} className="btn select-comoribities-list">
                                            <div className="selected-comorbidity">{data.comorbidities}</div>
                                        </div>
                                    )

                                }
                            })
                        }
                        </div>
                    </div>
                    <br />
                <div className="frm-field pr-3" style={{justifyContent:'flex-end'}}>
                    <button  className="save-button-container" onClick={() => {
                        if (weight2.trim().length > 0 && weight2!=="") {
                        // props.history.push({
                        //     pathname: "/newAfterLamax2",
                        //     state: { comorbitiesValue: global.totalReduction ,
                        //         sourceName: 'LA MAx Step-1',
                        //         returnPath: 'newAfterlamax'
                        //     }
                        // });
                        context.changeActiveId(216)
                        context.changecomorbities(global.totalReduction,'LA MAx Step-1','newAfterlamax')
                        dispatch(setNewComorbities(comorbitiesValue))
                    } else {
                        alert('Please Enter weight to go for next')
                    }
                    }}>Next<span style={{ marginLeft: 5, marginRight: 5 }}></span><i className="fas fa-play"></i></button>
                </div><br />
                </div>
               
            </div>
            <div onClick={() => {
                //CE_1321
                props.navigateToLast("CE_1321")
               
            }} className="crises-button" >
                LAST
            </div>
            {show ?
            <div className="static-modal" style={{backgroundColor: 'rgba(0,0,0,0.1)', width:'100%',border:'1px solid red', height:'100vh',left:0,top:0, position:'fixed', zIndex:999, alignItems:'center'}}>
                <Modal.Dialog  style={{ position: 'absolute'}} size="lg" className="modBox"

                >
                    <Modal.Header>
                        <Modal.Title>LA MAX Reference </Modal.Title>
                        <i className="fas fa-times" style={{ cursor: 'hand' }} onClick={handleClose}></i>
                    </Modal.Header>
                    <Modal.Body className="modbody labody">
                        <p style={{textDecoration:"underline"}}>LA MAX Calculator Details</p>
                        <p>The LA MAX calculator uses the following values to estimate the  weight based maximum dose for each medication: [shortcut to <span><a href="/druglink:localanesthesia">Drugs/Local Anesthetics</a></span>]</p>
                        <p>The calculator was designed to make sure each mixture of local anesthetics does not exceed 100% of the combined maximum dose.</p>
                        <p>**Caution – Mixing local anesthetics is not evidence-based practice. The potential for complications such as neurotoxicity is not well studied with mixing local anesthetics.</p>
                        <p>For more information, here is a review of adjuncts and mixing local anesthetics:  <a href="https://associationofanaesthetists-publications.onlinelibrary.wiley.com/doi/10.1111/anae.15641" target="_bamnk">Pharmacological and clinical implications of local anaesthetic mixtures: a narrative review (Anaesthesia 2022)</a></p>
                        </Modal.Body>
                </Modal.Dialog>
                
                </div> : <></>
            }
            
        </div>
    )
}

export default LAMAX1001