import React, { useEffect, useState } from 'react';
import './RE1225.css';

const RE1225 = () => {
    const [green,setGreen] = useState(0);
    const [bmi, setBmi] = useState(null);
    const [weight, setWeight] = useState(null);
    const [patient, setPatient] = useState(null);

    const [result, setResult] = useState(0);
    const postData = async () => {
        setResult(bmi + weight + patient);
        setGreen(0);
    }
    useEffect(() => {
        postData();


    }, [bmi, weight, patient,result]);
    const result1 = () => {
        if(bmi != null && weight != null && patient != null ){
     
            if (result == 0) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <h1>{0} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                        <div>MUST Score</div>
                    </div>
                    <div className="point-result-container" >
                        <h1>Low <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
                        <div>Routine clinical care</div>
                    </div>
                </div>
            }
            if (result == 1) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                        <div>MUST Score</div>
                    </div>
                    <div className="point-result-container" >
                        <h1>Medium <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
                        <div>Observe; document dietary intake and if adequate/little concern, continue screening; if inadequate, improve and increase overall intake according to local policy</div>
                    </div>
                </div>
            }
            if (result >= 2) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                        <div>MUST Score</div>
                    </div>
                    <div className="point-result-container" >
                        <h1>High <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>risk</span></h1>
                        <div>
                        Treat (unless detrimental or no benefit is expected from nutritional support, e.g. imminent death); refer to dietitian, improve and increase overall intake, increase frequency of monitoring according to local policy</div>
                    </div>
                </div>
            }
        }
        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }
    return (
        <div className="rev">
            <h5 className="text-info pl-2">Malnutrition Universal Screening Tool (MUST)</h5>
            <p className="pl-2">Identifies patients who are malnourished or at risk of malnutrition.</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>BMI, kg/m2</strong></div>
                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button style={{ height:  40   }} onClick={() => setBmi(0)} className={bmi === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name small">&gt;20</span><span className="calc-val small">0</span></button>
                    <button style={{ height:  40   }} onClick={() => setBmi(1)} className={bmi === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name small">18.5-20</span><span className="calc-val small">+1</span></button>
                    <button style={{ height:  40   }} onClick={() => setBmi(2)} className={bmi === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name small">&lt; 18.5</span><span className="calc-val small">+2</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div ><strong>Unplanned weight loss in past 3-6 months</strong></div>
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={weight === 0 ? "btn active" : "btn"} onClick={() => setWeight(0)}> &lt;5% <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height:  40   }} key="2" className={weight === 1 ? "btn active" : "btn"} onClick={() => setWeight(1)}>5-10%<span className="ml-4 text-muted">+1</span></button>
                    <button style={{ height:  40   }} key="2" className={weight === 2 ? "btn active" : "btn"} onClick={() => setWeight(2)}>&gt;10% <span className="ml-4 text-muted">+2</span></button>

                </div>
            </div>
            <div className="fields row mb-5">
                <div className="col-md-5">
                    <div ><strong>Patient is acutely ill and there has been or is likely to be no nutritional intake for &gt;5 days</strong></div>
                </div>
                <div className="col-md-7 btn-group text-left">
                    <button style={{ height:  40   }} key="1" className={patient === 0 ? "btn active" : "btn"} onClick={() => setPatient(0)}>No  <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height:  40   }} key="2" className={patient === 2 ? "btn active" : "btn"} onClick={() => setPatient(2)}>Yes <span className="ml-4 text-muted">+2</span></button>
                </div>
            </div>
          <div className="stick pt-2">
              {
                  result1()
              }
          </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://www.bapen.org.uk/pdfs/must/must-report.pdf">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >Elia M. Screening for malnutrition: A multidisciplinary responsibility. Development and Use of the Malnutrition Universal Screening Tool (‘MUST’) for Adults. Redditch: BAPEN; 2003.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default RE1225
