import React, { useEffect, useState } from 'react';
import './RE1235.css';

const RE1235 = () => {
    const [number1, setNumber1] = useState(0)
    const [number2, setNumber2] = useState(0)
    const [number3, setNumber3] = useState(0)
    const [number4, setNumber4] = useState(0)
    const [number5, setNumber5] = useState(0)
    const [number6, setNumber6] = useState(0)
    const [number7, setNumber7] = useState(0)
    const [number8, setNumber8] = useState(0)
    const [number9, setNumber9] = useState(0)
    const [number10, setNumber10] = useState(0)
    const [number11, setNumber11] = useState(0)
    const [number12, setNumber12] = useState(0)
    const [number13, setNumber13] = useState(0)
    const [number14, setNumber14] = useState(0)
    const [number15, setNumber15] = useState(0)
    const [number16, setNumber16] = useState(0)
    const [number17, setNumber17] = useState(0)
    const [tunit, setTunit] = useState('cm');
    const [patientHeight, setPatientHeight] = useState([]);
    const [placehldr, setPlacehldr] = useState('Norm: 152 - 213');
    const [weight, setWeight] = useState([]);
    const [tunit1, setTunit1] = useState('kg');





    const changeunit = () => {
        if (patientHeight === 0) {
            if (tunit === 'cm') {
                setTunit('in');

            }
            if (tunit === 'in') {
                setTunit('cm');

            }
        } else if (patientHeight !== 0) {
            if (tunit === 'cm') {
                setTunit('in');
                setPlacehldr("Norm: 60 - 84");
                if (patientHeight.length !== 0) {

                    setPatientHeight((patientHeight / 2.54).toFixed(2));
                }

            }
            if (tunit === 'in') {
                setTunit('cm');
                setPlacehldr("Norm: 152 - 213");
                if (patientHeight.length !== 0) {

                    setPatientHeight((patientHeight * 2.54).toFixed(2));
                }

            }
        }
    }
    const takeUnit = (e) => {
        if (tunit === 'cm') {
            setPatientHeight(e.target.value);

        }
        if (tunit === 'in') {
            let ht = e.target.value;
            setPatientHeight(ht);
        }
    }
    const changeunit1 = () => {
        if (weight === 0) {
            if (tunit1 === 'kg') {
                setTunit1('lbs');

            }
            if (tunit1 === 'lbs') {
                setTunit1('kg');

            }
        } else if (weight !== 0) {
            if (tunit1 === 'kg') {
                setTunit1('lbs');
                if (weight.length !== 0) {

                    setWeight((weight * 2.2046226218488).toFixed(2));
                }


            }
            if (tunit1 === 'lbs') {
                setTunit1('kg');
                if (weight.length !== 0) {

                    setWeight((weight / 2.2046226218488).toFixed(2));

                }

            }
        }
    }
    const takeUnit1 = (e) => {
        if (tunit1 === 'kg') {
            setWeight(e.target.value);


        }
        if (tunit1 === 'lbs') {
            let ht = e.target.value;
            setWeight(ht);

        }
    }
    // --------------------------------------------------
    return (
        <div className="rev">
            <h5 className="text-info pl-2">Nutrition Risk in the Critically Ill (NUTRIC) Score</h5>
            <p className="pl-2">Identifies critically ill patients who can benefit from nutrition therapy, recommended by ACG guidelines..</p>
            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Age Group</strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number1}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber1(patient);
                        }}
                    >

                        <option value="">select</option>
                        <option value='1'>Under 65 years</option>
                        <option value='2' >65-74</option>
                        <option value='3'>75-84</option>
                        <option value='4' >85 years or older</option>
                    </select>
                </div>

            </div>
            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Sex</strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number2}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber2(patient);
                        }}
                    >

                        <option value="">select</option>
                        <option value='1'>Female</option>
                        <option value='2' >Male</option>
                    </select>
                </div>

            </div>
            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Function Status</strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number3}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber3(patient);
                        }}
                    >

                        <option value="">select</option>
                        <option value='1'>Independent</option>
                        <option value='2' >Partially Dependent</option>
                        <option value='3'>Totally Dependent</option>
                    </select>
                </div>

            </div>
            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>
                        Emergency Case</strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number4}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber4(patient);
                        }}
                    >

                        <option value="">select</option>
                        <option value='1'>No</option>
                        <option value='2' >Yes</option>
                    </select>
                </div>

            </div>
            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>
                        ASA Class</strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number5}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber5(patient);
                        }}
                    >

                        <option value="">select</option>
                        <option value='1'>Healthy Patient</option>
                        <option value='2' >Mild systemic disease</option>
                        <option value='3' >Severe systemic disease</option>
                        <option value='4' >Severe systemic disease/constant threat to life </option>
                        <option value='5' >Moribund / not expected to survive surgery</option>
                    </select>
                </div>

            </div>
            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>
                        Steroid use for chronic condition</strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number6}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber6(patient);
                        }}
                    >

                        <option value="">select</option>
                        <option value='1'>No</option>
                        <option value='2' >Yes</option>
                    </select>
                </div>

            </div>
            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>
                        Ascites within 30 days prior to surgery</strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number6}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber6(patient);
                        }}
                    >

                        <option value="">select</option>
                        <option value='1'>No</option>
                        <option value='2' >Yes</option>
                    </select>
                </div>

            </div>
            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>
                        Systemic Sepsis within 48 hours prior to surgery</strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number7}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber7(patient);
                        }}
                    >

                        <option value="">select</option>
                        <option value='1'>None</option>
                        <option value='2' >SIRS</option>
                        <option value='1'>Sepsis</option>
                        <option value='2' >Septic Shock</option>
                    </select>
                </div>

            </div>
            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>
                        Ventilator Dependent</strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number7}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber7(patient);
                        }}
                    >
                        <option value="">select</option>
                        <option value='1'>No</option>
                        <option value='2' >Yes</option>
                    </select>
                </div>

            </div>

            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>
                        Disseminated Cancer </strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number9}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber9(patient);
                        }}
                    >
                        <option value="">select</option>
                        <option value='1'>No</option>
                        <option value='2' >Yes</option>
                    </select>
                </div>

            </div>
            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>
                        Diabetes  </strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number10}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber10(patient);
                        }}
                    >
                        <option value="">select</option>
                        <option value='1'>No</option>
                        <option value='2' >Oral</option>
                        <option value='3' >Insulin</option>
                    </select>
                </div>

            </div>
            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>
                        Hypertension requiring medication </strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number11}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber11(patient);
                        }}
                    >
                        <option value="">select</option>
                        <option value='1'>No</option>
                        <option value='2' >Yes</option>
                    </select>
                </div>

            </div>
            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>
                        Congestive Heart Failure in 30 days prior to surgery </strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number12}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber12(patient);
                        }}
                    >
                        <option value="">select</option>
                        <option value='1'>No</option>
                        <option value='2' >Yes</option>
                    </select>
                </div>

            </div>
            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>
                        Dyspnea </strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number13}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber13(patient);
                        }}
                    >
                        <option value="">select</option>
                        <option value='1'>No</option>
                        <option value='2' >With Moderant exertion </option>
                        <option value='2' >At rest</option>
                    </select>
                </div>

            </div>
            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>
                        Current Smoker within 1 Year  </strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number14}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber14(patient);
                        }}
                    >
                        <option value="">select</option>
                        <option value='1'>No</option>
                        <option value='2' >yes</option>
                    </select>
                </div>

            </div>
            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>
                        History of Severe COPD </strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number15}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber15(patient);
                        }}
                    >
                        <option value="">select</option>
                        <option value='1'>No</option>
                        <option value='2' >Yes</option>
                    </select>
                </div>

            </div>
            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>
                        Dialysis</strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number16}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber16(patient);
                        }}
                    >
                        <option value="">select</option>
                        <option value='1'>No</option>
                        <option value='2' >Yes</option>
                    </select>
                </div>

            </div>
            <div className="fields row w-100">
                <div className="col-md-5">
                    <div className="pt-2"><strong>
                        Acute Renal Failure</strong>
                    </div>
                </div>
                <div className="col-md-7">
                    <select className="custom-select" aria-label="Age"
                        value={number17}
                        onChange={(e) => {
                            const patient = e.target.value;

                            setNumber17(patient);
                        }}
                    >
                        <option value="">select</option>
                        <option value='1'>No</option>
                        <option value='2' >Yes</option>
                    </select>
                </div>
            </div>
            <div class="alert alert-success" role="alert">
                BMI Calculator :
            </div>

            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong> Height</strong></div>
                </div>
                <div className="col-md-7 frm-field">
                    <input style={{ width: "77%" }}
                        onChange={takeUnit}
                        placeholder={placehldr}
                        className="ant-text-input"
                        type="number"
                        value={patientHeight} /><span className="units" onClick={changeunit}>{tunit}&#8651;</span>
                </div>
            </div>
            <div className="fields row mb-5">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Weight</strong></div>
                </div>
                <div className="col-md-7 frm-field">
                    <input
                        onChange={takeUnit1}
                        type="number"

                        className="ant-text-input" style={{ width: "77%" }} value={weight} /><span className="units" onClick={changeunit1}>{tunit1}&#8651;</span>

                </div>
            </div>

            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/22085763/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >Heyland DK, Dhaliwal R, Jiang X, Day AG. Identifying critically ill patients who benefit the most from nutrition therapy: the development and initial validation of a novel risk assessment tool. Crit Care. 2011;15(6):R268..</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default RE1235
