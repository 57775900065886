import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';

const Acc = () => {
    const [green, setGreen] = useState(false);
 
    const [result, setResult] = useState(0);



    const [Age, setAge] = useState(0);
    const [gender, setGender] = useState(0);
    const [race, setRace] = useState(0);
    const [tunit, setTunit] = useState('mmol/L');
    const [totalCholesterol, setTotalCholesterol] = useState([]);
    const [placehldr, setPlacehldr] = useState('Norm: 2.9-7.1');
    const [placehldr1, setPlacehldr1] = useState('Norm: 125-250')
    const [Hdl, setHdl] = useState([]);
    const [tunit1, setTunit1] = useState('mmol/L');
    const [systolic, setSystolic] = useState(0);
    const [highBP, setHighBP] = useState(0);
    const [smoker, setSmoker] = useState(0);
    const [diabetes, setDiabetes] = useState(0);



    const postData = async () => {
        setResult(0);
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [result]);


    const changeunit = () => {
        if (totalCholesterol === 0) {
            if (tunit === 'mmol/L') {
                setTunit('mg/dL');

            }
            if (tunit === 'mg/dL') {
                setTunit('mmol/L');

            }
        } else if (totalCholesterol !== 0) {
            if (tunit === 'mmol/L') {
                setPlacehldr("Norm: 8-20");

                setTunit('mg/dL');
                if (totalCholesterol.length !== 0) {
                    setTotalCholesterol((totalCholesterol * 2.80112044).toFixed(2))
                }
            }
            if (tunit === 'mg/dL') {
                setPlacehldr("Norm: 2.9-7.1");
                setTunit('mmol/L');
                if (totalCholesterol.length !== 0) {
                    setTotalCholesterol((totalCholesterol / 2.80112044).toFixed(2))
                }

            }
        }
    }
    const takeUnit = (e) => {
        if (tunit === 'mmol/L') {
            setTotalCholesterol(e.target.value);
            setPlacehldr("Norm: 2.9-7.1");

        }
        if (tunit === 'mg/dL') {
            let ht = e.target.value;
            setTotalCholesterol(ht);
            setPlacehldr("Norm: 8-20");
        }
    }

    const changeunit1 = () => {
        if (Hdl === 0) {
            if (tunit1 === 'mmol/L') {
                setTunit1('mg/dL');

            }
            if (tunit1 === 'mg/dL') {
                setTunit1('mmol/L');

            }
        } else if (Hdl !== 0) {
            if (tunit1 === 'mmol/L') {
                setPlacehldr1("Norm: 350-700");
                setTunit1('mg/dL');
                if (Hdl.length !== 0) {
                    setHdl((Hdl * 2.80112044).toFixed(2))
                }
            }
            if (tunit1 === 'mg/dL') {
                setPlacehldr1("Norm: 125-250");
                setTunit1('mmol/L');
                if (Hdl.length !== 0) {
                    setHdl((Hdl / 2.80112044).toFixed(2))
                }

            }
        }
    }
    const takeUnit1 = (e) => {
        if (tunit1 === 'mmol/L') {
            setHdl(e.target.value);
            setPlacehldr1("Norm: 125-250");

        }
        if (tunit1 === 'mg/dL') {
            let ht = e.target.value;
            setHdl(ht);
            setPlacehldr1("Norm: 350-700");
        }
    }


    return (
        
        <div className="rev">
             <h5 className="text-info pl-2">ACC/AHA CV Risk Calculator (2013)</h5>
        <p className="pl-2">Estimate 10-year risk for atherosclerotic cardiovascular disease</p>
         
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Age</strong></div>

                </div>
                <div className="col-md-7 frm-field">
                    <input type="number" style={{ width: "90%" }} className="ant-text-input" placeholder="Norm: 40 - 79" onChange={(e) => setAge(e.target.value)} /><span className="units ">Years</span>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Gender</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left" >
                    <button style={{ height: 40 }} key="1" className={gender === 1 ? "btn active" : "btn"} onClick={() => setGender(1)}>Male </button>
                    <button style={{ height: 40 }} key="2" className={gender === 2 ? "btn active" : "btn"} onClick={() => setGender(2)}>Female</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Race</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={race === 1 ? "btn active" : "btn"} onClick={() => setRace(1)}>African-American </button>
                    <button style={{ height: 40 }} key="2" className={race === 2 ? "btn active" : "btn"} onClick={() => setRace(2)}>White/Other</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Total Cholesterol</strong></div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </div>
                <div className="col-md-7 frm-field ">
                    <input type="number" style={{ width: "90%" }}

                        onChange={takeUnit}
                        placeholder={placehldr}

                        className="ant-text-input fluidInputfu" value={totalCholesterol} /><span className="units unitBtnfu" onClick={changeunit}>{tunit}&#8651;</span>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>HDL Cholesterol</strong></div>
                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        onChange={takeUnit1}
                        placeholder={placehldr1}

                        className="ant-text-input" style={{ width: "90%" }} value={Hdl} /><span className="units" onClick={changeunit1}>{tunit1}&#8651;</span>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Systolic Blood Pressure</strong></div>

                </div>
                <div className="col-md-7 frm-field">
                    <input  type="number" style={{ width: "77%" }} className="ant-text-input " placeholder="Norm: 40 - 79" onChange={(e) => setSystolic(e.target.value)} /><span className="units ">mmHg</span>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Treatment for High Blood Pressure?</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={highBP === 1 ? "btn active" : "btn"} onClick={() => setHighBP(1)}>Yes </button>
                    <button style={{ height: 40 }} key="2" className={highBP === 2 ? "btn active" : "btn"} onClick={() => setHighBP(2)}>NO</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Smoker</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={smoker === 1 ? "btn active" : "btn"} onClick={() => setSmoker(1)}>Yes</button>
                    <button style={{ height: 40 }} key="2" className={smoker === 2 ? "btn active" : "btn"} onClick={() => setSmoker(2)}>No</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Diabetes</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={diabetes === 1 ? "btn active" : "btn"} onClick={() => setDiabetes(1)}>Yes</button>
                    <button style={{ height: 40 }} key="2" className={diabetes === 2 ? "btn active" : "btn"} onClick={() => setDiabetes(2)}>No</button>

                </div>
            </div>
          




            <div className="stick">
                {
                    result != 0 ? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container" >
                                <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>




                            </div>






                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <br/>
            <div className="p-2" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://read.qxmd.com/read/24222018/2013-acc-aha-guideline-on-the-assessment-of-cardiovascular-risk-a-report-of-the-american-college-of-cardiology-american-heart-association-task-force-on-practice-guidelines">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >2013 ACC/AHA guideline on the assessment of cardiovascular risk: a report of the American College of Cardiology/American Heart Association Task Force on Practice Guidelines.</p>
                </div>
            </a>
        </div>
        </div>
    )
}


export default Acc;

