import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';

const Care = () => {
    const [green, setGreen] = useState(false);
    const [patientStatus, setPatientStatus] = useState(0);
    const [emergency, setEmergency] = useState(null);
  
    const [result, setResult] = useState(0);

    const postData = async () => {
        setResult(patientStatus );
        setGreen(true);
        if(patientStatus == 1 || patientStatus == 2){
            setEmergency(null);
        }
    }
    useEffect(() => {
        postData();


    }, [patientStatus, result ,emergency]);




const Newfiels = () =>{
    if(patientStatus >= 3){
        return <div>
                     <div className="fields row mb-5">
                    <div className="col-md-4 col-sm-12">
                        <div className="pt-2"><strong>Emergency: surgery as soon as diagnosis is made and operating room is available</strong></div>
                    </div>
                    <div className="col-md-7 col-sm-8 btn-group" >
                        <button  className={emergency === null? "btn active text-white" : "btn "} style={{ height: '100%'}} onClick={() => setEmergency(null)}>No (0) <span className="calc-val text-right"></span></button>
                        <button  className={emergency === "E"? "btn active text-white" : "btn "} style={{ height: '100%'}} onClick={() => setEmergency("E")}>Yes (+1)<span className="calc-val text-right"></span></button>
                    </div>

                </div>
        </div>

    }
}

return (
    <div className="rev">
          <h5 className="text-info pl-2">Cardiac Anesthesia Risk Evaluation Score (CARE)</h5>
          <p className="pl-2">Predicts mortality and morbidity after cardiac surgery.</p>
        <div>
        <div className="fields row mb-5">
            <div className="col-md-4">
                <div className="pt-2"><strong>Patient status</strong></div>

            </div>
            <div className="col-md-8 btn-group-vertical options-btn mb-2">
                <button style={{ height: 60}} onClick={() => setPatientStatus(1)} className={patientStatus === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name  ">Stable cardiac disease, no other medical<br/> problems, undergoing noncomplex surgery</span><span className="calc-val  ">+1</span></button>
                <button style={{ height: 60}} onClick={() => setPatientStatus(2)} className={patientStatus === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Stable cardiac disease, one or more controlled<br/> medical problems, undergoing noncomplex surgery</span><span className="calc-val ">+2</span></button>
                <button style={{ height: 60}} onClick={() => setPatientStatus(3)} className={patientStatus === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Any uncontrolled medical problem OR undergoing<br/> complex surgery</span><span className="calc-val ">+3</span></button>
                <button style={{ height: 60}} onClick={() => setPatientStatus(4)} className={patientStatus === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Any uncontrolled medical problem AND undergoing<br/> complex surgery</span><span className="calc-val ">+4</span></button>
                <button style={{ height: 60}} onClick={() => setPatientStatus(5)} className={patientStatus === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name  ">Chronic or advanced cardiac disease undergoing <br/>cardiac surgery as a last hope to save or improve life</span><span className="calc-val ">+5</span></button>

            </div>
            </div>
            <div>{Newfiels()}</div>
        </div>



        <div className="stick">
            {
                result !=0 ? <>
                    <div className="apfel-score-result-container col-12 " >
                        <div className="point-result-container col-12" >
                            <h1> CARE {result}{emergency} </h1>

                          
                                <div>{result == 1  ? "0.5% in-hospital mortality" : " "}</div>
                                <div>{result == 1  ? "5.4% morbidity (See Formula section for morbidity definitions.)" : " "}</div>
                               
                                <div>{result == 2 ? "1.1% in-hospital mortality" : " "}</div>
                                <div>{result == 2 ? "10.3% morbidity (See Formula section for morbidity definitions.)" : " "}</div>
                                <div>{result == 3 && emergency !== "E" ? "2.2% in-hospital mortality" : " "}</div>
                                <div>{result == 3 && emergency !== "E"  ? "19.0% morbidity (See Formula section for morbidity definitions.)" : " "}</div>
                                <div>{result == 3 && emergency === "E" ? "4.5% in-hospital mortality" : " "}</div>
                                <div>{result == 3  && emergency === "E"  ? "32.1% morbidity (See Formula section for morbidity definitions.)" : " "}</div>
                                <div>{result == 4 && emergency !== "E" ? "8.8% in-hospital mortality" : " "}</div>
                                <div>{result == 4  && emergency !== "E"? "48.8% morbidity (See Formula section for morbidity definitions.)" : " "}</div>
                                <div>{result == 4 && emergency === "E" ? "16.7% in-hospital mortality" : " "}</div>
                                <div>{result == 4 && emergency === "E" ? "65.8% morbidity (See Formula section for morbidity definitions.)" : " "}</div>
                                <div>{result == 5 && emergency !== "E"  ? "29.3% in-hospital mortality" : " "}</div>
                                <div>{result == 5  && emergency !== "E"? "79.6% morbidity (See Formula section for morbidity definitions.)" : " "}</div>
                                <div>{result == 5 && emergency === "E"  ? "46.2% in-hospital mortality" : " "}</div>
                                <div>{result == 5 && emergency === "E"  ? "88.7% morbidity (See Formula section for morbidity definitions.)" : " "}</div>
                              
                              

                       
                        </div>






                    </div>



                </>

                    :
                    <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>
            }
        </div>
        <br/>
       <div className="p-2" >
        <h6>
            ORIGINAL/PRIMARY REFERENCE
        </h6>
        <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/11176081/">
            <div className="reference-container-card" >
                <div className="reference-img" >
                    <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                </div>
                <p className="reference-paragraph" >Dupuis JY et al. The Cardiac Anesthesia Risk Evaluation Score A Clinically Useful Predictor of Mortality and Morbidity after Cardiac Surgery. The Journal of the American Society of Anesthesiologists 94.2 (2001): 194-204.</p>
            </div>
        </a>
    </div>
    </div>
)
}


export default Care;

