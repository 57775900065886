import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_ROOT } from '../constants';
import { Link } from 'react-router-dom';
const AfterAirway = (props) => {
    const [resp, setResp] = useState([])
    const [show, setshow] = useState(false)
    const [resp2, setResp2] = useState([])
    const [show2, setshow2] = useState(false)
    const [resp3, setResp3] = useState([])
    const [show3, setshow3] = useState(false)
    const [resp4, setResp4] = useState([])
    const [show4, setshow4] = useState(false)
    const [resp5, setResp5] = useState([])
    const [show5, setshow5] = useState(false)
    var reqName = props.location.state.name
    var reqValue = props.location.state.value

    useEffect(() => {
        callLma()
    }, [])

    const callLma = async () => {
        var token = await sessionStorage.getItem("token")
        if (reqName === "LMA") {
            const body = {
                "lma_size": reqValue
            }
            const res = await Axios.post(API_ROOT + `airway-lma-details?token=${token}`, body, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })

            if (res.data.status === "true") {
                setResp(res.data.data)
                setshow(true)
            }
        }

        else if (reqName === "ET Tube Uncuffed") {
            const body = {
                "et_tube_uncuffed": reqValue
            }
            const res = await Axios.post(API_ROOT + `airway-lma-details?token=${token}`, body, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })

            if (res.data.status === "true") {
                setResp2(res.data.data)
                setshow2(true)
            }
        }
        else if (reqName === "ET Tube Cuffed") {
            const body = {
                "et_tube_cuffed": reqValue
            }
            const res = await Axios.post(API_ROOT + `airway-lma-details?token=${token}`, body, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })

            if (res.data.status === "true") {
                setResp2(res.data.data)
                setshow2(true)
            }
        }
        else if (reqName === "Double lumen ETT") {
            const body = {
                "double_lumen_ett": reqValue
            }
            const res = await Axios.post(API_ROOT + `airway-lma-details?token=${token}`, body, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })

            if (res.data.status === "true") {
                setResp3(res.data.data)
                setshow3(true)
                setshow4(false)
                setshow5(false)
            }
        }
        else if (reqName === "Bronchial blocker") {
            const body =
            {

                "bronchial_blocker": reqValue
            }

            const res = await Axios.post(API_ROOT + `airway-lma-details?token=${token}`, body, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })

            if (res.data.status === "true") {
                setResp4(res.data.data)
                setshow4(true)
                setshow5(false)
                setshow3(false)
            }
        }
        else if (reqName === "Univent") {
            const body =
            {

                "univent": reqValue
            }

            const res = await Axios.post(API_ROOT + `airway-lma-details?token=${token}`, body, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })

            if (res.data.status === "true") {
                setResp5(res.data.data)
                setshow5(true)
                setshow3(false)
                setshow4(false)
            }
        }
    }
   
    return (
        <div>
            <div className="backNavigation text-center">
                <Link className="arrow" to="/actionLibrary"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Action Library</span></span></Link>{reqName}</div>
           <div className="main-container lam">
            {
                show ?
                    <>
                        {
                            resp.map((data) => {
                                return (
                                    <div  className="card m-3 p-3 text-left">
                                        <div className="row">
                                        <div className="col-md-10">Airway exchanger cook fr:</div>
                                        <div className="col-md-2 text-right">{data.airway_exchanger_cook_fr}</div>
                                        </div>
                                        <div className="row">
                                        <div className="col-md-10">LMA maximum cuff inflation volume ml air:</div>
                                        <div className="col-md-2 text-right">{data.lma_maximum_cuff_inflation_volume_ml_air}</div>
                                        </div>
                                        <div className="row">
                                        <div className="col-md-10">LMA maximum ETT size:</div>
                                        <div className="col-md-2 text-right">{data.lma_maximum_ett_size}</div>
                                        </div><div className="row">
                                        <div className="col-md-10">Maximum fob size mm:</div>
                                        <div className="col-md-2 text-right">{data.maximum_fob_size_mm}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
                    :
                    <>
                    </>
            }
            {
                show2 && resp2 ?
                    <>
                        {
                            resp2.map((data) => {
                                return (
                                   
                                     <div  className="card m-3 p-3 text-left">
                                     <div className="row">
                                     <div className="col-md-10">Airway exchanger cook fr:</div>
                                     <div className="col-md-2 text-right">{data.airway_exchanger_cook_fr}</div>
                                     </div>
                                     <div className="row">
                                     <div className="col-md-10">ET tube insertion lengh at lips cm:</div>
                                     <div className="col-md-2 text-right">{data.et_tube_insertion_lengh_at_lips_cm}</div>
                                     </div>
                                     <div className="row">
                                     <div className="col-md-10">LMA size:</div>
                                     <div className="col-md-2 text-right">{data.lma_size}</div>
                                     </div>
                                 </div>
                                )
                            })
                        }
                    </>
                    :
                    <>
                    </>
            }
            {
                show3 ?
                    <>
                        {
                            resp3.map((data) => {
                                return (
                                   
                                    <div  className="card m-3 p-3 text-left">
                                    <div className="row">
                                    <div className="col-md-10">Maximum fob size mm:</div>
                                    <div className="col-md-2 text-right">{data.maximum_fob_size_mm}</div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-10">ET tube cuffed mm:</div>
                                    <div className="col-md-2 text-right">{data.et_tube_cuffed_mm}</div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-10">Maximum fob size mm:</div>
                                    <div className="col-md-2 text-right">{data.maximum_fob_size_mm}</div>
                                    </div>
                                </div>
                                )
                            })
                        }
                    </>
                    :
                    <>
                    </>
            }
            {
                show4 && resp4 ?
                    <>
                        {resp4.map((data) => {
                            return (
                                <div  className="card m-3 p-3 text-left">
                                <div className="row">
                                <div className="col-md-10">Bronchial blocker fr min:</div>
                                <div className="col-md-2 text-right">{data.bronchial_blocker_fr_min}</div>
                                </div>
                                <div className="row">
                                <div className="col-md-10">Bronchial blocker fr max:</div>
                                <div className="col-md-2 text-right">{data.bronchial_blocker_fr_max}</div>
                                </div>
                            </div>
                            )
                        })}

                    </>
                    :
                    <>
                    </>
            }
            {
                show5 && resp5 ?
                    <>
                        {resp5.map((data) => {
                            return (
                                 <div  className="card m-3 p-3 text-left">
                                 <div className="row">
                                 <div className="col-md-10">Univent mm min:</div>
                                 <div className="col-md-2 text-right">{data.univent_mm_min}</div>
                                 </div>
                                 <div className="row">
                                 <div className="col-md-10">Univent mm max:</div>
                                 <div className="col-md-2 text-right">{data.univent_mm_max}</div>
                                 </div>
                             </div>
                            )
                        })}

                    </>
                    :
                    <>
                    </>
            }
        </div>
        </div>
    )
}

export default AfterAirway
