import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { API_ROOT } from '../../../constants'
import axios from 'axios'

const ViewCaseLogs = (props) => {
    const [age, setage] = useState(null);
    const [ageType, setAgeType] = useState("Year");
    const [gender, setgender] = useState(null);
    const [patientType, setpatientType] = useState(null);
    const [height, setheight] = useState(null);
    const [heightType, setHeightType] = useState(null);
    const [weight, setweight] = useState(null);
    const [weightType, setWeightType] = useState(null);
    const [fileName, setfileName] = useState(null);
    const [npo_status_time, setnpo_status_time] = useState(null);
    const [foodTimeType, setfoodTimeType] = useState(null)
    const [npo_status_materials, setnpo_status_materials] = useState(null);
    const [surgeryData, setsurgeryData] = useState([]);
    const [surgeryTypesListData, setsurgeryTypesListData] = useState([]);
    const [allergies, setallergies] = useState([]);
    const [anesthesia_histories, setanesthesia_histories] = useState([]);
    const [midical_histories, setmidical_histories] = useState([]);
    const [midications, setmidications] = useState([]);
    const [asa_ps, setasa_ps] = useState([]);
    const [case_notes, setCase_notes] = useState([]);
    const [case_notes_img, setCase_notes_img] = useState([]);
    const [surgeryHistory, setSurgeryHistory] = useState([])
    const [anesthesiaTypes, setAnesthesiaTypes] = useState([])

    useEffect(() => {
        getCaseSummaryDataAsPerId(props.match.params.id)
    }, [])

    const getCaseSummaryDataAsPerId = async (caseId) => {
        try {
            const token = sessionStorage.getItem("token")
            let res = await axios.get(`${API_ROOT}case-summary-by-id/${caseId}?token=${token}`)
            let ageType = '';
            if (res.data.data?.age > 1 && res.data.data?.age_type === 'Y') {
                ageType = 'Years';
            }
            if (res.data.data?.age <= 1 && res.data.data?.age_type === 'Y') {
                ageType = 'Year';
            }
            if (res.data.data?.age > 1 && res.data.data?.age_type === 'M') {
                ageType = 'Months';
            }
            if (res.data.data?.age <= 1 && res.data.data?.age_type === 'M') {
                ageType = 'Month';
            }
            if (res.data.data?.age > 1 && res.data.data?.age_type === 'W') {
                ageType = 'Weeks';
            }
            if (res.data.data?.age <= 1 && res.data.data?.age_type === 'W') {
                ageType = 'Week';
            }
            if (res.data.data?.age > 1 && res.data.data?.age_type === 'D') {
                ageType = 'Days';
            }
            if (res.data.data?.age <= 1 && res.data.data?.age_type === 'D') {
                ageType = 'Day';
            }
            if (res.data.data?.gender !== null) {
                res.data.data.gender = res.data.data.gender[0].toUpperCase() + (res.data.data.gender).slice(1)
            } else {
                res.data.data.gender = null
            }
            setage(res.data.data.age);
            setAgeType(res.data.data.age_type);
            setgender(res.data.data.gender);
            setpatientType(res.data.data.patient_type_name);
            setheight(res.data.data.height);
            setHeightType(res.data.data.height_type);
            setweight(res.data.data.weight);
            setWeightType(res.data.data.weight_type);
            setfileName(res.data.data.name);
            setnpo_status_time(res.data.data.npo_status_time);
            setfoodTimeType(res.data.data.npo_status_time_type)
            setnpo_status_materials(res.data.data.npo_status_materials);
            setsurgeryData(res.data.surgeryTypes);
            setAnesthesiaTypes(res.data.anesthesiaTypes)
            setSurgeryHistory(res.data.surgeryHistories)
            setsurgeryTypesListData(res.data.surgeryTypesListData);
            setallergies(res.data.allergies);
            setanesthesia_histories(res.data.anesthesia_histories);
            setmidical_histories(res.data.midical_histories);
            setmidications(res.data.medications);
            setasa_ps(res.data.asa_ps);
            setCase_notes(res.data.data.case_note_message);
            setCase_notes_img(res.data.data.case_note_image);
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div>
            <div className="backNavigation text-center">
                <Link className="arrow" to="/case-logs"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Cases</span></span></Link>VIEW CASE SUMMARY
            </div>
            <div className="main-container mt-3">
                <div className="custom-heade-case-summary alert alert-info">
                    <div><strong>Case Name :{fileName === null ? '-' : fileName}</strong></div>
                    {/* <div className="sub-container btn-group">
                        <button onClick={() => nameModalVisible()} className="btn">Save</button>
                        <button onClick={() => closeModal()} className="btn">Close</button>
                    </div> */}
                </div>
                <div className="all-action-container mt-3" >
                    {/* <div className="btn-group">
                    <button className="btn sub-nav active"><Link to='/startacase/casesummary'>
                            CASE SUMMARY
                        </Link>
                        </button>
                        <button className="btn sub-nav">
                        <Link  to='/startacase/actionsummary'>
                            ACTION SUMMARY
                        </Link>
                        </button>
                        <button className="btn sub-nav" disabled> CASENAME: {fileName === null ? '-' : fileName}</button>
                        </div> */}
                    <div className="answer-wrapper">
                        <div className="result-container mt-3" >
                            <div className="answer-container" >
                                <div>
                                    CASE Notes
                                </div>
                                <div>{case_notes_img ? <img src={case_notes_img} className="img-thumbnail" /> : <></>} {case_notes !== "null" ? <>{case_notes}</> : <></>}</div>
                            </div>
                            {(age != null||ageType != null) &&
                                <div className="answer-container" >
                                    <div>
                                        AGE
                                    </div>
                                    <div>
                                        {age} {age != null ? <>{age <= 1 ? <>{ageType}</> : <>{ageType}s</>}</> : <></>}
                                    </div>
                                </div>
                            }
                            {gender!=null&&
                                <div className="answer-container" >
                                <div>
                                    GENDER
                                </div>
                                <div>
                                    {gender}
                                </div>
                            </div>
                            }
                            {patientType!=null&&
                                <div className="answer-container" >
                                <div>
                                    PATIENT TYPE
                                </div>
                                <div>
                                    {patientType}
                                </div>
                            </div>
                            }
                            {(height!=null||heightType!=null) &&
                                <div className="answer-container" >
                                <div>
                                    HEIGHT
                                </div>
                                <div>
                                    {height}{heightType !== null ? "" : null} {heightType}
                                </div>
                            </div>
                            }
                            {(weight!=null||weightType!=null) &&  
                                <div className="answer-container" >
                                <div>
                                    WEIGHT
                                </div>
                                <div>
                                    {weight}{weightType !== null ? "" : null} {weightType}
                                </div>
                            </div>
                            }
                            {asa_ps?.length?
                                <div className="answer-container" >
                                <div>
                                    ASA-PS
                                </div>
                                <div>
                                    {
                                        asa_ps?.map((data) => {
                                            return (
                                                <div>{data.name}, {data.emergency}</div>
                                            )
                                        })
                                    }
                                    {/*asaps.map((data) => {
                                        return (
                                            <div>
                                                {data.label},
                                            </div>
                                        )
                                    })*/}
                                </div>
                            </div>:<></>
                            }
                            {surgeryData?.length?
                                <div className="answer-container" >
                                <div>
                                    SURGERY TYPE
                                </div>
                                <div  >
                                    {
                                        surgeryData?.map((data) => {
                                            return (
                                                <div>{data.name}</div>
                                            )
                                        })
                                    }

                                </div>
                            </div>:<></>
                            }
                            {anesthesiaTypes?.length?
                                <div className="answer-container" >
                                <div>
                                    ANESTHESIA TYPE
                                </div>
                                <div>
                                    {anesthesiaTypes?.map((data) => {
                                        return (
                                            <p>{data.name}</p>
                                        )
                                    })}
                                </div>
                            </div>:<></>
                            }
                            {(npo_status_time!=null||foodTimeType!=null||npo_status_materials!=null) &&
                                <div className="answer-container" >
                                <div>
                                    NPO STATUS
                                </div>
                                <div>
                                    {npo_status_time}{foodTimeType !== null ? "" : null} {foodTimeType}{npo_status_materials !== null ? "" : null}  {npo_status_materials}
                                </div>
                            </div>
                            }
                            
                            {midications?.length?
                                <div className="answer-container" >
                                <div>
                                    MEDICATIONS
                                </div>
                                <div>

                                    {
                                        midications?.map((data) => {
                                            return (
                                                <div>{data.name}</div>
                                            )
                                        })
                                    }
                                    {/*
                                    medication.map((data) => {
                                        return (
                                            <div>{data.label}</div>
                                        )
                                    })
                                */}
                                </div>
                            </div>:<></>

                            }
                            {allergies?.length?
                                <div className="answer-container" >
                                <div>
                                    ALLERGIES
                                </div>
                                <div>

                                    {
                                        allergies?.map((data) => {
                                            return (
                                                <div>{data.name}</div>
                                            )
                                        })
                                    }
                                    {/*
                                    allegries.map((data) => {
                                        return (
                                            <div>{data.label}</div>
                                        )
                                    })
                                */}
                                </div>
                            </div>:<></>
                            }
                            

                            {midical_histories?.length?
                                <div className="answer-container" >
                                <div>
                                    MEDICAL HISTORY
                                </div>
                                <div>

                                    {
                                        midical_histories?.map((data) => {
                                            return (
                                                <div>{data.name}</div>
                                            )
                                        })
                                    }
                                    {/*
                                        medicalHistoryArray.map((data) => {
                                            return (
                                                <div>
                                                    {data},
                                                </div>
                                            )
                                        })
                                    */}
                                </div>
                            </div>:<></>

                            }
                            
                            {surgeryHistory?.length?
                                <div className="answer-container" >
                                <div>
                                    SURGICAL HISTORY
                                </div>
                                <div>
                                    {surgeryHistory?.map((data) => {
                                        return (
                                            <p>{data.name}</p>
                                        )
                                    })}

                                </div>
                            </div>:<></>
                            }
                            
                            {anesthesia_histories?.length?
                                <div className="answer-container" >
                                <div>
                                    ANESTHESIA HISTORY
                                </div>
                                <div>

                                    {
                                        anesthesia_histories?.map((data) => {
                                            return (
                                                <div>{data.name}</div>
                                            )
                                        })
                                    }
                                    {/*
                                    anesethesiaSubHistory.map((data) => {
                                        return (
                                            <div>
                                                {data.label}
                                            </div>
                                        )
                                    })
                                */}
                                </div>
                            </div>:<></>
                            
                            }
                            {/* <div className="answer-container" >
                                <div>
                                    CASE TIPS
                                </div>
                                <div>

                                    {
                                        choiceAnswer.map((data) => {
                                            return (
                                                <div>{data.name}</div>
                                            )
                                        })
                                    } 
                                    {
                                    anesethesiaSubHistory.map((data) => {
                                        return (
                                            <div>
                                                {data.label}
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewCaseLogs