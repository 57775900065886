import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';
import { Link } from 'react-router-dom';
import BicarbonateDeficit from './BicarbonateDeficit';
import DialysisRiskClinic from './DialysisRiskClinic';
import FenaFluidCalculator from './FenaFluidCalculator';
import FeureaFluidCalculator from './FeureaFluidCalculator';
import IfdaFluidCalculator from './IfdaFluidCalculator';
import FluidMaximumAbl from './FluidMaximunAbl';
import PlasmaFluidCalculator from './PlasmaFluidCalculator';
import RIFLEFluidCalculator from './RIFLEFluidCalculator';
import FluidsCalculator from './FluidsCalculator';
import AnionGap from './AnionGap';
import WintersFormula from './WintersFormula';
import DialysisRiskMehta from './DialysisRiskMehta';
import Abg from './Abg';
import SirsSepsis from './SirsSepsis';
import FluidBloodVolume from './FluidBloodVolume';
import SIRSpediatric from './SIRSpediatric';
const RenalCalculators = (props) => {
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
    };
    useEffect(() => {
        toggleTab(1);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
    }, [])

    return (
        <div>
            <div className="backNavigation text-center">
                <Link className="arrow" to="/actionLibrary"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Action Library</span></span></Link> RENAL CALCULATORS
            </div>
            <div className="main-container calculator-container mt-3">
                <div className="row">
                    <div className="col-md-4 airway-calc-tabs">
                    <button className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(1)} >ABG</button>
                        <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(2)} >Anion Gap</button>
                        <button className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(3)} >Bicarbonate Deficit</button>
                             <button className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(4)} >Blood Volume Calculation</button>
                        <button className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(5)} >Dialysis Risk After Cardiac Surgery (Cleveland Clinic Score by Thakar)</button>
                        <button className={toggleState === 6 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(6)} >Dialysis Risk After Cardiac Surgery (Mehta)</button>
                        <button className={toggleState === 7 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(7)} >FENa</button>
                        <button className={toggleState === 8 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(8)} >FEUrea</button>
                        <button className={toggleState === 9 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(9)} >Intraoperative Fluid Dosing in Adult Patients</button>  
                            <button className={toggleState === 10 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(10)} >Maximum allowable blood loss without transfusion</button>  
                        <button className={toggleState === 11 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(11)} >Plasma Volume</button>
                        <button className={toggleState === 12 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(12)} >RIFLE Criteria for AKI</button>
                        <button className={toggleState === 13 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(13)} >Serum Osmolality / Osmolarity</button>
                            <button className={toggleState === 14 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(14)} >SIRS, Sepsis and Septic Shock</button>
                            <button className={toggleState === 15 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(15)} >SIRS - Pediatric</button>
                        <button className={toggleState === 16 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(16)} >Winter's Formula</button>
                         
                    </div>
                    <div className="col-md-8 col-sm-12">
                        <div className="content-tabs">
                            <div
                                className={toggleState === 1 ? "calculator-content  active-content" : "calculator-content"}>
                             <Abg/>
                            </div>
                            <div className={toggleState === 2 ? "calculator-content  active-content" : "calculator-content"}>
                            <AnionGap/>
                            </div>

                            <div
                                className={toggleState === 3 ? "calculator-content  active-content" : "calculator-content"} >
                               <BicarbonateDeficit/>
                            </div>

                            <div
                                className={toggleState === 4 ? "calculator-content  active-content" : "calculator-content"}>
                               <FluidBloodVolume/>
                            </div>
                            <div
                                className={toggleState === 5 ? "calculator-content  active-content" : "calculator-content"}>
                               <DialysisRiskClinic/>
                            </div>
                            <div className={toggleState === 6 ? "calculator-content  active-content" : "calculator-content"}>
                            <DialysisRiskMehta/>
                            </div>

                            <div
                                className={toggleState === 7 ? "calculator-content  active-content" : "calculator-content"} >
                               <FenaFluidCalculator/>
                            </div>

                            <div
                                className={toggleState === 8 ? "calculator-content  active-content" : "calculator-content"}>
                               <FeureaFluidCalculator/>
                            </div>
                            <div
                                className={toggleState === 9 ? "calculator-content  active-content" : "calculator-content"}>
                               <IfdaFluidCalculator/>
                            </div>
                            <div
                                className={toggleState === 10 ? "calculator-content  active-content" : "calculator-content"}>
                                <FluidMaximumAbl/>
                            </div>
                            <div
                                className={toggleState === 11 ? "calculator-content  active-content" : "calculator-content"}>
                               <PlasmaFluidCalculator/>
                            </div>
                            <div
                                className={toggleState === 12 ? "calculator-content  active-content" : "calculator-content"}>
                               <RIFLEFluidCalculator/>
                            </div>
                            <div
                                className={toggleState === 13 ? "calculator-content  active-content" : "calculator-content"}>
                                <FluidsCalculator/>
                            </div>
                            <div
                                className={toggleState === 14 ? "calculator-content  active-content" : "calculator-content"}>
                               <SirsSepsis/>
                            </div>
                            <div
                                className={toggleState === 15 ? "calculator-content  active-content" : "calculator-content"}>
                               <SIRSpediatric/>
                            </div>
                            <div
                                className={toggleState === 16 ? "calculator-content  active-content" : "calculator-content"}>
                               <WintersFormula/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default RenalCalculators;