import { React, useState, useEffect } from 'react';
import './CAS1020.css';

const CAS1020 = () => {
    const [green, setGreen] = useState(false);
    const [number1, setNumber1] = useState([]);
    const [number2, setNumber2] = useState([]);
    const [prognath, setPrognath] = useState(0);
    const [total1, settotal1] = useState(0);
    const [total2, settotal2] = useState(0);
    const [total3, settotal3] = useState(0);
    const [twohr, setTwohr] = useState(0);
    const [threehr, setThreehr] = useState([]);
    const [fourhr, setFourhr] = useState(0);
    const [tunit, setTunit] = useState("kg");
    const [newnumber1, setNewnumber1] = useState(0);
    


    const postData = async () => {

        if (tunit === 'kg') {
               
                setNewnumber1(Math.round((number1)*100)/100);            
        }
        if (tunit === 'lbs') {                        
               setNewnumber1(Math.round((number1 / 2.2046226218488)*100)/100);

        }
      




        if ((number1 ) && (number2 < 73)) {

            if (number1 !== null && number2 !== null && prognath !== '') {
                if (prognath == 1) {
                    settotal1(Math.round(newnumber1 + 40));
                    settotal2(Math.round((newnumber1 + 40) * number2));
                    settotal3(Math.round((1 / 2 * ((newnumber1 + 40) * number2) + (newnumber1 + 40))));
                    setTwohr(Math.round((((newnumber1 + 40) * number2) * (1 / 4)) + (newnumber1 + 40) + (newnumber1 * 3)));
                    setThreehr(Math.round((((newnumber1 + 40) * number2) * (1 / 4)) + (newnumber1 + 40) + (newnumber1 * 3)));
                    setFourhr(Math.round((newnumber1 + 40) + (newnumber1 * 3)));
                   
                }
            }

            if (prognath == 2) {
                settotal1(Math.round(newnumber1 + 40))
                settotal2(Math.round((newnumber1 + 40) * number2));
                settotal3(Math.round(1 / 2 * ((newnumber1 + 40) * number2) + (newnumber1 + 40)));
                setTwohr(Math.round((((newnumber1 + 40) * number2) * (1 / 4)) + (newnumber1 + 40) + (newnumber1 * 5)));
                setThreehr(Math.round((((newnumber1 + 40) * number2) * (1 / 4)) + (newnumber1 + 40) + (newnumber1 * 5)));
                setFourhr(Math.round((newnumber1 + 40) + (newnumber1 * 5)));
             
            }
            if (prognath == 3) {
                settotal1(Math.round(newnumber1 + 40));
                settotal2(Math.round((newnumber1 + 40) * number2))
                settotal3(Math.round(1 / 2 * ((newnumber1 + 40) * number2) + (newnumber1 + 40)));
                setTwohr(Math.round((((newnumber1 + 40) * number2) * (1 / 4)) + (newnumber1 + 40) + (newnumber1 * 7)));
                setThreehr(Math.round((((newnumber1 + 40) * number2) * (1 / 4)) + (newnumber1 + 40) + (newnumber1 * 7)));
                setFourhr(Math.round((newnumber1 + 40) + (newnumber1 * 7)));
             
            }

        } else {
            settotal1(0);
            settotal2(0);
            settotal3(0);
            setTwohr(0);
            setThreehr(0);
            setFourhr(0);
        }
        setGreen(true);

    }

    // const resultvalidation = ()=>{
    //     if((tunit === 'kg')&&(number1<271)){
    //         setNewnumber1(Math.round((number1))); 
           
    //     }
    //     else if((tunit === 'ibs')&&(number1<600)){
    //         setNewnumber1(Math.round((number1 / 2.2046226218488)));
    //     }
    //     else{
    //         settotal1(0);
    //         settotal2(0);
    //         settotal3(0);
    //         setTwohr(0);
    //         setThreehr(0);
    //         setFourhr(0);     
    //     }
    // }
    const valnumber1 = () => {


        if (tunit === 'kg') {
        
                       if ((number1 < 273) && (number1 > 226)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (number1 > 272) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(number1)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
        if(tunit === 'lbs'){
            if ((number1 < 601) && (number1 > 500)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (number1 > 600) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(number1)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    const valnumber2 = () => {


        if (number2 != 0) {
            if ((number2 < 73) && (number2 > 24)) {
                return <div className="validate">*Very High;double check.</div>

            }

            if (number2 > 72) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(number2)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    useEffect(() => {
        postData();

    }, [tunit, number1, prognath, number2, twohr, threehr, fourhr, total1, total2, total3])

    const changeunit = () => {
        if (number1 === 0) {
            if (tunit === 'kg') {
                setTunit('lbs');

            }
            if (tunit === 'lbs') {
                setTunit('kg');

            }
        } else if (number1 !== 0) {
            if (tunit === 'kg') {
              
                setTunit('lbs');
                if(number1.length !== 0){
                    setNumber1((number1 * 2.2046226218488).toFixed(2));
                }
            }
            if (tunit === 'lbs') {
             
                setTunit('kg');
                if(number1.length !== 0){
                    setNumber1((number1 / 2.2046226218488).toFixed(2));
                }

            }
        }
    }

    const takeUnit = (e) => {
        if (tunit === 'kg') {
            let htcm = (e.target.value);
          
                 setNumber1(htcm);

             
        }
        if (tunit === 'lbs') {
            let htin = (e.target.value);
         
                setNumber1(htin);

        }

    }





    return (<div className="rev">
        <h5 className="text-info pl-2">Intraoperative Fluid Dosing in Adult Patients</h5>
        <p className="pl-2">Doses IV fluids intraoperatively.</p>

        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Weight</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input
 type="number"
                    onChange={takeUnit}

                    className="ant-text-input" style={{ width: "77%" }} value={number1} /><span className="units" onClick={changeunit}>{tunit}&#8651;</span>
                <div>{valnumber1()}</div>

            </div>
        </div>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Time spent NPO</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input type="number"
                    onChange={e => setNumber2((e.target.value))}
                    className="ant-text-input" style={{ width: "75%" }} value={number2} /><span className="units">hours</span>
                <div>{valnumber2()}</div>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Estimated severity of trauma to tissue</strong>
                    <div>minimal:e.g. hernia repair,laparoscopy. Moderrate:e.g open cholecystectomy,open appendectomy. Sever:e.g.bowel resection.
                    </div>
                </div>
            </div>

            <div className="col-md-7 frm-field btn-group-vertical options-btn" style={{height:150}}>
                <button className={prognath == 1 ? "btn active  text-white" : "btn "} onClick={() => setPrognath(1)}> Minimal </button>
                <button className={prognath == 2 ? "btn active  text-white" : "btn "} onClick={() => setPrognath(2)}>Moderate </button>
                <button className={prognath === 3 ? "btn active  text-white" : "btn "} onClick={() => setPrognath(3)}>Severe</button>
            </div>
        </div>
        <br />
        <div className="stick">
            {
                (total1 && total2 && total3) ?
                    <div className="apfel-score-result-container" >
                        <div className="point-result-container" >
                            <h1>{total1} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>ml/hr</span></h1> <br />
                            Hourly maintenance fluid
                        </div>
                        <div className="point-result-container" >
                            <h1>{total2} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>mL</span></h1><br />
                            NPO fluid deficit
                        </div>
                        <div className="point-result-container" >
                            <h1>{total3} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>mL/hr</span></h1>
                            <p>1st hour fluid</p>
                            <div>2nd hour: <span>{twohr}</span> ml/hr</div>
                            <div>3rd hour: <span>{threehr}</span> ml/hr</div>
                            <div>4th hour and beyond: <span>{fourhr}</span> ml/hr</div>
                        </div>
                    </div>
                    :
                    <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>
            }
        </div>
        <div className="p-2 mb-5" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/22253274/">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Corcoran T, Rhodes JE, Clarke S, Myles PS, Ho KM. Perioperative fluid management strategies in major surgery: a stratified meta-analysis. Anesth Analg. 2012;114(3):640-51.</p>
                </div>
            </a><br />
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/19837807/">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Giglio MT, Marucci M, Testini M, Brienza N. Goal-directed haemodynamic therapy and gastrointestinal complications in major surgery: a meta-analysis of randomized controlled trials. Br J Anaesth. 2009;103(5):637-46.</p>
                </div>
            </a><br />
            <a className="referenceLink" target="_blank" href="https://bjssjournals.onlinelibrary.wiley.com/doi/full/10.1002/bjs.5454">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Noblett SE, Snowden CP, Shenton BK, Horgan AF. Randomized clinical trial assessing the effect of Doppler-optimized fluid management on outcome after elective colorectal resection. Br J Surg. 2006;93(9):1069-76.</p>
                </div>
            </a><br />
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/15983453/">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Nisanevich V, Felsenstein I, Almogy G, Weissman C, Einav S, Matot I. Effect of intraoperative fluid management on outcome after intraabdominal surgery. Anesthesiology. 2005;103(1):25-32.</p>
                </div>
            </a><br />
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/22470070/">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Maitland K, Babiker A, Kiguli S, Molyneux E. The FEAST trial of fluid bolus in African children with severe infection. Lancet. 2012;379(9816):613.</p>
                </div>
            </a>

        </div>
    </div>
    )
}
export default CAS1020;