import React, { useEffect, useState } from 'react';
import './CAS1035.css';

const CAS1035 = () => {
    const [green, setGreen] = useState(false);
   
    const [result, setResult] = useState(0);


    const [question1, setQuestion1] = useState(0);
    const [question2, setQuestion2] = useState(0);
    const [question3, setQuestion3] = useState(0);
    const [question4, setQuestion4] = useState(0);
    const [question5, setQuestion5] = useState(0);
    const [question6, setQuestion6] = useState(0);
    const [question7, setQuestion7] = useState(0);
    const [question8, setQuestion8] = useState(0);
    const [question9, setQuestion9] = useState(0);
    const postData = async () => {
        setResult(question1+question2+question3+question4+question5+question6+question7+question8+question9);
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [question1,question2,question3,question4,question5,question6,question7,question8,question9,result]);

    return (
        <div className="rev">
           <h5 className="text-info pl-2">HAS-BLED Score</h5>
            <p className="pl-2">Stratifies bleeding risk in patients with atrial fibrillation before the anticoagulation therapy is initiated.</p>
  
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>1. Hypertension history - BP systolic above 160 mmHg, no treatment</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left" >
                    <button style={{ height: 40 }} className={question1 === 0 ? "btn active" : "btn"} onClick={() => setQuestion1(0)}>No (0)  </button>
                    <button style={{ height: 40 }} className={question1 === 1 ? "btn active" : "btn"} onClick={() => setQuestion1(1)}>Yes (+1) </button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>2. Renal disease - creatinine higher than 2.6 mg/dL (200 mmol/L)</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={question2 === 0 ? "btn active" : "btn"} onClick={() => setQuestion2(0)}>No (0)</button>
                    <button style={{ height: 40 }} className={question2 === 1 ? "btn active" : "btn"} onClick={() => setQuestion2(1)}>Yes (+1)</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>3. Liver disease - bilirubin higher 2x or AST/ALT/AP higher 3x normal</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={question3 === 0 ? "btn active" : "btn"} onClick={() => setQuestion3(0)}>No (0)</button>
                    <button style={{ height: 40 }} className={question3 === 1 ? "btn active" : "btn"} onClick={() => setQuestion3(1)}>Yes (+1)</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>4. Stroke history</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={question4 === 0 ? "btn active" : "btn"} onClick={() => setQuestion4(0)}>No (0)</button>
                    <button style={{ height: 40 }} className={question4 === 1 ? "btn active" : "btn"} onClick={() => setQuestion4(1)}>Yes (+1)</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>5. History of major bleeding or predisposition</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={question5 === 0 ? "btn active" : "btn"} onClick={() => setQuestion5(0)}>No (0)</button>
                    <button style={{ height: 40 }} className={question5 === 1 ? "btn active" : "btn"} onClick={() => setQuestion5(1)}>Yes (+1)</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>6. Labile INR – high values</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={question6 === 0 ? "btn active" : "btn"} onClick={() => setQuestion6(0)}>No (0)</button>
                    <button style={{ height: 40 }} className={question6 === 1 ? "btn active" : "btn"} onClick={() => setQuestion6(1)}>Yes (+1)</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>7. Age higher than 65</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={question7 === 0 ? "btn active" : "btn"} onClick={() => setQuestion7(0)}>No (0)</button>
                    <button style={{ height: 40 }} className={question7 === 1 ? "btn active" : "btn"} onClick={() => setQuestion7(1)}>Yes (+1)</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>8. Under medication that predisposes to bleeding</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={question8 === 0 ? "btn active" : "btn"} onClick={() => setQuestion8(0)}>No (0)</button>
                    <button style={{ height: 40 }} className={question8 === 1 ? "btn active" : "btn"} onClick={() => setQuestion8(1)}>Yes (+1)</button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>9. Alcohol or drug usage history – more than 7 drinks per week</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} className={question9 === 0 ? "btn active" : "btn"} onClick={() => setQuestion9(0)}>No (0)</button>
                    <button style={{ height: 40 }} className={question9 === 1 ? "btn active" : "btn"} onClick={() => setQuestion9(1)}>Yes (+1)</button>

                </div>
            </div>
          


            <div className="stick mt-5">
                {
                    result !=0 ? <>
                        <div className="apfel-score-result-container col-12" >
                            <div className="point-result-container col-12" >
                                <h5>HAS-BLED Score = {result}</h5>
                                    <div>{result == 0  ? "Interpretation: This score indicates a 1.10% risk of major bleeding within 1 year in patients with atrial fibrillation " : " "}</div>
                                    <div>{result == 1 ? "Interpretation: This score indicates a 3.40% risk of major bleeding within 1 year in patients with atrial fibrillation" : " "}</div>
                                    <div>{result == 2 ? "Interpretation: This score indicates a 4.10% risk of major bleeding within 1 year in patients with atrial fibrillation" : " "}</div>
                                    <div>{result == 3  ? "Interpretation: This score indicates a 1.10% risk of major bleeding within 1 year in patients with atrial fibrillation" : " "}</div>
                                    <div>{result == 4 ? "Interpretation: This score indicates a 8.90% risk of major bleeding within 1 year in patients with atrial fibrillation" : " "}</div>
                                    <div>{result == 5 ? "Interpretation: This score indicates a 9.10% risk of major bleeding within 1 year in patients with atrial fibrillation" : " "}</div>
                                    <div>{result >= 6 ? "Interpretation: This is a very rarely met score, so rarely met that is not often considered in studies and its major bleeding risk is not accounted for in research. However, it is considered to be above the HAS BLED score 5 risk percentage i.e. 9.10%." : " "}</div>
                                    <div>The score should be taken in consideration when initiating oral anticoagulation therapy or aspirin intake.</div>
                                  


                           
                            </div>






                        </div>



                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>
                }
            </div>  <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/20299623/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Pisters R, Lane DA, Nieuwlaat R, de Vos CB, Crijns HJ, Lip GY. A novel user-friendly score (HAS-BLED) to assess 1-year risk of major bleeding in patients with atrial fibrillation: the Euro Heart Survey. Chest. 2010; 138(5):1093-100.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}


export default CAS1035;

