import React from 'react'
import FAT1160 from './FAT1160/FAT1160';
import FAT1161 from './FAT1161/FAT1161';
import FAT1162 from './FAT1162/FAT1162';
import FAT1163 from './FAT1163/FAT1163';
import FAT1164 from './FAT1164/FAT1164';
import FAT1165 from './FAT1165/FAT1165';
import FAT1166 from './FAT1166/FAT1166';
import FAT1167 from './FAT1167/FAT1167';
import FAT1168 from './FAT1168/FAT1168';
import FAT1169 from './FAT1169/FAT1169';
import FAT1170 from './FAT1170/FAT1170';
import FAT1171 from './FAT1171/FAT1171';
import FAT1172 from './FAT1172/FAT1172';

const FluidsAndTransfusionsIndex = ({ id }) => {

    const data = {
        FAT1160: <FAT1160 />,
        FAT1161: <FAT1161 />,
        FAT1162: <FAT1162 />,
        FAT1163: <FAT1163 />,
        FAT1164: <FAT1164 />,
        FAT1165: <FAT1165 />,
        FAT1166: <FAT1166 />,
        FAT1167: <FAT1167 />,
        FAT1168: <FAT1168 />,
        FAT1169: <FAT1169 />,
        FAT1170: <FAT1170 />,
        FAT1171: <FAT1171 />,
        FAT1172: <FAT1172 />
    }

    return (
        <React.Fragment>
            {data[id]}
        </React.Fragment>
    )
}

export {
    FluidsAndTransfusionsIndex
}