import React, { useEffect, useState } from 'react';
import './FAT1163.css'

const FAT1163 = () => {
    const [green, setGreen] = useState(false);
    const [number1, setNumber1] = useState([]);
    const [number2, setNumber2] = useState([]);
    const [number3, setNumber3] = useState([]);
    const [number4, setNumber4] = useState([]);
    const [total1, settotal1] = useState(0);
    const [tunit, setTunit] = useState('mmol/L');
    const [placehldr, setPlacehldr] = useState('Norm: 2.9-7.1')
    const [tunit1, setTunit1] = useState('mmol/L');
    const [placehldr1, setPlacehldr1] = useState('Norm: 125-250')
    const [tunit2, setTunit2] = useState('μmol/L');
    const [placehldr2, setPlacehldr2] = useState('Norm: 62 - 115')
    const [tunit4, setTunit4] = useState('μmol/L');
    const [placehldr4, setPlacehldr4] = useState('Norm: 1768 - 32780')
    const [newNumber, setnewNumber] = useState(0);
    const [newNumber1, setnewNumber1] = useState(0);
    const [newNumber2, setnewNumber2] = useState(0);
    const [newNumber3, setnewNumber3] = useState(0)
    const postData = async () => {
        if (tunit === 'mmol/L') {
            if (number1 < 179) {
                setnewNumber(number1)
            } else {
                setnewNumber(0)
            }

        }
        if (tunit === 'mg/dL') {
            if ((number1 < 501) && (number1 > 1)) {
                setnewNumber((number1 / 2.80112044).toFixed(6))
            }
            else {
                setnewNumber(0)
            }

        }
        if (tunit1 === 'mmol/L') {
            if (number3 < 642) {
                setnewNumber1(number3)
            } else {
                setnewNumber1(0)
            }

        }
        if (tunit1 === 'mg/dL') {
            if (number3 < 200) {
                setnewNumber1((number3 / 2.80112042).toFixed(6))
            }
            else {
                setnewNumber1(0)
            }

        }
        if (tunit2 === 'μmol/L') {
            if (number2 < 3536) {
                setnewNumber2(number2)
            }
            else {
                setnewNumber2(0)
            }

        }
        if (tunit2 === 'mg/dL') {
            if (number2 < 41) {
                setnewNumber2((number2 * 88.4).toFixed(5))
            }
            else {
                setnewNumber2(0)
            }

        }
        if (tunit4 === 'μmol/L') {
            if (number4 < 61880) {
                setnewNumber3(number4)
            }

        }
        if (tunit4 === 'mg/dL') {
            if (number4 < 700) {
                setnewNumber3((number4 * 88.4).toFixed(5))
            }
            else {
                setnewNumber3(0)
            }

        }
        if ((number1 < 500) && (number2 < 3537) && (number3 < 2000) && (number4 < 61880)) {
            settotal1(((newNumber2 * newNumber1) / (newNumber * newNumber3) * 100).toFixed(1));
            setGreen(true);
        } else {
            settotal1(0);
        }
    }
    useEffect(() => {
        postData()
        result();

    }, [number1, number2, number3, number4, newNumber, newNumber1, newNumber2, newNumber3])

    const valnumber1 = () => {

        if (tunit === 'mmol/L') {


            if (number1 != 0) {
                if ((number1 == 0)) {
                    return <div className="validate">Too small; please change to proceed.</div>

                }
                if ((number1 < 179) && (number1 > 107)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number1 > 178) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number1)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
        if (tunit === 'mg/dL') {
            if (number1 !== 0) {
                if ((number1 == 1)) {
                    return <div className="validate">*Too low; please change to proceed.</div>

                }
                if ((number1 < 501) && (number1 > 300)) {
                    return <div className="validate">*Very High; double check.</div>

                }
                if (number1 > 500) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number1)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
    }
    const valnumber2 = () => {

        if (tunit2 === 'μmol/L') {
            if (number2 != 0) {
                if ((number2 < 3537) && (number2 > 884)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number2 > 3536) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number2)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
        if (tunit2 === 'mg/dL') {
            if (number2 !== 0) {
                // if ((number2 == 0)) {
                //     return <div className="validate">*Too low; please change to proceed.</div>

                // }
                if ((number2 < 41) && (number2 > 10)) {
                    return <div className="validate">*Very High; double check.</div>

                }
                if (number2 > 40) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number2)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }

    }

    const valnumber3 = () => {

        if (tunit1 === 'mmol/L') {
            if (number3 !== 0) {
                // if ((number3 <= 0)) {
                //     return <div className="validate">very dlow; check double.</div>

                // }
                if ((number3 < 643) && (number3 > 249)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number3 > 642) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number3)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
        if (tunit1 === 'mg/dL') {
            if (number3 != 0) {
                // if ((number3 < 10) && (number3 != 0) &&(number3 >0)) {
                //     return <div className="validate">*Too small please check double.</div>

                // }
                if ((number3 < 1799) && (number3 > 699)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number3 > 2000) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number3)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
    }
    const valnumber4 = () => {

        if (tunit4 === 'μmol/L') {
            if (number4 != 0) {
                if ((number4 < 89) && (number4 != 0) && (number4 > 0)) {
                    return <div className="validate">*Too low please double check.</div>

                }
                if ((number4 < 61880) && (number4 > 39780)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number4 > 61880) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number4)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }

        if (tunit4 === 'mg/dL') {
            if (number4 != 0) {
                if ((number4 <= 0)) {
                    return <div className="validate">*Too low please double check.</div>

                }
                if ((number4 < 701) && (number4 > 450)) {
                    return <div className="validate">*Very High;double check.</div>

                }
                if (number4 > 700) {
                    return <div className="validate">*Too high; please change to proceed.</div>

                }
                else if (isNaN(number4)) {
                    return <div className="validate">*Please Enter valid-no.</div>

                }
            }
        }
    }

    const changeunit = () => {
        if (number1 === 0) {
            if (tunit === 'mmol/L') {
                setTunit('mg/dL');

            }
            if (tunit === 'mg/dL') {
                setTunit('mmol/L');

            }
        } else if (number1 !== 0) {
            if (tunit === 'mmol/L') {
                setPlacehldr("Norm: 8-20");

                setTunit('mg/dL');
                if (number1.length !== 0) {
                    setNumber1((number1 * 2.80112044).toFixed(2))
                }
            }
            if (tunit === 'mg/dL') {
                setPlacehldr("Norm: 2.9-7.1");
                setTunit('mmol/L');
                if (number1.length !== 0) {
                    setNumber1((number1 / 2.80112044).toFixed(2))
                }

            }
        }
    }
    const takeUnit = (e) => {
        if (tunit === 'mmol/L') {
            setNumber1(e.target.value);
            setPlacehldr("Norm: 2.9-7.1");

        }
        if (tunit === 'mg/dL') {
            let ht = e.target.value;
            setNumber1(ht);
            setPlacehldr("Norm: 8-20");
        }
    }

    const changeunit1 = () => {
        if (number3 === 0) {
            if (tunit1 === 'mmol/L') {
                setTunit1('mg/dL');

            }
            if (tunit1 === 'mg/dL') {
                setTunit1('mmol/L');

            }
        } else if (number3 !== 0) {
            if (tunit1 === 'mmol/L') {
                setPlacehldr1("Norm: 350-700");
                setTunit1('mg/dL');
                if (number3.length !== 0) {
                    setNumber3((number3 * 2.80112044).toFixed(2))
                }
            }
            if (tunit1 === 'mg/dL') {
                setPlacehldr1("Norm: 125-250");
                setTunit1('mmol/L');
                if (number3.length !== 0) {
                    setNumber3((number3 / 2.80112044).toFixed(2))
                }

            }
        }
    }
    const takeUnit1 = (e) => {
        if (tunit1 === 'mmol/L') {
            setNumber3(e.target.value);
            setPlacehldr1("Norm: 125-250");

        }
        if (tunit1 === 'mg/dL') {
            let ht = e.target.value;
            setNumber3(ht);
            setPlacehldr1("Norm: 350-700");
        }
    }
    const changeunit2 = () => {
        if (number2 === 0) {
            if (tunit2 === 'μmol/L') {
                setTunit2('mg/dL');

            }
            if (tunit2 === 'mg/dL') {
                setTunit2('μmol/L');

            }
        } else if (number2 !== 0) {
            if (tunit2 === 'μmol/L') {

                setPlacehldr2("Norm: 0.7-1.3");
                setTunit2('mg/dL');

                if (number2.length !== 0) {
                    setNumber2((number2 / 88.4).toFixed(2))
                }


            }
            if (tunit2 === 'mg/dL') {

                setPlacehldr2("Norm: 62-155");
                setTunit2('μmol/L');
                if (number2.length !== 0) {
                    setNumber2((number2 * 88.4).toFixed(2))
                }
            }
        }
    }
    const takeUnit2 = (e) => {
        if (tunit2 === 'μmol/L') {
            setNumber2(e.target.value);
            setPlacehldr2("Norm: 62-155");

        }
        if (tunit2 === 'mg/dL') {
            let ht = e.target.value;
            setNumber2(ht);
            setPlacehldr2("Norm: 0.7-1.3");
        }
    }
    const changeunit4 = () => {
        if (number4 === 0) {
            if (tunit4 === 'μmol/L') {
                setTunit4('mg/dL');

            }
            if (tunit4 === 'mg/dL') {
                setTunit4('μmol/L');

            }
        } else if (number4 !== 0) {
            if (tunit4 === 'μmol/L') {

                setPlacehldr4("Norm: 20-370");
                setTunit4('mg/dL');
                if (number4.length !== 0) {
                    setNumber4((number4 / 88.40).toFixed(2))
                }
            }
            if (tunit4 === 'mg/dL') {

                setPlacehldr4("Norm: 1768-32780");
                setTunit4('μmol/L');
                if (number4.length !== 0) {
                    setNumber4((number4 * 88.40).toFixed(2))
                }
            }
        }
    }
    const takeUnit4 = (e) => {
        if (tunit4 === 'μmol/L') {
            setNumber4(e.target.value);
            setPlacehldr4("Norm: 1768-32780");

        }
        if (tunit4 === 'mg/dL') {
            let ht = e.target.value;
            setNumber4(ht);
            setPlacehldr4("Norm: 20-370");
        }
    }


    const result = () => {
        if (total1 && isFinite(total1)) {
            if (total1 > 35) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <span><h1 className="breath">{total1} %</h1></span>
                        <div>  FEUrea &gt;35% suggests intrinsic renal disease</div>

                    </div>
                </div>
            }
            else if ((total1 < 35)) {

                return <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <span><h1 className="breath">{total1} %</h1></span>
                        <div> FEUrea &lt;35% suggests pre-renal disease</div>

                    </div>
                </div>
            }
        } else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }

    return (<div className="rev">
        <h5 className="text-info pl-2">Fractional Excretion of Urea (FEUrea)</h5>
        <p className="pl-2">Determines the cause of renal failure. Similar to the FENa, but can be used on patients on diuretics.</p>

        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>BUN or serum urea</strong></div>
                <div>Enter BUN in mg/dL or serum urea in mmol/L</div>
            </div>
            <div className="col-md-7 frm-field">
                <input type="number"

                    onChange={takeUnit}
                    placeholder={placehldr}

                    className="ant-text-input fluidInputfu" value={number1} /><span className="units unitBtnfu" onClick={changeunit}>{tunit}&#8651;</span>
                <div>{valnumber1()}</div>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Serum creatinine</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input type="number"
                    onChange={takeUnit2}
                    placeholder={placehldr2}



                    className="ant-text-input" style={{ width: "65%" }} value={number2} /><span className="units" onClick={changeunit2}>{tunit2}&#8651;</span>
                <div>{valnumber2()}</div>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Urine urea</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input type="number"
                    onChange={takeUnit1}
                    placeholder={placehldr1}

                    className="ant-text-input" style={{ width: "65%" }} value={number3} /><span className="units" onClick={changeunit1}>{tunit1}&#8651;</span>
                <div>{valnumber3()}</div>
            </div>
        </div>
        <div className="fields row">
            <div className="col-md-5">
                <div className="pt-2"><strong>Urine creatinine</strong></div>
            </div>
            <div className="col-md-7 frm-field">
                <input
                    onChange={takeUnit4}
                    placeholder={placehldr4}
                    type="number"

                    className="ant-text-input" style={{ width: "65%" }} value={number4} /><span className="units" onClick={changeunit4}>{tunit4}&#8651;</span>
                <div>{valnumber4()}</div>
            </div>
        </div>

        {/* <div className="pb-2">
     <button onClick={postData} className="save-button-container" style={{ margin: '0 auto' }}>Submit</button>
 </div> */}
        <br />
        <div className="stick-result">
            {
                result()
            }
        </div>
        <br />
        <div className="p-2" >
            <h6>
                ORIGINAL/PRIMARY REFERENCE
            </h6>
            <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/1415365/">
                <div className="reference-container-card" >
                    <div className="reference-img" >
                        <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                    </div>
                    <p className="reference-paragraph" >Kaplan AA, Kohn OF. Fractional excretion of urea as a guide to renal dysfunction. Am J Nephrol. 1992;12(1-2):49-54.</p>
                </div>
            </a>
        </div>
    </div>
    )
}

export default FAT1163