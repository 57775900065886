import React from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import "../css/newLamaxHeader.css"

const LamaxSubHeader = (props) => {
    return (
        <div>
            {
                props.name === "Return To Favourite" ?
                   <div  className="backNavigation  text-center"><Link className="arrow" to="/favourite"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">{props.name}</span></span></Link><span>{props.text}</span></div>
                    :
                       props.name === "Return To Step-1" ?
                       <div  className="backNavigation  text-center"><Link className="arrow" to="/newAfterlamax"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Step-1</span></span></Link><span>LOCAL ANESTHETIC MAX</span></div>
                       :
                       props.name === "Return To Step-2" ?
                       <div  className="backNavigation  text-center"><Link className="arrow" to="/newAfterlamax2"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Step-2</span></span></Link><span>LOCAL ANESTHETIC MAX</span></div>
                    :   <div  className="backNavigation  text-center"><Link className="arrow" to="/actionLibrary"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Action Library</span></span></Link><span>LOCAL ANESTHETIC MAX</span></div>


           
           }
           
            {/* <nav className="newLamaxHeader"><Link className="arrow" to="/anesthesiatype"><h3><i className="fas fa-arrow-left"></i></h3></Link><span>Return to start a case/Intraop</span></nav> */}
        </div>
    )
}

export default LamaxSubHeader
