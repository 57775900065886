import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';
import { Link } from 'react-router-dom';
import SirsSepsis from './SirsSepsis';
import SIRSpediatric from './SIRSpediatric';
const InfectiousDiseaseCalculators = (props) => {
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };
    useEffect(() => {
        toggleTab(1);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
    }, [])

    return (
        <div>
            <div className="backNavigation text-center">
                <Link className="arrow" to="/actionLibrary"><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to Action Library</span></span></Link> INFECTIOUS DISEASE CALCULATORS
            </div>
            <div className="main-container calculator-container mt-3">
                <div className="row">
                    <div className="col-md-4 airway-calc-tabs">
                    <button className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(1)} >SIRS, Sepsis and Septic Shock</button>
                    <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(2)} >SIRS - Pediatric</button>        
                    </div>
                    <div className="col-md-8 col-sm-12">
                        <div className="content-tabs">
                            <div
                                className={toggleState === 1 ? "calculator-content  active-content" : "calculator-content"}>
                              <SirsSepsis/>
                            </div>
                            <div
                                className={toggleState === 2 ? "calculator-content  active-content" : "calculator-content"}>
                              <SIRSpediatric/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default InfectiousDiseaseCalculators;
