import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { API_ROOT } from '../constants'
import '../css/preoperativeevalution.css'
import Spinner from '../CustomComponent/Spinner'
import AppContext from '../contextStore/DataProvider'

const PreoperativeEvaluation = (props) => {
    const [medicalHistoryArray, setmedicalHistoryArray] = useState([])
    const [medicalHistory, setmedicalHistory] = useState([])
    const [medicationsArray, setmedicationsArray] = useState([])
    const [medication, setmedication] = useState([]);
    const [notListed, setnotListed] = useState([])
    const [diagnosisArray, setDiagnosis] = useState([])
    const [loading, setLoading] = useState(false)
    const [resShow, setResShow] = useState(false)
    const [res1, setRes1] = useState([])
    const [scrollpos, setScrollPos] = useState({ bottom: 0 });
    const [medicalHistoryOtherNotListed, setmedicalHistoryOtherNotListed] = useState('')
    const [medicationOtherNotListed, setmedicationOtherNotListed] = useState('')
    const context = useContext(AppContext)

    useEffect(() => {
        getCaseSummary()
    }, [])

    const getCaseSummary = async () => {
        var token = sessionStorage.getItem("token")

        fetch(API_ROOT + `case-summary-form-data?device_type=Android&device_token=123`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }

        })
            .then(response => response.json())
            .then(res => {
                if (res.status === "true" && res.message === "Case Summary Form Data") {

                    res.data.drugList.forEach(element => {
                        element.value = element.id;
                        element.label = element.drug_name
                    });

                    setmedicationsArray(res.data.medications)


                    setmedicalHistory(res.data.medicalHistories)
                    setnotListed(res?.data?.med_not_listed)
                    setLoading(true)
                }
            })


    }



    var obj = {
        name: ""
    }
    const onSiteChanged = (e, name) => {
        if (e.target.checked === true) {

            //  medicalHistoryArray.push(e.target.name);
            obj = {
                name: name
            }
            diagnosisArray.push(obj)
        }

        if (e.target.checked === false) {
            diagnosisArray.forEach((ob, i) => {
                if (ob.name === e.target.name) {
                    diagnosisArray.splice(i, 1);

                }
            })


        }

    }

    const onMedicationValue = (e, name) => {

        if (e.target.checked === true) {

            //  medicalHistoryArray.push(e.target.name);
            obj = {
                name: name
            }
            medication.push(obj)
        }

        if (e.target.checked === false) {
            medication.forEach((ob, i) => {
                if (ob.name === e.target.name) {
                    medication.splice(i, 1);

                }
            })


        }

    }

    const handleClick = async () => {
        try {

            var token = sessionStorage.getItem("token")
            fetch(API_ROOT + `preop-testing?token=${token}`, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    diagnosis: [...diagnosisArray, ...medication],
                    not_listed: medicalHistoryOtherNotListed,
                    med_not_listed: medicationOtherNotListed

                })

            })
                .then(response => response.json())
                .then(res => {
                    if (res.status === "true" && res.message === "Preoperative Testing results") {
                        let myData =  Object.keys(res.data).map(key => res.data[key]);
                        let unlisted=[];
                        if(res.not_listed){
                            unlisted.push(res.not_listed)
                            myData.push(unlisted)
                        }
                        let unlisted_med = [];
                        if(res.med_not_listed){
                            unlisted_med.push(res.med_not_listed);
                            myData.push(unlisted_med)
                        }
                     

                      let allResults = [...myData]  
                        setRes1(allResults)
                    } else {
                        alert(res.message)
                    }
                })


        } catch (error) {
        }
        setResShow(true)
    }

    const navigateToAllAction = () => {
        // const cp_code ='RE1265'
        // context.changeActiveId(cp_code)
        localStorage.removeItem('activeId')
        context.activeListData = null;
        context.activeId = null;

        props.history.push({ pathname: "/actionLibrary", state: { value: "preoperativeevaluation" } })
    }


    return (
        <div>

            <div className="container mt-3 bg-light p-0" >
                <div className="alert alert-info p-2 mt-3"><i className="fa fa-info-circle">&nbsp;</i>Select the relevant history and click submit. Recommendations for preoperative tests will be displayed.</div>
                <div className="alert alert-warning p-2 mt-3"><i className="fa fa-warning">&nbsp;</i>Disclaimer - The recommendations for preoperative testing and labs are only intended as a general guide based on the patient's history entered below. Please use the <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => navigateToAllAction()}>"RISK EVALUATION"</span> for a more comprehensive evaluation."
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" style={{ position: 'absolute', right: 5, top: 5 }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                {loading ?

                    medicalHistory.map((data, i) => {
                        return (
                            <>

                                <div style={{ width: "100%" }} key={data.id}>
                                    <div className="preoperative-medical-history-question-header pl-4" >
                                        <div style={{ fontWeight: "bold" }}>{i + 1}. {data.name}</div>

                                    </div>
                                    {data.name != 'OTHER/NOT LISTED' ?
                                        <div className="preoperative-medical-history-sub-question-sub-container" >
                                            {
                                                data.medical_history_sub_type.map((data) => {
                                                    return (
                                                        <div className="preoperative-sub-question-container" key={data.id}>
                                                            <div>{data.name}</div>

                                                            <div className="preoperative-option-box-container" >
                                                                {/* <input onChange={(e) => onSiteChanged(e, data.name)} type="radio" value="Yes" name={data.name} /> Yes
                                                            <input onChange={(e) => onSiteChanged(e)} type="radio" value="No" name={data.name} /> No */}
                                                                <div className="toggle-button-cover">
                                                                    <div className="button-cover">
                                                                        <div className="button b2" id="button-10">
                                                                            <input type="checkbox" className="checkbox" defaultChecked={false} onChange={(e) => onSiteChanged(e, data.name)} name={data.name} />

                                                                            <div className="knobs">
                                                                                <span>NO</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })
                                            }



                                        </div> : <div className="m1-1"><input type="text" onChange={(e) => setmedicalHistoryOtherNotListed(e.target.value)} value={medicalHistoryOtherNotListed} style={{ fontSize: 14 }} className="ant-text-input" /></div>
                                    }

                                </div>
                                <div className="all-action-subcontainer-content-1"></div>
                            </>

                        )
                    })
                    :
                    <Spinner />
                }

                {
                    medicationsArray.length === 0 ? <></> :
                        <div className='mb-5 pb-5 pro-pad'>
                            <div className="alert alert-warning p-2 text-center mt-3 " style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', textAlign: 'center' }}>Medication</div>
                            {
                                medicationsArray.map((data) => {
                                    return (
                                        <>
                                            <div key={data.id} className="preoperative-medication-sub-question-container"  >
                                                <div>{data.name}</div>
                                                {data.name != 'OTHER/NOT LISTED' ?
                                                    <div className="preoperative-option-box-container">
                                                        {/* <input onChange={(e) => onMedicationValue(e, data)} type="radio" value="Yes" name={data.name} /> Yes
                                        <input onChange={(e) => onMedicationValue(e, data)} type="radio" value="No" name={data.name} /> No */}
                                                        <div className="toggle-button-cover">
                                                            <div className="button-cover">
                                                                <div className="button b2" id="button-10">
                                                                    <input type="checkbox" className="checkbox" onChange={(e) => onMedicationValue(e, data.name)} name={data.name} defaultChecked={data.name === "No medication" ? true : medication.includes(data.name) ? true : false}
                                                                    />
                                                                    <div className="knobs">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : <div className="m1-1"><input value={medicationOtherNotListed} onChange={(e) => setmedicationOtherNotListed(e.target.value)} type="text" style={{ fontSize: 14 }} className="ant-text-input" /></div>}
                                            </div>
                                        </>
                                    )
                                })
                            }


                        </div>

                }

                <div className="stick mb-5 preop-stk" style={{ background: '#FFF' }} >
                    {loading ? <div className="bg-light text-center pb-2 pt-2">
                        <button onClick={() => handleClick()} className="save-button btn btn-secondary"><i className="fa fa-arrow-down">&nbsp;</i>Submit</button>
                        <hr />
                    </div> : <></>}
                    {
                        resShow ?
                            <>
                                <table className="table table-striped table-hover" style={scrollpos}>
                                    <thead className="bg-header text-left text-white stickheader-table">
                                        <tr>
                                            <th className="text-center">Past Medical History & Medications</th>
                                            <th className="text-center">Recommended Tests & Labs</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            res1.map((data) => {
                                                return (
                                                    <>
                                                        {
                                                            data.map((data1) => {
                                                                return (
                                                                    <>
                                                                        <tr key={data1.id}>
                                                                            <td className="text-center">{data1.diagnosis}</td>
                                                                            <td className="text-center">{data1.medication}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>

                                </table>

                            </>
                            :
                            <>
                            </>

                    }
                </div>

            </div>

        </div>
    )
}

export default PreoperativeEvaluation