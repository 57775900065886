import { SET_EXTRA_FAVOURITES,SET_ACTION_SUMMARY_CATEGORY,SET_ACTION_SUMMARY_TYPE, SET_DRUG_DATA, SET_LEVEL_1, SET_LEVEL_2 } from "./FavouriteTypes"

const initialState = {
    favourites: [],
    actionSummaryType:"",
    categorySelected:[],
    drugData: [],
    level1: [],
    level2: []
  
}

const FavouriteReducers = (state = initialState, action) => {
    switch (action.type) {
        case SET_EXTRA_FAVOURITES:
            return {
                ...state,
                favourites: action.payload
            }
        case SET_ACTION_SUMMARY_TYPE:
            return{
                ...state,
                actionSummaryType:action.payload
            }
        case SET_ACTION_SUMMARY_CATEGORY:
            return{
                ...state,
                categorySelected:action.payload
            }
        case SET_DRUG_DATA:
            return {
                ...state,
                drugData:action.payload
            }  
        case SET_LEVEL_1:
            return {
                ...state,
                level1:action.payload
            }  
        case SET_LEVEL_2:
            return {
                ...state,
                level2:action.payload
            }        
        default: return state
    }
}

export default FavouriteReducers