import React, { useState, useEffect, useContext } from 'react'
import './index.css'
import { Link, useHistory } from 'react-router-dom'
import Select from 'react-select'
import { API_ROOT } from '../../../constants'
import Spinner from '../../../CustomComponent/Spinner';
import AppContext from '../../../contextStore/DataProvider';

const optionsforage = [
    { value: 'Y', label: 'Year' },
    { value: 'M', label: 'Month' },
    { value: 'W', label: 'Week' },
    { value: 'D', label: 'Day' },
]

const optionsForWeight = [
    { value: 'KG', label: 'Kilograms' },
    { value: 'LB', label: 'Pounds' },

]
const ResultOfDrugs1 = (props) => {
    const [header, setHeader] = useState(null)
    const [age, setAge] = useState(18)
    const [weight, setWeight] = useState(70)
    const [height, setHeight] = useState(null)
    const [patientType, setPatientType] = useState(null)
    const [id, setId] = useState(null)
    const [drugId, setDrugId] = useState(null)
    const [parentName, setparentName] = useState(null)
    const [subParentName, setSubParentName] = useState(null)
    const [patientTypeArray, setpatientTypeArray] = useState([])
    const [ageType, setageType] = useState('Y')
    const [weightType, setweightType] = useState('KG')
    const [result, setResult] = useState([])
    const [resData, setResData] = useState([])
    const [resData2, setResData2] = useState([])
    const [drugSubType, setdrugSubType] = useState([])
    const [drugstomap, setDrugstoMap] = useState([])
    const [toggleState, setToggleState] = useState(false);
    const [title, setTitle] = useState(null);
    const [drId, setDrId] = useState(null);
    const [SurgeryresData, setSurgeryResData] = useState([]);
    const [selectedId, setselectedId] = useState(null);
    const [drugInfo, setDrugInfo] = useState([]);
    const [loading, setLoading] = useState(false)
    const [globalDrugs, setGlobalDrugs] = useState([]);
    const [drname, setDrName] = useState([]);
    const [value, setValue] = useState();
    const [keywords, setKeywords] = useState([])
    const context = useContext(AppContext)
    const handleChangeWeightType = selectedOption => {
        setweightType(selectedOption.value);
    };
    let history = useHistory();

    useEffect(() => {
        let path = window.location.pathname;
        let param_length = path.split(':').length;
        let params = window.location.pathname.split(':')[param_length - 1];
        params = params.replace(/%20/g, " ")
        let exp = params.replace(/~/g, "/")
        setValue(exp);
        let texts = ["Midazolam", "Fentanyl", "Ketamine", "Etomidate", "Propofol", "Lidocaine", "Dexamethasone", "Remifentanil", "Rocuronium", "Succinylcholine", "Glycopyrrolate", "Neostigmine", "Sugammadex", "Ondansetron", "Dexmedetomidine"]
        texts = [...new Set(texts)];
        setKeywords(texts);

        setToggleState(props.toggle)
        if (props.data?.parent_name === "Antibiotics - by surgery type") {
            setDrugInfo(props?.data?.children);

            runFunc(props?.data?.children[0]?.id);

        }
        getResultfromApi(props.data?.id, props.data?.name, props.data?.parent_id)
    }, [props.data?.name]);
    const wrapKeywordWithLink = (keyword, link) => {
        return `<a href=${link}>${keyword}</a>`
    }
    const transformContent = (content, keywords) => {
        let temp = content

        keywords.forEach(keyword2 => {
            let endp = keyword2.split(" ").join("")
            temp = temp.replace(keyword2.trim(), wrapKeywordWithLink(keyword2, "/druglink:" + `${endp.trim()}`));
        })
        return temp
    }

    const runFunc = (id) => {
        setselectedId(id)
        setResData([]);
        setLoading(true)
        var token = sessionStorage.getItem("token")
        fetch(API_ROOT + `drug-surgeries?token=${token}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "surgery_id": id
            })
        })
            .then(response => response.json())
            .then(res => {
                if (res.status === "true") {
                    setSurgeryResData(res.data);
                    setLoading(false)

                }
            })
    }

    const getResultfromApi = (drgid, drgname, subParent, drugInfo) => {
        setDrId(drgid ? drgid : props.data?.id);
        setTitle(drgname ? drgname : props.data?.name);
        setResData([]);
        setdrugSubType([]);
        let agee = 18;
        let weightVal = null;
        if (ageType === 'Y') {
            agee = parseInt(age) * 365;
        }
        if (ageType === 'M') {
            agee = parseInt(age) * 30;
        }
        if (ageType === 'W') {
            agee = parseInt(age) * 7;
        }
        if (ageType === 'D') {
            agee = parseInt(age);
        }

        var token = sessionStorage.getItem("token")
        // if (parentName === "Crises") {
        //     fetch(API_ROOT + `action-library-search?token=${token}`, {
        //         method: 'POST',

        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         body: JSON.stringify({

        //             "crises_id": id,
        //             "patient_age_days": 18,
        //             "patient_type_id": patientType,
        //             "patient_weight": weight


        //         })

        //     })
        //         .then(response => response.json())
        //         .then(res => {
        //             if (res.status === "true") {
        //             }
        //         })

        // } else if (parentName === "Case Tips") {
        //     fetch(API_ROOT + `action-library-search?token=${token}`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         body: JSON.stringify({
        //             "caseTips_id": id,
        //             "patient_age_days": 18,
        //             "patient_type_id": patientType,
        //             "patient_weight": weight
        //         })

        //     })
        //         .then(response => response.json())
        //         .then(res => {
        //             if (res.status === "true") {
        //             }
        //         })


        // }// else if (parentName === "Airway & Equipments") {
        //     fetch(API_ROOT + `action-library-search?token=${token}`, {
        //         method: 'POST',

        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         body: JSON.stringify({

        //             "airwayAndEquipments_id": props.id,
        //             "patient_age_days": 18,
        //             "patient_type_id": patientType,
        //             "patient_weight": weight


        //         })

        //     })
        //         .then(response => response.json())
        //         .then(res => {
        //             if (res.status === "true") {
        //             }
        //         })


        // }
        if (props.data?.parent_name === "Antibiotics - by class") {
            setLoading(true)

            fetch(API_ROOT + `drug-doses?token=${token}`, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    dose_id: props.data?.id,
                    age: agee,
                    weight: weight === null ? "70" : weight,

                })

            })

                .then(response => response.json())
                .then(res => {
                    if (res.status === "true") {
                        setResData(res.data)
                        setLoading(false)

                    }
                })
        }


        if (props.data?.parent_name == "ANALGESICS, NONOPIOIDS" ||
            props.data?.parent_name == "ANALGESICS, OPIOIDS" ||
            props.data?.parent_name == "ANTIARRHYTHMICS" ||
            props.data?.parent_name == "ANTIEMETICS" ||
            props.data?.parent_name == "ANTIPLATELETS & ANTICOAGULATION" ||
            props.data?.parent_name == "BRONCHODILATORS" ||
            props.data?.parent_name == "CARDIOVASCULAR AGONISTS" ||
            props.data?.parent_name == "CARDIOVASCULAR ANTAGONISTS" ||
            props.data?.parent_name == "DIURETICS" ||
            props.data?.parent_name == "ELECTROLYTES" ||
            props.data?.parent_name == "EMERGENCY DRUGS" ||
            props.data?.parent_name == "LOCAL ANESTHETICS" ||
            props.data?.parent_name == "MISCELLANEOUS" ||
            props.data?.parent_name == "NEUROMUSCULAR BLOCKADE" ||
            props.data?.parent_name == "OBSTETRIC" ||
            props.data?.parent_name === "SEDATIVES AND HYPNOTICS" ||
            props.data?.parent_name === "COMMON DRUGS"



        ) {
            setLoading(true)
            var token = sessionStorage.getItem("token")
            fetch(API_ROOT + `search-drug-brand-name?token=${token}`, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    "drug_brand_name": props.data?.name,
                    "age": agee,
                    "weight": weight,
                })
            })
                .then(response => response.json())
                .then((res) => {
                    console.log(res.data);
                   let searchResult = [...new Map(res.data.map(item => [item.indication, item])).values()]
                    setdrugSubType(searchResult);
                    setLoading(false)
                })
        }
        else if (

            props.data?.parent_name === "PENICILLINS" ||
            props.data?.parent_name === "MONOBACTAMS" ||
            props.data?.parent_name === "CEPHALOSPORINS" ||
            props.data?.parent_name === "FLUOROQUINOLONES" ||
            props.data?.parent_name === "OTHER" ||
            props.data?.parent_name === "TETRACYCLINES" ||
            props.data?.parent_name === "CARBAPENEMS" ||
            props.data?.parent_name === "ANTIFUNGALS" ||
            props.data?.parent_name === "AMINOGLYCOSIDES" ||
            props.data?.parent_name === "FLUOROQUINOLONES" ||
            props.data?.parent_name === "NITROIMIDAZOLES" ||
            props.data?.parent_name === "GLYCOPEPTIDES"


        ) {
            setLoading(true)
            var token = sessionStorage.getItem("token")
            fetch(API_ROOT + `search-drug-brand-name?token=${token}`, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    "drug_brand_name": props.data?.name,
                    "age": agee,
                    "weight": weight,
                })
            })
                .then(response => response.json())
                .then((res) => {
                    console.log(res.data)
                    setResData(res.data)
                    setLoading(false)
                })
        } else {
            setLoading(true)
            fetch(API_ROOT + `drug-combined-attributes?token=${token}`, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    "drug_id": props.data?.id,
                    "drug_name": props.data?.name,
                    "age": agee,
                    "weight": weight,
                })
            })
                .then(response => response.json())
                .then(res => {
                    if (res.status === "true") {
                        let responseData = res.data.sort((a, b) => (a.drug_brand_name > b.drug_brand_name) ? 1 : ((b.drug_brand_name > a.drug_brand_name) ? -1 : 0))
                        // console.log(responseData)
                        setdrugSubType(responseData);
                        setLoading(false)

                    }
                })

        }

    }
    const gotoParent = (data) => {
        if (data?.parent_name != null) {
            var token = sessionStorage.getItem("token");
            setDrId(data?.parent_id);
            setTitle(data?.parent_name);
            setToggleState(true)

            setSubParentName(data?.parent_name)
            setResData([]);
            setdrugSubType([]);
            let agee = 18;
            if (ageType === 'Y') {
                agee = parseInt(age) * 365;
            }
            if (ageType === 'M') {
                agee = parseInt(age) * 30;
            }
            if (ageType === 'W') {
                agee = parseInt(age) * 7;
            }
            if (ageType === 'D') {
                agee = parseInt(age);
            }
            if (data?.parent_name == "ANALGESICS, NONOPIOIDS" ||
                data?.parent_name == "ANALGESICS, OPIOIDS" ||
                data?.parent_name == "ANTIARRHYTHMICS" ||
                data?.parent_name == "ANTIEMETICS" ||
                data?.parent_name == "ANTIPLATELETS & ANTICOAGULATION" ||
                data?.parent_name == "BRONCHODILATORS" ||
                data?.parent_name == "CARDIOVASCULAR AGONISTS" ||
                data?.parent_name == "CARDIOVASCULAR ANTAGONISTS" ||
                data?.parent_name == "DIURETICS" ||
                data?.parent_name == "ELECTROLYTES" ||
                data?.parent_name == "EMERGENCY DRUGS" ||
                data?.parent_name == "LOCAL ANESTHETICS" ||
                data?.parent_name == "MISCELLANEOUS" ||
                data?.parent_name == "NEUROMUSCULAR BLOCKADE" ||
                data?.parent_name == "OBSTETRIC" ||
                data?.parent_name === "SEDATIVES AND HYPNOTICS" ||
                data?.parent_name === "COMMON DRUGS"
            ) {
                setLoading(true)

                fetch(API_ROOT + `drug-combined-attributes?token=${token}`, {
                    method: 'POST',

                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({

                        "drug_id": data?.parent_id,
                        "drug_name": data?.parent_name,
                        "age": agee,
                        "weight": weight,
                    })
                })
                    .then(response => response.json())
                    .then(res => {
                        if (res.status === "true") {
                            let responseData = res.data.sort((a, b) => (a.drug_brand_name > b.drug_brand_name) ? 1 : ((b.drug_brand_name > a.drug_brand_name) ? -1 : 0))
                            // console.log(responseData)

                            setdrugSubType(responseData);
                            setLoading(false)

                        }
                    })

            }
            if (

                props.data?.parent_name === "PENICILLINS" ||
                props.data?.parent_name === "MONOBACTAMS" ||
                props.data?.parent_name === "CEPHALOSPORINS" ||
                props.data?.parent_name === "FLUOROQUINOLONES" ||
                props.data?.parent_name === "OTHER" ||
                props.data?.parent_name === "TETRACYCLINES" ||
                props.data?.parent_name === "CARBAPENEMS" ||
                props.data?.parent_name === "ANTIFUNGALS" ||
                props.data?.parent_name === "AMINOGLYCOSIDES" ||
                props.data?.parent_name === "FLUOROQUINOLONES" ||
                props.data?.parent_name === "NITROIMIDAZOLES" ||
                props.data?.parent_name === "GLYCOPEPTIDES"


            ) {
                setLoading(true)

                fetch(API_ROOT + `drug-doses?token=${token}`, {
                    method: 'POST',

                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        dose_id: data?.parent_id,
                        age: agee,
                        weight: weight === null ? "70" : weight,

                    })

                })

                    .then(response => response.json())
                    .then(res => {
                        if (res.status === "true") {
                            setResData(res.data)
                            setLoading(false)

                        }
                    })
            }
        }
    }

    return (
        <>
            {props.data?.parent_name === "Antibiotics - by surgery type" ?
                <div className="rev">
                    {/* <nav ><ol className='breadcrumb'><li className="breadcrumb-item text-primary" onClick={()=>gotoParent(props.data.cp_code, props.data)}>{props.data?.parent_name}</li><li className="breadcrumb-item active">{props.data?.name}</li></ol></nav> */}

                    <h5 className="text-info p-3 text-center" >{title}</h5>

                    <div className="text-center">
                        {
                            drugInfo?.map((data, i) => {
                                return (
                                    <>  <div className="mt-2">
                                        <div className="all-action-subcontainer-content active" style={{ border: data.id === selectedId ? "1px solid #3F2579" : "1px solid #F2F2F2", cursor: "pointer", fontWeight: data.id === selectedId ? 'bold' : 'normal', textAlign: 'left' }} onClick={() => runFunc(data.id)} key={i} >{data.name}</div>
                                    </div>
                                    </>
                                )
                            })
                        }
                        <br></br>
                        {
                            SurgeryresData.length !== 0 ?
                                SurgeryresData.map((data2, index) => {
                                    return (

                                        <>
                                            <table className="table table-striped surgery-table" style={{ fontSize: 14, width: '100%' }}>
                                                <thead>
                                                    <tr border={1}>
                                                        <th style={{ width: '33.33%' }}>First Line Antibiotics</th>
                                                        <th style={{ width: '33.33%' }}>If mrsa colonization</th>
                                                        <th style={{ width: '33.33%' }}>If severe Penicillin</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '33.33%' }}>{data2.first_line_antibiotics ? data2.first_line_antibiotics : '-'}</td>
                                                        <td style={{ width: '33.33%' }}>{data2.if_mrsa_colonization ? data2.if_mrsa_colonization : '-'}</td>
                                                        <td style={{ width: '33.33%' }}>{data2.if_severe_penicillin ? data2.if_severe_penicillin : '-'}</td>
                                                    </tr>
                                                    {/* <tr>
                                                 <td><input style={{ height: 30, width: 200 }} placeholder="Enter value" className="ant-text-input" /></td>
                                                 <td><input style={{ height: 30, width: 200 }} placeholder="Enter value" className="ant-text-input" /></td>
                                                 <td><input style={{ height: 30, width: 200 }} placeholder="Enter value" className="ant-text-input" /></td>
                                             </tr> */}
                                                </tbody>
                                            </table>
                                            <div className="surgery-table-moble">
                                                <div className="card w-100 mt-2 p-2 " style={{ textAlign: 'left', border: '1px solid silver' }}>
                                                    <div className="mb-2 mt-2"><strong>First Line Antibiotics:</strong></div>
                                                    <div style={{ borderBottom: '1px solid silver' }}>{data2.first_line_antibiotics ? data2.first_line_antibiotics : '-'}</div>
                                                    <div className="mb-2 mt-2"><strong>If mrsa colonization:</strong></div>
                                                    <div style={{ borderBottom: '1px solid silver' }}>{data2.if_mrsa_colonization ? data2.if_mrsa_colonization : '-'}</div>
                                                    <div className="mb-2 mt-2"><strong>If severe Penicillin:</strong></div>
                                                    <div>{data2.if_severe_penicillin ? data2.if_severe_penicillin : '-'}</div>
                                                </div>
                                            </div>

                                        </>
                                    )
                                })
                                : <></>
                        }
                    </div>
                </div > :
                <>
                    <div className="result-action rev w-100">

                        <h5 className="text-info p-3 text-center" >{title}</h5>
                        {props.data?.parent_name === "Antibiotics - by class" || drugSubType?.parent_name === "Antibiotics - by class" || (props.data?.parent_name === undefined && drugSubType?.parent_name === undefined) || toggleState === true ? <></> :
                            <nav ><ol className='breadcrumb'><li className="breadcrumb-item text-primary" style={{ cursor: 'pointer' }} onClick={() => gotoParent(props.data)}>{props.data?.parent_name ? props.data?.parent_name : 'DRUGS'}</li><li className="breadcrumb-item active">{props.data?.name}</li></ol></nav>
                        }
                        <div className="mt-3 rev">
                            <div className="row">
                                <div className="dosing-container col-md-12 col-sm-12 content-tabs" >

                                    <div className="result-input-container desktop-v-sel" >
                                        <div className="w-25 text-right">Age</div>
                                        <div className="result-input-container-2 w-25" >

                                            <div>
                                                <input value={age} onChange={(e) => setAge(e.target.value)} placeholder="Age" className="ant-text-input  w-100" onBlur={(e) => {
                                                    if (age?.trim()?.length > 0) { return true } else { return setAge(18) }
                                                }} type="number" />
                                            </div>
                                        </div>
                                        <div className="w-50">
                                            <Select defaultValue={{ label: "Year", value: "Y" }} onChange={(e) => { setageType(e.value) }} placeholder="Year" className="patientype-dropdown  w-50" id="age" options={optionsforage} />

                                        </div>
                                        <div className="w-25 text-right">Weight</div>
                                        <div className="result-input-container-2 w-25">

                                            <div>
                                                <input value={weight === null ? "70" : weight} onChange={(e) => setWeight(e.target.value)} placeholder="Weight" className="ant-text-input w-100" type="number" />
                                            </div>
                                        </div>
                                        <div className="w-50">
                                            <Select defaultValue={{ label: "Kilograms", value: "KG" }} onChange={(value) => handleChangeWeightType(value)} placeholder="Weight Type" className="patientype-dropdown  w-50" id="weight" options={optionsForWeight} />

                                        </div>
                                    </div>
                                    <div className="result-input-container mobile-v-sel stickheader-cart" style={{ background: '#FFF' }} >
                                        <div className="row">
                                            <div className='col-xs-2'>Age</div>
                                            <div className='col-xs-3'>
                                                <input value={age} onChange={(e) => setAge(e.target.value)} placeholder="Age" className="ant-text-input" onBlur={(e) => {
                                                    if (age?.trim()?.length > 0) { return true } else { return setAge(18) }
                                                }} type="number" />
                                            </div>
                                            <div className='col-xs-7'>
                                                <Select defaultValue={{ label: "Year", value: "Y" }} onChange={(e) => { setageType(e.value) }} placeholder="Year" className="patientype-dropdown w-100" id="age" options={optionsforage} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-xs-2'>Weight</div>
                                            <div className='col-xs-3'><input type="number" value={weight === null ? "70" : weight} onChange={(e) => setWeight(e.target.value)} placeholder="Weight" className="ant-text-input" /></div>
                                            <div className='col-xs-7'><Select defaultValue={{ label: "Kilograms", value: "KG" }} onChange={(value) => handleChangeWeightType(value)} placeholder="Weight Type" className="patientype-dropdown w-100" id="weight" options={optionsForWeight} />
                                            </div>

                                        </div>
                                        {/* <div className="w-25 text-right">Age</div>
                                        <div className="result-input-container w-25" >

                                                <input value={age} onChange={(e) => setAge(e.target.value)} placeholder="Age" className="ant-text-input  w-50" onBlur={(e) => {
                                                    if (age?.trim()?.length > 0) { return true } else { return setAge(18) }
                                                }} />
                                        </div>
                                        <div className="w-50">
                                            <Select defaultValue={{ label: "Year", value: "Y" }} onChange={(e) => { setageType(e.value) }} placeholder="Year" className="patientype-dropdown  w-50" id="age" options={optionsforage} />

                                        </div>
                                        <div className="w-25 text-right">Weight</div>
                                        <div className="result-input-container w-25">

                                            <div>
                                                <input value={weight === null ? "70" : weight} onChange={(e) => setWeight(e.target.value)} placeholder="Weight" className="ant-text-input w-100" />
                                            </div>
                                        </div>
                                        <div className="w-50">
                                            <Select defaultValue={{ label: "Kilograms", value: "KG" }} onChange={(value) => handleChangeWeightType(value)} placeholder="Weight Type" className="patientype-dropdown  w-50" id="weight" options={optionsForWeight} />

                                        </div> */}
                                    </div>

                                    <div className="mobile-v-sel" style={{ margin: '0 auto', position: 'sticky', top: 190, zIndex: 6, background: '#FFF', width: '100%', paddingTop: 10 }} >
                                        {toggleState == false ?
                                            <button onClick={() => getResultfromApi(drId, title, subParentName)} className="submit-button-container submit-button">Submit</button> :
                                            <button onClick={() => gotoParent(props.data)} className="submit-button-container submit-button">Submit</button>



                                        }
                                    </div>
                                    <div style={{ margin: '0 auto' }} className="desktop-v-sel">
                                        {toggleState == false ?
                                            <button onClick={() => getResultfromApi(drId, title, subParentName)} className="submit-button-container submit-button">Submit</button> :
                                            <button onClick={() => gotoParent(props.data)} className="submit-button-container submit-button">Submit</button>



                                        }
                                    </div>
                                    <div className="drug-result p-2 w-100">
                                        {resData.length > 0 ?
                                            <div>
                                                <table className="table table-striped table-hover mt-2" style={{ fontSize: '14px' }}>
                                                    <thead className="bg-header text-left text-white stickheader-table">
                                                        <tr>
                                                            <th>Antibiotic Name</th>
                                                            <th>First Dose</th>
                                                            <th>First Dose (Calculated)</th>
                                                            <th>First dose max</th>
                                                            <th>Second Dose</th>
                                                            <th>Third Dose</th>
                                                            <th>Subsequent Dose</th>
                                                            <th>Admin Guidelines</th>

                                                        </tr>
                                                    </thead>

                                                    <tbody>

                                                        {

                                                            resData?.map((data, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td dangerouslySetInnerHTML={{ __html: transformContent(data.antibiotic_name, keywords) }}></td>
                                                                        <td>{data.first_dose === null || data.first_dose === 0 ? '' : data.first_dose} {data.first_dose === null || data.first_dose === 0 ? '' : data.first_dose_unit}</td>
                                                                        <td>{data.first_dose_calculated === null || data.first_dose_calculated === 0 ? '' : <> ({data.first_dose_calculated} {data.first_dose_final_unit})</>}</td>
                                                                        <td>{data.first_dose_maximum > 0 && data.first_dose_maximum} {data.first_dose_maximum > 0 && data.first_dose_final_unit}</td>
                                                                        <td>{data.second_dose}</td>
                                                                        <td>{data.third_dose}</td>
                                                                        <td>{data.subsequent_dose}</td>
                                                                        <td>{data.admin_guidelines}</td>

                                                                    </tr>

                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </table>

                                            </div> : <></>
                                        }
                                    </div>
                                    <div className="drug-result_mobile mb-5 p-3 w-100">
                                        {

                                            resData?.map((data, i) => {
                                                return (
                                                    <div className="card w-100 mt-2 p-2" key={i}>
                                                        <div className="mb-2"><strong>{data.antibiotic_name}</strong></div>
                                                        <div className="d-flex mb-2">
                                                            <div className="mr-1">First Dose: </div>
                                                            <div>{data.first_dose === null || data.first_dose === 0 ? '' : data.first_dose} {data.first_dose === null || data.first_dose === 0 ? '' : data.first_dose_unit}</div>
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="mr-1">First Dose (Calculated): </div>
                                                            <div> {data.first_dose_calculated === null || data.first_dose_calculated === 0 ? '' : <> ({data.first_dose_calculated} {data.first_dose_final_unit})</>}</div>
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="mr-1">First dose max: </div>
                                                            <div> {data.first_dose_maximum > 0 && data.first_dose_maximum} {data.first_dose_maximum > 0 && data.first_dose_final_unit}</div>
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="mr-1">Second Dose: </div>
                                                            <div> {data.second_dose}</div>
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="mr-1">Third Dose: </div>
                                                            <div> {data.third_dose}</div>
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="mr-1">Subsequent Dose: </div>
                                                            <div> {data.subsequent_dose}</div>
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="mr-1">Admin Guidelines: </div>
                                                            <div> {data.admin_guidelines}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="drug-result p-2">
                                        {
                                            drugSubType.length > 0 ?
                                                <div>
                                                    <table className="table table-striped table-hover mt-2" style={{ fontSize: '.9em' }}>
                                                        <thead className="bg-header text-left text-white stickheader-table">
                                                            <tr>
                                                                <th>Drug name</th>
                                                                <th>Admin Route</th>
                                                                <th>Indication</th>
                                                                <th>Dose</th>
                                                                <th>Calculated dose</th>
                                                                <th>Infusion dose</th>
                                                                <th>Calculated Infusion dose</th>
                                                                <th>Admin notes</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                drugSubType?.map((drugData, i) => {
                                                                    return (
                                                                        // <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',width:'80%',alignSelf:'center'}} >
                                                                        <tr key={i}>
                                                                            <td dangerouslySetInnerHTML={{ __html: transformContent(drugData.drug_brand_name, keywords) }}></td>
                                                                            <td>{drugData.administration_route}</td>
                                                                            <td>{drugData.indication}</td>
                                                                            <td>{drugData.bolus_dose_from} {drugData.bolus_dose_from ? <>-</> : <></>} {drugData.bolus_dose_to} {drugData.bolus_dose_unit}</td>
                                                                            <td>{drugData.bolus_dose_min !== 0 ? drugData.bolus_dose_min : ""} {drugData.bolus_dose_min ? <>-</> : <></>} {drugData.bolus_dose_max} {drugData.bolus_dose_final_unit}</td>
                                                                            <td>{drugData.infusion_dose_from !== 0 ? drugData.infusion_dose_from : ""} {drugData.infusion_dose_from ? <>-</> : <></>} {drugData.infusion_dose_to} {drugData.infusion_dose_unit}</td>
                                                                            <td>{drugData.infusion_dose_min !== 0 ? drugData.infusion_dose_min : ""} {drugData.infusion_dose_min ? <>-</> : <></>}{drugData.infusion_dose_max} {drugData.infusion_dose_final_unit}</td>
                                                                            <td>{drugData.administration_notes}</td>
                                                                        </tr>
                                                                        // </div>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div> : <></>
                                        }
                                    </div>
                                    <div className="drug-result_mobile mb-5 p-3 w-100">
                                        {
                                            drugSubType?.map((drugData, i) => {
                                                return (
                                                    <div className="card w-100 mt-2 p-2" key={i}>
                                                        <div className="mb-2"><strong dangerouslySetInnerHTML={{ __html: transformContent(drugData.drug_brand_name, keywords) }}></strong></div>
                                                        {drugData.administration_route.length > 0 ?
                                                            <div className="d-flex mb-2">
                                                                <div className="mr-1 font-italic">Admin Route: </div>
                                                                <div> {drugData.administration_route}</div>
                                                            </div> : <></>
                                                        }
                                                        {
                                                            drugData.indication.length > 0 ?
                                                                <div className="d-flex mb-2">
                                                                    <div className="mr-1 font-italic">Indication: </div>
                                                                    <div> {drugData.indication}</div>
                                                                </div> : <></>
                                                        }
                                                        {
                                                            drugData.bolus_dose_from?.length > 0 && drugData.bolus_dose_to?.length > 0 ?
                                                                <div className="d-flex mb-2">
                                                                    <div className="mr-1 font-italic">Dose: </div>
                                                                    <div> {drugData.bolus_dose_from} {drugData.bolus_dose_from ? <>-</> : <></>} {drugData.bolus_dose_to} {drugData.bolus_dose_unit}</div>
                                                                </div> : <></>
                                                        }
                                                        <div className="d-flex mb-2">
                                                            <div className="mr-1 font-italic">Calculated dose: </div>
                                                            <div> {drugData.bolus_dose_min !== 0 ? drugData.bolus_dose_min : ""} {drugData.bolus_dose_min ? <>-</> : <></>} {drugData.bolus_dose_max} {drugData.bolus_dose_final_unit}</div>
                                                        </div>
                                                        {
                                                            drugData.infusion_dose_from?.length > 0 && drugData.infusion_dose_to?.length > 0 ?
                                                                <div className="d-flex mb-2">
                                                                    <div className="mr-1 font-italic">Infusion dose: </div>
                                                                    <div> {drugData.infusion_dose_from !== 0 ? drugData.infusion_dose_from : ""} {drugData.infusion_dose_from ? <>-</> : <></>} {drugData.infusion_dose_to} {drugData.infusion_dose_unit}</div>
                                                                </div> : <></>
                                                        }
                                                        {drugData.infusion_dose_max?.length > 0 && drugData.infusion_dose_final_unit?.length > 0 ?
                                                            <div className="d-flex mb-2">
                                                                <div className="mr-1 font-italic">Calculated Infusion dose: </div>
                                                                <div> {drugData.infusion_dose_min !== 0 ? drugData.infusion_dose_min : ""} {drugData.infusion_dose_min ? <>-</> : <></>}{drugData.infusion_dose_max} {drugData.infusion_dose_final_unit}</div>
                                                            </div> : <></>
                                                        }
                                                        {drugData.administration_notes?.length > 0 ?
                                                            <div className="d-flex mb-2">
                                                                <div className="mr-1 font-italic">Admin notes: </div>
                                                                <div className="pl-3"> {drugData.administration_notes}</div>
                                                            </div> : <></>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {loading === true ? <Spinner /> : <></>}

        </>
    )
}

export {
    ResultOfDrugs1
}