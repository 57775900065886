import React, { useEffect, useState } from 'react';
import './CAS1025.css';

const CAS1025 = () => {
    const [sbp, setSbp] = useState(0)
    const [dbp, setDbp] = useState(0)

    const [result, setResult] = useState(0)

    const postData = async () => {
        setResult(sbp - dbp);
    }
    useEffect(() => {
        postData();
    }, [sbp, dbp]);
    return (
        <div className="rev">
            <h4 className="text-info pl-2">Pulse Pressure Calculator</h4>
            <p className="pl-2">Determines the difference between the systolic and the diastolic blood pressure..</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Systolic Blood Pressure (SBP)</strong></div>
                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        placeholder=" mmHg" onChange={(e) => setSbp(e.target.value)}
                        className="ant-text-input" style={{ height: 40 }} />
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Diastolic Blood Pressure (DBP)</strong></div>
                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        placeholder=" mm Hg" onChange={(e) => setDbp(e.target.value)}
                        className="ant-text-input" style={{ height: 40 }} />
                </div>
            </div>
            {/* <div className="stick">
                {
                    result1()

                }
            </div>  */}
            <div className="stick pt-4">
                {
                    ((sbp) && (dbp)) ?

                        <div className="apfel-score-result-container" >

                            <div className="point-result-container col-12" >
                                <h4>Pulse Pressure (PP)= {result}&nbsp;<span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>mmHg </span></h4>
                            </div>
                        </div>
                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <h5>Normal Ranges:</h5>
            <table class="table table-success table-striped">
                <thead>
                    <tr>
                        <th scope="col">Parameter</th>
                        <th scope="col">Normal Low (mmHg)</th>
                        <th scope="col">Normal High (mmHg)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Systolic Blood Pressure (SBP)</td>
                        <td>100</td>
                        <td>140</td>
                    </tr>
                    <tr>
                        <td>Diastolic Blood Pressure (DBP)</td>
                        <td>60</td>
                        <td>90</td>
                    </tr>
                    <tr>
                        <td>Pulse Pressure (PP)</td>
                        <td>40</td>
                        <td>60</td>
                    </tr>
                   
                </tbody>
            </table>
            <div className="p-2 mb-4" >
                <div>
                    ORIGINAL/PRIMARY REFERENCE
                </div>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/9236450/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Hemodynamic patterns of age-related changes in blood pressure. The Framingham Heart Study</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default CAS1025
