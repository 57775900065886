import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { API_ROOT } from '../constants'
import { useHistory } from "react-router-dom";
import Alert from '../CustomComponent/Alert';

const BurgerMenuScreen = (props) => {
    const [alertVisible, setAlertVisible] =  useState(false);
    const [message, setMessage] = useState('');

    const logout = () => {
        var token = sessionStorage.getItem("token")

        fetch(API_ROOT + `user-logout?device_type=Android&device_token=123`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }

        })
            .then(response => response.json())
            .then(res => {
                if (res.status === "true" && res.message === "Logout successfully") {
                    setAlertVisible(true);
                  setMessage('You are successfully logout from Anesthesia one');
                   
                } else {
                    props.history.push('/login')
                }

            })
    }
    
    let history = useHistory();
    const closeAlert = () => {
        setAlertVisible(false);
        props.history.push('/login');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem("id");
    }
    const gotoHome = () => {
        history.push('/actionLibrary')
    }
    const gotoProfile = () => {
        history.push('/myprofile')
    }
    const gotoFeedback = () => {
            props.history.push({
            pathname: '/feedback',
            state: {
            sourceName: 'Menu',
            returnPath: 'drawer'
        }
           
        });
}
    const gotoSettings = () => {
        history.push('/settings')
    }




    return (
        <div>

            <div className="col-md-12 pl-0 pr-2">

                <div className="conArea d-block">

                    <div className="row justify-content-center ">
                        <div className="col-md-9 savedCases1">
                            <div className="whtbxs1">
                                <ul>

                                    <li className="all-action-subcontainer-content" onClick={gotoProfile}>MY PROFILE</li>




                                    {/* <li  >
                        <div className="float-left">
                            <h6><Link to="/savecases"> OPEN A CASE </Link></h6>
                            <div style={{marginLeft:20}} >The User's Cases</div>
            <div style={{marginLeft:20}} >Shared Cases</div>
            <div style={{marginLeft:20}} >Case Templates</div>
          
                        </div>
                        <div className="clearfix"></div>
                    </li>


                    <li>
                        <div className="float-left">
                          <h6>  <Link  >OPEN CASE SUMMARY</Link></h6>
                        </div>

                        <div className="clearfix"></div>
                    </li>
                   */}
                                    <li className="all-action-subcontainer-sub-content" onClick={gotoSettings}>SETTINGS</li>


                                    {/* <li>
                                        <div className="float-left">
                                            <h6><Link to="/mysubcription" >MY SUBSCRIPTION</Link></h6>
                                        </div>

                                        <div className="clearfix"></div>
                                    </li>
                                    <li>
                                        <div className="float-left">
                                            <h6> <Link to="/notification" >NOTIFICATION</Link></h6>
                                        </div>

                                        <div className="clearfix"></div>
                                    </li> */}
                                    <li className="all-action-subcontainer-sub-content" onClick={() => gotoFeedback()}>FEEDBACK</li>
                                    <li className="all-action-subcontainer-sub-content" onClick={() => logout()}>LOGOUT</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Alert isOpen={alertVisible} message={message} label="Go to Login" closeAlert={()=>{closeAlert()}}/>

        </div>
    )
}

export default BurgerMenuScreen