import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';

const CiwaAr = () => {
    const [green, setGreen] = useState(false);
    const [vomiting, setVomiting] = useState(0);
    const [tremor, setTremor] = useState(0);
    const [paroxysmal, setParoxysmal] = useState(0);
    const [anxiety, setAnxiety] = useState(0);
    const [agitation, setAgitation] = useState(0);
    const [tactile, setTactile] = useState(0);
    const [auditory, setAuditory] = useState(0);
    const [visual, setVisual] = useState(0);
    const [headache, setHeadache] = useState(0);
    const [orientation, setOrientation] = useState(0);

    const [result, setResult] = useState(0);

    const postData = async () => {
        setResult(vomiting + tremor + paroxysmal + anxiety + agitation + tactile + auditory + visual + headache + orientation);
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [vomiting, tremor, paroxysmal, anxiety, agitation, tactile, auditory, visual, headache, orientation, result]);

    return (
        <div className="rev">
            <h4 className="text-info pl-2">CIWA-Ar for Alcohol Withdrawal</h4>
            <p className="pl-2">The CIWA-Ar objectifies severity of alcohol withdrawal.</p>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Nausea/vomiting</strong></div>
                    <div>Ask 'Do you feel sick to your stomach? Have you vomited?'</div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2">
                    <button onClick={() => setVomiting(0)} className={vomiting === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">No nausea and no vomiting</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setVomiting(1)} className={vomiting === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Mild nausea and no vomiting</span><span className="calc-val ">+1</span></button>
                    <button onClick={() => setVomiting(2)} className={vomiting === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setVomiting(3)} className={vomiting === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+3</span></button>
                    <button onClick={() => setVomiting(4)} className={vomiting === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Intermittent nausea with dry heaves</span><span className="calc-val ">+4</span></button>
                    <button onClick={() => setVomiting(5)} className={vomiting === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+5</span></button>
                    <button onClick={() => setVomiting(6)} className={vomiting === 6 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+6</span></button>
                    <button onClick={() => setVomiting(7)} className={vomiting === 7 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Constant nausea, frequent dry <br/>heaves and vomiting</span><span className="calc-val ">+7</span></button>



                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Tremor</strong></div>
                    <div>Arms extended and fingers spread apart</div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-2 ">
                    <button onClick={() => setTremor(0)} className={tremor === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">No tremor</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setTremor(1)} className={tremor === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Not visible, but can be felt fingertip to fingertip</span><span className="calc-val  ">+1</span></button>
                    <button onClick={() => setTremor(2)} className={tremor === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setTremor(3)} className={tremor === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+3</span></button>
                    <button onClick={() => setTremor(4)} className={tremor === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moderate, with patient's arms extended</span><span className="calc-val ">+4</span></button>
                    <button onClick={() => setTremor(5)} className={tremor === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val  ">+5</span></button>
                    <button onClick={() => setTremor(6)} className={tremor === 6 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+6</span></button>
                    <button onClick={() => setTremor(7)} className={tremor === 7 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Severe, even with arms<br/> not extended</span><span className="calc-val ">+7</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Paroxysmal sweats</strong></div>
                    <div></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button onClick={() => setParoxysmal(0)} className={paroxysmal === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">No sweat visible</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setParoxysmal(1)} className={paroxysmal === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Barely perceptible sweating,<br/> palms moist</span><span className="calc-val ">+1</span></button>
                    <button onClick={() => setParoxysmal(2)} className={paroxysmal === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setParoxysmal(3)} className={paroxysmal === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+3</span></button>
                    <button onClick={() => setParoxysmal(4)} className={paroxysmal === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Beads of sweat obvious <br/>on forehead</span><span className="calc-val ">+4</span></button>
                    <button onClick={() => setParoxysmal(5)} className={paroxysmal === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+5</span></button>
                    <button onClick={() => setParoxysmal(6)} className={paroxysmal === 6 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+6</span></button>
                    <button onClick={() => setParoxysmal(7)} className={paroxysmal === 7 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Drenching sweats</span><span className="calc-val ">+7</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Anxiety</strong></div>
                    <div>Ask, 'Do you feel nervous?'</div>


                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button onClick={() => setAnxiety(0)} className={anxiety === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">No anxiety, at ease</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setAnxiety(1)} className={anxiety === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Mildly anxious</span><span className="calc-val ">+1</span></button>
                    <button onClick={() => setAnxiety(2)} className={anxiety === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setAnxiety(3)} className={anxiety === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+3</span></button>
                    <button onClick={() => setAnxiety(4)} className={anxiety === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moderately anxious, or guarded,<br/> so anxiety is inferred</span><span className="calc-val ">+4</span></button>
                    <button onClick={() => setAnxiety(5)} className={anxiety === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+5</span></button>
                    <button onClick={() => setAnxiety(6)} className={anxiety === 6 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+6</span></button>
                    <button onClick={() => setAnxiety(7)} className={anxiety === 7 ? "btn  text-left active" : "btn  text-left"} style={{height:100}}><span className="btn-name ">Equivalent to acute panic<br/> states as seen in severe delirium or<br/> acute schizophrenic reactions</span><span className="calc-val ">+7</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Agitation</strong></div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button onClick={() => setAgitation(0)} className={agitation === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Normal activity</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setAgitation(1)} className={agitation === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Somewhat more activity than normal activty</span><span className="calc-val ">+1</span></button>
                    <button onClick={() => setAgitation(2)} className={agitation === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setAgitation(3)} className={agitation === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+3</span></button>
                    <button onClick={() => setAgitation(4)} className={agitation === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moderately fidgety and restless</span><span className="calc-val ">+4</span></button>
                    <button onClick={() => setAgitation(5)} className={agitation === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+5</span></button>
                    <button onClick={() => setAgitation(6)} className={agitation === 6 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">(More severe symptoms)</span><span className="calc-val ">+6</span></button>
                    <button onClick={() => setAgitation(7)} className={agitation === 7 ? "btn  text-left active" : "btn  text-left"} style={{height:100}}><span className="btn-name ">Paces back and forth during most<br/> of the interview, or constantly<br/> thrashes about</span><span className="calc-val ">+7</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Tactile disturbances</strong></div>
                    <div>Ask, 'Have you any itching, pins and needles sensations, any burning, any numbness, or do you feel bugs crawling on or under your skin?'</div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button onClick={() => setTactile(0)} className={tactile === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">None</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setTactile(1)} className={tactile === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Very mild itching, pin and needles,<br/> burning, or numbness</span><span className="calc-val ">+1</span></button>
                    <button onClick={() => setTactile(2)} className={tactile === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Mild itching, pin and needles,<br/> burning, or numbness</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setTactile(3)} className={tactile === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moderate itching, pin and needles,<br/> burning, or numbness</span><span className="calc-val ">+3</span></button>
                    <button onClick={() => setTactile(4)} className={tactile === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moderately severe hallucinations</span><span className="calc-val ">+4</span></button>
                    <button onClick={() => setTactile(5)} className={tactile === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Severe hallucinations</span><span className="calc-val ">+5</span></button>
                    <button onClick={() => setTactile(6)} className={tactile === 6 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Extremely severe hallucinations</span><span className="calc-val ">+6</span></button>
                    <button onClick={() => setTactile(7)} className={tactile === 7 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Continuous hallucinations</span><span className="calc-val ">+7</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Auditory disturbances</strong></div>
                    <div>Ask, 'Are you more aware of sounds around you? Are they harsh? Do they frighten you? Are you hearing anything that is disturbing to you? Are you hearing things you know are not there?'</div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button onClick={() => setAuditory(0)} className={auditory === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Not present</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setAuditory(1)} className={auditory === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Very mild harshness or <br/>ability to frighten</span><span className="calc-val ">+1</span></button>
                    <button onClick={() => setAuditory(2)} className={auditory === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Mild harshness or ability to frighten</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setAuditory(3)} className={auditory === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moderate harshness or ability to frighten</span><span className="calc-val ">+3</span></button>
                    <button onClick={() => setAuditory(4)} className={auditory === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moderately severe hallucinations</span><span className="calc-val ">+4</span></button>
                    <button onClick={() => setAuditory(5)} className={auditory === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Severe hallucinations</span><span className="calc-val ">+5</span></button>
                    <button onClick={() => setAuditory(6)} className={auditory === 6 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Extremely severe hallucinations</span><span className="calc-val ">+6</span></button>
                    <button onClick={() => setAuditory(7)} className={auditory === 7 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Continuous hallucinations</span><span className="calc-val ">+7</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Visual disturbances</strong></div>
                    <div>Ask 'Does the light appear to be too bright? Is its color different? Does it hurt your eyes? Are you seeing anything that is disturbing to you? Are you seeing things you know are not there?'</div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button onClick={() => setVisual(0)} className={visual === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Not present</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setVisual(1)} className={visual === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Very mild sensitivity</span><span className="calc-val ">+1</span></button>
                    <button onClick={() => setVisual(2)} className={visual === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Mild sensitivity</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setVisual(3)} className={visual === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moderate sensitivity</span><span className="calc-val ">+3</span></button>
                    <button onClick={() => setVisual(4)} className={visual === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moderately severe hallucinations</span><span className="calc-val ">+4</span></button>
                    <button onClick={() => setVisual(5)} className={visual === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Severe hallucinations</span><span className="calc-val ">+5</span></button>
                    <button onClick={() => setVisual(6)} className={visual === 6 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Extremely severe hallucinations</span><span className="calc-val ">+6</span></button>
                    <button onClick={() => setVisual(7)} className={visual === 7 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Continuous hallucinations</span><span className="calc-val ">+7</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Headache/fullness in head</strong></div>
                    <div>Ask 'Does your head feel different? Does it feel like there is a band around your head?' Do not rate for dizziness or lightheadedness. Otherwise, rate 'severity.'</div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button onClick={() => setHeadache(0)} className={headache === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Not present</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setHeadache(1)} className={headache === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Very mild</span><span className="calc-val ">+1</span></button>
                    <button onClick={() => setHeadache(2)} className={headache === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Mild</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setHeadache(3)} className={headache === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moderate </span><span className="calc-val ">+3</span></button>
                    <button onClick={() => setHeadache(4)} className={headache === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moderately severe </span><span className="calc-val ">+4</span></button>
                    <button onClick={() => setHeadache(5)} className={headache === 5 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Severe s</span><span className="calc-val ">+5</span></button>
                    <button onClick={() => setHeadache(6)} className={headache === 6 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Very severe</span><span className="calc-val ">+6</span></button>
                    <button onClick={() => setHeadache(7)} className={headache === 7 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Extremely severe</span><span className="calc-val ">+7</span></button>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Orientation/clouding of sensorium</strong></div>
                    <div>Ask 'What day is this? Where are you? Who am I?'</div>

                </div>
                <div className="col-md-7 btn-group-vertical options-btn mb-4">
                    <button onClick={() => setOrientation(0)} className={orientation === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Oriented, can do serial additions</span><span className="calc-val ">0</span></button>
                    <button onClick={() => setOrientation(1)} className={orientation === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Can't do serial additions or<br/> is uncertain about date</span><span className="calc-val ">+1</span></button>
                    <button onClick={() => setOrientation(2)} className={orientation === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Disoriented for date by no<br/> more than 2 calendar days</span><span className="calc-val ">+2</span></button>
                    <button onClick={() => setOrientation(3)} className={orientation === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Disoriented for date by <br/>more than 2 calendar days </span><span className="calc-val ">+3</span></button>
                    <button onClick={() => setOrientation(4)} className={orientation === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Disoriented to place or person</span><span className="calc-val ">+4</span></button>

                </div>
            </div>


            <div className="stick">
                {
                    green ? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container" >
                                <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>


                                <div>{result <= 8 && result >= 0 ? "Patients with scores ≤8 typically do not require medication for withdrawal." : " "}</div>
                                <div>{result > 8 && result < 20 ? "Patients with scores ≥9 may require medication for withdrawal." : " "}</div>
                                <div>{result >= 20 ? "Patients with scores ≥20 frequently require medication for withdrawal, and may also require admission to the ICU for observation for seizures or development of delirium tremens, and more frequent medication dosing." : " "}</div>



                            </div>






                        </div>



                    </>

                        :
                        <></>
                }
            </div>
            <div className="p-2" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/2597811/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />
                        </div>
                        <p className="reference-paragraph" >Sullivan JT, Sykora K, Schneiderman J, Naranjo CA, Sellers EM. Assessment of alcohol withdrawal: the revised clinical institute withdrawal assessment for alcohol scale (CIWA-Ar). Br J Addict. 1989 Nov;84(11):1353-7. PubMed PMID: 2597811.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}


export default CiwaAr;

