import React, { useEffect, useState } from 'react';
import '../css/ApfelScore.css';

const PediatricCardiovascularcalculator = () => {
    const [drugOption3, setDrugOption3] = useState(0)
    const postData = async () => {

    }
    useEffect(() => {
        postData();


    }, [drugOption3]);
    const result1 = () => {
        if (drugOption3) {
            if (drugOption3 == 0) {
                return (
                    <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>
    
                )
            }
            if (drugOption3 == 1) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container col-12" >
                    <h2> Age: Neonate(&lt; 1 Kg)</h2>
                        <h5><strong>Heart Rate (Awake)</strong>&nbsp;=&nbsp; 100-205 bpm</h5>
                        <h5><strong>Heart Rate (Asleep)</strong>&nbsp;=&nbsp; 90-160 bpm</h5>
                        <h5><strong>Respiratory Rate</strong>&nbsp;=&nbsp; 30-53 bpm</h5>
                        <h5><strong>Systolic Blood Pressure</strong>&nbsp;=&nbsp; 39-59 mmHg</h5>
                        <h5><strong>Diastolic Blood Pressure</strong> &nbsp;=&nbsp; 16-36 mmHg </h5>
                        <h5><strong>Temperature between</strong>&nbsp;36.5 and 37.5 &#176;C </h5>
                    </div>
                </div>
            }
            if (drugOption3 == 2) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container col-12" >
                    <h2> Age: Neonate( 3 Kg)</h2>
                        <h5><strong>Heart Rate (Awake)</strong>&nbsp;=&nbsp; 100-205 bpm</h5>
                        <h5><strong>Heart Rate (Asleep)</strong>&nbsp;=&nbsp; 90-160 bpm</h5>
                        <h5><strong>Respiratory Rate</strong>&nbsp;=&nbsp; 30-53 bpm</h5>
                        <h5><strong>Systolic Blood Pressure</strong>&nbsp;=&nbsp; 60-84 mmHg</h5>
                        <h5><strong>Diastolic Blood Pressure</strong> &nbsp;=&nbsp; 31-53 mmHg </h5>
                        <h5><strong>Temperature between</strong>&nbsp;36.5 and 37.5 &#176;C </h5>
                    </div>
                </div>
            }
            if (drugOption3 == 3) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container col-12" >
                    <h2> Age: Infant( 1-12 months)</h2>
                        <h5><strong>Heart Rate (Awake)</strong>&nbsp;=&nbsp; 100-190 bpm</h5>
                        <h5><strong>Heart Rate (Asleep)</strong>&nbsp;=&nbsp; 90-160 bpm</h5>
                        <h5><strong>Respiratory Rate</strong>&nbsp;=&nbsp; 30-53 bpm</h5>
                        <h5><strong>Systolic Blood Pressure</strong>&nbsp;=&nbsp; 70-104 mmHg</h5>
                        <h5><strong>Diastolic Blood Pressure</strong> &nbsp;=&nbsp; 37-56 mmHg </h5>
                        <h5><strong>Temperature between</strong>&nbsp;36.5 and 37.5 &#176;C </h5>
                    </div>
                </div>
            }
            if (drugOption3 == 4) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container col-12" >
                        <h2> Age: 1-2 Years</h2>
                        <h5><strong>Heart Rate (Awake)</strong>&nbsp;=&nbsp; 98-140 bpm</h5>
                        <h5><strong>Heart Rate (Asleep)</strong>&nbsp;=&nbsp; 80-120 bpm</h5>
                        <h5><strong>Respiratory Rate</strong>&nbsp;=&nbsp; 22-37 bpm</h5>
                        <h5><strong>Systolic Blood Pressure</strong>&nbsp;=&nbsp; 86-106 mmHg</h5>
                        <h5><strong>Diastolic Blood Pressure</strong> &nbsp;=&nbsp; 42-63 mmHg </h5>
                        <h5><strong>Temperature between</strong>&nbsp;36.5 and 37.5 &#176;C </h5>
                    </div>
                </div>
            }
            if (drugOption3 == 5) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container col-12" >
                    <h2> Age: 3-5 Years</h2>
                        <h5><strong>Heart Rate (Awake)</strong>&nbsp;=&nbsp; 80-120 bpm</h5>
                        <h5><strong>Heart Rate (Asleep)</strong>&nbsp;=&nbsp; 65-100 bpm</h5>
                        <h5><strong>Respiratory Rate</strong>&nbsp;=&nbsp; 20-28 bpm</h5>
                        <h5><strong>Systolic Blood Pressure</strong>&nbsp;=&nbsp; 89-112 mmHg</h5>
                        <h5><strong>Diastolic Blood Pressure</strong> &nbsp;=&nbsp; 46-72 mmHg </h5>
                        <h5><strong>Temperature between</strong>&nbsp;36.5 and 37.5 &#176;C </h5>
                    </div>
                </div>
            }
            if (drugOption3 == 6) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container col-12" >
                    <h2> Age: 6-9 Years</h2>
                        <h5><strong>Heart Rate (Awake)</strong>&nbsp;=&nbsp; 75-118 bpm</h5>
                        <h5><strong>Heart Rate (Asleep)</strong>&nbsp;=&nbsp; 58-90 bpm</h5>
                        <h5><strong>Respiratory Rate</strong>&nbsp;=&nbsp; 18-25 bpm</h5>
                        <h5><strong>Systolic Blood Pressure</strong>&nbsp;=&nbsp; 97-115 mmHg</h5>
                        <h5><strong>Diastolic Blood Pressure</strong> &nbsp;=&nbsp; 57-76 mmHg </h5>
                        <h5><strong>Temperature between</strong>&nbsp;36.5 and 37.5 &#176;C </h5>
                    </div>
                </div>
            }
            if (drugOption3 == 7) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container col-12" >
                    <h2> Age: 10-11 Years</h2>
                        <h5><strong>Heart Rate (Awake)</strong>&nbsp;=&nbsp; 75-118 bpm</h5>
                        <h5><strong>Heart Rate (Asleep)</strong>&nbsp;=&nbsp; 58-90 bpm</h5>
                        <h5><strong>Respiratory Rate</strong>&nbsp;=&nbsp; 18-25 bpm</h5>
                        <h5><strong>Systolic Blood Pressure</strong>&nbsp;=&nbsp; 102-120 mmHg</h5>
                        <h5><strong>Diastolic Blood Pressure</strong> &nbsp;=&nbsp; 61-80 mmHg </h5>
                        <h5><strong>Temperature between</strong>&nbsp;36.5 and 37.5  &#176;C </h5>
                    </div>
                </div>
            }
            if (drugOption3 == 8) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container col-12" >
                    <h2> Age: 12-15 Years</h2>
                        <h5><strong>Heart Rate (Awake)</strong>&nbsp;=&nbsp; 60-100 bpm</h5>
                        <h5><strong>Heart Rate (Asleep)</strong>&nbsp;=&nbsp; 50-90 bpm</h5>
                        <h5><strong>Respiratory Rate</strong>&nbsp;=&nbsp; 12-20 bpm</h5>
                        <h5><strong>Systolic Blood Pressure</strong>&nbsp;=&nbsp; 110-131 mmHg</h5>
                        <h5><strong>Diastolic Blood Pressure</strong> &nbsp;=&nbsp; 64-83 mmHg </h5>
                        <h5><strong>Temperature between</strong>&nbsp;36.5 and 37.5 &#176;C </h5>
                    </div>
                </div>
            }




        }
        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }
    return (<div className="rev">
          <h5 className="text-info pl-2">Pediatric Vital Signs Calculator</h5>
            <p className="pl-2">Provides a list of values for vital signs that are normal given the age of the patient.</p>
        <div className="fields row w-100">
            <div className="col-md-5">
                <div className="pt-2"><strong>Age</strong>
                </div>
            </div>
            <div className="col-md-7">
                <select className="custom-select" aria-label="Age"
                    value={drugOption3}
                    onChange={(e) => {
                        const patient = e.target.value;

                        setDrugOption3(patient);
                    }}
                >
                    <option value='0'>Select</option>
                    <option value='1'>Neonate( &lt;1 kg)</option>
                    <option value='2' >Neonate(3 Kg)</option>
                    <option value='3' >Infant (1-12 months)</option>
                    <option value='4' >1-2 years</option>
                    <option value='5' >3-5 years</option>
                    <option value='6' >6-9 years</option>
                    <option value='7' >10-11 years</option>
                    <option value='8' >12-15 years</option>
                </select>
            </div>
        </div><br/>
        <div className="stick pt-2">
            {
                result1()
            }
        </div>
        <div className="p-2 mb-4" >
                <div>
                    ORIGINAL/PRIMARY REFERENCE
                </div>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/21411136/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >1. Fleming S, Thompson M, Stevens R, Heneghan C, Plüddemann A, Maconochie I, Tarassenko L, Mant D. (2011) </p>
                    </div>
                </a>
            </div>
    </div>


    )
}

export default PediatricCardiovascularcalculator
