import React, { useState, useEffect } from 'react'
import upload from '../images/uploadIMg.png'
import { Link, useHistory } from 'react-router-dom';
import { API_ROOT } from '../constants';
import axios from 'axios';


const Feedback = (props) => {
    const [file, setFile] = useState('');
    const [image, setImage] = useState({ preview: "", raw: "", name: "" });
    // const [formdata, setFormData] = useState('')
    const [query, setQuery] = useState([])
    const [msg, setMsg] = useState("")
    const [imgValid, setImgValid] = useState("");
    let history = useHistory();
    const handleChangeUploadImage = e => {
        if (e.target.files.length) {
            if (e.target.files[0].size <= 500000) {
                setImage({
                    preview: URL.createObjectURL(e.target.files[0]),
                    raw: e.target.files[0],
                    name: e.target.files[0].name
                });
                setFile(e.target.files[0])
                setImgValid("")
            }
            else {
                setImgValid("Only files under 500kb can be uploaded")
            }
        }
    };
useEffect(() =>{
let queryval = props?.calcname ? props?.calcname: '';
setQuery(queryval)
}, [])
    const handleSubmit = async (e) => {
        if (query.length === 0) {
            alert('Please Enter Subject line of your feedback');
            return false;
        }

        else {
            var userID = sessionStorage.getItem("id")
            let formdata = new FormData();
            e.preventDefault()
            formdata.append("image", file)
            formdata.append("message", msg)
            formdata.append("users_id", userID)
            formdata.append("query_subject", query+" "+"Feedback from: " + props.calcname)
            let res;
            const token = sessionStorage.getItem('token')
                 res = await axios({
                    url: API_ROOT + `save-feedback?token=${token}`,
                    method: 'POST',
                    data: formdata,
                    headers: {
                        Accept: '*/*',
                        'Content-Type': 'multipart/form-data'
                    }
                });
           
            
            if (res.data.status) {
                alert(res.data.message)
                history.push(`/home`)
            }

            // try {
            //     const token = await sessionStorage.getItem('token')
            //     let formdata = new FormData();

            //     formdata.append("image", file)
            //     formdata.append("message", msg)
            //     formdata.append("caseId", "2186")
            //     const res = await axios({
            //         url: API_ROOT + `save-case-summary-note?token=${token}`,
            //         method: 'POST',
            //         data: formdata,
            //         headers: {
            //             'Content-Type': 'multipart/form-data'
            //         }
            //     });
            
            //     if (res.data.status) {
            //         alert(res.data.message)
            //     }
            // } catch (error) {
            
            // }
        }


    }
    return (
        <div>
            <div className="backNavigation text-center pt-2 pb-2">
               FEEDBACK
            </div>
            <div className="col-md-12 pl-0 pr-2">

                <div className="conArea d-block">


                    <div className="row justify-content-center ">
                        <div className="col-md-9 savedCases">
                                <form>
                                    {/* <div className="form-group">
                                        <label>Name</label>
                                        <input type="password" className="form-control" placeholder="Enter Name" />
                                    </div>
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input type="password" className="form-control" placeholder="Enter Email Address" />
                                    </div> */}
                                    <div className="form-group">
                                        <label><h6>Subject</h6></label>
                                        <input defaultValue={query} onChange={(e) => setQuery(e.target.value)} type="text" className="form-control" placeholder="Enter Subject" required />
                                    </div>
                                    <div className="form-group">
                                        <label><h6>Message</h6></label>
                                        <textarea defaultValue={msg} onChange={(e) => setMsg(e.target.value)} className="form-control" placeholder="Type your feedback here"></textarea>

                                    </div>


                                    <div className="form-group uploadsB">
                                        <label><h6>Upload a Image</h6></label>
                                        <span className="uploads">
                                            {image.preview ? (
                                                <div><img src={image.preview} alt="upload Image" /><span>{image.name}</span></div>
                                            ) : (
                                                <>
                                                    <i className="fa fa-file-image-o upload-icon"  style={{color:'lightgray'}}></i>
                                                </>
                                            )}
                                            <input type="file" id="myFile" name="filename" onChange={handleChangeUploadImage} />
                                        </span>
                                        <br></br>
                                        {imgValid !== "" ? <p style={{ color: "red" }}>{imgValid}</p> : ""}
                                        <br></br>
                                    </div>
                                    <div className="form-group mt-4 text-center mb-4">
                                        <button onClick={(e) => handleSubmit(e)} type="submit" className=" save-button-container font-weight-bold text-uppercase" style={{ margin: '0 auto' }}>Submit</button>
                                    </div>

                                </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Feedback