import React, { useState, useEffect } from 'react';
import './AAV1001.css'
import { API_ROOT } from '../../../constants';
import UserFeeds from '../../../CustomComponent/common/UserFeeds/UserFeeds';

const AAV1001 = () => {
    const [age, setAge] = useState([]);
    const [result, setResult] = useState([]);
    const [result1, setResult1] = useState([]);
    const [tunit, setTunit] = useState('Years');
    const [cuffed, setCuffed] = useState([]);
    const [uncuffed, setUnCuffed] = useState([]);
    const [lma, setLma] = useState([]);
    const [doubleLumen, setDoubleLumen] = useState([]);
    const [toggleState, setToggleState] = useState(0);
    const [toggleState1, setToggleState1] = useState(0);
    const [toggleSection, setToggleSection] = useState(10);
    const [selectName, setSelectName] = useState('');
    const [value, setValue] = useState('');
    const [options, setOptions] = useState([]);
    const [deviceVal, setDeviceVal] = useState('');
    const toggleTab = (index) => {
        setToggleState(index);
    };
    const toggleTab1 = (index) => {
        setToggleState1(index);
    };
    const toggleSearchTab = (index) => {
        setToggleSection(index);
        setResult1([]);
        setResult([]);
        setAge([]);
        setTunit('Years');
        setDeviceVal('');
        setValue('');

    };
    useEffect(() => {

    }, [options])

    const setPayloadforAgenWeight = () => {
        if (age.length !== 0) {
            let yearVal;
            if (tunit === 'Years') {
                yearVal = age * 365;
            }
            if (tunit === 'Months') {
                yearVal = age * 30;
            }
            if (tunit === 'Days') {
                yearVal = age;
            }
            var token = sessionStorage.getItem("token")

            fetch(API_ROOT + `airway-equipment-compatibility?token=${token}`, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "age": yearVal,
                    "weight": '',
                    "et_tube_uncuffed": '',
                    "et_tube_cuffed": '',
                    "lma_size": '',
                    "double_lumen_ett": ''

                })
            })

                .then(response => response.json())
                .then(res => {
                    if (res.status === "true") {
                        setResult(res.data);
                    }
                })
        }

    }
    const setPayloadforSection2 = () => {
        if (uncuffed.length !== 0 || cuffed.length !== 0 || lma.length !== 0 || doubleLumen.length !== 0) {

            var token = sessionStorage.getItem("token")

            fetch(API_ROOT + `airway-equipment-compatibility?token=${token}`, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "age": '',
                    "weight": '',
                    "et_tube_uncuffed": uncuffed,
                    "et_tube_cuffed": cuffed,
                    "lma_size": lma,
                    "double_lumen_ett": doubleLumen

                })
            })

                .then(response => response.json())
                .then(res => {
                    if (res.status === "true") {
                        setResult1(res.data);
                    }
                })
        }

    }


    const setValueforPaylod = (e) => {
        if (options) {
            if (selectName === 'uncuffed') {
                setUnCuffed(e.target.value);
                setValue(e.target.value)
                setCuffed('');
                setLma('');
                setDoubleLumen('');

            } else if (selectName === 'cuffed') {
                setCuffed(e.target.value);
                setValue(e.target.value)
                setUnCuffed('');
                setLma('');
                setDoubleLumen('');
            } else if (selectName === 'lmasize') {
                setLma(e.target.value);
                setValue(e.target.value)
                setUnCuffed('');
                setCuffed('');
                setDoubleLumen('');
            } else if (selectName === 'doublelumen') {
                setDoubleLumen(e.target.value);
                setValue(e.target.value);
                setUnCuffed('');
                setCuffed('');
                setLma('');
            } else {
                setValue(e.target.value)

                setUnCuffed('');
                setCuffed('');
                setLma('');
                setDoubleLumen('');
            }
        }

    }
    const changeOptions = (e) => {
        setDeviceVal(e.target.value);
        if (e.target.value === 'uncuffed') {
            setSelectName('uncuffed')
            const uArray = [];
            for (let i = 2; i <= 8; i += 0.5) {
                uArray.push(<option value={i} key={i}>{i} mm</option>)
            }
            setOptions(uArray);
            setValue('');
        } else if (e.target.value === 'cuffed') {
            setSelectName('cuffed')
            const cArray = [];
            for (let i = 2; i <= 8; i += 0.5) {
                cArray.push(<option value={i} key={i}>{i} mm</option>)
            }
            setOptions(cArray);
            setValue(0);

        } else if (e.target.value === 'lmasize') {
            setSelectName('lmasize')
            const lArray = [];
            for (let i = 1; i <= 8; i += 0.5) {
                lArray.push(<option value={i} key={i}>{i}</option>)
            }
            setOptions(lArray);
            setValue('');
        } else if (e.target.value === 'doublelumen') {
            setSelectName('doublelumen')
            const dArray = [];
            for (let i = 1; i <= 100; i++) {
                dArray.push(<option value={i} key={i - 1}>{i}</option>)
            }
            setOptions(dArray);
            setValue('');

        } else {
            setValue('');
        }
    }
    return (
        <div className="rev">
            <h5 className="text-info pl-2">Airway Compatibility Calculator</h5>
            <div className="mt-3 pl-2 frm-field stickinput">
                <div className={toggleSection === 10 ? "tabs search-tabs search-active-tabs" : "tabs search-tabs"}
                    onClick={() => toggleSearchTab(10)} style={{ width: '200px' }}>Search by Age</div>
                <div className={toggleSection === 11 ? "tabs search-tabs search-active-tabs" : "tabs search-tabs"}
                    onClick={() => toggleSearchTab(11)} style={{ width: '200px' }}>Search by Equipment</div>
            </div>

            <div className="content-tabs">

                <div className={toggleSection === 10 ? "tab-content active-tabContent" : "tab-content"}>

                <div className="fields row">
                        <div className="col-md-4 col-xs-12">
                            <div className="pt-3"><strong>Age</strong></div>
                        </div>
                        <div className="col-md-8 col-xs-12 frm-field">
                            <input onChange={(e) => { setAge(e.target.value.trim()); setResult('') }}
                                placeholder="Enter age"
                                className="ant-text-input" value={age} style={{marginLeft:0}} type="number"/>
                            
                            {/* <span className="units" onClick={changeunit}>{tunit}&#8651;</span> */}
                        {/* </div> */}
                        {/* <div className="col-md-2  frm-field"> */}
                        <select className="custom-select"  value={tunit} onChange={(e) => { setTunit(e.target.value); }} style={{height:40,}}>
                                <option value="Years">Year(s)</option>
                                <option value="Months">Month(s)</option>
                                <option value="Days">Day(s)</option>


                            </select>
                        </div>
                    </div>
                    {/* <div className="fields row">
                        <div className="col-md-5">
                            <div className="pt-2"><strong>Weight</strong></div>
                        </div>
                        <div className="col-md-7 frm-field">
                            <input
                                placeholder="Enter weight"
                                className="ant-text-input" style={{ width: '79%' }} onChange={(e) => setWeight(e.target.value.trim())} value={weight} /><span className="units">Kg</span>
                        </div>
                    </div> */}
                    <div className="pb-2">
                        <button className="save-button-container" style={{ margin: '0 auto' }} onClick={setPayloadforAgenWeight}>Submit</button>
                    </div>
                    <br />

                    {(result?.length !== 0) ? <div className="btn-group">
                        {result?.map((data, key) => {
                            return (<button style={{height:40 }} key={key} className={toggleState === key ? "btn active text-white" : "btn"}
                                onClick={() => toggleTab(key)} >{data.age} ({data.weight})</button>)

                        })
                        }
                    </div> : <></>
                    }
                    {(result?.length !== 0) ?
                        <div className="content-tabs">
                            {result?.map((data, key) => {
                                return (
                                    <div key={key}
                                        className={toggleState === key ? "calculator-content  active-content" : "calculator-content"}>
                                        <table className="table" key={key + 1}>
                                            <tbody>
                                                <tr>
                                                    <td>ET Tube insertion Length at lips (cm)</td>
                                                    <td>{data?.et_tube_insertion_lengh_at_lips_cm}</td>
                                                </tr>
                                                <tr>
                                                    <td>LMA size</td>
                                                    <td>{(data?.lma_size_from && data?.lma_size_to) && (data?.lma_size_from === data?.lma_size_to) ?
                                                        <span>{data?.lma_size_to}</span> :
                                                        <span>{data?.lma_size_from} - {data?.lma_size_to}</span>
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>LMA max cuff inflation volume (ml air)</td>
                                                    <td>{data?.lma_maximum_cuff_inflation_volume_ml_air}</td>
                                                </tr>
                                                <tr>
                                                    <td>LMA min i.d. (mm)</td>
                                                    <td>{data?.lma_minimum_id_mm}</td>
                                                </tr>
                                                <tr>
                                                    <td>LMA max ETT size</td>
                                                    <td>{data?.lma_maximum_ett_size}</td>
                                                </tr>
                                                <tr>
                                                    <td>Max FOB size (mm)</td>
                                                    <td>{data?.maximum_fob_size_mm}</td>
                                                </tr>
                                                <tr>
                                                    <td>Airway exchanger (Cook, Fr)</td>
                                                    <td>{data?.airway_exchanger_cook_fr}</td>
                                                </tr>
                                                <tr>
                                                    <td>Bronchial Blocker (Fr)</td>
                                                    <td>{(data?.bronchial_blocker_fr_min && data?.bronchial_blocker_fr_max) && (data?.bronchial_blocker_fr_min === data?.bronchial_blocker_fr_max) ?
                                                        <span>{data?.bronchial_blocker_fr_max}</span> :
                                                        <span>{data?.bronchial_blocker_fr_min} - {data?.bronchial_blocker_fr_max}</span>
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Univent (mm)</td>
                                                    <td>{(data?.univent_mm_min && data?.univent_mm_max) && (data?.univent_mm_min === data?.univent_mm_max) ?
                                                        <span>{data?.univent_mm_max}</span> :
                                                        <span>{data?.univent_mm_min} - {data?.univent_mm_max}</span>}


                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Suction Catheter (Fr)</td>
                                                    <td>{data?.suction_catheter_fr}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>)

                            })
                            }

                        </div> : <></>
                    }

                </div>

                <div className={toggleSection === 11 ? "tab-content  active-tabContent" : "tab-content"}>
                    <div className="fields">
                        <select className="custom-select" style={{ width: '60%' }} value={deviceVal} onChange={(e) => changeOptions(e)}>
                            <option value="">Select</option>
                            <option value="uncuffed">ET Tube uncuffed</option>
                            <option value="cuffed">ET Tube cuffed</option>
                            <option value="lmasize">LMA Size</option>
                            <option value="doublelumen">Double lumen ETT</option>

                        </select>
                        <select className="custom-select" style={{ width: '40%' }} value={value} onChange={(e) => setValueforPaylod(e)}>
                            <option value="">Select</option>
                            {options}
                        </select>
                    </div>

                    <div className="pb-2">
                        <button className="save-button-container" style={{ margin: '0 auto' }} onClick={setPayloadforSection2}>Submit</button>
                    </div>
                    <br />

                    {(cuffed.length !== 0 || uncuffed.length !== 0 || lma.length !== 0 || doubleLumen.length !== 0) ? <div className="btn-group" style={{overflowX:'auto'}}>
                        {result1?.map((data, key) => {
                            return (<button style={{height: 40 }} key={key} className={toggleState1 === key ? "btn active text-white" : "btn"}
                                onClick={() => toggleTab1(key)} >{data.age} ({data.weight})</button>)

                        })
                        }
                    </div> : <></>
                    }
                    {(cuffed.length !== 0 || uncuffed.length !== 0 || lma.length !== 0 || doubleLumen.length !== 0) ?
                        <div className="content-tabs">
                            {result1?.map((data, key) => {
                                return (
                                    <div key={key}
                                        className={toggleState1 === key ? "calculator-content  active-content" : "calculator-content"}>
                                        <table className="table" key={key + 1}>
                                            <tbody>
                                                <tr>
                                                    <td>ET Tube insertion Length at lips (cm)</td>
                                                    <td>{data?.et_tube_insertion_lengh_at_lips_cm}</td>
                                                </tr>
                                                <tr>
                                                    <td>LMA size</td>
                                                    <td>{(data?.lma_size_from && data?.lma_size_to) && (data?.lma_size_from === data?.lma_size_to) ?
                                                        <span>{data?.lma_size_to}</span> :
                                                        <span>{data?.lma_size_from} - {data?.lma_size_to}</span>
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>LMA max cuff inflation volume (ml air)</td>
                                                    <td>{data?.lma_maximum_cuff_inflation_volume_ml_air}</td>
                                                </tr>
                                                <tr>
                                                    <td>LMA min i.d. (mm)</td>
                                                    <td>{data?.lma_minimum_id_mm}</td>
                                                </tr>
                                                <tr>
                                                    <td>LMA max ETT size</td>
                                                    <td>{data?.lma_maximum_ett_size}</td>
                                                </tr>
                                                <tr>
                                                    <td>Max FOB size (mm)</td>
                                                    <td>{data?.maximum_fob_size_mm}</td>
                                                </tr>
                                                <tr>
                                                    <td>Airway exchanger (Cook, Fr)</td>
                                                    <td>{data?.airway_exchanger_cook_fr}</td>
                                                </tr>
                                                <tr>
                                                    <td>Bronchial Blocker (Fr)</td>
                                                    <td>{(data?.bronchial_blocker_fr_min && data?.bronchial_blocker_fr_max) && (data?.bronchial_blocker_fr_min === data?.bronchial_blocker_fr_max) ?
                                                        <span>{data?.bronchial_blocker_fr_max}</span> :
                                                        <span>{data?.bronchial_blocker_fr_min} - {data?.bronchial_blocker_fr_max}</span>
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Univent (mm)</td>
                                                    <td>{(data?.univent_mm_min && data?.univent_mm_max) && (data?.univent_mm_min === data?.univent_mm_max) ?
                                                        <span>{data?.univent_mm_max}</span> :
                                                        <span>{data?.univent_mm_min} - {data?.univent_mm_max}</span>}


                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Suction Catheter (Fr)</td>
                                                    <td>{data?.suction_catheter_fr}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>)

                            })
                            }

                        </div> : <></>
                    }

                </div>
            </div>
        </div>
    )
}

export default AAV1001