import React, { useEffect, useState } from 'react'
import '../css/ApfelScore.css'
import { Link } from 'react-router-dom';
import FluidAbcScore from './FluidAbcScore';
import FluidBloodVolume from './FluidBloodVolume';
import FenaFluidCalculator from './FenaFluidCalculator';
import FeureaFluidCalculator from './FeureaFluidCalculator';
import IfdaFluidCalculator from './IfdaFluidCalculator';
import RbcFluidCalculator from './RbcFluidCalculator';
import FluidMaximumAbl from './FluidMaximunAbl';
import PlasmaFluidCalculator from './PlasmaFluidCalculator';
import FluidCalculator from './FluidsCalculator';
import SickleCellRBCExchangeVolume from './SickleCellRBCExchangeVolume';
import BloodProductFluid from './BloodProductFluid';
import RIFLEFluidCalculator from './RIFLEFluidCalculator';
import PortFluidCalculator from './PortFluidCalculator';
const FluidsTransfusionsCalculators = (props) => {
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    useEffect(() => {
        toggleTab(props.location.state.index)

    }, [])

    return (
        <div>
            <div className="backNavigation text-center">
                <Link className="arrow" to={"/" + props.location.state.returnPath}><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i><span className="tooltiptext">Return to {props.location.state.sourceName}</span></span></Link> FLUIDS &amp; TRANSFUSIONS CALCULATORS
            </div>
            <div className="main-container calculator-container mt-3">
                <div className="row">
                    <div className="col-md-4 fluid-calc-tabs">
                    <button className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(1)} >ABC Score for Massive Transfusion</button>
                    <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(2)} >Blood Volume Calculation</button>
                    <button className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(3)} >FENa</button>
                    <button className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(4)} >FEUrea</button>
                     <button className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(5)} >Intraoperative Fluid Dosing in Adult Patients</button>
                    <button className={toggleState === 6 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(6)} >Intrauterine RBC Transfusion Dosage</button>
                    <button className={toggleState === 7 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(7)} >Maximum allowable blood loss without transfusion</button>
                    <button className={toggleState === 8 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(8)} >Plasma Volume</button>
                    <button className={toggleState === 9 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(9)} >PORT score</button>
                    <button className={toggleState === 10 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(10)} >RIFLE Criteria for AKI</button>
                    <button className={toggleState === 11 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(11)} >Serum Osmolality / Osmolarity</button>
                    <button className={toggleState === 12 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(12)} >Sickle Cell RBC Exchange Volume</button>
                    <button className={toggleState === 13 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(13)} >Blood products and expected results</button>
                        {/* <div className="btn-btn">
                            <a className="risk-btn save-button-container"><i className="fa fa-plus-circle">&nbsp;&nbsp;</i>Add</a>
                        </div> */}
                    </div>
                <div className="col-md-8 col-sm-12">
                <div className="content-tabs">
                    <div className={toggleState === 1 ? "calculator-content  active-content" : "calculator-content"}>
                       <FluidAbcScore/>
                    </div>
                    <div
                        className={toggleState === 2 ? "calculator-content  active-content" : "calculator-content"}>
                      <FluidBloodVolume/>
                    </div>
                    <div
                        className={toggleState === 3 ? "calculator-content  active-content" : "calculator-content"}>
                       <FenaFluidCalculator/>
                    </div>
                    <div className={toggleState === 4 ? "calculator-content  active-content" : "calculator-content"}>
                       <FeureaFluidCalculator/>
                    </div>

                    <div
                        className={toggleState === 5 ? "calculator-content  active-content" : "calculator-content"} >
                        <IfdaFluidCalculator/>
                    </div>

                    <div
                        className={toggleState === 6 ? "calculator-content  active-content" : "calculator-content"}>
                      <RbcFluidCalculator/>
                    </div>
                    <div
                        className={toggleState === 7 ? "calculator-content  active-content" : "calculator-content"}>
                       <FluidMaximumAbl/>
                    </div>
                    <div
                        className={toggleState === 8 ? "calculator-content  active-content" : "calculator-content"}>
                       <PlasmaFluidCalculator/>
                    </div>
                    <div
                        className={toggleState === 9 ? "calculator-content  active-content" : "calculator-content"} >
                        <PortFluidCalculator/>
                    </div>

                    <div
                        className={toggleState === 10 ? "calculator-content  active-content" : "calculator-content"}>
                      <RIFLEFluidCalculator/>
                    </div>
                    <div
                        className={toggleState === 11 ? "calculator-content  active-content" : "calculator-content"}>
                       <FluidCalculator/>
                    </div>
                    <div
                        className={toggleState === 12 ? "calculator-content  active-content" : "calculator-content"}>
                       <SickleCellRBCExchangeVolume/>
                    </div>
                    <div
                        className={toggleState === 13 ? "calculator-content  active-content" : "calculator-content"}>
                      <BloodProductFluid/>
                    </div>
                </div>
                </div>
            </div>
            </div>
   
        </div>
    )
}

export default FluidsTransfusionsCalculators;