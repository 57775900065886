import React from 'react'
import LAMAX1001 from './LAMAX1001/LAMAX1001';
import LAMAX1002 from './LAMAX1002/LAMAX1002';
import LAMAX1003 from './LAMAX1003/LAMAX1003';

const LaMaxCalculatorIndex = ({ id, closeLAMAXAfterFinish,navigateToLast }) => {
    console.log(id)
    const laMaxCalcultors = [{
        id: 215,
        component: <LAMAX1001 navigateToLast={navigateToLast} />
    },
    {
        id: 216,
        component: <LAMAX1002 navigateToLast={navigateToLast} />
    },
    {
        id: 217,
        component: <LAMAX1003 navigateToLast={navigateToLast} closeLAMAXAfterFinish={closeLAMAXAfterFinish} />
    }
    ]

    return (
        <React.Fragment>
            {
                laMaxCalcultors.map(data => {
                    if (data.id === parseInt(id)) {
                        return data.component
                    } else {
                        return null
                    }
                })
            }
        </React.Fragment>
    )
}

export {
    LaMaxCalculatorIndex
}