import React, { useEffect, useState } from 'react';
import './RE1241.css';

const RE1241 = () => {
    const [green, setGreen] = useState(false);
    const [fexpression, setFexpression] = useState(0);
    const [hemoglobin1, setHemoglobin1] = useState([]);
    const [tunit6, setTunit6] = useState('g/L');
    const [placehldr6, setPlacehldr6] = useState('Norm: 120 - 170');


    const [result, setResult] = useState(0);


    const [immunocompromise, setImmunocompromise] = useState(0);
    const [age, setAge] = useState(0);

    const [mAPressure, setMAPressure] = useState(0);

    const [ph, setPh] = useState(0);
    const [ps, setPs] = useState(0);
    const [pm, setPm] = useState(0);
    const [np, setNp] = useState(0);
    const [os, setOs] = useState(0);

    const [pulse, setPulse] = useState(0);
    const [respiratory, setRespiratory] = useState(0);
    const [respiratory1, setRespiratory1] = useState(0);

    const [sodium, setSodium] = useState([]);
    const [tunit1, setTunit1] = useState('mmol/L');
    const [placehldr1, setPlacehldr1] = useState('Norm:136-145')

    const [potassium, setPotassium] = useState([]);
    const [tunit2, setTunit2] = useState('mmol/L');
    const [placehldr2, setPlacehldr2] = useState('Norm:3.5-5')

    const [tunit3, setTunit3] = useState('mmol/L');
    const [placehldr3, setPlacehldr3] = useState('Norm: 2.9 - 7.1')
    const [creatinine, setCreatinine] = useState([]);

    const [tunit5, setTunit5] = useState('× 10⁹ cells/L');
    const [placehldr5, setPlacehldr5] = useState('Norm: 3.7 - 5')
    const [wbc, setWbc] = useState([]);

    const [fio, setFio] = useState(0);
    const [pao2, setPao2] = useState(0);
    const [cardiac, setCardiac] = useState(0);
    const [typeOfSurgery, setTypeOfSurgery] = useState(0);
    const [renal, setRenal] = useState(0);
    const [hematocrite, setHematocrite] = useState(0);
    const [glassglow, setGlasgow] = useState(0);



    const postData = async () => {
        setResult(fexpression + fio + pao2);
        setGreen(true);
    }
    useEffect(() => {
        postData();


    }, [fexpression, fio, pao2, result]);

    const changeunit1 = () => {
        if (sodium === 0) {
            if (tunit1 === 'mmol/L') {
                setTunit1('mEq/L');

            }
            if (tunit1 === 'mEq/L') {
                setTunit1('mmol/L');

            }
        } else if (sodium !== 0) {
            if (tunit1 === 'mmol/L') {
                setPlacehldr1("Norm: 136-145");
                setTunit1('mEq/L');
                if (sodium.length !== 0) {
                    setSodium(Math.round(sodium * 1).toFixed(2))
                }
            }
            if (tunit1 === 'mEq/L') {
                setPlacehldr1("Norm: 136-145");
                setTunit1('mmol/L');
                if (sodium.length !== 0) {
                    setSodium(Math.round(sodium * 1))
                }
            }
        }
    }
    const takeUnit1 = (e) => {
        if (tunit1 === 'mmol/L') {
            setSodium(e.target.value);
            setPlacehldr1("Norm: 136-145");

        }
        if (tunit1 === 'mEq/L') {
            let ht = e.target.value;
            setSodium(ht);
            setPlacehldr1("Norm:136-145");
        }
    }

    const changeUnit2 = () => {
        if (potassium === 0) {
            if (tunit2 === 'mmol/L') {
                setTunit2('mEq/L');

            }
            if (tunit2 === 'mEq/L') {
                setTunit2('mmol/L');

            }
        } else if (potassium !== 0) {
            if (tunit2 === 'mmol/L') {
                setPlacehldr2("Norm: 3.5-5");
                setTunit2('mEq/L');
                if (potassium.length !== 0) {
                    setPotassium(Math.round(potassium * 1).toFixed(2))
                }
            }
            if (tunit2 === 'mEq/L') {
                setPlacehldr2("Norm: 3.5-5");
                setTunit2('mmol/L');
                if (potassium.length !== 0) {
                    setPotassium(Math.round(potassium * 1))
                }
            }
        }
    }
    const takeUnit2 = (e) => {
        if (tunit2 === 'mmol/L') {
            setPotassium(e.target.value);
            setPlacehldr2("Norm: 3.5-5");

        }
        if (tunit2 === 'mEq/L') {
            let ht = e.target.value;
            setPotassium(ht);
            setPlacehldr2("Norm:3.5-5");
        }
    }
    const changeUnit3 = () => {
        if (creatinine === 0) {
            if (tunit3 === 'mmol/L') {
                setTunit3('mg/dL');

            }
            if (tunit3 === 'mg/dL') {
                setTunit3('mmol/L');

            }
        } else if (creatinine !== 0) {
            if (tunit3 === 'mmol/L') {
                setTunit3('mg/dL');

                setPlacehldr3("Norm: 8-20");
                if (creatinine.length !== 0) {
                    setCreatinine((creatinine / 88.4).toFixed(2))
                }
            }
            if (tunit3 === 'mg/dL') {
                setPlacehldr3 ("Norm: 2.9-7.1");
                setTunit3('mmol/L');
                if (creatinine.length !== 0) {
                    setCreatinine((creatinine * 88.4).toFixed(2))
                }
            }
        }
    }
    const takeUnit3 = (e) => {
        if (tunit3 === 'μmol/L') {
            setCreatinine(e.target.value);
            setPlacehldr3("Norm: 62-155");

        }
        if (tunit3 === 'mg/dL') {
            let ht = e.target.value;
            setCreatinine(ht);
            setPlacehldr3("Norm: 0.7-1.3");
        }
    }

    const changeUnit5 = () => {
        if (wbc === 0) {
            if (tunit5 === '× 10⁹ cells/L') {
                setTunit5('× 10³ cells/µL');

            }
            if (tunit5 === '× 10³ cells/µL') {
                setTunit5('× 10⁹ cells/L');

            }
        } else if (wbc !== 0) {
            if (tunit5 === '× 10⁹ cells/L') {
                setTunit5('× 10³ cells/µL');

                setPlacehldr5("Norm: 3.7-5");
                if (wbc.length !== 0) {
                    setWbc((wbc / 88.4).toFixed(2))
                }
            }
            if (tunit5 === '× 10³ cells/µL') {
                setPlacehldr5("Norm: 3.7-5");
                setTunit5('× 10⁹ cells/L');
                if (wbc.length !== 0) {
                    setWbc((wbc * 88.4).toFixed(2))
                }
            }
        }
    }
    const takeUnit5 = (e) => {
        if (tunit5 === '× 10⁹ cells/L') {
            setWbc(e.target.value);
            setPlacehldr5("Norm: 3.7-5");

        }
        if (tunit5 === '× 10³ cells/µL') {
            let ht = e.target.value;
            setWbc(ht);
            setPlacehldr5("Norm: 3.7-5");
        }
    }
    const changeunit6 = () => {
        if (hemoglobin1 === 0) {
            if (tunit6 === 'g/L') {
                setTunit6('g/dL');

            }
            if (tunit6 === 'g/dL') {
                setTunit6('g/L');

            }
        } else if (hemoglobin1 !== 0) {
            if (tunit6 === 'g/L') {
                setTunit6('g/dL');
                setPlacehldr6("Norm: 12- 17");
                if (hemoglobin1.length !== 0) {

                    setHemoglobin1((hemoglobin1 / 10).toFixed(2));
                }

            }
            if (tunit6 === 'g/dL') {
                setTunit6('g/L');
                setPlacehldr6("Norm: 120- 170");
                if (hemoglobin1.length !== 0) {

                    setHemoglobin1((hemoglobin1 * 10).toFixed(2));
                }

            }
        }
    }
    const takeUnit6 = (e) => {
        if (tunit6 === 'g/L') {
            setHemoglobin1(e.target.value);


        }
        if (tunit6 === 'g/dL') {

            setHemoglobin1(e.target.value);

        }
    }

    return (
        <div className="rev">
            <h5 className="text-info pl-2">POSSUM for Operative Morbidity and Mortality Risk</h5>
            <p className="pl-2">Estimates morbidity and mortality for general surgery patients.</p>
            <div class="alert alert-dark" role="alert">
                Physiological score
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Age</strong></div>
                </div>
                <div className="col-md-6 frm-field  ">
                    <input type="number" style={{ height:40 }} className="ant-text-input " onChange={(e) => setAge(e.target.value)} /><span className="units ">Years</span>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Cardiac</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn ">
                    <button style={{ height:40 }} onClick={() => setCardiac(1)} className={cardiac === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">
                        No failure</span></button>
                    <button style={{ height:40 }} onClick={() => setCardiac(2)} className={cardiac === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Diuretic, digoxin or angina/hypertension meds</span></button>
                    <button style={{ height:60 }} onClick={() => setCardiac(3)} className={cardiac === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Peripheral edema, warfarin, or <br/>borderline cardiomegaly on chest X-ray (CXR)
                    </span></button>
                    <button style={{  height:60 }} onClick={() => setCardiac(4)} className={cardiac === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Raised jugular venous pressure, or <br/>cardiomegaly on CXR
                    </span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Respiratory</strong></div>

                </div>
                <div className="col-md-6 btn-group-vertical options-btn ">
                    <button style={{ height:40 }} onClick={() => setRespiratory1(1)} className={respiratory1 === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">
                        No dyspnea</span></button>
                    <button style={{  height:40 }} onClick={() => setRespiratory1(2)} className={respiratory1 === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Exertional dyspnea or mild COPD on CXR</span></button>
                    <button style={{  height:40 }} onClick={() => setRespiratory1(3)} className={respiratory1 === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Limiting dyspnea or moderate COPD on CXR
                    </span></button>
                    <button style={{  height:40 }} onClick={() => setRespiratory1(4)} className={respiratory1 === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Dyspnea at rest or fibrosis/consolidation on CXR
                    </span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>sBP</strong></div>

                </div>
                <div className="col-md-6 frm-field  ">
                    <input style={{  height:40 }} type="number" className="ant-text-input " placeholder="Norm: 100-120" onChange={(e) => setMAPressure(e.target.value)} /><span className="units ">mm&nbsp;Hg</span>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>HR</strong></div>

                </div>
                <div className="col-md-6 frm-field  ">
                    <input style={{  height:40 }} type="number" className="ant-text-input " placeholder="Norm: 60 - 100" onChange={(e) => setPulse(e.target.value)} /><span className="units ">beats/min</span>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>GCS</strong></div>

                </div>
                <div className="col-md-6 frm-field ">
                    <input style={{  height:40 }} type="number" className="ant-text-input " placeholder=" Norm: 3 - 15" onChange={(e) => setGlasgow(e.target.value)} /><span className="units ">points</span>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Hgb</strong></div>
                </div>
                <div className="col-md-6 frm-field">
                    <input type="number"
                        onChange={takeUnit6}
                        placeholder={placehldr6}

                        className="ant-text-input" style={{  height:40 }} value={hemoglobin1} /><span className="units" onClick={changeunit6}>{tunit6}&#8651;</span>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6 ">
                    <div className="pt-2"><strong>WBC</strong></div>
                </div>
                <div className="col-md-6 frm-field ">
                    <input type="number"
                        onChange={takeUnit5}
                        placeholder={placehldr5}

                        className="ant-text-input" style={{  height:57 }} value={wbc} /><span className="units" onClick={changeUnit5}>{tunit5}&#8651;</span>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6 ">
                    <div className="pt-2"><strong>BUN</strong></div>
                </div>
                <div className="col-md-6 frm-field">
                    <input type="number"
                        onChange={takeUnit3}
                        placeholder={placehldr3}
                        className="ant-text-input" style={{  height:40 }} value={creatinine} /><span className="units" onClick={changeUnit3}>{tunit3}&#8651;</span>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6 ">
                    <div className="pt-2"><strong>sodium</strong></div>
                </div>
                <div className="col-md-6 frm-field">
                    <input type="number"
                        onChange={takeUnit1}
                        placeholder={placehldr1}

                        className="ant-text-input" style={{  height:40 }} value={sodium} /><span className="units" onClick={changeunit1}>{tunit1}&#8651;</span>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6 ">
                    <div className="pt-2"><strong>Potassium</strong></div>
                </div>
                <div className="col-md-6  frm-field">
                    <input type="number"
                        onChange={takeUnit2}
                        placeholder={placehldr2}
                        className="ant-text-input" style={{  height:40 }} value={potassium} /><span className="units" onClick={changeUnit2}>{tunit2}&#8651;</span>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>ECG</strong></div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn ">
                    <button style={{  height:40 }} onClick={() => setCardiac(1)} className={cardiac === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">
                        Normal</span></button>
                    <button style={{  height:40 }} onClick={() => setCardiac(2)} className={cardiac === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Atrial fibrillation (HR 60-90)</span></button>
                    <button style={{  height:60 }} onClick={() => setCardiac(3)} className={cardiac === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">5 ectopic beats/min, Q waves or ST/T<br/> wave changes

                    </span></button>
                    <button style={{  height:40 }} onClick={() => setCardiac(4)} className={cardiac === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Any other abnormal rhythm
                    </span></button>
                </div>
            </div>
            <div class="alert alert-dark" role="alert">
                Operative severity score
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Operative severity</strong></div>
                    <div>See About section for examples of surgeries in each category.</div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn ">
                    <button style={{  height:40 }} onClick={() => setOs(1)} className={os === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Minor
                    </span></button>
                    <button style={{  height:40 }} onClick={() => setOs(2)} className={os === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Moderate</span></button>
                    <button style={{  height:40 }} onClick={() => setOs(3)} className={os === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Major


                    </span></button>
                    <button style={{  height:40 }} onClick={() => setOs(4)} className={os === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Major+
                    </span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div ><strong>Number of procedures</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{  height:40 }} key="1" className={np === 3 ? "btn active" : "btn"} onClick={() => setNp(3)}> 1 <span className="ml-4 text-muted"></span></button>
                    <button style={{  height:40 }} key="2" className={np === 1 ? "btn active" : "btn"} onClick={() => setNp(1)}>2<span className="ml-4 text-muted"></span></button>
                    <button style={{  height:40 }} key="2" className={np === 2 ? "btn active" : "btn"} onClick={() => setNp(2)}>3<span className="ml-4 text-muted"></span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Estimated blood loss</strong></div>
                </div>
                <div className="col-md-6 frm-field ">
                    <input type="number" style={{ width: "92%" }} className="ant-text-input " onChange={(e) => setAge(e.target.value)} /><span className="units ">mL</span>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Peritoneal soiling</strong></div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn ">
                    <button onClick={() => setPs(1)} className={ps === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">

                        None</span></button>
                    <button style={{  height:40 }} onClick={() => setPs(2)} className={ps === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Minor (serous fluid)</span></button>
                    <button style={{  height:40 }} onClick={() => setPs(3)} className={ps === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Local pus


                    </span></button>
                    <button style={{  height:40 }} onClick={() => setPs(4)} className={ps === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Free bowel content, pus or blood
                    </span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Presence of malignancy</strong></div>
                    <div>Diagnosed before or during surgery</div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn ">
                    <button style={{  height:40 }} onClick={() => setPm(1)} className={pm === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">
                        None</span></button>
                    <button style={{  height:40 }} onClick={() => setPm(2)} className={pm === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Primary only</span></button>
                    <button style={{  height:40 }} onClick={() => setPm(3)} className={pm === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Lymph node mets



                    </span></button>
                    <button style={{  height:40 }} onClick={() => setOs(4)} className={os === 4 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Distant mets
                    </span></button>

                </div>
            </div>
            <div className="fields row mb-5">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Mode of surgery</strong></div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn ">
                    <button style={{  height:40 }} onClick={() => setOs(1)} className={os === 1 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">
                        Elective</span></button>
                    <button style={{  height:60 }} onClick={() => setOs(2)} className={os === 2 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Emergency (within 24h), resuscitation<br/> {">"}2h possible
                    </span></button>
                    <button style={{  height:40 }} onClick={() => setOs(3)} className={os === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name ">Emergency (within 2h)
                    </span></button>
                </div>
            </div>



            <div className="stick  pt-3">
                {
                    result != 0 ? <>
                        <div className="apfel-score-result-container " >
                            <div className="point-result-container" >
                                <h1>{result} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>



                            </div>

                        </div>

                    </>

                        :
                        <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                            <h6>Please fill out required fields.</h6></div>
                }
            </div>
            <div className="p-2 mb-5" >
                <h6>
                    ORIGINAL/PRIMARY REFERENCE
                </h6>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/2021856/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Copeland GP, Jones D, Walters M. POSSUM: a scoring system for surgical audit. Br J Surg 1991;78(3):355-60..</p>
                    </div>
                </a>
                <br/>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/9752863/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Prytherch DR, Whiteley MS, Higgins B, Weaver PC, Prout WG, Powell SJ. POSSUM and Portsmouth POSSUM for predicting mortality. Physiological and Operative Severity Score for the enUmeration of Mortality and morbidity. Br J Surg 1998 Sep;85(9):1217-20.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}


export default RE1241;

