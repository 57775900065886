import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { API_ROOT } from '../../../constants'
import './PE1313.css'
import Spinner from '../../../CustomComponent/Spinner'

const PE1313 = (props) => {
    const [medicalHistoryArray, setmedicalHistoryArray] = useState([])
    const [medicalHistory, setmedicalHistory] = useState([])
    const [medicationsArray, setmedicationsArray] = useState([])
    const [medication, setmedication] = useState([])
    const [diagnosisArray, setDiagnosis] = useState([])
    const [loading, setLoading] = useState(false)
    const [resShow, setResShow] = useState(false)
    const [res1, setRes1] = useState([])
    const [scrollpos, setScrollPos] = useState({ bottom: 0 });



    useEffect(() => {
        getCaseSummary()
    }, [])


    const getCaseSummary = async () => {
        var token = sessionStorage.getItem("token")

        fetch(API_ROOT + `case-summary-form-data?device_type=Android&device_token=123`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }

        })
            .then(response => response.json())
            .then(res => {
                if (res.status === "true" && res.message === "Case Summary Form Data") {

                    res.data.drugList.forEach(element => {
                        element.value = element.id;
                        element.label = element.drug_name
                    });

                    setmedicationsArray(res.data.medications)


                    setmedicalHistory(res.data.medicalHistories)
                    setLoading(true)
                }
            })


    }



    var obj = {
        name: ""
    }
    const onSiteChanged = (e, name) => {
        if (e.target.checked === true) {

            //  medicalHistoryArray.push(e.target.name);
            obj = {
                name: name
            }
            diagnosisArray.push(obj)
        }

        if (e.target.checked === false) {
            diagnosisArray.forEach((ob, i) => {
                if (ob.name === e.target.name) {
                    diagnosisArray.splice(i, 1);

                }
            })


        }

    }

    const onMedicationValue = (e, name) => {
   
        if (e.target.checked === true ) {
           
            //  medicalHistoryArray.push(e.target.name);
            obj = {
                name: name
            }
            medication.push(obj)
    }

        if (e.target.checked === false) {
            medication.forEach((ob, i) => {
                if (ob.name === e.target.name) {
                    medication.splice(i, 1);

                }
            })
        

        }

    }

    const handleClick = async () => {
        try {

            var token = sessionStorage.getItem("token")
            fetch(API_ROOT + `preop-testing?token=${token}`, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    diagnosis: [...diagnosisArray,...medication]
                    
                })

            })
                .then(response => response.json())
                .then(res => {
                    if (res.status === "true" && res.message === "Preoperative Testing results") {
                        let myData = Object.keys(res.data).map(key => res.data[key]);
                        setRes1(myData)
                    } else {
                        alert(res.message)
                    }
                })


        } catch (error) {
        }
        setResShow(true)
    }


    return (
        <div>


            <div className="backNavigation text-center">
                <Link className="arrow" ><span className="tooltip-arrow"><i className="fa fa-chevron-circle-left"></i></span></Link> PREOPERATIVE EVALUATION
            </div>
            <div className="main-container mt-3" >
                {loading ?
                    medicalHistory.map((data, i) => {
                        return (
                            <>
                                <div style={{ width: "100%" }} key={data.id}>
                                    <div className="preoperative-medical-history-question-header" >
                                        <div style={{ fontWeight: "bold" }}>{i + 1}. {data.name}</div>

                                    </div>

                                    <div className="preoperative-medical-history-sub-question-sub-container" >
                                        {
                                            data.medical_history_sub_type.map((data) => {
                                                return (
                                                    <div className="preoperative-sub-question-container" key={data.id}>
                                                        <div>{data.name}</div>

                                                        <div className="preoperative-option-box-container" >
                                                            {/* <input onChange={(e) => onSiteChanged(e, data.name)} type="radio" value="Yes" name={data.name} /> Yes
                                                            <input onChange={(e) => onSiteChanged(e)} type="radio" value="No" name={data.name} /> No */}
                                                            <div className="toggle-button-cover">
                                                                <div className="button-cover">
                                                                    <div className="button b2" id="button-10">
                                                                        <input type="checkbox" className="checkbox" defaultChecked={false} onChange={(e) => onSiteChanged(e, data.name)} name={data.name} />

                                                                        <div className="knobs">
                                                                            <span>NO</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })
                                        }



                                    </div>

                                </div>
                                <div className="all-action-subcontainer-content-1"></div>
                            </>

                        )
                    })
                    :
                    <Spinner />
                }
                {
                    medicationsArray.length === 0 ? <></> :
                <div>
                <div className="preoperative-medical-history-question-header  alert alert-warning p-2" style={{ fontWeight: 'bold',backgroundColor: '#f5f5f5',width:'90%' }}>Medication</div>
                {
                    medicationsArray.map((data) => {
                        return (
                            <>
                                <div key={data.id} className="preoperative-medication-sub-question-container" style={{ position: "relative", }} >
                                    <div>{data.name}</div>
                                    <div className="preoperative-option-box-container" >
                                        {/* <input onChange={(e) => onMedicationValue(e, data)} type="radio" value="Yes" name={data.name} /> Yes
                                        <input onChange={(e) => onMedicationValue(e, data)} type="radio" value="No" name={data.name} /> No */}
                                        <div className="toggle-button-cover">
                                            <div className="button-cover">
                                                <div className="button b2" id="button-10">
                                                    <input type="checkbox" className="checkbox" onChange={(e) => onMedicationValue(e, data.name)} name={data.name} defaultChecked={ data.name === "No medication" ? true : medication.includes(data.name) ? true : false}
 />
                                                    <div className="knobs">
                                                        <span>NO</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })
                }

                <div className="all-action-subcontainer-content-1"></div>
                <div className="button-div-preop">
                    <button onClick={() => handleClick()} className="save-button-container">Submit</button>
                </div>
                </div>
}
                <div className="stick" style={{background:'#FFF'}} >
                    {
                        resShow ?
                            <>
                                <table className="table table-striped table-hover" style={scrollpos}>
                                    <thead className="bg-header text-left text-white stickheader-table">
                                        <tr>
                                            <th className="text-center">Diagnosis</th>
                                            <th className="text-center">Medication</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            res1.map((data) => {
                                                return (
                                                    <>
                                                        {
                                                            data.map((data1) => {
                                                                return (
                                                                    <>
                                                                        <tr key={data1.id}>
                                                                            <td  className="text-center">{data1.diagnosis}</td>
                                                                            <td  className="text-center">{data1.medication}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>

                                </table>

                            </>
                            :
                            <>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}

export default PE1313