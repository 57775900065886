import React, { useEffect, useState } from 'react';
import './CAS1029.css';

const CAS1029 = () => {
    const [green, setGreen] = useState(true);
    const [age, setAge] = useState(0);
    const [sex, setSex] = useState(0);
    const [potassium, setPotassium] = useState(0);
    const [patient, setPatient] = useState(0);
    const [admission, setAdmission] = useState(0)
    const [myocardial, setMyocardial] = useState(0);
    const [sepsis, setSepsis] = useState(0)
    const [failure, setFailure] = useState(0)
    const [qtc, setQtc] = useState(0);
    const [exchange_volume, setExchange_volume] = useState(0);
    const [scrollpos, setScrollPos] = useState({ bottom: 0 });

    const postData = async () => {
        setGreen(true);
        setExchange_volume(age + sex + potassium + patient + admission + myocardial + sepsis + failure + qtc);
    }
    useEffect(() => {
        postData()

    }, [age, sex, potassium, patient, admission, myocardial, sepsis, failure, qtc])

    const output = () => {

        if (exchange_volume !== 0) {
            if ((exchange_volume > 0) && (exchange_volume < 7)) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <h1>{exchange_volume} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                        <div>Tisdale Risk Score for QTc</div>
                    </div>
                    <div className="point-result-container" >
                        <div>
                            <h1>Low<span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>&nbsp;risk</span></h1>
                            <br/>
                            <div>Low risk for QT prolongation; always consider that higher risk may develop depending on clinical course and drug interactions and pharmacokinetics</div>
                        </div>
                    </div>
                </div>
            }
            if ((exchange_volume > 6) && (exchange_volume < 11)) {
                return <div className="apfel-score-result-container" >
                    <div className="point-result-container" >
                        <h1>{exchange_volume} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                        <div>Tisdale Risk Score for QTc</div>
                    </div>
                    <div className="point-result-container" >
                        <div>
                            <h1>Moderate<span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>&nbsp; risk</span></h1>
                            <div>Moderate risk for QT prolongation; consider consultation with pharmacist, adjusting risk factors as much as possible</div>
                            <br/>
                            <div>
                                EKG should be repeated after 5 half-lives of QT prolonging drugs given to evaluate QTc</div>
                        </div>
                    </div>
                </div>
            }


            else if (exchange_volume > 10) {
                return <div className="apfel-score-result-container" >
                   <div className="point-result-container" >
                        <h1>{exchange_volume} <span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>points</span></h1>
                        <div>Tisdale Risk Score for QTc</div>
                    </div>
                    <div className="point-result-container" >
                        <div>
                            <h1>High<span style={{ fontWeight: 600, fontSize: '1rem', color: 'silver' }}>&nbsp; risk</span></h1>
                            <div>High risk for QT prolongation; consider consultation with pharmacist, adjusting risk factors as much as possible, and using alternative medications if possible</div>
                            <br/>
                            <div>
                            EKG should be repeated after 5 half-lives of QT prolonging drugs given to evaluate QTc</div>
                        </div>
                    </div>
                </div>

            }
        }
        else {
            return (
                <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                    <h6>Please fill out required fields.</h6></div>

            )
        }

    }

    return (
        <div className="rev">
            <h5 className="text-info pl-2">Tisdale Risk Score for QT Prolongation</h5>
            <p className="pl-2">Predicts risk of QT prolongation greater than 500 msec in hospitalized patients..</p>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Age &ge;68 years </strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40 }} key="1" className={age === 0 ? "btn active" : "btn"} onClick={() => setAge(0)}>No  <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40 }} key="2" className={age === 1 ? "btn active" : "btn"} onClick={() => setAge(1)}>Yes <span className="ml-4 text-muted">+1</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Sex</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40, width: 80 }} key="1" className={sex === 0 ? "btn active" : "btn"} onClick={() => setSex(0)}>Male <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40, width: 80 }} key="2" className={sex === 1 ? "btn active" : "btn"} onClick={() => setSex(1)}>Female <span className="ml-4 text-muted">+1</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Patient on loop diuretic</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40, width: 80 }} key="1" className={patient === 0 ? "btn active" : "btn"} onClick={() => setPatient(0)}>No <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40, width: 80 }} key="2" className={patient === 1 ? "btn active" : "btn"} onClick={() => setPatient(1)}>Yes <span className="ml-4 text-muted">+1</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Potassium ≤3.5 mEq/L</strong></div>
                    <div className="pt-2">Potassium should be potassium determined closest to EKG timing </div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40, width: 80 }} key="1" className={potassium === 0 ? "btn active" : "btn"} onClick={() => setPotassium(0)}>No  <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40, width: 80 }} key="2" className={potassium === 2 ? "btn active" : "btn"} onClick={() => setPotassium(2)}>Yes <span className="ml-4 text-muted">+2</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Admission QTc ≥450 msec</strong></div>
                    <div className="pt-2">Auto-calculated by EKG </div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40, width: 80 }} key="1" className={admission === 0 ? "btn active" : "btn"} onClick={() => setAdmission(0)}>No  <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40, width: 80 }} key="2" className={admission === 2 ? "btn active" : "btn"} onClick={() => setAdmission(2)}>Yes <span className="ml-4 text-muted">+2</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Being admitted for acute myocardial infarction</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40, width: 80 }} key="1" className={myocardial === 0 ? "btn active" : "btn"} onClick={() => setMyocardial(0)}>No  <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40, width: 80 }} key="2" className={myocardial === 2 ? "btn active" : "btn"} onClick={() => setMyocardial(2)}>Yes <span className="ml-4 text-muted">+2</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Being admitted for sepsis</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40, width: 80 }} key="1" className={sepsis === 0 ? "btn active" : "btn"} onClick={() => setSepsis(0)}>No  <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40, width: 80 }} key="2" className={sepsis === 3 ? "btn active" : "btn"} onClick={() => setSepsis(3)}>Yes <span className="ml-4 text-muted">+3</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Being admitted for heart failure</strong></div>
                </div>
                <div className="col-md-6 btn-group text-left">
                    <button style={{ height: 40, width: 80 }} key="1" className={failure === 0 ? "btn active" : "btn"} onClick={() => setFailure(0)}>No  <span className="ml-4 text-muted">0</span></button>
                    <button style={{ height: 40, width: 80 }} key="2" className={failure === 3 ? "btn active" : "btn"} onClick={() => setFailure(3)}>Yes <span className="ml-4 text-muted">+3</span></button>

                </div>
            </div>
            <div className="fields row">
                <div className="col-md-6">
                    <div className="pt-2"><strong>Number of QTc-prolonging drugs given</strong></div>
                    <div className="pt-2">If receiving ≥2 drugs, patient receives 3 points for 1 QTc-prolonging drug as well as 3 additional points for ≥2 </div>
                </div>
                <div className="col-md-6 btn-group-vertical options-btn">
                    <button onClick={() => setQtc(0)} className={qtc === 0 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">None</span><span className="text-right pv text-muted">0</span></button>
                    <button onClick={() => setQtc(3)} className={qtc === 3 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">1 QTc-prolonging drug</span><span className="text-right pv text-muted">+3</span></button>
                    <button onClick={() => setQtc(6)} className={qtc === 6 ? "btn  text-left active" : "btn  text-left"}><span className="btn-name">&ge; QTc-prolonging Drugs</span><span className="text-right pv text-muted">+6</span></button>
                </div>
            </div>
            <div className="stick">
                {
                    output()
                }
            </div>
            <div className="p-2 mb-4" >
                <div>
                    ORIGINAL/PRIMARY REFERENCE
                </div>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/23716032/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Chung F, Yegneswaran B, Liao P, et al. STOP questionnaire: a tool to screen patients for obstructive sleep apnea. Anesthesiology. 2008;108(5):812-21.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default CAS1029
