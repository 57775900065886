import React, { useEffect, useState } from 'react';
import './CAS1022.css';

const CAS1022 = () => {
    const [systolic,setSystolic] = useState(0);
    const [diastolic,setDiastolic] = useState(0);

    const [result,setResult] = useState(0);
    const postData = async () => {
        setResult(((1/3)*systolic+(2/3)*diastolic).toFixed(0));
    }
    useEffect(() => {
        postData();


    }, [systolic,diastolic]);
    const valnumber1 = () => {
        if (systolic != 0) {
            if ((systolic < 30) && (systolic >= 1)) {
                return <div className="validate">*Too low; please change to proceed.</div>

            }
            if ((systolic <= 39) && (systolic > 29)) {
                return <div className="validate">Very low; double-check.</div>

            }
            if ((systolic < 301) && (systolic >= 251)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (systolic >= 301) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(systolic)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    const valnumber2 = () => {
        if (diastolic != 0) {
            if ((diastolic < 10) && (diastolic >= 1)) {
                return <div className="validate">*Too low; please change to proceed.</div>

            }
            if ((diastolic <= 20) && (diastolic > 9)) {
                return <div className="validate">Very low; double-check.</div>

            }
            if ((diastolic <= 200) && (diastolic >= 180)) {
                return <div className="validate">*Very High;double check.</div>

            }
            if (diastolic >= 201) {
                return <div className="validate">*Too high; please change to proceed.</div>

            }
            else if (isNaN(diastolic)) {
                return <div className="validate">*Please Enter valid-no.</div>

            }
        }
    }
    return (
        <div className="rev">
            <h5 className="text-info pl-2">Mean Arterial Pressure (MAP)</h5>
            <p className="pl-2">Calculates mean arterial pressure.</p>
         
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Systolic BP</strong></div>
                </div>
                <div className="col-md-7 frm-field">
                    <input type="number"
                        placeholder="Norm: 100-120" onChange={(e) => setSystolic(e.target.value)}
                        className="ant-text-input" style={{ width: '77%', height: '100%' }} /><span className="units">mm Hg</span>
                           <div>{valnumber1()}</div>
                </div>
            </div>
            <div className="fields row">
                <div className="col-md-5">
                    <div className="pt-2"><strong>Diastolic BP</strong></div>
                </div>
                <div className="col-md-7 frm-field ">
                    <input type="number"
                        placeholder="Norm: 60-80" onChange={(e) => setDiastolic(e.target.value)}
                        className="ant-text-input" style={{ width: '77%', height: '100%' }} /><span className="units">mm Hg</span>
                        <div>{valnumber2()}</div>
                </div>
            </div>
            <div className="stick pt-4">
            {
                ((systolic) && (diastolic)) ?

                    <div className="apfel-score-result-container" >

                        <div className="point-result-container col-12" >
                            <h4>{result} <span style={{ fontWeight: 400, fontSize: '1rem', color: 'silver' }}>mm Hg </span></h4>
                            <div>Mean Arterial Pressure (MAP)</div>
                        </div>
                    </div>
                    :
                    <div className="text-white p-2 green-bg"><h1>Result:</h1><br />
                        <h6>Please fill out required fields.</h6></div>
            }
        </div>
            <div className="p-2 mb-4" >
                <div>
                    ORIGINAL/PRIMARY REFERENCE
                </div>
                <a className="referenceLink" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/24736333/">
                    <div className="reference-container-card" >
                        <div className="reference-img" >
                            <img src="https://cdn-web-img.mdcalc.com/icons/resource-pubmed.png" alt="Research Paper" />

                        </div>
                        <p className="reference-paragraph" >Magder SA. The highs and lows of blood pressure: toward meaningful clinical targets in patients with shock. Crit Care Med. 2014;42(5):1241–1251.</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default CAS1022
